import { baseAction } from "./baseAction";
import { docsConstant, alertConstant, loaderConstant } from "../constants";
import { docsService } from "../services";

import {
  getUserDocPreferences
} from "../../utils";

//import history
import history from "../../config/history";

const { request, success, failure } = baseAction;

export const docsAction = {
  createDoc,
  getDocs,
  setRootFolder,
  openSubFolder,
  setEditorDocId,
  getDocContent,
  updateDocContent,
  deleteDoc,
  addPublicAccessToken,
  addAccess,
  removePublicAccessToken,
  getPublicDoc,
  getShareMembers,
  updateAccess,
  scrollToDoc,
  resetDocState,
  getSharedDocs,
  copyDoc,
  pinDoc,
  getCustomFolder,
  moveDoc,
  updateDocContentV2
};

/*****************************COMMON DATA********************************* */

/**
 * An action method to create doc
 * @param {*} parent_doc_id - parent doc id to create doc or folder
 * @param {*} params 
 * @param {*} callBack 
 * @returns 
 */
function createDoc(parent_doc_id, params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(docsConstant.CREATE_DOC_R, params));
    dispatch(request(loaderConstant.CREATING_NEW_DOC_TRUE));
    dispatch(request(loaderConstant.LOADING_TRUE));
    docsService.createDoc(parent_doc_id, params).then(data => {
      callBack(data)
      if (data.http_code === 500) {
        dispatch(failure(docsConstant.CREATE_DOC_F, data.message));
        dispatch(failure(alertConstant.ALERT_ERROR, data.message));
        dispatch(request(loaderConstant.CREATING_NEW_DOC_FALSE));
        dispatch(request(loaderConstant.LOADING_FALSE));
        return
      }
      const { id, path, type, parent_doc_id } = data.data || {}
      dispatch(success(docsConstant.CREATE_DOC_S, data.data));
      dispatch(request(loaderConstant.CREATING_NEW_DOC_FALSE));
      dispatch(request(loaderConstant.LOADING_FALSE));
      if (id) {
        if (type === 1) {
          dispatch(setEditorDocId(data.data.id))
        } else {
          if (parent_doc_id === 0) {
            // open for root folder
            dispatch(setRootFolder(data.data))
          } else {
            // for sub folder
            // dispatch(openSubFolder({
            //   [id]: { id, path }
            // }))
          }
          dispatch(docsAction.scrollToDoc(id))
        }
      }
      callBack()
    }, error => {
      dispatch(failure(docsConstant.CREATE_DOC_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.CREATING_NEW_DOC_FALSE));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/**
 * An action method to get docs list 
 * @param {*} path - base path to retrive list from
 * @param {*} params 
 * @param {*} callBack 
 * @returns 
 */
function getDocs(path, params, callBack = () => { }) {
  return (dispatch, getState) => {
    if (!params.append) {
      dispatch(request(docsConstant.GET_DOCS_LIST_R, params));
      dispatch(request(loaderConstant.LOADING_TRUE));
      }
    docsService.getDocs(path, params).then(data => {
      if(!params.append){
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
      let updatedData = [];
      const folderRoot = Object.keys(data?.data)
      if (path === "root/"){
        updatedData = { ...data?.data }
      } else if (folderRoot[0]) {
        const { details } = getState().docs
        
        if(params.skip === 0 || params.skip === undefined ){
          updatedData = {
            ...details,
            [folderRoot[0]]: data?.data[folderRoot[0]] || []
          }
        } else {
          updatedData = {
            ...details,
            [folderRoot[0]]: [...(details[folderRoot[0]] || []) , ...(data?.data[folderRoot[0]] || [])]
          }
        }
        
        if (folderRoot[0] === 'alldocs') {
          const response_length = (data?.data[folderRoot[0]] || [])?.length
          if (response_length < params.limit && updatedData?.alldocs?.length > 0) {
            const lastIndex = updatedData.alldocs.length - 1;
            if (updatedData.alldocs[lastIndex]) {
              updatedData.alldocs[lastIndex].isLastRecord = true;
            }
          }
        }

        if (folderRoot[0] === 'alldocs') {
          const response_length = (data?.data[folderRoot[0]] || [])?.length
          if (response_length < params.limit && updatedData?.alldocs?.length > 0) {
            const lastIndex = updatedData.alldocs.length - 1;
            if (updatedData.alldocs[lastIndex]) {
              updatedData.alldocs[lastIndex].isLastRecord = true;
            }
          }
        }
      }
      if ( Object.keys(updatedData).length > 0 ){
        dispatch(success(docsConstant.GET_DOCS_LIST_S, { ...updatedData }));
      }
      callBack(updatedData)
      if (data.data && data.data['root']) {
          const default_folder = data.data['root'].find(f => f.id == 'alldocs')
          if (default_folder) {
            dispatch(setRootFolder(default_folder))
          }
      }

      // set default doc
      if (data.data && data.data['alldocs']) {
        const { editor: { id } } = getState().docs
        const { editor } = getUserDocPreferences()
        const old_id = editor?.id
        // check if all docs has the doc id
        const hasAccess = data.data['alldocs'].findIndex(f => `${f.id}` === `${old_id}`)
        const first_doc = data.data['alldocs'][0]?.id
        const to_load_id = !hasAccess ? first_doc : old_id || first_doc
        if (!id && to_load_id) {
          dispatch(setEditorDocId(to_load_id))
        }
      }

      callBack()
    }, error => {
      dispatch(failure(docsConstant.GET_DOCS_LIST_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/**
 * A method will call when root folder changes
 * @param {*} data id, path 
 */
function setRootFolder(data) {
  return {
    type: docsConstant.SET_ROOT_FOLDER,
    data
  }
}

/**
 * A method will call when sub-folder get clicked
 * @param {*} data {id: {id, path}, ...}
 */
function openSubFolder(data) {
  return {
    type: docsConstant.OPEN_SUB_FOLDER,
    data
  }
}

/**
 * A method will call when sub-folder get clicked
 * @param {*} data contains id of the doc
 */
function setEditorDocId(data, ext = {}) {
  return {
    type: docsConstant.SET_EDITOR_DOC_ID,
    data,
    ext: ext || {}
  }
}

/**
 * A method will call to reset doc state
 * @param {*} data contains id of the doc
 */
function resetDocState() {
  return {
    type: docsConstant.RESET_DOC_STATE
  }
}

/**
 * An action method to get a doc content
 * @param {*} id - doc id to retrive content for edit / view
 * @param {*} callBack 
 * @returns 
 */
function getDocContent(id, callBack = () => { }, loading = true) {
  return (dispatch) => {
    dispatch(request(docsConstant.GET_DOC_CONTENT_R));
    if (loading) {
      dispatch(request(loaderConstant.LOADING_TRUE));
    }
    docsService.getDocContent(id).then(data => {
      dispatch(success(docsConstant.GET_DOC_CONTENT_S, data.data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack(data)
    }, error => {
      dispatch(failure(docsConstant.GET_DOC_CONTENT_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/**
 * An action method to update a doc content
 * @param {*} id - doc id to retrive content for edit / view
 * @param {*} callBack 
 * @returns 
 */
function updateDocContent(id, params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(docsConstant.UPDATE_DOC_CONTENT_R));
    // dispatch(request(loaderConstant.LOADING_TRUE));
    docsService.updateDocContent(id, params).then(data => {
      dispatch(success(docsConstant.UPDATE_DOC_CONTENT_S, data.data));
      // dispatch(success(docsConstant.GET_DOC_CONTENT_S, data.data));
      // dispatch(request(loaderConstant.LOADING_FALSE));
      callBack({ ...params, id, modified_on: data.data.modified_on });
    }, error => {
      dispatch(failure(docsConstant.UPDATE_DOC_CONTENT_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      // dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/**
 * An action method to update a doc content
 * @param {*} id - doc id to retrive content for edit / view
 * @param {*} callBack 
 * @returns 
 */
function updateDocContentV2(id, params, callBack = () => { }) {
  return (dispatch) => {
    docsService.updateDocContent(id, params).then(data => {
      dispatch(success(docsConstant.UPDATE_DOC_CONTENT_S, data.data));
      callBack({ ...params, id, modified_on: data.data.modified_on });
    }, error => {
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    }
    );
  };
}

/**
 * An action method to update a doc content
 * @param {*} id - doc id to retrive content for edit / view
 * @param {*} callBack 
 * @returns 
 */
function deleteDoc(id, count = 1, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(docsConstant.DELETE_DOC_R));
    // dispatch(request(loaderConstant.LOADING_TRUE));
    docsService.deleteDoc(id).then(data => {
      data.data.count = count
      dispatch(success(docsConstant.DELETE_DOC_S, data.data));
      // dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(docsConstant.DELETE_DOC_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      // dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

function addPublicAccessToken(id) {
  return (dispatch) => {
    dispatch(request(docsConstant.ADD_DOC_PUBLIC_ACCESS_TOKEN_R));
    dispatch(request(loaderConstant.LOADING_TRUE));
    docsService.addPublicAccessToken(id).then(data => {
      dispatch(success(docsConstant.ADD_DOC_PUBLIC_ACCESS_TOKEN_S, data.data));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }, error => {
      dispatch(failure(docsConstant.ADD_DOC_PUBLIC_ACCESS_TOKEN_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

function removePublicAccessToken(id) {
  return (dispatch) => {
    dispatch(request(docsConstant.REMOVE_DOC_PUBLIC_ACCESS_TOKEN_R));
    dispatch(request(loaderConstant.LOADING_TRUE));
    docsService.removePublicAccessToken(id).then(data => {
      dispatch(success(docsConstant.REMOVE_DOC_PUBLIC_ACCESS_TOKEN_S, data.data));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }, error => {
      dispatch(failure(docsConstant.REMOVE_DOC_PUBLIC_ACCESS_TOKEN_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

function getPublicDoc(token, cb = () => { }) {
  return (dispatch) => {
    dispatch(request(docsConstant.GET_PUBLIC_DOCUMENT_R));
    dispatch(request(loaderConstant.LOADING_TRUE));
    docsService.getPublicDoc(token).then(data => {
      dispatch(success(docsConstant.GET_PUBLIC_DOCUMENT_S, data.data));
      cb(data.data)
      dispatch(request(loaderConstant.LOADING_FALSE));
    }, error => {
      dispatch(failure(docsConstant.GET_PUBLIC_DOCUMENT_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

function addAccess(id, params = {}, getALL = null) {
  return (dispatch) => {
    dispatch(request(docsConstant.ADD_DOC_ACCESS_R));
    dispatch(request(loaderConstant.LOADING_TRUE));
    docsService.addAccess(id, params).then(data => {
      dispatch(success(docsConstant.ADD_DOC_ACCESS_S, data.data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      if (getALL) {
        dispatch(docsAction.getShareMembers(getALL));
      }
    }, error => {
      dispatch(failure(docsConstant.ADD_DOC_ACCESS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

function getShareMembers(id, loading = true) {
  return (dispatch) => {
    dispatch(request(docsConstant.GET_SHARE_MEMBERS_R));
    if (loading) {
      dispatch(request(loaderConstant.LOADING_TRUE));
    }
    docsService.getShareMembers(id).then(data => {
      dispatch(success(docsConstant.GET_SHARE_MEMBERS_S, data.data));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }, error => {
      dispatch(failure(docsConstant.GET_SHARE_MEMBERS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

function updateAccess(id, params, getALL = null) {
  return (dispatch, getState) => {
    dispatch(request(docsConstant.UPDATE_SHARE_MEMBERS_R));
    dispatch(request(loaderConstant.LOADING_TRUE));
    docsService.updateShareMembers(id, params).then(data => {
      dispatch(success(docsConstant.UPDATE_SHARE_MEMBERS_S, data.data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      if (getALL) {
        dispatch(docsAction.getShareMembers(getALL));
      }
    }, error => {
      dispatch(failure(docsConstant.UPDATE_SHARE_MEMBERS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/**
 * A method to scroll to doucument for a given doc_id
 * @param {*} data id of the document
 */
function scrollToDoc(data) {
  return {
    type: docsConstant.SCROLL_TO_DOC,
    data
  }
}

/**
 * An action method to get docs list 
 * @param {*} path - base path to retrive list from
 * @param {*} params 
 * @param {*} callBack 
 * @returns 
 */
function getSharedDocs(callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(docsConstant.GET_SHARED_DOCS_LIST_R));
    docsService.getSharedDocs().then(data => {
      dispatch(success(docsConstant.GET_SHARED_DOCS_LIST_S, data.data));
      callBack()
    }, error => {
      dispatch(failure(docsConstant.GET_SHARED_DOCS_LIST_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    }
    );
  };
}

/**
 * An action method to create doc
 * @param {*} parent_doc_id - parent doc id to create doc or folder
 * @param {*} copy_doc_id - copy doc id to duplicate the doc
 * @param {*} params 
 * @param {*} callBack 
 * @returns 
 */
function copyDoc(parent_doc_id, params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(docsConstant.COPY_DOC_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    docsService.copyDoc(parent_doc_id, params).then(data => {
      if (data.http_code === 500) {
        dispatch(failure(docsConstant.COPY_DOC_F, data.message));
        dispatch(failure(alertConstant.ALERT_ERROR, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        return
      }
      const { id, type } = data.data || {}
      dispatch(success(docsConstant.COPY_DOC_S, data.data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      if (id) {
        if (type === 1) {
          dispatch(setEditorDocId(data.data.id))
        }
      }
      callBack(data.data)
    }, error => {
      dispatch(failure(docsConstant.CREATE_DOC_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/**
 * An action method to Pin Doc
 * @param {*} pin_doc_id - id of document which user is pinning
 * @param {*} params 
 * @param {*} callBack 
 * @returns 
 */
function pinDoc(doc_id, params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(docsConstant.PIN_DOC_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    docsService.pinDoc(doc_id, params).then(data => {
      if (data.http_code === 500) {
        dispatch(failure(docsConstant.PIN_DOC_F, data.message));
        dispatch(failure(alertConstant.ALERT_ERROR, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        return
      }
      dispatch(success(docsConstant.PIN_DOC_S, data.data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack(data.data)
    }, error => {
      dispatch(failure(docsConstant.PIN_DOC_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/**
 * An action method to get custom folder list
 * @param {*} doc_id - id of document
 * @param {*} params 
 * @param {*} callBack 
 * @returns 
 */
function getCustomFolder(doc_id, params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(docsConstant.GET_CUSTOM_FOLDERS_R));
    dispatch(request(loaderConstant.LOADING_TRUE));
    docsService.getCustomFolder(doc_id, params).then(data => {
      if (data.http_code === 500) {
        dispatch(failure(docsConstant.GET_CUSTOM_FOLDERS_F, data.message));
        dispatch(failure(alertConstant.ALERT_ERROR, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        return
      }
      dispatch(success(docsConstant.GET_CUSTOM_FOLDERS_S, data.data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack(data.data)
    }, error => {
      dispatch(failure(docsConstant.GET_CUSTOM_FOLDERS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/**
 * An action method to get custom folder list
 * @param {*} doc_id - id of document
 * @param {*} params 
 * @param {*} callBack 
 * @returns 
 */
function moveDoc(doc_id, params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(docsConstant.MOVE_DOC_R));
    dispatch(request(loaderConstant.LOADING_TRUE));
    docsService.moveDoc(doc_id, params).then(data => {
      if (data.http_code === 500) {
        dispatch(failure(docsConstant.MOVE_DOC_F, data.message));
        dispatch(failure(alertConstant.ALERT_ERROR, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        return
      }
      if(params.current_docPath) {
        data.data["current_docPath"] = params.current_docPath
        data.data["current_job_id"] = params.current_job_id
      }
      dispatch(success(docsConstant.MOVE_DOC_S, data.data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack(data.data)
    }, error => {
      dispatch(failure(docsConstant.MOVE_DOC_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}
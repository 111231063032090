import React from "react";
import PropTypes from 'prop-types';
import { Avatar, Grid, withStyles } from '@material-ui/core'

//Import Component
import { Typography } from '..'

// Imports Styles
import styles from "./styles";

import { CameraAttachments, GifAttachments, VideoAttachments, AudioAttachments } from "../../assets/svg/chatIcons";

import { ReplyRadius } from "../../assets/svg";

const InputReplyComponent = (props) => {
    const { classes, messageObj, userId } = props;
    let fileUrl = null; let fileDoc = null; let fileIcon = null; let fileType = null;
    if (messageObj.message_file) {
        let fileObj = JSON.parse(messageObj.message_file);
        if (messageObj.message_category === 1) {
            fileUrl = fileObj && fileObj.url ? fileObj.url : null;
            if(fileObj.type.includes("video")) {
                fileType = "video"
                fileUrl = fileObj.thumb ? fileObj.thumb : fileObj
                fileIcon = <VideoAttachments />
            } else if(fileObj.type.includes("gif")) {
                fileType = "GIF"
                fileIcon = <GifAttachments />
            } else {
                fileType = "Image"
                fileIcon = <CameraAttachments />
            }
        }
        else if (messageObj.message_category === 2) {
            fileDoc = fileObj && fileObj.name ? fileObj.name : null;
            fileIcon = fileObj && fileObj.type ? fileObj.type.split("/")[1] : "docs";
        } else if (messageObj.message_category === 3) {
            fileUrl = fileObj && fileObj[0].url ? fileObj[0].url : null;
        }
        else if (messageObj.message_category === 4) {
            fileDoc = fileObj && fileObj.name ? "Voice Note" : null;
            fileIcon = <AudioAttachments />
        }
    }
    return (
        <>
            {messageObj ?
            <>
            {/* <Grid className={"replyHorizontalBar"}></Grid> */}
            <Grid className={"replyVerticleBar"}>
                <ReplyRadius />
            </Grid>
                <Grid className={classes.replyTile} container item alignItems="center">
                    <Grid className={`${classes.replyTileBox} replyTileBox`}>
                        <Grid className={classes.profileContainers}>
                        <Grid className={`${classes.avatarBox} replyAtavarText replyTileListBox`}>
                            <Avatar alt={messageObj.name ? messageObj.name : (messageObj.first_name ? messageObj.first_name : '')} src={messageObj.avatar_filename ? messageObj.avatar_filename : messageObj.first_name} />

                            {/* commented tag for user replyed message displayed twice  */}
                            {/* <Typography variant="subtitle2" className="tasksText taskName">{messageObj.name ? messageObj.name : `${messageObj.first_name ? messageObj.first_name : ''} ${messageObj.last_name ? messageObj.last_name : ''}`}</Typography>
                        {messageObj.message_text ? <Typography variant="body2" color="textSecondary" className="tasksText threeLineEllip">{messageObj.message_text ? messageObj.message_text : ""}</Typography> : null}
                        {messageObj.message_file ? <Typography variant="body2" color="textSecondary" className="tasksText threeLineEllip">{messageObj.message_text ? messageObj.message_text : ""}</Typography> : null} */}

                        </Grid>
                        <Grid className="replyTileBubbleBox mr-2">
                            <Typography variant="subtitle2" className="tasksText taskName fontSize10">{messageObj?.user_id == userId ? "You" : messageObj.name ? messageObj.name : `${messageObj.first_name ? messageObj.first_name : ''} ${messageObj.last_name ? messageObj.last_name : ''}`}</Typography>
                            {messageObj?.message_text?.trim() ? <Typography variant="body2" color="textSecondary" className="tasksText oneLineEllip fontSize12">{messageObj.message_text ? messageObj.message_text : ""}</Typography> : 
                            messageObj.message_file ? <span className="dflex alignCenter">{fileIcon && <Typography variant="body2" color="textSecondary" className="tasksText oneLineEllip fontSize10 dflex alignCenter pr5"> {fileIcon}</Typography>}<Typography variant="body2" color="textSecondary" className="tasksText oneLineEllip fontSize10 dflex alignCenter">{fileType ? fileType : ""}</Typography></span> : null}
                        </Grid>
                        </Grid>
                        {fileUrl ? <img alt={"imagethumb"} src={fileUrl} className={classes.replyThumb} /> : null}
                        {/* {fileDoc ? <Typography variant="body2" className="tasksText oneLineEllip">{fileDoc}</Typography> : null} */}
                    </Grid>
                </Grid>
                </> : null}
        </>
    );
};

// Default props
InputReplyComponent.defaultProps = {
    classes: "",
    messageObj: { id: "" }
};

// Prop types
InputReplyComponent.propTypes = {
    classes: PropTypes.object,
    messageObj: PropTypes.any,
};

/** Export Component */
export const ChatReplyComponent = withStyles(styles)(InputReplyComponent);

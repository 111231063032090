import React, { useRef, useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { Grid, withStyles } from "@material-ui/core";

//Import Styles
import styles from "./styles";
import { Editor, EditorState, convertFromRaw, ContentState } from 'draft-js';

import createMentionPlugin from '@draft-js-plugins/mention';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import createHashtagPlugin from '@draft-js-plugins/hashtag';
import className from 'classnames';
import { createEditorStateWithText } from '@draft-js-plugins/editor';
import { CircularProgress } from "@material-ui/core"

const styleMap = {
    CODE: {
        backgroundColor: '#111',
        color: '#fff',
        fontSize: '1.5rem',
        padding: '2px',
    },
};

const GroupMediaMessage = (props) => {
    const { classes, message, handleSlideShowView, handleUndo, undo, slidepreviewdata, handleHashTagClick, handleMentionClick, msgComponentType, userId, isUploading } = props;
    const editor = useRef(null);

    let notesContent = "";

    useEffect(() => {
        if (message?.message_raw_text) {
            try {
                if (message.is_infiniti_res === 1) {
                    notesContent = ContentState.createFromText(`${message?.message_text} - ${message?.infiniti_response}`)
                    // notesContent = convertFromRaw(infiniti_content)
                } else {
                    notesContent = message.message_raw_text ? convertFromRaw(JSON.parse(message.message_raw_text)) : ""
                }
                const initialState = notesContent ? EditorState.createWithContent(notesContent) : EditorState.createEmpty();
                setEditorState(initialState)
            } catch (err) {
                setEditorState(EditorState.createEmpty())
            }
        }
    }, [message?.message_raw_text])

    try {
        notesContent = message.message_text ? createEditorStateWithText(message.message_text?.trim()) : EditorState.createEmpty();
    } catch (err) {
        notesContent = EditorState.createEmpty()
    }
    // const initialState = notesContent;
    const [editorState, setEditorState] = useState(notesContent);

    const media = message.message_file ? JSON.parse(message.message_file) : [];
    media.map((m) => { m["id"] = message.id; return m })
    var groupSize = media.length;
    var displayData = groupSize > 4 ? media.slice(0, 4) : media;

    const onChange = (editorState) => {
        setEditorState(editorState);
    };

    const mentionPlugin = createMentionPlugin({
        classes: classes,
        mentionComponent: (mentionProps) => {
            let { mention, decoratedText } = mentionProps
            return (
                <a className={`${className(classes.messageMention, msgComponentType)} messageMention`} onClick={() => handleMentionClick(mention)}> {decoratedText}</a >
            )
        }
    });

    const linkifyPlugin = createLinkifyPlugin({
        target: '_blank',
        classes: classes,
        component: (props) => {
            return <a  {...props} className={`${className(classes.messageLink, msgComponentType)} messageLink`} onClick={() => window.open(props.href, '_blank')} />
        },
    });

    const renderImage = (m) => {
        const media = message?.uploadPreviews?.files_data !== "{}" ? JSON.parse(message?.uploadPreviews?.files_data) : [];
        media?.map((m) => { m["id"] = message?.id; return m })
        var groupSize = media.length;
        var displayData = groupSize > 4 ? media.slice(0, 4) : media;
        return (
            <>
                {
                    displayData.map((data, i) => {
                        let extension = data?.path?.split('.')?.pop()
                        if (extension === 'mov') {
                            extension = `./icons/mp4.svg`
                        }
                        else if (extension === 'm4a' || extension === 'mp3' || extension === 'html' || extension === 'csv') {
                            extension = `./icons/doc.svg`
                        }
                        else if (extension === 'txt') {
                            extension = `./icons/txt.svg`
                        }
                        else if (extension === 'xls' || extension === 'xlsx') {
                            extension = `./icons/xls.svg`
                        }
                        else if (extension === 'pdf') {
                            extension = `./icons/pdf.svg`
                        }
                        else {
                            extension = data.thumb
                        }
                        return <div className="groupImgContainer_img" key={`group_media_msg_${i}`}>
                            <img className={`${"ChatGroupImages"} ${classes.chatImage}`} alt='loading'
                                src={extension}
                                onClick={(e) => message?.message_file && !isUploading ? handleSlideShowView({ mediaArray: [...media], allFiles: [message], index: i, star: message.star, previewData: Array(groupSize).fill(slidepreviewdata), data: message }) : console.log("loading...")} key={i} />
                            {(i == 3 && groupSize > 4) ? (
                                <div className="overlay groupImgOverlay" onClick={(e) => message?.message_file && !isUploading ? handleSlideShowView({ mediaArray: [...media], allFiles: [message], index: i, star: message.star, previewData: Array(groupSize).fill(slidepreviewdata), data: message }) : console.log("loading...")}>+{parseInt(groupSize) - 3} More</div>
                            ) : null}
                        </div>
                    })
                }
            </>
        )
    }

    const hashtagPlugin = createHashtagPlugin({
        classes: classes,
        hashtagComponent: (props) => {
            let { decoratedText } = props;
            return <a className={className(classes.messageHashTag, msgComponentType)} onClick={() => handleHashTagClick(decoratedText)} >{decoratedText}</a>
        },
    });
    if ((isUploading && message.uploadPreviews) || message.uploadPreviews) {
        return (
            <>
                {!undo ?
                    <div className={`${classes.groupImgContainerMain}`} >
                        <Grid className={`${classes.groupImgContainer} groupImgContainer`} >
                            {renderImage()}
                        </Grid>
                        {message?.message_text && message?.message_text !== "\r\n" && message?.message_text !== "\n" &&
                            <Grid className={`${message?.message_text ? 'm4' : 'mt-1'}`}>
                                <div className={`${message?.star && userId == message?.star_user_id ? classes.textMsgBubbleStar : classes.textMsgBubble} spacenone ${classes.hideGroupText} disable-user-select`}>
                                    <Editor
                                        customStyleMap={styleMap}
                                        editorState={editorState}
                                        onChange={onChange}
                                        plugins={[linkifyPlugin, mentionPlugin, hashtagPlugin]}
                                        ref={editor}
                                        readOnly={true}
                                    />
                                </div>
                            </Grid>
                        }
                        {!message.message_file && <CircularProgress className="circular-progress" />}
                    </div>
                    :
                    <span className="deletedMessage">This message has been deleted
                        <a style={{ paddingLeft: "10px", color: "blue", fontWeight: "bold" }} className={"cursorPointer"} onClick={() => handleUndo(message?.id)}>undo</a>
                    </span>
                }
            </>
        )
    } else {
        return (
            <>
                {!undo ?
                    <div className={`${classes.groupImgContainerMain}`} >
                        <Grid className={`${classes.groupImgContainer} groupImgContainer`} >
                            {
                                displayData.map((data, i) =>
                                    <div className="groupImgContainer_img" key={`group_media_msg_${i}`}>
                                        <img className={`${"ChatGroupImages"} ${classes.chatImage}`} alt='loadingg'
                                            src={data?.thumb ? data?.thumb : `./icons/${data.name.split('.').pop() === 'xlsx' ? 'doc' : ''}.svg`}

                                            onClick={(e) => handleSlideShowView({ mediaArray: [...media], allFiles: [message], index: i, star: message.star, previewData: Array(groupSize).fill(slidepreviewdata), data: message })} key={i} />
                                        {(i == 3 && groupSize > 4) ? (
                                            <div className="overlay groupImgOverlay" onClick={(e) => handleSlideShowView({ mediaArray: [...media], allFiles: [message], index: i, data: message, previewData: Array(groupSize).fill(slidepreviewdata) })} >+{parseInt(groupSize) - 3} More</div>
                                        ) : null}
                                    </div>
                                )
                            }
                        </Grid>
                        {message?.message_text && message?.message_text !== "\r\n" && message?.message_text !== "\n" &&
                            <Grid className={`${message?.message_text ? 'm4' : 'mt-1'}`}>
                                <div className={`${message?.star && userId == message?.star_user_id ? classes.textMsgBubbleStar : classes.textMsgBubble} spacenone ${classes.hideGroupText} disable-user-select`}>
                                    <Editor
                                        customStyleMap={styleMap}
                                        editorState={editorState}
                                        onChange={onChange}
                                        plugins={[linkifyPlugin, mentionPlugin, hashtagPlugin]}
                                        ref={editor}
                                        readOnly={true}
                                    />
                                </div>
                            </Grid>
                        }
                    </div>
                    :
                    <span className="deletedMessage">This message has been deleted
                        <a style={{ paddingLeft: "10px", color: "blue", fontWeight: "bold" }} className={"cursorPointer"} onClick={() => handleUndo(message?.id)}>undo</a>
                    </span>
                }
            </>
        )
    }
}

// Default props
GroupMediaMessage.defaultProps = {
    classes: {},
    message: null,
    handleSlideShowView: () => { }
};

// Prop types
GroupMediaMessage.propTypes = {
    classes: PropTypes.object,
    message: PropTypes.object,
    handleSlideShowView: PropTypes.func,
};

export default withStyles(styles)(GroupMediaMessage)

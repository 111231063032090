const styles = (theme) => ({
    calendar: {
        minHeight: "280px"
    },
    headerCont: {
        display: "flex",
        alignItems: 'center',
        justifyContent: "space-between",
        borderBottom: `1px solid ${theme.palette.primary.border3}`,
        height: '46px',
        paddingBottom: '10px',
        '& .MuiIconButton-root': {
            '&:hover': {
                '& svg': {
                    fill: '#2563EB'
                }
            }
        }
    },
    navigation: {
        display: "flex",
        justifyContent: "spaceBetween",
        marginBottom: "10px"
    },
    days: {
        display: "grid",
        gridTemplateColumns: "repeat(7, 1fr)",
        minHeight: "300px",
        '@media only screen and (min-width: 900px)':{
            gap: '4px'
        },
        "& .currentDayCls":{
            color:"#487BEB !important",
            fontWeight:700,
        },
    },
    day: {
        padding: "5px",
        textAlign: "center",
        cursor: "pointer",
        position: 'relative',
        height: '57px',
        width: '57px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '16px',
        fontWeight: '400',
        color:  theme.embedPage?.textColor || theme.palette.primary.main,
        '&.hasEvent': {
            border: `1px solid ${theme.embedPage?.primaryColor || "#2563EB"}`,
            borderRadius: '60px'
        },
        '@media only screen and (max-width: 768px)':{
            '&.dayField': {
                width: '40px !important',
                height: '40px !important',
                fontSize: '12px'
            }
        }
    },
    selected: {
        backgroundColor: theme.embedPage?.primaryColor || "#2563EB",
        borderRadius: '100%',
        color: '#fff !important',
        boxShadow: theme.palette.shadows.shadow1,
        fontSize: '16px',
        fontWeight: '700',
        "& .dotItem": {
            display: 'none'
        },
        '@media only screen and (max-width: 768px)':{
            '&.dayField': {
                width: '40px !important',
                height: '40px !important',
                fontSize: '12px'
            }
        }
    },
    noEvents: {
        backgroundColor: "#E4ECF3",
        borderRadius: '100%',
        color: '#0F172A !important',
    },
    blank: {
        visibility: "hidden"
    },
    disabledField: {
        color: "#94A3B8 !important"
    },
    // dot: {
    //     height: "5px",
    //     width: "5px",
    //     backgroundColor: "blue",
    //     borderRadius: "50%",
    //     display: "inline-block",
    //     bottom: '10px',
    //     position: 'absolute'
    // }
});

export default styles;

import { baseAction } from "./baseAction";
import { authConstant, alertConstant, loaderConstant, dashboardConstant, chatv2Constant } from "../constants";
import { authService } from "../services";

// Import History
import history from "../../config/history";
import { removeToken, setToken } from "../../utils/appHelper";
import { isIOS, isAndroid, isDesktop } from 'react-device-detect';
import { clientAction } from "./clientAction";
import { eventAction } from "./eventAction";
import moment from "moment";

const { request, success, failure } = baseAction;

// All login based actions
export const authAction = {
  login,
  socialLogin,
  appleLogin,
  forgetPassword,
  resetPassword,
  logout,
  socialSignUp,
  createUser,
  updateSignUpUserData,
  updatePayment,
  getProfileData,
  signUpKeyValidation,
  getUpdatedStatus,
  getProductsList,
  pageRedirect,
  getGoogleAuthUrl
};

/**
 * Request Login
 */
function login(params, urlRedirectType, onboarding = '', callback = () => { }) {
  return (dispatch) => {
    dispatch(request(authConstant.USER_LOGIN_R));
    dispatch(request(loaderConstant.LOADING_TRUE));
    authService.login(params).then((data) => {
      dispatch(request(loaderConstant.LOADING_FALSE));
      //redirect if success
      if (data.message === "success" || data.message === "Your access has been expired") {
        //send to project page
        dispatch(success(authConstant.USER_LOGIN_S, data));
        // dispatch(success(authConstant.SHOW_POPUP_LOGIN, data));
        setToken(data.data.token);
        if (data.data.access) {
          if (onboarding) {
            dispatch(clientAction.clientOnboardingUsersAdd({ link_name: onboarding,type:params.type }, (data) => {
              if (data?.data) {
                if (urlRedirectType) {
                  if (urlRedirectType === "default") {
                    history.push('/dashboard')
                  } else {
                    history.push('/settings/profile')
                  }
                }
              } else {
                dispatch(success(alertConstant.ALERT_INFO, data));
              }
            }))
          } else if (urlRedirectType) {
            if (urlRedirectType === "default") {
              history.push('/dashboard')
            } else {
              history.push('/settings/profile')
            }
          }
        } else {
          dispatch(failure(alertConstant.ALERT_ERROR, "You don't have workspaces access to continue."));
        }
      } else {
        dispatch(success(alertConstant.ALERT_INFO, data));
      }
      callback(data)
    }, (error) => {
      dispatch(request(loaderConstant.LOADING_FALSE));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(failure(authConstant.USER_LOGIN_F, error));
    }
    );
  };
}

/**
 * Social Login Action
 */
function socialLogin(params, redirectUrl = "default", onboarding = '', callback = () => { }) {
  return (dispatch) => {
    dispatch(request(authConstant.USER_LOGIN_R));
    dispatch(request(loaderConstant.LOADING_TRUE));
    authService.socialLogin(params).then((data) => {
      dispatch(request(loaderConstant.LOADING_FALSE));
      //redirect if success
      if (data.status === "success") {
        //send to project page
        dispatch(success(authConstant.USER_LOGIN_S, data));
        setToken(data.data.token);
        localStorage.setItem("staySignedIn", true);
        if (onboarding) {
          dispatch(clientAction.clientOnboardingUsersAdd({ link_name: onboarding }))
        }
        if (redirectUrl === "default") {
          history.push('/dashboard')
        } else if (redirectUrl === "profile") {
          history.push(`/settings/profile`)
        }
        else {
          history.push(`/${redirectUrl}`)
        }
      } else {
        dispatch(success(alertConstant.ALERT_INFO, data));
      }
      callback()
    }, (error) => {
      dispatch(request(loaderConstant.LOADING_FALSE));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(failure(authConstant.USER_LOGIN_F, error));
    }
    );
  };
}


/**
 * Social Login Action
 */
function appleLogin(params, onboarding = '', callback = () => { }) {
  return (dispatch) => {
    dispatch(request(authConstant.USER_LOGIN_R));
    dispatch(request(loaderConstant.LOADING_TRUE));
    authService.appleLogin(params).then((data) => {
      dispatch(request(loaderConstant.LOADING_FALSE));
      //redirect if success
      if (data.status === "success" && data.message === "success") {
        //send to project page
        dispatch(success(authConstant.USER_LOGIN_S, data));
        setToken(data.data.token);
        localStorage.setItem("staySignedIn", true);
        if (onboarding) {
          dispatch(clientAction.clientOnboardingUsersAdd({ link_name: onboarding }))
        }
        history.push('/dashboard');
      } else if (data.data && data.data.status === "email_required") {
        dispatch(success(authConstant.SOCIAL_LOGIN_EMAIL_REQ, params));
      }
      else {
        dispatch(success(alertConstant.ALERT_INFO, data));
      }
      callback()
    }, (error) => {
      dispatch(request(loaderConstant.LOADING_FALSE));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(failure(authConstant.USER_LOGIN_F, error));
    }
    );
  };
}


/**
 * Request Forgot Password
 */
function forgetPassword(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(authConstant.FORGET_PASSWORD_R, params));
    authService.forgetPassword(params).then(
      (data) => {
        dispatch(success(authConstant.FORGET_PASSWORD_S, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack()
      },
      (error) => {
        dispatch(failure(authConstant.FORGET_PASSWORD_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Request Reset Password
 */
function resetPassword(params, successCallBack = () => { }) {
  return dispatch => {
    dispatch(request(authConstant.RESET_PASSWORD_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    authService.resetPassword(params).then(
      data => {
        dispatch(success(authConstant.RESET_PASSWORD_S, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        successCallBack(data)
      },
      error => {
        dispatch(failure(authConstant.RESET_PASSWORD_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}
/**
 * Request Logout
 */
function logout() {
  return (dispatch, getState) => {
    dispatch(request(authConstant.LOGOUT_R));
    dispatch(request(loaderConstant.LOADING_TRUE));
    let userId = getState().auth.loginUser.id || getState().auth.signup.id;
    let isCustomDomain = getState().profile?.appConfig?.isCustomDomain;
    let device;
    if (isAndroid) device = "ANDROID";
    else if (isIOS) device = "IOS";
    else if (isDesktop) device = "WEB";
    const notificationToken = localStorage.getItem("notificationToken")
    const data = {
      device,
      token: notificationToken,
      user_id: userId
    }
    authService.logout(data).then((data) => {
      localStorage.removeItem("notificationToken")
      localStorage.removeItem("AF_Token_SentToServer")

      // un-register service worker when logout
      try {
        navigator.serviceWorker.getRegistrations().then(registrations => {
          for (const registration of registrations) {
            registration.unregister();
          }
        });
      } catch (err) {
        console.log("🚀 ~ authService.logout ~ err: --> service worker reg issue catch", err)
      }

      if (localStorage.getItem("emailAddress")) {
        const params = {
          email: '',
          type: 'disconnect'
        }
        dispatch(eventAction.getGoogleEmail(params))
        if (localStorage.getItem("emailtoken") && localStorage.getItem("emailtoken") !== "undefined")
          dispatch(eventAction.getAppEvents({
            dateRange: {
              start_date: moment(new Date()).format('yyyy-MM-DD'),
              end_date: moment(new Date()).format('yyyy-MM-DD')
            },
            type: 'disconnect',
            tokens: localStorage.getItem("emailtoken") ? JSON.parse(localStorage.getItem("emailtoken")) : null
          }))
      }
      localStorage.removeItem('emailAddress')
      dispatch(success(authConstant.LOGOUT_S));
      dispatch(request(loaderConstant.LOADING_FALSE));
      // Redirect to login
      history.push(`/login`);
      if (isCustomDomain) {
        window.location.reload();
      }
      removeToken()
    }, error => {
      dispatch(request(loaderConstant.LOADING_FALSE));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(failure(authConstant.LOGOUT_F, error));
    })
  };
}

/**
 * Create User by using Google & Facebook buttons in signup page 
 */
function socialSignUp(params) {
  return (dispatch) => {
    dispatch(request(authConstant.SIGNUP_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    authService.socialSignUp(params).then(data => {
      dispatch(success(authConstant.SIGNUP_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      if (data.status === "success") {
        history.push(`/signup/process`);
        localStorage.setItem("newID", data && data.data && data.data.id ? data.data.id : 0);
      }
    }, error => {
      dispatch(failure(authConstant.SIGNUP_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

//getUpdatedStatus
function getUpdatedStatus(params) {
  return (dispatch) => {
    dispatch(request(authConstant.GET_UPDATED_STATUS_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    authService.getUpdatedStatus(params).then(data => {
      dispatch(success(authConstant.GET_UPDATED_STATUS_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }, error => {
      dispatch(failure(authConstant.GET_UPDATED_STATUS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/**
 * Create User From Signup Page
 */
function createUser(params) {
  return (dispatch) => {
    dispatch(request(authConstant.SIGNUP_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    authService.signUp(params).then(data => {
      dispatch(success(authConstant.SIGNUP_S, data));
      // dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      dispatch(request(loaderConstant.LOADING_FALSE));
      if (data.message === "success") {
        localStorage.setItem("referralCode", data && data.data && data.data.referral_code ? data.data.referral_code : "");
        history.push(localStorage.getItem("referralCode") ? `/signup/process?ref=${localStorage.getItem("referralCode")}` : `/signup/process`);
        localStorage.setItem("newID", data && data.data && data.data.id ? data.data.id : 0);
      } else if (data.status === "failed") {
        dispatch(failure(alertConstant.ALERT_ERROR, data.message));
      } else {
        // nothing
      }
    }, error => {
      dispatch(failure(authConstant.SIGNUP_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/**
 * update signup user data
 * @param {*} params 
 */
function updateSignUpUserData(params) {
  return (dispatch, getState) => {
    let userId = getState().auth.signup.id || getState().auth.loginUser.id;
    userId = userId ? userId : localStorage.getItem("newID")
    dispatch(request(authConstant.UPDATE_SIGNUP_DATA_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    authService.updateSignUpUserData({ data: params, userId }).then(data => {
      dispatch(success(authConstant.UPDATE_SIGNUP_DATA_S, data));
      localStorage.setItem("newID1", data && data.data && data.data.agency_id ? data.data.agency_id : 0);
      dispatch(request(loaderConstant.LOADING_FALSE));
      history.push(`/signup/payment`);
    }, error => {
      dispatch(failure(authConstant.UPDATE_SIGNUP_DATA_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/**
 * update payment
 */
function updatePayment(params, cb = () => { }) {
  return (dispatch, getState) => {
    let userId = getState().auth.signup.id
    let agency_id = getState().auth.signup.agency_id;
    userId = userId ? userId : localStorage.getItem("newID")
    agency_id = agency_id ? agency_id : localStorage.getItem("newID1")
    dispatch(request(authConstant.UPDATE_PAYMENT_DATA_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    authService.updatePayment({ data: params, userId, agency_id }).then(data => {
      if (data.status === "success") {
        dispatch(success(authConstant.UPDATE_PAYMENT_DATA_S, data));
        // localStorage.removeItem("newID")
        // localStorage.removeItem("newID1")
        // localStorage.removeItem("referralCode")
        cb(data)
      }
      // dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }, error => {
      dispatch(failure(authConstant.UPDATE_PAYMENT_DATA_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/**
 * get profile data
 */
function getProfileData(params) {
  return (dispatch) => {
    dispatch(request(authConstant.GET_PROFILE_R));
    dispatch(request(loaderConstant.LOADING_TRUE));
    authService.getProfileData(params).then(data => {
      dispatch(success(authConstant.GET_PROFILE_S, data));
      dispatch(success(chatv2Constant.CHATV2_GET_UNSEND_MESSAGES_R, {}))
      dispatch(request(loaderConstant.LOADING_FALSE));
      if (data && data["data"]) {
        if (!data["data"]['agency_pref']) {
          history.push("/process_validate") 
        } else if (data["data"]["status"] === 3) {
          dispatch(success(authConstant.SHOW_POPUP_LOGIN, { message: "Your access has been expired" }));
        } else if (data["data"]["status"] === 2) {
          dispatch(success(authConstant.SHOW_POPUP_LOGIN, { message: "Paused Subscription" }));
        } else if (data["data"]["status"] === 0) {
          dispatch(success(authConstant.SHOW_POPUP_LOGIN, { message: "Update Payment" }));
        } else {
          dispatch(success(authConstant.SHOW_POPUP_LOGIN, { message: "" }));
        }
        dispatch(success(dashboardConstant.UPDATE_REM_TEXT, data["data"]["rText"]));
      }
    }, error => {
      dispatch(failure(authConstant.GET_PROFILE_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/**
 * get profile data
 */
function signUpKeyValidation(parms, cb = () => { }) {
  return (dispatch) => {
    authService.signUpKeyValidation(parms).then(data => {
      if (data && data["data"] && data["data"]["isValid"] !== true) {
        dispatch(failure(alertConstant.ALERT_ERROR, "Invalid Invite URL"));
        history.push("/signup")
      }
      cb()
    }, error => {
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    });
  };
}

function getProductsList(params) {
  return (dispatch) => {
    dispatch(request(authConstant.GET_PRODUCTS_LIST_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    authService.getProductsList(params).then(data => {
      dispatch(success(authConstant.GET_PRODUCTS_LIST_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }, error => {
      dispatch(failure(authConstant.GET_PRODUCTS_LIST_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

function pageRedirect(params, cb = () => { }) {
  return () => {
    if (params && params.redirectUrl) {
      history.push(params.redirectUrl)
    } else {
      cb(params)
    }
  };
}

function getGoogleAuthUrl(params, callback = () => { }) {
  return (dispatch) => {
    dispatch(request(authConstant.GET_GOOGLE_AUTH_URL_R));
    authService.getGoogleAuthUrl(params).then(data => {
      dispatch(success(authConstant.GET_GOOGLE_AUTH_URL_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callback(data)
    }, error => {
      dispatch(failure(authConstant.GET_GOOGLE_AUTH_URL_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    });
  };
}

import React from 'react';
import { withStyles } from "@material-ui/core";

//Import Styles
import styles from "./styles";

//Import Audio Player Component
import { AudioPlayer } from "../AudioPlayer"
import { isAndroid, isIOS } from 'react-device-detect';
import Player from '../AudioPlayer/AudioPlayer';
// import Player from '../AudioPlayer/AudioPlayer';

const VoiceNoteMessage = (props) => {
    const { message, handleUndo, undo } = props;
    const media = message.message_file ? JSON.parse(message.message_file) : null;
    // if (isIOS || isAndroid) {
    //     // return <Player id={JSON.stringify(props)} src={media.url}></Player>
    //     return (
    //         <>
    //             {!undo ?
    //             <Player id={JSON.stringify(props)} src={media.url}></Player>
    //               :
    //                 <span>This message has been deleted
    //                     <a style={{ paddingLeft: "10px", color: "blue", fontWeight: "bold" }} className={"cursorPointer"} onClick={() => handleUndo(message.id)}>undo</a>
    //                 </span>
    //             }
    //         </>
    //     );
    // }
    // else {
        return (
            <>
                {!undo ? (isIOS || isAndroid) ? <AudioPlayer audioFile={media.url} {...props} /> :
                    <AudioPlayer audioFile={media.url} {...props} />
                    :
                    <span className="deletedMessage">This message has been deleted
                        <a style={{ paddingLeft: "10px", color: "blue", fontWeight: "bold" }} className={"cursorPointer"} onClick={() => handleUndo(message.id)}>undo</a>
                    </span>
                }
            </>
        )
    // }
}

export default withStyles(styles)(VoiceNoteMessage)
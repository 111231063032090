import "../../../node_modules/react-image-gallery/styles/css/image-gallery.css";
// import theme from "../../assets/theme";
const styles = (theme) => ({
    slideShow: {
        display: "flex",
        flexDirection: "column",
        "& .MuiAppBar-root": {
            color: "#fff",
            // backgroundColor: "#ffffffeb",
            boxShadow: "none",
        },
        "& .MuiPaper-rounded": {
            borderRadius: '0px !important',
        },
        '& .MuiDialog-container': {
            background: theme.palette.containers.card,
        }
    },
    appBar: {
        display: "flex",
        height: "60px",
        background: theme.palette.containers.card,

        '& .MuiToolbar-gutters': {
            paddingTop: '10px',
            paddingLeft: '10px',
            paddingRight: '5px'
        },
        '& .MuiSvgIcon-root': {
            fill: '#64748B',
        },
        '& .MuiIconButton-root': {
            padding: 5,
            height: '32px',
            width: '32px !important',
            '& #myChickooo': {
                '& svg': {
                    height: '18px',
                    '& path': {
                        stroke: '#64748B'
                    }
                }
            }
        },
        '& .chatTileStarMenu': {
            visibility: 'visible',
            // padding: '3px',
            marginTop: "-2px"
        },
        '& .noStarMenu': {
            display: 'none',
        },
        '& .headerAction': {
            '& .closeIcon': {
                height: '20px'
            },
            '@media only screen and (max-width: 768px)': {
                '& .mr5': {
                    merginRight: '0px !important'
                }
            }
        },
        '& .headerLeft': {
            maxWidth: 'calc(100% - 108px)',
            '& .headerTextMobile': {
                maxWidth: 'calc(100% - 92px)'
            },
            '& .headerText': {
                maxWidth: 'calc(100% - 10px)'
            }
        },
        '@media only screen and (max-width: 768px)': {
            '& .MuiAvatar-root, & .avatarDiv': {
                height: '30px !important',
                width: '30px !important'
            },
        }
    },
    sliderSection: {
        width: "100%",
        height: "100%",
        display: "block",
        overflow: "hidden",
        // background: '#fffffff7',
        background: `${theme.palette.containers.card}f7`,
        '& .alice-carousel, .alice-carousel>div .alice-carousel__wrapper': {
            height: 'calc(100% - 0px) !important'
        },
        "& .alice-carousel": {
            '&>div': {
                height: 'calc(100% - 120px) !important'
            },
            "& .alice-carousel__stage-item": {
                // height: "750px",
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: "center"
            },
            "& .alice-carousel__dots": {
                display: "flex",
                width: "100%",
                alignItems: "center",
                // justifyContent: "center",
                margin: '0px',
                padding: 10,
                background: theme.palette.containers.card,
                overflowX: 'auto',
                "& li": {
                    cursor: "pointer",
                    margin: "2px",
                    padding: "2.5px",
                    border: "1px solid transparent",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexShrink: 0,
                    width: 'auto',
                    height: '80px',
                    "& span": {
                        display: "flex",
                        alignItems: "center",
                        width: 80,
                    }
                },
                "& li.__active": {
                    border: "1px solid #fff",
                    background: "#fff",
                    '& img': {
                        width: '80px',
                        height: '72px',
                        border: '4px solid #000000b3',
                        borderRadius: '7px',
                        boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.25)'
                    },
                    '& div': {
                        width: '80px',
                        height: '72px',
                        border: '4px solid #000000b3',
                        borderRadius: '7px',
                        boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.25)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }

                },

                "& img": {
                    width: "80px",
                    height: "80px",
                    objectFit: "cover",
                    border: '4px solid #CBD5E1',
                    borderRadius: '6px',
                    flexShrink: 0
                },

            },
            "& .alice-carousel__prev-btn": {
                position: "absolute",
                top: "40%",
                left: "0",
                color: "#fff",
                fontSize: "4rem",
                textAlign: "left",
                width: "5%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "20px !important",
                cursor: "pointer",
                "& .MuiSvgIcon-root": {
                    fontSize: "2rem",
                    fill: '#64748B',
                    background: '#E4E4E4',
                    borderRadius: 30,
                    padding: '5px',
                    width: '40px',
                    height: '40px'
                }
            },
            "& .alice-carousel__next-btn": {
                position: "absolute",
                top: "40%",
                right: "0",
                color: "#fff",
                fontSize: "4rem",
                textAlign: "left",
                width: "5%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "20px !important",
                cursor: "pointer",
                "& .MuiSvgIcon-root": {
                    fontSize: "2rem",
                    fill: '#64748B',
                    background: '#E4E4E4',
                    borderRadius: 30,
                    padding: '5px',
                    width: '40px',
                    height: '40px'
                }
            },
            "& .alice-carousel__play-btn": {
                color: "#fff",
                "& .MuiSvgIcon-root": {
                    fontSize: "3rem"
                }
            },
            "& .alice-carousel__slide-info": {
                background: "transparent",
                color: "#ccc",
                border: "2px solid #ccc",
                fontSize: "1rem",
                padding: '2px 7px',
                display: 'none'
            },
            '@media only screen and (max-width: 768px)': {
                '& .alice-carousel__dots': {
                    display: 'none'
                },
                '& .alice-carousel__prev-btn': {
                    display: 'none'
                },
                '& .alice-carousel__next-btn': {
                    display: 'none'
                },
                '&>div': {
                    height: 'calc(100% - 0px) !important'
                },
            }
        },
    },
    sliderSectionCenter: {
        "& .alice-carousel__dots": {
            justifyContent: "center"
        }
    },
    sliderSectionBase: {
        "& .alice-carousel__dots": {
            justifyContent: "none"
        }
    },



    prevpopupContainer: {
        '& .MuiListItem-root': {
            minHeight: '34px'
        },
        '& .MuiPaper-root': {
            paddingTop: '8px',
            paddingBottom: '8px',
        }
    },
    emojipopupmobile: {
        '@media only screen and (max-width: 599px)': {
            top: '130px !important'
        }
    },
    slideItem: {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        '@media only screen and (max-width: 899px)': {
            '& > div > div': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }
        }
    },
    itemContainer: {
        width: "75%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& img": {
            // width: "auto",
            // height: "auto",
            maxHeight: "100%",
            maxWidth: "100%",
            objectFit: 'contain',
            boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.25)',
            borderRadius: '11px'
        },
        "& video": {
            width: "auto",
            height: "auto",
            maxHeight: "100%",
            maxWidth: "100%",
        },
        '@media only screen and (max-width: 899px)': {
            '&.itemContainer': {
                width: "100%",
                '& img': {
                    height: "auto",
                    width: "100%",
                    objectFit: 'fill',
                    borderRadius: '0px'
                },
                '& >div >div': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }
            }
        }
    },
    sliderThumb: {
        "& img": {
            width: "50px",
            height: "50px",
            objectFit: "cover"
        }
    },
    zoomableImage: {
        transition: 'transform 0.3s ease-in-out',
        cursor: 'zoom-in'
    },
    zoomed: {
        transform: 'scale(2)'
    },
    ImageContainer: {
        overflow: "hidden",
        position: "relative"
    },
    reaction: {
        position: "absolute",
        bottom: "0",
        width: '100%',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    previewContainer: {
        width: '100%',
        height: '85%'
    }

})
export default styles
import { CustommeetIcon, FlozyLogo, GmailIcon, GooglemeetIcon, OutlookIcon, ZoomAppIconSmall } from "../assets/svg"

import { EventOnetoOneIcon } from "../assets/svg"

export const homePageContent = {
    welcomeText: "Hi, Hello Welcome!"
}

export const userPageContent = {
    userList: "User List "
}

export const DEFAULT_FILTERS = [
    {"key": "Active", "value":"Active"},
    {"key": "Completed", "value":"Completed"},
    {"key": "Deleted", "value":"Deleted"},
]
export const CLIENT_FILTERS = [
    {"key": "status", "value":"Status"},
    {"key": "tags", "value":"Tags"}
]
export const STATUS = "status";

export const TAGS = "tags";

export const infoBarsContent = {
    projectScale: `Want to join the 8 week Live Project Scale Mentorship?`,
    expiry: "Your agency plan has expired. Kindly pay your invoice as soon as possible to continue enjoying our services."
}

export const infoBarsLinks = {
    projectScale: "https://www.agenciflow.com/project-scale"
}

export const signupPageConstants = {
    FORGOT_PASSWORD: "Forgotton Your Password ?",
    FORGOT_PASSWORD_DESCRIPTION: "Type in your email address below and we will send you reset instructions",
    EMAIL_ADDRESS: "Email address",
    RESET_INSTRUCTION_BUTTON: "Send Reset Instruction"
}

export const loginPageConstants = {
    GOOGLE_LOGIN_BUTTON_TEXT: "Google",
    FACEBOOK_LOGIN_BUTTON_TEXT: "Facebook",
    GOOGLE_SIGNUP_BUTTON_TEXT: "Google",
    FACEBOOK_SIGNUP_BUTTON_TEXT: "Facebook",
}

export const taskNotesConstants = {
    MAX_FILE_SIZE: 2,
    SIZE_ALERT: "File size should be less than 2mb",
}

export const emailDefaultConstants = {
    INVITE_MAIL_DEFAULT_MESSAGES: `<div>Hello!</div>
                                    <div><br></div>
                                    <div>I'd like to invite you to  join <b>[Agency_Name]</b> to keep track of the work we do together.</div>
                                    <div><br></div>
                                    <div>Using this secure online system, you’ll keep track of our work, collaborate with our team, and stay up to date with [Agency_Name]! It gives a real-time view and is a great way to remain in sync.</div>
                                    <div><br></div>
                                    <div>Please click on the link below to access your account now.</div>
                                    <div><br></div>
                                    <div>Looking forward to working together! 🙂</div>
                                    <div><br></div>
                                    <div>Best,</div>
                                    <div>[Agency_Name] team</div>`,
}

export const projectsPageConstants = {
    createProjects: {
        PAGE_TITLE_TEXT: "CREATE FLOW",
        PAGE_SUB_TITLE_TEXT: "Create a new flow for you and your team",
        FORM_TITLE_TEXT: "Basic Info",
        FORM_SUB_TITLE_TEXT: "About your flow",
        PROJECT_TITLE_TEXT: "Title",
        PROJECT_SUB_TITLE_TEXT: "Give the flow a catchy title",
        PROJECT_TITLE_PLACE_HOLDER: "Type it here...",
        PROJECT_BRIEF_TEXT: "Brief",
        PROJECT_BRIEF_SUB_TEXT: "Write a brief explaining what the flow is about",
        PROJECT_BRIEF_PLACE_HOLDER: "Type it here...",
        PROJECT_MEDIA_DELETE_DIALOG_TEXT: "Do you want to delete this file"
    }
}

export const chatPageConstants = {
    MESSAGE_LIMIT_COUNT: 20,
    SELECT_FILES_ALERT: "Please select some files first"
}

export const invoicePageConstant = {
    MAX_FILE_SIZE: 2,
    SIZE_ALERT: "File size should be less than 2mb",
}

export const iconSetArray = ["ai", "android", "apk", "css", "disc", "doc", "docs", "docx", "excel", "font", "iso", "js", "mp3", "mp4", "pdf", "php", "play", "ppt", "psd", "sql", "svg", "ttf", "txt", "vcf", "xls", "zip"]


export const countryList = [
    { label: 'United States', value: 'US' },
    { label: 'United Kingdom', value: 'GB' },
    { label: 'Canada', value: 'CA' },
    { label: 'Germany', value: 'DE' },
    { label: 'Australia', value: 'AU' },
    { label: 'Afghanistan', value: 'AF' },
    { label: 'Åland Islands', value: 'AX' },
    { label: 'Albania', value: 'AL' },
    { label: 'Algeria', value: 'DZ' },
    { label: 'American Samoa', value: 'AS' },
    { label: 'AndorrA', value: 'AD' },
    { label: 'Angola', value: 'AO' },
    { label: 'Anguilla', value: 'AI' },
    { label: 'Antarctica', value: 'AQ' },
    { label: 'Antigua and Barbuda', value: 'AG' },
    { label: 'Argentina', value: 'AR' },
    { label: 'Armenia', value: 'AM' },
    { label: 'Aruba', value: 'AW' },
    { label: 'Austria', value: 'AT' },
    { label: 'Azerbaijan', value: 'AZ' },
    { label: 'Bahamas', value: 'BS' },
    { label: 'Bahrain', value: 'BH' },
    { label: 'Bangladesh', value: 'BD' },
    { label: 'Barbados', value: 'BB' },
    { label: 'Belarus', value: 'BY' },
    { label: 'Belgium', value: 'BE' },
    { label: 'Belize', value: 'BZ' },
    { label: 'Benin', value: 'BJ' },
    { label: 'Bermuda', value: 'BM' },
    { label: 'Bhutan', value: 'BT' },
    { label: 'Bolivia', value: 'BO' },
    { label: 'Bosnia and Herzegovina', value: 'BA' },
    { label: 'Botswana', value: 'BW' },
    { label: 'Bouvet Island', value: 'BV' },
    { label: 'Brazil', value: 'BR' },
    { label: 'British Indian Ocean Territory', value: 'IO' },
    { label: 'Brunei Darussalam', value: 'BN' },
    { label: 'Bulgaria', value: 'BG' },
    { label: 'Burkina Faso', value: 'BF' },
    { label: 'Burundi', value: 'BI' },
    { label: 'Cambodia', value: 'KH' },
    { label: 'Cameroon', value: 'CM' },
    { label: 'Cape Verde', value: 'CV' },
    { label: 'Cayman Islands', value: 'KY' },
    { label: 'Central African Republic', value: 'CF' },
    { label: 'Chad', value: 'TD' },
    { label: 'Chile', value: 'CL' },
    { label: 'China', value: 'CN' },
    { label: 'Christmas Island', value: 'CX' },
    { label: 'Cocos (Keeling) Islands', value: 'CC' },
    { label: 'Colombia', value: 'CO' },
    { label: 'Comoros', value: 'KM' },
    { label: 'Congo', value: 'CG' },
    { label: 'Congo, The Democratic Republic of the', value: 'CD' },
    { label: 'Cook Islands', value: 'CK' },
    { label: 'Costa Rica', value: 'CR' },
    { label: 'Cote D\'Ivoire', value: 'CI' },
    { label: 'Croatia', value: 'HR' },
    { label: 'Cuba', value: 'CU' },
    { label: 'Cyprus', value: 'CY' },
    { label: 'Czech Republic', value: 'CZ' },
    { label: 'Denmark', value: 'DK' },
    { label: 'Djibouti', value: 'DJ' },
    { label: 'Dominica', value: 'DM' },
    { label: 'Dominican Republic', value: 'DO' },
    { label: 'Ecuador', value: 'EC' },
    { label: 'Egypt', value: 'EG' },
    { label: 'El Salvador', value: 'SV' },
    { label: 'Equatorial Guinea', value: 'GQ' },
    { label: 'Eritrea', value: 'ER' },
    { label: 'Estonia', value: 'EE' },
    { label: 'Ethiopia', value: 'ET' },
    { label: 'Falkland Islands (Malvinas)', value: 'FK' },
    { label: 'Faroe Islands', value: 'FO' },
    { label: 'Fiji', value: 'FJ' },
    { label: 'Finland', value: 'FI' },
    { label: 'France', value: 'FR' },
    { label: 'French Guiana', value: 'GF' },
    { label: 'French Polynesia', value: 'PF' },
    { label: 'French Southern Territories', value: 'TF' },
    { label: 'Gabon', value: 'GA' },
    { label: 'Gambia', value: 'GM' },
    { label: 'Georgia', value: 'GE' },
    { label: 'Ghana', value: 'GH' },
    { label: 'Gibraltar', value: 'GI' },
    { label: 'Greece', value: 'GR' },
    { label: 'Greenland', value: 'GL' },
    { label: 'Grenada', value: 'GD' },
    { label: 'Guadeloupe', value: 'GP' },
    { label: 'Guam', value: 'GU' },
    { label: 'Guatemala', value: 'GT' },
    { label: 'Guernsey', value: 'GG' },
    { label: 'Guinea', value: 'GN' },
    { label: 'Guinea-Bissau', value: 'GW' },
    { label: 'Guyana', value: 'GY' },
    { label: 'Haiti', value: 'HT' },
    { label: 'Heard Island and Mcdonald Islands', value: 'HM' },
    { label: 'Holy See (Vatican City State)', value: 'VA' },
    { label: 'Honduras', value: 'HN' },
    { label: 'Hong Kong', value: 'HK' },
    { label: 'Hungary', value: 'HU' },
    { label: 'Iceland', value: 'IS' },
    { label: 'India', value: 'IN' },
    { label: 'Indonesia', value: 'ID' },
    { label: 'Iran, Islamic Republic Of', value: 'IR' },
    { label: 'Iraq', value: 'IQ' },
    { label: 'Ireland', value: 'IE' },
    { label: 'Isle of Man', value: 'IM' },
    { label: 'Israel', value: 'IL' },
    { label: 'Italy', value: 'IT' },
    { label: 'Jamaica', value: 'JM' },
    { label: 'Japan', value: 'JP' },
    { label: 'Jersey', value: 'JE' },
    { label: 'Jordan', value: 'JO' },
    { label: 'Kazakhstan', value: 'KZ' },
    { label: 'Kenya', value: 'KE' },
    { label: 'Kiribati', value: 'KI' },
    { label: 'Korea, Democratic People\'S Republic of', value: 'KP' },
    { label: 'Korea, Republic of', value: 'KR' },
    { label: 'Kuwait', value: 'KW' },
    { label: 'Kyrgyzstan', value: 'KG' },
    { label: 'Lao People\'S Democratic Republic', value: 'LA' },
    { label: 'Latvia', value: 'LV' },
    { label: 'Lebanon', value: 'LB' },
    { label: 'Lesotho', value: 'LS' },
    { label: 'Liberia', value: 'LR' },
    { label: 'Libyan Arab Jamahiriya', value: 'LY' },
    { label: 'Liechtenstein', value: 'LI' },
    { label: 'Lithuania', value: 'LT' },
    { label: 'Luxembourg', value: 'LU' },
    { label: 'Macao', value: 'MO' },
    { label: 'Macedonia, The Former Yugoslav Republic of', value: 'MK' },
    { label: 'Madagascar', value: 'MG' },
    { label: 'Malawi', value: 'MW' },
    { label: 'Malaysia', value: 'MY' },
    { label: 'Maldives', value: 'MV' },
    { label: 'Mali', value: 'ML' },
    { label: 'Malta', value: 'MT' },
    { label: 'Marshall Islands', value: 'MH' },
    { label: 'Martinique', value: 'MQ' },
    { label: 'Mauritania', value: 'MR' },
    { label: 'Mauritius', value: 'MU' },
    { label: 'Mayotte', value: 'YT' },
    { label: 'Mexico', value: 'MX' },
    { label: 'Micronesia, Federated States of', value: 'FM' },
    { label: 'Moldova, Republic of', value: 'MD' },
    { label: 'Monaco', value: 'MC' },
    { label: 'Mongolia', value: 'MN' },
    { label: 'Montserrat', value: 'MS' },
    { label: 'Morocco', value: 'MA' },
    { label: 'Mozambique', value: 'MZ' },
    { label: 'Myanmar', value: 'MM' },
    { label: 'Namibia', value: 'NA' },
    { label: 'Nauru', value: 'NR' },
    { label: 'Nepal', value: 'NP' },
    { label: 'Netherlands', value: 'NL' },
    { label: 'Netherlands Antilles', value: 'AN' },
    { label: 'New Caledonia', value: 'NC' },
    { label: 'New Zealand', value: 'NZ' },
    { label: 'Nicaragua', value: 'NI' },
    { label: 'Niger', value: 'NE' },
    { label: 'Nigeria', value: 'NG' },
    { label: 'Niue', value: 'NU' },
    { label: 'Norfolk Island', value: 'NF' },
    { label: 'Northern Mariana Islands', value: 'MP' },
    { label: 'Norway', value: 'NO' },
    { label: 'Oman', value: 'OM' },
    { label: 'Pakistan', value: 'PK' },
    { label: 'Palau', value: 'PW' },
    { label: 'Palestinian Territory, Occupied', value: 'PS' },
    { label: 'Panama', value: 'PA' },
    { label: 'Papua New Guinea', value: 'PG' },
    { label: 'Paraguay', value: 'PY' },
    { label: 'Peru', value: 'PE' },
    { label: 'Philippines', value: 'PH' },
    { label: 'Pitcairn', value: 'PN' },
    { label: 'Poland', value: 'PL' },
    { label: 'Portugal', value: 'PT' },
    { label: 'Puerto Rico', value: 'PR' },
    { label: 'Qatar', value: 'QA' },
    { label: 'Reunion', value: 'RE' },
    { label: 'Romania', value: 'RO' },
    { label: 'Russian Federation', value: 'RU' },
    { label: 'RWANDA', value: 'RW' },
    { label: 'Saint Helena', value: 'SH' },
    { label: 'Saint Kitts and Nevis', value: 'KN' },
    { label: 'Saint Lucia', value: 'LC' },
    { label: 'Saint Pierre and Miquelon', value: 'PM' },
    { label: 'Saint Vincent and the Grenadines', value: 'VC' },
    { label: 'Samoa', value: 'WS' },
    { label: 'San Marino', value: 'SM' },
    { label: 'Sao Tome and Principe', value: 'ST' },
    { label: 'Saudi Arabia', value: 'SA' },
    { label: 'Senegal', value: 'SN' },
    { label: 'Serbia and Montenegro', value: 'CS' },
    { label: 'Seychelles', value: 'SC' },
    { label: 'Sierra Leone', value: 'SL' },
    { label: 'Singapore', value: 'SG' },
    { label: 'Slovakia', value: 'SK' },
    { label: 'Slovenia', value: 'SI' },
    { label: 'Solomon Islands', value: 'SB' },
    { label: 'Somalia', value: 'SO' },
    { label: 'South Africa', value: 'ZA' },
    { label: 'South Georgia and the South Sandwich Islands', value: 'GS' },
    { label: 'Spain', value: 'ES' },
    { label: 'Sri Lanka', value: 'LK' },
    { label: 'Sudan', value: 'SD' },
    { label: 'Suriname', value: 'SR' },
    { label: 'Svalbard and Jan Mayen', value: 'SJ' },
    { label: 'Swaziland', value: 'SZ' },
    { label: 'Sweden', value: 'SE' },
    { label: 'Switzerland', value: 'CH' },
    { label: 'Syrian Arab Republic', value: 'SY' },
    { label: 'Taiwan, Province of China', value: 'TW' },
    { label: 'Tajikistan', value: 'TJ' },
    { label: 'Tanzania, United Republic of', value: 'TZ' },
    { label: 'Thailand', value: 'TH' },
    { label: 'Timor-Leste', value: 'TL' },
    { label: 'Togo', value: 'TG' },
    { label: 'Tokelau', value: 'TK' },
    { label: 'Tonga', value: 'TO' },
    { label: 'Trinidad and Tobago', value: 'TT' },
    { label: 'Tunisia', value: 'TN' },
    { label: 'Turkey', value: 'TR' },
    { label: 'Turkmenistan', value: 'TM' },
    { label: 'Turks and Caicos Islands', value: 'TC' },
    { label: 'Tuvalu', value: 'TV' },
    { label: 'Uganda', value: 'UG' },
    { label: 'Ukraine', value: 'UA' },
    { label: 'United Arab Emirates', value: 'AE' },
    { label: 'United States Minor Outlying Islands', value: 'UM' },
    { label: 'Uruguay', value: 'UY' },
    { label: 'Uzbekistan', value: 'UZ' },
    { label: 'Vanuatu', value: 'VU' },
    { label: 'Venezuela', value: 'VE' },
    { label: 'Viet Nam', value: 'VN' },
    { label: 'Virgin Islands, British', value: 'VG' },
    { label: 'Virgin Islands, U.S.', value: 'VI' },
    { label: 'Wallis and Futuna', value: 'WF' },
    { label: 'Western Sahara', value: 'EH' },
    { label: 'Yemen', value: 'YE' },
    { label: 'Zambia', value: 'ZM' },
    { label: 'Zimbabwe', value: 'ZW' }
];

export const currencyList = [
    {
        label: "US Dollar",
        value: "USD",
        symbol: "$"
    },
    {
        label: "Canadian Dollar",
        value: "CAD",
        symbol: "$"
    },
    {
        label: "Euro",
        value: "EUR",
        symbol: "€"
    },
    {
        label: "United Arab Emirates Dirham",
        value: "AED",
        symbol: "د.إ.‏"
    },
    {
        label: "Afghan Afghani",
        value: "AFN",
        symbol: "؋"
    },
    {
        label: "Albanian Lek",
        value: "ALL",
        symbol: "Lek"
    },
    {
        label: "Armenian Dram",
        value: "AMD",
        symbol: "դր."
    },
    {
        label: "Argentine Peso",
        value: "ARS",
        symbol: "$"
    },
    {
        label: "Australian Dollar",
        value: "AUD",
        symbol: "$"
    },
    {
        label: "Azerbaijani Manat",
        value: "AZN",
        symbol: "ман."
    },
    {
        label: "Bosnia-Herzegovina Convertible Mark",
        value: "BAM",
        symbol: "KM"
    },
    {
        label: "Bangladeshi Taka",
        value: "BDT",
        symbol: "৳"
    },
    {
        label: "Bulgarian Lev",
        value: "BGN",
        symbol: "лв."
    },
    {
        label: "Bahraini Dinar",
        value: "BHD",
        symbol: "د.ب.‏"
    },
    {
        label: "Burundian Franc",
        value: "BIF",
        symbol: "FBu"
    },
    {
        label: "Brunei Dollar",
        value: "BND",
        symbol: "$"
    },
    {
        label: "Bolivian Boliviano",
        value: "BOB",
        symbol: "Bs"
    },
    {
        label: "Brazilian Real",
        value: "BRL",
        symbol: "R$"
    },
    {
        label: "Botswanan Pula",
        value: "BWP",
        symbol: "P"
    },
    {
        label: "Belarusian Ruble",
        value: "BYR",
        symbol: "BYR"
    },
    {
        label: "Belize Dollar",
        value: "BZD",
        symbol: "$"
    },
    {
        label: "Congolese Franc",
        value: "CDF",
        symbol: "FrCD"
    },
    {
        label: "Swiss Franc",
        value: "CHF",
        symbol: "CHF"
    },
    {
        label: "Chilean Peso",
        value: "CLP",
        symbol: "$"
    },
    {
        label: "Chinese Yuan",
        value: "CNY",
        symbol: "CN¥"
    },
    {
        label: "Colombian Peso",
        value: "COP",
        symbol: "$"
    },
    {
        label: "Costa Rican Colón",
        value: "CRC",
        symbol: "₡"
    },
    {
        label: "Cape Verdean Escudo",
        value: "CVE",
        symbol: "CV$"
    },
    {
        label: "Czech Republic Koruna",
        value: "CZK",
        symbol: "Kč"
    },
    {
        label: "Djiboutian Franc",
        value: "DJF",
        symbol: "Fdj"
    },
    {
        label: "Danish Krone",
        value: "DKK",
        symbol: "kr"
    },
    {
        label: "Dominican Peso",
        value: "DOP",
        symbol: "RD$"
    },
    {
        label: "Algerian Dinar",
        value: "DZD",
        symbol: "د.ج.‏"
    },
    {
        label: "Estonian Kroon",
        value: "EEK",
        symbol: "kr"
    },
    {
        label: "Egyptian Pound",
        value: "EGP",
        symbol: "ج.م.‏"
    },
    {
        label: "Eritrean Nakfa",
        value: "ERN",
        symbol: "Nfk"
    },
    {
        label: "Ethiopian Birr",
        value: "ETB",
        symbol: "Br"
    },
    {
        label: "British Pound Sterling",
        value: "GBP",
        symbol: "£"
    },
    {
        label: "Georgian Lari",
        value: "GEL",
        symbol: "GEL"
    },
    {
        label: "Ghanaian Cedi",
        value: "GHS",
        symbol: "GH₵"
    },
    {
        label: "Guinean Franc",
        value: "GNF",
        symbol: "FG"
    },
    {
        label: "Guatemalan Quetzal",
        value: "GTQ",
        symbol: "Q"
    },
    {
        label: "Hong Kong Dollar",
        value: "HKD",
        symbol: "$"
    },
    {
        label: "Honduran Lempira",
        value: "HNL",
        symbol: "L"
    },
    {
        label: "Croatian Kuna",
        value: "HRK",
        symbol: "kn"
    },
    {
        label: "Hungarian Forint",
        value: "HUF",
        symbol: "Ft"
    },
    {
        label: "Indonesian Rupiah",
        value: "IDR",
        symbol: "Rp"
    },
    {
        label: "Israeli New Sheqel",
        value: "ILS",
        symbol: "₪"
    },
    {
        label: "Indian Rupee",
        value: "INR",
        symbol: "₹"
    },
    {
        label: "Iraqi Dinar",
        value: "IQD",
        symbol: "د.ع.‏"
    },
    {
        label: "Iranian Rial",
        value: "IRR",
        symbol: "﷼"
    },
    {
        label: "Icelandic Króna",
        value: "ISK",
        symbol: "kr"
    },
    {
        label: "Jamaican Dollar",
        value: "JMD",
        symbol: "$"
    },
    {
        label: "Jordanian Dinar",
        value: "JOD",
        symbol: "د.أ.‏"
    },
    {
        label: "Japanese Yen",
        value: "JPY",
        symbol: "￥"
    },
    {
        label: "Kenyan Shilling",
        value: "KES",
        symbol: "Ksh"
    },
    {
        label: "Cambodian Riel",
        value: "KHR",
        symbol: "៛"
    },
    {
        label: "Comorian Franc",
        value: "KMF",
        symbol: "FC"
    },
    {
        label: "South Korean Won",
        value: "KRW",
        symbol: "₩"
    },
    {
        label: "Kuwaiti Dinar",
        value: "KWD",
        symbol: "د.ك.‏"
    },
    {
        label: "Kazakhstani Tenge",
        value: "KZT",
        symbol: "тңг."
    },
    {
        label: "Lebanese Pound",
        value: "LBP",
        symbol: "ل.ل.‏"
    },
    {
        label: "Sri Lankan Rupee",
        value: "LKR",
        symbol: "SL Re"
    },
    {
        label: "Lithuanian Litas",
        value: "LTL",
        symbol: "Lt"
    },
    {
        label: "Latvian Lats",
        value: "LVL",
        symbol: "Ls"
    },
    {
        label: "Libyan Dinar",
        value: "LYD",
        symbol: "د.ل.‏"
    },
    {
        label: "Moroccan Dirham",
        value: "MAD",
        symbol: "د.م.‏"
    },
    {
        label: "Moldovan Leu",
        value: "MDL",
        symbol: "MDL"
    },
    {
        label: "Malagasy Ariary",
        value: "MGA",
        symbol: "MGA"
    },
    {
        label: "Macedonian Denar",
        value: "MKD",
        symbol: "MKD"
    },
    {
        label: "Myanma Kyat",
        value: "MMK",
        symbol: "K"
    },
    {
        label: "Macanese Pataca",
        value: "MOP",
        symbol: "MOP$"
    },
    {
        label: "Mauritian Rupee",
        value: "MUR",
        symbol: "MURs"
    },
    {
        label: "Mexican Peso",
        value: "MXN",
        symbol: "$"
    },
    {
        label: "Malaysian Ringgit",
        value: "MYR",
        symbol: "RM"
    },
    {
        label: "Mozambican Metical",
        value: "MZN",
        symbol: "MTn"
    },
    {
        label: "Namibian Dollar",
        value: "NAD",
        symbol: "N$"
    },
    {
        label: "Nigerian Naira",
        value: "NGN",
        symbol: "₦"
    },
    {
        label: "Nicaraguan Córdoba",
        value: "NIO",
        symbol: "C$"
    },
    {
        label: "Norwegian Krone",
        value: "NOK",
        symbol: "kr"
    },
    {
        label: "Nepalese Rupee",
        value: "NPR",
        symbol: "नेरू"
    },
    {
        label: "New Zealand Dollar",
        value: "NZD",
        symbol: "$"
    },
    {
        label: "Omani Rial",
        value: "OMR",
        symbol: "ر.ع.‏"
    },
    {
        label: "Panamanian Balboa",
        value: "PAB",
        symbol: "B/."
    },
    {
        label: "Peruvian Nuevo Sol",
        value: "PEN",
        symbol: "S/."
    },
    {
        label: "Philippine Peso",
        value: "PHP",
        symbol: "₱"
    },
    {
        label: "Pakistani Rupee",
        value: "PKR",
        symbol: "₨"
    },
    {
        label: "Polish Zloty",
        value: "PLN",
        symbol: "zł"
    },
    {
        label: "Paraguayan Guarani",
        value: "PYG",
        symbol: "₲"
    },
    {
        label: "Qatari Rial",
        value: "QAR",
        symbol: "ر.ق.‏"
    },
    {
        label: "Romanian Leu",
        value: "RON",
        symbol: "RON"
    },
    {
        label: "Serbian Dinar",
        value: "RSD",
        symbol: "дин."
    },
    {
        label: "Russian Ruble",
        value: "RUB",
        symbol: "руб."
    },
    {
        label: "Rwandan Franc",
        value: "RWF",
        symbol: "FR"
    },
    {
        label: "Saudi Riyal",
        value: "SAR",
        symbol: "ر.س.‏"
    },
    {
        label: "Sudanese Pound",
        value: "SDG",
        symbol: "SDG"
    },
    {
        label: "Swedish Krona",
        value: "SEK",
        symbol: "kr"
    },
    {
        label: "Singapore Dollar",
        value: "SGD",
        symbol: "$"
    },
    {
        label: "Somali Shilling",
        value: "SOS",
        symbol: "Ssh"
    },
    {
        label: "Syrian Pound",
        value: "SYP",
        symbol: "ل.س.‏"
    },
    {
        label: "Thai Baht",
        value: "THB",
        symbol: "฿"
    },
    {
        label: "Tunisian Dinar",
        value: "TND",
        symbol: "د.ت.‏"
    },
    {
        label: "Tongan Paʻanga",
        value: "TOP",
        symbol: "T$"
    },
    {
        label: "Turkish Lira",
        value: "TRY",
        symbol: "TL"
    },
    {
        label: "Trinidad and Tobago Dollar",
        value: "TTD",
        symbol: "$"
    },
    {
        label: "New Taiwan Dollar",
        value: "TWD",
        symbol: "NT$"
    },
    {
        label: "Tanzanian Shilling",
        value: "TZS",
        symbol: "TSh"
    },
    {
        label: "Ukrainian Hryvnia",
        value: "UAH",
        symbol: "₴"
    },
    {
        label: "Ugandan Shilling",
        value: "UGX",
        symbol: "USh"
    },
    {
        label: "Uruguayan Peso",
        value: "UYU",
        symbol: "$"
    },
    {
        label: "Uzbekistan Som",
        value: "UZS",
        symbol: "UZS"
    },
    {
        label: "Venezuelan Bolívar",
        value: "VEF",
        symbol: "Bs.F."
    },
    {
        label: "Vietnamese Dong",
        value: "VND",
        symbol: "₫"
    },
    {
        label: "CFA Franc BEAC",
        value: "XAF",
        symbol: "FCFA"
    },
    {
        label: "CFA Franc BCEAO",
        value: "XOF",
        symbol: "CFA"
    },
    {
        label: "Yemeni Rial",
        value: "YER",
        symbol: "ر.ي.‏"
    },
    {
        label: "South African Rand",
        value: "ZAR",
        symbol: "R"
    },
    {
        label: "Zambian Kwacha",
        value: "ZMK",
        symbol: "ZK"
    }
]

export const currencyJson = [
    {
        "symbol": "$",
        "name": "US Dollar",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "USD",
        "name_plural": "US dollars"
    },
    {
        "symbol": "CA$",
        "name": "Canadian Dollar",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "CAD",
        "name_plural": "Canadian dollars"
    },
    {
        "symbol": "€",
        "name": "Euro",
        "symbol_native": "€",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "EUR",
        "name_plural": "euros"
    },
    {
        "symbol": "AED",
        "name": "United Arab Emirates Dirham",
        "symbol_native": "د.إ.‏",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "AED",
        "name_plural": "UAE dirhams"
    },
    {
        "symbol": "Af",
        "name": "Afghan Afghani",
        "symbol_native": "؋",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "AFN",
        "name_plural": "Afghan Afghanis"
    },
    {
        "symbol": "ALL",
        "name": "Albanian Lek",
        "symbol_native": "Lek",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "ALL",
        "name_plural": "Albanian lekë"
    },
    {
        "symbol": "AMD",
        "name": "Armenian Dram",
        "symbol_native": "դր.",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "AMD",
        "name_plural": "Armenian drams"
    },
    {
        "symbol": "AR$",
        "name": "Argentine Peso",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "ARS",
        "name_plural": "Argentine pesos"
    },
    {
        "symbol": "AU$",
        "name": "Australian Dollar",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "AUD",
        "name_plural": "Australian dollars"
    },
    {
        "symbol": "man.",
        "name": "Azerbaijani Manat",
        "symbol_native": "ман.",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "AZN",
        "name_plural": "Azerbaijani manats"
    },
    {
        "symbol": "KM",
        "name": "Bosnia-Herzegovina Convertible Mark",
        "symbol_native": "KM",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "BAM",
        "name_plural": "Bosnia-Herzegovina convertible marks"
    },
    {
        "symbol": "Tk",
        "name": "Bangladeshi Taka",
        "symbol_native": "৳",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "BDT",
        "name_plural": "Bangladeshi takas"
    },
    {
        "symbol": "BGN",
        "name": "Bulgarian Lev",
        "symbol_native": "лв.",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "BGN",
        "name_plural": "Bulgarian leva"
    },
    {
        "symbol": "BD",
        "name": "Bahraini Dinar",
        "symbol_native": "د.ب.‏",
        "decimal_digits": 3,
        "rounding": 0,
        "code": "BHD",
        "name_plural": "Bahraini dinars"
    },
    {
        "symbol": "FBu",
        "name": "Burundian Franc",
        "symbol_native": "FBu",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "BIF",
        "name_plural": "Burundian francs"
    },
    {
        "symbol": "BN$",
        "name": "Brunei Dollar",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "BND",
        "name_plural": "Brunei dollars"
    },
    {
        "symbol": "Bs",
        "name": "Bolivian Boliviano",
        "symbol_native": "Bs",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "BOB",
        "name_plural": "Bolivian bolivianos"
    },
    {
        "symbol": "R$",
        "name": "Brazilian Real",
        "symbol_native": "R$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "BRL",
        "name_plural": "Brazilian reals"
    },
    {
        "symbol": "BWP",
        "name": "Botswanan Pula",
        "symbol_native": "P",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "BWP",
        "name_plural": "Botswanan pulas"
    },
    {
        "symbol": "BYR",
        "name": "Belarusian Ruble",
        "symbol_native": "BYR",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "BYR",
        "name_plural": "Belarusian rubles"
    },
    {
        "symbol": "BZ$",
        "name": "Belize Dollar",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "BZD",
        "name_plural": "Belize dollars"
    },
    {
        "symbol": "CDF",
        "name": "Congolese Franc",
        "symbol_native": "FrCD",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "CDF",
        "name_plural": "Congolese francs"
    },
    {
        "symbol": "CHF",
        "name": "Swiss Franc",
        "symbol_native": "CHF",
        "decimal_digits": 2,
        "rounding": 0.05,
        "code": "CHF",
        "name_plural": "Swiss francs"
    },
    {
        "symbol": "CL$",
        "name": "Chilean Peso",
        "symbol_native": "$",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "CLP",
        "name_plural": "Chilean pesos"
    },
    {
        "symbol": "CN¥",
        "name": "Chinese Yuan",
        "symbol_native": "CN¥",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "CNY",
        "name_plural": "Chinese yuan"
    },
    {
        "symbol": "CO$",
        "name": "Colombian Peso",
        "symbol_native": "$",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "COP",
        "name_plural": "Colombian pesos"
    },
    {
        "symbol": "₡",
        "name": "Costa Rican Colón",
        "symbol_native": "₡",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "CRC",
        "name_plural": "Costa Rican colóns"
    },
    {
        "symbol": "CV$",
        "name": "Cape Verdean Escudo",
        "symbol_native": "CV$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "CVE",
        "name_plural": "Cape Verdean escudos"
    },
    {
        "symbol": "Kč",
        "name": "Czech Republic Koruna",
        "symbol_native": "Kč",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "CZK",
        "name_plural": "Czech Republic korunas"
    },
    {
        "symbol": "Fdj",
        "name": "Djiboutian Franc",
        "symbol_native": "Fdj",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "DJF",
        "name_plural": "Djiboutian francs"
    },
    {
        "symbol": "Dkr",
        "name": "Danish Krone",
        "symbol_native": "kr",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "DKK",
        "name_plural": "Danish kroner"
    },
    {
        "symbol": "RD$",
        "name": "Dominican Peso",
        "symbol_native": "RD$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "DOP",
        "name_plural": "Dominican pesos"
    },
    {
        "symbol": "DA",
        "name": "Algerian Dinar",
        "symbol_native": "د.ج.‏",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "DZD",
        "name_plural": "Algerian dinars"
    },
    {
        "symbol": "Ekr",
        "name": "Estonian Kroon",
        "symbol_native": "kr",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "EEK",
        "name_plural": "Estonian kroons"
    },
    {
        "symbol": "EGP",
        "name": "Egyptian Pound",
        "symbol_native": "ج.م.‏",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "EGP",
        "name_plural": "Egyptian pounds"
    },
    {
        "symbol": "Nfk",
        "name": "Eritrean Nakfa",
        "symbol_native": "Nfk",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "ERN",
        "name_plural": "Eritrean nakfas"
    },
    {
        "symbol": "Br",
        "name": "Ethiopian Birr",
        "symbol_native": "Br",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "ETB",
        "name_plural": "Ethiopian birrs"
    },
    {
        "symbol": "£",
        "name": "British Pound Sterling",
        "symbol_native": "£",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "GBP",
        "name_plural": "British pounds sterling"
    },
    {
        "symbol": "GEL",
        "name": "Georgian Lari",
        "symbol_native": "GEL",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "GEL",
        "name_plural": "Georgian laris"
    },
    {
        "symbol": "GH₵",
        "name": "Ghanaian Cedi",
        "symbol_native": "GH₵",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "GHS",
        "name_plural": "Ghanaian cedis"
    },
    {
        "symbol": "FG",
        "name": "Guinean Franc",
        "symbol_native": "FG",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "GNF",
        "name_plural": "Guinean francs"
    },
    {
        "symbol": "GTQ",
        "name": "Guatemalan Quetzal",
        "symbol_native": "Q",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "GTQ",
        "name_plural": "Guatemalan quetzals"
    },
    {
        "symbol": "HK$",
        "name": "Hong Kong Dollar",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "HKD",
        "name_plural": "Hong Kong dollars"
    },
    {
        "symbol": "HNL",
        "name": "Honduran Lempira",
        "symbol_native": "L",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "HNL",
        "name_plural": "Honduran lempiras"
    },
    {
        "symbol": "kn",
        "name": "Croatian Kuna",
        "symbol_native": "kn",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "HRK",
        "name_plural": "Croatian kunas"
    },
    {
        "symbol": "Ft",
        "name": "Hungarian Forint",
        "symbol_native": "Ft",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "HUF",
        "name_plural": "Hungarian forints"
    },
    {
        "symbol": "Rp",
        "name": "Indonesian Rupiah",
        "symbol_native": "Rp",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "IDR",
        "name_plural": "Indonesian rupiahs"
    },
    {
        "symbol": "₪",
        "name": "Israeli New Sheqel",
        "symbol_native": "₪",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "ILS",
        "name_plural": "Israeli new sheqels"
    },
    {
        "symbol": "Rs",
        "name": "Indian Rupee",
        "symbol_native": "টকা",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "INR",
        "name_plural": "Indian rupees"
    },
    {
        "symbol": "IQD",
        "name": "Iraqi Dinar",
        "symbol_native": "د.ع.‏",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "IQD",
        "name_plural": "Iraqi dinars"
    },
    {
        "symbol": "IRR",
        "name": "Iranian Rial",
        "symbol_native": "﷼",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "IRR",
        "name_plural": "Iranian rials"
    },
    {
        "symbol": "Ikr",
        "name": "Icelandic Króna",
        "symbol_native": "kr",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "ISK",
        "name_plural": "Icelandic krónur"
    },
    {
        "symbol": "J$",
        "name": "Jamaican Dollar",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "JMD",
        "name_plural": "Jamaican dollars"
    },
    {
        "symbol": "JD",
        "name": "Jordanian Dinar",
        "symbol_native": "د.أ.‏",
        "decimal_digits": 3,
        "rounding": 0,
        "code": "JOD",
        "name_plural": "Jordanian dinars"
    },
    {
        "symbol": "¥",
        "name": "Japanese Yen",
        "symbol_native": "￥",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "JPY",
        "name_plural": "Japanese yen"
    },
    {
        "symbol": "Ksh",
        "name": "Kenyan Shilling",
        "symbol_native": "Ksh",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "KES",
        "name_plural": "Kenyan shillings"
    },
    {
        "symbol": "KHR",
        "name": "Cambodian Riel",
        "symbol_native": "៛",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "KHR",
        "name_plural": "Cambodian riels"
    },
    {
        "symbol": "CF",
        "name": "Comorian Franc",
        "symbol_native": "FC",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "KMF",
        "name_plural": "Comorian francs"
    },
    {
        "symbol": "₩",
        "name": "South Korean Won",
        "symbol_native": "₩",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "KRW",
        "name_plural": "South Korean won"
    },
    {
        "symbol": "KD",
        "name": "Kuwaiti Dinar",
        "symbol_native": "د.ك.‏",
        "decimal_digits": 3,
        "rounding": 0,
        "code": "KWD",
        "name_plural": "Kuwaiti dinars"
    },
    {
        "symbol": "KZT",
        "name": "Kazakhstani Tenge",
        "symbol_native": "тңг.",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "KZT",
        "name_plural": "Kazakhstani tenges"
    },
    {
        "symbol": "LB£",
        "name": "Lebanese Pound",
        "symbol_native": "ل.ل.‏",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "LBP",
        "name_plural": "Lebanese pounds"
    },
    {
        "symbol": "SLRs",
        "name": "Sri Lankan Rupee",
        "symbol_native": "SL Re",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "LKR",
        "name_plural": "Sri Lankan rupees"
    },
    {
        "symbol": "Lt",
        "name": "Lithuanian Litas",
        "symbol_native": "Lt",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "LTL",
        "name_plural": "Lithuanian litai"
    },
    {
        "symbol": "Ls",
        "name": "Latvian Lats",
        "symbol_native": "Ls",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "LVL",
        "name_plural": "Latvian lati"
    },
    {
        "symbol": "LD",
        "name": "Libyan Dinar",
        "symbol_native": "د.ل.‏",
        "decimal_digits": 3,
        "rounding": 0,
        "code": "LYD",
        "name_plural": "Libyan dinars"
    },
    {
        "symbol": "MAD",
        "name": "Moroccan Dirham",
        "symbol_native": "د.م.‏",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "MAD",
        "name_plural": "Moroccan dirhams"
    },
    {
        "symbol": "MDL",
        "name": "Moldovan Leu",
        "symbol_native": "MDL",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "MDL",
        "name_plural": "Moldovan lei"
    },
    {
        "symbol": "MGA",
        "name": "Malagasy Ariary",
        "symbol_native": "MGA",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "MGA",
        "name_plural": "Malagasy Ariaries"
    },
    {
        "symbol": "MKD",
        "name": "Macedonian Denar",
        "symbol_native": "MKD",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "MKD",
        "name_plural": "Macedonian denari"
    },
    {
        "symbol": "MMK",
        "name": "Myanma Kyat",
        "symbol_native": "K",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "MMK",
        "name_plural": "Myanma kyats"
    },
    {
        "symbol": "MOP$",
        "name": "Macanese Pataca",
        "symbol_native": "MOP$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "MOP",
        "name_plural": "Macanese patacas"
    },
    {
        "symbol": "MURs",
        "name": "Mauritian Rupee",
        "symbol_native": "MURs",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "MUR",
        "name_plural": "Mauritian rupees"
    },
    {
        "symbol": "MX$",
        "name": "Mexican Peso",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "MXN",
        "name_plural": "Mexican pesos"
    },
    {
        "symbol": "RM",
        "name": "Malaysian Ringgit",
        "symbol_native": "RM",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "MYR",
        "name_plural": "Malaysian ringgits"
    },
    {
        "symbol": "MTn",
        "name": "Mozambican Metical",
        "symbol_native": "MTn",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "MZN",
        "name_plural": "Mozambican meticals"
    },
    {
        "symbol": "N$",
        "name": "Namibian Dollar",
        "symbol_native": "N$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "NAD",
        "name_plural": "Namibian dollars"
    },
    {
        "symbol": "₦",
        "name": "Nigerian Naira",
        "symbol_native": "₦",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "NGN",
        "name_plural": "Nigerian nairas"
    },
    {
        "symbol": "C$",
        "name": "Nicaraguan Córdoba",
        "symbol_native": "C$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "NIO",
        "name_plural": "Nicaraguan córdobas"
    },
    {
        "symbol": "Nkr",
        "name": "Norwegian Krone",
        "symbol_native": "kr",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "NOK",
        "name_plural": "Norwegian kroner"
    },
    {
        "symbol": "NPRs",
        "name": "Nepalese Rupee",
        "symbol_native": "नेरू",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "NPR",
        "name_plural": "Nepalese rupees"
    },
    {
        "symbol": "NZ$",
        "name": "New Zealand Dollar",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "NZD",
        "name_plural": "New Zealand dollars"
    },
    {
        "symbol": "OMR",
        "name": "Omani Rial",
        "symbol_native": "ر.ع.‏",
        "decimal_digits": 3,
        "rounding": 0,
        "code": "OMR",
        "name_plural": "Omani rials"
    },
    {
        "symbol": "B/.",
        "name": "Panamanian Balboa",
        "symbol_native": "B/.",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "PAB",
        "name_plural": "Panamanian balboas"
    },
    {
        "symbol": "S/.",
        "name": "Peruvian Nuevo Sol",
        "symbol_native": "S/.",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "PEN",
        "name_plural": "Peruvian nuevos soles"
    },
    {
        "symbol": "₱",
        "name": "Philippine Peso",
        "symbol_native": "₱",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "PHP",
        "name_plural": "Philippine pesos"
    },
    {
        "symbol": "PKRs",
        "name": "Pakistani Rupee",
        "symbol_native": "₨",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "PKR",
        "name_plural": "Pakistani rupees"
    },
    {
        "symbol": "zł",
        "name": "Polish Zloty",
        "symbol_native": "zł",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "PLN",
        "name_plural": "Polish zlotys"
    },
    {
        "symbol": "₲",
        "name": "Paraguayan Guarani",
        "symbol_native": "₲",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "PYG",
        "name_plural": "Paraguayan guaranis"
    },
    {
        "symbol": "QR",
        "name": "Qatari Rial",
        "symbol_native": "ر.ق.‏",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "QAR",
        "name_plural": "Qatari rials"
    },
    {
        "symbol": "RON",
        "name": "Romanian Leu",
        "symbol_native": "RON",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "RON",
        "name_plural": "Romanian lei"
    },
    {
        "symbol": "din.",
        "name": "Serbian Dinar",
        "symbol_native": "дин.",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "RSD",
        "name_plural": "Serbian dinars"
    },
    {
        "symbol": "RUB",
        "name": "Russian Ruble",
        "symbol_native": "руб.",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "RUB",
        "name_plural": "Russian rubles"
    },
    {
        "symbol": "RWF",
        "name": "Rwandan Franc",
        "symbol_native": "FR",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "RWF",
        "name_plural": "Rwandan francs"
    },
    {
        "symbol": "SR",
        "name": "Saudi Riyal",
        "symbol_native": "ر.س.‏",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "SAR",
        "name_plural": "Saudi riyals"
    },
    {
        "symbol": "SDG",
        "name": "Sudanese Pound",
        "symbol_native": "SDG",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "SDG",
        "name_plural": "Sudanese pounds"
    },
    {
        "symbol": "Skr",
        "name": "Swedish Krona",
        "symbol_native": "kr",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "SEK",
        "name_plural": "Swedish kronor"
    },
    {
        "symbol": "S$",
        "name": "Singapore Dollar",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "SGD",
        "name_plural": "Singapore dollars"
    },
    {
        "symbol": "Ssh",
        "name": "Somali Shilling",
        "symbol_native": "Ssh",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "SOS",
        "name_plural": "Somali shillings"
    },
    {
        "symbol": "SY£",
        "name": "Syrian Pound",
        "symbol_native": "ل.س.‏",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "SYP",
        "name_plural": "Syrian pounds"
    },
    {
        "symbol": "฿",
        "name": "Thai Baht",
        "symbol_native": "฿",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "THB",
        "name_plural": "Thai baht"
    },
    {
        "symbol": "DT",
        "name": "Tunisian Dinar",
        "symbol_native": "د.ت.‏",
        "decimal_digits": 3,
        "rounding": 0,
        "code": "TND",
        "name_plural": "Tunisian dinars"
    },
    {
        "symbol": "T$",
        "name": "Tongan Paʻanga",
        "symbol_native": "T$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "TOP",
        "name_plural": "Tongan paʻanga"
    },
    {
        "symbol": "TL",
        "name": "Turkish Lira",
        "symbol_native": "TL",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "TRY",
        "name_plural": "Turkish Lira"
    },
    {
        "symbol": "TT$",
        "name": "Trinidad and Tobago Dollar",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "TTD",
        "name_plural": "Trinidad and Tobago dollars"
    },
    {
        "symbol": "NT$",
        "name": "New Taiwan Dollar",
        "symbol_native": "NT$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "TWD",
        "name_plural": "New Taiwan dollars"
    },
    {
        "symbol": "TSh",
        "name": "Tanzanian Shilling",
        "symbol_native": "TSh",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "TZS",
        "name_plural": "Tanzanian shillings"
    },
    {
        "symbol": "₴",
        "name": "Ukrainian Hryvnia",
        "symbol_native": "₴",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "UAH",
        "name_plural": "Ukrainian hryvnias"
    },
    {
        "symbol": "USh",
        "name": "Ugandan Shilling",
        "symbol_native": "USh",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "UGX",
        "name_plural": "Ugandan shillings"
    },
    {
        "symbol": "$U",
        "name": "Uruguayan Peso",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "UYU",
        "name_plural": "Uruguayan pesos"
    },
    {
        "symbol": "UZS",
        "name": "Uzbekistan Som",
        "symbol_native": "UZS",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "UZS",
        "name_plural": "Uzbekistan som"
    },
    {
        "symbol": "Bs.F.",
        "name": "Venezuelan Bolívar",
        "symbol_native": "Bs.F.",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "VEF",
        "name_plural": "Venezuelan bolívars"
    },
    {
        "symbol": "₫",
        "name": "Vietnamese Dong",
        "symbol_native": "₫",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "VND",
        "name_plural": "Vietnamese dong"
    },
    {
        "symbol": "FCFA",
        "name": "CFA Franc BEAC",
        "symbol_native": "FCFA",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "XAF",
        "name_plural": "CFA francs BEAC"
    },
    {
        "symbol": "CFA",
        "name": "CFA Franc BCEAO",
        "symbol_native": "CFA",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "XOF",
        "name_plural": "CFA francs BCEAO"
    },
    {
        "symbol": "YR",
        "name": "Yemeni Rial",
        "symbol_native": "ر.ي.‏",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "YER",
        "name_plural": "Yemeni rials"
    },
    {
        "symbol": "R",
        "name": "South African Rand",
        "symbol_native": "R",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "ZAR",
        "name_plural": "South African rand"
    },
    {
        "symbol": "ZK",
        "name": "Zambian Kwacha",
        "symbol_native": "ZK",
        "decimal_digits": 0,
        "rounding": 0,
        "code": "ZMK",
        "name_plural": "Zambian kwachas"
    }
]

export const allCitiesClockList = [
    {
        "name": "Qal eh-ye Now",
        "country": "Afghanistan",
        "province": "Badghis",
        "timezone": "Asia/Kabul"
    },
    {
        "name": "Chaghcharan",
        "country": "Afghanistan",
        "province": "Ghor",
        "timezone": "Asia/Kabul"
    },
    {
        "name": "Lashkar Gah",
        "country": "Afghanistan",
        "province": "Hilmand",
        "timezone": "Asia/Kabul"
    },
    {
        "name": "Zaranj",
        "country": "Afghanistan",
        "province": "Nimroz",
        "timezone": "Asia/Kabul"
    },
    {
        "name": "Tarin Kowt",
        "country": "Afghanistan",
        "province": "Uruzgan",
        "timezone": "Asia/Kabul"
    },
    {
        "name": "Zareh Sharan",
        "country": "Afghanistan",
        "province": "Paktika",
        "timezone": "Asia/Kabul"
    },
    {
        "name": "Asadabad",
        "country": "Afghanistan",
        "province": "Kunar",
        "timezone": "Asia/Kabul"
    },
    {
        "name": "Taloqan",
        "country": "Afghanistan",
        "province": "Takhar",
        "timezone": "Asia/Kabul"
    },
    {
        "name": "Mahmud-E Eraqi",
        "country": "Afghanistan",
        "province": "Kapisa",
        "timezone": "Asia/Kabul"
    },
    {
        "name": "Mehtar Lam",
        "country": "Afghanistan",
        "province": "Laghman",
        "timezone": "Asia/Kabul"
    },
    {
        "name": "Baraki Barak",
        "country": "Afghanistan",
        "province": "Logar",
        "timezone": "Asia/Kabul"
    },
    {
        "name": "Aybak",
        "country": "Afghanistan",
        "province": "Samangan",
        "timezone": "Asia/Kabul"
    },
    {
        "name": "Mayda Shahr",
        "country": "Afghanistan",
        "province": "Wardak",
        "timezone": "Asia/Kabul"
    },
    {
        "name": "Karokh",
        "country": "Afghanistan",
        "province": "Hirat",
        "timezone": "Asia/Kabul"
    },
    {
        "name": "Sheberghan",
        "country": "Afghanistan",
        "province": "Jawzjan",
        "timezone": "Asia/Kabul"
    },
    {
        "name": "Pol-e Khomri",
        "country": "Afghanistan",
        "province": "Baghlan",
        "timezone": "Asia/Kabul"
    },
    {
        "name": "Balkh",
        "country": "Afghanistan",
        "province": "Balkh",
        "timezone": "Asia/Kabul"
    },
    {
        "name": "Meymaneh",
        "country": "Afghanistan",
        "province": "Faryab",
        "timezone": "Asia/Kabul"
    },
    {
        "name": "Andkhvoy",
        "country": "Afghanistan",
        "province": "Faryab",
        "timezone": "Asia/Kabul"
    },
    {
        "name": "Qalat",
        "country": "Afghanistan",
        "province": "Zabul",
        "timezone": "Asia/Kabul"
    },
    {
        "name": "Ghazni",
        "country": "Afghanistan",
        "province": "Ghazni",
        "timezone": "Asia/Kabul"
    },
    {
        "name": "Feyzabad",
        "country": "Afghanistan",
        "province": "Badakhshan",
        "timezone": "Asia/Kabul"
    },
    {
        "name": "Kondoz",
        "country": "Afghanistan",
        "province": "Kunduz",
        "timezone": "Asia/Kabul"
    },
    {
        "name": "Jalalabad",
        "country": "Afghanistan",
        "province": "Nangarhar",
        "timezone": "Asia/Kabul"
    },
    {
        "name": "Charikar",
        "country": "Afghanistan",
        "province": "Parwan",
        "timezone": "Asia/Kabul"
    },
    {
        "name": "Gardiz",
        "country": "Afghanistan",
        "province": "Paktya",
        "timezone": "Asia/Kabul"
    },
    {
        "name": "Bamian",
        "country": "Afghanistan",
        "province": "Bamyan",
        "timezone": "Asia/Kabul"
    },
    {
        "name": "Baghlan",
        "country": "Afghanistan",
        "province": "Baghlan",
        "timezone": "Asia/Kabul"
    },
    {
        "name": "Farah",
        "country": "Afghanistan",
        "province": "Farah",
        "timezone": "Asia/Kabul"
    },
    {
        "name": "Herat",
        "country": "Afghanistan",
        "province": "Hirat",
        "timezone": "Asia/Kabul"
    },
    {
        "name": "Mazar-e Sharif",
        "country": "Afghanistan",
        "province": "Balkh",
        "timezone": "Asia/Kabul"
    },
    {
        "name": "Kandahar",
        "country": "Afghanistan",
        "province": "Kandahar",
        "timezone": "Asia/Kabul"
    },
    {
        "name": "Kabul",
        "country": "Afghanistan",
        "province": "Kabul",
        "timezone": "Asia/Kabul"
    },
    {
        "name": "Mariehamn",
        "country": "Aland",
        "province": "Finström",
        "timezone": "Europe/Mariehamn"
    },
    {
        "name": "Kruje",
        "country": "Albania",
        "province": "Durrës",
        "timezone": "Europe/Tirane"
    },
    {
        "name": "Fier",
        "country": "Albania",
        "province": "Fier",
        "timezone": "Europe/Tirane"
    },
    {
        "name": "Lushnje",
        "country": "Albania",
        "province": "Fier",
        "timezone": "Europe/Tirane"
    },
    {
        "name": "Puke",
        "country": "Albania",
        "province": "Shkodër",
        "timezone": "Europe/Tirane"
    },
    {
        "name": "Bajram Curri",
        "country": "Albania",
        "province": "Kukës",
        "timezone": "Europe/Tirane"
    },
    {
        "name": "Kukes",
        "country": "Albania",
        "province": "Kukës",
        "timezone": "Europe/Tirane"
    },
    {
        "name": "Sarande",
        "country": "Albania",
        "province": "Vlorë",
        "timezone": "Europe/Tirane"
    },
    {
        "name": "Erseke",
        "country": "Albania",
        "province": "Korçë",
        "timezone": "Europe/Tirane"
    },
    {
        "name": "Pogradec",
        "country": "Albania",
        "province": "Korçë",
        "timezone": "Europe/Tirane"
    },
    {
        "name": "Korce",
        "country": "Albania",
        "province": "Korçë",
        "timezone": "Europe/Tirane"
    },
    {
        "name": "Berat",
        "country": "Albania",
        "province": "Berat",
        "timezone": "Europe/Tirane"
    },
    {
        "name": "Corovode",
        "country": "Albania",
        "province": "Berat",
        "timezone": "Europe/Tirane"
    },
    {
        "name": "Gramsh",
        "country": "Albania",
        "province": "Elbasan",
        "timezone": "Europe/Tirane"
    },
    {
        "name": "Librazhd",
        "country": "Albania",
        "province": "Elbasan",
        "timezone": "Europe/Tirane"
    },
    {
        "name": "Tepelene",
        "country": "Albania",
        "province": "Gjirokastër",
        "timezone": "Europe/Tirane"
    },
    {
        "name": "Permet",
        "country": "Albania",
        "province": "Gjirokastër",
        "timezone": "Europe/Tirane"
    },
    {
        "name": "Gjirokaster",
        "country": "Albania",
        "province": "Gjirokastër",
        "timezone": "Europe/Tirane"
    },
    {
        "name": "Peshkopi",
        "country": "Albania",
        "province": "Dibër",
        "timezone": "Europe/Tirane"
    },
    {
        "name": "Burrel",
        "country": "Albania",
        "province": "Dibër",
        "timezone": "Europe/Tirane"
    },
    {
        "name": "Lezhe",
        "country": "Albania",
        "province": "Lezhë",
        "timezone": "Europe/Tirane"
    },
    {
        "name": "Rreshen",
        "country": "Albania",
        "province": "Lezhë",
        "timezone": "Europe/Tirane"
    },
    {
        "name": "Vlore",
        "country": "Albania",
        "province": "Vlorë",
        "timezone": "Europe/Tirane"
    },
    {
        "name": "Elbasan",
        "country": "Albania",
        "province": "Elbasan",
        "timezone": "Europe/Tirane"
    },
    {
        "name": "Durres",
        "country": "Albania",
        "province": "Durrës",
        "timezone": "Europe/Tirane"
    },
    {
        "name": "Shkoder",
        "country": "Albania",
        "province": "Shkodër",
        "timezone": "Europe/Tirane"
    },
    {
        "name": "Tirana",
        "country": "Albania",
        "province": "Durrës",
        "timezone": "Europe/Tirane"
    },
    {
        "name": "Jijel",
        "country": "Algeria",
        "province": "Jijel",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Tizi-Ouzou",
        "country": "Algeria",
        "province": "Tizi Ouzou",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Bordj Bou Arreridj",
        "country": "Algeria",
        "province": "Bordj Bou Arréridj",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "M'sila",
        "country": "Algeria",
        "province": "M'Sila",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Guelma",
        "country": "Algeria",
        "province": "Guelma",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Oum el Bouaghi",
        "country": "Algeria",
        "province": "Oum el Bouaghi",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Timimoun",
        "country": "Algeria",
        "province": "Adrar",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Sidi bel Abbes",
        "country": "Algeria",
        "province": "Sidi Bel Abbès",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Tlimcen",
        "country": "Algeria",
        "province": "Tlemcen",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Beni Ounif",
        "country": "Algeria",
        "province": "Béchar",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Abadla",
        "country": "Algeria",
        "province": "Béchar",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Sefra",
        "country": "Algeria",
        "province": "Naâma",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Skikda",
        "country": "Algeria",
        "province": "Skikda",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Djanet",
        "country": "Algeria",
        "province": "Illizi",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "I-n-Amenas",
        "country": "Algeria",
        "province": "Illizi",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "In Amguel",
        "country": "Algeria",
        "province": "Tamanghasset",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "El Bayadh",
        "country": "Algeria",
        "province": "El Bayadh",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "El Oued",
        "country": "Algeria",
        "province": "Biskra",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Hassi Messaoud",
        "country": "Algeria",
        "province": "Ouargla",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Chlef",
        "country": "Algeria",
        "province": "Chlef",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Mascara",
        "country": "Algeria",
        "province": "Mascara",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Mostaganem",
        "country": "Algeria",
        "province": "Mostaganem",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Saida",
        "country": "Algeria",
        "province": "Saïda",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Tiarat",
        "country": "Algeria",
        "province": "Tiaret",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Bejaia",
        "country": "Algeria",
        "province": "Béjaïa",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Blida",
        "country": "Algeria",
        "province": "Blida",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Bouira",
        "country": "Algeria",
        "province": "Bouira",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Medea",
        "country": "Algeria",
        "province": "Médéa",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Souk Ahras",
        "country": "Algeria",
        "province": "Souk Ahras",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Tebessa",
        "country": "Algeria",
        "province": "Tébessa",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Adrar",
        "country": "Algeria",
        "province": "Adrar",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Reggane",
        "country": "Algeria",
        "province": "Adrar",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Bechar",
        "country": "Algeria",
        "province": "Béchar",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Tindouf",
        "country": "Algeria",
        "province": "Tindouf",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Illizi",
        "country": "Algeria",
        "province": "Illizi",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Arak",
        "country": "Algeria",
        "province": "Tamanghasset",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "I-n-Salah",
        "country": "Algeria",
        "province": "Tamanghasset",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "El Golea",
        "country": "Algeria",
        "province": "Ghardaïa",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Laghouat",
        "country": "Algeria",
        "province": "Laghouat",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Touggourt",
        "country": "Algeria",
        "province": "Ouargla",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Ouargla",
        "country": "Algeria",
        "province": "Ouargla",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Biskra",
        "country": "Algeria",
        "province": "Biskra",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Djelfa",
        "country": "Algeria",
        "province": "Djelfa",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Setif",
        "country": "Algeria",
        "province": "Sétif",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Batna",
        "country": "Algeria",
        "province": "Batna",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Annaba",
        "country": "Algeria",
        "province": "Annaba",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Constantine",
        "country": "Algeria",
        "province": "Constantine",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Oran",
        "country": "Algeria",
        "province": "Oran",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Tamanrasset",
        "country": "Algeria",
        "province": "Tamanghasset",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Ghardaia",
        "country": "Algeria",
        "province": "Ghardaïa",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Algiers",
        "country": "Algeria",
        "province": "Alger",
        "timezone": "Africa/Algiers"
    },
    {
        "name": "Pago Pago",
        "country": "American Samoa",
        "province": "",
        "timezone": "Pacific/Pago_Pago"
    },
    {
        "name": "Andorra",
        "country": "Andorra",
        "province": "",
        "timezone": "Europe/Andorra"
    },
    {
        "name": "Mucusso",
        "country": "Angola",
        "province": "Cuando Cubango",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Lucapa",
        "country": "Angola",
        "province": "Lunda Norte",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Capenda-Camulemba",
        "country": "Angola",
        "province": "Lunda Norte",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Saurimo",
        "country": "Angola",
        "province": "Lunda Sul",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Muconda",
        "country": "Angola",
        "province": "Lunda Sul",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Cacolo",
        "country": "Angola",
        "province": "Lunda Sul",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Caxito",
        "country": "Angola",
        "province": "Bengo",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Camabatela",
        "country": "Angola",
        "province": "Cuanza Norte",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Ndalatando",
        "country": "Angola",
        "province": "Cuanza Norte",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Quibala",
        "country": "Angola",
        "province": "Cuanza Sul",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Calulo",
        "country": "Angola",
        "province": "Cuanza Sul",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Waku Kungo",
        "country": "Angola",
        "province": "Cuanza Sul",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Songo",
        "country": "Angola",
        "province": "Uíge",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Mbanza-Congo",
        "country": "Angola",
        "province": "Zaire",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Nzeto",
        "country": "Angola",
        "province": "Zaire",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Soyo",
        "country": "Angola",
        "province": "Zaire",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Cabinda",
        "country": "Angola",
        "province": "Cabinda",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Calucinga",
        "country": "Angola",
        "province": "Bié",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Camacupa",
        "country": "Angola",
        "province": "Bié",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Cubal",
        "country": "Angola",
        "province": "Benguela",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Mavinga",
        "country": "Angola",
        "province": "Cuando Cubango",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Cuito Caunavale",
        "country": "Angola",
        "province": "Cuando Cubango",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Luiana",
        "country": "Angola",
        "province": "Cuando Cubango",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Ondjiva",
        "country": "Angola",
        "province": "Cunene",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Chitado",
        "country": "Angola",
        "province": "Cunene",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Chibemba",
        "country": "Angola",
        "province": "Huíla",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Chibia",
        "country": "Angola",
        "province": "Huíla",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Quipungo",
        "country": "Angola",
        "province": "Huíla",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Luau",
        "country": "Angola",
        "province": "Moxico",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Cangamba",
        "country": "Angola",
        "province": "Moxico",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Lumbala Nguimbo",
        "country": "Angola",
        "province": "Moxico",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Cazombo",
        "country": "Angola",
        "province": "Moxico",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Dundo",
        "country": "Angola",
        "province": "Lunda Norte",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Ambriz",
        "country": "Angola",
        "province": "Bengo",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Dondo",
        "country": "Angola",
        "province": "Cuanza Norte",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Sumbe",
        "country": "Angola",
        "province": "Cuanza Sul",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Uige",
        "country": "Angola",
        "province": "Uíge",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Kuito",
        "country": "Angola",
        "province": "Bié",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Lobito",
        "country": "Angola",
        "province": "Benguela",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Xangongo",
        "country": "Angola",
        "province": "Cunene",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Luena",
        "country": "Angola",
        "province": "Moxico",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Tômbua",
        "country": "Angola",
        "province": "Namibe",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Malanje",
        "country": "Angola",
        "province": "Malanje",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Benguela",
        "country": "Angola",
        "province": "Benguela",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Lubango",
        "country": "Angola",
        "province": "Huíla",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Namibe",
        "country": "Angola",
        "province": "Namibe",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Menongue",
        "country": "Angola",
        "province": "Cuando Cubango",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Huambo",
        "country": "Angola",
        "province": "Huambo",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Luanda",
        "country": "Angola",
        "province": "Luanda",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Artigas Base",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Capitan Arturo Prat Station",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Marambio Station",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Zucchelli Station",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Rothera Station",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Palmer Station",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Base Presidente Montalva",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Carlini Station",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "King Sejong Station",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Great Wall Station",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Escudero Base",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Elephant Island",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Scott Base",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "McMurdo Station",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Zhongshan Station",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Vostok",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Peter I Island",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Mirny Station",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Mawson Station",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Davis Station",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Concordia Research Station",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Casey Station",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Amundsen–Scott South Pole Station",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Wasa Station",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Troll Station",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Svea Station",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Novolazarevskaya Station",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Neumayer Station III",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Maitri Station",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Halley Station",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Belgrano Station",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Camp Sobral",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Aboa Station",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "San Martín Station",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Gen. O'Higgins Station",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Esperanza Station",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Orcadas Station",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Signy Research Station",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Dumont d'Urville Station",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Showa Station",
        "country": "Antarctica",
        "province": "",
        "timezone": null
    },
    {
        "name": "Saint John's",
        "country": "Antigua and Barbuda",
        "province": "",
        "timezone": "America/Antigua"
    },
    {
        "name": "28 de Noviembre",
        "country": "Argentina",
        "province": "Santa Cruz",
        "timezone": "America/Santiago"
    },
    {
        "name": "Gobernador Gregores",
        "country": "Argentina",
        "province": "Santa Cruz",
        "timezone": "America/Argentina/Rio_Gallegos"
    },
    {
        "name": "Comondante Luis Piedrabuena",
        "country": "Argentina",
        "province": "Santa Cruz",
        "timezone": "America/Argentina/Rio_Gallegos"
    },
    {
        "name": "Paso Rio Mayo",
        "country": "Argentina",
        "province": "Chubut",
        "timezone": "America/Argentina/Catamarca"
    },
    {
        "name": "Alto Rio Sanguer",
        "country": "Argentina",
        "province": "Chubut",
        "timezone": "America/Argentina/Catamarca"
    },
    {
        "name": "El Maiten",
        "country": "Argentina",
        "province": "Chubut",
        "timezone": "America/Argentina/Catamarca"
    },
    {
        "name": "Puerto Madryn",
        "country": "Argentina",
        "province": "Chubut",
        "timezone": "America/Argentina/Catamarca"
    },
    {
        "name": "Trelew",
        "country": "Argentina",
        "province": "Chubut",
        "timezone": "America/Argentina/Catamarca"
    },
    {
        "name": "Las Heras",
        "country": "Argentina",
        "province": "Mendoza",
        "timezone": "America/Argentina/Mendoza"
    },
    {
        "name": "San Martin",
        "country": "Argentina",
        "province": "Mendoza",
        "timezone": "America/Argentina/Mendoza"
    },
    {
        "name": "Uspallata",
        "country": "Argentina",
        "province": "Mendoza",
        "timezone": "America/Argentina/Mendoza"
    },
    {
        "name": "Cutral Co",
        "country": "Argentina",
        "province": "Neuquén",
        "timezone": "America/Argentina/Salta"
    },
    {
        "name": "Punta Alta",
        "country": "Argentina",
        "province": "Ciudad de Buenos Aires",
        "timezone": "America/Argentina/Buenos_Aires"
    },
    {
        "name": "San Nicolas",
        "country": "Argentina",
        "province": "Ciudad de Buenos Aires",
        "timezone": "America/Argentina/Buenos_Aires"
    },
    {
        "name": "Campana",
        "country": "Argentina",
        "province": "Ciudad de Buenos Aires",
        "timezone": "America/Argentina/Buenos_Aires"
    },
    {
        "name": "Chacabuco",
        "country": "Argentina",
        "province": "Ciudad de Buenos Aires",
        "timezone": "America/Argentina/Buenos_Aires"
    },
    {
        "name": "Mercedes",
        "country": "Argentina",
        "province": "Ciudad de Buenos Aires",
        "timezone": "America/Argentina/Buenos_Aires"
    },
    {
        "name": "Lincoln",
        "country": "Argentina",
        "province": "Ciudad de Buenos Aires",
        "timezone": "America/Argentina/Buenos_Aires"
    },
    {
        "name": "Chivilcoy",
        "country": "Argentina",
        "province": "Ciudad de Buenos Aires",
        "timezone": "America/Argentina/Buenos_Aires"
    },
    {
        "name": "Veinticinco de Mayo",
        "country": "Argentina",
        "province": "Ciudad de Buenos Aires",
        "timezone": "America/Argentina/Buenos_Aires"
    },
    {
        "name": "Nueve de Julio",
        "country": "Argentina",
        "province": "Ciudad de Buenos Aires",
        "timezone": "America/Argentina/Buenos_Aires"
    },
    {
        "name": "Dolores",
        "country": "Argentina",
        "province": "Ciudad de Buenos Aires",
        "timezone": "America/Argentina/Buenos_Aires"
    },
    {
        "name": "Pedro Luro",
        "country": "Argentina",
        "province": "Ciudad de Buenos Aires",
        "timezone": "America/Argentina/Buenos_Aires"
    },
    {
        "name": "Tres Arroyos",
        "country": "Argentina",
        "province": "Ciudad de Buenos Aires",
        "timezone": "America/Argentina/Buenos_Aires"
    },
    {
        "name": "Coronel Suarez",
        "country": "Argentina",
        "province": "Ciudad de Buenos Aires",
        "timezone": "America/Argentina/Buenos_Aires"
    },
    {
        "name": "Balcarce",
        "country": "Argentina",
        "province": "Ciudad de Buenos Aires",
        "timezone": "America/Argentina/Buenos_Aires"
    },
    {
        "name": "25 de Mayo",
        "country": "Argentina",
        "province": "Río Negro",
        "timezone": "America/Argentina/Salta"
    },
    {
        "name": "General Roca",
        "country": "Argentina",
        "province": "Río Negro",
        "timezone": "America/Argentina/Salta"
    },
    {
        "name": "Comallo",
        "country": "Argentina",
        "province": "Río Negro",
        "timezone": "America/Argentina/Salta"
    },
    {
        "name": "Ingeniero Jacobacci",
        "country": "Argentina",
        "province": "Río Negro",
        "timezone": "America/Argentina/Salta"
    },
    {
        "name": "General Conesa",
        "country": "Argentina",
        "province": "Río Negro",
        "timezone": "America/Argentina/Salta"
    },
    {
        "name": "Choele Choel",
        "country": "Argentina",
        "province": "Río Negro",
        "timezone": "America/Argentina/Salta"
    },
    {
        "name": "San Francisco",
        "country": "Argentina",
        "province": "Córdoba",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Alta Gracia",
        "country": "Argentina",
        "province": "Córdoba",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Villa Maria",
        "country": "Argentina",
        "province": "Córdoba",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Bell Ville",
        "country": "Argentina",
        "province": "Córdoba",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Villa Rumipal",
        "country": "Argentina",
        "province": "Córdoba",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Villa Carlos Paz",
        "country": "Argentina",
        "province": "Córdoba",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Chumbicha",
        "country": "Argentina",
        "province": "Catamarca",
        "timezone": "America/Argentina/Catamarca"
    },
    {
        "name": "Tinogasta",
        "country": "Argentina",
        "province": "Catamarca",
        "timezone": "America/Argentina/Catamarca"
    },
    {
        "name": "Abra Pampa",
        "country": "Argentina",
        "province": "Jujuy",
        "timezone": "America/Argentina/Jujuy"
    },
    {
        "name": "Humahuaca",
        "country": "Argentina",
        "province": "Jujuy",
        "timezone": "America/Argentina/Jujuy"
    },
    {
        "name": "Susques",
        "country": "Argentina",
        "province": "Jujuy",
        "timezone": "America/Argentina/Jujuy"
    },
    {
        "name": "Chepes",
        "country": "Argentina",
        "province": "La Rioja",
        "timezone": "America/Argentina/La_Rioja"
    },
    {
        "name": "Yacuiba",
        "country": "Argentina",
        "province": "Salta",
        "timezone": "America/Argentina/Salta"
    },
    {
        "name": "Tartagal",
        "country": "Argentina",
        "province": "Salta",
        "timezone": "America/Argentina/Salta"
    },
    {
        "name": "Joaquin V. Gonzalez",
        "country": "Argentina",
        "province": "Salta",
        "timezone": "America/Argentina/Salta"
    },
    {
        "name": "General Guemes",
        "country": "Argentina",
        "province": "Salta",
        "timezone": "America/Argentina/Salta"
    },
    {
        "name": "Trancas",
        "country": "Argentina",
        "province": "Tucumán",
        "timezone": "America/Argentina/Tucuman"
    },
    {
        "name": "Presidencia Roque Saenz Pena",
        "country": "Argentina",
        "province": "Chaco",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Pampa del Infierno",
        "country": "Argentina",
        "province": "Chaco",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Villa Angela",
        "country": "Argentina",
        "province": "Chaco",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Ingeniero Guillermo N. Juarez",
        "country": "Argentina",
        "province": "Formosa",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Comandante Fontana",
        "country": "Argentina",
        "province": "Formosa",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Doctor Pedro P. Pena",
        "country": "Argentina",
        "province": "Formosa",
        "timezone": "America/Asuncion"
    },
    {
        "name": "San Lorenzo",
        "country": "Argentina",
        "province": "Corrientes",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Corrientes",
        "country": "Argentina",
        "province": "Corrientes",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Concepcion del Uruguay",
        "country": "Argentina",
        "province": "Entre Ríos",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Victoria",
        "country": "Argentina",
        "province": "Entre Ríos",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Gualeguay",
        "country": "Argentina",
        "province": "Entre Ríos",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Parana",
        "country": "Argentina",
        "province": "Entre Ríos",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Villa Constitucion",
        "country": "Argentina",
        "province": "Santa Fe",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Rafaela",
        "country": "Argentina",
        "province": "Santa Fe",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Eldorado",
        "country": "Argentina",
        "province": "Misiones",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Rodeo",
        "country": "Argentina",
        "province": "San Juan",
        "timezone": "America/Argentina/San_Juan"
    },
    {
        "name": "Las Plumas",
        "country": "Argentina",
        "province": "Chubut",
        "timezone": "America/Argentina/Catamarca"
    },
    {
        "name": "Gastre",
        "country": "Argentina",
        "province": "Chubut",
        "timezone": "America/Argentina/Catamarca"
    },
    {
        "name": "Telsen",
        "country": "Argentina",
        "province": "Chubut",
        "timezone": "America/Argentina/Catamarca"
    },
    {
        "name": "Malargue",
        "country": "Argentina",
        "province": "Mendoza",
        "timezone": "America/Argentina/Mendoza"
    },
    {
        "name": "Tunuyan",
        "country": "Argentina",
        "province": "Mendoza",
        "timezone": "America/Argentina/Mendoza"
    },
    {
        "name": "La Paz",
        "country": "Argentina",
        "province": "Mendoza",
        "timezone": "America/Argentina/Mendoza"
    },
    {
        "name": "Chos Malal",
        "country": "Argentina",
        "province": "Neuquén",
        "timezone": "America/Argentina/Salta"
    },
    {
        "name": "Las Lajas",
        "country": "Argentina",
        "province": "Neuquén",
        "timezone": "America/Argentina/Salta"
    },
    {
        "name": "Zarate",
        "country": "Argentina",
        "province": "Ciudad de Buenos Aires",
        "timezone": "America/Argentina/Buenos_Aires"
    },
    {
        "name": "Carhue",
        "country": "Argentina",
        "province": "Ciudad de Buenos Aires",
        "timezone": "America/Argentina/Buenos_Aires"
    },
    {
        "name": "Darregueira",
        "country": "Argentina",
        "province": "Ciudad de Buenos Aires",
        "timezone": "America/Argentina/Buenos_Aires"
    },
    {
        "name": "Juarez",
        "country": "Argentina",
        "province": "Ciudad de Buenos Aires",
        "timezone": "America/Argentina/Buenos_Aires"
    },
    {
        "name": "Mar de Ajo",
        "country": "Argentina",
        "province": "Ciudad de Buenos Aires",
        "timezone": "America/Argentina/Buenos_Aires"
    },
    {
        "name": "Lobos",
        "country": "Argentina",
        "province": "Ciudad de Buenos Aires",
        "timezone": "America/Argentina/Buenos_Aires"
    },
    {
        "name": "Chascomus",
        "country": "Argentina",
        "province": "Ciudad de Buenos Aires",
        "timezone": "America/Argentina/Buenos_Aires"
    },
    {
        "name": "Junin",
        "country": "Argentina",
        "province": "Ciudad de Buenos Aires",
        "timezone": "America/Argentina/Buenos_Aires"
    },
    {
        "name": "La Plata",
        "country": "Argentina",
        "province": "Ciudad de Buenos Aires",
        "timezone": "America/Argentina/Buenos_Aires"
    },
    {
        "name": "Pergamino",
        "country": "Argentina",
        "province": "Ciudad de Buenos Aires",
        "timezone": "America/Argentina/Buenos_Aires"
    },
    {
        "name": "Lujan",
        "country": "Argentina",
        "province": "Ciudad de Buenos Aires",
        "timezone": "America/Argentina/Buenos_Aires"
    },
    {
        "name": "Azul",
        "country": "Argentina",
        "province": "Ciudad de Buenos Aires",
        "timezone": "America/Argentina/Buenos_Aires"
    },
    {
        "name": "Villalonga",
        "country": "Argentina",
        "province": "Ciudad de Buenos Aires",
        "timezone": "America/Argentina/Buenos_Aires"
    },
    {
        "name": "Victorica",
        "country": "Argentina",
        "province": "La Pampa",
        "timezone": "America/Argentina/Salta"
    },
    {
        "name": "General Pico",
        "country": "Argentina",
        "province": "La Pampa",
        "timezone": "America/Argentina/Salta"
    },
    {
        "name": "San Antonio Oeste",
        "country": "Argentina",
        "province": "Río Negro",
        "timezone": "America/Argentina/Salta"
    },
    {
        "name": "Sierra Colorado",
        "country": "Argentina",
        "province": "Río Negro",
        "timezone": "America/Argentina/Salta"
    },
    {
        "name": "Mercedes",
        "country": "Argentina",
        "province": "San Luis",
        "timezone": "America/Argentina/San_Luis"
    },
    {
        "name": "Rio Tercero",
        "country": "Argentina",
        "province": "Córdoba",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Belen",
        "country": "Argentina",
        "province": "Catamarca",
        "timezone": "America/Argentina/Catamarca"
    },
    {
        "name": "Rinconada",
        "country": "Argentina",
        "province": "Jujuy",
        "timezone": "America/Argentina/Jujuy"
    },
    {
        "name": "San Pedro",
        "country": "Argentina",
        "province": "Jujuy",
        "timezone": "America/Argentina/Jujuy"
    },
    {
        "name": "Libertador General San Martin",
        "country": "Argentina",
        "province": "Jujuy",
        "timezone": "America/Argentina/Jujuy"
    },
    {
        "name": "Chilecito",
        "country": "Argentina",
        "province": "La Rioja",
        "timezone": null
    },
    {
        "name": "Chamical",
        "country": "Argentina",
        "province": "La Rioja",
        "timezone": "America/Argentina/La_Rioja"
    },
    {
        "name": "Los Blancos",
        "country": "Argentina",
        "province": "Salta",
        "timezone": "America/Argentina/Salta"
    },
    {
        "name": "Cafayate",
        "country": "Argentina",
        "province": "Salta",
        "timezone": "America/Argentina/Salta"
    },
    {
        "name": "Cerrillos",
        "country": "Argentina",
        "province": "Salta",
        "timezone": "America/Argentina/Salta"
    },
    {
        "name": "San Antonio de los Cobres",
        "country": "Argentina",
        "province": "Salta",
        "timezone": "America/Argentina/Salta"
    },
    {
        "name": "Anatuya",
        "country": "Argentina",
        "province": "Santiago del Estero",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Frias",
        "country": "Argentina",
        "province": "Santiago del Estero",
        "timezone": "America/Argentina/Catamarca"
    },
    {
        "name": "Monte Quemado",
        "country": "Argentina",
        "province": "Santiago del Estero",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Juan Jose Castelli",
        "country": "Argentina",
        "province": "Chaco",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Charata",
        "country": "Argentina",
        "province": "Chaco",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Las Lomitas",
        "country": "Argentina",
        "province": "Formosa",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Mercedes",
        "country": "Argentina",
        "province": "Corrientes",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Concordia",
        "country": "Argentina",
        "province": "Entre Ríos",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Sunchales",
        "country": "Argentina",
        "province": "Santa Fe",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "San Justo",
        "country": "Argentina",
        "province": "Santa Fe",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Vera",
        "country": "Argentina",
        "province": "Santa Fe",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Reconquista",
        "country": "Argentina",
        "province": "Santa Fe",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Venado Tuerto",
        "country": "Argentina",
        "province": "Santa Fe",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Esquel",
        "country": "Argentina",
        "province": "Chubut",
        "timezone": "America/Argentina/Catamarca"
    },
    {
        "name": "Zapala",
        "country": "Argentina",
        "province": "Neuquén",
        "timezone": "America/Argentina/Salta"
    },
    {
        "name": "Olavarria",
        "country": "Argentina",
        "province": "Ciudad de Buenos Aires",
        "timezone": "America/Argentina/Buenos_Aires"
    },
    {
        "name": "Tandil",
        "country": "Argentina",
        "province": "Ciudad de Buenos Aires",
        "timezone": "America/Argentina/Buenos_Aires"
    },
    {
        "name": "Viedma",
        "country": "Argentina",
        "province": "Ciudad de Buenos Aires",
        "timezone": "America/Argentina/Salta"
    },
    {
        "name": "San Luis",
        "country": "Argentina",
        "province": "San Luis",
        "timezone": "America/Argentina/San_Luis"
    },
    {
        "name": "Rio Cuarto",
        "country": "Argentina",
        "province": "Córdoba",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "San Salvador de Jujuy",
        "country": "Argentina",
        "province": "Jujuy",
        "timezone": "America/Argentina/Jujuy"
    },
    {
        "name": "San Ramon de la Nueva Oran",
        "country": "Argentina",
        "province": "Salta",
        "timezone": "America/Argentina/Salta"
    },
    {
        "name": "Goya",
        "country": "Argentina",
        "province": "Corrientes",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Puerto San Julian",
        "country": "Argentina",
        "province": "Santa Cruz",
        "timezone": "America/Argentina/Rio_Gallegos"
    },
    {
        "name": "Perito Moreno",
        "country": "Argentina",
        "province": "Santa Cruz",
        "timezone": "America/Argentina/Rio_Gallegos"
    },
    {
        "name": "Rio Grande",
        "country": "Argentina",
        "province": "Tierra del Fuego",
        "timezone": "America/Argentina/Ushuaia"
    },
    {
        "name": "Ushuaia",
        "country": "Argentina",
        "province": "Tierra del Fuego",
        "timezone": "America/Argentina/Ushuaia"
    },
    {
        "name": "Sarmiento",
        "country": "Argentina",
        "province": "Chubut",
        "timezone": "America/Argentina/Catamarca"
    },
    {
        "name": "San Rafael",
        "country": "Argentina",
        "province": "Mendoza",
        "timezone": "America/Argentina/Mendoza"
    },
    {
        "name": "Necochea",
        "country": "Argentina",
        "province": "Ciudad de Buenos Aires",
        "timezone": "America/Argentina/Buenos_Aires"
    },
    {
        "name": "Rio Colorado",
        "country": "Argentina",
        "province": "La Pampa",
        "timezone": "America/Argentina/Salta"
    },
    {
        "name": "Catamarca",
        "country": "Argentina",
        "province": "Catamarca",
        "timezone": "America/Argentina/Catamarca"
    },
    {
        "name": "La Rioja",
        "country": "Argentina",
        "province": "La Rioja",
        "timezone": "America/Argentina/La_Rioja"
    },
    {
        "name": "Santiago del Estero",
        "country": "Argentina",
        "province": "Santiago del Estero",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Resistencia",
        "country": "Argentina",
        "province": "Chaco",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Gualeguaychu",
        "country": "Argentina",
        "province": "Entre Ríos",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "El Calafate",
        "country": "Argentina",
        "province": "Santa Cruz",
        "timezone": "America/Argentina/Rio_Gallegos"
    },
    {
        "name": "San Juan",
        "country": "Argentina",
        "province": "San Juan",
        "timezone": "America/Argentina/San_Juan"
    },
    {
        "name": "Rawson",
        "country": "Argentina",
        "province": "Chubut",
        "timezone": "America/Argentina/Catamarca"
    },
    {
        "name": "Neuquen",
        "country": "Argentina",
        "province": "Neuquén",
        "timezone": "America/Argentina/Salta"
    },
    {
        "name": "Santa Rosa",
        "country": "Argentina",
        "province": "La Pampa",
        "timezone": "America/Argentina/Salta"
    },
    {
        "name": "San Carlos de Bariloche",
        "country": "Argentina",
        "province": "Río Negro",
        "timezone": "America/Argentina/Salta"
    },
    {
        "name": "Salta",
        "country": "Argentina",
        "province": "Salta",
        "timezone": "America/Argentina/Salta"
    },
    {
        "name": "Tucumán",
        "country": "Argentina",
        "province": "Tucumán",
        "timezone": "America/Argentina/Tucuman"
    },
    {
        "name": "Formosa",
        "country": "Argentina",
        "province": "Formosa",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Santa Fe",
        "country": "Argentina",
        "province": "Santa Fe",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Rosario",
        "country": "Argentina",
        "province": "Santa Fe",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Puerto Deseado",
        "country": "Argentina",
        "province": "Santa Cruz",
        "timezone": "America/Argentina/Rio_Gallegos"
    },
    {
        "name": "Rio Gallegos",
        "country": "Argentina",
        "province": "Santa Cruz",
        "timezone": "America/Argentina/Rio_Gallegos"
    },
    {
        "name": "Comodoro Rivadavia",
        "country": "Argentina",
        "province": "Chubut",
        "timezone": null
    },
    {
        "name": "Mendoza",
        "country": "Argentina",
        "province": "Mendoza",
        "timezone": "America/Argentina/Mendoza"
    },
    {
        "name": "Bahia Blanca",
        "country": "Argentina",
        "province": "Ciudad de Buenos Aires",
        "timezone": "America/Argentina/Buenos_Aires"
    },
    {
        "name": "Mar del Plata",
        "country": "Argentina",
        "province": "Ciudad de Buenos Aires",
        "timezone": "America/Argentina/Buenos_Aires"
    },
    {
        "name": "Córdoba",
        "country": "Argentina",
        "province": "Córdoba",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Posadas",
        "country": "Argentina",
        "province": "Misiones",
        "timezone": "America/Argentina/Cordoba"
    },
    {
        "name": "Buenos Aires",
        "country": "Argentina",
        "province": "Ciudad de Buenos Aires",
        "timezone": "America/Argentina/Buenos_Aires"
    },
    {
        "name": "Ashtarak",
        "country": "Armenia",
        "province": "Aragatsotn",
        "timezone": "Asia/Yerevan"
    },
    {
        "name": "Ijevan",
        "country": "Armenia",
        "province": "Tavush",
        "timezone": "Asia/Yerevan"
    },
    {
        "name": "Artashat",
        "country": "Armenia",
        "province": "Ararat",
        "timezone": "Asia/Yerevan"
    },
    {
        "name": "Gavarr",
        "country": "Armenia",
        "province": "Gegharkunik",
        "timezone": "Asia/Yerevan"
    },
    {
        "name": "Yeghegnadzor",
        "country": "Armenia",
        "province": "Vayots Dzor",
        "timezone": "Asia/Yerevan"
    },
    {
        "name": "Gyumri",
        "country": "Armenia",
        "province": "Shirak",
        "timezone": "Asia/Yerevan"
    },
    {
        "name": "Vanadzor",
        "country": "Armenia",
        "province": "Lori",
        "timezone": "Asia/Yerevan"
    },
    {
        "name": "Yerevan",
        "country": "Armenia",
        "province": "Erevan",
        "timezone": "Asia/Yerevan"
    },
    {
        "name": "Oranjestad",
        "country": "Aruba",
        "province": "",
        "timezone": "America/Aruba"
    },
    {
        "name": "Central Coast",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Sunshine Coast",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Bourke",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Pine Creek",
        "country": "Australia",
        "province": "Northern Territory",
        "timezone": "Australia/Darwin"
    },
    {
        "name": "Adelaide River",
        "country": "Australia",
        "province": "Northern Territory",
        "timezone": "Australia/Darwin"
    },
    {
        "name": "McMinns Lagoon",
        "country": "Australia",
        "province": "Northern Territory",
        "timezone": "Australia/Darwin"
    },
    {
        "name": "Newcastle Waters",
        "country": "Australia",
        "province": "Northern Territory",
        "timezone": "Australia/Darwin"
    },
    {
        "name": "Ravensthorpe",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Wagin",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Roebourne",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Pannawonica",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Tom Price",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Kalbarri",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Mount Magnet",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Morawa",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Port Denison",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Merredin",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Mount Barker",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Katanning",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Narrogin",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Gingin",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Bunbury",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Kwinana",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Southern Cross",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Kaltukatjara",
        "country": "Australia",
        "province": "Northern Territory",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Queanbeyan",
        "country": "Australia",
        "province": "Australian Capital Territory",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Tweed Heads",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Ivanhoe",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Wilcannia",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Merimbula",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Echuca",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Melbourne"
    },
    {
        "name": "Deniliquin",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Nowra",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Ulladulla",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Batemans Bay",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Cooma",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Tumut",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Leeton",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Young",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Cowra",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Forbes",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Goulburn",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Kiama",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Katoomba",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Richmond",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Lithgow",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Parkes",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Bathurst",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Maitland",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Singleton",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Mudgee",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Muswellbrook",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Taree",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Kempsey",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Gunnedah",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Coffs Harbour",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Narrabri",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Inverell",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Yamba",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Ballina",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Wagga Wagga",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Scone",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Byron Bay",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Berri",
        "country": "Australia",
        "province": "South Australia",
        "timezone": "Australia/Adelaide"
    },
    {
        "name": "Peterborough",
        "country": "Australia",
        "province": "South Australia",
        "timezone": "Australia/Adelaide"
    },
    {
        "name": "Wallaroo",
        "country": "Australia",
        "province": "South Australia",
        "timezone": "Australia/Adelaide"
    },
    {
        "name": "Clare",
        "country": "Australia",
        "province": "South Australia",
        "timezone": "Australia/Adelaide"
    },
    {
        "name": "Meningie",
        "country": "Australia",
        "province": "South Australia",
        "timezone": "Australia/Adelaide"
    },
    {
        "name": "Kingston South East",
        "country": "Australia",
        "province": "South Australia",
        "timezone": "Australia/Adelaide"
    },
    {
        "name": "Bordertown",
        "country": "Australia",
        "province": "South Australia",
        "timezone": "Australia/Adelaide"
    },
    {
        "name": "Penola",
        "country": "Australia",
        "province": "South Australia",
        "timezone": "Australia/Adelaide"
    },
    {
        "name": "Kingoonya",
        "country": "Australia",
        "province": "South Australia",
        "timezone": "Australia/Adelaide"
    },
    {
        "name": "Kimba",
        "country": "Australia",
        "province": "South Australia",
        "timezone": "Australia/Adelaide"
    },
    {
        "name": "Streaky Bay",
        "country": "Australia",
        "province": "South Australia",
        "timezone": "Australia/Adelaide"
    },
    {
        "name": "Cowell",
        "country": "Australia",
        "province": "South Australia",
        "timezone": "Australia/Adelaide"
    },
    {
        "name": "Tumby Bay",
        "country": "Australia",
        "province": "South Australia",
        "timezone": "Australia/Adelaide"
    },
    {
        "name": "Andamooka",
        "country": "Australia",
        "province": "South Australia",
        "timezone": "Australia/Adelaide"
    },
    {
        "name": "Woomera",
        "country": "Australia",
        "province": "South Australia",
        "timezone": "Australia/Adelaide"
    },
    {
        "name": "Port Pirie",
        "country": "Australia",
        "province": "South Australia",
        "timezone": "Australia/Adelaide"
    },
    {
        "name": "Gawler",
        "country": "Australia",
        "province": "South Australia",
        "timezone": "Australia/Adelaide"
    },
    {
        "name": "Murray Bridge",
        "country": "Australia",
        "province": "South Australia",
        "timezone": "Australia/Adelaide"
    },
    {
        "name": "Victor Harbor",
        "country": "Australia",
        "province": "South Australia",
        "timezone": "Australia/Adelaide"
    },
    {
        "name": "Hamilton",
        "country": "Australia",
        "province": "Victoria",
        "timezone": "Australia/Melbourne"
    },
    {
        "name": "Ouyen",
        "country": "Australia",
        "province": "Victoria",
        "timezone": "Australia/Melbourne"
    },
    {
        "name": "Colac",
        "country": "Australia",
        "province": "Victoria",
        "timezone": "Australia/Melbourne"
    },
    {
        "name": "Stawell",
        "country": "Australia",
        "province": "Victoria",
        "timezone": "Australia/Melbourne"
    },
    {
        "name": "Horsham",
        "country": "Australia",
        "province": "Victoria",
        "timezone": "Australia/Melbourne"
    },
    {
        "name": "Ararat",
        "country": "Australia",
        "province": "Victoria",
        "timezone": "Australia/Melbourne"
    },
    {
        "name": "Maryborough",
        "country": "Australia",
        "province": "Victoria",
        "timezone": "Australia/Melbourne"
    },
    {
        "name": "Bairnsdale",
        "country": "Australia",
        "province": "Victoria",
        "timezone": "Australia/Melbourne"
    },
    {
        "name": "Sale",
        "country": "Australia",
        "province": "Victoria",
        "timezone": "Australia/Melbourne"
    },
    {
        "name": "Traralgon",
        "country": "Australia",
        "province": "Victoria",
        "timezone": "Australia/Melbourne"
    },
    {
        "name": "Wonthaggi",
        "country": "Australia",
        "province": "Victoria",
        "timezone": "Australia/Melbourne"
    },
    {
        "name": "Cranbourne",
        "country": "Australia",
        "province": "Victoria",
        "timezone": "Australia/Melbourne"
    },
    {
        "name": "Ballarat",
        "country": "Australia",
        "province": "Victoria",
        "timezone": "Australia/Melbourne"
    },
    {
        "name": "Melton",
        "country": "Australia",
        "province": "Victoria",
        "timezone": "Australia/Melbourne"
    },
    {
        "name": "Seymour",
        "country": "Australia",
        "province": "Victoria",
        "timezone": "Australia/Melbourne"
    },
    {
        "name": "Shepparton",
        "country": "Australia",
        "province": "Victoria",
        "timezone": "Australia/Melbourne"
    },
    {
        "name": "Cobram",
        "country": "Australia",
        "province": "Victoria",
        "timezone": "Australia/Melbourne"
    },
    {
        "name": "Swan Hill",
        "country": "Australia",
        "province": "Victoria",
        "timezone": "Australia/Melbourne"
    },
    {
        "name": "Sunbury",
        "country": "Australia",
        "province": "Victoria",
        "timezone": "Australia/Melbourne"
    },
    {
        "name": "Proserpine",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Theodore",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Eidsvold",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Barcaldine",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Winton",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Longreach",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Caboolture",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Warwick",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Kingaroy",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Dalby",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Bongaree",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Gympie",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Ingham",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Birdsville",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Bedourie",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Boulia",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Richmond",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Burketown",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Hervey Bay",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Biloela",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Yeppoon",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Emerald",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Moranbah",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Charters Towers",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Ayr",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Atherton",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Port Douglas",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Smithton",
        "country": "Australia",
        "province": "Tasmania",
        "timezone": "Australia/Hobart"
    },
    {
        "name": "Scottsdale",
        "country": "Australia",
        "province": "Tasmania",
        "timezone": "Australia/Hobart"
    },
    {
        "name": "Bicheno",
        "country": "Australia",
        "province": "Tasmania",
        "timezone": "Australia/Hobart"
    },
    {
        "name": "Oatlands",
        "country": "Australia",
        "province": "Tasmania",
        "timezone": "Australia/Hobart"
    },
    {
        "name": "Queenstown",
        "country": "Australia",
        "province": "Tasmania",
        "timezone": "Australia/Hobart"
    },
    {
        "name": "Kingston",
        "country": "Australia",
        "province": "Tasmania",
        "timezone": "Australia/Hobart"
    },
    {
        "name": "Tennant Creek",
        "country": "Australia",
        "province": "Northern Territory",
        "timezone": "Australia/Darwin"
    },
    {
        "name": "Yulara",
        "country": "Australia",
        "province": "Northern Territory",
        "timezone": "Australia/Darwin"
    },
    {
        "name": "Erldunda",
        "country": "Australia",
        "province": "Northern Territory",
        "timezone": "Australia/Darwin"
    },
    {
        "name": "Norseman",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Halls Creek",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Kununurra",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Derby",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Onslow",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Exmouth",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Carnarvon",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Newman",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Meekatharra",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Three Springs",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Manjimup",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Northam",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Esperance",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Leonara",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Laverton",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Wyndham",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Albury",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Forster-Tuncurry",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Port Macquarie",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Tamworth",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Grafton",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Moree",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Goondiwindi",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Lismore",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Wollongong",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Ceduna",
        "country": "Australia",
        "province": "South Australia",
        "timezone": "Australia/Adelaide"
    },
    {
        "name": "Mount Gambier",
        "country": "Australia",
        "province": "South Australia",
        "timezone": "Australia/Adelaide"
    },
    {
        "name": "Port Augusta",
        "country": "Australia",
        "province": "South Australia",
        "timezone": "Australia/Adelaide"
    },
    {
        "name": "Warrnambool",
        "country": "Australia",
        "province": "Victoria",
        "timezone": "Australia/Melbourne"
    },
    {
        "name": "Mildura",
        "country": "Australia",
        "province": "Victoria",
        "timezone": "Australia/Melbourne"
    },
    {
        "name": "Geelong",
        "country": "Australia",
        "province": "Victoria",
        "timezone": "Australia/Melbourne"
    },
    {
        "name": "Camooweal",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Quilpie",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Charleville",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Hughenden",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Caloundra",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Roma",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Toowoomba",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Georgetown",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Thargomindah",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Weipa",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Karumba",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Cloncurry",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Maryborough",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Bundaberg",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Gladstone",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Bowen",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Innisfail",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Mackay",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Burnie",
        "country": "Australia",
        "province": "Tasmania",
        "timezone": "Australia/Hobart"
    },
    {
        "name": "Launceston",
        "country": "Australia",
        "province": "Tasmania",
        "timezone": "Australia/Hobart"
    },
    {
        "name": "Katherine",
        "country": "Australia",
        "province": "Northern Territory",
        "timezone": "Australia/Darwin"
    },
    {
        "name": "Busselton",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Mandurah",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Broome",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Kalgoorlie",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Albany",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Port Hedland",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Karratha",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Geraldton",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Griffith",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Orange",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Dubbo",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Armidale",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Broken Hill",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Broken_Hill"
    },
    {
        "name": "Port Lincoln",
        "country": "Australia",
        "province": "South Australia",
        "timezone": "Australia/Adelaide"
    },
    {
        "name": "Whyalla",
        "country": "Australia",
        "province": "South Australia",
        "timezone": "Australia/Adelaide"
    },
    {
        "name": "Portland",
        "country": "Australia",
        "province": "Victoria",
        "timezone": "Australia/Melbourne"
    },
    {
        "name": "Bendigo",
        "country": "Australia",
        "province": "Victoria",
        "timezone": "Australia/Melbourne"
    },
    {
        "name": "Wangaratta",
        "country": "Australia",
        "province": "Victoria",
        "timezone": "Australia/Melbourne"
    },
    {
        "name": "Windorah",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Mount Isa",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Rockhampton",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Cairns",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Gold Coast",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Devonport",
        "country": "Australia",
        "province": "Tasmania",
        "timezone": "Australia/Hobart"
    },
    {
        "name": "Darwin",
        "country": "Australia",
        "province": "Northern Territory",
        "timezone": "Australia/Darwin"
    },
    {
        "name": "Alice Springs",
        "country": "Australia",
        "province": "Northern Territory",
        "timezone": "Australia/Darwin"
    },
    {
        "name": "Canberra",
        "country": "Australia",
        "province": "Australian Capital Territory",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Newcastle",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Adelaide",
        "country": "Australia",
        "province": "South Australia",
        "timezone": "Australia/Adelaide"
    },
    {
        "name": "Townsville",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Brisbane",
        "country": "Australia",
        "province": "Queensland",
        "timezone": "Australia/Brisbane"
    },
    {
        "name": "Hobart",
        "country": "Australia",
        "province": "Tasmania",
        "timezone": "Australia/Hobart"
    },
    {
        "name": "Perth",
        "country": "Australia",
        "province": "Western Australia",
        "timezone": "Australia/Perth"
    },
    {
        "name": "Melbourne",
        "country": "Australia",
        "province": "Victoria",
        "timezone": "Australia/Melbourne"
    },
    {
        "name": "Sydney",
        "country": "Australia",
        "province": "New South Wales",
        "timezone": "Australia/Sydney"
    },
    {
        "name": "Bregenz",
        "country": "Austria",
        "province": "Vorarlberg",
        "timezone": "Europe/Vienna"
    },
    {
        "name": "Eisenstadt",
        "country": "Austria",
        "province": "Burgenland",
        "timezone": "Europe/Vienna"
    },
    {
        "name": "Wiener Neustadt",
        "country": "Austria",
        "province": "Niederösterreich",
        "timezone": "Europe/Vienna"
    },
    {
        "name": "Graz",
        "country": "Austria",
        "province": "Steiermark",
        "timezone": "Europe/Vienna"
    },
    {
        "name": "Klagenfurt",
        "country": "Austria",
        "province": "Kärnten",
        "timezone": "Europe/Vienna"
    },
    {
        "name": "Linz",
        "country": "Austria",
        "province": "Oberösterreich",
        "timezone": "Europe/Vienna"
    },
    {
        "name": "Passau",
        "country": "Austria",
        "province": "Oberösterreich",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Salzburg",
        "country": "Austria",
        "province": "Salzburg",
        "timezone": "Europe/Vienna"
    },
    {
        "name": "Innsbruck",
        "country": "Austria",
        "province": "Tirol",
        "timezone": "Europe/Vienna"
    },
    {
        "name": "Vienna",
        "country": "Austria",
        "province": "Wien",
        "timezone": "Europe/Vienna"
    },
    {
        "name": "Gadabay",
        "country": "Azerbaijan",
        "province": "Gadabay",
        "timezone": "Asia/Baku"
    },
    {
        "name": "Goranboy",
        "country": "Azerbaijan",
        "province": "Goranboy",
        "timezone": "Asia/Baku"
    },
    {
        "name": "Tovuz",
        "country": "Azerbaijan",
        "province": "Tovuz",
        "timezone": "Asia/Baku"
    },
    {
        "name": "Agdam",
        "country": "Azerbaijan",
        "province": "Tovuz",
        "timezone": "Asia/Baku"
    },
    {
        "name": "Qabala",
        "country": "Azerbaijan",
        "province": "Qabala",
        "timezone": "Asia/Baku"
    },
    {
        "name": "Oguz",
        "country": "Azerbaijan",
        "province": "Oguz",
        "timezone": "Asia/Baku"
    },
    {
        "name": "Ganca",
        "country": "Azerbaijan",
        "province": "Ganca",
        "timezone": "Asia/Baku"
    },
    {
        "name": "Yevlax",
        "country": "Azerbaijan",
        "province": "Yevlax",
        "timezone": "Asia/Baku"
    },
    {
        "name": "Sumqayt",
        "country": "Azerbaijan",
        "province": "Sumqayit",
        "timezone": "Asia/Baku"
    },
    {
        "name": "Ali Bayramli",
        "country": "Azerbaijan",
        "province": "?li Bayramli",
        "timezone": "Asia/Baku"
    },
    {
        "name": "Goycay",
        "country": "Azerbaijan",
        "province": "Göyçay",
        "timezone": "Asia/Baku"
    },
    {
        "name": "Lankaran",
        "country": "Azerbaijan",
        "province": "Astara",
        "timezone": "Asia/Baku"
    },
    {
        "name": "Saki",
        "country": "Azerbaijan",
        "province": "S?ki",
        "timezone": "Asia/Baku"
    },
    {
        "name": "Stepanakert",
        "country": "Azerbaijan",
        "province": "Xocali",
        "timezone": "Asia/Baku"
    },
    {
        "name": "Kapan",
        "country": "Azerbaijan",
        "province": "Z?ngilan",
        "timezone": "Asia/Yerevan"
    },
    {
        "name": "Naxcivan",
        "country": "Azerbaijan",
        "province": "Naxçivan",
        "timezone": "Asia/Baku"
    },
    {
        "name": "Baku",
        "country": "Azerbaijan",
        "province": "Baki",
        "timezone": "Asia/Baku"
    },
    {
        "name": "Manama",
        "country": "Bahrain",
        "province": "",
        "timezone": "Asia/Bahrain"
    },
    {
        "name": "Tangail",
        "country": "Bangladesh",
        "province": "Dhaka",
        "timezone": "Asia/Dhaka"
    },
    {
        "name": "Sylhet",
        "country": "Bangladesh",
        "province": "Sylhet",
        "timezone": "Asia/Dhaka"
    },
    {
        "name": "Mymensingh",
        "country": "Bangladesh",
        "province": "Dhaka",
        "timezone": "Asia/Dhaka"
    },
    {
        "name": "Jamalpur",
        "country": "Bangladesh",
        "province": "Dhaka",
        "timezone": "Asia/Dhaka"
    },
    {
        "name": "Narayanganj",
        "country": "Bangladesh",
        "province": "Dhaka",
        "timezone": "Asia/Dhaka"
    },
    {
        "name": "Jessore",
        "country": "Bangladesh",
        "province": "Khulna",
        "timezone": "Asia/Dhaka"
    },
    {
        "name": "Barisal",
        "country": "Bangladesh",
        "province": "Barisal",
        "timezone": "Asia/Dhaka"
    },
    {
        "name": "Comilla",
        "country": "Bangladesh",
        "province": "Chittagong",
        "timezone": "Asia/Dhaka"
    },
    {
        "name": "Pabna",
        "country": "Bangladesh",
        "province": "Rajshahi",
        "timezone": "Asia/Dhaka"
    },
    {
        "name": "Nawabganj",
        "country": "Bangladesh",
        "province": "Rajshahi",
        "timezone": "Asia/Dhaka"
    },
    {
        "name": "Saidpur",
        "country": "Bangladesh",
        "province": "Rajshahi",
        "timezone": "Asia/Dhaka"
    },
    {
        "name": "Rangpur",
        "country": "Bangladesh",
        "province": "Rajshahi",
        "timezone": "Asia/Dhaka"
    },
    {
        "name": "Khulna",
        "country": "Bangladesh",
        "province": "Khulna",
        "timezone": "Asia/Dhaka"
    },
    {
        "name": "Rajshahi",
        "country": "Bangladesh",
        "province": "Rajshahi",
        "timezone": "Asia/Dhaka"
    },
    {
        "name": "Dhaka",
        "country": "Bangladesh",
        "province": "Dhaka",
        "timezone": "Asia/Dhaka"
    },
    {
        "name": "Chittagong",
        "country": "Bangladesh",
        "province": "Chittagong",
        "timezone": "Asia/Dhaka"
    },
    {
        "name": "Bridgetown",
        "country": "Barbados",
        "province": "Saint Michael",
        "timezone": "America/Barbados"
    },
    {
        "name": "Baranavichy",
        "country": "Belarus",
        "province": "Brest",
        "timezone": "Europe/Minsk"
    },
    {
        "name": "Polatsk",
        "country": "Belarus",
        "province": "Vitsyebsk",
        "timezone": "Europe/Minsk"
    },
    {
        "name": "Maladzyechna",
        "country": "Belarus",
        "province": "Minsk",
        "timezone": "Europe/Minsk"
    },
    {
        "name": "Pinsk",
        "country": "Belarus",
        "province": "Brest",
        "timezone": "Europe/Minsk"
    },
    {
        "name": "Mazyr",
        "country": "Belarus",
        "province": "Homyel'",
        "timezone": "Europe/Minsk"
    },
    {
        "name": "Mahilyow",
        "country": "Belarus",
        "province": "Mahilyow",
        "timezone": "Europe/Minsk"
    },
    {
        "name": "Babruysk",
        "country": "Belarus",
        "province": "Mahilyow",
        "timezone": "Europe/Minsk"
    },
    {
        "name": "Orsha",
        "country": "Belarus",
        "province": "Vitsyebsk",
        "timezone": "Europe/Minsk"
    },
    {
        "name": "Lida",
        "country": "Belarus",
        "province": "Hrodna",
        "timezone": "Europe/Minsk"
    },
    {
        "name": "Hrodna",
        "country": "Belarus",
        "province": "Hrodna",
        "timezone": "Europe/Minsk"
    },
    {
        "name": "Barysaw",
        "country": "Belarus",
        "province": "Minsk",
        "timezone": "Europe/Minsk"
    },
    {
        "name": "Homyel",
        "country": "Belarus",
        "province": "Homyel'",
        "timezone": "Europe/Minsk"
    },
    {
        "name": "Vitsyebsk",
        "country": "Belarus",
        "province": "Vitsyebsk",
        "timezone": "Europe/Minsk"
    },
    {
        "name": "Brest",
        "country": "Belarus",
        "province": "Brest",
        "timezone": "Europe/Minsk"
    },
    {
        "name": "Minsk",
        "country": "Belarus",
        "province": "Minsk",
        "timezone": "Europe/Minsk"
    },
    {
        "name": "Mons",
        "country": "Belgium",
        "province": "Hainaut",
        "timezone": "Europe/Brussels"
    },
    {
        "name": "Hasselt",
        "country": "Belgium",
        "province": "Limburg",
        "timezone": "Europe/Brussels"
    },
    {
        "name": "Arlon",
        "country": "Belgium",
        "province": "Arlon",
        "timezone": "Europe/Brussels"
    },
    {
        "name": "Gent",
        "country": "Belgium",
        "province": "East Flanders",
        "timezone": "Europe/Brussels"
    },
    {
        "name": "Liege",
        "country": "Belgium",
        "province": "Liege",
        "timezone": "Europe/Brussels"
    },
    {
        "name": "Brugge",
        "country": "Belgium",
        "province": "Brugge",
        "timezone": "Europe/Brussels"
    },
    {
        "name": "Namur",
        "country": "Belgium",
        "province": "Namur",
        "timezone": "Europe/Brussels"
    },
    {
        "name": "Charleroi",
        "country": "Belgium",
        "province": "Charleroi",
        "timezone": "Europe/Brussels"
    },
    {
        "name": "Antwerpen",
        "country": "Belgium",
        "province": "Antwerp",
        "timezone": "Europe/Brussels"
    },
    {
        "name": "Brussels",
        "country": "Belgium",
        "province": "Brussels",
        "timezone": "Europe/Brussels"
    },
    {
        "name": "El Cayo",
        "country": "Belize",
        "province": "Cayo",
        "timezone": "America/Belize"
    },
    {
        "name": "Corozal",
        "country": "Belize",
        "province": "Corozal",
        "timezone": "America/Belize"
    },
    {
        "name": "Dangriga",
        "country": "Belize",
        "province": "Stann Creek",
        "timezone": "America/Belize"
    },
    {
        "name": "Belize City",
        "country": "Belize",
        "province": "Belize",
        "timezone": "America/Belize"
    },
    {
        "name": "Orange Walk",
        "country": "Belize",
        "province": "Orange Walk",
        "timezone": "America/Belize"
    },
    {
        "name": "Punta Gorda",
        "country": "Belize",
        "province": "Toledo",
        "timezone": "America/Belize"
    },
    {
        "name": "Belmopan",
        "country": "Belize",
        "province": "Cayo",
        "timezone": "America/Belize"
    },
    {
        "name": "Lokossa",
        "country": "Benin",
        "province": "Mono",
        "timezone": "Africa/Porto-Novo"
    },
    {
        "name": "Kandi",
        "country": "Benin",
        "province": "Alibori",
        "timezone": "Africa/Porto-Novo"
    },
    {
        "name": "Ouidah",
        "country": "Benin",
        "province": "Atlantique",
        "timezone": "Africa/Porto-Novo"
    },
    {
        "name": "Abomey",
        "country": "Benin",
        "province": "Zou",
        "timezone": "Africa/Porto-Novo"
    },
    {
        "name": "Natitingou",
        "country": "Benin",
        "province": "Atakora",
        "timezone": "Africa/Porto-Novo"
    },
    {
        "name": "Djougou",
        "country": "Benin",
        "province": "Donga",
        "timezone": "Africa/Porto-Novo"
    },
    {
        "name": "Parakou",
        "country": "Benin",
        "province": "Borgou",
        "timezone": "Africa/Porto-Novo"
    },
    {
        "name": "Porto-Novo",
        "country": "Benin",
        "province": "Ouémé",
        "timezone": "Africa/Porto-Novo"
    },
    {
        "name": "Cotonou",
        "country": "Benin",
        "province": "Ouémé",
        "timezone": "Africa/Porto-Novo"
    },
    {
        "name": "Hamilton",
        "country": "Bermuda",
        "province": "",
        "timezone": "Atlantic/Bermuda"
    },
    {
        "name": "Paro",
        "country": "Bhutan",
        "province": "Thimphu",
        "timezone": "Asia/Thimphu"
    },
    {
        "name": "Punakha",
        "country": "Bhutan",
        "province": "Punakha",
        "timezone": "Asia/Thimphu"
    },
    {
        "name": "Wangdue Prodrang",
        "country": "Bhutan",
        "province": "Wangdi Phodrang",
        "timezone": "Asia/Thimphu"
    },
    {
        "name": "Thimphu",
        "country": "Bhutan",
        "province": "Thimphu",
        "timezone": "Asia/Thimphu"
    },
    {
        "name": "Punata",
        "country": "Bolivia",
        "province": "Cochabamba",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Cliza",
        "country": "Bolivia",
        "province": "Cochabamba",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Quillacollo",
        "country": "Bolivia",
        "province": "Cochabamba",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Puerto Villarroel",
        "country": "Bolivia",
        "province": "Cochabamba",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Tarabuco",
        "country": "Bolivia",
        "province": "Chuquisaca",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Guayaramerin",
        "country": "Bolivia",
        "province": "El Beni",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Santa Ana",
        "country": "Bolivia",
        "province": "El Beni",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Baures",
        "country": "Bolivia",
        "province": "El Beni",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Sica Sica",
        "country": "Bolivia",
        "province": "La Paz",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Rurrenabaque",
        "country": "Bolivia",
        "province": "La Paz",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Sorata",
        "country": "Bolivia",
        "province": "La Paz",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Achacachi",
        "country": "Bolivia",
        "province": "La Paz",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Viacha",
        "country": "Bolivia",
        "province": "La Paz",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Quime",
        "country": "Bolivia",
        "province": "La Paz",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Llallagua",
        "country": "Bolivia",
        "province": "Potosí",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Uncia",
        "country": "Bolivia",
        "province": "Potosí",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Uyuni",
        "country": "Bolivia",
        "province": "Potosí",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Villa Martin",
        "country": "Bolivia",
        "province": "Potosí",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Betanzos",
        "country": "Bolivia",
        "province": "Potosí",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Portachuelo",
        "country": "Bolivia",
        "province": "Santa Cruz",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Samaipata",
        "country": "Bolivia",
        "province": "Santa Cruz",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Cuevo",
        "country": "Bolivia",
        "province": "Santa Cruz",
        "timezone": "America/La_Paz"
    },
    {
        "name": "San Carlos",
        "country": "Bolivia",
        "province": "Santa Cruz",
        "timezone": "America/La_Paz"
    },
    {
        "name": "San Lorenzo",
        "country": "Bolivia",
        "province": "Tarija",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Entre Rios",
        "country": "Bolivia",
        "province": "Tarija",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Aiquile",
        "country": "Bolivia",
        "province": "Cochabamba",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Padilla",
        "country": "Bolivia",
        "province": "Chuquisaca",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Camargo",
        "country": "Bolivia",
        "province": "Chuquisaca",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Reyes",
        "country": "Bolivia",
        "province": "El Beni",
        "timezone": "America/La_Paz"
    },
    {
        "name": "San Borja",
        "country": "Bolivia",
        "province": "El Beni",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Magdalena",
        "country": "Bolivia",
        "province": "El Beni",
        "timezone": "America/La_Paz"
    },
    {
        "name": "San Ramon",
        "country": "Bolivia",
        "province": "El Beni",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Puerto Heath",
        "country": "Bolivia",
        "province": "La Paz",
        "timezone": "America/Lima"
    },
    {
        "name": "Charana",
        "country": "Bolivia",
        "province": "La Paz",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Puerto Acosta",
        "country": "Bolivia",
        "province": "La Paz",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Apolo",
        "country": "Bolivia",
        "province": "La Paz",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Coroico",
        "country": "Bolivia",
        "province": "La Paz",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Coro Coro",
        "country": "Bolivia",
        "province": "La Paz",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Sabaya",
        "country": "Bolivia",
        "province": "Oruro",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Challapata",
        "country": "Bolivia",
        "province": "Oruro",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Llica",
        "country": "Bolivia",
        "province": "Potosí",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Potosi",
        "country": "Bolivia",
        "province": "Potosí",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Villazon",
        "country": "Bolivia",
        "province": "Potosí",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Tupiza",
        "country": "Bolivia",
        "province": "Potosí",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Montero",
        "country": "Bolivia",
        "province": "Santa Cruz",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Piso Firme",
        "country": "Bolivia",
        "province": "Santa Cruz",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Robore",
        "country": "Bolivia",
        "province": "Santa Cruz",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Puerto Quijarro",
        "country": "Bolivia",
        "province": "Santa Cruz",
        "timezone": "America/La_Paz"
    },
    {
        "name": "San Ignacio",
        "country": "Bolivia",
        "province": "Santa Cruz",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Ascension",
        "country": "Bolivia",
        "province": "Santa Cruz",
        "timezone": "America/La_Paz"
    },
    {
        "name": "San Javier",
        "country": "Bolivia",
        "province": "Santa Cruz",
        "timezone": "America/La_Paz"
    },
    {
        "name": "San Rafael",
        "country": "Bolivia",
        "province": "Santa Cruz",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Vallegrande",
        "country": "Bolivia",
        "province": "Santa Cruz",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Puerto Suarez",
        "country": "Bolivia",
        "province": "Santa Cruz",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Charagua",
        "country": "Bolivia",
        "province": "Santa Cruz",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Villamontes",
        "country": "Bolivia",
        "province": "Tarija",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Bermejo",
        "country": "Bolivia",
        "province": "Tarija",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Cochabamba",
        "country": "Bolivia",
        "province": "Cochabamba",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Oruro",
        "country": "Bolivia",
        "province": "Oruro",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Camiri",
        "country": "Bolivia",
        "province": "Santa Cruz",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Cobija",
        "country": "Bolivia",
        "province": "Pando",
        "timezone": "America/Rio_Branco"
    },
    {
        "name": "San Matias",
        "country": "Bolivia",
        "province": "Santa Cruz",
        "timezone": "America/La_Paz"
    },
    {
        "name": "San José",
        "country": "Bolivia",
        "province": "Santa Cruz",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Trinidad",
        "country": "Bolivia",
        "province": "El Beni",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Tarija",
        "country": "Bolivia",
        "province": "Tarija",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Sucre",
        "country": "Bolivia",
        "province": "Chuquisaca",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Riberalta",
        "country": "Bolivia",
        "province": "El Beni",
        "timezone": "America/La_Paz"
    },
    {
        "name": "La Paz",
        "country": "Bolivia",
        "province": "La Paz",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Santa Cruz",
        "country": "Bolivia",
        "province": "Santa Cruz",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Zenica",
        "country": "Bosnia and Herzegovina",
        "province": "Zenica-Doboj",
        "timezone": "Europe/Sarajevo"
    },
    {
        "name": "Mostar",
        "country": "Bosnia and Herzegovina",
        "province": "Herzegovina-Neretva",
        "timezone": "Europe/Sarajevo"
    },
    {
        "name": "Tuzla",
        "country": "Bosnia and Herzegovina",
        "province": "Tuzla",
        "timezone": "Europe/Sarajevo"
    },
    {
        "name": "Prijedor",
        "country": "Bosnia and Herzegovina",
        "province": "Serbian Republic",
        "timezone": "Europe/Sarajevo"
    },
    {
        "name": "Banja Luka",
        "country": "Bosnia and Herzegovina",
        "province": "Serbian Republic",
        "timezone": "Europe/Sarajevo"
    },
    {
        "name": "Sarajevo",
        "country": "Bosnia and Herzegovina",
        "province": "Sarajevo",
        "timezone": "Europe/Sarajevo"
    },
    {
        "name": "Mochudi",
        "country": "Botswana",
        "province": "Kgatleng",
        "timezone": "Africa/Gaborone"
    },
    {
        "name": "Ghanzi",
        "country": "Botswana",
        "province": "Ghanzi",
        "timezone": "Africa/Gaborone"
    },
    {
        "name": "Lokhwabe",
        "country": "Botswana",
        "province": "Kgalagadi",
        "timezone": "Africa/Gaborone"
    },
    {
        "name": "Lehututu",
        "country": "Botswana",
        "province": "Kgalagadi",
        "timezone": "Africa/Gaborone"
    },
    {
        "name": "Tshabong",
        "country": "Botswana",
        "province": "Kgalagadi",
        "timezone": "Africa/Gaborone"
    },
    {
        "name": "Tsau",
        "country": "Botswana",
        "province": "North-West",
        "timezone": "Africa/Gaborone"
    },
    {
        "name": "Nokaneng",
        "country": "Botswana",
        "province": "North-West",
        "timezone": "Africa/Gaborone"
    },
    {
        "name": "Mohembo",
        "country": "Botswana",
        "province": "North-West",
        "timezone": "Africa/Gaborone"
    },
    {
        "name": "Maun",
        "country": "Botswana",
        "province": "North-West",
        "timezone": "Africa/Gaborone"
    },
    {
        "name": "Kasane",
        "country": "Botswana",
        "province": "North-West",
        "timezone": "Africa/Gaborone"
    },
    {
        "name": "Nata",
        "country": "Botswana",
        "province": "Central",
        "timezone": "Africa/Gaborone"
    },
    {
        "name": "Mopipi",
        "country": "Botswana",
        "province": "Central",
        "timezone": "Africa/Gaborone"
    },
    {
        "name": "Palapye",
        "country": "Botswana",
        "province": "Central",
        "timezone": "Africa/Gaborone"
    },
    {
        "name": "Lobatse",
        "country": "Botswana",
        "province": "South-East",
        "timezone": "Africa/Gaborone"
    },
    {
        "name": "Kanye",
        "country": "Botswana",
        "province": "Southern",
        "timezone": "Africa/Gaborone"
    },
    {
        "name": "Molepolole",
        "country": "Botswana",
        "province": "Kweneng",
        "timezone": "Africa/Gaborone"
    },
    {
        "name": "Francistown",
        "country": "Botswana",
        "province": "Central",
        "timezone": "Africa/Gaborone"
    },
    {
        "name": "Mahalapye",
        "country": "Botswana",
        "province": "Central",
        "timezone": "Africa/Gaborone"
    },
    {
        "name": "Serowe",
        "country": "Botswana",
        "province": "Central",
        "timezone": "Africa/Gaborone"
    },
    {
        "name": "Gaborone",
        "country": "Botswana",
        "province": "South-East",
        "timezone": "Africa/Gaborone"
    },
    {
        "name": "Grajau",
        "country": "Brazil",
        "province": "Maranhão",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Presidente Dutra",
        "country": "Brazil",
        "province": "Maranhão",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Itapecuru Mirim",
        "country": "Brazil",
        "province": "Maranhão",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Sao Jose de Ribamar",
        "country": "Brazil",
        "province": "Maranhão",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Santa Ines",
        "country": "Brazil",
        "province": "Maranhão",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Rosario",
        "country": "Brazil",
        "province": "Maranhão",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Timon",
        "country": "Brazil",
        "province": "Maranhão",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Capanema",
        "country": "Brazil",
        "province": "Pará",
        "timezone": "America/Belem"
    },
    {
        "name": "Portel",
        "country": "Brazil",
        "province": "Pará",
        "timezone": "America/Belem"
    },
    {
        "name": "Itupiranga",
        "country": "Brazil",
        "province": "Pará",
        "timezone": "America/Belem"
    },
    {
        "name": "Pimenta Bueno",
        "country": "Brazil",
        "province": "Rondônia",
        "timezone": "America/Porto_Velho"
    },
    {
        "name": "Ponta Pora",
        "country": "Brazil",
        "province": "Mato Grosso do Sul",
        "timezone": "America/Campo_Grande"
    },
    {
        "name": "Maracaju",
        "country": "Brazil",
        "province": "Mato Grosso do Sul",
        "timezone": "America/Campo_Grande"
    },
    {
        "name": "Jardim",
        "country": "Brazil",
        "province": "Mato Grosso do Sul",
        "timezone": "America/Campo_Grande"
    },
    {
        "name": "Tres Lagoas",
        "country": "Brazil",
        "province": "Mato Grosso do Sul",
        "timezone": "America/Campo_Grande"
    },
    {
        "name": "Guanhaes",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Leopoldina",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Nova Lima",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Pouso Alegre",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Itauna",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Caratinga",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Diamantina",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Nanuque",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Barbacena",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Pocos de Caldas",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Guaxupe",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Sao Joao del Rei",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Muriae",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Passos",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Conselheiro Lafaiete",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Formiga",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Frutal",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Iturama",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Ituiutaba",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Araguari",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Almenara",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Varzea Grande",
        "country": "Brazil",
        "province": "Mato Grosso",
        "timezone": "America/Cuiaba"
    },
    {
        "name": "Cáceres",
        "country": "Brazil",
        "province": "Mato Grosso",
        "timezone": "America/Cuiaba"
    },
    {
        "name": "Santana do Livramento",
        "country": "Brazil",
        "province": "Rio Grande do Sul",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Canoas",
        "country": "Brazil",
        "province": "Rio Grande do Sul",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Quarai",
        "country": "Brazil",
        "province": "Rio Grande do Sul",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Santa Vitoria do Palmar",
        "country": "Brazil",
        "province": "Rio Grande do Sul",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Sao Lourenco do Sul",
        "country": "Brazil",
        "province": "Rio Grande do Sul",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Canela",
        "country": "Brazil",
        "province": "Rio Grande do Sul",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Sao Gabriel",
        "country": "Brazil",
        "province": "Rio Grande do Sul",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Rosario do Sul",
        "country": "Brazil",
        "province": "Rio Grande do Sul",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Cachoeira do Sul",
        "country": "Brazil",
        "province": "Rio Grande do Sul",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Osorio",
        "country": "Brazil",
        "province": "Rio Grande do Sul",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Santa Cruz do Sul",
        "country": "Brazil",
        "province": "Rio Grande do Sul",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Sao Luiz Gonzaga",
        "country": "Brazil",
        "province": "Rio Grande do Sul",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Santo Angelo",
        "country": "Brazil",
        "province": "Rio Grande do Sul",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Carazinho",
        "country": "Brazil",
        "province": "Rio Grande do Sul",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Erechim",
        "country": "Brazil",
        "province": "Rio Grande do Sul",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Guaira",
        "country": "Brazil",
        "province": "Paraná",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Palmas",
        "country": "Brazil",
        "province": "Paraná",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Arapongas",
        "country": "Brazil",
        "province": "Paraná",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Paranagua",
        "country": "Brazil",
        "province": "Paraná",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Sao Jose dos Pinhais",
        "country": "Brazil",
        "province": "Paraná",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Guarapuava",
        "country": "Brazil",
        "province": "Paraná",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Rio Negro",
        "country": "Brazil",
        "province": "Paraná",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Apucarana",
        "country": "Brazil",
        "province": "Paraná",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Lapa",
        "country": "Brazil",
        "province": "Paraná",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Irati",
        "country": "Brazil",
        "province": "Paraná",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Castro",
        "country": "Brazil",
        "province": "Paraná",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Telemaco Borba",
        "country": "Brazil",
        "province": "Paraná",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Jacarezinho",
        "country": "Brazil",
        "province": "Paraná",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Concordia",
        "country": "Brazil",
        "province": "Santa Catarina",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Blumenau",
        "country": "Brazil",
        "province": "Santa Catarina",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Brusque",
        "country": "Brazil",
        "province": "Santa Catarina",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Ararangua",
        "country": "Brazil",
        "province": "Santa Catarina",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Jaragua do Sul",
        "country": "Brazil",
        "province": "Santa Catarina",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Tubarao",
        "country": "Brazil",
        "province": "Santa Catarina",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Laguna",
        "country": "Brazil",
        "province": "Santa Catarina",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Joacaba",
        "country": "Brazil",
        "province": "Santa Catarina",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Cacador",
        "country": "Brazil",
        "province": "Santa Catarina",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Canoinhas",
        "country": "Brazil",
        "province": "Santa Catarina",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Camocim",
        "country": "Brazil",
        "province": "Ceará",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Russas",
        "country": "Brazil",
        "province": "Ceará",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Sobral",
        "country": "Brazil",
        "province": "Ceará",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Iguatu",
        "country": "Brazil",
        "province": "Ceará",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Quixada",
        "country": "Brazil",
        "province": "Ceará",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Caninde",
        "country": "Brazil",
        "province": "Ceará",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Campo Maior",
        "country": "Brazil",
        "province": "Piauí",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Barras",
        "country": "Brazil",
        "province": "Piauí",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Rio Largo",
        "country": "Brazil",
        "province": "Alagoas",
        "timezone": "America/Maceio"
    },
    {
        "name": "Palmeira dos Indios",
        "country": "Brazil",
        "province": "Alagoas",
        "timezone": "America/Maceio"
    },
    {
        "name": "Santa Cruz Cabralia",
        "country": "Brazil",
        "province": "Bahia",
        "timezone": "America/Bahia"
    },
    {
        "name": "Paulo Afonso",
        "country": "Brazil",
        "province": "Bahia",
        "timezone": "America/Bahia"
    },
    {
        "name": "Brumado",
        "country": "Brazil",
        "province": "Bahia",
        "timezone": "America/Bahia"
    },
    {
        "name": "Jaguaquara",
        "country": "Brazil",
        "province": "Bahia",
        "timezone": "America/Bahia"
    },
    {
        "name": "Itapetinga",
        "country": "Brazil",
        "province": "Bahia",
        "timezone": "America/Bahia"
    },
    {
        "name": "Ubaitaba",
        "country": "Brazil",
        "province": "Bahia",
        "timezone": "America/Bahia"
    },
    {
        "name": "Cachoeiro de Itapemirim",
        "country": "Brazil",
        "province": "Espírito Santo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Barra Mansa",
        "country": "Brazil",
        "province": "Rio de Janeiro",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Nova Iguacu",
        "country": "Brazil",
        "province": "Rio de Janeiro",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Duque de Caxias",
        "country": "Brazil",
        "province": "Rio de Janeiro",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Niteroi",
        "country": "Brazil",
        "province": "Rio de Janeiro",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Cabo Frio",
        "country": "Brazil",
        "province": "Rio de Janeiro",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Macae",
        "country": "Brazil",
        "province": "Rio de Janeiro",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Miracema",
        "country": "Brazil",
        "province": "Rio de Janeiro",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Apodi",
        "country": "Brazil",
        "province": "Rio Grande do Norte",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Santa Cruz",
        "country": "Brazil",
        "province": "Rio Grande do Norte",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Morrinhos",
        "country": "Brazil",
        "province": "Goiás",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Ceres",
        "country": "Brazil",
        "province": "Goiás",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Catalao",
        "country": "Brazil",
        "province": "Goiás",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Cristalina",
        "country": "Brazil",
        "province": "Goiás",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Trindade",
        "country": "Brazil",
        "province": "Goiás",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Ipora",
        "country": "Brazil",
        "province": "Goiás",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Inhumas",
        "country": "Brazil",
        "province": "Goiás",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Itaberai",
        "country": "Brazil",
        "province": "Goiás",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Santo Andre",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Pindamonhangaba",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Rio Claro",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Ourinhos",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Itanhaem",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Jaboticabal",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Braganca Paulista",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Jundiai",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Sao Jose dos Campos",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Guaratingueta",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Pirassununga",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Americana",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Piracicaba",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Sao Joao da Boa Vista",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Sao Carlos",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Tupa",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Penapolis",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Presidente Prudente",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Registro",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Tatui",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Avare",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Garca",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Catanduva",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Batatais",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Barretos",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Marilia",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Itu",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Itapetininga",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Jaboatao",
        "country": "Brazil",
        "province": "Pernambuco",
        "timezone": "America/Recife"
    },
    {
        "name": "Olinda",
        "country": "Brazil",
        "province": "Pernambuco",
        "timezone": "America/Recife"
    },
    {
        "name": "Cabo de Santo Agostinho",
        "country": "Brazil",
        "province": "Pernambuco",
        "timezone": "America/Recife"
    },
    {
        "name": "Carpina",
        "country": "Brazil",
        "province": "Pernambuco",
        "timezone": "America/Recife"
    },
    {
        "name": "Arcoverde",
        "country": "Brazil",
        "province": "Pernambuco",
        "timezone": "America/Recife"
    },
    {
        "name": "Manacapuru",
        "country": "Brazil",
        "province": "Amazonas",
        "timezone": "America/Manaus"
    },
    {
        "name": "Maues",
        "country": "Brazil",
        "province": "Amazonas",
        "timezone": "America/Manaus"
    },
    {
        "name": "Pedreiras",
        "country": "Brazil",
        "province": "Maranhão",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Codo",
        "country": "Brazil",
        "province": "Maranhão",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Coroata",
        "country": "Brazil",
        "province": "Maranhão",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Chapadinha",
        "country": "Brazil",
        "province": "Maranhão",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Pinheiro",
        "country": "Brazil",
        "province": "Maranhão",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Barra do Corda",
        "country": "Brazil",
        "province": "Maranhão",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Viana",
        "country": "Brazil",
        "province": "Maranhão",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Colinas",
        "country": "Brazil",
        "province": "Maranhão",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Viseu",
        "country": "Brazil",
        "province": "Pará",
        "timezone": "America/Belem"
    },
    {
        "name": "Capitao Poco",
        "country": "Brazil",
        "province": "Pará",
        "timezone": "America/Belem"
    },
    {
        "name": "Castanhal",
        "country": "Brazil",
        "province": "Pará",
        "timezone": "America/Belem"
    },
    {
        "name": "Salinopolis",
        "country": "Brazil",
        "province": "Pará",
        "timezone": "America/Belem"
    },
    {
        "name": "Alenquer",
        "country": "Brazil",
        "province": "Pará",
        "timezone": "America/Santarem"
    },
    {
        "name": "Oriximina",
        "country": "Brazil",
        "province": "Pará",
        "timezone": "America/Santarem"
    },
    {
        "name": "Xinguara",
        "country": "Brazil",
        "province": "Pará",
        "timezone": "America/Belem"
    },
    {
        "name": "Jacundá",
        "country": "Brazil",
        "province": "Pará",
        "timezone": "America/Belem"
    },
    {
        "name": "Uruara",
        "country": "Brazil",
        "province": "Pará",
        "timezone": "America/Santarem"
    },
    {
        "name": "Altamira",
        "country": "Brazil",
        "province": "Pará",
        "timezone": "America/Santarem"
    },
    {
        "name": "Paragominas",
        "country": "Brazil",
        "province": "Pará",
        "timezone": "America/Belem"
    },
    {
        "name": "Cameta",
        "country": "Brazil",
        "province": "Pará",
        "timezone": "America/Belem"
    },
    {
        "name": "Rolim de Moura",
        "country": "Brazil",
        "province": "Rondônia",
        "timezone": "America/Porto_Velho"
    },
    {
        "name": "Ariquemes",
        "country": "Brazil",
        "province": "Rondônia",
        "timezone": "America/Porto_Velho"
    },
    {
        "name": "Abuna",
        "country": "Brazil",
        "province": "Rondônia",
        "timezone": "America/Porto_Velho"
    },
    {
        "name": "Tocantinopolis",
        "country": "Brazil",
        "province": "Tocantins",
        "timezone": "America/Araguaina"
    },
    {
        "name": "Gurupi",
        "country": "Brazil",
        "province": "Tocantins",
        "timezone": "America/Araguaina"
    },
    {
        "name": "Aquidauana",
        "country": "Brazil",
        "province": "Mato Grosso do Sul",
        "timezone": "America/Campo_Grande"
    },
    {
        "name": "Paranaiba",
        "country": "Brazil",
        "province": "Mato Grosso do Sul",
        "timezone": "America/Campo_Grande"
    },
    {
        "name": "Sete Lagoas",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Divinopolis",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Ipatinga",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Araxa",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Lavras",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Uba",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Campo Belo",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Ponte Nova",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Curvelo",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Paracatu",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Bocaiuva",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Aracuai",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Janauba",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Juina",
        "country": "Brazil",
        "province": "Mato Grosso",
        "timezone": "America/Cuiaba"
    },
    {
        "name": "Barra do Garcas",
        "country": "Brazil",
        "province": "Mato Grosso",
        "timezone": "America/Cuiaba"
    },
    {
        "name": "Pontes e Lacerda",
        "country": "Brazil",
        "province": "Mato Grosso",
        "timezone": "America/Cuiaba"
    },
    {
        "name": "Barra do Bugres",
        "country": "Brazil",
        "province": "Mato Grosso",
        "timezone": "America/Cuiaba"
    },
    {
        "name": "Rondonopolis",
        "country": "Brazil",
        "province": "Mato Grosso",
        "timezone": "America/Cuiaba"
    },
    {
        "name": "Uruguaiana",
        "country": "Brazil",
        "province": "Rio Grande do Sul",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Sao Borja",
        "country": "Brazil",
        "province": "Rio Grande do Sul",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Novo Hamburgo",
        "country": "Brazil",
        "province": "Rio Grande do Sul",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Rio Grande",
        "country": "Brazil",
        "province": "Rio Grande do Sul",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Camaqua",
        "country": "Brazil",
        "province": "Rio Grande do Sul",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Bento Goncalves",
        "country": "Brazil",
        "province": "Rio Grande do Sul",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Vacaria",
        "country": "Brazil",
        "province": "Rio Grande do Sul",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Ijui",
        "country": "Brazil",
        "province": "Rio Grande do Sul",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Santa Rosa",
        "country": "Brazil",
        "province": "Rio Grande do Sul",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Maringa",
        "country": "Brazil",
        "province": "Paraná",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Cascavel",
        "country": "Brazil",
        "province": "Paraná",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Campo Murao",
        "country": "Brazil",
        "province": "Paraná",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Foz do Iguacu",
        "country": "Brazil",
        "province": "Paraná",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Sao Francisco do Sul",
        "country": "Brazil",
        "province": "Santa Catarina",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Porto Uniao",
        "country": "Brazil",
        "province": "Santa Catarina",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Itajai",
        "country": "Brazil",
        "province": "Santa Catarina",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Imbituba",
        "country": "Brazil",
        "province": "Santa Catarina",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Lajes",
        "country": "Brazil",
        "province": "Santa Catarina",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Granja",
        "country": "Brazil",
        "province": "Ceará",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Crato",
        "country": "Brazil",
        "province": "Ceará",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Itapipoca",
        "country": "Brazil",
        "province": "Ceará",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Paracuru",
        "country": "Brazil",
        "province": "Ceará",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Acarau",
        "country": "Brazil",
        "province": "Ceará",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Taua",
        "country": "Brazil",
        "province": "Ceará",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Crateus",
        "country": "Brazil",
        "province": "Ceará",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Baturite",
        "country": "Brazil",
        "province": "Ceará",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Ipu",
        "country": "Brazil",
        "province": "Ceará",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Floriano",
        "country": "Brazil",
        "province": "Piauí",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Piripiri",
        "country": "Brazil",
        "province": "Piauí",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Penedo",
        "country": "Brazil",
        "province": "Alagoas",
        "timezone": "America/Maceio"
    },
    {
        "name": "Itabuna",
        "country": "Brazil",
        "province": "Bahia",
        "timezone": "America/Bahia"
    },
    {
        "name": "Itamaraju",
        "country": "Brazil",
        "province": "Bahia",
        "timezone": "America/Bahia"
    },
    {
        "name": "Guanambi",
        "country": "Brazil",
        "province": "Bahia",
        "timezone": "America/Bahia"
    },
    {
        "name": "Porto Seguro",
        "country": "Brazil",
        "province": "Bahia",
        "timezone": "America/Bahia"
    },
    {
        "name": "Valenca",
        "country": "Brazil",
        "province": "Bahia",
        "timezone": "America/Bahia"
    },
    {
        "name": "Serrinha",
        "country": "Brazil",
        "province": "Bahia",
        "timezone": "America/Bahia"
    },
    {
        "name": "Tucano",
        "country": "Brazil",
        "province": "Bahia",
        "timezone": "America/Bahia"
    },
    {
        "name": "Senhor do Bonfim",
        "country": "Brazil",
        "province": "Bahia",
        "timezone": "America/Bahia"
    },
    {
        "name": "Remanso",
        "country": "Brazil",
        "province": "Bahia",
        "timezone": "America/Bahia"
    },
    {
        "name": "Itambe",
        "country": "Brazil",
        "province": "Bahia",
        "timezone": "America/Bahia"
    },
    {
        "name": "Bom Jesus da Lapa",
        "country": "Brazil",
        "province": "Bahia",
        "timezone": "America/Bahia"
    },
    {
        "name": "Itaberaba",
        "country": "Brazil",
        "province": "Bahia",
        "timezone": "America/Bahia"
    },
    {
        "name": "Sao Mateus",
        "country": "Brazil",
        "province": "Espírito Santo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Patos",
        "country": "Brazil",
        "province": "Paraíba",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Volta Redonda",
        "country": "Brazil",
        "province": "Rio de Janeiro",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Petropolis",
        "country": "Brazil",
        "province": "Rio de Janeiro",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Nova Cruz",
        "country": "Brazil",
        "province": "Rio Grande do Norte",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Caico",
        "country": "Brazil",
        "province": "Rio Grande do Norte",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Acu",
        "country": "Brazil",
        "province": "Rio Grande do Norte",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Estancia",
        "country": "Brazil",
        "province": "Sergipe",
        "timezone": "America/Maceio"
    },
    {
        "name": "Caracarai",
        "country": "Brazil",
        "province": "Roraima",
        "timezone": "America/Boa_Vista"
    },
    {
        "name": "Porto Santana",
        "country": "Brazil",
        "province": "Amapá",
        "timezone": "America/Belem"
    },
    {
        "name": "Rio Verde",
        "country": "Brazil",
        "province": "Goiás",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Pires do Rio",
        "country": "Brazil",
        "province": "Goiás",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Anapolis",
        "country": "Brazil",
        "province": "Goiás",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Goianesia",
        "country": "Brazil",
        "province": "Goiás",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Niquelandia",
        "country": "Brazil",
        "province": "Goiás",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Itumbiara",
        "country": "Brazil",
        "province": "Goiás",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Jatai",
        "country": "Brazil",
        "province": "Goiás",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Mineiros",
        "country": "Brazil",
        "province": "Goiás",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Formosa",
        "country": "Brazil",
        "province": "Goiás",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Sao Jose do Rio Preto",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Limeira",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Taubate",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Jau",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Assis",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Itapeva",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Botucatu",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Novo Horizonte",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Andradina",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Fernandopolis",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Barreiros",
        "country": "Brazil",
        "province": "Pernambuco",
        "timezone": "America/Recife"
    },
    {
        "name": "Salgueiro",
        "country": "Brazil",
        "province": "Pernambuco",
        "timezone": "America/Recife"
    },
    {
        "name": "Goiana",
        "country": "Brazil",
        "province": "Pernambuco",
        "timezone": "America/Recife"
    },
    {
        "name": "Timbauba",
        "country": "Brazil",
        "province": "Pernambuco",
        "timezone": "America/Recife"
    },
    {
        "name": "Bacabal",
        "country": "Brazil",
        "province": "Maranhão",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Braganca",
        "country": "Brazil",
        "province": "Pará",
        "timezone": "America/Belem"
    },
    {
        "name": "Obidos",
        "country": "Brazil",
        "province": "Pará",
        "timezone": "America/Santarem"
    },
    {
        "name": "Guajara-Miram",
        "country": "Brazil",
        "province": "Rondônia",
        "timezone": "America/La_Paz"
    },
    {
        "name": "Porto Nacional",
        "country": "Brazil",
        "province": "Tocantins",
        "timezone": "America/Araguaina"
    },
    {
        "name": "Dourados",
        "country": "Brazil",
        "province": "Mato Grosso do Sul",
        "timezone": "America/Campo_Grande"
    },
    {
        "name": "Governador Valadares",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Pirapora",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Juiz de Fora",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Santa Maria",
        "country": "Brazil",
        "province": "Rio Grande do Sul",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Passo Fundo",
        "country": "Brazil",
        "province": "Rio Grande do Sul",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Xapeco",
        "country": "Brazil",
        "province": "Santa Catarina",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Joinville",
        "country": "Brazil",
        "province": "Santa Catarina",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Juazeiro do Norte",
        "country": "Brazil",
        "province": "Ceará",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Nova Vicosa",
        "country": "Brazil",
        "province": "Bahia",
        "timezone": "America/Bahia"
    },
    {
        "name": "Alagoinhas",
        "country": "Brazil",
        "province": "Bahia",
        "timezone": "America/Bahia"
    },
    {
        "name": "Juazeiro",
        "country": "Brazil",
        "province": "Bahia",
        "timezone": "America/Bahia"
    },
    {
        "name": "Vitória",
        "country": "Brazil",
        "province": "Espírito Santo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Joao Pessoa",
        "country": "Brazil",
        "province": "Paraíba",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Campina Grande",
        "country": "Brazil",
        "province": "Paraíba",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Nova Friburgo",
        "country": "Brazil",
        "province": "Rio de Janeiro",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Aracatuba",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Sena Madureira",
        "country": "Brazil",
        "province": "Acre",
        "timezone": "America/Rio_Branco"
    },
    {
        "name": "Fonte Boa",
        "country": "Brazil",
        "province": "Amazonas",
        "timezone": "America/Manaus"
    },
    {
        "name": "Eirunepe",
        "country": "Brazil",
        "province": "Amazonas",
        "timezone": "America/Eirunepe"
    },
    {
        "name": "Manicore",
        "country": "Brazil",
        "province": "Amazonas",
        "timezone": "America/Manaus"
    },
    {
        "name": "Barcelos",
        "country": "Brazil",
        "province": "Amazonas",
        "timezone": "America/Manaus"
    },
    {
        "name": "Tonantins",
        "country": "Brazil",
        "province": "Amazonas",
        "timezone": "America/Manaus"
    },
    {
        "name": "Tefe",
        "country": "Brazil",
        "province": "Amazonas",
        "timezone": "America/Manaus"
    },
    {
        "name": "Coari",
        "country": "Brazil",
        "province": "Amazonas",
        "timezone": "America/Manaus"
    },
    {
        "name": "Sao Cabriel da Cachoeira",
        "country": "Brazil",
        "province": "Amazonas",
        "timezone": "America/Manaus"
    },
    {
        "name": "Novo Airao",
        "country": "Brazil",
        "province": "Amazonas",
        "timezone": "America/Manaus"
    },
    {
        "name": "Itacoatiara",
        "country": "Brazil",
        "province": "Amazonas",
        "timezone": "America/Manaus"
    },
    {
        "name": "Parintins",
        "country": "Brazil",
        "province": "Amazonas",
        "timezone": "America/Manaus"
    },
    {
        "name": "Natal",
        "country": "Brazil",
        "province": "Amazonas",
        "timezone": "America/Manaus"
    },
    {
        "name": "Crato",
        "country": "Brazil",
        "province": "Amazonas",
        "timezone": "America/Manaus"
    },
    {
        "name": "Imperatriz",
        "country": "Brazil",
        "province": "Maranhão",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Balsas",
        "country": "Brazil",
        "province": "Maranhão",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Breves",
        "country": "Brazil",
        "province": "Pará",
        "timezone": "America/Belem"
    },
    {
        "name": "Jacareacanga",
        "country": "Brazil",
        "province": "Pará",
        "timezone": "America/Santarem"
    },
    {
        "name": "Tucurui",
        "country": "Brazil",
        "province": "Pará",
        "timezone": "America/Belem"
    },
    {
        "name": "Itaituba",
        "country": "Brazil",
        "province": "Pará",
        "timezone": "America/Santarem"
    },
    {
        "name": "Conceicao do Araguaia",
        "country": "Brazil",
        "province": "Pará",
        "timezone": "America/Belem"
    },
    {
        "name": "Abaetetuba",
        "country": "Brazil",
        "province": "Pará",
        "timezone": "America/Belem"
    },
    {
        "name": "Principe da Beira",
        "country": "Brazil",
        "province": "Rondônia",
        "timezone": "America/Porto_Velho"
    },
    {
        "name": "Araguaina",
        "country": "Brazil",
        "province": "Tocantins",
        "timezone": "America/Araguaina"
    },
    {
        "name": "Palmas",
        "country": "Brazil",
        "province": "Tocantins",
        "timezone": "America/Araguaina"
    },
    {
        "name": "Teofilo Otoni",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Uberaba",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Januaria",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Mato Grosso",
        "country": "Brazil",
        "province": "Mato Grosso",
        "timezone": "America/Cuiaba"
    },
    {
        "name": "Aripuana",
        "country": "Brazil",
        "province": "Mato Grosso",
        "timezone": "America/Cuiaba"
    },
    {
        "name": "Sinop",
        "country": "Brazil",
        "province": "Mato Grosso",
        "timezone": "America/Cuiaba"
    },
    {
        "name": "Jaguarao",
        "country": "Brazil",
        "province": "Rio Grande do Sul",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Bage",
        "country": "Brazil",
        "province": "Rio Grande do Sul",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Londrina",
        "country": "Brazil",
        "province": "Paraná",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Criciuma",
        "country": "Brazil",
        "province": "Santa Catarina",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Aracati",
        "country": "Brazil",
        "province": "Ceará",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Ico",
        "country": "Brazil",
        "province": "Ceará",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Parnaiba",
        "country": "Brazil",
        "province": "Piauí",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Picos",
        "country": "Brazil",
        "province": "Piauí",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Arapiraca",
        "country": "Brazil",
        "province": "Alagoas",
        "timezone": "America/Maceio"
    },
    {
        "name": "Jequie",
        "country": "Brazil",
        "province": "Bahia",
        "timezone": "America/Bahia"
    },
    {
        "name": "Ilheus",
        "country": "Brazil",
        "province": "Bahia",
        "timezone": "America/Bahia"
    },
    {
        "name": "Canavieiras",
        "country": "Brazil",
        "province": "Bahia",
        "timezone": "America/Bahia"
    },
    {
        "name": "Santa Maria da Vitoria",
        "country": "Brazil",
        "province": "Bahia",
        "timezone": "America/Bahia"
    },
    {
        "name": "Irece",
        "country": "Brazil",
        "province": "Bahia",
        "timezone": "America/Bahia"
    },
    {
        "name": "Xique-Xique",
        "country": "Brazil",
        "province": "Bahia",
        "timezone": "America/Bahia"
    },
    {
        "name": "Linhares",
        "country": "Brazil",
        "province": "Espírito Santo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Campos",
        "country": "Brazil",
        "province": "Rio de Janeiro",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Mossoro",
        "country": "Brazil",
        "province": "Rio Grande do Norte",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Aracaju",
        "country": "Brazil",
        "province": "Sergipe",
        "timezone": "America/Maceio"
    },
    {
        "name": "Laranjal do Jari",
        "country": "Brazil",
        "province": "Amapá",
        "timezone": "America/Santarem"
    },
    {
        "name": "Amapa",
        "country": "Brazil",
        "province": "Amapá",
        "timezone": "America/Belem"
    },
    {
        "name": "Vila Velha",
        "country": "Brazil",
        "province": "Amapá",
        "timezone": "America/Belem"
    },
    {
        "name": "Santos",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Bauru",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Iguape",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Franca",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Garanhuns",
        "country": "Brazil",
        "province": "Pernambuco",
        "timezone": "America/Recife"
    },
    {
        "name": "Caruaru",
        "country": "Brazil",
        "province": "Pernambuco",
        "timezone": "America/Recife"
    },
    {
        "name": "Rio Branco",
        "country": "Brazil",
        "province": "Acre",
        "timezone": "America/Rio_Branco"
    },
    {
        "name": "São Luís",
        "country": "Brazil",
        "province": "Maranhão",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Porto Velho",
        "country": "Brazil",
        "province": "Rondônia",
        "timezone": "America/Porto_Velho"
    },
    {
        "name": "Alvorada",
        "country": "Brazil",
        "province": "Tocantins",
        "timezone": "America/Araguaina"
    },
    {
        "name": "Corumba",
        "country": "Brazil",
        "province": "Mato Grosso do Sul",
        "timezone": "America/Campo_Grande"
    },
    {
        "name": "Belo Horizonte",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Montes Claros",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Uberlandia",
        "country": "Brazil",
        "province": "Minas Gerais",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Colider",
        "country": "Brazil",
        "province": "Mato Grosso",
        "timezone": "America/Cuiaba"
    },
    {
        "name": "Alta Floresta",
        "country": "Brazil",
        "province": "Mato Grosso",
        "timezone": "America/Cuiaba"
    },
    {
        "name": "Cuiaba",
        "country": "Brazil",
        "province": "Mato Grosso",
        "timezone": "America/Cuiaba"
    },
    {
        "name": "Pelotas",
        "country": "Brazil",
        "province": "Rio Grande do Sul",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Caxias do Sul",
        "country": "Brazil",
        "province": "Rio Grande do Sul",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Ponta Grossa",
        "country": "Brazil",
        "province": "Paraná",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Teresina",
        "country": "Brazil",
        "province": "Piauí",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Maceio",
        "country": "Brazil",
        "province": "Alagoas",
        "timezone": "America/Maceio"
    },
    {
        "name": "Vitoria da Conquista",
        "country": "Brazil",
        "province": "Bahia",
        "timezone": "America/Bahia"
    },
    {
        "name": "Barreiras",
        "country": "Brazil",
        "province": "Bahia",
        "timezone": "America/Bahia"
    },
    {
        "name": "Vila Velha",
        "country": "Brazil",
        "province": "Espírito Santo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Natal",
        "country": "Brazil",
        "province": "Rio Grande do Norte",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Campinas",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Sorocaba",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Ribeirao Preto",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Petrolina",
        "country": "Brazil",
        "province": "Pernambuco",
        "timezone": "America/Recife"
    },
    {
        "name": "Cruzeiro do Sul",
        "country": "Brazil",
        "province": "Acre",
        "timezone": "America/Rio_Branco"
    },
    {
        "name": "Manaus",
        "country": "Brazil",
        "province": "Amazonas",
        "timezone": "America/Manaus"
    },
    {
        "name": "Caxias",
        "country": "Brazil",
        "province": "Maranhão",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Santarem",
        "country": "Brazil",
        "province": "Pará",
        "timezone": "America/Santarem"
    },
    {
        "name": "Maraba",
        "country": "Brazil",
        "province": "Pará",
        "timezone": "America/Belem"
    },
    {
        "name": "Vilhena",
        "country": "Brazil",
        "province": "Rondônia",
        "timezone": "America/Porto_Velho"
    },
    {
        "name": "Ji-Parana",
        "country": "Brazil",
        "province": "Rondônia",
        "timezone": "America/Porto_Velho"
    },
    {
        "name": "Campo Grande",
        "country": "Brazil",
        "province": "Mato Grosso do Sul",
        "timezone": "America/Campo_Grande"
    },
    {
        "name": "Florianopolis",
        "country": "Brazil",
        "province": "Santa Catarina",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Feira de Santana",
        "country": "Brazil",
        "province": "Bahia",
        "timezone": "America/Bahia"
    },
    {
        "name": "Boa Vista",
        "country": "Brazil",
        "province": "Roraima",
        "timezone": "America/Boa_Vista"
    },
    {
        "name": "Macapá",
        "country": "Brazil",
        "province": "Amapá",
        "timezone": "America/Belem"
    },
    {
        "name": "Belem",
        "country": "Brazil",
        "province": "Pará",
        "timezone": "America/Belem"
    },
    {
        "name": "Brasilia",
        "country": "Brazil",
        "province": "Distrito Federal",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Porto Alegre",
        "country": "Brazil",
        "province": "Rio Grande do Sul",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Curitiba",
        "country": "Brazil",
        "province": "Paraná",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Fortaleza",
        "country": "Brazil",
        "province": "Ceará",
        "timezone": "America/Fortaleza"
    },
    {
        "name": "Salvador",
        "country": "Brazil",
        "province": "Bahia",
        "timezone": "America/Bahia"
    },
    {
        "name": "Goiania",
        "country": "Brazil",
        "province": "Goiás",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Recife",
        "country": "Brazil",
        "province": "Pernambuco",
        "timezone": "America/Recife"
    },
    {
        "name": "Rio de Janeiro",
        "country": "Brazil",
        "province": "Rio de Janeiro",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Sao Paulo",
        "country": "Brazil",
        "province": "São Paulo",
        "timezone": "America/Sao_Paulo"
    },
    {
        "name": "Bandar Seri Begawan",
        "country": "Brunei",
        "province": "Brunei and Muara",
        "timezone": "Asia/Brunei"
    },
    {
        "name": "Lovec",
        "country": "Bulgaria",
        "province": "Lovech",
        "timezone": "Europe/Sofia"
    },
    {
        "name": "Montana",
        "country": "Bulgaria",
        "province": "Montana",
        "timezone": "Europe/Sofia"
    },
    {
        "name": "Razgrad",
        "country": "Bulgaria",
        "province": "Razgrad",
        "timezone": "Europe/Sofia"
    },
    {
        "name": "Sliven",
        "country": "Bulgaria",
        "province": "Sliven",
        "timezone": "Europe/Sofia"
    },
    {
        "name": "Plovdiv",
        "country": "Bulgaria",
        "province": "Plovdiv",
        "timezone": "Europe/Sofia"
    },
    {
        "name": "Pernik",
        "country": "Bulgaria",
        "province": "Pernik",
        "timezone": "Europe/Sofia"
    },
    {
        "name": "Vratsa",
        "country": "Bulgaria",
        "province": "Vratsa",
        "timezone": "Europe/Sofia"
    },
    {
        "name": "Shumen",
        "country": "Bulgaria",
        "province": "Shumen",
        "timezone": "Europe/Sofia"
    },
    {
        "name": "Khaskovo",
        "country": "Bulgaria",
        "province": "Haskovo",
        "timezone": "Europe/Sofia"
    },
    {
        "name": "Stara Zagora",
        "country": "Bulgaria",
        "province": "Stara Zagora",
        "timezone": "Europe/Sofia"
    },
    {
        "name": "Pleven",
        "country": "Bulgaria",
        "province": "Pleven",
        "timezone": "Europe/Sofia"
    },
    {
        "name": "Turnovo",
        "country": "Bulgaria",
        "province": "Veliko Tarnovo",
        "timezone": "Europe/Sofia"
    },
    {
        "name": "Kyustendil",
        "country": "Bulgaria",
        "province": "Kyustendil",
        "timezone": "Europe/Sofia"
    },
    {
        "name": "Dobrich",
        "country": "Bulgaria",
        "province": "Dobrich",
        "timezone": "Europe/Sofia"
    },
    {
        "name": "Varna",
        "country": "Bulgaria",
        "province": "Varna",
        "timezone": "Europe/Sofia"
    },
    {
        "name": "Ruse",
        "country": "Bulgaria",
        "province": "Ruse",
        "timezone": "Europe/Sofia"
    },
    {
        "name": "Burgas",
        "country": "Bulgaria",
        "province": "Burgas",
        "timezone": "Europe/Sofia"
    },
    {
        "name": "Sofia",
        "country": "Bulgaria",
        "province": "Grad Sofiya",
        "timezone": "Europe/Sofia"
    },
    {
        "name": "Fada Ngourma",
        "country": "Burkina Faso",
        "province": "Gourma",
        "timezone": "Africa/Ouagadougou"
    },
    {
        "name": "Orodara",
        "country": "Burkina Faso",
        "province": "Kénédougou",
        "timezone": "Africa/Ouagadougou"
    },
    {
        "name": "Solenzo",
        "country": "Burkina Faso",
        "province": "Banwa",
        "timezone": "Africa/Ouagadougou"
    },
    {
        "name": "Nouna",
        "country": "Burkina Faso",
        "province": "Kossi",
        "timezone": "Africa/Ouagadougou"
    },
    {
        "name": "Dedougou",
        "country": "Burkina Faso",
        "province": "Mou Houn",
        "timezone": "Africa/Ouagadougou"
    },
    {
        "name": "Gorom Gorom",
        "country": "Burkina Faso",
        "province": "Oudalan",
        "timezone": "Africa/Ouagadougou"
    },
    {
        "name": "Djibo",
        "country": "Burkina Faso",
        "province": "Soum",
        "timezone": "Africa/Ouagadougou"
    },
    {
        "name": "Tougan",
        "country": "Burkina Faso",
        "province": "Sourou",
        "timezone": "Africa/Ouagadougou"
    },
    {
        "name": "Kombissiri",
        "country": "Burkina Faso",
        "province": "Bazéga",
        "timezone": "Africa/Ouagadougou"
    },
    {
        "name": "Ziniare",
        "country": "Burkina Faso",
        "province": "Oubritenga",
        "timezone": "Africa/Ouagadougou"
    },
    {
        "name": "Yako",
        "country": "Burkina Faso",
        "province": "Passoré",
        "timezone": "Africa/Ouagadougou"
    },
    {
        "name": "Reo",
        "country": "Burkina Faso",
        "province": "Sanguié",
        "timezone": "Africa/Ouagadougou"
    },
    {
        "name": "Leo",
        "country": "Burkina Faso",
        "province": "Sissili",
        "timezone": "Africa/Ouagadougou"
    },
    {
        "name": "Sapouy",
        "country": "Burkina Faso",
        "province": "Ziro",
        "timezone": "Africa/Ouagadougou"
    },
    {
        "name": "Boulsa",
        "country": "Burkina Faso",
        "province": "Namentenga",
        "timezone": "Africa/Ouagadougou"
    },
    {
        "name": "Zorgo",
        "country": "Burkina Faso",
        "province": "Ganzourgou",
        "timezone": "Africa/Ouagadougou"
    },
    {
        "name": "Koupela",
        "country": "Burkina Faso",
        "province": "Kouritenga",
        "timezone": "Africa/Ouagadougou"
    },
    {
        "name": "Po",
        "country": "Burkina Faso",
        "province": "Nahouri",
        "timezone": "Africa/Ouagadougou"
    },
    {
        "name": "Manga",
        "country": "Burkina Faso",
        "province": "Zoundwéogo",
        "timezone": "Africa/Ouagadougou"
    },
    {
        "name": "Diebougou",
        "country": "Burkina Faso",
        "province": "Bougouriba",
        "timezone": "Africa/Ouagadougou"
    },
    {
        "name": "Gaoua",
        "country": "Burkina Faso",
        "province": "Poni",
        "timezone": "Africa/Ouagadougou"
    },
    {
        "name": "Bogande",
        "country": "Burkina Faso",
        "province": "Gnagna",
        "timezone": "Africa/Ouagadougou"
    },
    {
        "name": "Dori",
        "country": "Burkina Faso",
        "province": "Séno",
        "timezone": "Africa/Ouagadougou"
    },
    {
        "name": "Sebba",
        "country": "Burkina Faso",
        "province": "Yagha",
        "timezone": "Africa/Ouagadougou"
    },
    {
        "name": "Diapaga",
        "country": "Burkina Faso",
        "province": "Tapoa",
        "timezone": "Africa/Ouagadougou"
    },
    {
        "name": "Koudougou",
        "country": "Burkina Faso",
        "province": "Boulkiemdé",
        "timezone": "Africa/Ouagadougou"
    },
    {
        "name": "Ouahigouya",
        "country": "Burkina Faso",
        "province": "Yatenga",
        "timezone": "Africa/Ouagadougou"
    },
    {
        "name": "Kaya",
        "country": "Burkina Faso",
        "province": "Sanmatenga",
        "timezone": "Africa/Ouagadougou"
    },
    {
        "name": "Tenkodogo",
        "country": "Burkina Faso",
        "province": "Boulgou",
        "timezone": "Africa/Ouagadougou"
    },
    {
        "name": "Banfora",
        "country": "Burkina Faso",
        "province": "Komoé",
        "timezone": "Africa/Ouagadougou"
    },
    {
        "name": "Bobo Dioulasso",
        "country": "Burkina Faso",
        "province": "Houet",
        "timezone": "Africa/Ouagadougou"
    },
    {
        "name": "Ouagadougou",
        "country": "Burkina Faso",
        "province": "Kadiogo",
        "timezone": "Africa/Ouagadougou"
    },
    {
        "name": "Cankuzo",
        "country": "Burundi",
        "province": "Cankuzo",
        "timezone": "Africa/Bujumbura"
    },
    {
        "name": "Karusi",
        "country": "Burundi",
        "province": "Karuzi",
        "timezone": "Africa/Bujumbura"
    },
    {
        "name": "Rutana",
        "country": "Burundi",
        "province": "Rutana",
        "timezone": "Africa/Bujumbura"
    },
    {
        "name": "Ruyigi",
        "country": "Burundi",
        "province": "Ruyigi",
        "timezone": "Africa/Bujumbura"
    },
    {
        "name": "Bubanza",
        "country": "Burundi",
        "province": "Bubanza",
        "timezone": "Africa/Bujumbura"
    },
    {
        "name": "Kayanza",
        "country": "Burundi",
        "province": "Kayanza",
        "timezone": "Africa/Bujumbura"
    },
    {
        "name": "Makamba",
        "country": "Burundi",
        "province": "Makamba",
        "timezone": "Africa/Bujumbura"
    },
    {
        "name": "Ngozi",
        "country": "Burundi",
        "province": "Ngozi",
        "timezone": "Africa/Bujumbura"
    },
    {
        "name": "Kirundo",
        "country": "Burundi",
        "province": "Kirundo",
        "timezone": "Africa/Bujumbura"
    },
    {
        "name": "Muramvya",
        "country": "Burundi",
        "province": "Muramvya",
        "timezone": "Africa/Bujumbura"
    },
    {
        "name": "Bururi",
        "country": "Burundi",
        "province": "Bururi",
        "timezone": "Africa/Bujumbura"
    },
    {
        "name": "Muyinga",
        "country": "Burundi",
        "province": "Muyinga",
        "timezone": "Africa/Bujumbura"
    },
    {
        "name": "Gitega",
        "country": "Burundi",
        "province": "Muramvya",
        "timezone": "Africa/Bujumbura"
    },
    {
        "name": "Bujumbura",
        "country": "Burundi",
        "province": "Bujumbura Mairie",
        "timezone": "Africa/Bujumbura"
    },
    {
        "name": "Kampong Spoe",
        "country": "Cambodia",
        "province": "Kâmpóng Spœ",
        "timezone": "Asia/Phnom_Penh"
    },
    {
        "name": "Kampong Thum",
        "country": "Cambodia",
        "province": "Kâmpóng Thum",
        "timezone": "Asia/Phnom_Penh"
    },
    {
        "name": "Prey Veng",
        "country": "Cambodia",
        "province": "Prey Vêng",
        "timezone": "Asia/Phnom_Penh"
    },
    {
        "name": "Phnum Tbeng Meanchey",
        "country": "Cambodia",
        "province": "Preah Vihéar",
        "timezone": "Asia/Phnom_Penh"
    },
    {
        "name": "Stoeng Treng",
        "country": "Cambodia",
        "province": "Stœng Trêng",
        "timezone": "Asia/Phnom_Penh"
    },
    {
        "name": "Kracheh",
        "country": "Cambodia",
        "province": "Krâchéh",
        "timezone": "Asia/Phnom_Penh"
    },
    {
        "name": "Senmonorom",
        "country": "Cambodia",
        "province": "Môndól Kiri",
        "timezone": "Asia/Phnom_Penh"
    },
    {
        "name": "Lumphat",
        "country": "Cambodia",
        "province": "Rôtânôkiri",
        "timezone": "Asia/Phnom_Penh"
    },
    {
        "name": "Svay Rieng",
        "country": "Cambodia",
        "province": "Svay Rieng",
        "timezone": "Asia/Phnom_Penh"
    },
    {
        "name": "Sisophon",
        "country": "Cambodia",
        "province": "Bântéay Méanchey",
        "timezone": "Asia/Phnom_Penh"
    },
    {
        "name": "Krong Koh Kong",
        "country": "Cambodia",
        "province": "Kaôh Kong",
        "timezone": "Asia/Phnom_Penh"
    },
    {
        "name": "Pursat",
        "country": "Cambodia",
        "province": "Pouthisat",
        "timezone": "Asia/Phnom_Penh"
    },
    {
        "name": "Kampong Cham",
        "country": "Cambodia",
        "province": "Kâmpóng Cham",
        "timezone": "Asia/Phnom_Penh"
    },
    {
        "name": "Kompong Chhnang",
        "country": "Cambodia",
        "province": "Kâmpóng Chhnang",
        "timezone": "Asia/Phnom_Penh"
    },
    {
        "name": "Kampot",
        "country": "Cambodia",
        "province": "Kâmpôt",
        "timezone": "Asia/Phnom_Penh"
    },
    {
        "name": "Takeo",
        "country": "Cambodia",
        "province": "Takêv",
        "timezone": "Asia/Phnom_Penh"
    },
    {
        "name": "Battambang",
        "country": "Cambodia",
        "province": "Batdâmbâng",
        "timezone": "Asia/Phnom_Penh"
    },
    {
        "name": "Siem Reap",
        "country": "Cambodia",
        "province": "Siemréab",
        "timezone": "Asia/Phnom_Penh"
    },
    {
        "name": "Phnom Penh",
        "country": "Cambodia",
        "province": "Phnom Penh",
        "timezone": "Asia/Phnom_Penh"
    },
    {
        "name": "Buea",
        "country": "Cameroon",
        "province": "Sud-Ouest",
        "timezone": "Africa/Douala"
    },
    {
        "name": "Bafang",
        "country": "Cameroon",
        "province": "Ouest",
        "timezone": "Africa/Douala"
    },
    {
        "name": "Foumban",
        "country": "Cameroon",
        "province": "Ouest",
        "timezone": "Africa/Douala"
    },
    {
        "name": "Bafoussam",
        "country": "Cameroon",
        "province": "Ouest",
        "timezone": "Africa/Douala"
    },
    {
        "name": "Kumba",
        "country": "Cameroon",
        "province": "Sud-Ouest",
        "timezone": "Africa/Douala"
    },
    {
        "name": "Eyumojok",
        "country": "Cameroon",
        "province": "Sud-Ouest",
        "timezone": "Africa/Douala"
    },
    {
        "name": "Limbe",
        "country": "Cameroon",
        "province": "Sud-Ouest",
        "timezone": "Africa/Douala"
    },
    {
        "name": "Kribi",
        "country": "Cameroon",
        "province": "Sud",
        "timezone": "Africa/Douala"
    },
    {
        "name": "Nkongsamba",
        "country": "Cameroon",
        "province": "Littoral",
        "timezone": "Africa/Douala"
    },
    {
        "name": "Edea",
        "country": "Cameroon",
        "province": "Littoral",
        "timezone": "Africa/Douala"
    },
    {
        "name": "Wum",
        "country": "Cameroon",
        "province": "Nord-Ouest",
        "timezone": "Africa/Douala"
    },
    {
        "name": "Kumbo",
        "country": "Cameroon",
        "province": "Nord-Ouest",
        "timezone": "Africa/Douala"
    },
    {
        "name": "Bafia",
        "country": "Cameroon",
        "province": "Centre",
        "timezone": "Africa/Douala"
    },
    {
        "name": "Mbalmayo",
        "country": "Cameroon",
        "province": "Centre",
        "timezone": "Africa/Douala"
    },
    {
        "name": "Eseka",
        "country": "Cameroon",
        "province": "Centre",
        "timezone": "Africa/Douala"
    },
    {
        "name": "Bertoua",
        "country": "Cameroon",
        "province": "Est",
        "timezone": "Africa/Douala"
    },
    {
        "name": "Abong Mbang",
        "country": "Cameroon",
        "province": "Est",
        "timezone": "Africa/Douala"
    },
    {
        "name": "Batouri",
        "country": "Cameroon",
        "province": "Est",
        "timezone": "Africa/Douala"
    },
    {
        "name": "Belabo",
        "country": "Cameroon",
        "province": "Est",
        "timezone": "Africa/Douala"
    },
    {
        "name": "Meiganga",
        "country": "Cameroon",
        "province": "Adamaoua",
        "timezone": "Africa/Douala"
    },
    {
        "name": "Ngaoundere",
        "country": "Cameroon",
        "province": "Adamaoua",
        "timezone": "Africa/Douala"
    },
    {
        "name": "Tibati",
        "country": "Cameroon",
        "province": "Adamaoua",
        "timezone": "Africa/Douala"
    },
    {
        "name": "Kontcha",
        "country": "Cameroon",
        "province": "Adamaoua",
        "timezone": "Africa/Douala"
    },
    {
        "name": "Guider",
        "country": "Cameroon",
        "province": "Nord",
        "timezone": "Africa/Douala"
    },
    {
        "name": "Mbe",
        "country": "Cameroon",
        "province": "Nord",
        "timezone": "Africa/Douala"
    },
    {
        "name": "Douala",
        "country": "Cameroon",
        "province": "Littoral",
        "timezone": "Africa/Douala"
    },
    {
        "name": "Ebolowa",
        "country": "Cameroon",
        "province": "Sud",
        "timezone": "Africa/Douala"
    },
    {
        "name": "Bamenda",
        "country": "Cameroon",
        "province": "Nord-Ouest",
        "timezone": "Africa/Douala"
    },
    {
        "name": "Garoua",
        "country": "Cameroon",
        "province": "Nord",
        "timezone": "Africa/Douala"
    },
    {
        "name": "Maroua",
        "country": "Cameroon",
        "province": "Extrême-Nord",
        "timezone": "Africa/Douala"
    },
    {
        "name": "Yaounde",
        "country": "Cameroon",
        "province": "Centre",
        "timezone": "Africa/Douala"
    },
    {
        "name": "Selkirk",
        "country": "Canada",
        "province": "Manitoba",
        "timezone": "America/Winnipeg"
    },
    {
        "name": "Berens River",
        "country": "Canada",
        "province": "Manitoba",
        "timezone": "America/Winnipeg"
    },
    {
        "name": "Pukatawagan",
        "country": "Canada",
        "province": "Manitoba",
        "timezone": "America/Winnipeg"
    },
    {
        "name": "Gimli",
        "country": "Canada",
        "province": "Manitoba",
        "timezone": "America/Winnipeg"
    },
    {
        "name": "Island Lake",
        "country": "Canada",
        "province": "Manitoba",
        "timezone": "America/Winnipeg"
    },
    {
        "name": "Melville",
        "country": "Canada",
        "province": "Saskatchewan",
        "timezone": "America/Regina"
    },
    {
        "name": "Weyburn",
        "country": "Canada",
        "province": "Saskatchewan",
        "timezone": "America/Regina"
    },
    {
        "name": "La Ronge",
        "country": "Canada",
        "province": "Saskatchewan",
        "timezone": "America/Regina"
    },
    {
        "name": "Stony Rapids",
        "country": "Canada",
        "province": "Saskatchewan",
        "timezone": "America/Regina"
    },
    {
        "name": "Camrose",
        "country": "Canada",
        "province": "Alberta",
        "timezone": "America/Edmonton"
    },
    {
        "name": "Hinton",
        "country": "Canada",
        "province": "Alberta",
        "timezone": "America/Edmonton"
    },
    {
        "name": "Vegreville",
        "country": "Canada",
        "province": "Alberta",
        "timezone": "America/Edmonton"
    },
    {
        "name": "Stettler",
        "country": "Canada",
        "province": "Alberta",
        "timezone": "America/Edmonton"
    },
    {
        "name": "Lac La Biche",
        "country": "Canada",
        "province": "Alberta",
        "timezone": "America/Edmonton"
    },
    {
        "name": "Wetaskiwin",
        "country": "Canada",
        "province": "Alberta",
        "timezone": "America/Edmonton"
    },
    {
        "name": "Meander River",
        "country": "Canada",
        "province": "Alberta",
        "timezone": "America/Edmonton"
    },
    {
        "name": "Creston",
        "country": "Canada",
        "province": "British Columbia",
        "timezone": "America/Creston"
    },
    {
        "name": "Cranbrook",
        "country": "Canada",
        "province": "British Columbia",
        "timezone": "America/Edmonton"
    },
    {
        "name": "Terrace",
        "country": "Canada",
        "province": "British Columbia",
        "timezone": "America/Vancouver"
    },
    {
        "name": "Chilliwack",
        "country": "Canada",
        "province": "British Columbia",
        "timezone": "America/Vancouver"
    },
    {
        "name": "Hall Beach",
        "country": "Canada",
        "province": "Nunavut",
        "timezone": "America/Iqaluit"
    },
    {
        "name": "Lutselke",
        "country": "Canada",
        "province": "Northwest Territories",
        "timezone": "America/Yellowknife"
    },
    {
        "name": "Hay River",
        "country": "Canada",
        "province": "Northwest Territories",
        "timezone": "America/Yellowknife"
    },
    {
        "name": "Déline",
        "country": "Canada",
        "province": "Northwest Territories",
        "timezone": "America/Yellowknife"
    },
    {
        "name": "Paulatuk",
        "country": "Canada",
        "province": "Northwest Territories",
        "timezone": "America/Yellowknife"
    },
    {
        "name": "Tsiigehtchic",
        "country": "Canada",
        "province": "Northwest Territories",
        "timezone": "America/Yellowknife"
    },
    {
        "name": "Owen Sound",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Toronto"
    },
    {
        "name": "Orillia",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Toronto"
    },
    {
        "name": "Kapuskasing",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Toronto"
    },
    {
        "name": "Thessalon",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Toronto"
    },
    {
        "name": "Geraldton",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Toronto"
    },
    {
        "name": "Belleville",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Toronto"
    },
    {
        "name": "Sarnia",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Toronto"
    },
    {
        "name": "Peterborough",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Toronto"
    },
    {
        "name": "Oshawa",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Toronto"
    },
    {
        "name": "London",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Toronto"
    },
    {
        "name": "Kitchener",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Toronto"
    },
    {
        "name": "New Liskeard",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Toronto"
    },
    {
        "name": "Brockville",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Toronto"
    },
    {
        "name": "Big Beaver House",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Winnipeg"
    },
    {
        "name": "Port-Menier",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "Riviere-du-Loup",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "Drummondville",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "Sherbrooke",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "Cap-Chat",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "Baie-Comeau",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "Natashquan",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "Eastmain",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "Schefferville",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "Salluit",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "Amos",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "Joliette",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "St.-Jerome",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "St-Augustin",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Blanc-Sablon"
    },
    {
        "name": "Rouyn-Noranda",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "La Sarre",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "New Glasgow",
        "country": "Canada",
        "province": "Nova Scotia",
        "timezone": "America/Halifax"
    },
    {
        "name": "Liverpool",
        "country": "Canada",
        "province": "Nova Scotia",
        "timezone": "America/Halifax"
    },
    {
        "name": "Amherst",
        "country": "Canada",
        "province": "Nova Scotia",
        "timezone": "America/Halifax"
    },
    {
        "name": "Baddeck",
        "country": "Canada",
        "province": "Nova Scotia",
        "timezone": "America/Halifax"
    },
    {
        "name": "Deer Lake",
        "country": "Canada",
        "province": "Newfoundland and Labrador",
        "timezone": "America/St_Johns"
    },
    {
        "name": "La Scie",
        "country": "Canada",
        "province": "Newfoundland and Labrador",
        "timezone": "America/St_Johns"
    },
    {
        "name": "Hopedale",
        "country": "Canada",
        "province": "Newfoundland and Labrador",
        "timezone": "America/Goose_Bay"
    },
    {
        "name": "Happy Valley - Goose Bay",
        "country": "Canada",
        "province": "Newfoundland and Labrador",
        "timezone": "America/Goose_Bay"
    },
    {
        "name": "Port Hope Simpson",
        "country": "Canada",
        "province": "Newfoundland and Labrador",
        "timezone": "America/St_Johns"
    },
    {
        "name": "Tofino",
        "country": "Canada",
        "province": "British Columbia",
        "timezone": "America/Vancouver"
    },
    {
        "name": "Steinbach",
        "country": "Canada",
        "province": "Manitoba",
        "timezone": "America/Winnipeg"
    },
    {
        "name": "Nelson House",
        "country": "Canada",
        "province": "Manitoba",
        "timezone": "America/Winnipeg"
    },
    {
        "name": "Shamattawa",
        "country": "Canada",
        "province": "Manitoba",
        "timezone": "America/Winnipeg"
    },
    {
        "name": "Oxford House",
        "country": "Canada",
        "province": "Manitoba",
        "timezone": "America/Winnipeg"
    },
    {
        "name": "Yorkton",
        "country": "Canada",
        "province": "Saskatchewan",
        "timezone": "America/Regina"
    },
    {
        "name": "Swift Current",
        "country": "Canada",
        "province": "Saskatchewan",
        "timezone": "America/Regina"
    },
    {
        "name": "Biggar",
        "country": "Canada",
        "province": "Saskatchewan",
        "timezone": "America/Regina"
    },
    {
        "name": "Kindersley",
        "country": "Canada",
        "province": "Saskatchewan",
        "timezone": "America/Regina"
    },
    {
        "name": "Meadow Lake",
        "country": "Canada",
        "province": "Saskatchewan",
        "timezone": "America/Regina"
    },
    {
        "name": "Hudson Bay",
        "country": "Canada",
        "province": "Saskatchewan",
        "timezone": "America/Regina"
    },
    {
        "name": "Lethbridge",
        "country": "Canada",
        "province": "Alberta",
        "timezone": "America/Edmonton"
    },
    {
        "name": "Brooks",
        "country": "Canada",
        "province": "Alberta",
        "timezone": "America/Edmonton"
    },
    {
        "name": "Lake Louise",
        "country": "Canada",
        "province": "Alberta",
        "timezone": "America/Edmonton"
    },
    {
        "name": "Athabasca",
        "country": "Canada",
        "province": "Alberta",
        "timezone": "America/Edmonton"
    },
    {
        "name": "Fort Chipewyan",
        "country": "Canada",
        "province": "Alberta",
        "timezone": "America/Edmonton"
    },
    {
        "name": "Bella Bella",
        "country": "Canada",
        "province": "British Columbia",
        "timezone": "America/Vancouver"
    },
    {
        "name": "Sandspit",
        "country": "Canada",
        "province": "British Columbia",
        "timezone": "America/Vancouver"
    },
    {
        "name": "Campbell River",
        "country": "Canada",
        "province": "British Columbia",
        "timezone": "America/Vancouver"
    },
    {
        "name": "Port Hardy",
        "country": "Canada",
        "province": "British Columbia",
        "timezone": "America/Vancouver"
    },
    {
        "name": "Nanaimo",
        "country": "Canada",
        "province": "British Columbia",
        "timezone": "America/Vancouver"
    },
    {
        "name": "Quesnel",
        "country": "Canada",
        "province": "British Columbia",
        "timezone": "America/Vancouver"
    },
    {
        "name": "Abbotsford",
        "country": "Canada",
        "province": "British Columbia",
        "timezone": "America/Vancouver"
    },
    {
        "name": "Dawson Creek",
        "country": "Canada",
        "province": "British Columbia",
        "timezone": "America/Dawson_Creek"
    },
    {
        "name": "Penticton",
        "country": "Canada",
        "province": "British Columbia",
        "timezone": "America/Vancouver"
    },
    {
        "name": "Nelson",
        "country": "Canada",
        "province": "British Columbia",
        "timezone": "America/Vancouver"
    },
    {
        "name": "Lillooet",
        "country": "Canada",
        "province": "British Columbia",
        "timezone": "America/Vancouver"
    },
    {
        "name": "Powell River",
        "country": "Canada",
        "province": "British Columbia",
        "timezone": "America/Vancouver"
    },
    {
        "name": "Revelstoke",
        "country": "Canada",
        "province": "British Columbia",
        "timezone": "America/Vancouver"
    },
    {
        "name": "Burns Lake",
        "country": "Canada",
        "province": "British Columbia",
        "timezone": "America/Vancouver"
    },
    {
        "name": "Dease Lake",
        "country": "Canada",
        "province": "British Columbia",
        "timezone": "America/Vancouver"
    },
    {
        "name": "Coral Harbour",
        "country": "Canada",
        "province": "Nunavut",
        "timezone": "America/Coral_Harbour"
    },
    {
        "name": "Baker Lake",
        "country": "Canada",
        "province": "Nunavut",
        "timezone": "America/Rankin_Inlet"
    },
    {
        "name": "Norman Wells",
        "country": "Canada",
        "province": "Northwest Territories",
        "timezone": "America/Yellowknife"
    },
    {
        "name": "Fort McPherson",
        "country": "Canada",
        "province": "Northwest Territories",
        "timezone": "America/Yellowknife"
    },
    {
        "name": "Burwash Landing",
        "country": "Canada",
        "province": "Yukon",
        "timezone": "America/Whitehorse"
    },
    {
        "name": "Orangeville",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Toronto"
    },
    {
        "name": "Little Current",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Toronto"
    },
    {
        "name": "Chapleau",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Toronto"
    },
    {
        "name": "Wawa",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Toronto"
    },
    {
        "name": "Hearst",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Toronto"
    },
    {
        "name": "Marathon",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Toronto"
    },
    {
        "name": "Sioux Lookout",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Winnipeg"
    },
    {
        "name": "Red Lake",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Winnipeg"
    },
    {
        "name": "Deer Lake",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Winnipeg"
    },
    {
        "name": "Cat Lake",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Winnipeg"
    },
    {
        "name": "Cornwall",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Toronto"
    },
    {
        "name": "Kingston",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Toronto"
    },
    {
        "name": "Barrie",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Toronto"
    },
    {
        "name": "Parry Sound",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Toronto"
    },
    {
        "name": "Wiarton",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Toronto"
    },
    {
        "name": "Cobalt",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Toronto"
    },
    {
        "name": "Cochrane",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Toronto"
    },
    {
        "name": "Nipigon",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Nipigon"
    },
    {
        "name": "Atikokan",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Atikokan"
    },
    {
        "name": "Rimouski",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "Saint-Georges",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "Victoriaville",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "Chevery",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Blanc-Sablon"
    },
    {
        "name": "Mistassini",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "Kangirsuk",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "Shawinigan",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "Matagami",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "Mont-Laurier",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "Pembroke",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "Radisson",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "Saint John",
        "country": "Canada",
        "province": "New Brunswick",
        "timezone": "America/Moncton"
    },
    {
        "name": "Edmundston",
        "country": "Canada",
        "province": "New Brunswick",
        "timezone": "America/Moncton"
    },
    {
        "name": "Shelburne",
        "country": "Canada",
        "province": "Nova Scotia",
        "timezone": "America/Halifax"
    },
    {
        "name": "Antigonish",
        "country": "Canada",
        "province": "Nova Scotia",
        "timezone": "America/Halifax"
    },
    {
        "name": "Windsor",
        "country": "Canada",
        "province": "Nova Scotia",
        "timezone": "America/Halifax"
    },
    {
        "name": "Digby",
        "country": "Canada",
        "province": "Nova Scotia",
        "timezone": "America/Halifax"
    },
    {
        "name": "Stephenville",
        "country": "Canada",
        "province": "Newfoundland and Labrador",
        "timezone": "America/St_Johns"
    },
    {
        "name": "Argentia",
        "country": "Canada",
        "province": "Newfoundland and Labrador",
        "timezone": "America/St_Johns"
    },
    {
        "name": "St. Anthony",
        "country": "Canada",
        "province": "Newfoundland and Labrador",
        "timezone": "America/St_Johns"
    },
    {
        "name": "Channel-Port aux Basques",
        "country": "Canada",
        "province": "Newfoundland and Labrador",
        "timezone": "America/St_Johns"
    },
    {
        "name": "Buchans",
        "country": "Canada",
        "province": "Newfoundland and Labrador",
        "timezone": "America/St_Johns"
    },
    {
        "name": "Trout River",
        "country": "Canada",
        "province": "Newfoundland and Labrador",
        "timezone": "America/St_Johns"
    },
    {
        "name": "Churchill Falls",
        "country": "Canada",
        "province": "Newfoundland and Labrador",
        "timezone": "America/Goose_Bay"
    },
    {
        "name": "Forteau",
        "country": "Canada",
        "province": "Newfoundland and Labrador",
        "timezone": "America/St_Johns"
    },
    {
        "name": "Trepassey",
        "country": "Canada",
        "province": "Newfoundland and Labrador",
        "timezone": "America/St_Johns"
    },
    {
        "name": "Brochet",
        "country": "Canada",
        "province": "Manitoba",
        "timezone": "America/Winnipeg"
    },
    {
        "name": "Lynn Lake",
        "country": "Canada",
        "province": "Manitoba",
        "timezone": "America/Winnipeg"
    },
    {
        "name": "Gillam",
        "country": "Canada",
        "province": "Manitoba",
        "timezone": "America/Winnipeg"
    },
    {
        "name": "North Battleford",
        "country": "Canada",
        "province": "Saskatchewan",
        "timezone": "America/Regina"
    },
    {
        "name": "Prince Albert",
        "country": "Canada",
        "province": "Saskatchewan",
        "timezone": "America/Regina"
    },
    {
        "name": "Courtenay",
        "country": "Canada",
        "province": "British Columbia",
        "timezone": "America/Vancouver"
    },
    {
        "name": "Kelowna",
        "country": "Canada",
        "province": "British Columbia",
        "timezone": "America/Vancouver"
    },
    {
        "name": "Pangnirtung",
        "country": "Canada",
        "province": "Nunavut",
        "timezone": "America/Pangnirtung"
    },
    {
        "name": "Holman",
        "country": "Canada",
        "province": "Northwest Territories",
        "timezone": "America/Yellowknife"
    },
    {
        "name": "Dryden",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Winnipeg"
    },
    {
        "name": "Attawapiskat",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Toronto"
    },
    {
        "name": "Hamilton",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Toronto"
    },
    {
        "name": "Windsor",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Detroit"
    },
    {
        "name": "Trois-Rivières",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "Sept-Îles",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "Corner Brook",
        "country": "Canada",
        "province": "Newfoundland and Labrador",
        "timezone": "America/St_Johns"
    },
    {
        "name": "Norway House",
        "country": "Canada",
        "province": "Manitoba",
        "timezone": "America/Winnipeg"
    },
    {
        "name": "Flin Flon",
        "country": "Canada",
        "province": "Manitoba",
        "timezone": "America/Winnipeg"
    },
    {
        "name": "Dauphin",
        "country": "Canada",
        "province": "Manitoba",
        "timezone": "America/Winnipeg"
    },
    {
        "name": "The Pas",
        "country": "Canada",
        "province": "Manitoba",
        "timezone": "America/Winnipeg"
    },
    {
        "name": "Uranium City",
        "country": "Canada",
        "province": "Saskatchewan",
        "timezone": "America/Regina"
    },
    {
        "name": "Moose Jaw",
        "country": "Canada",
        "province": "Saskatchewan",
        "timezone": "America/Regina"
    },
    {
        "name": "Jasper",
        "country": "Canada",
        "province": "Alberta",
        "timezone": "America/Edmonton"
    },
    {
        "name": "Medicine Hat",
        "country": "Canada",
        "province": "Alberta",
        "timezone": "America/Edmonton"
    },
    {
        "name": "Red Deer",
        "country": "Canada",
        "province": "Alberta",
        "timezone": "America/Edmonton"
    },
    {
        "name": "Banff",
        "country": "Canada",
        "province": "Alberta",
        "timezone": "America/Edmonton"
    },
    {
        "name": "Grand Prairie",
        "country": "Canada",
        "province": "Alberta",
        "timezone": "America/Edmonton"
    },
    {
        "name": "Smithers",
        "country": "Canada",
        "province": "British Columbia",
        "timezone": "America/Vancouver"
    },
    {
        "name": "Kamloops",
        "country": "Canada",
        "province": "British Columbia",
        "timezone": "America/Vancouver"
    },
    {
        "name": "Williams Lake",
        "country": "Canada",
        "province": "British Columbia",
        "timezone": "America/Vancouver"
    },
    {
        "name": "Prince George",
        "country": "Canada",
        "province": "British Columbia",
        "timezone": "America/Vancouver"
    },
    {
        "name": "Fort Nelson",
        "country": "Canada",
        "province": "British Columbia",
        "timezone": "America/Fort_Nelson"
    },
    {
        "name": "Pond Inlet",
        "country": "Canada",
        "province": "Nunavut",
        "timezone": "America/Iqaluit"
    },
    {
        "name": "Cape Dorset",
        "country": "Canada",
        "province": "Nunavut",
        "timezone": "America/Iqaluit"
    },
    {
        "name": "Kimmirut",
        "country": "Canada",
        "province": "Nunavut",
        "timezone": "America/Iqaluit"
    },
    {
        "name": "Gjoa Haven",
        "country": "Canada",
        "province": "Nunavut",
        "timezone": "America/Cambridge_Bay"
    },
    {
        "name": "Grise Fiord",
        "country": "Canada",
        "province": "Nunavut",
        "timezone": "America/Iqaluit"
    },
    {
        "name": "Alert",
        "country": "Canada",
        "province": "Nunavut",
        "timezone": "America/Pangnirtung"
    },
    {
        "name": "Ennadai",
        "country": "Canada",
        "province": "Nunavut",
        "timezone": "America/Rankin_Inlet"
    },
    {
        "name": "Rankin Inlet",
        "country": "Canada",
        "province": "Nunavut",
        "timezone": "America/Rankin_Inlet"
    },
    {
        "name": "Fort Resolution",
        "country": "Canada",
        "province": "Northwest Territories",
        "timezone": "America/Yellowknife"
    },
    {
        "name": "Fort Simpson",
        "country": "Canada",
        "province": "Northwest Territories",
        "timezone": "America/Yellowknife"
    },
    {
        "name": "Inuvik",
        "country": "Canada",
        "province": "Northwest Territories",
        "timezone": "America/Inuvik"
    },
    {
        "name": "Tuktoyaktuk",
        "country": "Canada",
        "province": "Northwest Territories",
        "timezone": "America/Yellowknife"
    },
    {
        "name": "Watson Lake",
        "country": "Canada",
        "province": "Yukon",
        "timezone": "America/Whitehorse"
    },
    {
        "name": "Lansdowne House",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Toronto"
    },
    {
        "name": "Moosonee",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Toronto"
    },
    {
        "name": "Sudbury",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Toronto"
    },
    {
        "name": "Kenora",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Winnipeg"
    },
    {
        "name": "Gaspe",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "Mingan",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "Dolbeau",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "Val d'Or",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "Ivugivik",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "Inukjuak",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "Chicoutimi",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "Moncton",
        "country": "Canada",
        "province": "New Brunswick",
        "timezone": "America/Moncton"
    },
    {
        "name": "Fredericton",
        "country": "Canada",
        "province": "New Brunswick",
        "timezone": "America/Moncton"
    },
    {
        "name": "Bathurst",
        "country": "Canada",
        "province": "New Brunswick",
        "timezone": "America/Moncton"
    },
    {
        "name": "Yarmouth",
        "country": "Canada",
        "province": "Nova Scotia",
        "timezone": "America/Halifax"
    },
    {
        "name": "Gander",
        "country": "Canada",
        "province": "Newfoundland and Labrador",
        "timezone": "America/St_Johns"
    },
    {
        "name": "Cartwright",
        "country": "Canada",
        "province": "Newfoundland and Labrador",
        "timezone": "America/Goose_Bay"
    },
    {
        "name": "Rigolet",
        "country": "Canada",
        "province": "Newfoundland and Labrador",
        "timezone": "America/Goose_Bay"
    },
    {
        "name": "Port Burwell",
        "country": "Canada",
        "province": "Newfoundland and Labrador",
        "timezone": "America/Goose_Bay"
    },
    {
        "name": "Thompson",
        "country": "Canada",
        "province": "Manitoba",
        "timezone": "America/Winnipeg"
    },
    {
        "name": "Brandon",
        "country": "Canada",
        "province": "Manitoba",
        "timezone": "America/Winnipeg"
    },
    {
        "name": "Fort Smith",
        "country": "Canada",
        "province": "Alberta",
        "timezone": "America/Yellowknife"
    },
    {
        "name": "Fort McMurray",
        "country": "Canada",
        "province": "Alberta",
        "timezone": "America/Edmonton"
    },
    {
        "name": "Peace River",
        "country": "Canada",
        "province": "Alberta",
        "timezone": "America/Edmonton"
    },
    {
        "name": "Fort St. John",
        "country": "Canada",
        "province": "British Columbia",
        "timezone": null
    },
    {
        "name": "Iqaluit",
        "country": "Canada",
        "province": "Nunavut",
        "timezone": "America/Iqaluit"
    },
    {
        "name": "Cambridge Bay",
        "country": "Canada",
        "province": "Nunavut",
        "timezone": "America/Cambridge_Bay"
    },
    {
        "name": "Kugluktuk",
        "country": "Canada",
        "province": "Nunavut",
        "timezone": "America/Cambridge_Bay"
    },
    {
        "name": "Chesterfield Inlet",
        "country": "Canada",
        "province": "Nunavut",
        "timezone": "America/Rankin_Inlet"
    },
    {
        "name": "Arviat",
        "country": "Canada",
        "province": "Nunavut",
        "timezone": "America/Rankin_Inlet"
    },
    {
        "name": "Taloyoak",
        "country": "Canada",
        "province": "Nunavut",
        "timezone": "America/Cambridge_Bay"
    },
    {
        "name": "Igloolik",
        "country": "Canada",
        "province": "Nunavut",
        "timezone": "America/Iqaluit"
    },
    {
        "name": "Dawson City",
        "country": "Canada",
        "province": "Yukon",
        "timezone": "America/Dawson"
    },
    {
        "name": "Timmins",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Toronto"
    },
    {
        "name": "North Bay",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Toronto"
    },
    {
        "name": "Kuujjuarapik",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "Kuujjuaq",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "Sydney",
        "country": "Canada",
        "province": "Nova Scotia",
        "timezone": "America/Glace_Bay"
    },
    {
        "name": "Labrador City",
        "country": "Canada",
        "province": "Newfoundland and Labrador",
        "timezone": "America/Goose_Bay"
    },
    {
        "name": "Winnipeg",
        "country": "Canada",
        "province": "Manitoba",
        "timezone": "America/Winnipeg"
    },
    {
        "name": "Churchill",
        "country": "Canada",
        "province": "Manitoba",
        "timezone": "America/Winnipeg"
    },
    {
        "name": "Regina",
        "country": "Canada",
        "province": "Saskatchewan",
        "timezone": "America/Regina"
    },
    {
        "name": "Saskatoon",
        "country": "Canada",
        "province": "Saskatchewan",
        "timezone": "America/Regina"
    },
    {
        "name": "Calgary",
        "country": "Canada",
        "province": "Alberta",
        "timezone": "America/Edmonton"
    },
    {
        "name": "Prince Rupert",
        "country": "Canada",
        "province": "British Columbia",
        "timezone": "America/Vancouver"
    },
    {
        "name": "Victoria",
        "country": "Canada",
        "province": "British Columbia",
        "timezone": "America/Vancouver"
    },
    {
        "name": "Arctic Bay",
        "country": "Canada",
        "province": "Nunavut",
        "timezone": "America/Rankin_Inlet"
    },
    {
        "name": "Resolute",
        "country": "Canada",
        "province": "Nunavut",
        "timezone": "America/Resolute"
    },
    {
        "name": "Repulse Bay",
        "country": "Canada",
        "province": "Nunavut",
        "timezone": "America/Rankin_Inlet"
    },
    {
        "name": "Yellowknife",
        "country": "Canada",
        "province": "Northwest Territories",
        "timezone": "America/Yellowknife"
    },
    {
        "name": "Fort Good Hope",
        "country": "Canada",
        "province": "Northwest Territories",
        "timezone": "America/Yellowknife"
    },
    {
        "name": "Whitehorse",
        "country": "Canada",
        "province": "Yukon",
        "timezone": "America/Whitehorse"
    },
    {
        "name": "Ottawa",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Toronto"
    },
    {
        "name": "Fort Severn",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Toronto"
    },
    {
        "name": "Thunder Bay",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Thunder_Bay"
    },
    {
        "name": "Québec",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "Halifax",
        "country": "Canada",
        "province": "Nova Scotia",
        "timezone": "America/Halifax"
    },
    {
        "name": "St. John’s",
        "country": "Canada",
        "province": "Newfoundland and Labrador",
        "timezone": "America/St_Johns"
    },
    {
        "name": "Nain",
        "country": "Canada",
        "province": "Newfoundland and Labrador",
        "timezone": "America/Goose_Bay"
    },
    {
        "name": "Charlottetown",
        "country": "Canada",
        "province": "Prince Edward Island",
        "timezone": "America/Halifax"
    },
    {
        "name": "Edmonton",
        "country": "Canada",
        "province": "Alberta",
        "timezone": "America/Edmonton"
    },
    {
        "name": "Montréal",
        "country": "Canada",
        "province": "Québec",
        "timezone": "America/Montreal"
    },
    {
        "name": "Vancouver",
        "country": "Canada",
        "province": "British Columbia",
        "timezone": "America/Vancouver"
    },
    {
        "name": "Toronto",
        "country": "Canada",
        "province": "Ontario",
        "timezone": "America/Toronto"
    },
    {
        "name": "Mindelo",
        "country": "Cape Verde",
        "province": "",
        "timezone": "Atlantic/Cape_Verde"
    },
    {
        "name": "Praia",
        "country": "Cape Verde",
        "province": "",
        "timezone": "Atlantic/Cape_Verde"
    },
    {
        "name": "George Town",
        "country": "Cayman Islands",
        "province": "",
        "timezone": "America/Cayman"
    },
    {
        "name": "Mobaye",
        "country": "Central African Republic",
        "province": "Basse-Kotto",
        "timezone": "Africa/Bangui"
    },
    {
        "name": "Mbaiki",
        "country": "Central African Republic",
        "province": "Lobaye",
        "timezone": "Africa/Bangui"
    },
    {
        "name": "Carnot",
        "country": "Central African Republic",
        "province": "Mambéré-Kadéï",
        "timezone": "Africa/Bangui"
    },
    {
        "name": "Bozoum",
        "country": "Central African Republic",
        "province": "Ouham-Pendé",
        "timezone": "Africa/Bangui"
    },
    {
        "name": "Kaga Bandoro",
        "country": "Central African Republic",
        "province": "Nana-Grébizi",
        "timezone": "Africa/Bangui"
    },
    {
        "name": "Zemio",
        "country": "Central African Republic",
        "province": "Haut-Mbomou",
        "timezone": "Africa/Bangui"
    },
    {
        "name": "Yakossi",
        "country": "Central African Republic",
        "province": "Mbomou",
        "timezone": "Africa/Bangui"
    },
    {
        "name": "Nola",
        "country": "Central African Republic",
        "province": "Sangha-Mbaéré",
        "timezone": "Africa/Bangui"
    },
    {
        "name": "Sibut",
        "country": "Central African Republic",
        "province": "Kémo",
        "timezone": "Africa/Bangui"
    },
    {
        "name": "Bossangoa",
        "country": "Central African Republic",
        "province": "Ouham",
        "timezone": "Africa/Bangui"
    },
    {
        "name": "Birao",
        "country": "Central African Republic",
        "province": "Vakaga",
        "timezone": "Africa/Bangui"
    },
    {
        "name": "Ouadda",
        "country": "Central African Republic",
        "province": "Haute-Kotto",
        "timezone": "Africa/Bangui"
    },
    {
        "name": "Bangassou",
        "country": "Central African Republic",
        "province": "Mbomou",
        "timezone": "Africa/Bangui"
    },
    {
        "name": "Bossembele",
        "country": "Central African Republic",
        "province": "Bangui",
        "timezone": "Africa/Bangui"
    },
    {
        "name": "Berberati",
        "country": "Central African Republic",
        "province": "Mambéré-Kadéï",
        "timezone": "Africa/Bangui"
    },
    {
        "name": "Bria",
        "country": "Central African Republic",
        "province": "Haute-Kotto",
        "timezone": "Africa/Bangui"
    },
    {
        "name": "Bouar",
        "country": "Central African Republic",
        "province": "Nana-Mambéré",
        "timezone": "Africa/Bangui"
    },
    {
        "name": "Bambari",
        "country": "Central African Republic",
        "province": "Ouaka",
        "timezone": "Africa/Bangui"
    },
    {
        "name": "Ndele",
        "country": "Central African Republic",
        "province": "Bamingui-Bangoran",
        "timezone": "Africa/Bangui"
    },
    {
        "name": "Obo",
        "country": "Central African Republic",
        "province": "Haut-Mbomou",
        "timezone": "Africa/Bangui"
    },
    {
        "name": "Bangui",
        "country": "Central African Republic",
        "province": "Bangui",
        "timezone": "Africa/Bangui"
    },
    {
        "name": "Lai",
        "country": "Chad",
        "province": "Tandjilé",
        "timezone": "Africa/Ndjamena"
    },
    {
        "name": "Zouar",
        "country": "Chad",
        "province": "Bet",
        "timezone": "Africa/Ndjamena"
    },
    {
        "name": "Bol",
        "country": "Chad",
        "province": "Lac",
        "timezone": "Africa/Ndjamena"
    },
    {
        "name": "Ati",
        "country": "Chad",
        "province": "Batha",
        "timezone": "Africa/Ndjamena"
    },
    {
        "name": "Oum Hadjer",
        "country": "Chad",
        "province": "Batha",
        "timezone": "Africa/Ndjamena"
    },
    {
        "name": "Mongo",
        "country": "Chad",
        "province": "Guéra",
        "timezone": "Africa/Ndjamena"
    },
    {
        "name": "Doba",
        "country": "Chad",
        "province": "Logone Oriental",
        "timezone": "Africa/Ndjamena"
    },
    {
        "name": "Pala",
        "country": "Chad",
        "province": "Mayo-Kebbi Est",
        "timezone": "Africa/Ndjamena"
    },
    {
        "name": "Bongor",
        "country": "Chad",
        "province": "Mayo-Kebbi Est",
        "timezone": "Africa/Ndjamena"
    },
    {
        "name": "Kelo",
        "country": "Chad",
        "province": "Tandjilé",
        "timezone": "Africa/Ndjamena"
    },
    {
        "name": "Fada",
        "country": "Chad",
        "province": "Bet",
        "timezone": "Africa/Ndjamena"
    },
    {
        "name": "Faya Largeau",
        "country": "Chad",
        "province": "Bet",
        "timezone": "Africa/Ndjamena"
    },
    {
        "name": "Mao",
        "country": "Chad",
        "province": "Kanem",
        "timezone": "Africa/Ndjamena"
    },
    {
        "name": "Biltine",
        "country": "Chad",
        "province": "Wadi Fira",
        "timezone": "Africa/Ndjamena"
    },
    {
        "name": "Sarh",
        "country": "Chad",
        "province": "Mandoul",
        "timezone": "Africa/Ndjamena"
    },
    {
        "name": "Am Timan",
        "country": "Chad",
        "province": "Salamat",
        "timezone": "Africa/Ndjamena"
    },
    {
        "name": "Moundou",
        "country": "Chad",
        "province": "Logone Oriental",
        "timezone": "Africa/Ndjamena"
    },
    {
        "name": "Ndjamena",
        "country": "Chad",
        "province": "Hadjer-Lamis",
        "timezone": "Africa/Ndjamena"
    },
    {
        "name": "Abeche",
        "country": "Chad",
        "province": "Ouaddaï",
        "timezone": "Africa/Ndjamena"
    },
    {
        "name": "Rio Verde",
        "country": "Chile",
        "province": "Magallanes y Antártica Chilena",
        "timezone": "America/Santiago"
    },
    {
        "name": "Cuya",
        "country": "Chile",
        "province": "Tarapacá",
        "timezone": "America/Santiago"
    },
    {
        "name": "Chuquicamata",
        "country": "Chile",
        "province": "Antofagasta",
        "timezone": "America/Santiago"
    },
    {
        "name": "Maria Elena",
        "country": "Chile",
        "province": "Antofagasta",
        "timezone": "America/Santiago"
    },
    {
        "name": "Tierra Amarilla",
        "country": "Chile",
        "province": "Atacama",
        "timezone": "America/Santiago"
    },
    {
        "name": "Combarbala",
        "country": "Chile",
        "province": "Coquimbo",
        "timezone": "America/Santiago"
    },
    {
        "name": "San Bernardo",
        "country": "Chile",
        "province": "Región Metropolitana de Santiago",
        "timezone": "America/Santiago"
    },
    {
        "name": "San Felipe",
        "country": "Chile",
        "province": "Valparaíso",
        "timezone": "America/Santiago"
    },
    {
        "name": "Vina del Mar",
        "country": "Chile",
        "province": "Valparaíso",
        "timezone": "America/Santiago"
    },
    {
        "name": "La Ligua",
        "country": "Chile",
        "province": "Valparaíso",
        "timezone": "America/Santiago"
    },
    {
        "name": "Quillota",
        "country": "Chile",
        "province": "Valparaíso",
        "timezone": "America/Santiago"
    },
    {
        "name": "Nueva Imperial",
        "country": "Chile",
        "province": "La Araucanía",
        "timezone": "America/Santiago"
    },
    {
        "name": "Loncoche",
        "country": "Chile",
        "province": "La Araucanía",
        "timezone": "America/Santiago"
    },
    {
        "name": "Villarica",
        "country": "Chile",
        "province": "La Araucanía",
        "timezone": "America/Santiago"
    },
    {
        "name": "Tolten",
        "country": "Chile",
        "province": "La Araucanía",
        "timezone": "America/Santiago"
    },
    {
        "name": "Lonquimay",
        "country": "Chile",
        "province": "La Araucanía",
        "timezone": "America/Santiago"
    },
    {
        "name": "Angol",
        "country": "Chile",
        "province": "La Araucanía",
        "timezone": "America/Santiago"
    },
    {
        "name": "Collipulli",
        "country": "Chile",
        "province": "La Araucanía",
        "timezone": "America/Santiago"
    },
    {
        "name": "La Union",
        "country": "Chile",
        "province": "Los Ríos",
        "timezone": "America/Santiago"
    },
    {
        "name": "Rio Bueno",
        "country": "Chile",
        "province": "Los Ríos",
        "timezone": "America/Santiago"
    },
    {
        "name": "Coronel",
        "country": "Chile",
        "province": "Bío-Bío",
        "timezone": "America/Santiago"
    },
    {
        "name": "Talcahuano",
        "country": "Chile",
        "province": "Bío-Bío",
        "timezone": "America/Santiago"
    },
    {
        "name": "Quirihue",
        "country": "Chile",
        "province": "Bío-Bío",
        "timezone": "America/Santiago"
    },
    {
        "name": "Curanilahue",
        "country": "Chile",
        "province": "Bío-Bío",
        "timezone": "America/Santiago"
    },
    {
        "name": "Santa Barbara",
        "country": "Chile",
        "province": "Bío-Bío",
        "timezone": "America/Santiago"
    },
    {
        "name": "Pichilemu",
        "country": "Chile",
        "province": "Libertador General Bernardo O'Higgins",
        "timezone": "America/Santiago"
    },
    {
        "name": "San Fernando",
        "country": "Chile",
        "province": "Libertador General Bernardo O'Higgins",
        "timezone": "America/Santiago"
    },
    {
        "name": "Puerto Varas",
        "country": "Chile",
        "province": "Los Lagos",
        "timezone": "America/Santiago"
    },
    {
        "name": "Calbuco",
        "country": "Chile",
        "province": "Los Lagos",
        "timezone": "America/Santiago"
    },
    {
        "name": "Castro",
        "country": "Chile",
        "province": "Los Lagos",
        "timezone": "America/Santiago"
    },
    {
        "name": "Chonchi",
        "country": "Chile",
        "province": "Los Lagos",
        "timezone": "America/Santiago"
    },
    {
        "name": "Linares",
        "country": "Chile",
        "province": "Maule",
        "timezone": "America/Santiago"
    },
    {
        "name": "Cauquenes",
        "country": "Chile",
        "province": "Maule",
        "timezone": "America/Santiago"
    },
    {
        "name": "Cochrane",
        "country": "Chile",
        "province": "Aisén del General Carlos Ibáñez del Campo",
        "timezone": "America/Santiago"
    },
    {
        "name": "Lagunas",
        "country": "Chile",
        "province": "Tarapacá",
        "timezone": "America/Santiago"
    },
    {
        "name": "Pozo Almonte",
        "country": "Chile",
        "province": "Tarapacá",
        "timezone": "America/Santiago"
    },
    {
        "name": "Toconao",
        "country": "Chile",
        "province": "Antofagasta",
        "timezone": "America/Santiago"
    },
    {
        "name": "Huasco",
        "country": "Chile",
        "province": "Atacama",
        "timezone": "America/Santiago"
    },
    {
        "name": "Diego de Almagro",
        "country": "Chile",
        "province": "Atacama",
        "timezone": "America/Santiago"
    },
    {
        "name": "Caldera",
        "country": "Chile",
        "province": "Atacama",
        "timezone": "America/Santiago"
    },
    {
        "name": "Coquimbo",
        "country": "Chile",
        "province": "Coquimbo",
        "timezone": "America/Santiago"
    },
    {
        "name": "Vicuna",
        "country": "Chile",
        "province": "Coquimbo",
        "timezone": "America/Santiago"
    },
    {
        "name": "Illapel",
        "country": "Chile",
        "province": "Coquimbo",
        "timezone": "America/Santiago"
    },
    {
        "name": "Salamanca",
        "country": "Chile",
        "province": "Coquimbo",
        "timezone": "America/Santiago"
    },
    {
        "name": "Los Andes",
        "country": "Chile",
        "province": "Valparaíso",
        "timezone": "America/Santiago"
    },
    {
        "name": "San Antonio",
        "country": "Chile",
        "province": "Valparaíso",
        "timezone": "America/Santiago"
    },
    {
        "name": "Victoria",
        "country": "Chile",
        "province": "La Araucanía",
        "timezone": "America/Santiago"
    },
    {
        "name": "Carahue",
        "country": "Chile",
        "province": "La Araucanía",
        "timezone": "America/Santiago"
    },
    {
        "name": "Los Lagos",
        "country": "Chile",
        "province": "Los Ríos",
        "timezone": "America/Santiago"
    },
    {
        "name": "Lota",
        "country": "Chile",
        "province": "Bío-Bío",
        "timezone": "America/Santiago"
    },
    {
        "name": "Lebu",
        "country": "Chile",
        "province": "Bío-Bío",
        "timezone": "America/Santiago"
    },
    {
        "name": "Quellon",
        "country": "Chile",
        "province": "Los Lagos",
        "timezone": "America/Santiago"
    },
    {
        "name": "Constitucion",
        "country": "Chile",
        "province": "Maule",
        "timezone": "America/Santiago"
    },
    {
        "name": "Villa O'Higgins",
        "country": "Chile",
        "province": "Santa Cruz",
        "timezone": "America/Santiago"
    },
    {
        "name": "Puerto Aisen",
        "country": "Chile",
        "province": "Aisén del General Carlos Ibáñez del Campo",
        "timezone": "America/Santiago"
    },
    {
        "name": "Puerto Natales",
        "country": "Chile",
        "province": "Magallanes y Antártica Chilena",
        "timezone": "America/Santiago"
    },
    {
        "name": "Puerto Williams",
        "country": "Chile",
        "province": "Magallanes y Antártica Chilena",
        "timezone": "America/Santiago"
    },
    {
        "name": "Temuco",
        "country": "Chile",
        "province": "La Araucanía",
        "timezone": "America/Santiago"
    },
    {
        "name": "Tocopilla",
        "country": "Chile",
        "province": "Antofagasta",
        "timezone": "America/Santiago"
    },
    {
        "name": "Calama",
        "country": "Chile",
        "province": "Antofagasta",
        "timezone": "America/Santiago"
    },
    {
        "name": "Mejillones",
        "country": "Chile",
        "province": "Antofagasta",
        "timezone": "America/Santiago"
    },
    {
        "name": "Taltal",
        "country": "Chile",
        "province": "Antofagasta",
        "timezone": "America/Santiago"
    },
    {
        "name": "Vallenar",
        "country": "Chile",
        "province": "Atacama",
        "timezone": "America/Santiago"
    },
    {
        "name": "Chanaral",
        "country": "Chile",
        "province": "Atacama",
        "timezone": "America/Santiago"
    },
    {
        "name": "Ovalle",
        "country": "Chile",
        "province": "Coquimbo",
        "timezone": "America/Santiago"
    },
    {
        "name": "Chillan",
        "country": "Chile",
        "province": "Bío-Bío",
        "timezone": "America/Santiago"
    },
    {
        "name": "Rancagua",
        "country": "Chile",
        "province": "Libertador General Bernardo O'Higgins",
        "timezone": "America/Santiago"
    },
    {
        "name": "Osorno",
        "country": "Chile",
        "province": "Los Lagos",
        "timezone": "America/Santiago"
    },
    {
        "name": "Ancud",
        "country": "Chile",
        "province": "Los Lagos",
        "timezone": "America/Santiago"
    },
    {
        "name": "Talca",
        "country": "Chile",
        "province": "Maule",
        "timezone": "America/Santiago"
    },
    {
        "name": "Curico",
        "country": "Chile",
        "province": "Maule",
        "timezone": "America/Santiago"
    },
    {
        "name": "Coihaique",
        "country": "Chile",
        "province": "Aisén del General Carlos Ibáñez del Campo",
        "timezone": "America/Santiago"
    },
    {
        "name": "Arica",
        "country": "Chile",
        "province": "Arica y Parinacota",
        "timezone": "America/Santiago"
    },
    {
        "name": "Copiapo",
        "country": "Chile",
        "province": "Atacama",
        "timezone": "America/Santiago"
    },
    {
        "name": "La Serena",
        "country": "Chile",
        "province": "Coquimbo",
        "timezone": "America/Santiago"
    },
    {
        "name": "Los Angeles",
        "country": "Chile",
        "province": "Bío-Bío",
        "timezone": "America/Santiago"
    },
    {
        "name": "Punta Arenas",
        "country": "Chile",
        "province": "Magallanes y Antártica Chilena",
        "timezone": "America/Santiago"
    },
    {
        "name": "Iquique",
        "country": "Chile",
        "province": "Tarapacá",
        "timezone": "America/Santiago"
    },
    {
        "name": "Antofagasta",
        "country": "Chile",
        "province": "Antofagasta",
        "timezone": "America/Santiago"
    },
    {
        "name": "Valparaiso",
        "country": "Chile",
        "province": "Valparaíso",
        "timezone": "America/Santiago"
    },
    {
        "name": "Valdivia",
        "country": "Chile",
        "province": "Los Ríos",
        "timezone": "America/Santiago"
    },
    {
        "name": "Concepcion",
        "country": "Chile",
        "province": "Bío-Bío",
        "timezone": "America/Santiago"
    },
    {
        "name": "Puerto Montt",
        "country": "Chile",
        "province": "Los Lagos",
        "timezone": "America/Santiago"
    },
    {
        "name": "Santiago",
        "country": "Chile",
        "province": "Región Metropolitana de Santiago",
        "timezone": "America/Santiago"
    },
    {
        "name": "Yumen",
        "country": "China",
        "province": "Gansu",
        "timezone": "Asia/Urumqi"
    },
    {
        "name": "Linxia",
        "country": "China",
        "province": "Gansu",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Zhuozhou",
        "country": "China",
        "province": "Beijing",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Sanming",
        "country": "China",
        "province": "Fujian",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Huizhou",
        "country": "China",
        "province": "Guangdong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Chaozhou",
        "country": "China",
        "province": "Guangdong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Gyangze",
        "country": "China",
        "province": "Xizang",
        "timezone": "Asia/Urumqi"
    },
    {
        "name": "Dali",
        "country": "China",
        "province": "Shaanxi",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Yangquan",
        "country": "China",
        "province": "Shanxi",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Shiyan",
        "country": "China",
        "province": "Hubei",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Danjiangkou",
        "country": "China",
        "province": "Hubei",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Shashi",
        "country": "China",
        "province": "Hubei",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Anlu",
        "country": "China",
        "province": "Hubei",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Zixing",
        "country": "China",
        "province": "Hunan",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Deyang",
        "country": "China",
        "province": "Sichuan",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Tengchong",
        "country": "China",
        "province": "Yunnan",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Mengzi",
        "country": "China",
        "province": "Yunnan",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Chuxiong",
        "country": "China",
        "province": "Yunnan",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Hengshui",
        "country": "China",
        "province": "Hebei",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Xuanhua",
        "country": "China",
        "province": "Hebei",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Luohe",
        "country": "China",
        "province": "Henan",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Beipiao",
        "country": "China",
        "province": "Liaoning",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Wafangdian",
        "country": "China",
        "province": "Liaoning",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Zhucheng",
        "country": "China",
        "province": "Shandong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Hangu",
        "country": "China",
        "province": "Tianjin",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Xinyi",
        "country": "China",
        "province": "Jiangsu",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Yangzhou",
        "country": "China",
        "province": "Jiangsu",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Linhai",
        "country": "China",
        "province": "Zhejiang",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Huangyan",
        "country": "China",
        "province": "Zhejiang",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Daan",
        "country": "China",
        "province": "Jilin",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Changling",
        "country": "China",
        "province": "Jilin",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Tonghua",
        "country": "China",
        "province": "Jilin",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Baishan",
        "country": "China",
        "province": "Jilin",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Yanji",
        "country": "China",
        "province": "Jilin",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Ergun Zuoqi",
        "country": "China",
        "province": "Nei Mongol",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Shangdu",
        "country": "China",
        "province": "Nei Mongol",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Orongen Zizhiqi",
        "country": "China",
        "province": "Nei Mongol",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Zalantun",
        "country": "China",
        "province": "Nei Mongol",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Wuchuan",
        "country": "China",
        "province": "Nei Mongol",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Hanggin Houqi",
        "country": "China",
        "province": "Nei Mongol",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Anda",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Qinggang",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Angangxi",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Hulan Ergi",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Qingan",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Baiquan",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Suileng",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Linkou",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Longxi",
        "country": "China",
        "province": "Gansu",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Pingliang",
        "country": "China",
        "province": "Gansu",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Anxi",
        "country": "China",
        "province": "Gansu",
        "timezone": "Asia/Urumqi"
    },
    {
        "name": "Minxian",
        "country": "China",
        "province": "Gansu",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Jinchang",
        "country": "China",
        "province": "Gansu",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Guide",
        "country": "China",
        "province": "Gansu",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Qinzhou",
        "country": "China",
        "province": "Guangxi",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Pingxiang",
        "country": "China",
        "province": "Guangxi",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Yishan",
        "country": "China",
        "province": "Guangxi",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Beihai",
        "country": "China",
        "province": "Guangxi",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Hechi",
        "country": "China",
        "province": "Guangxi",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Tongren",
        "country": "China",
        "province": "Guizhou",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Fengjie",
        "country": "China",
        "province": "Chongqing",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Changping",
        "country": "China",
        "province": "Beijing",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Shaowu",
        "country": "China",
        "province": "Fujian",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Longyan",
        "country": "China",
        "province": "Fujian",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Zhangzhou",
        "country": "China",
        "province": "Fujian",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Putian",
        "country": "China",
        "province": "Fujian",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Fuan",
        "country": "China",
        "province": "Fujian",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Changting",
        "country": "China",
        "province": "Fujian",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Nanping",
        "country": "China",
        "province": "Fujian",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Ninde",
        "country": "China",
        "province": "Fujian",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Jieshou",
        "country": "China",
        "province": "Anhui",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Tongling",
        "country": "China",
        "province": "Anhui",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Maanshan",
        "country": "China",
        "province": "Anhui",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Fuyang",
        "country": "China",
        "province": "Anhui",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Yangjiang",
        "country": "China",
        "province": "Guangdong",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Meizhou",
        "country": "China",
        "province": "Guangdong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Heyuan",
        "country": "China",
        "province": "Guangdong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Qingyuan",
        "country": "China",
        "province": "Guangdong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Zhaoqing",
        "country": "China",
        "province": "Guangdong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Lianxian",
        "country": "China",
        "province": "Guangdong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Jiangmen",
        "country": "China",
        "province": "Guangdong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Maoming",
        "country": "China",
        "province": "Guangdong",
        "timezone": "Asia/Urumqi"
    },
    {
        "name": "Gar",
        "country": "China",
        "province": "Xizang",
        "timezone": "Asia/Kashgar"
    },
    {
        "name": "Turpan",
        "country": "China",
        "province": "Xinjiang Uygur",
        "timezone": "Asia/Urumqi"
    },
    {
        "name": "Quiemo",
        "country": "China",
        "province": "Xinjiang Uygur",
        "timezone": "Asia/Urumqi"
    },
    {
        "name": "Koktokay",
        "country": "China",
        "province": "Xinjiang Uygur",
        "timezone": "Asia/Urumqi"
    },
    {
        "name": "Hancheng",
        "country": "China",
        "province": "Shaanxi",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Weinan",
        "country": "China",
        "province": "Shaanxi",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Shuozhou",
        "country": "China",
        "province": "Shanxi",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Xinzhou",
        "country": "China",
        "province": "Shanxi",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Jincheng",
        "country": "China",
        "province": "Shanxi",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Jiexiu",
        "country": "China",
        "province": "Shanxi",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Changzhi",
        "country": "China",
        "province": "Shanxi",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Guangshui",
        "country": "China",
        "province": "Hubei",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Jingmen",
        "country": "China",
        "province": "Hubei",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Zicheng",
        "country": "China",
        "province": "Hubei",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Shishou",
        "country": "China",
        "province": "Hubei",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Xiaogan",
        "country": "China",
        "province": "Hubei",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Puqi",
        "country": "China",
        "province": "Hubei",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Yunxian",
        "country": "China",
        "province": "Hubei",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Jinshi",
        "country": "China",
        "province": "Hunan",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Chenzhou",
        "country": "China",
        "province": "Hunan",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Zhijiang",
        "country": "China",
        "province": "Hunan",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Xiangtan",
        "country": "China",
        "province": "Hunan",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Zigong",
        "country": "China",
        "province": "Sichuan",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Yaan",
        "country": "China",
        "province": "Sichuan",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Langzhong",
        "country": "China",
        "province": "Sichuan",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Rongzhag",
        "country": "China",
        "province": "Sichuan",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Simao",
        "country": "China",
        "province": "Yunnan",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Wenshan",
        "country": "China",
        "province": "Yunnan",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Zhanyi",
        "country": "China",
        "province": "Yunnan",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Huize",
        "country": "China",
        "province": "Yunnan",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Chengde",
        "country": "China",
        "province": "Hebei",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Cangzhou",
        "country": "China",
        "province": "Hebei",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Baoding",
        "country": "China",
        "province": "Hebei",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Huanghua",
        "country": "China",
        "province": "Hebei",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Dingzhou",
        "country": "China",
        "province": "Hebei",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Nangong",
        "country": "China",
        "province": "Hebei",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Linqing",
        "country": "China",
        "province": "Hebei",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Xiangtai",
        "country": "China",
        "province": "Hebei",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Puyang",
        "country": "China",
        "province": "Henan",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Hebi",
        "country": "China",
        "province": "Henan",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Xuchang",
        "country": "China",
        "province": "Henan",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Zhoukou",
        "country": "China",
        "province": "Henan",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Dengzhou",
        "country": "China",
        "province": "Henan",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Tieling",
        "country": "China",
        "province": "Liaoning",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Chaoyang",
        "country": "China",
        "province": "Liaoning",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Huanren",
        "country": "China",
        "province": "Liaoning",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Zhuanghe",
        "country": "China",
        "province": "Liaoning",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Yishui",
        "country": "China",
        "province": "Shandong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Shanxian",
        "country": "China",
        "province": "Shandong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Pingyi",
        "country": "China",
        "province": "Shandong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Pingdu",
        "country": "China",
        "province": "Shandong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Laiwu",
        "country": "China",
        "province": "Shandong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Buizhou",
        "country": "China",
        "province": "Shandong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Liaocheng",
        "country": "China",
        "province": "Shandong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Rizhao",
        "country": "China",
        "province": "Shandong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Dezhou",
        "country": "China",
        "province": "Shandong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Linchuan",
        "country": "China",
        "province": "Jiangxi",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Fengcheng",
        "country": "China",
        "province": "Jiangxi",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Jian",
        "country": "China",
        "province": "Jiangxi",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Shangrao",
        "country": "China",
        "province": "Jiangxi",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Jingdezhen",
        "country": "China",
        "province": "Jiangxi",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Taizhou",
        "country": "China",
        "province": "Jiangsu",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Shuyang",
        "country": "China",
        "province": "Jiangsu",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Lianyungang",
        "country": "China",
        "province": "Jiangsu",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Lishui",
        "country": "China",
        "province": "Zhejiang",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Jiaojing",
        "country": "China",
        "province": "Zhejiang",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Quzhou",
        "country": "China",
        "province": "Zhejiang",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Fuyu",
        "country": "China",
        "province": "Jilin",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Dunhua",
        "country": "China",
        "province": "Jilin",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Nongan",
        "country": "China",
        "province": "Jilin",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Taonan",
        "country": "China",
        "province": "Jilin",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Liuhe",
        "country": "China",
        "province": "Jilin",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Huinan",
        "country": "China",
        "province": "Jilin",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Panshi",
        "country": "China",
        "province": "Jilin",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Jiaohe",
        "country": "China",
        "province": "Jilin",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Linjiang",
        "country": "China",
        "province": "Jilin",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Wangqing",
        "country": "China",
        "province": "Jilin",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Helong",
        "country": "China",
        "province": "Jilin",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Shulan",
        "country": "China",
        "province": "Jilin",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Jiutai",
        "country": "China",
        "province": "Jilin",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Alxa Zuoqi",
        "country": "China",
        "province": "Nei Mongol",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Linxi",
        "country": "China",
        "province": "Nei Mongol",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Kailu",
        "country": "China",
        "province": "Nei Mongol",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Bairin Zuoqi",
        "country": "China",
        "province": "Nei Mongol",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Yitulihe",
        "country": "China",
        "province": "Nei Mongol",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Yakeshi",
        "country": "China",
        "province": "Nei Mongol",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Bugt",
        "country": "China",
        "province": "Nei Mongol",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Wuyuan",
        "country": "China",
        "province": "Nei Mongol",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Bayan Obo",
        "country": "China",
        "province": "Nei Mongol",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Fengzhen",
        "country": "China",
        "province": "Nei Mongol",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Suihua",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Shuangyashan",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Shangzhi",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Fujin",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Yian",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Tailai",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Longjiang",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Gannan",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Hailun",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Mishan",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Tieli",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Shuangcheng",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Zhaodong",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Lanxi",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Keshan",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Nancha",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Xinqing",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Hulin",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Boli",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Ningan",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Jyekundo",
        "country": "China",
        "province": "Gansu",
        "timezone": "Asia/Urumqi"
    },
    {
        "name": "Liuzhou",
        "country": "China",
        "province": "Guangxi",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Xingyi",
        "country": "China",
        "province": "Guizhou",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Anshun",
        "country": "China",
        "province": "Guizhou",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Zunyi",
        "country": "China",
        "province": "Guizhou",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Wanzhou",
        "country": "China",
        "province": "Chongqing",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Huaibei",
        "country": "China",
        "province": "Anhui",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Wuhu",
        "country": "China",
        "province": "Anhui",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Luan",
        "country": "China",
        "province": "Anhui",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Bengbu",
        "country": "China",
        "province": "Anhui",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Anqing",
        "country": "China",
        "province": "Anhui",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Foshan",
        "country": "China",
        "province": "Guangdong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Nagchu",
        "country": "China",
        "province": "Xizang",
        "timezone": "Asia/Urumqi"
    },
    {
        "name": "Nyingchi",
        "country": "China",
        "province": "Xizang",
        "timezone": "Asia/Urumqi"
    },
    {
        "name": "Chamdo",
        "country": "China",
        "province": "Xizang",
        "timezone": "Asia/Urumqi"
    },
    {
        "name": "Korla",
        "country": "China",
        "province": "Xinjiang Uygur",
        "timezone": "Asia/Urumqi"
    },
    {
        "name": "Kuqa",
        "country": "China",
        "province": "Xinjiang Uygur",
        "timezone": "Asia/Urumqi"
    },
    {
        "name": "Tacheng",
        "country": "China",
        "province": "Xinjiang Uygur",
        "timezone": "Asia/Urumqi"
    },
    {
        "name": "Shihezi",
        "country": "China",
        "province": "Xinjiang Uygur",
        "timezone": "Asia/Urumqi"
    },
    {
        "name": "Karamay",
        "country": "China",
        "province": "Xinjiang Uygur",
        "timezone": "Asia/Urumqi"
    },
    {
        "name": "Aksu",
        "country": "China",
        "province": "Xinjiang Uygur",
        "timezone": "Asia/Kashgar"
    },
    {
        "name": "Sanya",
        "country": "China",
        "province": "Hainan",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Haikou",
        "country": "China",
        "province": "Hainan",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Hanzhong",
        "country": "China",
        "province": "Shaanxi",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Baoji",
        "country": "China",
        "province": "Shaanxi",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Tongchuan",
        "country": "China",
        "province": "Shaanxi",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Linfen",
        "country": "China",
        "province": "Shanxi",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Yuci",
        "country": "China",
        "province": "Shanxi",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Datong",
        "country": "China",
        "province": "Shanxi",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Jianmen",
        "country": "China",
        "province": "Hubei",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Yichang",
        "country": "China",
        "province": "Hubei",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Xiantao",
        "country": "China",
        "province": "Hubei",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Macheng",
        "country": "China",
        "province": "Hubei",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Huangshi",
        "country": "China",
        "province": "Hubei",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Zhuzhou",
        "country": "China",
        "province": "Hunan",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Yongzhou",
        "country": "China",
        "province": "Hunan",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Yiyang",
        "country": "China",
        "province": "Hunan",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Changde",
        "country": "China",
        "province": "Hunan",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Shaoyang",
        "country": "China",
        "province": "Hunan",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Leshan",
        "country": "China",
        "province": "Sichuan",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Panzhihua",
        "country": "China",
        "province": "Sichuan",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Fulin",
        "country": "China",
        "province": "Sichuan",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Guangyuan",
        "country": "China",
        "province": "Sichuan",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Luzhou",
        "country": "China",
        "province": "Sichuan",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Yibin",
        "country": "China",
        "province": "Sichuan",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Zhaotang",
        "country": "China",
        "province": "Yunnan",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Lijiang",
        "country": "China",
        "province": "Yunnan",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Yuxi",
        "country": "China",
        "province": "Yunnan",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Dali",
        "country": "China",
        "province": "Yunnan",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Qinhuangdao",
        "country": "China",
        "province": "Hebei",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Langfang",
        "country": "China",
        "province": "Hebei",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Zhangjiakou",
        "country": "China",
        "province": "Hebei",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Tangshan",
        "country": "China",
        "province": "Hebei",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Anyang",
        "country": "China",
        "province": "Henan",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Jiaozuo",
        "country": "China",
        "province": "Henan",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Kaifeng",
        "country": "China",
        "province": "Henan",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Shangqiu",
        "country": "China",
        "province": "Henan",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Pingdingshan",
        "country": "China",
        "province": "Henan",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Xinyang",
        "country": "China",
        "province": "Henan",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Xinxiang",
        "country": "China",
        "province": "Henan",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Luoyang",
        "country": "China",
        "province": "Henan",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Liaoyang",
        "country": "China",
        "province": "Liaoning",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Dandong",
        "country": "China",
        "province": "Liaoning",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Yingkow",
        "country": "China",
        "province": "Liaoning",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Jinzhou",
        "country": "China",
        "province": "Liaoning",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Fuxin",
        "country": "China",
        "province": "Liaoning",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Benxi",
        "country": "China",
        "province": "Liaoning",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Fushun",
        "country": "China",
        "province": "Liaoning",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Jining",
        "country": "China",
        "province": "Shandong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Weifang",
        "country": "China",
        "province": "Shandong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Taian",
        "country": "China",
        "province": "Shandong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Heze",
        "country": "China",
        "province": "Shandong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Laiyang",
        "country": "China",
        "province": "Shandong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Xinyu",
        "country": "China",
        "province": "Jiangxi",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Ganzhou",
        "country": "China",
        "province": "Jiangxi",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Jiujiang",
        "country": "China",
        "province": "Jiangxi",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Changzhou",
        "country": "China",
        "province": "Jiangsu",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Zhenjiang",
        "country": "China",
        "province": "Jiangsu",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Nantong",
        "country": "China",
        "province": "Jiangsu",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Jiaxing",
        "country": "China",
        "province": "Zhejiang",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Huzhou",
        "country": "China",
        "province": "Zhejiang",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Shaoxing",
        "country": "China",
        "province": "Zhejiang",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Jinhua",
        "country": "China",
        "province": "Zhejiang",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Liaoyuan",
        "country": "China",
        "province": "Jilin",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Tumen",
        "country": "China",
        "province": "Jilin",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Siping",
        "country": "China",
        "province": "Jilin",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Baicheng",
        "country": "China",
        "province": "Jilin",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Wuhai",
        "country": "China",
        "province": "Nei Mongol",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Erenhot",
        "country": "China",
        "province": "Nei Mongol",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Jining",
        "country": "China",
        "province": "Nei Mongol",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Arxan",
        "country": "China",
        "province": "Nei Mongol",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Manzhouli",
        "country": "China",
        "province": "Nei Mongol",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Xilinhot",
        "country": "China",
        "province": "Nei Mongol",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Heihe",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Qitaihe",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Yichun",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Hegang",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Nenjiang",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Nehe",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Mudangiang",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Xuanzhou",
        "country": "China",
        "province": "Anhui",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Zhuhai",
        "country": "China",
        "province": "Guangdong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Xianyang",
        "country": "China",
        "province": "Shaanxi",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Xiangfan",
        "country": "China",
        "province": "Hubei",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Suining",
        "country": "China",
        "province": "Sichuan",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Lingyuan",
        "country": "China",
        "province": "Liaoning",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Weihai",
        "country": "China",
        "province": "Shandong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Yichun",
        "country": "China",
        "province": "Jiangxi",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Yancheng",
        "country": "China",
        "province": "Jiangsu",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Fuyang",
        "country": "China",
        "province": "Zhejiang",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Xiamen",
        "country": "China",
        "province": "Fujian",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Nanchong",
        "country": "China",
        "province": "Sichuan",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Neijiang",
        "country": "China",
        "province": "Sichuan",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Nanyang",
        "country": "China",
        "province": "Henan",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Jinxi",
        "country": "China",
        "province": "Liaoning",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Yantai",
        "country": "China",
        "province": "Shandong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Zaozhuang",
        "country": "China",
        "province": "Shandong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Suzhou",
        "country": "China",
        "province": "Jiangsu",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Xuzhou",
        "country": "China",
        "province": "Jiangsu",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Wuxi",
        "country": "China",
        "province": "Jiangsu",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Jilin",
        "country": "China",
        "province": "Jilin",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Zhangye",
        "country": "China",
        "province": "Gansu",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Wuwei",
        "country": "China",
        "province": "Gansu",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Dunhuang",
        "country": "China",
        "province": "Gansu",
        "timezone": "Asia/Urumqi"
    },
    {
        "name": "Tianshui",
        "country": "China",
        "province": "Gansu",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Dulan",
        "country": "China",
        "province": "Gansu",
        "timezone": "Asia/Urumqi"
    },
    {
        "name": "Golmud",
        "country": "China",
        "province": "Gansu",
        "timezone": "Asia/Urumqi"
    },
    {
        "name": "Yulin",
        "country": "China",
        "province": "Guangxi",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Bose",
        "country": "China",
        "province": "Guangxi",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Wuzhou",
        "country": "China",
        "province": "Guangxi",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Lupanshui",
        "country": "China",
        "province": "Guizhou",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Quanzhou",
        "country": "China",
        "province": "Fujian",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Hefei",
        "country": "China",
        "province": "Anhui",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Suzhou",
        "country": "China",
        "province": "Anhui",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Zhanjiang",
        "country": "China",
        "province": "Guangdong",
        "timezone": "Asia/Urumqi"
    },
    {
        "name": "Shaoguan",
        "country": "China",
        "province": "Guangdong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Xigaze",
        "country": "China",
        "province": "Xizang",
        "timezone": "Asia/Urumqi"
    },
    {
        "name": "Shache",
        "country": "China",
        "province": "Xinjiang Uygur",
        "timezone": "Asia/Kashgar"
    },
    {
        "name": "Yining",
        "country": "China",
        "province": "Xinjiang Uygur",
        "timezone": "Asia/Kashgar"
    },
    {
        "name": "Altay",
        "country": "China",
        "province": "Xinjiang Uygur",
        "timezone": "Asia/Urumqi"
    },
    {
        "name": "Shizuishan",
        "country": "China",
        "province": "Ningxia Hui",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Yulin",
        "country": "China",
        "province": "Shaanxi",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Ankang",
        "country": "China",
        "province": "Shaanxi",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Houma",
        "country": "China",
        "province": "Shanxi",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Yueyang",
        "country": "China",
        "province": "Hunan",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Hengyang",
        "country": "China",
        "province": "Hunan",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Mianyang",
        "country": "China",
        "province": "Sichuan",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Xichang",
        "country": "China",
        "province": "Sichuan",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Baoshan",
        "country": "China",
        "province": "Yunnan",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Gejiu",
        "country": "China",
        "province": "Yunnan",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Shijianzhuang",
        "country": "China",
        "province": "Hebei",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Handan",
        "country": "China",
        "province": "Hebei",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Anshan",
        "country": "China",
        "province": "Liaoning",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Dalian",
        "country": "China",
        "province": "Liaoning",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Qingdao",
        "country": "China",
        "province": "Shandong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Linyi",
        "country": "China",
        "province": "Shandong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Huaiyin",
        "country": "China",
        "province": "Jiangsu",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Wenzhou",
        "country": "China",
        "province": "Zhejiang",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Ningbo",
        "country": "China",
        "province": "Zhejiang",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Tongliao",
        "country": "China",
        "province": "Nei Mongol",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Hohhot",
        "country": "China",
        "province": "Nei Mongol",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Chifeng",
        "country": "China",
        "province": "Nei Mongol",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Ulanhot",
        "country": "China",
        "province": "Nei Mongol",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Hailar",
        "country": "China",
        "province": "Nei Mongol",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Jiamusi",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Beian",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Daqing",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Jixi",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Jiayuguan",
        "country": "China",
        "province": "Gansu",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Xining",
        "country": "China",
        "province": "Gansu",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Guilin",
        "country": "China",
        "province": "Guangxi",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Huainan",
        "country": "China",
        "province": "Anhui",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Shantou",
        "country": "China",
        "province": "Guangdong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Lhasa",
        "country": "China",
        "province": "Xizang",
        "timezone": "Asia/Urumqi"
    },
    {
        "name": "Hami",
        "country": "China",
        "province": "Xinjiang Uygur",
        "timezone": "Asia/Urumqi"
    },
    {
        "name": "Hotan",
        "country": "China",
        "province": "Xinjiang Uygur",
        "timezone": "Asia/Kashgar"
    },
    {
        "name": "Kashgar",
        "country": "China",
        "province": "Xinjiang Uygur",
        "timezone": "Asia/Kashgar"
    },
    {
        "name": "Yinchuan",
        "country": "China",
        "province": "Ningxia Hui",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Pingxiang",
        "country": "China",
        "province": "Jiangxi",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Qiqihar",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Shenzhen",
        "country": "China",
        "province": "Guangdong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Zibo",
        "country": "China",
        "province": "Shandong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Lanzhou",
        "country": "China",
        "province": "Gansu",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Nanning",
        "country": "China",
        "province": "Guangxi",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Guiyang",
        "country": "China",
        "province": "Guizhou",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Chongqing",
        "country": "China",
        "province": "Chongqing",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Fuzhou",
        "country": "China",
        "province": "Fujian",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Guangzhou",
        "country": "China",
        "province": "Guangdong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Dongguan",
        "country": "China",
        "province": "Guangdong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Xian",
        "country": "China",
        "province": "Shaanxi",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Taiyuan",
        "country": "China",
        "province": "Shanxi",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Wuhan",
        "country": "China",
        "province": "Hubei",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Changsha",
        "country": "China",
        "province": "Hunan",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Kunming",
        "country": "China",
        "province": "Yunnan",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Zhengzhou",
        "country": "China",
        "province": "Henan",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Shenyeng",
        "country": "China",
        "province": "Liaoning",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Jinan",
        "country": "China",
        "province": "Shandong",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Tianjin",
        "country": "China",
        "province": "Tianjin",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Nanchang",
        "country": "China",
        "province": "Jiangxi",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Nanjing",
        "country": "China",
        "province": "Jiangsu",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Hangzhou",
        "country": "China",
        "province": "Zhejiang",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Changchun",
        "country": "China",
        "province": "Jilin",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Baotou",
        "country": "China",
        "province": "Nei Mongol",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Harbin",
        "country": "China",
        "province": "Heilongjiang",
        "timezone": "Asia/Harbin"
    },
    {
        "name": "Urumqi",
        "country": "China",
        "province": "Xinjiang Uygur",
        "timezone": "Asia/Urumqi"
    },
    {
        "name": "Chengdu",
        "country": "China",
        "province": "Sichuan",
        "timezone": "Asia/Chongqing"
    },
    {
        "name": "Beijing",
        "country": "China",
        "province": "Beijing",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Shanghai",
        "country": "China",
        "province": "Shanghai",
        "timezone": "Asia/Shanghai"
    },
    {
        "name": "Yopal",
        "country": "Colombia",
        "province": "Casanare",
        "timezone": "America/Bogota"
    },
    {
        "name": "San Andres",
        "country": "Colombia",
        "province": "",
        "timezone": "America/Bogota"
    },
    {
        "name": "Sonson",
        "country": "Colombia",
        "province": "Antioquia",
        "timezone": "America/Bogota"
    },
    {
        "name": "Sogamoso",
        "country": "Colombia",
        "province": "Boyacá",
        "timezone": "America/Bogota"
    },
    {
        "name": "Barrancabermeja",
        "country": "Colombia",
        "province": "Santander",
        "timezone": "America/Bogota"
    },
    {
        "name": "Girardot",
        "country": "Colombia",
        "province": "Cundinamarca",
        "timezone": "America/Bogota"
    },
    {
        "name": "Campoalegre",
        "country": "Colombia",
        "province": "Huila",
        "timezone": "America/Bogota"
    },
    {
        "name": "Tuquerres",
        "country": "Colombia",
        "province": "Nariño",
        "timezone": "America/Bogota"
    },
    {
        "name": "Mocoa",
        "country": "Colombia",
        "province": "Putumayo",
        "timezone": "America/Bogota"
    },
    {
        "name": "Cartago",
        "country": "Colombia",
        "province": "Valle del Cauca",
        "timezone": "America/Bogota"
    },
    {
        "name": "Soledad",
        "country": "Colombia",
        "province": "Atlántico",
        "timezone": "America/Bogota"
    },
    {
        "name": "Sabanalarga",
        "country": "Colombia",
        "province": "Atlántico",
        "timezone": "America/Bogota"
    },
    {
        "name": "Arjona",
        "country": "Colombia",
        "province": "Bolívar",
        "timezone": "America/Bogota"
    },
    {
        "name": "Magangue",
        "country": "Colombia",
        "province": "Bolívar",
        "timezone": "America/Bogota"
    },
    {
        "name": "Valledupar",
        "country": "Colombia",
        "province": "Cesar",
        "timezone": "America/Bogota"
    },
    {
        "name": "San Jose del Guaviare",
        "country": "Colombia",
        "province": "Meta",
        "timezone": "America/Bogota"
    },
    {
        "name": "Puerto Lopez",
        "country": "Colombia",
        "province": "Meta",
        "timezone": "America/Bogota"
    },
    {
        "name": "Yarumal",
        "country": "Colombia",
        "province": "Antioquia",
        "timezone": "America/Bogota"
    },
    {
        "name": "Puerto Berrio",
        "country": "Colombia",
        "province": "Antioquia",
        "timezone": "America/Bogota"
    },
    {
        "name": "Turbo",
        "country": "Colombia",
        "province": "Antioquia",
        "timezone": "America/Bogota"
    },
    {
        "name": "Tunja",
        "country": "Colombia",
        "province": "Boyacá",
        "timezone": "America/Bogota"
    },
    {
        "name": "Chiquinquira",
        "country": "Colombia",
        "province": "Boyacá",
        "timezone": "America/Bogota"
    },
    {
        "name": "Duitama",
        "country": "Colombia",
        "province": "Boyacá",
        "timezone": "America/Bogota"
    },
    {
        "name": "Ayapel",
        "country": "Colombia",
        "province": "Córdoba",
        "timezone": "America/Bogota"
    },
    {
        "name": "Lorica",
        "country": "Colombia",
        "province": "Córdoba",
        "timezone": "America/Bogota"
    },
    {
        "name": "Socorro",
        "country": "Colombia",
        "province": "Santander",
        "timezone": "America/Bogota"
    },
    {
        "name": "Riohacha",
        "country": "Colombia",
        "province": "La Guajira",
        "timezone": "America/Bogota"
    },
    {
        "name": "Armenia",
        "country": "Colombia",
        "province": "Quindío",
        "timezone": "America/Bogota"
    },
    {
        "name": "Pereira",
        "country": "Colombia",
        "province": "Risaralda",
        "timezone": "America/Bogota"
    },
    {
        "name": "Honda",
        "country": "Colombia",
        "province": "Tolima",
        "timezone": "America/Bogota"
    },
    {
        "name": "San Vicente del Caguan",
        "country": "Colombia",
        "province": "Caquetá",
        "timezone": "America/Bogota"
    },
    {
        "name": "Florencia",
        "country": "Colombia",
        "province": "Caquetá",
        "timezone": "America/Bogota"
    },
    {
        "name": "Guapi",
        "country": "Colombia",
        "province": "Cauca",
        "timezone": "America/Bogota"
    },
    {
        "name": "Neiva",
        "country": "Colombia",
        "province": "Huila",
        "timezone": "America/Bogota"
    },
    {
        "name": "Garzon",
        "country": "Colombia",
        "province": "Huila",
        "timezone": "America/Bogota"
    },
    {
        "name": "Ipiales",
        "country": "Colombia",
        "province": "Nariño",
        "timezone": "America/Bogota"
    },
    {
        "name": "Buenaventura",
        "country": "Colombia",
        "province": "Valle del Cauca",
        "timezone": "America/Bogota"
    },
    {
        "name": "Tulua",
        "country": "Colombia",
        "province": "Valle del Cauca",
        "timezone": "America/Bogota"
    },
    {
        "name": "El Carmen de Bolivar",
        "country": "Colombia",
        "province": "Bolívar",
        "timezone": "America/Bogota"
    },
    {
        "name": "Jurado",
        "country": "Colombia",
        "province": "Chocó",
        "timezone": "America/Bogota"
    },
    {
        "name": "Nuqui",
        "country": "Colombia",
        "province": "Chocó",
        "timezone": "America/Bogota"
    },
    {
        "name": "Quibdo",
        "country": "Colombia",
        "province": "Chocó",
        "timezone": "America/Bogota"
    },
    {
        "name": "El Banco",
        "country": "Colombia",
        "province": "Magdalena",
        "timezone": "America/Bogota"
    },
    {
        "name": "Cienaga",
        "country": "Colombia",
        "province": "Magdalena",
        "timezone": "America/Bogota"
    },
    {
        "name": "Sincelejo",
        "country": "Colombia",
        "province": "Sucre",
        "timezone": "America/Bogota"
    },
    {
        "name": "Tolu",
        "country": "Colombia",
        "province": "Sucre",
        "timezone": "America/Bogota"
    },
    {
        "name": "Arauca",
        "country": "Colombia",
        "province": "Arauca",
        "timezone": "America/Bogota"
    },
    {
        "name": "Tame",
        "country": "Colombia",
        "province": "Arauca",
        "timezone": "America/Bogota"
    },
    {
        "name": "Pamplona",
        "country": "Colombia",
        "province": "Norte de Santander",
        "timezone": "America/Bogota"
    },
    {
        "name": "Ocana",
        "country": "Colombia",
        "province": "Norte de Santander",
        "timezone": "America/Bogota"
    },
    {
        "name": "Orocue",
        "country": "Colombia",
        "province": "Casanare",
        "timezone": "America/Bogota"
    },
    {
        "name": "Obando",
        "country": "Colombia",
        "province": "Guainía",
        "timezone": "America/Bogota"
    },
    {
        "name": "San Martin",
        "country": "Colombia",
        "province": "Meta",
        "timezone": "America/Bogota"
    },
    {
        "name": "Puerto Carreno",
        "country": "Colombia",
        "province": "Vichada",
        "timezone": "America/Bogota"
    },
    {
        "name": "Bello",
        "country": "Colombia",
        "province": "Antioquia",
        "timezone": "America/Bogota"
    },
    {
        "name": "Monteria",
        "country": "Colombia",
        "province": "Córdoba",
        "timezone": "America/Bogota"
    },
    {
        "name": "Bucaramanga",
        "country": "Colombia",
        "province": "Santander",
        "timezone": "America/Bogota"
    },
    {
        "name": "Ibague",
        "country": "Colombia",
        "province": "Tolima",
        "timezone": "America/Bogota"
    },
    {
        "name": "Popayan",
        "country": "Colombia",
        "province": "Cauca",
        "timezone": "America/Bogota"
    },
    {
        "name": "Santa Marta",
        "country": "Colombia",
        "province": "Magdalena",
        "timezone": "America/Bogota"
    },
    {
        "name": "Cucuta",
        "country": "Colombia",
        "province": "Norte de Santander",
        "timezone": "America/Bogota"
    },
    {
        "name": "Villavicencio",
        "country": "Colombia",
        "province": "Meta",
        "timezone": "America/Bogota"
    },
    {
        "name": "Tumaco",
        "country": "Colombia",
        "province": "Nariño",
        "timezone": "America/Bogota"
    },
    {
        "name": "Manizales",
        "country": "Colombia",
        "province": "Caldas",
        "timezone": "America/Bogota"
    },
    {
        "name": "Pasto",
        "country": "Colombia",
        "province": "Nariño",
        "timezone": "America/Bogota"
    },
    {
        "name": "Barranquilla",
        "country": "Colombia",
        "province": "Atlántico",
        "timezone": "America/Bogota"
    },
    {
        "name": "Cartagena",
        "country": "Colombia",
        "province": "Bolívar",
        "timezone": "America/Bogota"
    },
    {
        "name": "Mitu",
        "country": "Colombia",
        "province": "Vaupés",
        "timezone": "America/Bogota"
    },
    {
        "name": "Leticia",
        "country": "Colombia",
        "province": "Amazonas",
        "timezone": "America/Bogota"
    },
    {
        "name": "Medellin",
        "country": "Colombia",
        "province": "Antioquia",
        "timezone": "America/Bogota"
    },
    {
        "name": "Cali",
        "country": "Colombia",
        "province": "Valle del Cauca",
        "timezone": "America/Bogota"
    },
    {
        "name": "Bogota",
        "country": "Colombia",
        "province": "Bogota",
        "timezone": "America/Bogota"
    },
    {
        "name": "Moroni",
        "country": "Comoros",
        "province": "",
        "timezone": "Indian/Comoro"
    },
    {
        "name": "Madingou",
        "country": "Congo (Brazzaville)",
        "province": "Bouenza",
        "timezone": "Africa/Brazzaville"
    },
    {
        "name": "Kinkala",
        "country": "Congo (Brazzaville)",
        "province": "Pool",
        "timezone": "Africa/Brazzaville"
    },
    {
        "name": "Ewo",
        "country": "Congo (Brazzaville)",
        "province": "Cuvette-Ouest",
        "timezone": "Africa/Brazzaville"
    },
    {
        "name": "Impfondo",
        "country": "Congo (Brazzaville)",
        "province": "Likouala",
        "timezone": "Africa/Brazzaville"
    },
    {
        "name": "Sembe",
        "country": "Congo (Brazzaville)",
        "province": "Sangha",
        "timezone": "Africa/Brazzaville"
    },
    {
        "name": "Moloundou",
        "country": "Congo (Brazzaville)",
        "province": "Sangha",
        "timezone": "Africa/Brazzaville"
    },
    {
        "name": "Owando",
        "country": "Congo (Brazzaville)",
        "province": "Cuvette",
        "timezone": "Africa/Brazzaville"
    },
    {
        "name": "Makoua",
        "country": "Congo (Brazzaville)",
        "province": "Cuvette",
        "timezone": "Africa/Brazzaville"
    },
    {
        "name": "Sibiti",
        "country": "Congo (Brazzaville)",
        "province": "Lékoumou",
        "timezone": "Africa/Brazzaville"
    },
    {
        "name": "Mossendjo",
        "country": "Congo (Brazzaville)",
        "province": "Niari",
        "timezone": "Africa/Brazzaville"
    },
    {
        "name": "Loubomo",
        "country": "Congo (Brazzaville)",
        "province": "Niari",
        "timezone": "Africa/Brazzaville"
    },
    {
        "name": "Gamboma",
        "country": "Congo (Brazzaville)",
        "province": "Plateaux",
        "timezone": "Africa/Brazzaville"
    },
    {
        "name": "Djambala",
        "country": "Congo (Brazzaville)",
        "province": "Plateaux",
        "timezone": "Africa/Brazzaville"
    },
    {
        "name": "Ouesso",
        "country": "Congo (Brazzaville)",
        "province": "Sangha",
        "timezone": "Africa/Brazzaville"
    },
    {
        "name": "Kayes",
        "country": "Congo (Brazzaville)",
        "province": "Bouenza",
        "timezone": "Africa/Brazzaville"
    },
    {
        "name": "Pointe-Noire",
        "country": "Congo (Brazzaville)",
        "province": "Kouilou",
        "timezone": "Africa/Brazzaville"
    },
    {
        "name": "Brazzaville",
        "country": "Congo (Brazzaville)",
        "province": "Pool",
        "timezone": "Africa/Brazzaville"
    },
    {
        "name": "Buluko",
        "country": "Congo (Kinshasa)",
        "province": "Nord-Kivu",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Zongo",
        "country": "Congo (Kinshasa)",
        "province": "Équateur",
        "timezone": "Africa/Kinshasa"
    },
    {
        "name": "Libenge",
        "country": "Congo (Kinshasa)",
        "province": "Équateur",
        "timezone": "Africa/Kinshasa"
    },
    {
        "name": "Bongandanga",
        "country": "Congo (Kinshasa)",
        "province": "Équateur",
        "timezone": "Africa/Kinshasa"
    },
    {
        "name": "Ikela",
        "country": "Congo (Kinshasa)",
        "province": "Équateur",
        "timezone": "Africa/Kinshasa"
    },
    {
        "name": "Binga",
        "country": "Congo (Kinshasa)",
        "province": "Équateur",
        "timezone": "Africa/Kinshasa"
    },
    {
        "name": "Basankusu",
        "country": "Congo (Kinshasa)",
        "province": "Équateur",
        "timezone": "Africa/Kinshasa"
    },
    {
        "name": "Boende",
        "country": "Congo (Kinshasa)",
        "province": "Équateur",
        "timezone": "Africa/Kinshasa"
    },
    {
        "name": "Gbadolite",
        "country": "Congo (Kinshasa)",
        "province": "Équateur",
        "timezone": "Africa/Kinshasa"
    },
    {
        "name": "Businga",
        "country": "Congo (Kinshasa)",
        "province": "Équateur",
        "timezone": "Africa/Kinshasa"
    },
    {
        "name": "Bosobolo",
        "country": "Congo (Kinshasa)",
        "province": "Équateur",
        "timezone": "Africa/Kinshasa"
    },
    {
        "name": "Yangambi",
        "country": "Congo (Kinshasa)",
        "province": "Orientale",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Aketi",
        "country": "Congo (Kinshasa)",
        "province": "Orientale",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Mongbwalu",
        "country": "Congo (Kinshasa)",
        "province": "Orientale",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Bafwasende",
        "country": "Congo (Kinshasa)",
        "province": "Orientale",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Bunia",
        "country": "Congo (Kinshasa)",
        "province": "Orientale",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Wamba",
        "country": "Congo (Kinshasa)",
        "province": "Orientale",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Basoko",
        "country": "Congo (Kinshasa)",
        "province": "Orientale",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Kenge",
        "country": "Congo (Kinshasa)",
        "province": "Bandundu",
        "timezone": "Africa/Kinshasa"
    },
    {
        "name": "Bolobo",
        "country": "Congo (Kinshasa)",
        "province": "Bandundu",
        "timezone": "Africa/Kinshasa"
    },
    {
        "name": "Kahemba",
        "country": "Congo (Kinshasa)",
        "province": "Bandundu",
        "timezone": "Africa/Kinshasa"
    },
    {
        "name": "Bulungu",
        "country": "Congo (Kinshasa)",
        "province": "Bandundu",
        "timezone": "Africa/Kinshasa"
    },
    {
        "name": "Lusanga",
        "country": "Congo (Kinshasa)",
        "province": "Bandundu",
        "timezone": "Africa/Kinshasa"
    },
    {
        "name": "Mangai",
        "country": "Congo (Kinshasa)",
        "province": "Bandundu",
        "timezone": "Africa/Kinshasa"
    },
    {
        "name": "Kasongo-Lunda",
        "country": "Congo (Kinshasa)",
        "province": "Bandundu",
        "timezone": "Africa/Kinshasa"
    },
    {
        "name": "Mushie",
        "country": "Congo (Kinshasa)",
        "province": "Bandundu",
        "timezone": "Africa/Kinshasa"
    },
    {
        "name": "Dibaya",
        "country": "Congo (Kinshasa)",
        "province": "Kasaï-Occidental",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Mweka",
        "country": "Congo (Kinshasa)",
        "province": "Kasaï-Occidental",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Luebo",
        "country": "Congo (Kinshasa)",
        "province": "Kasaï-Occidental",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Demba",
        "country": "Congo (Kinshasa)",
        "province": "Kasaï-Occidental",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Ilebo",
        "country": "Congo (Kinshasa)",
        "province": "Kasaï-Occidental",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Moanda",
        "country": "Congo (Kinshasa)",
        "province": "Bas-Congo",
        "timezone": "Africa/Kinshasa"
    },
    {
        "name": "Kimpese",
        "country": "Congo (Kinshasa)",
        "province": "Bas-Congo",
        "timezone": "Africa/Kinshasa"
    },
    {
        "name": "Kasangulu",
        "country": "Congo (Kinshasa)",
        "province": "Bas-Congo",
        "timezone": "Africa/Kinshasa"
    },
    {
        "name": "Mbanza-Ngungu",
        "country": "Congo (Kinshasa)",
        "province": "Bas-Congo",
        "timezone": "Africa/Kinshasa"
    },
    {
        "name": "Tshela",
        "country": "Congo (Kinshasa)",
        "province": "Bas-Congo",
        "timezone": "Africa/Kinshasa"
    },
    {
        "name": "Mwenga",
        "country": "Congo (Kinshasa)",
        "province": "Sud-Kivu",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Kampene",
        "country": "Congo (Kinshasa)",
        "province": "Maniema",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Kalima",
        "country": "Congo (Kinshasa)",
        "province": "Maniema",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Lubutu",
        "country": "Congo (Kinshasa)",
        "province": "Maniema",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Kabinda",
        "country": "Congo (Kinshasa)",
        "province": "Kasaï-Oriental",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Lubao",
        "country": "Congo (Kinshasa)",
        "province": "Kasaï-Oriental",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Lusambo",
        "country": "Congo (Kinshasa)",
        "province": "Kasaï-Oriental",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Gandajika",
        "country": "Congo (Kinshasa)",
        "province": "Kasaï-Oriental",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Lodja",
        "country": "Congo (Kinshasa)",
        "province": "Kasaï-Oriental",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Dilolo",
        "country": "Congo (Kinshasa)",
        "province": "Katanga",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Nyunzu",
        "country": "Congo (Kinshasa)",
        "province": "Katanga",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Kasaji",
        "country": "Congo (Kinshasa)",
        "province": "Katanga",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Luanza",
        "country": "Congo (Kinshasa)",
        "province": "Katanga",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Moba",
        "country": "Congo (Kinshasa)",
        "province": "Katanga",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Bukama",
        "country": "Congo (Kinshasa)",
        "province": "Katanga",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Kaniama",
        "country": "Congo (Kinshasa)",
        "province": "Katanga",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Kipushi",
        "country": "Congo (Kinshasa)",
        "province": "Katanga",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Kambove",
        "country": "Congo (Kinshasa)",
        "province": "Katanga",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Kongolo",
        "country": "Congo (Kinshasa)",
        "province": "Katanga",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Kabalo",
        "country": "Congo (Kinshasa)",
        "province": "Katanga",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Beni",
        "country": "Congo (Kinshasa)",
        "province": "Nord-Kivu",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Lisala",
        "country": "Congo (Kinshasa)",
        "province": "Équateur",
        "timezone": "Africa/Kinshasa"
    },
    {
        "name": "Gemena",
        "country": "Congo (Kinshasa)",
        "province": "Équateur",
        "timezone": "Africa/Kinshasa"
    },
    {
        "name": "Buta",
        "country": "Congo (Kinshasa)",
        "province": "Orientale",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Watsa",
        "country": "Congo (Kinshasa)",
        "province": "Orientale",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Isiro",
        "country": "Congo (Kinshasa)",
        "province": "Orientale",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Bondo",
        "country": "Congo (Kinshasa)",
        "province": "Orientale",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Inongo",
        "country": "Congo (Kinshasa)",
        "province": "Bandundu",
        "timezone": "Africa/Kinshasa"
    },
    {
        "name": "Tshikapa",
        "country": "Congo (Kinshasa)",
        "province": "Kasaï-Occidental",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Boma",
        "country": "Congo (Kinshasa)",
        "province": "Bas-Congo",
        "timezone": "Africa/Kinshasa"
    },
    {
        "name": "Bukavu",
        "country": "Congo (Kinshasa)",
        "province": "Sud-Kivu",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Uvira",
        "country": "Congo (Kinshasa)",
        "province": "Sud-Kivu",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Kindu",
        "country": "Congo (Kinshasa)",
        "province": "Maniema",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Mwene-Ditu",
        "country": "Congo (Kinshasa)",
        "province": "Kasaï-Oriental",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Likasi",
        "country": "Congo (Kinshasa)",
        "province": "Katanga",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Manono",
        "country": "Congo (Kinshasa)",
        "province": "Katanga",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Kamina",
        "country": "Congo (Kinshasa)",
        "province": "Katanga",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Mbandaka",
        "country": "Congo (Kinshasa)",
        "province": "Équateur",
        "timezone": "Africa/Kinshasa"
    },
    {
        "name": "Kisangani",
        "country": "Congo (Kinshasa)",
        "province": "Orientale",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Bandundu",
        "country": "Congo (Kinshasa)",
        "province": "Bandundu",
        "timezone": "Africa/Kinshasa"
    },
    {
        "name": "Kananga",
        "country": "Congo (Kinshasa)",
        "province": "Kasaï-Occidental",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Kasongo",
        "country": "Congo (Kinshasa)",
        "province": "Maniema",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Mbuji-Mayi",
        "country": "Congo (Kinshasa)",
        "province": "Kasaï-Oriental",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Kalemie",
        "country": "Congo (Kinshasa)",
        "province": "Katanga",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Butembo",
        "country": "Congo (Kinshasa)",
        "province": "Nord-Kivu",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Goma",
        "country": "Congo (Kinshasa)",
        "province": "Nord-Kivu",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Bumba",
        "country": "Congo (Kinshasa)",
        "province": "Équateur",
        "timezone": "Africa/Kinshasa"
    },
    {
        "name": "Kikwit",
        "country": "Congo (Kinshasa)",
        "province": "Bandundu",
        "timezone": "Africa/Kinshasa"
    },
    {
        "name": "Matadi",
        "country": "Congo (Kinshasa)",
        "province": "Bas-Congo",
        "timezone": "Africa/Kinshasa"
    },
    {
        "name": "Kolwezi",
        "country": "Congo (Kinshasa)",
        "province": "Katanga",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Lubumbashi",
        "country": "Congo (Kinshasa)",
        "province": "Katanga",
        "timezone": "Africa/Lubumbashi"
    },
    {
        "name": "Kinshasa",
        "country": "Congo (Kinshasa)",
        "province": "Kinshasa City",
        "timezone": "Africa/Kinshasa"
    },
    {
        "name": "Rarotonga",
        "country": "Cook Islands",
        "province": "",
        "timezone": "Pacific/Rarotonga"
    },
    {
        "name": "Heredia",
        "country": "Costa Rica",
        "province": "Heredia",
        "timezone": "America/Costa_Rica"
    },
    {
        "name": "Cartago",
        "country": "Costa Rica",
        "province": "Cartago",
        "timezone": "America/Costa_Rica"
    },
    {
        "name": "Golfito",
        "country": "Costa Rica",
        "province": "Puntarenas",
        "timezone": "America/Costa_Rica"
    },
    {
        "name": "Alajuela",
        "country": "Costa Rica",
        "province": "Alajuela",
        "timezone": "America/Costa_Rica"
    },
    {
        "name": "Canas",
        "country": "Costa Rica",
        "province": "Guanacaste",
        "timezone": "America/Costa_Rica"
    },
    {
        "name": "Sixaola",
        "country": "Costa Rica",
        "province": "Limón",
        "timezone": "America/Costa_Rica"
    },
    {
        "name": "Puntarenas",
        "country": "Costa Rica",
        "province": "Puntarenas",
        "timezone": "America/Costa_Rica"
    },
    {
        "name": "Ciudad Cortes",
        "country": "Costa Rica",
        "province": "Puntarenas",
        "timezone": "America/Costa_Rica"
    },
    {
        "name": "Quesada",
        "country": "Costa Rica",
        "province": "Alajuela",
        "timezone": "America/Costa_Rica"
    },
    {
        "name": "Liberia",
        "country": "Costa Rica",
        "province": "Guanacaste",
        "timezone": "America/Costa_Rica"
    },
    {
        "name": "La Cruz",
        "country": "Costa Rica",
        "province": "Guanacaste",
        "timezone": "America/Costa_Rica"
    },
    {
        "name": "Puerto Limon",
        "country": "Costa Rica",
        "province": "Limón",
        "timezone": "America/Costa_Rica"
    },
    {
        "name": "San Jose",
        "country": "Costa Rica",
        "province": "San José",
        "timezone": "America/Costa_Rica"
    },
    {
        "name": "Sibenik",
        "country": "Croatia",
        "province": "Šibensko-Kninska",
        "timezone": "Europe/Zagreb"
    },
    {
        "name": "Karlovac",
        "country": "Croatia",
        "province": "Karlovacka",
        "timezone": "Europe/Zagreb"
    },
    {
        "name": "Rijeka",
        "country": "Croatia",
        "province": "Primorsko-Goranska",
        "timezone": "Europe/Zagreb"
    },
    {
        "name": "Slavonski Brod",
        "country": "Croatia",
        "province": "Brodsko-Posavska",
        "timezone": "Europe/Zagreb"
    },
    {
        "name": "Dubrovnik",
        "country": "Croatia",
        "province": "Dubrovacko-Neretvanska",
        "timezone": "Europe/Zagreb"
    },
    {
        "name": "Split",
        "country": "Croatia",
        "province": "Splitsko-Dalmatinska",
        "timezone": "Europe/Zagreb"
    },
    {
        "name": "Zadar",
        "country": "Croatia",
        "province": "Zadarska",
        "timezone": "Europe/Zagreb"
    },
    {
        "name": "Pula",
        "country": "Croatia",
        "province": "Istarska",
        "timezone": "Europe/Zagreb"
    },
    {
        "name": "Osijek",
        "country": "Croatia",
        "province": "Osjecko-Baranjska",
        "timezone": "Europe/Zagreb"
    },
    {
        "name": "Zagreb",
        "country": "Croatia",
        "province": "Grad Zagreb",
        "timezone": "Europe/Zagreb"
    },
    {
        "name": "Ciego de Avila",
        "country": "Cuba",
        "province": "Ciego de Ávila",
        "timezone": "America/Havana"
    },
    {
        "name": "Palma Soriano",
        "country": "Cuba",
        "province": "Santiago de Cuba",
        "timezone": "America/Havana"
    },
    {
        "name": "San Antonio de los Banos",
        "country": "Cuba",
        "province": "La Habana",
        "timezone": "America/Havana"
    },
    {
        "name": "Guines",
        "country": "Cuba",
        "province": "La Habana",
        "timezone": "America/Havana"
    },
    {
        "name": "Caibarien",
        "country": "Cuba",
        "province": "Villa Clara",
        "timezone": "America/Havana"
    },
    {
        "name": "Placetas",
        "country": "Cuba",
        "province": "Villa Clara",
        "timezone": "America/Havana"
    },
    {
        "name": "Cienfuegos",
        "country": "Cuba",
        "province": "Cienfuegos",
        "timezone": "America/Havana"
    },
    {
        "name": "Nueva Gerona",
        "country": "Cuba",
        "province": "Isla de la Juventud",
        "timezone": "America/Havana"
    },
    {
        "name": "Sancti Spiritus",
        "country": "Cuba",
        "province": "Sancti Spíritus",
        "timezone": "America/Havana"
    },
    {
        "name": "Moron",
        "country": "Cuba",
        "province": "Ciego de Ávila",
        "timezone": "America/Havana"
    },
    {
        "name": "Nuevitas",
        "country": "Cuba",
        "province": "Camagüey",
        "timezone": "America/Havana"
    },
    {
        "name": "Manzanillo",
        "country": "Cuba",
        "province": "Granma",
        "timezone": "America/Havana"
    },
    {
        "name": "Bayamo",
        "country": "Cuba",
        "province": "Granma",
        "timezone": "America/Havana"
    },
    {
        "name": "Banes",
        "country": "Cuba",
        "province": "Holguín",
        "timezone": "America/Havana"
    },
    {
        "name": "Las Tunas",
        "country": "Cuba",
        "province": "Las Tunas",
        "timezone": "America/Havana"
    },
    {
        "name": "Artemisa",
        "country": "Cuba",
        "province": "La Habana",
        "timezone": "America/Havana"
    },
    {
        "name": "Matanzas",
        "country": "Cuba",
        "province": "Matanzas",
        "timezone": "America/Havana"
    },
    {
        "name": "Colon",
        "country": "Cuba",
        "province": "Matanzas",
        "timezone": "America/Havana"
    },
    {
        "name": "Sagua la Grande",
        "country": "Cuba",
        "province": "Villa Clara",
        "timezone": "America/Havana"
    },
    {
        "name": "Pinar del Rio",
        "country": "Cuba",
        "province": "Pinar del Río",
        "timezone": "America/Havana"
    },
    {
        "name": "Camaguey",
        "country": "Cuba",
        "province": "Camagüey",
        "timezone": "America/Havana"
    },
    {
        "name": "Guantanamo",
        "country": "Cuba",
        "province": "Guantánamo",
        "timezone": "America/Havana"
    },
    {
        "name": "Holguin",
        "country": "Cuba",
        "province": "Holguín",
        "timezone": "America/Havana"
    },
    {
        "name": "Santa Clara",
        "country": "Cuba",
        "province": "Villa Clara",
        "timezone": "America/Havana"
    },
    {
        "name": "Santiago de Cuba",
        "country": "Cuba",
        "province": "Santiago de Cuba",
        "timezone": "America/Havana"
    },
    {
        "name": "Havana",
        "country": "Cuba",
        "province": "Ciudad de la Habana",
        "timezone": "America/Havana"
    },
    {
        "name": "Willemstad",
        "country": "Curacao",
        "province": "",
        "timezone": "America/Curacao"
    },
    {
        "name": "Larnaka",
        "country": "Cyprus",
        "province": "Larnaca",
        "timezone": "Asia/Nicosia"
    },
    {
        "name": "Paphos",
        "country": "Cyprus",
        "province": "Paphos",
        "timezone": "Asia/Nicosia"
    },
    {
        "name": "Lemosos",
        "country": "Cyprus",
        "province": "Limassol",
        "timezone": "Asia/Nicosia"
    },
    {
        "name": "Nicosia",
        "country": "Cyprus",
        "province": "",
        "timezone": "Asia/Nicosia"
    },
    {
        "name": "Usti Nad Labem",
        "country": "Czech Republic",
        "province": "Liberecký",
        "timezone": "Europe/Prague"
    },
    {
        "name": "Hradec Kralove",
        "country": "Czech Republic",
        "province": "Královéhradecký",
        "timezone": "Europe/Prague"
    },
    {
        "name": "Ceske Budejovice",
        "country": "Czech Republic",
        "province": "Jihoceský",
        "timezone": "Europe/Prague"
    },
    {
        "name": "Liberec",
        "country": "Czech Republic",
        "province": "Liberecký",
        "timezone": "Europe/Prague"
    },
    {
        "name": "Olomouc",
        "country": "Czech Republic",
        "province": "Moravskoslezský",
        "timezone": "Europe/Prague"
    },
    {
        "name": "Pizen",
        "country": "Czech Republic",
        "province": "Karlovarský",
        "timezone": "Europe/Prague"
    },
    {
        "name": "Jihlava",
        "country": "Czech Republic",
        "province": "Kraj Vysocina",
        "timezone": "Europe/Prague"
    },
    {
        "name": "Zlin",
        "country": "Czech Republic",
        "province": "Kraj Vysocina",
        "timezone": "Europe/Prague"
    },
    {
        "name": "Brno",
        "country": "Czech Republic",
        "province": "Kraj Vysocina",
        "timezone": "Europe/Prague"
    },
    {
        "name": "Pardubice",
        "country": "Czech Republic",
        "province": "Královéhradecký",
        "timezone": "Europe/Prague"
    },
    {
        "name": "Ostrava",
        "country": "Czech Republic",
        "province": "Moravskoslezský",
        "timezone": "Europe/Prague"
    },
    {
        "name": "Prague",
        "country": "Czech Republic",
        "province": "Prague",
        "timezone": "Europe/Prague"
    },
    {
        "name": "Vejle",
        "country": "Denmark",
        "province": "Syddanmark",
        "timezone": "Europe/Copenhagen"
    },
    {
        "name": "Hillerod",
        "country": "Denmark",
        "province": "Hovedstaden",
        "timezone": "Europe/Copenhagen"
    },
    {
        "name": "Soro",
        "country": "Denmark",
        "province": "Sjaælland",
        "timezone": "Europe/Copenhagen"
    },
    {
        "name": "Viborg",
        "country": "Denmark",
        "province": "Midtjylland",
        "timezone": "Europe/Copenhagen"
    },
    {
        "name": "Roskilde",
        "country": "Denmark",
        "province": "Sjaælland",
        "timezone": "Europe/Copenhagen"
    },
    {
        "name": "Svendborg",
        "country": "Denmark",
        "province": "Syddanmark",
        "timezone": "Europe/Copenhagen"
    },
    {
        "name": "Odense",
        "country": "Denmark",
        "province": "Syddanmark",
        "timezone": "Europe/Copenhagen"
    },
    {
        "name": "Esbjerg",
        "country": "Denmark",
        "province": "Syddanmark",
        "timezone": "Europe/Copenhagen"
    },
    {
        "name": "Frederikshavn",
        "country": "Denmark",
        "province": "Nordjylland",
        "timezone": "Europe/Copenhagen"
    },
    {
        "name": "Aalborg",
        "country": "Denmark",
        "province": "Nordjylland",
        "timezone": "Europe/Copenhagen"
    },
    {
        "name": "Århus",
        "country": "Denmark",
        "province": "Midtjylland",
        "timezone": "Europe/Copenhagen"
    },
    {
        "name": "København",
        "country": "Denmark",
        "province": "Hovedstaden",
        "timezone": "Europe/Copenhagen"
    },
    {
        "name": "Dikhil",
        "country": "Djibouti",
        "province": "Dikhil",
        "timezone": "Africa/Djibouti"
    },
    {
        "name": "Tadjoura",
        "country": "Djibouti",
        "province": "Tadjourah",
        "timezone": "Africa/Djibouti"
    },
    {
        "name": "Ali Sabih",
        "country": "Djibouti",
        "province": "Ali Sabieh",
        "timezone": "Africa/Djibouti"
    },
    {
        "name": "Obock",
        "country": "Djibouti",
        "province": "Obock",
        "timezone": "Africa/Djibouti"
    },
    {
        "name": "Djibouti",
        "country": "Djibouti",
        "province": "Djibouti",
        "timezone": "Africa/Djibouti"
    },
    {
        "name": "Roseau",
        "country": "Dominica",
        "province": "Saint George",
        "timezone": "America/Dominica"
    },
    {
        "name": "Sabaneta",
        "country": "Dominican Republic",
        "province": "Santiago Rodríguez",
        "timezone": "America/Santo_Domingo"
    },
    {
        "name": "Mao",
        "country": "Dominican Republic",
        "province": "Valverde",
        "timezone": "America/Santo_Domingo"
    },
    {
        "name": "Cotui",
        "country": "Dominican Republic",
        "province": "Sánchez Ramírez",
        "timezone": "America/Santo_Domingo"
    },
    {
        "name": "Puerto Plata",
        "country": "Dominican Republic",
        "province": "Puerto Plata",
        "timezone": "America/Santo_Domingo"
    },
    {
        "name": "Dajabon",
        "country": "Dominican Republic",
        "province": "Dajabón",
        "timezone": "America/Santo_Domingo"
    },
    {
        "name": "Moca",
        "country": "Dominican Republic",
        "province": "Espaillat",
        "timezone": "America/Santo_Domingo"
    },
    {
        "name": "Salcedo",
        "country": "Dominican Republic",
        "province": "Hermanas",
        "timezone": "America/Santo_Domingo"
    },
    {
        "name": "Jimani",
        "country": "Dominican Republic",
        "province": "Independencia",
        "timezone": "America/Santo_Domingo"
    },
    {
        "name": "Comendador",
        "country": "Dominican Republic",
        "province": "Elias Pina",
        "timezone": "America/Santo_Domingo"
    },
    {
        "name": "Pedernales",
        "country": "Dominican Republic",
        "province": "Pedernales",
        "timezone": "America/Santo_Domingo"
    },
    {
        "name": "Azua",
        "country": "Dominican Republic",
        "province": "Azua",
        "timezone": "America/Santo_Domingo"
    },
    {
        "name": "Bonao",
        "country": "Dominican Republic",
        "province": "Monseñor Nouel",
        "timezone": "America/Santo_Domingo"
    },
    {
        "name": "Bani",
        "country": "Dominican Republic",
        "province": "Peravia",
        "timezone": "America/Santo_Domingo"
    },
    {
        "name": "Hato Mayor",
        "country": "Dominican Republic",
        "province": "Hato Mayor",
        "timezone": "America/Santo_Domingo"
    },
    {
        "name": "Monte Plata",
        "country": "Dominican Republic",
        "province": "Monte Plata",
        "timezone": "America/Santo_Domingo"
    },
    {
        "name": "Nagua",
        "country": "Dominican Republic",
        "province": "María Trinidad Sánchez",
        "timezone": "America/Santo_Domingo"
    },
    {
        "name": "Samana",
        "country": "Dominican Republic",
        "province": "Samaná",
        "timezone": "America/Santo_Domingo"
    },
    {
        "name": "San Cristobal",
        "country": "Dominican Republic",
        "province": "San Cristóbal",
        "timezone": "America/Santo_Domingo"
    },
    {
        "name": "El Seibo",
        "country": "Dominican Republic",
        "province": "El Seybo",
        "timezone": "America/Santo_Domingo"
    },
    {
        "name": "Higuey",
        "country": "Dominican Republic",
        "province": "La Altagracia",
        "timezone": "America/Santo_Domingo"
    },
    {
        "name": "Neiba",
        "country": "Dominican Republic",
        "province": "Bahoruco",
        "timezone": "America/Santo_Domingo"
    },
    {
        "name": "La Vega",
        "country": "Dominican Republic",
        "province": "La Vega",
        "timezone": "America/Santo_Domingo"
    },
    {
        "name": "San Francisco de Macoris",
        "country": "Dominican Republic",
        "province": "Duarte",
        "timezone": "America/Santo_Domingo"
    },
    {
        "name": "San Pedro de Macoris",
        "country": "Dominican Republic",
        "province": "San Pedro de Macorís",
        "timezone": "America/Santo_Domingo"
    },
    {
        "name": "Monte Cristi",
        "country": "Dominican Republic",
        "province": "Monte Cristi",
        "timezone": "America/Santo_Domingo"
    },
    {
        "name": "Barahona",
        "country": "Dominican Republic",
        "province": "Barahona",
        "timezone": "America/Santo_Domingo"
    },
    {
        "name": "Bavaro",
        "country": "Dominican Republic",
        "province": "La Altagracia",
        "timezone": "America/Santo_Domingo"
    },
    {
        "name": "La Romana",
        "country": "Dominican Republic",
        "province": "La Romana",
        "timezone": "America/Santo_Domingo"
    },
    {
        "name": "San Juan",
        "country": "Dominican Republic",
        "province": "San Juan",
        "timezone": "America/Santo_Domingo"
    },
    {
        "name": "Santiago",
        "country": "Dominican Republic",
        "province": "Santiago",
        "timezone": "America/Santo_Domingo"
    },
    {
        "name": "Santo Domingo",
        "country": "Dominican Republic",
        "province": "Distrito Nacional",
        "timezone": "America/Santo_Domingo"
    },
    {
        "name": "Dili",
        "country": "East Timor",
        "province": "Dili",
        "timezone": "Asia/Dili"
    },
    {
        "name": "Puyo",
        "country": "Ecuador",
        "province": "Pastaza",
        "timezone": "America/Guayaquil"
    },
    {
        "name": "Tulcan",
        "country": "Ecuador",
        "province": "Carchi",
        "timezone": "America/Guayaquil"
    },
    {
        "name": "Pinas",
        "country": "Ecuador",
        "province": "El Oro",
        "timezone": "America/Guayaquil"
    },
    {
        "name": "Puerto Villamil",
        "country": "Ecuador",
        "province": "Galápagos",
        "timezone": "Pacific/Galapagos"
    },
    {
        "name": "Puerto Baquerizo Moreno",
        "country": "Ecuador",
        "province": "Galápagos",
        "timezone": "Pacific/Galapagos"
    },
    {
        "name": "Guaranda",
        "country": "Ecuador",
        "province": "Bolivar",
        "timezone": "America/Guayaquil"
    },
    {
        "name": "Azogues",
        "country": "Ecuador",
        "province": "Cañar",
        "timezone": "America/Guayaquil"
    },
    {
        "name": "Salinas",
        "country": "Ecuador",
        "province": "Guayas",
        "timezone": "America/Guayaquil"
    },
    {
        "name": "Alausi",
        "country": "Ecuador",
        "province": "Chimborazo",
        "timezone": "America/Guayaquil"
    },
    {
        "name": "Sangolqui",
        "country": "Ecuador",
        "province": "Pichincha",
        "timezone": "America/Guayaquil"
    },
    {
        "name": "Muisne",
        "country": "Ecuador",
        "province": "Esmeraldas",
        "timezone": "America/Guayaquil"
    },
    {
        "name": "San Gabriel",
        "country": "Ecuador",
        "province": "Carchi",
        "timezone": "America/Guayaquil"
    },
    {
        "name": "Macara",
        "country": "Ecuador",
        "province": "Loja",
        "timezone": "America/Guayaquil"
    },
    {
        "name": "Zamora",
        "country": "Ecuador",
        "province": "Zamora Chinchipe",
        "timezone": "America/Guayaquil"
    },
    {
        "name": "Latacunga",
        "country": "Ecuador",
        "province": "Cotopaxi",
        "timezone": "America/Guayaquil"
    },
    {
        "name": "Milagro",
        "country": "Ecuador",
        "province": "Guayas",
        "timezone": "America/Guayaquil"
    },
    {
        "name": "Babahoyo",
        "country": "Ecuador",
        "province": "Los Rios",
        "timezone": "America/Guayaquil"
    },
    {
        "name": "Chone",
        "country": "Ecuador",
        "province": "Manabi",
        "timezone": "America/Guayaquil"
    },
    {
        "name": "Jipijapa",
        "country": "Ecuador",
        "province": "Manabi",
        "timezone": "America/Guayaquil"
    },
    {
        "name": "Yaupi",
        "country": "Ecuador",
        "province": "Morona Santiago",
        "timezone": "America/Guayaquil"
    },
    {
        "name": "Macas",
        "country": "Ecuador",
        "province": "Morona Santiago",
        "timezone": "America/Guayaquil"
    },
    {
        "name": "Cayambe",
        "country": "Ecuador",
        "province": "Pichincha",
        "timezone": "America/Guayaquil"
    },
    {
        "name": "Ambato",
        "country": "Ecuador",
        "province": "Napo",
        "timezone": "America/Guayaquil"
    },
    {
        "name": "Tena",
        "country": "Ecuador",
        "province": "Tungurahua",
        "timezone": "America/Guayaquil"
    },
    {
        "name": "Valdez",
        "country": "Ecuador",
        "province": "Esmeraldas",
        "timezone": "America/Guayaquil"
    },
    {
        "name": "San Lorenzo",
        "country": "Ecuador",
        "province": "Esmeraldas",
        "timezone": "America/Guayaquil"
    },
    {
        "name": "Esmeraldas",
        "country": "Ecuador",
        "province": "Esmeraldas",
        "timezone": "America/Guayaquil"
    },
    {
        "name": "Ibarra",
        "country": "Ecuador",
        "province": "Imbabura",
        "timezone": "America/Guayaquil"
    },
    {
        "name": "Portoviejo",
        "country": "Ecuador",
        "province": "Manabi",
        "timezone": "America/Guayaquil"
    },
    {
        "name": "Machala",
        "country": "Ecuador",
        "province": "El Oro",
        "timezone": "America/Guayaquil"
    },
    {
        "name": "Loja",
        "country": "Ecuador",
        "province": "Loja",
        "timezone": "America/Guayaquil"
    },
    {
        "name": "Manta",
        "country": "Ecuador",
        "province": "Manabi",
        "timezone": "America/Guayaquil"
    },
    {
        "name": "Riobamba",
        "country": "Ecuador",
        "province": "Chimborazo",
        "timezone": "America/Guayaquil"
    },
    {
        "name": "Cuenca",
        "country": "Ecuador",
        "province": "Azuay",
        "timezone": "America/Guayaquil"
    },
    {
        "name": "Santa Cruz",
        "country": "Ecuador",
        "province": "Galápagos",
        "timezone": "Pacific/Galapagos"
    },
    {
        "name": "Quito",
        "country": "Ecuador",
        "province": "Pichincha",
        "timezone": "America/Guayaquil"
    },
    {
        "name": "Guayaquil",
        "country": "Ecuador",
        "province": "Guayas",
        "timezone": "America/Guayaquil"
    },
    {
        "name": "Shibin el Kom",
        "country": "Egypt",
        "province": "Al Minufiyah",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "Benha",
        "country": "Egypt",
        "province": "Al Qalyubiyah",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "Zagazig",
        "country": "Egypt",
        "province": "Ash Sharqiyah",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "Kafr el Sheikh",
        "country": "Egypt",
        "province": "Kafr ash Shaykh",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "Tanta",
        "country": "Egypt",
        "province": "Al Gharbiyah",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "Ismailia",
        "country": "Egypt",
        "province": "Al Isma`iliyah",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "El Mansura",
        "country": "Egypt",
        "province": "Ad Daqahliyah",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "Dumyat",
        "country": "Egypt",
        "province": "Dumyat",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "Matruh",
        "country": "Egypt",
        "province": "Matruh",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "El Alamein",
        "country": "Egypt",
        "province": "Matruh",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "El Daba",
        "country": "Egypt",
        "province": "Matruh",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "Salum",
        "country": "Egypt",
        "province": "Matruh",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "Damanhûr",
        "country": "Egypt",
        "province": "Al Buhayrah",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "Samalut",
        "country": "Egypt",
        "province": "Al Minya",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "Mallawi",
        "country": "Egypt",
        "province": "Al Minya",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "Beni Mazar",
        "country": "Egypt",
        "province": "Al Minya",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "Beni Suef",
        "country": "Egypt",
        "province": "Bani Suwayf",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "Rashid",
        "country": "Egypt",
        "province": "Kafr ash Shaykh",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "Qasr Farafra",
        "country": "Egypt",
        "province": "Al Wadi at Jadid",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "El Qasr",
        "country": "Egypt",
        "province": "Al Wadi at Jadid",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "Isna",
        "country": "Egypt",
        "province": "Qina",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "Qena",
        "country": "Egypt",
        "province": "Qina",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "Girga",
        "country": "Egypt",
        "province": "Suhaj",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "Sohag",
        "country": "Egypt",
        "province": "Suhaj",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "Berenice",
        "country": "Egypt",
        "province": "Al Bahr al Ahmar",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "Bur Safaga",
        "country": "Egypt",
        "province": "Al Bahr al Ahmar",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "El Tur",
        "country": "Egypt",
        "province": "Janub Sina'",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "El Arish",
        "country": "Egypt",
        "province": "Shamal Sina'",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "El Giza",
        "country": "Egypt",
        "province": "Al Jizah",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "Siwa",
        "country": "Egypt",
        "province": "Matruh",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "El Minya",
        "country": "Egypt",
        "province": "Al Minya",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "Kom Ombo",
        "country": "Egypt",
        "province": "Aswan",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "El Kharga",
        "country": "Egypt",
        "province": "Al Wadi at Jadid",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "Hurghada",
        "country": "Egypt",
        "province": "Al Bahr al Ahmar",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "Suez",
        "country": "Egypt",
        "province": "As Suways",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "Bur Said",
        "country": "Egypt",
        "province": "Bur Sa`id",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "El Faiyum",
        "country": "Egypt",
        "province": "Al Fayyum",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "Aswan",
        "country": "Egypt",
        "province": "Aswan",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "Asyut",
        "country": "Egypt",
        "province": "Asyut",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "Luxor",
        "country": "Egypt",
        "province": "Qina",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "Alexandria",
        "country": "Egypt",
        "province": "Al Iskandariyah",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "Cairo",
        "country": "Egypt",
        "province": "Al Qahirah",
        "timezone": "Africa/Cairo"
    },
    {
        "name": "Ahuachapan",
        "country": "El Salvador",
        "province": "Ahuachapán",
        "timezone": "America/El_Salvador"
    },
    {
        "name": "Cojutepeque",
        "country": "El Salvador",
        "province": "Cuscatlán",
        "timezone": "America/El_Salvador"
    },
    {
        "name": "Nueva San Salvador",
        "country": "El Salvador",
        "province": "La Libertad",
        "timezone": "America/El_Salvador"
    },
    {
        "name": "Zacatecoluca",
        "country": "El Salvador",
        "province": "La Paz",
        "timezone": "America/El_Salvador"
    },
    {
        "name": "La Union",
        "country": "El Salvador",
        "province": "La Unión",
        "timezone": "America/El_Salvador"
    },
    {
        "name": "San Francisco Gotera",
        "country": "El Salvador",
        "province": "Morazán",
        "timezone": "America/El_Salvador"
    },
    {
        "name": "San Vicente",
        "country": "El Salvador",
        "province": "San Vicente",
        "timezone": "America/El_Salvador"
    },
    {
        "name": "Usulutan",
        "country": "El Salvador",
        "province": "Usulután",
        "timezone": "America/El_Salvador"
    },
    {
        "name": "Chalatenango",
        "country": "El Salvador",
        "province": "Chalatenango",
        "timezone": "America/El_Salvador"
    },
    {
        "name": "Sensuntepeque",
        "country": "El Salvador",
        "province": "Cabañas",
        "timezone": "America/El_Salvador"
    },
    {
        "name": "Sonsonate",
        "country": "El Salvador",
        "province": "Sonsonate",
        "timezone": "America/El_Salvador"
    },
    {
        "name": "San Miguel",
        "country": "El Salvador",
        "province": "San Miguel",
        "timezone": "America/El_Salvador"
    },
    {
        "name": "Santa Ana",
        "country": "El Salvador",
        "province": "Santa Ana",
        "timezone": "America/El_Salvador"
    },
    {
        "name": "San Salvador",
        "country": "El Salvador",
        "province": "San Salvador",
        "timezone": "America/El_Salvador"
    },
    {
        "name": "Evinayong",
        "country": "Equatorial Guinea",
        "province": "Centro Sur",
        "timezone": "Africa/Malabo"
    },
    {
        "name": "Luba",
        "country": "Equatorial Guinea",
        "province": "Bioko Sur",
        "timezone": "Africa/Malabo"
    },
    {
        "name": "Calatrava",
        "country": "Equatorial Guinea",
        "province": "Litoral",
        "timezone": "Africa/Malabo"
    },
    {
        "name": "Mongomo",
        "country": "Equatorial Guinea",
        "province": "Wele-Nzás",
        "timezone": "Africa/Malabo"
    },
    {
        "name": "Bata",
        "country": "Equatorial Guinea",
        "province": "Litoral",
        "timezone": "Africa/Malabo"
    },
    {
        "name": "Malabo",
        "country": "Equatorial Guinea",
        "province": "Bioko Norte",
        "timezone": "Africa/Malabo"
    },
    {
        "name": "Tessenei",
        "country": "Eritrea",
        "province": "Gash Barka",
        "timezone": "Africa/Asmara"
    },
    {
        "name": "Agordat",
        "country": "Eritrea",
        "province": "Gash Barka",
        "timezone": "Africa/Asmara"
    },
    {
        "name": "Massawa",
        "country": "Eritrea",
        "province": "Debub",
        "timezone": "Africa/Asmara"
    },
    {
        "name": "Keren",
        "country": "Eritrea",
        "province": "Semenawi Keyih Bahri",
        "timezone": "Africa/Asmara"
    },
    {
        "name": "Mendefera",
        "country": "Eritrea",
        "province": "Maekel",
        "timezone": "Africa/Asmara"
    },
    {
        "name": "Assab",
        "country": "Eritrea",
        "province": "Debubawi Keyih Bahri",
        "timezone": "Africa/Asmara"
    },
    {
        "name": "Asmara",
        "country": "Eritrea",
        "province": "Anseba",
        "timezone": "Africa/Asmara"
    },
    {
        "name": "Haapsalu",
        "country": "Estonia",
        "province": "Lääne",
        "timezone": "Europe/Tallinn"
    },
    {
        "name": "Viljandi",
        "country": "Estonia",
        "province": "Viljandi",
        "timezone": "Europe/Tallinn"
    },
    {
        "name": "Kohtla-Jarve",
        "country": "Estonia",
        "province": "Ida-Viru",
        "timezone": "Europe/Tallinn"
    },
    {
        "name": "Narva",
        "country": "Estonia",
        "province": "Ida-Viru",
        "timezone": "Europe/Tallinn"
    },
    {
        "name": "Tartu",
        "country": "Estonia",
        "province": "Tartu",
        "timezone": "Europe/Tallinn"
    },
    {
        "name": "Parnu",
        "country": "Estonia",
        "province": "Pärnu",
        "timezone": "Europe/Tallinn"
    },
    {
        "name": "Tallinn",
        "country": "Estonia",
        "province": "Harju",
        "timezone": "Europe/Tallinn"
    },
    {
        "name": "Awasa",
        "country": "Ethiopia",
        "province": "Southern Nations, Nationalities and Peoples",
        "timezone": "Africa/Addis_Ababa"
    },
    {
        "name": "Gore",
        "country": "Ethiopia",
        "province": "Addis Ababa",
        "timezone": "Africa/Addis_Ababa"
    },
    {
        "name": "Debre Birhan",
        "country": "Ethiopia",
        "province": "Amhara",
        "timezone": "Africa/Addis_Ababa"
    },
    {
        "name": "Bati",
        "country": "Ethiopia",
        "province": "Amhara",
        "timezone": "Africa/Addis_Ababa"
    },
    {
        "name": "Adigrat",
        "country": "Ethiopia",
        "province": "Tigray",
        "timezone": "Africa/Addis_Ababa"
    },
    {
        "name": "Aksum",
        "country": "Ethiopia",
        "province": "Tigray",
        "timezone": "Africa/Addis_Ababa"
    },
    {
        "name": "Yirga Alem",
        "country": "Ethiopia",
        "province": "Southern Nations, Nationalities and Peoples",
        "timezone": "Africa/Addis_Ababa"
    },
    {
        "name": "Hosaina",
        "country": "Ethiopia",
        "province": "Southern Nations, Nationalities and Peoples",
        "timezone": "Africa/Addis_Ababa"
    },
    {
        "name": "Dila",
        "country": "Ethiopia",
        "province": "Southern Nations, Nationalities and Peoples",
        "timezone": "Africa/Addis_Ababa"
    },
    {
        "name": "Giyon",
        "country": "Ethiopia",
        "province": "Addis Ababa",
        "timezone": "Africa/Addis_Ababa"
    },
    {
        "name": "Hagere Hiywet",
        "country": "Ethiopia",
        "province": "Addis Ababa",
        "timezone": "Africa/Addis_Ababa"
    },
    {
        "name": "Nekemte",
        "country": "Ethiopia",
        "province": "Addis Ababa",
        "timezone": "Africa/Addis_Ababa"
    },
    {
        "name": "Asela",
        "country": "Ethiopia",
        "province": "Addis Ababa",
        "timezone": "Africa/Addis_Ababa"
    },
    {
        "name": "Shashemene",
        "country": "Ethiopia",
        "province": "Addis Ababa",
        "timezone": "Africa/Addis_Ababa"
    },
    {
        "name": "Dembi Dolo",
        "country": "Ethiopia",
        "province": "Addis Ababa",
        "timezone": "Africa/Addis_Ababa"
    },
    {
        "name": "Gimbi",
        "country": "Ethiopia",
        "province": "Addis Ababa",
        "timezone": "Africa/Addis_Ababa"
    },
    {
        "name": "Asosa",
        "country": "Ethiopia",
        "province": "Benshangul-Gumaz",
        "timezone": "Africa/Addis_Ababa"
    },
    {
        "name": "Jijiga",
        "country": "Ethiopia",
        "province": "Somali",
        "timezone": "Africa/Addis_Ababa"
    },
    {
        "name": "Debre Markos",
        "country": "Ethiopia",
        "province": "Amhara",
        "timezone": "Africa/Addis_Ababa"
    },
    {
        "name": "Dese",
        "country": "Ethiopia",
        "province": "Amhara",
        "timezone": "Africa/Addis_Ababa"
    },
    {
        "name": "Sodo",
        "country": "Ethiopia",
        "province": "Southern Nations, Nationalities and Peoples",
        "timezone": "Africa/Addis_Ababa"
    },
    {
        "name": "Arba Minch",
        "country": "Ethiopia",
        "province": "Southern Nations, Nationalities and Peoples",
        "timezone": "Africa/Addis_Ababa"
    },
    {
        "name": "Harar",
        "country": "Ethiopia",
        "province": "Harari",
        "timezone": "Africa/Addis_Ababa"
    },
    {
        "name": "Goba",
        "country": "Ethiopia",
        "province": "Addis Ababa",
        "timezone": "Africa/Addis_Ababa"
    },
    {
        "name": "Jima",
        "country": "Ethiopia",
        "province": "Addis Ababa",
        "timezone": "Africa/Addis_Ababa"
    },
    {
        "name": "Nazret",
        "country": "Ethiopia",
        "province": "Addis Ababa",
        "timezone": "Africa/Addis_Ababa"
    },
    {
        "name": "Nagele",
        "country": "Ethiopia",
        "province": "Addis Ababa",
        "timezone": "Africa/Addis_Ababa"
    },
    {
        "name": "Gode",
        "country": "Ethiopia",
        "province": "Somali",
        "timezone": "Africa/Addis_Ababa"
    },
    {
        "name": "Dolo Bay",
        "country": "Ethiopia",
        "province": "Somali",
        "timezone": "Africa/Addis_Ababa"
    },
    {
        "name": "Bahir Dar",
        "country": "Ethiopia",
        "province": "Amhara",
        "timezone": "Africa/Addis_Ababa"
    },
    {
        "name": "Mekele",
        "country": "Ethiopia",
        "province": "Tigray",
        "timezone": "Africa/Addis_Ababa"
    },
    {
        "name": "Dire Dawa",
        "country": "Ethiopia",
        "province": "Dire Dawa",
        "timezone": "Africa/Addis_Ababa"
    },
    {
        "name": "Gonder",
        "country": "Ethiopia",
        "province": "Amhara",
        "timezone": "Africa/Addis_Ababa"
    },
    {
        "name": "Addis Ababa",
        "country": "Ethiopia",
        "province": "Addis Ababa",
        "timezone": "Africa/Addis_Ababa"
    },
    {
        "name": "Fox Bay",
        "country": "Falkland Islands",
        "province": "",
        "timezone": "Atlantic/Stanley"
    },
    {
        "name": "Stanley",
        "country": "Falkland Islands",
        "province": "",
        "timezone": "Atlantic/Stanley"
    },
    {
        "name": "Klaksvik",
        "country": "Faroe Islands",
        "province": "Eysturoyar",
        "timezone": "Atlantic/Faroe"
    },
    {
        "name": "Tórshavn",
        "country": "Faroe Islands",
        "province": "Eysturoyar",
        "timezone": "Atlantic/Faroe"
    },
    {
        "name": "Palikir",
        "country": "Federated States of Micronesia",
        "province": "",
        "timezone": "Pacific/Pohnpei"
    },
    {
        "name": "Nandi",
        "country": "Fiji",
        "province": "Western",
        "timezone": "Pacific/Fiji"
    },
    {
        "name": "Lautoka",
        "country": "Fiji",
        "province": "Western",
        "timezone": "Pacific/Fiji"
    },
    {
        "name": "Labasa",
        "country": "Fiji",
        "province": "Western",
        "timezone": "Pacific/Fiji"
    },
    {
        "name": "Suva",
        "country": "Fiji",
        "province": "Central",
        "timezone": "Pacific/Fiji"
    },
    {
        "name": "Hameenlinna",
        "country": "Finland",
        "province": "Tavastia Proper",
        "timezone": "Europe/Helsinki"
    },
    {
        "name": "Kouvola",
        "country": "Finland",
        "province": "Southern Finland",
        "timezone": "Europe/Helsinki"
    },
    {
        "name": "Mikkeli",
        "country": "Finland",
        "province": "Southern Savonia",
        "timezone": "Europe/Helsinki"
    },
    {
        "name": "Savonlinna",
        "country": "Finland",
        "province": "Southern Savonia",
        "timezone": "Europe/Helsinki"
    },
    {
        "name": "Pori",
        "country": "Finland",
        "province": "Satakunta",
        "timezone": "Europe/Helsinki"
    },
    {
        "name": "Sodankylä",
        "country": "Finland",
        "province": "Lapland",
        "timezone": "Europe/Helsinki"
    },
    {
        "name": "Jyväskylä",
        "country": "Finland",
        "province": "Central Finland",
        "timezone": "Europe/Helsinki"
    },
    {
        "name": "Kuopio",
        "country": "Finland",
        "province": "Eastern Finland",
        "timezone": "Europe/Helsinki"
    },
    {
        "name": "Lappeenranta",
        "country": "Finland",
        "province": "South Karelia",
        "timezone": "Europe/Helsinki"
    },
    {
        "name": "Porvoo",
        "country": "Finland",
        "province": "Eastern Uusimaa",
        "timezone": "Europe/Helsinki"
    },
    {
        "name": "Kemijarvi",
        "country": "Finland",
        "province": "Lapland",
        "timezone": "Europe/Helsinki"
    },
    {
        "name": "Kokkola",
        "country": "Finland",
        "province": "Western Finland",
        "timezone": "Europe/Helsinki"
    },
    {
        "name": "Lahti",
        "country": "Finland",
        "province": "Päijänne Tavastia",
        "timezone": "Europe/Helsinki"
    },
    {
        "name": "Joensuu",
        "country": "Finland",
        "province": "North Karelia",
        "timezone": "Europe/Helsinki"
    },
    {
        "name": "Turku",
        "country": "Finland",
        "province": "Finland Proper",
        "timezone": "Europe/Helsinki"
    },
    {
        "name": "Kemi",
        "country": "Finland",
        "province": "Lapland",
        "timezone": "Europe/Helsinki"
    },
    {
        "name": "Oulu",
        "country": "Finland",
        "province": "Northern Ostrobothnia",
        "timezone": "Europe/Helsinki"
    },
    {
        "name": "Rovaniemi",
        "country": "Finland",
        "province": "Lapland",
        "timezone": "Europe/Helsinki"
    },
    {
        "name": "Vaasa",
        "country": "Finland",
        "province": "Western Finland",
        "timezone": "Europe/Helsinki"
    },
    {
        "name": "Tampere",
        "country": "Finland",
        "province": "Pirkanmaa",
        "timezone": "Europe/Helsinki"
    },
    {
        "name": "Helsinki",
        "country": "Finland",
        "province": "Southern Finland",
        "timezone": "Europe/Helsinki"
    },
    {
        "name": "Annecy",
        "country": "France",
        "province": "Rhône-Alpes",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Roanne",
        "country": "France",
        "province": "Rhône-Alpes",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Roura",
        "country": "France",
        "province": "Guinaa",
        "timezone": "America/Cayenne"
    },
    {
        "name": "Sinnamary",
        "country": "France",
        "province": "Guinaa",
        "timezone": "America/Cayenne"
    },
    {
        "name": "St.-Brieuc",
        "country": "France",
        "province": "Bretagne",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Poitier",
        "country": "France",
        "province": "Poitou-Charentes",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Angers",
        "country": "France",
        "province": "Pays de la Loire",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Biarritz",
        "country": "France",
        "province": "Aquitaine",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Aix-en-Provence",
        "country": "France",
        "province": "Provence-Alpes-Côte-d'Azur",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Perpignan",
        "country": "France",
        "province": "Languedoc-Roussillon",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Tarbes",
        "country": "France",
        "province": "Midi-Pyrénées",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Clermont-Ferrand",
        "country": "France",
        "province": "Auvergne",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Melun",
        "country": "France",
        "province": "Île-de-France",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Arras",
        "country": "France",
        "province": "Nord-Pas-de-Calais",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Besancon",
        "country": "France",
        "province": "Franche-Comté",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Saint-Georges",
        "country": "France",
        "province": "Amapá",
        "timezone": "America/Cayenne"
    },
    {
        "name": "Saint-Etienne",
        "country": "France",
        "province": "Rhône-Alpes",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Grenoble",
        "country": "France",
        "province": "Rhône-Alpes",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Fort-de-France",
        "country": "France",
        "province": "Martinique",
        "timezone": "America/Martinique"
    },
    {
        "name": "Saint-Laurent-du-Maroni",
        "country": "France",
        "province": "Guinaa",
        "timezone": "America/Cayenne"
    },
    {
        "name": "Iracoubo",
        "country": "France",
        "province": "Guinaa",
        "timezone": "America/Cayenne"
    },
    {
        "name": "Cherbourg",
        "country": "France",
        "province": "Basse-Normandie",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Caen",
        "country": "France",
        "province": "Basse-Normandie",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Lorient",
        "country": "France",
        "province": "Bretagne",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Brest",
        "country": "France",
        "province": "Bretagne",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Le Mans",
        "country": "France",
        "province": "Pays de la Loire",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Nantes",
        "country": "France",
        "province": "Pays de la Loire",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Agen",
        "country": "France",
        "province": "Aquitaine",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Ajaccio",
        "country": "France",
        "province": "Corse",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Bastia",
        "country": "France",
        "province": "Corse",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Toulon",
        "country": "France",
        "province": "Provence-Alpes-Côte-d'Azur",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Beziers",
        "country": "France",
        "province": "Languedoc-Roussillon",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Montpellier",
        "country": "France",
        "province": "Languedoc-Roussillon",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Nimes",
        "country": "France",
        "province": "Languedoc-Roussillon",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Vichy",
        "country": "France",
        "province": "Auvergne",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Nevers",
        "country": "France",
        "province": "Bourgogne",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Auxerre",
        "country": "France",
        "province": "Bourgogne",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Dijon",
        "country": "France",
        "province": "Bourgogne",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Bourges",
        "country": "France",
        "province": "Centre",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Tours",
        "country": "France",
        "province": "Centre",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Orleans",
        "country": "France",
        "province": "Centre",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Dieppe",
        "country": "France",
        "province": "Haute-Normandie",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Rouen",
        "country": "France",
        "province": "Haute-Normandie",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Versailles",
        "country": "France",
        "province": "Île-de-France",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Brive",
        "country": "France",
        "province": "Limousin",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Troyes",
        "country": "France",
        "province": "Champagne-Ardenne",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Reims",
        "country": "France",
        "province": "Champagne-Ardenne",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Calais",
        "country": "France",
        "province": "Nord-Pas-de-Calais",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Amiens",
        "country": "France",
        "province": "Picardie",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Mulhouse",
        "country": "France",
        "province": "Alsace",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Nancy",
        "country": "France",
        "province": "Lorraine",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Metz",
        "country": "France",
        "province": "Lorraine",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Pointe-a-Pitre",
        "country": "France",
        "province": "Guadeloupe",
        "timezone": "America/Guadeloupe"
    },
    {
        "name": "Basse-terre",
        "country": "France",
        "province": "Guadeloupe",
        "timezone": "America/Guadeloupe"
    },
    {
        "name": "St.-Benoit",
        "country": "France",
        "province": "La Réunion",
        "timezone": "Indian/Reunion"
    },
    {
        "name": "Dzaoudzi",
        "country": "France",
        "province": "Moyotte",
        "timezone": "Indian/Mayotte"
    },
    {
        "name": "Rennes",
        "country": "France",
        "province": "Bretagne",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Nice",
        "country": "France",
        "province": "Provence-Alpes-Côte-d'Azur",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Toulouse",
        "country": "France",
        "province": "Midi-Pyrénées",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Limoges",
        "country": "France",
        "province": "Limousin",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Lille",
        "country": "France",
        "province": "Nord-Pas-de-Calais",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Strasbourg",
        "country": "France",
        "province": "Alsace",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Kourou",
        "country": "France",
        "province": "Guinaa",
        "timezone": "America/Cayenne"
    },
    {
        "name": "La Rochelle",
        "country": "France",
        "province": "Poitou-Charentes",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Bordeaux",
        "country": "France",
        "province": "Aquitaine",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Marseille",
        "country": "France",
        "province": "Provence-Alpes-Côte-d'Azur",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Le Havre",
        "country": "France",
        "province": "Haute-Normandie",
        "timezone": "Europe/Paris"
    },
    {
        "name": "St.-Denis",
        "country": "France",
        "province": "La Réunion",
        "timezone": "Indian/Reunion"
    },
    {
        "name": "Lyon",
        "country": "France",
        "province": "Rhône-Alpes",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Cayenne",
        "country": "France",
        "province": "Guinaa",
        "timezone": "America/Cayenne"
    },
    {
        "name": "Paris",
        "country": "France",
        "province": "Île-de-France",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Papeete",
        "country": "French Polynesia",
        "province": "",
        "timezone": "Pacific/Tahiti"
    },
    {
        "name": "Ebebiyin",
        "country": "Gabon",
        "province": "Wouleu-Ntem",
        "timezone": "Africa/Malabo"
    },
    {
        "name": "Tchibanga",
        "country": "Gabon",
        "province": "Nyanga",
        "timezone": "Africa/Libreville"
    },
    {
        "name": "Mekambo",
        "country": "Gabon",
        "province": "Ogooué-Ivindo",
        "timezone": "Africa/Libreville"
    },
    {
        "name": "Makokou",
        "country": "Gabon",
        "province": "Ogooué-Ivindo",
        "timezone": "Africa/Libreville"
    },
    {
        "name": "Mitzik",
        "country": "Gabon",
        "province": "Wouleu-Ntem",
        "timezone": "Africa/Libreville"
    },
    {
        "name": "Bitam",
        "country": "Gabon",
        "province": "Wouleu-Ntem",
        "timezone": "Africa/Libreville"
    },
    {
        "name": "Lambarene",
        "country": "Gabon",
        "province": "Moyen-Ogooué",
        "timezone": "Africa/Libreville"
    },
    {
        "name": "Bifoum",
        "country": "Gabon",
        "province": "Moyen-Ogooué",
        "timezone": "Africa/Libreville"
    },
    {
        "name": "Ndende",
        "country": "Gabon",
        "province": "Ngounié",
        "timezone": "Africa/Libreville"
    },
    {
        "name": "Mouila",
        "country": "Gabon",
        "province": "Ngounié",
        "timezone": "Africa/Libreville"
    },
    {
        "name": "Omboue",
        "country": "Gabon",
        "province": "Ogooué-Maritime",
        "timezone": "Africa/Libreville"
    },
    {
        "name": "Moanda",
        "country": "Gabon",
        "province": "Haut-Ogooué",
        "timezone": "Africa/Libreville"
    },
    {
        "name": "Okandja",
        "country": "Gabon",
        "province": "Haut-Ogooué",
        "timezone": "Africa/Libreville"
    },
    {
        "name": "Koulamoutou",
        "country": "Gabon",
        "province": "Ogooué-Lolo",
        "timezone": "Africa/Libreville"
    },
    {
        "name": "Oyem",
        "country": "Gabon",
        "province": "Wouleu-Ntem",
        "timezone": "Africa/Libreville"
    },
    {
        "name": "Mayumba",
        "country": "Gabon",
        "province": "Nyanga",
        "timezone": "Africa/Libreville"
    },
    {
        "name": "Gamba",
        "country": "Gabon",
        "province": "Ogooué-Maritime",
        "timezone": "Africa/Libreville"
    },
    {
        "name": "Franceville",
        "country": "Gabon",
        "province": "Haut-Ogooué",
        "timezone": "Africa/Libreville"
    },
    {
        "name": "Libreville",
        "country": "Gabon",
        "province": "Estuaire",
        "timezone": "Africa/Libreville"
    },
    {
        "name": "Port-Gentil",
        "country": "Gabon",
        "province": "Ogooué-Maritime",
        "timezone": "Africa/Libreville"
    },
    {
        "name": "Kutaisi",
        "country": "Georgia",
        "province": "Imereti",
        "timezone": "Asia/Tbilisi"
    },
    {
        "name": "Tskhinvali",
        "country": "Georgia",
        "province": "Shida Kartli",
        "timezone": "Asia/Tbilisi"
    },
    {
        "name": "Poti",
        "country": "Georgia",
        "province": "Samegrelo-Zemo Svaneti",
        "timezone": "Asia/Tbilisi"
    },
    {
        "name": "Rustavi",
        "country": "Georgia",
        "province": "Kvemo Kartli",
        "timezone": "Asia/Tbilisi"
    },
    {
        "name": "Batumi",
        "country": "Georgia",
        "province": "Ajaria",
        "timezone": "Asia/Tbilisi"
    },
    {
        "name": "Sukhumi",
        "country": "Georgia",
        "province": "Abkhazia",
        "timezone": "Asia/Tbilisi"
    },
    {
        "name": "Tbilisi",
        "country": "Georgia",
        "province": "Tbilisi",
        "timezone": "Asia/Tbilisi"
    },
    {
        "name": "Mainz",
        "country": "Germany",
        "province": "Rheinland-Pfalz",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Schwerin",
        "country": "Germany",
        "province": "Mecklenburg-Vorpommern",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Bielefeld",
        "country": "Germany",
        "province": "Nordrhein-Westfalen",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Dortmund",
        "country": "Germany",
        "province": "Nordrhein-Westfalen",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Duisburg",
        "country": "Germany",
        "province": "Nordrhein-Westfalen",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Wuppertal",
        "country": "Germany",
        "province": "Nordrhein-Westfalen",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Essen",
        "country": "Germany",
        "province": "Nordrhein-Westfalen",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Karlsruhe",
        "country": "Germany",
        "province": "Baden-Württemberg",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Heidelberg",
        "country": "Germany",
        "province": "Baden-Württemberg",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Kassel",
        "country": "Germany",
        "province": "Hessen",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Oldenburg",
        "country": "Germany",
        "province": "Niedersachsen",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Emden",
        "country": "Germany",
        "province": "Niedersachsen",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Braunschweig",
        "country": "Germany",
        "province": "Niedersachsen",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Erfurt",
        "country": "Germany",
        "province": "Thüringen",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Coburg",
        "country": "Germany",
        "province": "Bayern",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Augsburg",
        "country": "Germany",
        "province": "Bayern",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Furth",
        "country": "Germany",
        "province": "Bayern",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Chemnitz",
        "country": "Germany",
        "province": "Sachsen",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Bonn",
        "country": "Germany",
        "province": "Nordrhein-Westfalen",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Münster",
        "country": "Germany",
        "province": "Nordrhein-Westfalen",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Düsseldorf",
        "country": "Germany",
        "province": "Nordrhein-Westfalen",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Ulm",
        "country": "Germany",
        "province": "Baden-Württemberg",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Mannheim",
        "country": "Germany",
        "province": "Baden-Württemberg",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Freiburg",
        "country": "Germany",
        "province": "Baden-Württemberg",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Giessen",
        "country": "Germany",
        "province": "Hessen",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Wiesbaden",
        "country": "Germany",
        "province": "Hessen",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Bremerhaven",
        "country": "Germany",
        "province": "Bremen",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Osnabrück",
        "country": "Germany",
        "province": "Niedersachsen",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Hannover",
        "country": "Germany",
        "province": "Niedersachsen",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Gottingen",
        "country": "Germany",
        "province": "Niedersachsen",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Gera",
        "country": "Germany",
        "province": "Thüringen",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Jena",
        "country": "Germany",
        "province": "Thüringen",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Flensburg",
        "country": "Germany",
        "province": "Schleswig-Holstein",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Lubeck",
        "country": "Germany",
        "province": "Schleswig-Holstein",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Kiel",
        "country": "Germany",
        "province": "Schleswig-Holstein",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Koblenz",
        "country": "Germany",
        "province": "Rheinland-Pfalz",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Saarbrucken",
        "country": "Germany",
        "province": "Saarland",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Regensburg",
        "country": "Germany",
        "province": "Bayern",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Rosenheim",
        "country": "Germany",
        "province": "Bayern",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Hof",
        "country": "Germany",
        "province": "Bayern",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Wurzburg",
        "country": "Germany",
        "province": "Bayern",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Ingolstadt",
        "country": "Germany",
        "province": "Bayern",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Cottbus",
        "country": "Germany",
        "province": "Brandenburg",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Potsdam",
        "country": "Germany",
        "province": "Brandenburg",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Magdeburg",
        "country": "Germany",
        "province": "Sachsen-Anhalt",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Leipzig",
        "country": "Germany",
        "province": "Sachsen",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Stralsund",
        "country": "Germany",
        "province": "Mecklenburg-Vorpommern",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Rostock",
        "country": "Germany",
        "province": "Mecklenburg-Vorpommern",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Stuttgart",
        "country": "Germany",
        "province": "Baden-Württemberg",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Bremen",
        "country": "Germany",
        "province": "Bremen",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Nürnberg",
        "country": "Germany",
        "province": "Bayern",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Cologne",
        "country": "Germany",
        "province": "Nordrhein-Westfalen",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Dresden",
        "country": "Germany",
        "province": "Sachsen",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Frankfurt",
        "country": "Germany",
        "province": "Hessen",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Hamburg",
        "country": "Germany",
        "province": "Hamburg",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Munich",
        "country": "Germany",
        "province": "Bayern",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Berlin",
        "country": "Germany",
        "province": "Berlin",
        "timezone": "Europe/Berlin"
    },
    {
        "name": "Sunyani",
        "country": "Ghana",
        "province": "Brong Ahafo",
        "timezone": "Africa/Accra"
    },
    {
        "name": "Tamale",
        "country": "Ghana",
        "province": "Northern",
        "timezone": "Africa/Accra"
    },
    {
        "name": "Yendi",
        "country": "Ghana",
        "province": "Northern",
        "timezone": "Africa/Accra"
    },
    {
        "name": "Bolgatanga",
        "country": "Ghana",
        "province": "Upper East",
        "timezone": "Africa/Accra"
    },
    {
        "name": "Bawku",
        "country": "Ghana",
        "province": "Upper East",
        "timezone": "Africa/Accra"
    },
    {
        "name": "Wa",
        "country": "Ghana",
        "province": "Upper West",
        "timezone": "Africa/Accra"
    },
    {
        "name": "Obuasi",
        "country": "Ghana",
        "province": "Ashanti",
        "timezone": "Africa/Accra"
    },
    {
        "name": "Berekum",
        "country": "Ghana",
        "province": "Brong Ahafo",
        "timezone": "Africa/Accra"
    },
    {
        "name": "Winneba",
        "country": "Ghana",
        "province": "Central",
        "timezone": "Africa/Accra"
    },
    {
        "name": "Cape Coast",
        "country": "Ghana",
        "province": "Central",
        "timezone": "Africa/Accra"
    },
    {
        "name": "Nkawkaw",
        "country": "Ghana",
        "province": "Eastern",
        "timezone": "Africa/Accra"
    },
    {
        "name": "Koforidua",
        "country": "Ghana",
        "province": "Eastern",
        "timezone": "Africa/Accra"
    },
    {
        "name": "Tema",
        "country": "Ghana",
        "province": "Greater Accra",
        "timezone": "Africa/Accra"
    },
    {
        "name": "Ho",
        "country": "Ghana",
        "province": "Volta",
        "timezone": "Africa/Accra"
    },
    {
        "name": "Kumasi",
        "country": "Ghana",
        "province": "Ashanti",
        "timezone": "Africa/Accra"
    },
    {
        "name": "Sekondi",
        "country": "Ghana",
        "province": "Western",
        "timezone": "Africa/Accra"
    },
    {
        "name": "Accra",
        "country": "Ghana",
        "province": "Greater Accra",
        "timezone": "Africa/Accra"
    },
    {
        "name": "Gibraltar",
        "country": "Gibraltar",
        "province": "Gibraltar",
        "timezone": "Europe/Gibraltar"
    },
    {
        "name": "Lamia",
        "country": "Greece",
        "province": "Stereá Elláda",
        "timezone": "Europe/Athens"
    },
    {
        "name": "Polygyros",
        "country": "Greece",
        "province": "Kentriki Makedonia",
        "timezone": "Europe/Athens"
    },
    {
        "name": "Komatini",
        "country": "Greece",
        "province": "Anatoliki Makedonia kai Thraki",
        "timezone": "Europe/Athens"
    },
    {
        "name": "Piraiévs",
        "country": "Greece",
        "province": "Attiki",
        "timezone": "Europe/Athens"
    },
    {
        "name": "Volos",
        "country": "Greece",
        "province": "Thessalia",
        "timezone": "Europe/Athens"
    },
    {
        "name": "Hania",
        "country": "Greece",
        "province": "Kriti",
        "timezone": "Europe/Athens"
    },
    {
        "name": "Kavala",
        "country": "Greece",
        "province": "Anatoliki Makedonia kai Thraki",
        "timezone": "Europe/Athens"
    },
    {
        "name": "Alexandroupoli",
        "country": "Greece",
        "province": "Anatoliki Makedonia kai Thraki",
        "timezone": "Europe/Athens"
    },
    {
        "name": "Kerkira",
        "country": "Greece",
        "province": "Ionioi Nisoi",
        "timezone": "Europe/Athens"
    },
    {
        "name": "Tripoli",
        "country": "Greece",
        "province": "Peloponnisos",
        "timezone": "Europe/Athens"
    },
    {
        "name": "Sparti",
        "country": "Greece",
        "province": "Peloponnisos",
        "timezone": "Europe/Athens"
    },
    {
        "name": "Agrinio",
        "country": "Greece",
        "province": "Dytiki Ellada",
        "timezone": "Europe/Athens"
    },
    {
        "name": "Pirgos",
        "country": "Greece",
        "province": "Dytiki Ellada",
        "timezone": "Europe/Athens"
    },
    {
        "name": "Larissa",
        "country": "Greece",
        "province": "Thessalia",
        "timezone": "Europe/Athens"
    },
    {
        "name": "Ioanina",
        "country": "Greece",
        "province": "Ipeiros",
        "timezone": "Europe/Athens"
    },
    {
        "name": "Mitilini",
        "country": "Greece",
        "province": "Voreio Aigaio",
        "timezone": "Europe/Athens"
    },
    {
        "name": "Hios",
        "country": "Greece",
        "province": "Voreio Aigaio",
        "timezone": "Europe/Athens"
    },
    {
        "name": "Chalkida",
        "country": "Greece",
        "province": "Stereá Elláda",
        "timezone": "Europe/Athens"
    },
    {
        "name": "Sitia",
        "country": "Greece",
        "province": "Kriti",
        "timezone": "Europe/Athens"
    },
    {
        "name": "Katerini",
        "country": "Greece",
        "province": "Kentriki Makedonia",
        "timezone": "Europe/Athens"
    },
    {
        "name": "Seres",
        "country": "Greece",
        "province": "Kentriki Makedonia",
        "timezone": "Europe/Athens"
    },
    {
        "name": "Xanthi",
        "country": "Greece",
        "province": "Anatoliki Makedonia kai Thraki",
        "timezone": "Europe/Athens"
    },
    {
        "name": "Ermoupoli",
        "country": "Greece",
        "province": "Notio Aigaio",
        "timezone": "Europe/Athens"
    },
    {
        "name": "Kos",
        "country": "Greece",
        "province": "Notio Aigaio",
        "timezone": "Europe/Athens"
    },
    {
        "name": "Rodos",
        "country": "Greece",
        "province": "Notio Aigaio",
        "timezone": "Europe/Athens"
    },
    {
        "name": "Patra",
        "country": "Greece",
        "province": "Dytiki Ellada",
        "timezone": "Europe/Athens"
    },
    {
        "name": "Kalamata",
        "country": "Greece",
        "province": "Peloponnisos",
        "timezone": "Europe/Athens"
    },
    {
        "name": "Iraklio",
        "country": "Greece",
        "province": "Kriti",
        "timezone": "Europe/Athens"
    },
    {
        "name": "Thessaloniki",
        "country": "Greece",
        "province": "Kentriki Makedonia",
        "timezone": "Europe/Athens"
    },
    {
        "name": "Athens",
        "country": "Greece",
        "province": "Attiki",
        "timezone": "Europe/Athens"
    },
    {
        "name": "Qasigiannguit",
        "country": "Greenland",
        "province": "Qaasuitsup Kommunia",
        "timezone": "America/Godthab"
    },
    {
        "name": "Kullorsuaq",
        "country": "Greenland",
        "province": "",
        "timezone": "America/Godthab"
    },
    {
        "name": "Tasiusaq",
        "country": "Greenland",
        "province": "",
        "timezone": "America/Godthab"
    },
    {
        "name": "Kulusuk",
        "country": "Greenland",
        "province": "Kommuneqarfik Sermersooq",
        "timezone": "America/Godthab"
    },
    {
        "name": "Paamiut",
        "country": "Greenland",
        "province": "Kommuneqarfik Sermersooq",
        "timezone": "America/Godthab"
    },
    {
        "name": "Ittoqqortoormiit",
        "country": "Greenland",
        "province": "Kommuneqarfik Sermersooq",
        "timezone": "America/Danmarkshavn"
    },
    {
        "name": "Timmiarmiut",
        "country": "Greenland",
        "province": "Kommune Kujalleq",
        "timezone": "America/Godthab"
    },
    {
        "name": "Qaqortoq",
        "country": "Greenland",
        "province": "Kommune Kujalleq",
        "timezone": "America/Godthab"
    },
    {
        "name": "Kangerlussuaq",
        "country": "Greenland",
        "province": "Qeqqata Kommunia",
        "timezone": "America/Godthab"
    },
    {
        "name": "Nord",
        "country": "Greenland",
        "province": "Nationalparken",
        "timezone": "America/Godthab"
    },
    {
        "name": "Qeqertasuaq",
        "country": "Greenland",
        "province": "Qaasuitsup Kommunia",
        "timezone": "America/Godthab"
    },
    {
        "name": "Nuussuaq",
        "country": "Greenland",
        "province": "Qaasuitsup Kommunia",
        "timezone": "America/Godthab"
    },
    {
        "name": "Ilulissat",
        "country": "Greenland",
        "province": "Qaasuitsup Kommunia",
        "timezone": "America/Godthab"
    },
    {
        "name": "Tasiilaq",
        "country": "Greenland",
        "province": "",
        "timezone": "America/Godthab"
    },
    {
        "name": "Savissivik",
        "country": "Greenland",
        "province": "",
        "timezone": "America/Thule"
    },
    {
        "name": "Kangersuatsiaq",
        "country": "Greenland",
        "province": "",
        "timezone": "America/Godthab"
    },
    {
        "name": "Uummannaq",
        "country": "Greenland",
        "province": "",
        "timezone": "America/Godthab"
    },
    {
        "name": "Narsarsuaq",
        "country": "Greenland",
        "province": "Kommune Kujalleq",
        "timezone": "America/Godthab"
    },
    {
        "name": "Sisimiut",
        "country": "Greenland",
        "province": "Qeqqata Kommunia",
        "timezone": "America/Godthab"
    },
    {
        "name": "Upernavik",
        "country": "Greenland",
        "province": "Qaasuitsup Kommunia",
        "timezone": "America/Godthab"
    },
    {
        "name": "Qaanaaq",
        "country": "Greenland",
        "province": "Qaasuitsup Kommunia",
        "timezone": "America/Thule"
    },
    {
        "name": "Nuuk",
        "country": "Greenland",
        "province": "Kommuneqarfik Sermersooq",
        "timezone": "America/Godthab"
    },
    {
        "name": "Saint George's",
        "country": "Grenada",
        "province": "",
        "timezone": "America/Grenada"
    },
    {
        "name": "Agana",
        "country": "Guam",
        "province": "",
        "timezone": "Pacific/Guam"
    },
    {
        "name": "Salama",
        "country": "Guatemala",
        "province": "Baja Verapaz",
        "timezone": "America/Guatemala"
    },
    {
        "name": "Retalhuleu",
        "country": "Guatemala",
        "province": "Retalhuleu",
        "timezone": "America/Guatemala"
    },
    {
        "name": "San Marcos",
        "country": "Guatemala",
        "province": "San Marcos",
        "timezone": "America/Guatemala"
    },
    {
        "name": "Chimaltenango",
        "country": "Guatemala",
        "province": "Chimaltenango",
        "timezone": "America/Guatemala"
    },
    {
        "name": "Antigua Guatemala",
        "country": "Guatemala",
        "province": "Sacatepéquez",
        "timezone": "America/Guatemala"
    },
    {
        "name": "Solola",
        "country": "Guatemala",
        "province": "Sololá",
        "timezone": "America/Guatemala"
    },
    {
        "name": "Totonicapan",
        "country": "Guatemala",
        "province": "Totonicapán",
        "timezone": "America/Guatemala"
    },
    {
        "name": "El Progreso",
        "country": "Guatemala",
        "province": "El Progreso",
        "timezone": "America/Guatemala"
    },
    {
        "name": "Cuilapa",
        "country": "Guatemala",
        "province": "Santa Rosa",
        "timezone": "America/Guatemala"
    },
    {
        "name": "Chiquimula",
        "country": "Guatemala",
        "province": "Chiquimula",
        "timezone": "America/Guatemala"
    },
    {
        "name": "Jalapa",
        "country": "Guatemala",
        "province": "Jalapa",
        "timezone": "America/Guatemala"
    },
    {
        "name": "Zacapa",
        "country": "Guatemala",
        "province": "Zacapa",
        "timezone": "America/Guatemala"
    },
    {
        "name": "Santa Cruz Del Quiche",
        "country": "Guatemala",
        "province": "Quiché",
        "timezone": "America/Guatemala"
    },
    {
        "name": "San Luis",
        "country": "Guatemala",
        "province": "Petén",
        "timezone": "America/Guatemala"
    },
    {
        "name": "Coban",
        "country": "Guatemala",
        "province": "Alta Verapaz",
        "timezone": "America/Guatemala"
    },
    {
        "name": "Livingston",
        "country": "Guatemala",
        "province": "Izabal",
        "timezone": "America/Guatemala"
    },
    {
        "name": "Jutiapa",
        "country": "Guatemala",
        "province": "Jutiapa",
        "timezone": "America/Guatemala"
    },
    {
        "name": "Huehuetenango",
        "country": "Guatemala",
        "province": "Huehuetenango",
        "timezone": "America/Guatemala"
    },
    {
        "name": "Flores",
        "country": "Guatemala",
        "province": "Petén",
        "timezone": "America/Guatemala"
    },
    {
        "name": "La Libertad",
        "country": "Guatemala",
        "province": "Petén",
        "timezone": "America/Guatemala"
    },
    {
        "name": "San Jose",
        "country": "Guatemala",
        "province": "Escuintla",
        "timezone": "America/Guatemala"
    },
    {
        "name": "Escuintla",
        "country": "Guatemala",
        "province": "Escuintla",
        "timezone": "America/Guatemala"
    },
    {
        "name": "Mazatenango",
        "country": "Guatemala",
        "province": "Suchitepéquez",
        "timezone": "America/Guatemala"
    },
    {
        "name": "Puerto Barrios",
        "country": "Guatemala",
        "province": "Izabal",
        "timezone": "America/Guatemala"
    },
    {
        "name": "Quetzaltenango",
        "country": "Guatemala",
        "province": "Quezaltenango",
        "timezone": "America/Guatemala"
    },
    {
        "name": "Guatemala",
        "country": "Guatemala",
        "province": "Guatemala",
        "timezone": "America/Guatemala"
    },
    {
        "name": "Mali",
        "country": "Guinea",
        "province": "Labe",
        "timezone": "Africa/Conakry"
    },
    {
        "name": "Tongue",
        "country": "Guinea",
        "province": "Labe",
        "timezone": "Africa/Conakry"
    },
    {
        "name": "Kouroussa",
        "country": "Guinea",
        "province": "Kankan",
        "timezone": "Africa/Conakry"
    },
    {
        "name": "Pita",
        "country": "Guinea",
        "province": "Mamou",
        "timezone": "Africa/Conakry"
    },
    {
        "name": "Dalaba",
        "country": "Guinea",
        "province": "Mamou",
        "timezone": "Africa/Conakry"
    },
    {
        "name": "Boffa",
        "country": "Guinea",
        "province": "Boke",
        "timezone": "Africa/Conakry"
    },
    {
        "name": "Koundara",
        "country": "Guinea",
        "province": "Boke",
        "timezone": "Africa/Conakry"
    },
    {
        "name": "Gaoual",
        "country": "Guinea",
        "province": "Boke",
        "timezone": "Africa/Conakry"
    },
    {
        "name": "Telimele",
        "country": "Guinea",
        "province": "Kindia",
        "timezone": "Africa/Conakry"
    },
    {
        "name": "Forecariah",
        "country": "Guinea",
        "province": "Kindia",
        "timezone": "Africa/Conakry"
    },
    {
        "name": "Beyla",
        "country": "Guinea",
        "province": "Nzerekore",
        "timezone": "Africa/Conakry"
    },
    {
        "name": "Gueckedou",
        "country": "Guinea",
        "province": "Nzerekore",
        "timezone": "Africa/Conakry"
    },
    {
        "name": "Dinguiraye",
        "country": "Guinea",
        "province": "Faranah",
        "timezone": "Africa/Conakry"
    },
    {
        "name": "Dabola",
        "country": "Guinea",
        "province": "Faranah",
        "timezone": "Africa/Conakry"
    },
    {
        "name": "Kerouane",
        "country": "Guinea",
        "province": "Kankan",
        "timezone": "Africa/Conakry"
    },
    {
        "name": "Siguiri",
        "country": "Guinea",
        "province": "Kankan",
        "timezone": "Africa/Conakry"
    },
    {
        "name": "Mamou",
        "country": "Guinea",
        "province": "Mamou",
        "timezone": "Africa/Conakry"
    },
    {
        "name": "Kamsar",
        "country": "Guinea",
        "province": "Boke",
        "timezone": "Africa/Conakry"
    },
    {
        "name": "Fria",
        "country": "Guinea",
        "province": "Boke",
        "timezone": "Africa/Conakry"
    },
    {
        "name": "Macenta",
        "country": "Guinea",
        "province": "Nzerekore",
        "timezone": "Africa/Conakry"
    },
    {
        "name": "Yomou",
        "country": "Guinea",
        "province": "Nzerekore",
        "timezone": "Africa/Conakry"
    },
    {
        "name": "Faranah",
        "country": "Guinea",
        "province": "Faranah",
        "timezone": "Africa/Conakry"
    },
    {
        "name": "Kissidougou",
        "country": "Guinea",
        "province": "Faranah",
        "timezone": "Africa/Conakry"
    },
    {
        "name": "Labe",
        "country": "Guinea",
        "province": "Labe",
        "timezone": "Africa/Conakry"
    },
    {
        "name": "Boke",
        "country": "Guinea",
        "province": "Boke",
        "timezone": "Africa/Conakry"
    },
    {
        "name": "Kindia",
        "country": "Guinea",
        "province": "Kindia",
        "timezone": "Africa/Conakry"
    },
    {
        "name": "Kankan",
        "country": "Guinea",
        "province": "Kankan",
        "timezone": "Africa/Conakry"
    },
    {
        "name": "Nzerekore",
        "country": "Guinea",
        "province": "Nzerekore",
        "timezone": "Africa/Conakry"
    },
    {
        "name": "Conakry",
        "country": "Guinea",
        "province": "Conakry",
        "timezone": "Africa/Conakry"
    },
    {
        "name": "Cacheu",
        "country": "Guinea Bissau",
        "province": "Cacheu",
        "timezone": "Africa/Bissau"
    },
    {
        "name": "Farim",
        "country": "Guinea Bissau",
        "province": "Oio",
        "timezone": "Africa/Bissau"
    },
    {
        "name": "Fulacunda",
        "country": "Guinea Bissau",
        "province": "Quinara",
        "timezone": "Africa/Bissau"
    },
    {
        "name": "Gabu",
        "country": "Guinea Bissau",
        "province": "Gabú",
        "timezone": "Africa/Bissau"
    },
    {
        "name": "Catio",
        "country": "Guinea Bissau",
        "province": "Tombali",
        "timezone": "Africa/Bissau"
    },
    {
        "name": "Bolama",
        "country": "Guinea Bissau",
        "province": "Bolama",
        "timezone": "Africa/Bissau"
    },
    {
        "name": "Bafata",
        "country": "Guinea Bissau",
        "province": "Bafatá",
        "timezone": "Africa/Bissau"
    },
    {
        "name": "Bissau",
        "country": "Guinea Bissau",
        "province": "Bissau",
        "timezone": "Africa/Bissau"
    },
    {
        "name": "Corriverton",
        "country": "Guyana",
        "province": "Mahaica-Berbice",
        "timezone": "America/Guyana"
    },
    {
        "name": "Ituni",
        "country": "Guyana",
        "province": "Upper Takutu-Upper Essequibo",
        "timezone": "America/Guyana"
    },
    {
        "name": "Lethem",
        "country": "Guyana",
        "province": "Upper Demerara-Berbice",
        "timezone": "America/Guyana"
    },
    {
        "name": "Kumaka",
        "country": "Guyana",
        "province": "Upper Demerara-Berbice",
        "timezone": "America/Guyana"
    },
    {
        "name": "Bartica",
        "country": "Guyana",
        "province": "Pomeroon-Supenaam",
        "timezone": "America/Guyana"
    },
    {
        "name": "Anna Regina",
        "country": "Guyana",
        "province": "Cuyuni-Mazaruni",
        "timezone": "America/Guyana"
    },
    {
        "name": "Linden",
        "country": "Guyana",
        "province": "Upper Takutu-Upper Essequibo",
        "timezone": "America/Guyana"
    },
    {
        "name": "Mabaruma",
        "country": "Guyana",
        "province": "Barima-Waini",
        "timezone": "America/Guyana"
    },
    {
        "name": "New Amsterdam",
        "country": "Guyana",
        "province": "Essequibo Islands-West Demerara",
        "timezone": "America/Guyana"
    },
    {
        "name": "Georgetown",
        "country": "Guyana",
        "province": "East Berbice-Corentyne",
        "timezone": "America/Guyana"
    },
    {
        "name": "Jeremie",
        "country": "Haiti",
        "province": "Grand'Anse",
        "timezone": "America/Port-au-Prince"
    },
    {
        "name": "Port-De-Paix",
        "country": "Haiti",
        "province": "Nord-Ouest",
        "timezone": "America/Port-au-Prince"
    },
    {
        "name": "Hinche",
        "country": "Haiti",
        "province": "Centre",
        "timezone": "America/Port-au-Prince"
    },
    {
        "name": "Fort-Liberte",
        "country": "Haiti",
        "province": "Nord-Est",
        "timezone": "America/Port-au-Prince"
    },
    {
        "name": "Jacmel",
        "country": "Haiti",
        "province": "Sud-Est",
        "timezone": "America/Port-au-Prince"
    },
    {
        "name": "Les Cayes",
        "country": "Haiti",
        "province": "Sud",
        "timezone": "America/Port-au-Prince"
    },
    {
        "name": "Gonaives",
        "country": "Haiti",
        "province": "L'Artibonite",
        "timezone": "America/Port-au-Prince"
    },
    {
        "name": "Cap-Haitien",
        "country": "Haiti",
        "province": "Nord",
        "timezone": "America/Port-au-Prince"
    },
    {
        "name": "Port-au-Prince",
        "country": "Haiti",
        "province": "Ouest",
        "timezone": "America/Port-au-Prince"
    },
    {
        "name": "Yoro",
        "country": "Honduras",
        "province": "Yoro",
        "timezone": "America/Tegucigalpa"
    },
    {
        "name": "La Esperanza",
        "country": "Honduras",
        "province": "Intibucá",
        "timezone": "America/Tegucigalpa"
    },
    {
        "name": "La Paz",
        "country": "Honduras",
        "province": "La Paz",
        "timezone": "America/Tegucigalpa"
    },
    {
        "name": "Santa Barbara",
        "country": "Honduras",
        "province": "Santa Bárbara",
        "timezone": "America/Tegucigalpa"
    },
    {
        "name": "Gracias",
        "country": "Honduras",
        "province": "Lempira",
        "timezone": "America/Tegucigalpa"
    },
    {
        "name": "Nueva Ocotepeque",
        "country": "Honduras",
        "province": "Ocotepeque",
        "timezone": "America/Tegucigalpa"
    },
    {
        "name": "Yuscaran",
        "country": "Honduras",
        "province": "El Paraíso",
        "timezone": "America/Tegucigalpa"
    },
    {
        "name": "Roatan",
        "country": "Honduras",
        "province": "Islas de la Bahía",
        "timezone": "America/Tegucigalpa"
    },
    {
        "name": "Nacaome",
        "country": "Honduras",
        "province": "Valle",
        "timezone": "America/Tegucigalpa"
    },
    {
        "name": "Santa Rosa de Copan",
        "country": "Honduras",
        "province": "Copán",
        "timezone": "America/Tegucigalpa"
    },
    {
        "name": "Trujillo",
        "country": "Honduras",
        "province": "Colón",
        "timezone": "America/Tegucigalpa"
    },
    {
        "name": "Brus Laguna",
        "country": "Honduras",
        "province": "Gracias a Dios",
        "timezone": "America/Tegucigalpa"
    },
    {
        "name": "Puerto Lempira",
        "country": "Honduras",
        "province": "Gracias a Dios",
        "timezone": "America/Tegucigalpa"
    },
    {
        "name": "Juticalpa",
        "country": "Honduras",
        "province": "Olancho",
        "timezone": "America/Tegucigalpa"
    },
    {
        "name": "Comayagua",
        "country": "Honduras",
        "province": "Comayagua",
        "timezone": "America/Tegucigalpa"
    },
    {
        "name": "Choluteca",
        "country": "Honduras",
        "province": "Choluteca",
        "timezone": "America/Tegucigalpa"
    },
    {
        "name": "La Ceiba",
        "country": "Honduras",
        "province": "Atlántida",
        "timezone": "America/Tegucigalpa"
    },
    {
        "name": "San Pedro Sula",
        "country": "Honduras",
        "province": "Cortés",
        "timezone": "America/Tegucigalpa"
    },
    {
        "name": "Tegucigalpa",
        "country": "Honduras",
        "province": "Francisco Morazán",
        "timezone": "America/Tegucigalpa"
    },
    {
        "name": "Hong Kong",
        "country": "Hong Kong S.A.R.",
        "province": "",
        "timezone": "Asia/Hong_Kong"
    },
    {
        "name": "Veszprem",
        "country": "Hungary",
        "province": "Veszprém",
        "timezone": "Europe/Budapest"
    },
    {
        "name": "Zalaegerszeg",
        "country": "Hungary",
        "province": "Zala",
        "timezone": "Europe/Budapest"
    },
    {
        "name": "Tatabanya",
        "country": "Hungary",
        "province": "Komárom-Esztergom",
        "timezone": "Europe/Budapest"
    },
    {
        "name": "Szekszard",
        "country": "Hungary",
        "province": "Tolna",
        "timezone": "Europe/Budapest"
    },
    {
        "name": "Salgotarjan",
        "country": "Hungary",
        "province": "Nógrád",
        "timezone": "Europe/Budapest"
    },
    {
        "name": "Bekescsaba",
        "country": "Hungary",
        "province": "Békés",
        "timezone": "Europe/Budapest"
    },
    {
        "name": "Eger",
        "country": "Hungary",
        "province": "Heves",
        "timezone": "Europe/Budapest"
    },
    {
        "name": "Szombathely",
        "country": "Hungary",
        "province": "Vas",
        "timezone": "Europe/Budapest"
    },
    {
        "name": "Kecskemet",
        "country": "Hungary",
        "province": "Bács-Kiskun",
        "timezone": "Europe/Budapest"
    },
    {
        "name": "Szekesfehervar",
        "country": "Hungary",
        "province": "Fejér",
        "timezone": "Europe/Budapest"
    },
    {
        "name": "Nyiregyhaza",
        "country": "Hungary",
        "province": "Szabolcs-Szatmár-Bereg",
        "timezone": "Europe/Budapest"
    },
    {
        "name": "Pecs",
        "country": "Hungary",
        "province": "Baranya",
        "timezone": "Europe/Budapest"
    },
    {
        "name": "Gyor",
        "country": "Hungary",
        "province": "Gyor-Moson-Sopron",
        "timezone": "Europe/Budapest"
    },
    {
        "name": "Kaposvar",
        "country": "Hungary",
        "province": "Somogy",
        "timezone": "Europe/Budapest"
    },
    {
        "name": "Vac",
        "country": "Hungary",
        "province": "Pest",
        "timezone": "Europe/Budapest"
    },
    {
        "name": "Miskolc",
        "country": "Hungary",
        "province": "Borsod-Abaúj-Zemplén",
        "timezone": "Europe/Budapest"
    },
    {
        "name": "Szeged",
        "country": "Hungary",
        "province": "Csongrád",
        "timezone": "Europe/Budapest"
    },
    {
        "name": "Debrecen",
        "country": "Hungary",
        "province": "Hajdú-Bihar",
        "timezone": "Europe/Budapest"
    },
    {
        "name": "Szolnok",
        "country": "Hungary",
        "province": "Jász-Nagykun-Szolnok",
        "timezone": "Europe/Budapest"
    },
    {
        "name": "Budapest",
        "country": "Hungary",
        "province": "Budapest",
        "timezone": "Europe/Budapest"
    },
    {
        "name": "Borgarnes",
        "country": "Iceland",
        "province": "Vesturland",
        "timezone": "Atlantic/Reykjavik"
    },
    {
        "name": "Egilsstaðir",
        "country": "Iceland",
        "province": "Austur-Hérað",
        "timezone": "Atlantic/Reykjavik"
    },
    {
        "name": "Sauðárkrókur",
        "country": "Iceland",
        "province": "Akrahreppur",
        "timezone": "Atlantic/Reykjavik"
    },
    {
        "name": "Selfoss",
        "country": "Iceland",
        "province": "Biskupstungnahreppur",
        "timezone": "Atlantic/Reykjavik"
    },
    {
        "name": "Hofn",
        "country": "Iceland",
        "province": "Sveitarfélagið Hornafjörður",
        "timezone": "Atlantic/Reykjavik"
    },
    {
        "name": "Ísafjörður",
        "country": "Iceland",
        "province": "Vestfirðir",
        "timezone": "Atlantic/Reykjavik"
    },
    {
        "name": "Akureyi",
        "country": "Iceland",
        "province": "Akureyri",
        "timezone": "Atlantic/Reykjavik"
    },
    {
        "name": "Keflavík",
        "country": "Iceland",
        "province": "Suðurnes",
        "timezone": "Atlantic/Reykjavik"
    },
    {
        "name": "Reykjavík",
        "country": "Iceland",
        "province": "Suðurnes",
        "timezone": "Atlantic/Reykjavik"
    },
    {
        "name": "Panaji",
        "country": "India",
        "province": "Goa",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Simla",
        "country": "India",
        "province": "Himachal Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Gurgaon",
        "country": "India",
        "province": "Haryana",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Sonipat",
        "country": "India",
        "province": "Haryana",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Rohtak",
        "country": "India",
        "province": "Haryana",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Hisar",
        "country": "India",
        "province": "Haryana",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Bhiwani",
        "country": "India",
        "province": "Haryana",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Ambala",
        "country": "India",
        "province": "Haryana",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Sopore",
        "country": "India",
        "province": "Jammu and Kashmir",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Silvassa",
        "country": "India",
        "province": "Dadra and Nagar Haveli",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Kalyan",
        "country": "India",
        "province": "Maharashtra",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Bhusawal",
        "country": "India",
        "province": "Maharashtra",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Jorhat",
        "country": "India",
        "province": "Assam",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Hoshiarpur",
        "country": "India",
        "province": "Punjab",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Ajmer",
        "country": "India",
        "province": "Rajasthan",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Hathras",
        "country": "India",
        "province": "Uttar Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Sitapur",
        "country": "India",
        "province": "Uttar Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Pilibhit",
        "country": "India",
        "province": "Uttar Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Budaun",
        "country": "India",
        "province": "Uttar Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Firozabad",
        "country": "India",
        "province": "Uttar Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Mathura",
        "country": "India",
        "province": "Uttar Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Bulandshahr",
        "country": "India",
        "province": "Uttar Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Hapur",
        "country": "India",
        "province": "Uttar Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Muzaffarnagar",
        "country": "India",
        "province": "Uttar Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Gangtok",
        "country": "India",
        "province": "Sikkim",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Diu",
        "country": "India",
        "province": "Dadra and Nagar Haveli",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Pathankot",
        "country": "India",
        "province": "Himachal Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Sirsa",
        "country": "India",
        "province": "Haryana",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Panipat",
        "country": "India",
        "province": "Haryana",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Karnal",
        "country": "India",
        "province": "Haryana",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Baramula",
        "country": "India",
        "province": "Jammu and Kashmir",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Proddatur",
        "country": "India",
        "province": "Andhra Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Nandyal",
        "country": "India",
        "province": "Andhra Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Hindupur",
        "country": "India",
        "province": "Andhra Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Tirupati",
        "country": "India",
        "province": "Andhra Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Ongole",
        "country": "India",
        "province": "Andhra Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Vizianagaram",
        "country": "India",
        "province": "Andhra Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Rajahmundry",
        "country": "India",
        "province": "Andhra Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Machilipatnam",
        "country": "India",
        "province": "Andhra Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Khammam",
        "country": "India",
        "province": "Andhra Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Chirala",
        "country": "India",
        "province": "Andhra Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Karimnagar",
        "country": "India",
        "province": "Andhra Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Nizamabad",
        "country": "India",
        "province": "Andhra Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Kollam",
        "country": "India",
        "province": "Kerala",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Alappuzha",
        "country": "India",
        "province": "Kerala",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Puri",
        "country": "India",
        "province": "Orissa",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Sambalpur",
        "country": "India",
        "province": "Orissa",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Raurkela",
        "country": "India",
        "province": "Orissa",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Kavaratti",
        "country": "India",
        "province": "Lakshadweep",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Mandya",
        "country": "India",
        "province": "Karnataka",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Kolar",
        "country": "India",
        "province": "Karnataka",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Shimoga",
        "country": "India",
        "province": "Karnataka",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Raichur",
        "country": "India",
        "province": "Karnataka",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Hospet",
        "country": "India",
        "province": "Karnataka",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Bidar",
        "country": "India",
        "province": "Karnataka",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Sangli",
        "country": "India",
        "province": "Maharashtra",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Parbhani",
        "country": "India",
        "province": "Maharashtra",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Malegaon",
        "country": "India",
        "province": "Maharashtra",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Port Blair",
        "country": "India",
        "province": "Andaman and Nicobar",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Tezpur",
        "country": "India",
        "province": "Assam",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Silchar",
        "country": "India",
        "province": "Assam",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Kohima",
        "country": "India",
        "province": "Nagaland",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Shillong",
        "country": "India",
        "province": "Meghalaya",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Abohar",
        "country": "India",
        "province": "Punjab",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Patiala",
        "country": "India",
        "province": "Punjab",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Bhilwara",
        "country": "India",
        "province": "Rajasthan",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Pali",
        "country": "India",
        "province": "Rajasthan",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Tonk",
        "country": "India",
        "province": "Rajasthan",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Sikar",
        "country": "India",
        "province": "Rajasthan",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Bikaner",
        "country": "India",
        "province": "Rajasthan",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Bharatpur",
        "country": "India",
        "province": "Rajasthan",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Alwar",
        "country": "India",
        "province": "Rajasthan",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Fatehpur",
        "country": "India",
        "province": "Uttar Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Faizabad",
        "country": "India",
        "province": "Uttar Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Bahraich",
        "country": "India",
        "province": "Uttar Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Mirzapur",
        "country": "India",
        "province": "Uttar Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Jhansi",
        "country": "India",
        "province": "Uttar Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Shahjahanpur",
        "country": "India",
        "province": "Uttar Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Rampur",
        "country": "India",
        "province": "Uttar Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Bareilly",
        "country": "India",
        "province": "Uttar Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Etawah",
        "country": "India",
        "province": "Uttar Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Dehra Dun",
        "country": "India",
        "province": "Uttaranchal",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Haora",
        "country": "India",
        "province": "West Bengal",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Alipur Duar",
        "country": "India",
        "province": "West Bengal",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Haldia",
        "country": "India",
        "province": "West Bengal",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Bhatpara",
        "country": "India",
        "province": "West Bengal",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Medinipur",
        "country": "India",
        "province": "West Bengal",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Siliguri",
        "country": "India",
        "province": "West Bengal",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Purnia",
        "country": "India",
        "province": "Bihar",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Muzaffarpur",
        "country": "India",
        "province": "Bihar",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Aurangabad",
        "country": "India",
        "province": "Bihar",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Bilaspur",
        "country": "India",
        "province": "Chhattisgarh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Burhanpur",
        "country": "India",
        "province": "Madhya Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Ujjain",
        "country": "India",
        "province": "Madhya Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Ratlam",
        "country": "India",
        "province": "Madhya Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Sagar",
        "country": "India",
        "province": "Madhya Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Vellore",
        "country": "India",
        "province": "Tamil Nadu",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Tiruvannamalai",
        "country": "India",
        "province": "Tamil Nadu",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Rajapalaiyam",
        "country": "India",
        "province": "Tamil Nadu",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Cuddalore",
        "country": "India",
        "province": "Tamil Nadu",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Karur",
        "country": "India",
        "province": "Tamil Nadu",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Kanchipuram",
        "country": "India",
        "province": "Tamil Nadu",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Tirunelveli",
        "country": "India",
        "province": "Tamil Nadu",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Nagercoil",
        "country": "India",
        "province": "Tamil Nadu",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Thanjavur",
        "country": "India",
        "province": "Tamil Nadu",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Kumbakonam",
        "country": "India",
        "province": "Tamil Nadu",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Valparai",
        "country": "India",
        "province": "Tamil Nadu",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Tiruppur",
        "country": "India",
        "province": "Tamil Nadu",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Daman",
        "country": "India",
        "province": "Dadra and Nagar Haveli",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Navsari",
        "country": "India",
        "province": "Dadra and Nagar Haveli",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Bhuj",
        "country": "India",
        "province": "Dadra and Nagar Haveli",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Bhavnagar",
        "country": "India",
        "province": "Dadra and Nagar Haveli",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Gandhinagar",
        "country": "India",
        "province": "Dadra and Nagar Haveli",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Itanagar",
        "country": "India",
        "province": "Arunachal Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Aizawl",
        "country": "India",
        "province": "Mizoram",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Agartala",
        "country": "India",
        "province": "Tripura",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Kakinada",
        "country": "India",
        "province": "Andhra Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Warangal",
        "country": "India",
        "province": "Andhra Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Brahmapur",
        "country": "India",
        "province": "Orissa",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Bijapur",
        "country": "India",
        "province": "Karnataka",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Bhiwandi",
        "country": "India",
        "province": "Maharashtra",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Latur",
        "country": "India",
        "province": "Maharashtra",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Ahmednagar",
        "country": "India",
        "province": "Maharashtra",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Chandrapur",
        "country": "India",
        "province": "Maharashtra",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Amravati",
        "country": "India",
        "province": "Maharashtra",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Dhule",
        "country": "India",
        "province": "Maharashtra",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Dibrugarh",
        "country": "India",
        "province": "Assam",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Imphal",
        "country": "India",
        "province": "Manipur",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Udaipur",
        "country": "India",
        "province": "Rajasthan",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Gorakhpur",
        "country": "India",
        "province": "Uttar Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Barddhaman",
        "country": "India",
        "province": "West Bengal",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Krishnanagar",
        "country": "India",
        "province": "West Bengal",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Gaya",
        "country": "India",
        "province": "Bihar",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Porbandar",
        "country": "India",
        "province": "Dadra and Nagar Haveli",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Nellore",
        "country": "India",
        "province": "Andhra Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Kurnool",
        "country": "India",
        "province": "Andhra Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Guntur",
        "country": "India",
        "province": "Andhra Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Tumkur",
        "country": "India",
        "province": "Karnataka",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Davangere",
        "country": "India",
        "province": "Karnataka",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Bellary",
        "country": "India",
        "province": "Karnataka",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Belgaum",
        "country": "India",
        "province": "Karnataka",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Tuticorin",
        "country": "India",
        "province": "Tamil Nadu",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Dindigul",
        "country": "India",
        "province": "Tamil Nadu",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Chandigarh",
        "country": "India",
        "province": "Chandigarh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Jammu",
        "country": "India",
        "province": "Jammu and Kashmir",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Sholapur",
        "country": "India",
        "province": "Maharashtra",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Aurangabad",
        "country": "India",
        "province": "Maharashtra",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Nasik",
        "country": "India",
        "province": "Maharashtra",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Dispur",
        "country": "India",
        "province": "Assam",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Jullundur",
        "country": "India",
        "province": "Punjab",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Allahabad",
        "country": "India",
        "province": "Uttar Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Moradabad",
        "country": "India",
        "province": "Uttar Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Ghaziabad",
        "country": "India",
        "province": "Uttar Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Agra",
        "country": "India",
        "province": "Uttar Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Aligarh",
        "country": "India",
        "province": "Uttar Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Meerut",
        "country": "India",
        "province": "Uttar Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Dhanbad",
        "country": "India",
        "province": "Jharkhand",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Gwalior",
        "country": "India",
        "province": "Madhya Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Vadodara",
        "country": "India",
        "province": "Dadra and Nagar Haveli",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Rajkot",
        "country": "India",
        "province": "Dadra and Nagar Haveli",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Faridabad",
        "country": "India",
        "province": "Haryana",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Srinagar",
        "country": "India",
        "province": "Jammu and Kashmir",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Vijayawada",
        "country": "India",
        "province": "Andhra Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Thiruvananthapuram",
        "country": "India",
        "province": "Kerala",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Kochi",
        "country": "India",
        "province": "Kerala",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Cuttack",
        "country": "India",
        "province": "Orissa",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Hubli",
        "country": "India",
        "province": "Karnataka",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Mangalore",
        "country": "India",
        "province": "Karnataka",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Mysore",
        "country": "India",
        "province": "Karnataka",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Gulbarga",
        "country": "India",
        "province": "Karnataka",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Kolhapur",
        "country": "India",
        "province": "Maharashtra",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Nanded",
        "country": "India",
        "province": "Maharashtra",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Akola",
        "country": "India",
        "province": "Maharashtra",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Guwahati",
        "country": "India",
        "province": "Assam",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Ludhiana",
        "country": "India",
        "province": "Punjab",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Kota",
        "country": "India",
        "province": "Rajasthan",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Jodhpur",
        "country": "India",
        "province": "Rajasthan",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Lucknow",
        "country": "India",
        "province": "Uttar Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Saharanpur",
        "country": "India",
        "province": "Uttar Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Ranchi",
        "country": "India",
        "province": "Jharkhand",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Bhagalpur",
        "country": "India",
        "province": "Bihar",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Raipur",
        "country": "India",
        "province": "Chhattisgarh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Jabalpur",
        "country": "India",
        "province": "Madhya Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Indore",
        "country": "India",
        "province": "Madhya Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Pondicherry",
        "country": "India",
        "province": "Puducherry",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Salem",
        "country": "India",
        "province": "Tamil Nadu",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Tiruchirappalli",
        "country": "India",
        "province": "Tamil Nadu",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Kozhikode",
        "country": "India",
        "province": "Kerala",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Bhubaneshwar",
        "country": "India",
        "province": "Orissa",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Jamshedpur",
        "country": "India",
        "province": "Jharkhand",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Vishakhapatnam",
        "country": "India",
        "province": "Andhra Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Amritsar",
        "country": "India",
        "province": "Punjab",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Varanasi",
        "country": "India",
        "province": "Uttar Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Asansol",
        "country": "India",
        "province": "West Bengal",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Bhilai",
        "country": "India",
        "province": "Chhattisgarh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Bhopal",
        "country": "India",
        "province": "Madhya Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Madurai",
        "country": "India",
        "province": "Tamil Nadu",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Coimbatore",
        "country": "India",
        "province": "Tamil Nadu",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Delhi",
        "country": "India",
        "province": "Delhi",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Hyderabad",
        "country": "India",
        "province": "Andhra Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Pune",
        "country": "India",
        "province": "Maharashtra",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Nagpur",
        "country": "India",
        "province": "Maharashtra",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Jaipur",
        "country": "India",
        "province": "Rajasthan",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Kanpur",
        "country": "India",
        "province": "Uttar Pradesh",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Patna",
        "country": "India",
        "province": "Bihar",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Chennai",
        "country": "India",
        "province": "Tamil Nadu",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Ahmedabad",
        "country": "India",
        "province": "Dadra and Nagar Haveli",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Surat",
        "country": "India",
        "province": "Dadra and Nagar Haveli",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "New Delhi",
        "country": "India",
        "province": "Delhi",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Bangalore",
        "country": "India",
        "province": "Karnataka",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Mumbai",
        "country": "India",
        "province": "Maharashtra",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Kolkata",
        "country": "India",
        "province": "West Bengal",
        "timezone": "Asia/Kolkata"
    },
    {
        "name": "Binjai",
        "country": "Indonesia",
        "province": "Sumatera Utara",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Padangsidempuan",
        "country": "Indonesia",
        "province": "Sumatera Utara",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Tarutung",
        "country": "Indonesia",
        "province": "Sumatera Utara",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Tebingtinggi",
        "country": "Indonesia",
        "province": "Sumatera Utara",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Tidore",
        "country": "Indonesia",
        "province": "Maluku Utara",
        "timezone": "Asia/Jayapura"
    },
    {
        "name": "Bukittinggi",
        "country": "Indonesia",
        "province": "Sumatera Barat",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Sawahlunto",
        "country": "Indonesia",
        "province": "Sumatera Barat",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Padangpanjang",
        "country": "Indonesia",
        "province": "Sumatera Barat",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Amahai",
        "country": "Indonesia",
        "province": "Maluku",
        "timezone": "Asia/Jayapura"
    },
    {
        "name": "Mataram",
        "country": "Indonesia",
        "province": "Nusa Tenggara Barat",
        "timezone": "Asia/Makassar"
    },
    {
        "name": "Praya",
        "country": "Indonesia",
        "province": "Nusa Tenggara Barat",
        "timezone": "Asia/Makassar"
    },
    {
        "name": "Baubau",
        "country": "Indonesia",
        "province": "Sulawesi Tenggara",
        "timezone": "Asia/Makassar"
    },
    {
        "name": "Luwuk",
        "country": "Indonesia",
        "province": "Sulawesi Tengah",
        "timezone": "Asia/Makassar"
    },
    {
        "name": "Poso",
        "country": "Indonesia",
        "province": "Sulawesi Tengah",
        "timezone": "Asia/Makassar"
    },
    {
        "name": "Biak",
        "country": "Indonesia",
        "province": "Papua",
        "timezone": "Asia/Jayapura"
    },
    {
        "name": "Timika",
        "country": "Indonesia",
        "province": "Papua",
        "timezone": "Asia/Jayapura"
    },
    {
        "name": "Langsa",
        "country": "Indonesia",
        "province": "Aceh",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Indramayu",
        "country": "Indonesia",
        "province": "Jawa Barat",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Sukabumi",
        "country": "Indonesia",
        "province": "Jawa Barat",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Cilacap",
        "country": "Indonesia",
        "province": "Jawa Tengah",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Pati",
        "country": "Indonesia",
        "province": "Jawa Tengah",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Pakalongan",
        "country": "Indonesia",
        "province": "Jawa Tengah",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Tegal",
        "country": "Indonesia",
        "province": "Jawa Tengah",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Salatiga",
        "country": "Indonesia",
        "province": "Jawa Tengah",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Magelang",
        "country": "Indonesia",
        "province": "Jawa Tengah",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Serang",
        "country": "Indonesia",
        "province": "Banten",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Bekasi",
        "country": "Indonesia",
        "province": "Jakarta Raya",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Singkawang",
        "country": "Indonesia",
        "province": "Kalimantan Barat",
        "timezone": "Asia/Pontianak"
    },
    {
        "name": "Bandar Lampung",
        "country": "Indonesia",
        "province": "Lampung",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Perabumulih",
        "country": "Indonesia",
        "province": "Sumatera Selatan",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Kuta",
        "country": "Indonesia",
        "province": "Bali",
        "timezone": "Asia/Makassar"
    },
    {
        "name": "Singaraja",
        "country": "Indonesia",
        "province": "Bali",
        "timezone": "Asia/Makassar"
    },
    {
        "name": "Sumenep",
        "country": "Indonesia",
        "province": "Jawa Timur",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Banyuwangi",
        "country": "Indonesia",
        "province": "Jawa Timur",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Tuban",
        "country": "Indonesia",
        "province": "Jawa Timur",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Probolinggo",
        "country": "Indonesia",
        "province": "Jawa Timur",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Pasuruan",
        "country": "Indonesia",
        "province": "Jawa Timur",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Mojokerto",
        "country": "Indonesia",
        "province": "Jawa Timur",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Madiun",
        "country": "Indonesia",
        "province": "Jawa Timur",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Kediri",
        "country": "Indonesia",
        "province": "Jawa Timur",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Blitar",
        "country": "Indonesia",
        "province": "Jawa Timur",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Waingapu",
        "country": "Indonesia",
        "province": "Nusa Tenggara Timur",
        "timezone": "Asia/Makassar"
    },
    {
        "name": "Maumere",
        "country": "Indonesia",
        "province": "Nusa Tenggara Timur",
        "timezone": "Asia/Makassar"
    },
    {
        "name": "Ende",
        "country": "Indonesia",
        "province": "Nusa Tenggara Timur",
        "timezone": "Asia/Makassar"
    },
    {
        "name": "Makale",
        "country": "Indonesia",
        "province": "Sulawesi Selatan",
        "timezone": "Asia/Makassar"
    },
    {
        "name": "Palopo",
        "country": "Indonesia",
        "province": "Sulawesi Selatan",
        "timezone": "Asia/Makassar"
    },
    {
        "name": "Watampone",
        "country": "Indonesia",
        "province": "Sulawesi Selatan",
        "timezone": "Asia/Makassar"
    },
    {
        "name": "Pinrang",
        "country": "Indonesia",
        "province": "Sulawesi Selatan",
        "timezone": "Asia/Makassar"
    },
    {
        "name": "Majene",
        "country": "Indonesia",
        "province": "Sulawesi Barat",
        "timezone": "Asia/Makassar"
    },
    {
        "name": "Tanjungpinang",
        "country": "Indonesia",
        "province": "Kepulauan Riau",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Telukbutun",
        "country": "Indonesia",
        "province": "Kepulauan Riau",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Sungaipenuh",
        "country": "Indonesia",
        "province": "Jambi",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Sampit",
        "country": "Indonesia",
        "province": "Kalimantan Tengah",
        "timezone": "Asia/Pontianak"
    },
    {
        "name": "Kualakapuas",
        "country": "Indonesia",
        "province": "Kalimantan Tengah",
        "timezone": "Asia/Pontianak"
    },
    {
        "name": "Palangkaraya",
        "country": "Indonesia",
        "province": "Kalimantan Tengah",
        "timezone": "Asia/Pontianak"
    },
    {
        "name": "Bontang",
        "country": "Indonesia",
        "province": "Kalimantan Timur",
        "timezone": "Asia/Makassar"
    },
    {
        "name": "Denpasar",
        "country": "Indonesia",
        "province": "Bali",
        "timezone": "Asia/Makassar"
    },
    {
        "name": "Sorong",
        "country": "Indonesia",
        "province": "Irian Jaya Barat",
        "timezone": "Asia/Jayapura"
    },
    {
        "name": "Sibolga",
        "country": "Indonesia",
        "province": "Sumatera Utara",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Pematangsiantar",
        "country": "Indonesia",
        "province": "Sumatera Utara",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Pekanbaru",
        "country": "Indonesia",
        "province": "Riau",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Manado",
        "country": "Indonesia",
        "province": "Sulawesi Utara",
        "timezone": "Asia/Makassar"
    },
    {
        "name": "Yogyakarta",
        "country": "Indonesia",
        "province": "Yogyakarta",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Kendari",
        "country": "Indonesia",
        "province": "Sulawesi Tenggara",
        "timezone": "Asia/Makassar"
    },
    {
        "name": "Palu",
        "country": "Indonesia",
        "province": "Sulawesi Tengah",
        "timezone": "Asia/Makassar"
    },
    {
        "name": "Nabire",
        "country": "Indonesia",
        "province": "Papua",
        "timezone": "Asia/Jayapura"
    },
    {
        "name": "Merauke",
        "country": "Indonesia",
        "province": "Papua",
        "timezone": "Asia/Jayapura"
    },
    {
        "name": "Lhokseumawe",
        "country": "Indonesia",
        "province": "Aceh",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Samarinda",
        "country": "Indonesia",
        "province": "Kalimantan Timur",
        "timezone": "Asia/Makassar"
    },
    {
        "name": "Cirebon",
        "country": "Indonesia",
        "province": "Jawa Barat",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Tasikmalaya",
        "country": "Indonesia",
        "province": "Jawa Barat",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Bogor",
        "country": "Indonesia",
        "province": "Jawa Barat",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Bengkulu",
        "country": "Indonesia",
        "province": "Bengkulu",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Pontianak",
        "country": "Indonesia",
        "province": "Kalimantan Barat",
        "timezone": "Asia/Pontianak"
    },
    {
        "name": "Kotabumi",
        "country": "Indonesia",
        "province": "Lampung",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Lahat",
        "country": "Indonesia",
        "province": "Sumatera Selatan",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Pangkalpinang",
        "country": "Indonesia",
        "province": "Bangka-Belitung",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Jember",
        "country": "Indonesia",
        "province": "Jawa Timur",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Martapura",
        "country": "Indonesia",
        "province": "Kalimantan Selatan",
        "timezone": "Asia/Makassar"
    },
    {
        "name": "Ruteng",
        "country": "Indonesia",
        "province": "Nusa Tenggara Timur",
        "timezone": "Asia/Makassar"
    },
    {
        "name": "Jambi",
        "country": "Indonesia",
        "province": "Jambi",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Manokwari",
        "country": "Indonesia",
        "province": "Irian Jaya Barat",
        "timezone": "Asia/Jayapura"
    },
    {
        "name": "Ternate",
        "country": "Indonesia",
        "province": "Maluku Utara",
        "timezone": "Asia/Jayapura"
    },
    {
        "name": "Ambon",
        "country": "Indonesia",
        "province": "Maluku",
        "timezone": "Asia/Jayapura"
    },
    {
        "name": "Raba",
        "country": "Indonesia",
        "province": "Nusa Tenggara Barat",
        "timezone": "Asia/Makassar"
    },
    {
        "name": "Jayapura",
        "country": "Indonesia",
        "province": "Papua",
        "timezone": "Asia/Jayapura"
    },
    {
        "name": "Banda Aceh",
        "country": "Indonesia",
        "province": "Aceh",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Balikpapan",
        "country": "Indonesia",
        "province": "Kalimantan Timur",
        "timezone": "Asia/Makassar"
    },
    {
        "name": "Surakarta",
        "country": "Indonesia",
        "province": "Jawa Tengah",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Bandar Lampung",
        "country": "Indonesia",
        "province": "Lampung",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Tanjungpandan",
        "country": "Indonesia",
        "province": "Bangka-Belitung",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Malang",
        "country": "Indonesia",
        "province": "Jawa Timur",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Kupang",
        "country": "Indonesia",
        "province": "Nusa Tenggara Timur",
        "timezone": "Asia/Makassar"
    },
    {
        "name": "Parepare",
        "country": "Indonesia",
        "province": "Sulawesi Selatan",
        "timezone": "Asia/Makassar"
    },
    {
        "name": "Gorontalo",
        "country": "Indonesia",
        "province": "Gorontalo",
        "timezone": "Asia/Makassar"
    },
    {
        "name": "Padang",
        "country": "Indonesia",
        "province": "Sumatera Barat",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Tarakan",
        "country": "Indonesia",
        "province": "Kalimantan Timur",
        "timezone": "Asia/Makassar"
    },
    {
        "name": "Semarang",
        "country": "Indonesia",
        "province": "Jawa Tengah",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Palembang",
        "country": "Indonesia",
        "province": "Sumatera Selatan",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Bandjarmasin",
        "country": "Indonesia",
        "province": "Kalimantan Selatan",
        "timezone": "Asia/Makassar"
    },
    {
        "name": "Ujungpandang",
        "country": "Indonesia",
        "province": "Sulawesi Selatan",
        "timezone": "Asia/Makassar"
    },
    {
        "name": "Medan",
        "country": "Indonesia",
        "province": "Sumatera Utara",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Bandung",
        "country": "Indonesia",
        "province": "Jawa Barat",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Surabaya",
        "country": "Indonesia",
        "province": "Jawa Timur",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Jakarta",
        "country": "Indonesia",
        "province": "Jakarta Raya",
        "timezone": "Asia/Jakarta"
    },
    {
        "name": "Yasuj",
        "country": "Iran",
        "province": "Kohgiluyeh and Buyer Ahmad",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Shar e Kord",
        "country": "Iran",
        "province": "Chahar Mahall and Bakhtiari",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Marv Dasht",
        "country": "Iran",
        "province": "Fars",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Shahrud",
        "country": "Iran",
        "province": "Semnan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Varamin",
        "country": "Iran",
        "province": "Tehran",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Masjed Soleyman",
        "country": "Iran",
        "province": "Khuzestan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Borujerd",
        "country": "Iran",
        "province": "Lorestan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Malayer",
        "country": "Iran",
        "province": "Hamadan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Zanjan",
        "country": "Iran",
        "province": "Zanjan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Urmia",
        "country": "Iran",
        "province": "West Azarbaijan",
        "timezone": null
    },
    {
        "name": "Ahar",
        "country": "Iran",
        "province": "East Azarbaijan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Sanandaj",
        "country": "Iran",
        "province": "Kordestan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Neyshabur",
        "country": "Iran",
        "province": "Razavi Khorasan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Bojnurd",
        "country": "Iran",
        "province": "North Khorasan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Sirjan",
        "country": "Iran",
        "province": "Kerman",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Qomsheh",
        "country": "Iran",
        "province": "Esfahan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Kashan",
        "country": "Iran",
        "province": "Esfahan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Khomeini Shahr",
        "country": "Iran",
        "province": "Esfahan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Fasa",
        "country": "Iran",
        "province": "Fars",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Gonbad-e Kavus",
        "country": "Iran",
        "province": "Golestan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Gorgan",
        "country": "Iran",
        "province": "Golestan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Amol",
        "country": "Iran",
        "province": "Mazandaran",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Sari",
        "country": "Iran",
        "province": "Mazandaran",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Semnan",
        "country": "Iran",
        "province": "Semnan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Karaj",
        "country": "Iran",
        "province": "Tehran",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Behbehan",
        "country": "Iran",
        "province": "Khuzestan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Dezful",
        "country": "Iran",
        "province": "Khuzestan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Khorramabad",
        "country": "Iran",
        "province": "Lorestan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Ilam",
        "country": "Iran",
        "province": "Ilam",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Saveh",
        "country": "Iran",
        "province": "Markazi",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Arak",
        "country": "Iran",
        "province": "Markazi",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Mahabad",
        "country": "Iran",
        "province": "West Azarbaijan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Khvoy",
        "country": "Iran",
        "province": "West Azarbaijan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Maragheh",
        "country": "Iran",
        "province": "East Azarbaijan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Qasr-e Shirin",
        "country": "Iran",
        "province": "Kermanshah",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Bijar",
        "country": "Iran",
        "province": "Kordestan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Yazdan",
        "country": "Iran",
        "province": "South Khorasan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Torbat-e Jam",
        "country": "Iran",
        "province": "Razavi Khorasan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Quchan",
        "country": "Iran",
        "province": "Razavi Khorasan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Chabahar",
        "country": "Iran",
        "province": "Sistan and Baluchestan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Kashmar",
        "country": "Iran",
        "province": "Razavi Khorasan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Bam",
        "country": "Iran",
        "province": "Kerman",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Kerman",
        "country": "Iran",
        "province": "Kerman",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Bandar-e Bushehr",
        "country": "Iran",
        "province": "Bushehr",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Abadan",
        "country": "Iran",
        "province": "Khuzestan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Ardabil",
        "country": "Iran",
        "province": "Ardebil",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Qom",
        "country": "Iran",
        "province": "Qom",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Qazvin",
        "country": "Iran",
        "province": "Qazvin",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Kermanshah",
        "country": "Iran",
        "province": "Kermanshah",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Rasht",
        "country": "Iran",
        "province": "Gilan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Birjand",
        "country": "Iran",
        "province": "South Khorasan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Sabzewar",
        "country": "Iran",
        "province": "Razavi Khorasan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Zabol",
        "country": "Iran",
        "province": "Sistan and Baluchestan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Zahedan",
        "country": "Iran",
        "province": "Sistan and Baluchestan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Yazd",
        "country": "Iran",
        "province": "Yazd",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Ahvaz",
        "country": "Iran",
        "province": "Khuzestan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Bandar-e-Abbas",
        "country": "Iran",
        "province": "Hormozgan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Hamadan",
        "country": "Iran",
        "province": "Hamadan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Tabriz",
        "country": "Iran",
        "province": "East Azarbaijan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Isfahan",
        "country": "Iran",
        "province": "Esfahan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Shiraz",
        "country": "Iran",
        "province": "Fars",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Mashhad",
        "country": "Iran",
        "province": "Razavi Khorasan",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Tehran",
        "country": "Iran",
        "province": "Tehran",
        "timezone": "Asia/Tehran"
    },
    {
        "name": "Dahuk",
        "country": "Iraq",
        "province": "Dihok",
        "timezone": "Asia/Baghdad"
    },
    {
        "name": "Samarra",
        "country": "Iraq",
        "province": "Sala ad-Din",
        "timezone": "Asia/Baghdad"
    },
    {
        "name": "Az Aubayr",
        "country": "Iraq",
        "province": "Al-Basrah",
        "timezone": "Asia/Baghdad"
    },
    {
        "name": "Ad Diwaniyah",
        "country": "Iraq",
        "province": "Al-Qadisiyah",
        "timezone": "Asia/Baghdad"
    },
    {
        "name": "Ash Shatrah",
        "country": "Iraq",
        "province": "Dhi-Qar",
        "timezone": "Asia/Baghdad"
    },
    {
        "name": "Mandali",
        "country": "Iraq",
        "province": "Diyala",
        "timezone": "Asia/Baghdad"
    },
    {
        "name": "Ar Ramadi",
        "country": "Iraq",
        "province": "Al-Anbar",
        "timezone": "Asia/Baghdad"
    },
    {
        "name": "Al Musayyib",
        "country": "Iraq",
        "province": "Babil",
        "timezone": "Asia/Baghdad"
    },
    {
        "name": "Zakho",
        "country": "Iraq",
        "province": "Dihok",
        "timezone": "Asia/Baghdad"
    },
    {
        "name": "Tall Afar",
        "country": "Iraq",
        "province": "Ninawa",
        "timezone": "Asia/Baghdad"
    },
    {
        "name": "Tikrit",
        "country": "Iraq",
        "province": "Sala ad-Din",
        "timezone": "Asia/Baghdad"
    },
    {
        "name": "Karbala",
        "country": "Iraq",
        "province": "Karbala'",
        "timezone": "Asia/Baghdad"
    },
    {
        "name": "As Samawah",
        "country": "Iraq",
        "province": "Al-Muthannia",
        "timezone": "Asia/Baghdad"
    },
    {
        "name": "An Nasiriyah",
        "country": "Iraq",
        "province": "Dhi-Qar",
        "timezone": "Asia/Baghdad"
    },
    {
        "name": "Al Amarah",
        "country": "Iraq",
        "province": "Maysan",
        "timezone": "Asia/Baghdad"
    },
    {
        "name": "Al Kut",
        "country": "Iraq",
        "province": "Wasit",
        "timezone": "Asia/Baghdad"
    },
    {
        "name": "As Sulaymaniyah",
        "country": "Iraq",
        "province": "As-Sulaymaniyah",
        "timezone": "Asia/Baghdad"
    },
    {
        "name": "Baqubah",
        "country": "Iraq",
        "province": "Diyala",
        "timezone": "Asia/Baghdad"
    },
    {
        "name": "Ar Rutbah",
        "country": "Iraq",
        "province": "Al-Anbar",
        "timezone": "Asia/Baghdad"
    },
    {
        "name": "Al Fallujah",
        "country": "Iraq",
        "province": "Al-Anbar",
        "timezone": "Asia/Baghdad"
    },
    {
        "name": "Al Hillah",
        "country": "Iraq",
        "province": "Babil",
        "timezone": "Asia/Baghdad"
    },
    {
        "name": "Irbil",
        "country": "Iraq",
        "province": "Arbil",
        "timezone": "Asia/Baghdad"
    },
    {
        "name": "Kirkuk",
        "country": "Iraq",
        "province": "At-Ta'mim",
        "timezone": "Asia/Baghdad"
    },
    {
        "name": "Mosul",
        "country": "Iraq",
        "province": "Ninawa",
        "timezone": "Asia/Baghdad"
    },
    {
        "name": "An Najaf",
        "country": "Iraq",
        "province": "An-Najaf",
        "timezone": "Asia/Baghdad"
    },
    {
        "name": "Basra",
        "country": "Iraq",
        "province": "Al-Basrah",
        "timezone": "Asia/Baghdad"
    },
    {
        "name": "Baghdad",
        "country": "Iraq",
        "province": "Baghdad",
        "timezone": "Asia/Baghdad"
    },
    {
        "name": "Ros Comain",
        "country": "Ireland",
        "province": "Roscommon",
        "timezone": "Europe/Dublin"
    },
    {
        "name": "Muineachan",
        "country": "Ireland",
        "province": "Monaghan",
        "timezone": "Europe/Dublin"
    },
    {
        "name": "Shannon",
        "country": "Ireland",
        "province": "Clare",
        "timezone": "Europe/Dublin"
    },
    {
        "name": "Waterford",
        "country": "Ireland",
        "province": "Kilkenny",
        "timezone": "Europe/Dublin"
    },
    {
        "name": "Tralee",
        "country": "Ireland",
        "province": "Kerry",
        "timezone": "Europe/Dublin"
    },
    {
        "name": "Donegal",
        "country": "Ireland",
        "province": "Donegal",
        "timezone": "Europe/Dublin"
    },
    {
        "name": "Drogheda",
        "country": "Ireland",
        "province": "Louth",
        "timezone": "Europe/Dublin"
    },
    {
        "name": "Dundalk",
        "country": "Ireland",
        "province": "Louth",
        "timezone": "Europe/Dublin"
    },
    {
        "name": "Galway",
        "country": "Ireland",
        "province": "Galway",
        "timezone": "Europe/Dublin"
    },
    {
        "name": "Kilkenny",
        "country": "Ireland",
        "province": "Kilkenny",
        "timezone": "Europe/Dublin"
    },
    {
        "name": "Killarney",
        "country": "Ireland",
        "province": "Kerry",
        "timezone": "Europe/Dublin"
    },
    {
        "name": "Sligo",
        "country": "Ireland",
        "province": "Sligo",
        "timezone": "Europe/Dublin"
    },
    {
        "name": "Cork",
        "country": "Ireland",
        "province": "Cork",
        "timezone": "Europe/Dublin"
    },
    {
        "name": "Limerick",
        "country": "Ireland",
        "province": "Limerick",
        "timezone": "Europe/Dublin"
    },
    {
        "name": "Dublin",
        "country": "Ireland",
        "province": "Dublin",
        "timezone": "Europe/Dublin"
    },
    {
        "name": "Douglas",
        "country": "Isle of Man",
        "province": "",
        "timezone": "Europe/Isle_of_Man"
    },
    {
        "name": "Ramla",
        "country": "Israel",
        "province": "HaMerkaz",
        "timezone": "Asia/Jerusalem"
    },
    {
        "name": "Beer Sheva",
        "country": "Israel",
        "province": "HaDarom",
        "timezone": "Asia/Jerusalem"
    },
    {
        "name": "Haifa",
        "country": "Israel",
        "province": "Haifa",
        "timezone": "Asia/Jerusalem"
    },
    {
        "name": "Nazareth",
        "country": "Israel",
        "province": "HaZafon",
        "timezone": "Asia/Jerusalem"
    },
    {
        "name": "Jerusalem",
        "country": "Israel",
        "province": "Jerusalem",
        "timezone": "Asia/Jerusalem"
    },
    {
        "name": "Tel Aviv-Yafo",
        "country": "Israel",
        "province": "Tel Aviv",
        "timezone": "Asia/Jerusalem"
    },
    {
        "name": "Potenza",
        "country": "Italy",
        "province": "Basilicata",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Campobasso",
        "country": "Italy",
        "province": "Molise",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Aosta",
        "country": "Italy",
        "province": "Valle d'Aosta",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Modena",
        "country": "Italy",
        "province": "Emilia-Romagna",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Crotone",
        "country": "Italy",
        "province": "Calabria",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Vibo Valentia",
        "country": "Italy",
        "province": "Calabria",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Reggio di Calabria",
        "country": "Italy",
        "province": "Calabria",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Caserta",
        "country": "Italy",
        "province": "Campania",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Barletta",
        "country": "Italy",
        "province": "Apulia",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Ragusa",
        "country": "Italy",
        "province": "Sicily",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Asti",
        "country": "Italy",
        "province": "Piemonte",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Novara",
        "country": "Italy",
        "province": "Piemonte",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Como",
        "country": "Italy",
        "province": "Lombardia",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Udine",
        "country": "Italy",
        "province": "Friuli-Venezia Giulia",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Treviso",
        "country": "Italy",
        "province": "Veneto",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Parma",
        "country": "Italy",
        "province": "Emilia-Romagna",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Ravenna",
        "country": "Italy",
        "province": "Emilia-Romagna",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Ferrara",
        "country": "Italy",
        "province": "Emilia-Romagna",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Bologna",
        "country": "Italy",
        "province": "Emilia-Romagna",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Olbia",
        "country": "Italy",
        "province": "Sardegna",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Cagliari",
        "country": "Italy",
        "province": "Sardegna",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Pisa",
        "country": "Italy",
        "province": "Toscana",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Livorno",
        "country": "Italy",
        "province": "Toscana",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Siena",
        "country": "Italy",
        "province": "Toscana",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Arezzo",
        "country": "Italy",
        "province": "Toscana",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Catanzaro",
        "country": "Italy",
        "province": "Calabria",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Salerno",
        "country": "Italy",
        "province": "Campania",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Benevento",
        "country": "Italy",
        "province": "Campania",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Bari",
        "country": "Italy",
        "province": "Apulia",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Foggia",
        "country": "Italy",
        "province": "Apulia",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Lecce",
        "country": "Italy",
        "province": "Apulia",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Brindisi",
        "country": "Italy",
        "province": "Apulia",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Taranto",
        "country": "Italy",
        "province": "Apulia",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Messina",
        "country": "Italy",
        "province": "Sicily",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Marsala",
        "country": "Italy",
        "province": "Sicily",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Siracusa",
        "country": "Italy",
        "province": "Sicily",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Pescara",
        "country": "Italy",
        "province": "Abruzzo",
        "timezone": "Europe/Rome"
    },
    {
        "name": "L'Aquila",
        "country": "Italy",
        "province": "Abruzzo",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Civitavecchia",
        "country": "Italy",
        "province": "Lazio",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Ancona",
        "country": "Italy",
        "province": "Marche",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Perugia",
        "country": "Italy",
        "province": "Umbria",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Bergamo",
        "country": "Italy",
        "province": "Lombardia",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Trieste",
        "country": "Italy",
        "province": "Friuli-Venezia Giulia",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Bolzano",
        "country": "Italy",
        "province": "Trentino-Alto Adige",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Trento",
        "country": "Italy",
        "province": "Trentino-Alto Adige",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Verona",
        "country": "Italy",
        "province": "Veneto",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Sassari",
        "country": "Italy",
        "province": "Sardegna",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Turin",
        "country": "Italy",
        "province": "Piemonte",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Genoa",
        "country": "Italy",
        "province": "Liguria",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Florence",
        "country": "Italy",
        "province": "Toscana",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Catania",
        "country": "Italy",
        "province": "Sicily",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Venice",
        "country": "Italy",
        "province": "Veneto",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Palermo",
        "country": "Italy",
        "province": "Sicily",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Naples",
        "country": "Italy",
        "province": "Campania",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Milan",
        "country": "Italy",
        "province": "Lombardia",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Rome",
        "country": "Italy",
        "province": "Lazio",
        "timezone": "Europe/Rome"
    },
    {
        "name": "Touba",
        "country": "Ivory Coast",
        "province": "Bafing",
        "timezone": "Africa/Abidjan"
    },
    {
        "name": "Bouafle",
        "country": "Ivory Coast",
        "province": "Marahoué",
        "timezone": "Africa/Abidjan"
    },
    {
        "name": "Divo",
        "country": "Ivory Coast",
        "province": "Sud-Bandama",
        "timezone": "Africa/Abidjan"
    },
    {
        "name": "Toumodi",
        "country": "Ivory Coast",
        "province": "Lacs",
        "timezone": "Africa/Abidjan"
    },
    {
        "name": "Aboisso",
        "country": "Ivory Coast",
        "province": "Sud-Comoé",
        "timezone": "Africa/Abidjan"
    },
    {
        "name": "Ferkessedougou",
        "country": "Ivory Coast",
        "province": "Savanes",
        "timezone": "Africa/Abidjan"
    },
    {
        "name": "Odienne",
        "country": "Ivory Coast",
        "province": "Denguélé",
        "timezone": "Africa/Abidjan"
    },
    {
        "name": "Man",
        "country": "Ivory Coast",
        "province": "Dix-Huit Montagnes",
        "timezone": "Africa/Abidjan"
    },
    {
        "name": "Seguela",
        "country": "Ivory Coast",
        "province": "Worodougou",
        "timezone": "Africa/Abidjan"
    },
    {
        "name": "Gagnoa",
        "country": "Ivory Coast",
        "province": "Fromager",
        "timezone": "Africa/Abidjan"
    },
    {
        "name": "Soubre",
        "country": "Ivory Coast",
        "province": "Bas-Sassandra",
        "timezone": "Africa/Abidjan"
    },
    {
        "name": "San-Pedro",
        "country": "Ivory Coast",
        "province": "Bas-Sassandra",
        "timezone": "Africa/Abidjan"
    },
    {
        "name": "Sassandra",
        "country": "Ivory Coast",
        "province": "Bas-Sassandra",
        "timezone": "Africa/Abidjan"
    },
    {
        "name": "Bondoukou",
        "country": "Ivory Coast",
        "province": "Zanzan",
        "timezone": "Africa/Abidjan"
    },
    {
        "name": "Agboville",
        "country": "Ivory Coast",
        "province": "Agnéby",
        "timezone": "Africa/Abidjan"
    },
    {
        "name": "Dimbokro",
        "country": "Ivory Coast",
        "province": "N'zi-Comoé",
        "timezone": "Africa/Abidjan"
    },
    {
        "name": "Grand Bassam",
        "country": "Ivory Coast",
        "province": "Lagunes",
        "timezone": "Africa/Abidjan"
    },
    {
        "name": "Dabou",
        "country": "Ivory Coast",
        "province": "Lagunes",
        "timezone": "Africa/Abidjan"
    },
    {
        "name": "Guiglo",
        "country": "Ivory Coast",
        "province": "Moyen-Cavally",
        "timezone": "Africa/Abidjan"
    },
    {
        "name": "Abengourou",
        "country": "Ivory Coast",
        "province": "Moyen-Comoe",
        "timezone": "Africa/Abidjan"
    },
    {
        "name": "Korhogo",
        "country": "Ivory Coast",
        "province": "Savanes",
        "timezone": "Africa/Abidjan"
    },
    {
        "name": "Daloa",
        "country": "Ivory Coast",
        "province": "Haut-Sassandra",
        "timezone": "Africa/Abidjan"
    },
    {
        "name": "Bouake",
        "country": "Ivory Coast",
        "province": "Vallée du Bandama",
        "timezone": "Africa/Abidjan"
    },
    {
        "name": "Yamoussoukro",
        "country": "Ivory Coast",
        "province": "Lacs",
        "timezone": "Africa/Abidjan"
    },
    {
        "name": "Abidjan",
        "country": "Ivory Coast",
        "province": "Lagunes",
        "timezone": "Africa/Abidjan"
    },
    {
        "name": "Lucea",
        "country": "Jamaica",
        "province": "Hanover",
        "timezone": "America/Jamaica"
    },
    {
        "name": "Mandeville",
        "country": "Jamaica",
        "province": "Manchester",
        "timezone": "America/Jamaica"
    },
    {
        "name": "Black River",
        "country": "Jamaica",
        "province": "Saint Elizabeth",
        "timezone": "America/Jamaica"
    },
    {
        "name": "Falmouth",
        "country": "Jamaica",
        "province": "Trelawny",
        "timezone": "America/Jamaica"
    },
    {
        "name": "Savanna-la-Mar",
        "country": "Jamaica",
        "province": "Westmoreland",
        "timezone": "America/Jamaica"
    },
    {
        "name": "Port Antonio",
        "country": "Jamaica",
        "province": "Portland",
        "timezone": "America/Jamaica"
    },
    {
        "name": "St. Ann's Bay",
        "country": "Jamaica",
        "province": "Saint Ann",
        "timezone": "America/Jamaica"
    },
    {
        "name": "Port Maria",
        "country": "Jamaica",
        "province": "Saint Mary",
        "timezone": "America/Jamaica"
    },
    {
        "name": "Half Way Tree",
        "country": "Jamaica",
        "province": "Saint Andrew",
        "timezone": "America/Jamaica"
    },
    {
        "name": "Port Morant",
        "country": "Jamaica",
        "province": "Saint Thomas",
        "timezone": "America/Jamaica"
    },
    {
        "name": "May Pen",
        "country": "Jamaica",
        "province": "Clarendon",
        "timezone": "America/Jamaica"
    },
    {
        "name": "Spanish Town",
        "country": "Jamaica",
        "province": "Saint Catherine",
        "timezone": "America/Jamaica"
    },
    {
        "name": "Montego Bay",
        "country": "Jamaica",
        "province": "Saint James",
        "timezone": "America/Jamaica"
    },
    {
        "name": "Kingston",
        "country": "Jamaica",
        "province": "Kingston",
        "timezone": "America/Jamaica"
    },
    {
        "name": "Okayama",
        "country": "Japan",
        "province": "Okayama",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Shimonoseki",
        "country": "Japan",
        "province": "Yamaguchi",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Kanoya",
        "country": "Japan",
        "province": "Kagoshima",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Takamatsu",
        "country": "Japan",
        "province": "Kagawa",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Tokushima",
        "country": "Japan",
        "province": "Tokushima",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Toyama",
        "country": "Japan",
        "province": "Toyama",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Takaoka",
        "country": "Japan",
        "province": "Toyama",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Otsu",
        "country": "Japan",
        "province": "Shiga",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Maebashi",
        "country": "Japan",
        "province": "Gunma",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Kawasaki",
        "country": "Japan",
        "province": "Kanagawa",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Kawagoe",
        "country": "Japan",
        "province": "Saitama",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Utsunomiya",
        "country": "Japan",
        "province": "Tochigi",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Hachioji",
        "country": "Japan",
        "province": "Tokyo",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Koriyama",
        "country": "Japan",
        "province": "Fukushima",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Kure",
        "country": "Japan",
        "province": "Hiroshima",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Matsue",
        "country": "Japan",
        "province": "Shimane",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Tottori",
        "country": "Japan",
        "province": "Tottori",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Sasebo",
        "country": "Japan",
        "province": "Nagasaki",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Kitakyushu",
        "country": "Japan",
        "province": "Fukuoka",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Kumamoto",
        "country": "Japan",
        "province": "Kumamoto",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Oita",
        "country": "Japan",
        "province": "Oita",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Gifu",
        "country": "Japan",
        "province": "Gifu",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Tsu",
        "country": "Japan",
        "province": "Mie",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Matsumoto",
        "country": "Japan",
        "province": "Nagano",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Shizuoka",
        "country": "Japan",
        "province": "Shizuoka",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Hamamatsu",
        "country": "Japan",
        "province": "Shizuoka",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Obihiro",
        "country": "Japan",
        "province": "Hokkaido",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Tomakomai",
        "country": "Japan",
        "province": "Hokkaido",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Kitami",
        "country": "Japan",
        "province": "Hokkaido",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Otaru",
        "country": "Japan",
        "province": "Hokkaido",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Fukui",
        "country": "Japan",
        "province": "Fukui",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Maizuru",
        "country": "Japan",
        "province": "Kyoto",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Wakayama",
        "country": "Japan",
        "province": "Wakayama",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Mito",
        "country": "Japan",
        "province": "Ibaraki",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Kofu",
        "country": "Japan",
        "province": "Yamanashi",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Iwaki",
        "country": "Japan",
        "province": "Fukushima",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Nagaoka",
        "country": "Japan",
        "province": "Niigata",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Yamagata",
        "country": "Japan",
        "province": "Yamagata",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Tsuruoka",
        "country": "Japan",
        "province": "Yamagata",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Kagoshima",
        "country": "Japan",
        "province": "Kagoshima",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Matsuyama",
        "country": "Japan",
        "province": "Ehime",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Kanazawa",
        "country": "Japan",
        "province": "Ishikawa",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Muroran",
        "country": "Japan",
        "province": "Hokkaido",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Asahikawa",
        "country": "Japan",
        "province": "Hokkaido",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Kobe",
        "country": "Japan",
        "province": "Hyogo",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Yokohama",
        "country": "Japan",
        "province": "Kanagawa",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Akita",
        "country": "Japan",
        "province": "Akita",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Aomori",
        "country": "Japan",
        "province": "Aomori",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Hirosaki",
        "country": "Japan",
        "province": "Aomori",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Hachinohe",
        "country": "Japan",
        "province": "Aomori",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Fukushima",
        "country": "Japan",
        "province": "Fukushima",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Morioka",
        "country": "Japan",
        "province": "Iwate",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Niigata",
        "country": "Japan",
        "province": "Niigata",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Fukuoka",
        "country": "Japan",
        "province": "Fukuoka",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Miyazaki",
        "country": "Japan",
        "province": "Miyazaki",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Naha",
        "country": "Japan",
        "province": "Okinawa",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Kochi",
        "country": "Japan",
        "province": "Kochi",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Nagoya",
        "country": "Japan",
        "province": "Aichi",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Nagano",
        "country": "Japan",
        "province": "Nagano",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Kushiro",
        "country": "Japan",
        "province": "Hokkaido",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Hakodate",
        "country": "Japan",
        "province": "Hokkaido",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Kyoto",
        "country": "Japan",
        "province": "Kyoto",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Sendai",
        "country": "Japan",
        "province": "Miyagi",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Sakata",
        "country": "Japan",
        "province": "Yamagata",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Nagasaki",
        "country": "Japan",
        "province": "Nagasaki",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Hiroshima",
        "country": "Japan",
        "province": "Hiroshima",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Sapporo",
        "country": "Japan",
        "province": "Hokkaido",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Osaka",
        "country": "Japan",
        "province": "Osaka",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Tokyo",
        "country": "Japan",
        "province": "Tokyo",
        "timezone": "Asia/Tokyo"
    },
    {
        "name": "Al Mafraq",
        "country": "Jordan",
        "province": "Mafraq",
        "timezone": "Asia/Amman"
    },
    {
        "name": "At Tafilah",
        "country": "Jordan",
        "province": "Tafilah",
        "timezone": "Asia/Amman"
    },
    {
        "name": "Ma'an",
        "country": "Jordan",
        "province": "Ma`an",
        "timezone": "Asia/Amman"
    },
    {
        "name": "Irbid",
        "country": "Jordan",
        "province": "Irbid",
        "timezone": "Asia/Amman"
    },
    {
        "name": "As Salt",
        "country": "Jordan",
        "province": "Balqa",
        "timezone": "Asia/Amman"
    },
    {
        "name": "Az Zarqa",
        "country": "Jordan",
        "province": "Zarqa",
        "timezone": "Asia/Amman"
    },
    {
        "name": "Al Aqabah",
        "country": "Jordan",
        "province": "Aqaba",
        "timezone": "Asia/Amman"
    },
    {
        "name": "Al Karak",
        "country": "Jordan",
        "province": "Karak",
        "timezone": "Asia/Amman"
    },
    {
        "name": "Amman",
        "country": "Jordan",
        "province": "Amman",
        "timezone": "Asia/Amman"
    },
    {
        "name": "Turgay",
        "country": "Kazakhstan",
        "province": "Qostanay",
        "timezone": "Asia/Qyzylorda"
    },
    {
        "name": "Mangyshlak",
        "country": "Kazakhstan",
        "province": "Mangghystau",
        "timezone": "Asia/Aqtau"
    },
    {
        "name": "Maqat",
        "country": "Kazakhstan",
        "province": "Atyrau",
        "timezone": "Asia/Atyrau"
    },
    {
        "name": "Bestobe",
        "country": "Kazakhstan",
        "province": "Aqmola",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Osakarovka",
        "country": "Kazakhstan",
        "province": "Qaraghandy",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Aqadyr",
        "country": "Kazakhstan",
        "province": "Qaraghandy",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Sharbaqty",
        "country": "Kazakhstan",
        "province": "Pavlodar",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Shemonaikha",
        "country": "Kazakhstan",
        "province": "East Kazakhstan",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Serebryansk",
        "country": "Kazakhstan",
        "province": "East Kazakhstan",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Boralday",
        "country": "Kazakhstan",
        "province": "Almaty",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Zharkent",
        "country": "Kazakhstan",
        "province": "Almaty",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Esik",
        "country": "Kazakhstan",
        "province": "Almaty",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Lenger",
        "country": "Kazakhstan",
        "province": "South Kazakhstan",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Kentau",
        "country": "Kazakhstan",
        "province": "South Kazakhstan",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Zhosaly",
        "country": "Kazakhstan",
        "province": "Qyzylorda",
        "timezone": "Asia/Qyzylorda"
    },
    {
        "name": "Oktyabrsk",
        "country": "Kazakhstan",
        "province": "Aqtöbe",
        "timezone": "Asia/Aqtobe"
    },
    {
        "name": "Algha",
        "country": "Kazakhstan",
        "province": "Aqtöbe",
        "timezone": "Asia/Aqtobe"
    },
    {
        "name": "Bayghanin",
        "country": "Kazakhstan",
        "province": "Aqtöbe",
        "timezone": "Asia/Aqtobe"
    },
    {
        "name": "Embi",
        "country": "Kazakhstan",
        "province": "Aqtöbe",
        "timezone": "Asia/Aqtobe"
    },
    {
        "name": "Zhetiqara",
        "country": "Kazakhstan",
        "province": "Qostanay",
        "timezone": "Asia/Qyzylorda"
    },
    {
        "name": "Komsomolets",
        "country": "Kazakhstan",
        "province": "Qostanay",
        "timezone": "Asia/Qyzylorda"
    },
    {
        "name": "Tobol",
        "country": "Kazakhstan",
        "province": "Qostanay",
        "timezone": "Asia/Qyzylorda"
    },
    {
        "name": "Qusmuryn",
        "country": "Kazakhstan",
        "province": "Qostanay",
        "timezone": "Asia/Qyzylorda"
    },
    {
        "name": "Shieli",
        "country": "Kazakhstan",
        "province": "Qyzylorda",
        "timezone": "Asia/Qyzylorda"
    },
    {
        "name": "Makhambet",
        "country": "Kazakhstan",
        "province": "Atyrau",
        "timezone": "Asia/Atyrau"
    },
    {
        "name": "Chapaev",
        "country": "Kazakhstan",
        "province": "West Kazakhstan",
        "timezone": "Asia/Oral"
    },
    {
        "name": "Zhanibek",
        "country": "Kazakhstan",
        "province": "West Kazakhstan",
        "timezone": "Asia/Oral"
    },
    {
        "name": "Aqsay",
        "country": "Kazakhstan",
        "province": "West Kazakhstan",
        "timezone": "Asia/Oral"
    },
    {
        "name": "Esil",
        "country": "Kazakhstan",
        "province": "Aqmola",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Derzhavinsk",
        "country": "Kazakhstan",
        "province": "Aqmola",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Zhaltyr",
        "country": "Kazakhstan",
        "province": "Aqmola",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Makinsk",
        "country": "Kazakhstan",
        "province": "Aqmola",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Aqsu",
        "country": "Kazakhstan",
        "province": "Aqmola",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Zholymbet",
        "country": "Kazakhstan",
        "province": "Aqmola",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Erymentau",
        "country": "Kazakhstan",
        "province": "Aqmola",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Saryshaghan",
        "country": "Kazakhstan",
        "province": "Qaraghandy",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Qarazhal",
        "country": "Kazakhstan",
        "province": "Qaraghandy",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Atasu",
        "country": "Kazakhstan",
        "province": "Qaraghandy",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Kishkenekol",
        "country": "Kazakhstan",
        "province": "North Kazakhstan",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Tayynsha",
        "country": "Kazakhstan",
        "province": "North Kazakhstan",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Bulaevo",
        "country": "Kazakhstan",
        "province": "North Kazakhstan",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Ertis",
        "country": "Kazakhstan",
        "province": "Pavlodar",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Kachiry",
        "country": "Kazakhstan",
        "province": "Pavlodar",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Zaysan",
        "country": "Kazakhstan",
        "province": "East Kazakhstan",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Zyryanovsk",
        "country": "Kazakhstan",
        "province": "East Kazakhstan",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Ridder",
        "country": "Kazakhstan",
        "province": "East Kazakhstan",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Shar",
        "country": "Kazakhstan",
        "province": "East Kazakhstan",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Urzhar",
        "country": "Kazakhstan",
        "province": "East Kazakhstan",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Sarqan",
        "country": "Kazakhstan",
        "province": "Almaty",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Ushtobe",
        "country": "Kazakhstan",
        "province": "Almaty",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Shonzhy",
        "country": "Kazakhstan",
        "province": "Almaty",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Qapshaghay",
        "country": "Kazakhstan",
        "province": "Almaty",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Otar",
        "country": "Kazakhstan",
        "province": "Almaty",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Fort Shevchenko",
        "country": "Kazakhstan",
        "province": "Mangghystau",
        "timezone": "Asia/Aqtau"
    },
    {
        "name": "Zhangaozen",
        "country": "Kazakhstan",
        "province": "Mangghystau",
        "timezone": "Asia/Aqtau"
    },
    {
        "name": "Arys",
        "country": "Kazakhstan",
        "province": "South Kazakhstan",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Burylbaytal",
        "country": "Kazakhstan",
        "province": "Zhambyl",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Shu",
        "country": "Kazakhstan",
        "province": "Zhambyl",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Qulan",
        "country": "Kazakhstan",
        "province": "Zhambyl",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Oytal",
        "country": "Kazakhstan",
        "province": "Zhambyl",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Qaratau",
        "country": "Kazakhstan",
        "province": "Zhambyl",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Khromtau",
        "country": "Kazakhstan",
        "province": "Aqtöbe",
        "timezone": "Asia/Aqtobe"
    },
    {
        "name": "Arqalyq",
        "country": "Kazakhstan",
        "province": "Qostanay",
        "timezone": "Asia/Qyzylorda"
    },
    {
        "name": "Oostanay",
        "country": "Kazakhstan",
        "province": "Qostanay",
        "timezone": "Asia/Qyzylorda"
    },
    {
        "name": "Baykonur",
        "country": "Kazakhstan",
        "province": "Qyzylorda",
        "timezone": "Asia/Qyzylorda"
    },
    {
        "name": "Balyqshy",
        "country": "Kazakhstan",
        "province": "Atyrau",
        "timezone": "Asia/Atyrau"
    },
    {
        "name": "Atbasar",
        "country": "Kazakhstan",
        "province": "Aqmola",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Kokshetau",
        "country": "Kazakhstan",
        "province": "Aqmola",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Temirtau",
        "country": "Kazakhstan",
        "province": "Qaraghandy",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Zhezqazghan",
        "country": "Kazakhstan",
        "province": "Qaraghandy",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Qarqaraly",
        "country": "Kazakhstan",
        "province": "Qaraghandy",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Balqash",
        "country": "Kazakhstan",
        "province": "Qaraghandy",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Petropavlovsk",
        "country": "Kazakhstan",
        "province": "North Kazakhstan",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Ayakoz",
        "country": "Kazakhstan",
        "province": "East Kazakhstan",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Taldyqorghan",
        "country": "Kazakhstan",
        "province": "Almaty",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Turkistan",
        "country": "Kazakhstan",
        "province": "South Kazakhstan",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Shalqar",
        "country": "Kazakhstan",
        "province": "Aqtöbe",
        "timezone": "Asia/Aqtobe"
    },
    {
        "name": "Qazaly",
        "country": "Kazakhstan",
        "province": "Qyzylorda",
        "timezone": "Asia/Qyzylorda"
    },
    {
        "name": "Aral",
        "country": "Kazakhstan",
        "province": "Qyzylorda",
        "timezone": "Asia/Qyzylorda"
    },
    {
        "name": "Qulsary",
        "country": "Kazakhstan",
        "province": "Atyrau",
        "timezone": "Asia/Atyrau"
    },
    {
        "name": "Oral",
        "country": "Kazakhstan",
        "province": "West Kazakhstan",
        "timezone": "Asia/Oral"
    },
    {
        "name": "Beyneu",
        "country": "Kazakhstan",
        "province": "Mangghystau",
        "timezone": "Asia/Aqtau"
    },
    {
        "name": "Aktau",
        "country": "Kazakhstan",
        "province": "Mangghystau",
        "timezone": "Asia/Aqtau"
    },
    {
        "name": "Aktobe",
        "country": "Kazakhstan",
        "province": "Aqtöbe",
        "timezone": "Asia/Aqtobe"
    },
    {
        "name": "Rudny",
        "country": "Kazakhstan",
        "province": "Qostanay",
        "timezone": "Asia/Qyzylorda"
    },
    {
        "name": "Qyzylorda",
        "country": "Kazakhstan",
        "province": "Qyzylorda",
        "timezone": "Asia/Qyzylorda"
    },
    {
        "name": "Atyrau",
        "country": "Kazakhstan",
        "province": "Atyrau",
        "timezone": "Asia/Atyrau"
    },
    {
        "name": "Ekibastuz",
        "country": "Kazakhstan",
        "province": "Pavlodar",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Pavlodar",
        "country": "Kazakhstan",
        "province": "Pavlodar",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Semey",
        "country": "Kazakhstan",
        "province": "East Kazakhstan",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Oskemen",
        "country": "Kazakhstan",
        "province": "East Kazakhstan",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Shymkent",
        "country": "Kazakhstan",
        "province": "South Kazakhstan",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Taraz",
        "country": "Kazakhstan",
        "province": "Zhambyl",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Astana",
        "country": "Kazakhstan",
        "province": "Aqmola",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Qaraghandy",
        "country": "Kazakhstan",
        "province": "Qaraghandy",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Almaty",
        "country": "Kazakhstan",
        "province": "Almaty",
        "timezone": "Asia/Almaty"
    },
    {
        "name": "Nyeri",
        "country": "Kenya",
        "province": "Central",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Mwingi",
        "country": "Kenya",
        "province": "Eastern",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Embu",
        "country": "Kenya",
        "province": "Eastern",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Machakos",
        "country": "Kenya",
        "province": "Eastern",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Nanyuki",
        "country": "Kenya",
        "province": "Rift Valley",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Maralal",
        "country": "Kenya",
        "province": "Rift Valley",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Konza",
        "country": "Kenya",
        "province": "Rift Valley",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Lodwar",
        "country": "Kenya",
        "province": "Rift Valley",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Eldama Ravine",
        "country": "Kenya",
        "province": "Rift Valley",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Sotik",
        "country": "Kenya",
        "province": "Rift Valley",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Namanga",
        "country": "Kenya",
        "province": "Rift Valley",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Naivasha",
        "country": "Kenya",
        "province": "Rift Valley",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Kericho",
        "country": "Kenya",
        "province": "Rift Valley",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Kitale",
        "country": "Kenya",
        "province": "Rift Valley",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Bungoma",
        "country": "Kenya",
        "province": "Western",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Kakamega",
        "country": "Kenya",
        "province": "Western",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Wajir",
        "country": "Kenya",
        "province": "North-Eastern",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Garissa",
        "country": "Kenya",
        "province": "North-Eastern",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Witu",
        "country": "Kenya",
        "province": "Coast",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Tsavo",
        "country": "Kenya",
        "province": "Coast",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Voi",
        "country": "Kenya",
        "province": "Coast",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Kilifi",
        "country": "Kenya",
        "province": "Coast",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Thika",
        "country": "Kenya",
        "province": "Central",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Kendu Bay",
        "country": "Kenya",
        "province": "Nyanza",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Karungu",
        "country": "Kenya",
        "province": "Nyanza",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Kisii",
        "country": "Kenya",
        "province": "Nyanza",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Marsabit",
        "country": "Kenya",
        "province": "Eastern",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Moyale",
        "country": "Kenya",
        "province": "Eastern",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Nakuru",
        "country": "Kenya",
        "province": "Rift Valley",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Lamu",
        "country": "Kenya",
        "province": "Coast",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Malindi",
        "country": "Kenya",
        "province": "Coast",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Kisumu",
        "country": "Kenya",
        "province": "Nyanza",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Meru",
        "country": "Kenya",
        "province": "Eastern",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Eldoret",
        "country": "Kenya",
        "province": "Rift Valley",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Mombasa",
        "country": "Kenya",
        "province": "Coast",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Nairobi",
        "country": "Kenya",
        "province": "Nairobi",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Tarawa",
        "country": "Kiribati",
        "province": "",
        "timezone": "Pacific/Tarawa"
    },
    {
        "name": "Prizren",
        "country": "Kosovo",
        "province": "Prizren",
        "timezone": "Europe/Belgrade"
    },
    {
        "name": "Pec",
        "country": "Kosovo",
        "province": "Ðakovica",
        "timezone": "Europe/Belgrade"
    },
    {
        "name": "Pristina",
        "country": "Kosovo",
        "province": "Pristina",
        "timezone": "Europe/Belgrade"
    },
    {
        "name": "Hawalli",
        "country": "Kuwait",
        "province": "Hawalli",
        "timezone": "Asia/Kuwait"
    },
    {
        "name": "Al Ahmadi",
        "country": "Kuwait",
        "province": "Al Ahmadi",
        "timezone": "Asia/Kuwait"
    },
    {
        "name": "Al Jahra",
        "country": "Kuwait",
        "province": "Al Jahrah",
        "timezone": "Asia/Kuwait"
    },
    {
        "name": "Kuwait",
        "country": "Kuwait",
        "province": "Al Kuwayt",
        "timezone": "Asia/Kuwait"
    },
    {
        "name": "Tokmak",
        "country": "Kyrgyzstan",
        "province": "Bishkek",
        "timezone": "Asia/Bishkek"
    },
    {
        "name": "Kara Balta",
        "country": "Kyrgyzstan",
        "province": "Bishkek",
        "timezone": "Asia/Bishkek"
    },
    {
        "name": "Cholpon Ata",
        "country": "Kyrgyzstan",
        "province": "Ysyk-Köl",
        "timezone": "Asia/Bishkek"
    },
    {
        "name": "Naryn",
        "country": "Kyrgyzstan",
        "province": "Naryn",
        "timezone": "Asia/Bishkek"
    },
    {
        "name": "Kok Yangak",
        "country": "Kyrgyzstan",
        "province": "Jalal-Abad",
        "timezone": "Asia/Bishkek"
    },
    {
        "name": "Balykchy",
        "country": "Kyrgyzstan",
        "province": "Ysyk-Köl",
        "timezone": "Asia/Bishkek"
    },
    {
        "name": "At Bashy",
        "country": "Kyrgyzstan",
        "province": "Naryn",
        "timezone": "Asia/Bishkek"
    },
    {
        "name": "Jalal Abad",
        "country": "Kyrgyzstan",
        "province": "Jalal-Abad",
        "timezone": "Asia/Bishkek"
    },
    {
        "name": "Toktogul",
        "country": "Kyrgyzstan",
        "province": "Jalal-Abad",
        "timezone": "Asia/Bishkek"
    },
    {
        "name": "Tash Komur",
        "country": "Kyrgyzstan",
        "province": "Jalal-Abad",
        "timezone": "Asia/Bishkek"
    },
    {
        "name": "Talas",
        "country": "Kyrgyzstan",
        "province": "Talas",
        "timezone": "Asia/Bishkek"
    },
    {
        "name": "Osh",
        "country": "Kyrgyzstan",
        "province": "Osh",
        "timezone": "Asia/Bishkek"
    },
    {
        "name": "Karakol",
        "country": "Kyrgyzstan",
        "province": "Ysyk-Köl",
        "timezone": "Asia/Bishkek"
    },
    {
        "name": "Bishkek",
        "country": "Kyrgyzstan",
        "province": "Bishkek",
        "timezone": "Asia/Bishkek"
    },
    {
        "name": "Ban Houayxay",
        "country": "Laos",
        "province": "Bokeo",
        "timezone": "Asia/Vientiane"
    },
    {
        "name": "Louang Namtha",
        "country": "Laos",
        "province": "Louang Namtha",
        "timezone": "Asia/Vientiane"
    },
    {
        "name": "Champasak",
        "country": "Laos",
        "province": "Champasak",
        "timezone": "Asia/Vientiane"
    },
    {
        "name": "Saravan",
        "country": "Laos",
        "province": "Saravan",
        "timezone": "Asia/Vientiane"
    },
    {
        "name": "Xam Nua",
        "country": "Laos",
        "province": "Houaphan",
        "timezone": "Asia/Vientiane"
    },
    {
        "name": "Phongsali",
        "country": "Laos",
        "province": "Phôngsali",
        "timezone": "Asia/Vientiane"
    },
    {
        "name": "Attapu",
        "country": "Laos",
        "province": "Attapu",
        "timezone": "Asia/Vientiane"
    },
    {
        "name": "Xaignabouri",
        "country": "Laos",
        "province": "Xaignabouri",
        "timezone": "Asia/Vientiane"
    },
    {
        "name": "Pakxe",
        "country": "Laos",
        "province": "Champasak",
        "timezone": "Asia/Vientiane"
    },
    {
        "name": "Xiangkhoang",
        "country": "Laos",
        "province": "Xiangkhoang",
        "timezone": "Asia/Vientiane"
    },
    {
        "name": "Louangphrabang",
        "country": "Laos",
        "province": "Louangphrabang",
        "timezone": "Asia/Vientiane"
    },
    {
        "name": "Thakhek",
        "country": "Laos",
        "province": "Khammouan",
        "timezone": "Asia/Vientiane"
    },
    {
        "name": "Savannakhet",
        "country": "Laos",
        "province": "Savannakhét",
        "timezone": "Asia/Vientiane"
    },
    {
        "name": "Vientiane",
        "country": "Laos",
        "province": "Vientiane [prefecture]",
        "timezone": "Asia/Vientiane"
    },
    {
        "name": "Rezekne",
        "country": "Latvia",
        "province": "Latgale",
        "timezone": "Europe/Riga"
    },
    {
        "name": "Ventspils",
        "country": "Latvia",
        "province": "Ventspils",
        "timezone": "Europe/Riga"
    },
    {
        "name": "Jelgava",
        "country": "Latvia",
        "province": "Jelgava",
        "timezone": "Europe/Riga"
    },
    {
        "name": "Liepaga",
        "country": "Latvia",
        "province": "Liepaja",
        "timezone": "Europe/Riga"
    },
    {
        "name": "Daugavpils",
        "country": "Latvia",
        "province": "Daugavpils",
        "timezone": "Europe/Riga"
    },
    {
        "name": "Riga",
        "country": "Latvia",
        "province": "Riga",
        "timezone": "Europe/Riga"
    },
    {
        "name": "B'abda",
        "country": "Lebanon",
        "province": "Mount Lebanon",
        "timezone": "Asia/Beirut"
    },
    {
        "name": "Nabatiye et Tahta",
        "country": "Lebanon",
        "province": "An Nabatiyah",
        "timezone": "Asia/Beirut"
    },
    {
        "name": "Saida",
        "country": "Lebanon",
        "province": "South Lebanon",
        "timezone": "Asia/Beirut"
    },
    {
        "name": "Zahle",
        "country": "Lebanon",
        "province": "Mount Lebanon",
        "timezone": "Asia/Beirut"
    },
    {
        "name": "Trablous",
        "country": "Lebanon",
        "province": "North Lebanon",
        "timezone": "Asia/Beirut"
    },
    {
        "name": "Beirut",
        "country": "Lebanon",
        "province": "Beirut",
        "timezone": "Asia/Beirut"
    },
    {
        "name": "Teyateyaneng",
        "country": "Lesotho",
        "province": "Berea",
        "timezone": "Africa/Maseru"
    },
    {
        "name": "Mohales Hoek",
        "country": "Lesotho",
        "province": "Mohale's Hoek",
        "timezone": "Africa/Maseru"
    },
    {
        "name": "Moyeni",
        "country": "Lesotho",
        "province": "Quthing",
        "timezone": "Africa/Maseru"
    },
    {
        "name": "Hlotse",
        "country": "Lesotho",
        "province": "Leribe",
        "timezone": "Africa/Maseru"
    },
    {
        "name": "Butha-Buthe",
        "country": "Lesotho",
        "province": "Leribe",
        "timezone": "Africa/Maseru"
    },
    {
        "name": "Mokhotlong",
        "country": "Lesotho",
        "province": "Mokhotlong",
        "timezone": "Africa/Maseru"
    },
    {
        "name": "Mafetang",
        "country": "Lesotho",
        "province": "Mafeteng",
        "timezone": "Africa/Maseru"
    },
    {
        "name": "Maseru",
        "country": "Lesotho",
        "province": "Maseru",
        "timezone": "Africa/Maseru"
    },
    {
        "name": "Barclayville",
        "country": "Liberia",
        "province": "GrandKru",
        "timezone": "Africa/Monrovia"
    },
    {
        "name": "Voinjama",
        "country": "Liberia",
        "province": "Lofa",
        "timezone": "Africa/Monrovia"
    },
    {
        "name": "Bensonville",
        "country": "Liberia",
        "province": "Montserrado",
        "timezone": "Africa/Monrovia"
    },
    {
        "name": "Kakata",
        "country": "Liberia",
        "province": "Margibi",
        "timezone": "Africa/Monrovia"
    },
    {
        "name": "Sanniquellie",
        "country": "Liberia",
        "province": "Nimba",
        "timezone": "Africa/Monrovia"
    },
    {
        "name": "Rivercess",
        "country": "Liberia",
        "province": "River Cess",
        "timezone": "Africa/Monrovia"
    },
    {
        "name": "Harper",
        "country": "Liberia",
        "province": "Maryland",
        "timezone": "Africa/Monrovia"
    },
    {
        "name": "Gbarnga",
        "country": "Liberia",
        "province": "Bong",
        "timezone": "Africa/Monrovia"
    },
    {
        "name": "Zwedru",
        "country": "Liberia",
        "province": "GrandGedeh",
        "timezone": "Africa/Monrovia"
    },
    {
        "name": "Greenville",
        "country": "Liberia",
        "province": "Sinoe",
        "timezone": "Africa/Monrovia"
    },
    {
        "name": "Buchanan",
        "country": "Liberia",
        "province": "Grand Bassa",
        "timezone": "Africa/Monrovia"
    },
    {
        "name": "Robertsport",
        "country": "Liberia",
        "province": "Grand Cape Mount",
        "timezone": "Africa/Monrovia"
    },
    {
        "name": "Monrovia",
        "country": "Liberia",
        "province": "Montserrado",
        "timezone": "Africa/Monrovia"
    },
    {
        "name": "Dirj",
        "country": "Libya",
        "province": "Ghadamis",
        "timezone": "Africa/Tripoli"
    },
    {
        "name": "Nalut",
        "country": "Libya",
        "province": "Ghadamis",
        "timezone": "Africa/Tripoli"
    },
    {
        "name": "Zillah",
        "country": "Libya",
        "province": "Al Jufrah",
        "timezone": "Africa/Tripoli"
    },
    {
        "name": "Al Khums",
        "country": "Libya",
        "province": "Al Marqab",
        "timezone": "Africa/Tripoli"
    },
    {
        "name": "Tajarhi",
        "country": "Libya",
        "province": "Murzuq",
        "timezone": "Africa/Tripoli"
    },
    {
        "name": "Umm al Abid",
        "country": "Libya",
        "province": "Sabha",
        "timezone": "Africa/Tripoli"
    },
    {
        "name": "Az Zawiyah",
        "country": "Libya",
        "province": "Az Zawiyah",
        "timezone": "Africa/Tripoli"
    },
    {
        "name": "Gharyan",
        "country": "Libya",
        "province": "Mizdah",
        "timezone": "Africa/Tripoli"
    },
    {
        "name": "Mizdah",
        "country": "Libya",
        "province": "Mizdah",
        "timezone": "Africa/Tripoli"
    },
    {
        "name": "Bani Walid",
        "country": "Libya",
        "province": "Bani Walid",
        "timezone": "Africa/Tripoli"
    },
    {
        "name": "Al Marj",
        "country": "Libya",
        "province": "Al Hizam Al Akhdar",
        "timezone": "Africa/Tripoli"
    },
    {
        "name": "Al Bayda",
        "country": "Libya",
        "province": "Al Jabal al Akhdar",
        "timezone": "Africa/Tripoli"
    },
    {
        "name": "Shahhat",
        "country": "Libya",
        "province": "Al Jabal al Akhdar",
        "timezone": "Africa/Tripoli"
    },
    {
        "name": "El Agheila",
        "country": "Libya",
        "province": "Ajdabiya",
        "timezone": "Africa/Tripoli"
    },
    {
        "name": "Maradah",
        "country": "Libya",
        "province": "Ajdabiya",
        "timezone": "Africa/Tripoli"
    },
    {
        "name": "Qaminis",
        "country": "Libya",
        "province": "Benghazi",
        "timezone": "Africa/Tripoli"
    },
    {
        "name": "As Sidr",
        "country": "Libya",
        "province": "Surt",
        "timezone": "Africa/Tripoli"
    },
    {
        "name": "Al Jaghbub",
        "country": "Libya",
        "province": "Al Butnan",
        "timezone": "Africa/Tripoli"
    },
    {
        "name": "Ghadamis",
        "country": "Libya",
        "province": "Ghadamis",
        "timezone": "Africa/Tripoli"
    },
    {
        "name": "Hun",
        "country": "Libya",
        "province": "Al Jufrah",
        "timezone": "Africa/Tripoli"
    },
    {
        "name": "Birak",
        "country": "Libya",
        "province": "Ash Shati'",
        "timezone": "Africa/Tripoli"
    },
    {
        "name": "Ghat",
        "country": "Libya",
        "province": "Ghat",
        "timezone": "Africa/Tripoli"
    },
    {
        "name": "Marzuq",
        "country": "Libya",
        "province": "Murzuq",
        "timezone": "Africa/Tripoli"
    },
    {
        "name": "Ajdabiya",
        "country": "Libya",
        "province": "Ajdabiya",
        "timezone": "Africa/Tripoli"
    },
    {
        "name": "Awjilah",
        "country": "Libya",
        "province": "Ajdabiya",
        "timezone": "Africa/Tripoli"
    },
    {
        "name": "Surt",
        "country": "Libya",
        "province": "Surt",
        "timezone": "Africa/Tripoli"
    },
    {
        "name": "Darnah",
        "country": "Libya",
        "province": "Al Qubbah",
        "timezone": "Africa/Tripoli"
    },
    {
        "name": "Tubruq",
        "country": "Libya",
        "province": "Al Butnan",
        "timezone": "Africa/Tripoli"
    },
    {
        "name": "Al Jawf",
        "country": "Libya",
        "province": "Al Kufrah",
        "timezone": "Africa/Tripoli"
    },
    {
        "name": "Tmassah",
        "country": "Libya",
        "province": "Murzuq",
        "timezone": "Africa/Tripoli"
    },
    {
        "name": "Misratah",
        "country": "Libya",
        "province": "Misratah",
        "timezone": "Africa/Tripoli"
    },
    {
        "name": "Zuwarah",
        "country": "Libya",
        "province": "An Nuqat al Khams",
        "timezone": "Africa/Tripoli"
    },
    {
        "name": "Sabha",
        "country": "Libya",
        "province": "Sabha",
        "timezone": "Africa/Tripoli"
    },
    {
        "name": "Banghazi",
        "country": "Libya",
        "province": "Benghazi",
        "timezone": "Africa/Tripoli"
    },
    {
        "name": "Tripoli",
        "country": "Libya",
        "province": "Tajura' wa an Nawahi al Arba",
        "timezone": "Africa/Tripoli"
    },
    {
        "name": "Vaduz",
        "country": "Liechtenstein",
        "province": "",
        "timezone": "Europe/Vaduz"
    },
    {
        "name": "Panevežys",
        "country": "Lithuania",
        "province": "Panevezio",
        "timezone": "Europe/Vilnius"
    },
    {
        "name": "Siauliai",
        "country": "Lithuania",
        "province": "Šiauliai",
        "timezone": "Europe/Vilnius"
    },
    {
        "name": "Klaipeda",
        "country": "Lithuania",
        "province": "Klaipedos",
        "timezone": "Europe/Vilnius"
    },
    {
        "name": "Kaunas",
        "country": "Lithuania",
        "province": "Kauno",
        "timezone": "Europe/Vilnius"
    },
    {
        "name": "Vilnius",
        "country": "Lithuania",
        "province": "Vilniaus",
        "timezone": "Europe/Vilnius"
    },
    {
        "name": "Diekirch",
        "country": "Luxembourg",
        "province": "Diekirch",
        "timezone": "Europe/Luxembourg"
    },
    {
        "name": "Grevenmacher",
        "country": "Luxembourg",
        "province": "Grevenmacher",
        "timezone": "Europe/Luxembourg"
    },
    {
        "name": "Luxembourg",
        "country": "Luxembourg",
        "province": "Luxembourg",
        "timezone": "Europe/Luxembourg"
    },
    {
        "name": "Macau",
        "country": "Macau S.A.R",
        "province": "",
        "timezone": "Asia/Macau"
    },
    {
        "name": "Tetovo",
        "country": "Macedonia",
        "province": "Tetovo",
        "timezone": "Europe/Skopje"
    },
    {
        "name": "Bitola",
        "country": "Macedonia",
        "province": "Bitola",
        "timezone": "Europe/Skopje"
    },
    {
        "name": "Skopje",
        "country": "Macedonia",
        "province": "Centar",
        "timezone": "Europe/Skopje"
    },
    {
        "name": "Sambava",
        "country": "Madagascar",
        "province": "Antsiranana",
        "timezone": "Indian/Antananarivo"
    },
    {
        "name": "Ambanja",
        "country": "Madagascar",
        "province": "Antsiranana",
        "timezone": "Indian/Antananarivo"
    },
    {
        "name": "Ihosy",
        "country": "Madagascar",
        "province": "Fianarantsoa",
        "timezone": "Indian/Antananarivo"
    },
    {
        "name": "Mandritsara",
        "country": "Madagascar",
        "province": "Mahajanga",
        "timezone": "Indian/Antananarivo"
    },
    {
        "name": "Besalampy",
        "country": "Madagascar",
        "province": "Mahajanga",
        "timezone": "Indian/Antananarivo"
    },
    {
        "name": "Marovoay",
        "country": "Madagascar",
        "province": "Mahajanga",
        "timezone": "Indian/Antananarivo"
    },
    {
        "name": "Antsohihy",
        "country": "Madagascar",
        "province": "Mahajanga",
        "timezone": "Indian/Antananarivo"
    },
    {
        "name": "Ambatondrazaka",
        "country": "Madagascar",
        "province": "Toamasina",
        "timezone": "Indian/Antananarivo"
    },
    {
        "name": "Bekiy",
        "country": "Madagascar",
        "province": "Toliary",
        "timezone": "Indian/Antananarivo"
    },
    {
        "name": "Manja",
        "country": "Madagascar",
        "province": "Toliary",
        "timezone": "Indian/Antananarivo"
    },
    {
        "name": "Miandrivazo",
        "country": "Madagascar",
        "province": "Toliary",
        "timezone": "Indian/Antananarivo"
    },
    {
        "name": "Antsirabe",
        "country": "Madagascar",
        "province": "Antananarivo",
        "timezone": "Indian/Antananarivo"
    },
    {
        "name": "Antalaha",
        "country": "Madagascar",
        "province": "Antsiranana",
        "timezone": "Indian/Antananarivo"
    },
    {
        "name": "Andoany",
        "country": "Madagascar",
        "province": "Antsiranana",
        "timezone": "Indian/Antananarivo"
    },
    {
        "name": "Farafangana",
        "country": "Madagascar",
        "province": "Fianarantsoa",
        "timezone": "Indian/Antananarivo"
    },
    {
        "name": "Mananjary",
        "country": "Madagascar",
        "province": "Fianarantsoa",
        "timezone": "Indian/Antananarivo"
    },
    {
        "name": "Maintirano",
        "country": "Madagascar",
        "province": "Mahajanga",
        "timezone": "Indian/Antananarivo"
    },
    {
        "name": "Toamasina",
        "country": "Madagascar",
        "province": "Toamasina",
        "timezone": "Indian/Antananarivo"
    },
    {
        "name": "Maroantsetra",
        "country": "Madagascar",
        "province": "Toamasina",
        "timezone": "Indian/Antananarivo"
    },
    {
        "name": "Tolanaro",
        "country": "Madagascar",
        "province": "Toliary",
        "timezone": "Indian/Antananarivo"
    },
    {
        "name": "Morombe",
        "country": "Madagascar",
        "province": "Toliary",
        "timezone": "Indian/Antananarivo"
    },
    {
        "name": "Androka",
        "country": "Madagascar",
        "province": "Toliary",
        "timezone": "Indian/Antananarivo"
    },
    {
        "name": "Morondava",
        "country": "Madagascar",
        "province": "Toliary",
        "timezone": "Indian/Antananarivo"
    },
    {
        "name": "Antsiranana",
        "country": "Madagascar",
        "province": "Antsiranana",
        "timezone": "Indian/Antananarivo"
    },
    {
        "name": "Fianarantsoa",
        "country": "Madagascar",
        "province": "Fianarantsoa",
        "timezone": "Indian/Antananarivo"
    },
    {
        "name": "Mahajanga",
        "country": "Madagascar",
        "province": "Mahajanga",
        "timezone": "Indian/Antananarivo"
    },
    {
        "name": "Toliara",
        "country": "Madagascar",
        "province": "Toliary",
        "timezone": "Indian/Antananarivo"
    },
    {
        "name": "Antananarivo",
        "country": "Madagascar",
        "province": "Antananarivo",
        "timezone": "Indian/Antananarivo"
    },
    {
        "name": "Mzimba",
        "country": "Malawi",
        "province": "Mzimba",
        "timezone": "Africa/Blantyre"
    },
    {
        "name": "Machinga",
        "country": "Malawi",
        "province": "Machinga",
        "timezone": "Africa/Blantyre"
    },
    {
        "name": "Dedza",
        "country": "Malawi",
        "province": "Dedza",
        "timezone": "Africa/Blantyre"
    },
    {
        "name": "Mchinji",
        "country": "Malawi",
        "province": "Mchinji",
        "timezone": "Africa/Blantyre"
    },
    {
        "name": "Ntcheu",
        "country": "Malawi",
        "province": "Ntcheu",
        "timezone": "Africa/Blantyre"
    },
    {
        "name": "Chiradzulu",
        "country": "Malawi",
        "province": "Chiradzulu",
        "timezone": "Africa/Blantyre"
    },
    {
        "name": "Nsanje",
        "country": "Malawi",
        "province": "Nsanje",
        "timezone": "Africa/Blantyre"
    },
    {
        "name": "Mwanza",
        "country": "Malawi",
        "province": "Mwanza",
        "timezone": "Africa/Blantyre"
    },
    {
        "name": "Mulanje",
        "country": "Malawi",
        "province": "Mulanje",
        "timezone": "Africa/Blantyre"
    },
    {
        "name": "Karonga",
        "country": "Malawi",
        "province": "Chitipa",
        "timezone": "Africa/Blantyre"
    },
    {
        "name": "Chitipa",
        "country": "Malawi",
        "province": "Chitipa",
        "timezone": "Africa/Blantyre"
    },
    {
        "name": "Nkhata Bay",
        "country": "Malawi",
        "province": "Nkhata Bay",
        "timezone": "Africa/Blantyre"
    },
    {
        "name": "Nkhotakota",
        "country": "Malawi",
        "province": "Nkhotakota",
        "timezone": "Africa/Blantyre"
    },
    {
        "name": "Mangochi",
        "country": "Malawi",
        "province": "Mangochi",
        "timezone": "Africa/Blantyre"
    },
    {
        "name": "Salima",
        "country": "Malawi",
        "province": "Salima",
        "timezone": "Africa/Blantyre"
    },
    {
        "name": "Chiromo",
        "country": "Malawi",
        "province": "Nsanje",
        "timezone": "Africa/Blantyre"
    },
    {
        "name": "Zomba",
        "country": "Malawi",
        "province": "Zomba",
        "timezone": "Africa/Blantyre"
    },
    {
        "name": "Mzuzu",
        "country": "Malawi",
        "province": "Mzimba",
        "timezone": "Africa/Blantyre"
    },
    {
        "name": "Blantyre",
        "country": "Malawi",
        "province": "Blantyre",
        "timezone": "Africa/Blantyre"
    },
    {
        "name": "Lilongwe",
        "country": "Malawi",
        "province": "Lilongwe",
        "timezone": "Africa/Blantyre"
    },
    {
        "name": "Kangar",
        "country": "Malaysia",
        "province": "Perlis",
        "timezone": "Asia/Kuala_Lumpur"
    },
    {
        "name": "Kuala Lipis",
        "country": "Malaysia",
        "province": "Pahang",
        "timezone": "Asia/Kuala_Lumpur"
    },
    {
        "name": "Shah Alam",
        "country": "Malaysia",
        "province": "Selangor",
        "timezone": "Asia/Kuala_Lumpur"
    },
    {
        "name": "Teluk Intan",
        "country": "Malaysia",
        "province": "Perak",
        "timezone": "Asia/Kuala_Lumpur"
    },
    {
        "name": "Butterworth",
        "country": "Malaysia",
        "province": "Pulau Pinang",
        "timezone": "Asia/Kuala_Lumpur"
    },
    {
        "name": "Sungai Petani",
        "country": "Malaysia",
        "province": "Kedah",
        "timezone": "Asia/Kuala_Lumpur"
    },
    {
        "name": "Alor Setar",
        "country": "Malaysia",
        "province": "Kedah",
        "timezone": "Asia/Kuala_Lumpur"
    },
    {
        "name": "Muar",
        "country": "Malaysia",
        "province": "Johor",
        "timezone": "Asia/Kuala_Lumpur"
    },
    {
        "name": "Batu Pahat",
        "country": "Malaysia",
        "province": "Johor",
        "timezone": "Asia/Kuala_Lumpur"
    },
    {
        "name": "Keluang",
        "country": "Malaysia",
        "province": "Johor",
        "timezone": "Asia/Kuala_Lumpur"
    },
    {
        "name": "Seremban",
        "country": "Malaysia",
        "province": "Negeri Sembilan",
        "timezone": "Asia/Kuala_Lumpur"
    },
    {
        "name": "Raub",
        "country": "Malaysia",
        "province": "Pahang",
        "timezone": "Asia/Kuala_Lumpur"
    },
    {
        "name": "Chukai",
        "country": "Malaysia",
        "province": "Trengganu",
        "timezone": "Asia/Kuala_Lumpur"
    },
    {
        "name": "Kuala Terengganu",
        "country": "Malaysia",
        "province": "Trengganu",
        "timezone": "Asia/Kuala_Lumpur"
    },
    {
        "name": "Lahad Datu",
        "country": "Malaysia",
        "province": "Sabah",
        "timezone": "Asia/Kuching"
    },
    {
        "name": "Bintulu",
        "country": "Malaysia",
        "province": "Sarawak",
        "timezone": "Asia/Kuching"
    },
    {
        "name": "Miri",
        "country": "Malaysia",
        "province": "Sarawak",
        "timezone": "Asia/Kuching"
    },
    {
        "name": "Johor Bahru",
        "country": "Malaysia",
        "province": "Johor",
        "timezone": "Asia/Kuala_Lumpur"
    },
    {
        "name": "Kelang",
        "country": "Malaysia",
        "province": "Selangor",
        "timezone": "Asia/Kuala_Lumpur"
    },
    {
        "name": "Taiping",
        "country": "Malaysia",
        "province": "Perak",
        "timezone": "Asia/Kuala_Lumpur"
    },
    {
        "name": "Ipoh",
        "country": "Malaysia",
        "province": "Perak",
        "timezone": "Asia/Kuala_Lumpur"
    },
    {
        "name": "Kota Baharu",
        "country": "Malaysia",
        "province": "Kelantan",
        "timezone": "Asia/Kuala_Lumpur"
    },
    {
        "name": "Malacca",
        "country": "Malaysia",
        "province": "Melaka",
        "timezone": "Asia/Kuala_Lumpur"
    },
    {
        "name": "Kuantan",
        "country": "Malaysia",
        "province": "Pahang",
        "timezone": "Asia/Kuala_Lumpur"
    },
    {
        "name": "Tawau",
        "country": "Malaysia",
        "province": "Sabah",
        "timezone": "Asia/Kuching"
    },
    {
        "name": "Sandakan",
        "country": "Malaysia",
        "province": "Sabah",
        "timezone": "Asia/Kuching"
    },
    {
        "name": "Kota Kinabalu",
        "country": "Malaysia",
        "province": "Sabah",
        "timezone": "Asia/Kuching"
    },
    {
        "name": "Sibu",
        "country": "Malaysia",
        "province": "Sarawak",
        "timezone": "Asia/Kuching"
    },
    {
        "name": "George Town",
        "country": "Malaysia",
        "province": "Pulau Pinang",
        "timezone": "Asia/Kuala_Lumpur"
    },
    {
        "name": "Kuching",
        "country": "Malaysia",
        "province": "Sarawak",
        "timezone": "Asia/Kuching"
    },
    {
        "name": "Putrajaya",
        "country": "Malaysia",
        "province": "Selangor",
        "timezone": "Asia/Kuala_Lumpur"
    },
    {
        "name": "Kuala Lumpur",
        "country": "Malaysia",
        "province": "Selangor",
        "timezone": "Asia/Kuala_Lumpur"
    },
    {
        "name": "Male",
        "country": "Maldives",
        "province": "",
        "timezone": "Indian/Maldives"
    },
    {
        "name": "Goundam",
        "country": "Mali",
        "province": "Timbuktu",
        "timezone": "Africa/Bamako"
    },
    {
        "name": "Aguelhok",
        "country": "Mali",
        "province": "Kidal",
        "timezone": "Africa/Bamako"
    },
    {
        "name": "Bourem",
        "country": "Mali",
        "province": "Gao",
        "timezone": "Africa/Bamako"
    },
    {
        "name": "Kati",
        "country": "Mali",
        "province": "Bamako",
        "timezone": "Africa/Bamako"
    },
    {
        "name": "Banamba",
        "country": "Mali",
        "province": "Bamako",
        "timezone": "Africa/Bamako"
    },
    {
        "name": "Kangaba",
        "country": "Mali",
        "province": "Bamako",
        "timezone": "Africa/Bamako"
    },
    {
        "name": "Nioro du Sahel",
        "country": "Mali",
        "province": "Kayes",
        "timezone": "Africa/Bamako"
    },
    {
        "name": "Bafoulabe",
        "country": "Mali",
        "province": "Kayes",
        "timezone": "Africa/Bamako"
    },
    {
        "name": "Satadougou",
        "country": "Mali",
        "province": "Kayes",
        "timezone": "Africa/Bamako"
    },
    {
        "name": "Yelimane",
        "country": "Mali",
        "province": "Kayes",
        "timezone": "Africa/Bamako"
    },
    {
        "name": "Kita",
        "country": "Mali",
        "province": "Kayes",
        "timezone": "Africa/Bamako"
    },
    {
        "name": "Koutiala",
        "country": "Mali",
        "province": "Sikasso",
        "timezone": "Africa/Bamako"
    },
    {
        "name": "Sikasso",
        "country": "Mali",
        "province": "Sikasso",
        "timezone": "Africa/Bamako"
    },
    {
        "name": "Bougouni",
        "country": "Mali",
        "province": "Sikasso",
        "timezone": "Africa/Bamako"
    },
    {
        "name": "Markala",
        "country": "Mali",
        "province": "Ségou",
        "timezone": "Africa/Bamako"
    },
    {
        "name": "Sokolo",
        "country": "Mali",
        "province": "Ségou",
        "timezone": "Africa/Bamako"
    },
    {
        "name": "San",
        "country": "Mali",
        "province": "Ségou",
        "timezone": "Africa/Bamako"
    },
    {
        "name": "Taoudenni",
        "country": "Mali",
        "province": "Timbuktu",
        "timezone": "Africa/Bamako"
    },
    {
        "name": "Araouane",
        "country": "Mali",
        "province": "Timbuktu",
        "timezone": "Africa/Bamako"
    },
    {
        "name": "Tessalit",
        "country": "Mali",
        "province": "Kidal",
        "timezone": "Africa/Bamako"
    },
    {
        "name": "Menaka",
        "country": "Mali",
        "province": "Gao",
        "timezone": "Africa/Bamako"
    },
    {
        "name": "Nara",
        "country": "Mali",
        "province": "Bamako",
        "timezone": "Africa/Bamako"
    },
    {
        "name": "Koulikoro",
        "country": "Mali",
        "province": "Bamako",
        "timezone": "Africa/Bamako"
    },
    {
        "name": "Mopti",
        "country": "Mali",
        "province": "Mopti",
        "timezone": "Africa/Bamako"
    },
    {
        "name": "Gao",
        "country": "Mali",
        "province": "Gao",
        "timezone": "Africa/Bamako"
    },
    {
        "name": "Kayes",
        "country": "Mali",
        "province": "Kayes",
        "timezone": "Africa/Bamako"
    },
    {
        "name": "Segou",
        "country": "Mali",
        "province": "Ségou",
        "timezone": "Africa/Bamako"
    },
    {
        "name": "Timbuktu",
        "country": "Mali",
        "province": "Timbuktu",
        "timezone": "Africa/Bamako"
    },
    {
        "name": "Bamako",
        "country": "Mali",
        "province": "Bamako",
        "timezone": "Africa/Bamako"
    },
    {
        "name": "Djenne",
        "country": "Mali",
        "province": "Mopti",
        "timezone": "Africa/Bamako"
    },
    {
        "name": "Valletta",
        "country": "Malta",
        "province": "",
        "timezone": "Europe/Malta"
    },
    {
        "name": "Majuro",
        "country": "Marshall Islands",
        "province": "",
        "timezone": "Pacific/Majuro"
    },
    {
        "name": "Fderik",
        "country": "Mauritania",
        "province": "Tiris Zemmour",
        "timezone": "Africa/Nouakchott"
    },
    {
        "name": "Aleg",
        "country": "Mauritania",
        "province": "Brakna",
        "timezone": "Africa/Nouakchott"
    },
    {
        "name": "Akjoujt",
        "country": "Mauritania",
        "province": "Inchiri",
        "timezone": "Africa/Nouakchott"
    },
    {
        "name": "Zouirat",
        "country": "Mauritania",
        "province": "Tiris Zemmour",
        "timezone": "Africa/Nouakchott"
    },
    {
        "name": "Chegga",
        "country": "Mauritania",
        "province": "Tiris Zemmour",
        "timezone": "Africa/Nouakchott"
    },
    {
        "name": "Magta Lajar",
        "country": "Mauritania",
        "province": "Brakna",
        "timezone": "Africa/Nouakchott"
    },
    {
        "name": "Bogue",
        "country": "Mauritania",
        "province": "Brakna",
        "timezone": "Africa/Nouakchott"
    },
    {
        "name": "Boutilimit",
        "country": "Mauritania",
        "province": "Trarza",
        "timezone": "Africa/Nouakchott"
    },
    {
        "name": "Selibaby",
        "country": "Mauritania",
        "province": "Guidimaka",
        "timezone": "Africa/Nouakchott"
    },
    {
        "name": "Timbedra",
        "country": "Mauritania",
        "province": "Hodh ech Chargui",
        "timezone": "Africa/Nouakchott"
    },
    {
        "name": "Nema",
        "country": "Mauritania",
        "province": "Hodh ech Chargui",
        "timezone": "Africa/Nouakchott"
    },
    {
        "name": "Saint-Louis",
        "country": "Mauritania",
        "province": "Trarza",
        "timezone": "Africa/Dakar"
    },
    {
        "name": "Tidjikdja",
        "country": "Mauritania",
        "province": "Tagant",
        "timezone": "Africa/Nouakchott"
    },
    {
        "name": "Bir Mogrein",
        "country": "Mauritania",
        "province": "Tiris Zemmour",
        "timezone": "Africa/Nouakchott"
    },
    {
        "name": "Rosso",
        "country": "Mauritania",
        "province": "Trarza",
        "timezone": "Africa/Nouakchott"
    },
    {
        "name": "Kiffa",
        "country": "Mauritania",
        "province": "Assaba",
        "timezone": "Africa/Nouakchott"
    },
    {
        "name": "Nouadhibou",
        "country": "Mauritania",
        "province": "Dakhlet Nouadhibou",
        "timezone": "Africa/Nouakchott"
    },
    {
        "name": "Ayoun el Atrous",
        "country": "Mauritania",
        "province": "Hodh el Gharbi",
        "timezone": "Africa/Nouakchott"
    },
    {
        "name": "Nouakchott",
        "country": "Mauritania",
        "province": "Nouakchott",
        "timezone": "Africa/Nouakchott"
    },
    {
        "name": "Atar",
        "country": "Mauritania",
        "province": "Adrar",
        "timezone": "Africa/Nouakchott"
    },
    {
        "name": "Curepipe",
        "country": "Mauritius",
        "province": "",
        "timezone": "Indian/Mauritius"
    },
    {
        "name": "Port Louis",
        "country": "Mauritius",
        "province": "",
        "timezone": "Indian/Mauritius"
    },
    {
        "name": "Vicente Guerrero",
        "country": "Mexico",
        "province": "Baja California",
        "timezone": "America/Tijuana"
    },
    {
        "name": "Loreto",
        "country": "Mexico",
        "province": "Baja California Sur",
        "timezone": "America/Mazatlan"
    },
    {
        "name": "Ciudad Constitucion",
        "country": "Mexico",
        "province": "Baja California Sur",
        "timezone": "America/Mazatlan"
    },
    {
        "name": "Allende",
        "country": "Mexico",
        "province": "Coahuila",
        "timezone": "America/Monterrey"
    },
    {
        "name": "Nueva Rosita",
        "country": "Mexico",
        "province": "Coahuila",
        "timezone": "America/Monterrey"
    },
    {
        "name": "Hidalgo del Parral",
        "country": "Mexico",
        "province": "Chihuahua",
        "timezone": "America/Chihuahua"
    },
    {
        "name": "Ascension",
        "country": "Mexico",
        "province": "Chihuahua",
        "timezone": "America/Ojinaga"
    },
    {
        "name": "Gomez Palacio",
        "country": "Mexico",
        "province": "Durango",
        "timezone": "America/Monterrey"
    },
    {
        "name": "Canatlan",
        "country": "Mexico",
        "province": "Durango",
        "timezone": "America/Monterrey"
    },
    {
        "name": "Villa Union",
        "country": "Mexico",
        "province": "Sinaloa",
        "timezone": "America/Mazatlan"
    },
    {
        "name": "Altata",
        "country": "Mexico",
        "province": "Sinaloa",
        "timezone": "America/Mazatlan"
    },
    {
        "name": "Esperanza",
        "country": "Mexico",
        "province": "Sonora",
        "timezone": "America/Hermosillo"
    },
    {
        "name": "Magdalena",
        "country": "Mexico",
        "province": "Sonora",
        "timezone": "America/Hermosillo"
    },
    {
        "name": "Nacozari Viejo",
        "country": "Mexico",
        "province": "Sonora",
        "timezone": "America/Hermosillo"
    },
    {
        "name": "Villanueva",
        "country": "Mexico",
        "province": "Zacatecas",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Montemorelos",
        "country": "Mexico",
        "province": "Nuevo León",
        "timezone": "America/Monterrey"
    },
    {
        "name": "Sabinas Hidalgo",
        "country": "Mexico",
        "province": "Nuevo León",
        "timezone": "America/Monterrey"
    },
    {
        "name": "Cardenas",
        "country": "Mexico",
        "province": "San Luis Potosí",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Ciudad Valles",
        "country": "Mexico",
        "province": "San Luis Potosí",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Rio Verde",
        "country": "Mexico",
        "province": "San Luis Potosí",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Ciudad Mante",
        "country": "Mexico",
        "province": "Tamaulipas",
        "timezone": "America/Monterrey"
    },
    {
        "name": "Reynosa",
        "country": "Mexico",
        "province": "Tamaulipas",
        "timezone": "America/Matamoros"
    },
    {
        "name": "Ciudad Madero",
        "country": "Mexico",
        "province": "Tamaulipas",
        "timezone": "America/Monterrey"
    },
    {
        "name": "Autlan",
        "country": "Mexico",
        "province": "Jalisco",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Ciudad Hidalgo",
        "country": "Mexico",
        "province": "Michoacán",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Apatzingan",
        "country": "Mexico",
        "province": "Michoacán",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Santiago Ixcuintla",
        "country": "Mexico",
        "province": "Nayarit",
        "timezone": "America/Mazatlan"
    },
    {
        "name": "Juchitan",
        "country": "Mexico",
        "province": "Oaxaca",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Miahuatlan",
        "country": "Mexico",
        "province": "Oaxaca",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Atlixco",
        "country": "Mexico",
        "province": "Puebla",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Acatlan",
        "country": "Mexico",
        "province": "Puebla",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Paraiso",
        "country": "Mexico",
        "province": "Tabasco",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Balancan",
        "country": "Mexico",
        "province": "Tabasco",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Tlaxcala",
        "country": "Mexico",
        "province": "Tlaxcala",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Irapuato",
        "country": "Mexico",
        "province": "Guanajuato",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Celaya",
        "country": "Mexico",
        "province": "Guanajuato",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Chilpancingo",
        "country": "Mexico",
        "province": "Guerrero",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Iguala",
        "country": "Mexico",
        "province": "Guerrero",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Tecpan",
        "country": "Mexico",
        "province": "Guerrero",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Atoyac",
        "country": "Mexico",
        "province": "Guerrero",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Nezahualcoyotl",
        "country": "Mexico",
        "province": "México",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "San Juan del Rio",
        "country": "Mexico",
        "province": "Querétaro",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Jaltipan",
        "country": "Mexico",
        "province": "Veracruz",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Orizaba",
        "country": "Mexico",
        "province": "Veracruz",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Xalapa",
        "country": "Mexico",
        "province": "Veracruz",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Nautla",
        "country": "Mexico",
        "province": "Veracruz",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "San Cristobal de Las Casas",
        "country": "Mexico",
        "province": "Chiapas",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Escuintla",
        "country": "Mexico",
        "province": "Chiapas",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Motul",
        "country": "Mexico",
        "province": "Yucatán",
        "timezone": "America/Merida"
    },
    {
        "name": "Tekax",
        "country": "Mexico",
        "province": "Yucatán",
        "timezone": "America/Merida"
    },
    {
        "name": "Peto",
        "country": "Mexico",
        "province": "Yucatán",
        "timezone": "America/Merida"
    },
    {
        "name": "Halacho",
        "country": "Mexico",
        "province": "Yucatán",
        "timezone": "America/Merida"
    },
    {
        "name": "San Quintin",
        "country": "Mexico",
        "province": "Baja California",
        "timezone": "America/Tijuana"
    },
    {
        "name": "Punta Prieta",
        "country": "Mexico",
        "province": "Baja California",
        "timezone": "America/Tijuana"
    },
    {
        "name": "San Felipe",
        "country": "Mexico",
        "province": "Baja California",
        "timezone": "America/Tijuana"
    },
    {
        "name": "Santa Rosalia",
        "country": "Mexico",
        "province": "Baja California Sur",
        "timezone": "America/Mazatlan"
    },
    {
        "name": "Guerrero Negro",
        "country": "Mexico",
        "province": "Baja California Sur",
        "timezone": "America/Mazatlan"
    },
    {
        "name": "Piedras Negras",
        "country": "Mexico",
        "province": "Coahuila",
        "timezone": "America/Matamoros"
    },
    {
        "name": "San Pedro de las Colonias",
        "country": "Mexico",
        "province": "Coahuila",
        "timezone": "America/Monterrey"
    },
    {
        "name": "Sierra Mojada",
        "country": "Mexico",
        "province": "Coahuila",
        "timezone": "America/Matamoros"
    },
    {
        "name": "Parras",
        "country": "Mexico",
        "province": "Coahuila",
        "timezone": "America/Monterrey"
    },
    {
        "name": "Cuauhtemoc",
        "country": "Mexico",
        "province": "Chihuahua",
        "timezone": "America/Chihuahua"
    },
    {
        "name": "Nuevo Casas Grandes",
        "country": "Mexico",
        "province": "Chihuahua",
        "timezone": "America/Chihuahua"
    },
    {
        "name": "Ojinaga",
        "country": "Mexico",
        "province": "Chihuahua",
        "timezone": "America/Ojinaga"
    },
    {
        "name": "Villa Ahumada",
        "country": "Mexico",
        "province": "Chihuahua",
        "timezone": "America/Chihuahua"
    },
    {
        "name": "Santa Barbara",
        "country": "Mexico",
        "province": "Chihuahua",
        "timezone": "America/Chihuahua"
    },
    {
        "name": "Ciudad Camargo",
        "country": "Mexico",
        "province": "Chihuahua",
        "timezone": "America/Chihuahua"
    },
    {
        "name": "Cuencame",
        "country": "Mexico",
        "province": "Durango",
        "timezone": "America/Monterrey"
    },
    {
        "name": "Papasquiaro",
        "country": "Mexico",
        "province": "Durango",
        "timezone": "America/Monterrey"
    },
    {
        "name": "Escuinapa",
        "country": "Mexico",
        "province": "Sinaloa",
        "timezone": "America/Mazatlan"
    },
    {
        "name": "Guamuchil",
        "country": "Mexico",
        "province": "Sinaloa",
        "timezone": "America/Mazatlan"
    },
    {
        "name": "Guasave",
        "country": "Mexico",
        "province": "Sinaloa",
        "timezone": "America/Mazatlan"
    },
    {
        "name": "El Fuerte",
        "country": "Mexico",
        "province": "Sinaloa",
        "timezone": "America/Mazatlan"
    },
    {
        "name": "Eldorado",
        "country": "Mexico",
        "province": "Sinaloa",
        "timezone": "America/Mazatlan"
    },
    {
        "name": "La Cruz",
        "country": "Mexico",
        "province": "Sinaloa",
        "timezone": "America/Mazatlan"
    },
    {
        "name": "Agua Prieta",
        "country": "Mexico",
        "province": "Sonora",
        "timezone": "America/Hermosillo"
    },
    {
        "name": "Ciudad Obregon",
        "country": "Mexico",
        "province": "Sonora",
        "timezone": "America/Hermosillo"
    },
    {
        "name": "Navajoa",
        "country": "Mexico",
        "province": "Sonora",
        "timezone": "America/Hermosillo"
    },
    {
        "name": "Caborca",
        "country": "Mexico",
        "province": "Sonora",
        "timezone": "America/Hermosillo"
    },
    {
        "name": "Mazatlán",
        "country": "Mexico",
        "province": "Sonora",
        "timezone": "America/Hermosillo"
    },
    {
        "name": "Cananea",
        "country": "Mexico",
        "province": "Sonora",
        "timezone": "America/Hermosillo"
    },
    {
        "name": "Huatabampo",
        "country": "Mexico",
        "province": "Sonora",
        "timezone": "America/Hermosillo"
    },
    {
        "name": "Zacatecas",
        "country": "Mexico",
        "province": "Zacatecas",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Juan Aldama",
        "country": "Mexico",
        "province": "Zacatecas",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Valparaiso",
        "country": "Mexico",
        "province": "Zacatecas",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Fresnillo",
        "country": "Mexico",
        "province": "Zacatecas",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Linares",
        "country": "Mexico",
        "province": "Nuevo León",
        "timezone": "America/Monterrey"
    },
    {
        "name": "Matehuala",
        "country": "Mexico",
        "province": "San Luis Potosí",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Tamuin",
        "country": "Mexico",
        "province": "San Luis Potosí",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Tamazunchale",
        "country": "Mexico",
        "province": "San Luis Potosí",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Tula",
        "country": "Mexico",
        "province": "Tamaulipas",
        "timezone": "America/Monterrey"
    },
    {
        "name": "Aldama",
        "country": "Mexico",
        "province": "Tamaulipas",
        "timezone": "America/Monterrey"
    },
    {
        "name": "San Fernando",
        "country": "Mexico",
        "province": "Tamaulipas",
        "timezone": "America/Monterrey"
    },
    {
        "name": "Tecoman",
        "country": "Mexico",
        "province": "Colima",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Puerto Vallarta",
        "country": "Mexico",
        "province": "Jalisco",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "La Barca",
        "country": "Mexico",
        "province": "Jalisco",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Ciudad Guzman",
        "country": "Mexico",
        "province": "Jalisco",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Lagos de Moreno",
        "country": "Mexico",
        "province": "Jalisco",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Morelia",
        "country": "Mexico",
        "province": "Michoacán",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Lazaro Cardenas",
        "country": "Mexico",
        "province": "Michoacán",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Zamora",
        "country": "Mexico",
        "province": "Michoacán",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Coalcoman",
        "country": "Mexico",
        "province": "Michoacán",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Uruapan",
        "country": "Mexico",
        "province": "Michoacán",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Tuxpan",
        "country": "Mexico",
        "province": "Nayarit",
        "timezone": "America/Mazatlan"
    },
    {
        "name": "Tepic",
        "country": "Mexico",
        "province": "Nayarit",
        "timezone": "America/Mazatlan"
    },
    {
        "name": "Compostela",
        "country": "Mexico",
        "province": "Nayarit",
        "timezone": "America/Mazatlan"
    },
    {
        "name": "Tecuala",
        "country": "Mexico",
        "province": "Nayarit",
        "timezone": "America/Mazatlan"
    },
    {
        "name": "Ciudad del Carmen",
        "country": "Mexico",
        "province": "Campeche",
        "timezone": "America/Merida"
    },
    {
        "name": "Champoton",
        "country": "Mexico",
        "province": "Campeche",
        "timezone": "America/Merida"
    },
    {
        "name": "Salina Cruz",
        "country": "Mexico",
        "province": "Oaxaca",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Puerto Escondido",
        "country": "Mexico",
        "province": "Oaxaca",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Pochutla",
        "country": "Mexico",
        "province": "Oaxaca",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Mitla",
        "country": "Mexico",
        "province": "Oaxaca",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Tlaxiaco",
        "country": "Mexico",
        "province": "Oaxaca",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Huajuapan de Leon",
        "country": "Mexico",
        "province": "Oaxaca",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Tehuacan",
        "country": "Mexico",
        "province": "Puebla",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Teziutlan",
        "country": "Mexico",
        "province": "Puebla",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Frontera",
        "country": "Mexico",
        "province": "Tabasco",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Tenosique",
        "country": "Mexico",
        "province": "Tabasco",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Salamanca",
        "country": "Mexico",
        "province": "Guanajuato",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Guanajuato",
        "country": "Mexico",
        "province": "Guanajuato",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Taxco",
        "country": "Mexico",
        "province": "Guerrero",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Ayutla",
        "country": "Mexico",
        "province": "Guerrero",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Ciudad Altamirano",
        "country": "Mexico",
        "province": "Guerrero",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Petatlan",
        "country": "Mexico",
        "province": "Guerrero",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Pachuca",
        "country": "Mexico",
        "province": "Hidalgo",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Toluca",
        "country": "Mexico",
        "province": "México",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Zumpango",
        "country": "Mexico",
        "province": "México",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Minatitlan",
        "country": "Mexico",
        "province": "Veracruz",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Coatzacoalcos",
        "country": "Mexico",
        "province": "Veracruz",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Poza Rica de Hidalgo",
        "country": "Mexico",
        "province": "Veracruz",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Cordoba",
        "country": "Mexico",
        "province": "Veracruz",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Santiago Tuxtla",
        "country": "Mexico",
        "province": "Veracruz",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Tuxpam",
        "country": "Mexico",
        "province": "Veracruz",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Panuco",
        "country": "Mexico",
        "province": "Veracruz",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Pijijiapan",
        "country": "Mexico",
        "province": "Chiapas",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Isla Mujeres",
        "country": "Mexico",
        "province": "Quintana Roo",
        "timezone": "America/Cancun"
    },
    {
        "name": "Felipe Carrillo Puerto",
        "country": "Mexico",
        "province": "Quintana Roo",
        "timezone": "America/Cancun"
    },
    {
        "name": "Tizimin",
        "country": "Mexico",
        "province": "Yucatán",
        "timezone": "America/Merida"
    },
    {
        "name": "Valladolid",
        "country": "Mexico",
        "province": "Yucatán",
        "timezone": "America/Merida"
    },
    {
        "name": "Izamal",
        "country": "Mexico",
        "province": "Yucatán",
        "timezone": "America/Merida"
    },
    {
        "name": "Ticul",
        "country": "Mexico",
        "province": "Yucatán",
        "timezone": "America/Merida"
    },
    {
        "name": "Ensenada",
        "country": "Mexico",
        "province": "Baja California",
        "timezone": "America/Tijuana"
    },
    {
        "name": "Saltillo",
        "country": "Mexico",
        "province": "Coahuila",
        "timezone": "America/Monterrey"
    },
    {
        "name": "Ciudad Juárez",
        "country": "Mexico",
        "province": "Chihuahua",
        "timezone": "America/Ojinaga"
    },
    {
        "name": "Delicias",
        "country": "Mexico",
        "province": "Chihuahua",
        "timezone": "America/Chihuahua"
    },
    {
        "name": "Durango",
        "country": "Mexico",
        "province": "Durango",
        "timezone": "America/Monterrey"
    },
    {
        "name": "Los Mochis",
        "country": "Mexico",
        "province": "Sinaloa",
        "timezone": "America/Mazatlan"
    },
    {
        "name": "Ciudad Victoria",
        "country": "Mexico",
        "province": "Tamaulipas",
        "timezone": "America/Monterrey"
    },
    {
        "name": "Aguascalientes",
        "country": "Mexico",
        "province": "Aguascalientes",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Manzanillo",
        "country": "Mexico",
        "province": "Colima",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Tehuantepec",
        "country": "Mexico",
        "province": "Oaxaca",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Villahermosa",
        "country": "Mexico",
        "province": "Tabasco",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Cuernavaca",
        "country": "Mexico",
        "province": "Morelos",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Queretaro",
        "country": "Mexico",
        "province": "Querétaro",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Tapachula",
        "country": "Mexico",
        "province": "Chiapas",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Chetumal",
        "country": "Mexico",
        "province": "Quintana Roo",
        "timezone": "America/Cancun"
    },
    {
        "name": "Progreso",
        "country": "Mexico",
        "province": "Yucatán",
        "timezone": "America/Merida"
    },
    {
        "name": "Cabo San Lucas",
        "country": "Mexico",
        "province": "Baja California Sur",
        "timezone": "America/Mazatlan"
    },
    {
        "name": "Monclova",
        "country": "Mexico",
        "province": "Coahuila",
        "timezone": "America/Monterrey"
    },
    {
        "name": "Ometepec",
        "country": "Mexico",
        "province": "Guerrero",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Cozumel",
        "country": "Mexico",
        "province": "Quintana Roo",
        "timezone": "America/Cancun"
    },
    {
        "name": "Mexicali",
        "country": "Mexico",
        "province": "Baja California",
        "timezone": "America/Tijuana"
    },
    {
        "name": "La Paz",
        "country": "Mexico",
        "province": "Baja California Sur",
        "timezone": "America/Mazatlan"
    },
    {
        "name": "Torreon",
        "country": "Mexico",
        "province": "Coahuila",
        "timezone": "America/Monterrey"
    },
    {
        "name": "Culiacan",
        "country": "Mexico",
        "province": "Sinaloa",
        "timezone": "America/Mazatlan"
    },
    {
        "name": "Nogales",
        "country": "Mexico",
        "province": "Sonora",
        "timezone": "America/Hermosillo"
    },
    {
        "name": "Hermosillo",
        "country": "Mexico",
        "province": "Sonora",
        "timezone": "America/Hermosillo"
    },
    {
        "name": "Guaymas",
        "country": "Mexico",
        "province": "Sonora",
        "timezone": "America/Hermosillo"
    },
    {
        "name": "San Luis Potosi",
        "country": "Mexico",
        "province": "San Luis Potosí",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Matamoros",
        "country": "Mexico",
        "province": "Tamaulipas",
        "timezone": "America/Matamoros"
    },
    {
        "name": "Nuevo Laredo",
        "country": "Mexico",
        "province": "Tamaulipas",
        "timezone": "America/Matamoros"
    },
    {
        "name": "Colima",
        "country": "Mexico",
        "province": "Colima",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Campeche",
        "country": "Mexico",
        "province": "Campeche",
        "timezone": "America/Merida"
    },
    {
        "name": "Oaxaca",
        "country": "Mexico",
        "province": "Oaxaca",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Leon",
        "country": "Mexico",
        "province": "Guanajuato",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Tijuana",
        "country": "Mexico",
        "province": "Baja California",
        "timezone": "America/Tijuana"
    },
    {
        "name": "Chihuahua",
        "country": "Mexico",
        "province": "Chihuahua",
        "timezone": "America/Chihuahua"
    },
    {
        "name": "Mazatlan",
        "country": "Mexico",
        "province": "Sinaloa",
        "timezone": "America/Mazatlan"
    },
    {
        "name": "Tampico",
        "country": "Mexico",
        "province": "Tamaulipas",
        "timezone": "America/Monterrey"
    },
    {
        "name": "Acapulco",
        "country": "Mexico",
        "province": "Guerrero",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Veracruz",
        "country": "Mexico",
        "province": "Veracruz",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Tuxtla Gutierrez",
        "country": "Mexico",
        "province": "Chiapas",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Cancun",
        "country": "Mexico",
        "province": "Quintana Roo",
        "timezone": "America/Cancun"
    },
    {
        "name": "Merida",
        "country": "Mexico",
        "province": "Yucatán",
        "timezone": "America/Merida"
    },
    {
        "name": "Guadalajara",
        "country": "Mexico",
        "province": "Jalisco",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Puebla",
        "country": "Mexico",
        "province": "Puebla",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Monterrey",
        "country": "Mexico",
        "province": "Nuevo León",
        "timezone": "America/Monterrey"
    },
    {
        "name": "Mexico City",
        "country": "Mexico",
        "province": "Distrito Federal",
        "timezone": "America/Mexico_City"
    },
    {
        "name": "Dubasari",
        "country": "Moldova",
        "province": "Transnistria",
        "timezone": "Europe/Chisinau"
    },
    {
        "name": "Balti",
        "country": "Moldova",
        "province": "Balti",
        "timezone": "Europe/Chisinau"
    },
    {
        "name": "Cahul",
        "country": "Moldova",
        "province": "Cahul",
        "timezone": "Europe/Chisinau"
    },
    {
        "name": "Tiraspol",
        "country": "Moldova",
        "province": "Bender",
        "timezone": "Europe/Chisinau"
    },
    {
        "name": "Chisinau",
        "country": "Moldova",
        "province": "Chisinau",
        "timezone": "Europe/Chisinau"
    },
    {
        "name": "Monaco",
        "country": "Monaco",
        "province": "",
        "timezone": "Europe/Paris"
    },
    {
        "name": "Suchboatar",
        "country": "Mongolia",
        "province": "Selenge",
        "timezone": "Asia/Ulaanbaatar"
    },
    {
        "name": "Dzuunmod",
        "country": "Mongolia",
        "province": "Töv",
        "timezone": "Asia/Ulaanbaatar"
    },
    {
        "name": "Tsetserleg",
        "country": "Mongolia",
        "province": "Arhangay",
        "timezone": "Asia/Ulaanbaatar"
    },
    {
        "name": "Olgiy",
        "country": "Mongolia",
        "province": "Bayan-Ölgiy",
        "timezone": "Asia/Hovd"
    },
    {
        "name": "Ulaan-Uul",
        "country": "Mongolia",
        "province": "Dornogovi",
        "timezone": "Asia/Ulaanbaatar"
    },
    {
        "name": "Hodrogo",
        "country": "Mongolia",
        "province": "Dzavhan",
        "timezone": "Asia/Hovd"
    },
    {
        "name": "Buyant-Uhaa",
        "country": "Mongolia",
        "province": "Dornogovi",
        "timezone": "Asia/Ulaanbaatar"
    },
    {
        "name": "Ondorhaan",
        "country": "Mongolia",
        "province": "Hentiy",
        "timezone": "Asia/Ulaanbaatar"
    },
    {
        "name": "Bayankhongor",
        "country": "Mongolia",
        "province": "Bayanhongor",
        "timezone": "Asia/Ulaanbaatar"
    },
    {
        "name": "Uliastay",
        "country": "Mongolia",
        "province": "Dzavhan",
        "timezone": "Asia/Hovd"
    },
    {
        "name": "Altay",
        "country": "Mongolia",
        "province": "Govi-Altay",
        "timezone": "Asia/Hovd"
    },
    {
        "name": "Moron",
        "country": "Mongolia",
        "province": "Hövsgöl",
        "timezone": "Asia/Ulaanbaatar"
    },
    {
        "name": "Ulaangom",
        "country": "Mongolia",
        "province": "Uvs",
        "timezone": "Asia/Hovd"
    },
    {
        "name": "Bulgan",
        "country": "Mongolia",
        "province": "Bulgan",
        "timezone": "Asia/Ulaanbaatar"
    },
    {
        "name": "Mandalgovi",
        "country": "Mongolia",
        "province": "Dundgovi",
        "timezone": "Asia/Ulaanbaatar"
    },
    {
        "name": "Darhan",
        "country": "Mongolia",
        "province": "Selenge",
        "timezone": "Asia/Ulaanbaatar"
    },
    {
        "name": "Dzuunharaa",
        "country": "Mongolia",
        "province": "Selenge",
        "timezone": "Asia/Ulaanbaatar"
    },
    {
        "name": "Arvayheer",
        "country": "Mongolia",
        "province": "Övörhangay",
        "timezone": "Asia/Ulaanbaatar"
    },
    {
        "name": "Baruun Urt",
        "country": "Mongolia",
        "province": "Sühbaatar",
        "timezone": "Asia/Choibalsan"
    },
    {
        "name": "Dalandzadgad",
        "country": "Mongolia",
        "province": "Ömnögovi",
        "timezone": "Asia/Ulaanbaatar"
    },
    {
        "name": "Dund-Us",
        "country": "Mongolia",
        "province": "Hovd",
        "timezone": "Asia/Hovd"
    },
    {
        "name": "Choybalsan",
        "country": "Mongolia",
        "province": "Dornod",
        "timezone": "Asia/Choibalsan"
    },
    {
        "name": "Erdenet",
        "country": "Mongolia",
        "province": "Orhon",
        "timezone": "Asia/Ulaanbaatar"
    },
    {
        "name": "Ulaanbaatar",
        "country": "Mongolia",
        "province": "Ulaanbaatar",
        "timezone": "Asia/Ulaanbaatar"
    },
    {
        "name": "Podgorica",
        "country": "Montenegro",
        "province": "Podgorica",
        "timezone": "Europe/Podgorica"
    },
    {
        "name": "Ksar El Kebir",
        "country": "Morocco",
        "province": "Tanger - Tétouan",
        "timezone": "Africa/Casablanca"
    },
    {
        "name": "Larache",
        "country": "Morocco",
        "province": "Tanger - Tétouan",
        "timezone": "Africa/Casablanca"
    },
    {
        "name": "Taza",
        "country": "Morocco",
        "province": "Taza - Al Hoceima - Taounate",
        "timezone": "Africa/Casablanca"
    },
    {
        "name": "Ouezzane",
        "country": "Morocco",
        "province": "Gharb - Chrarda - Béni Hssen",
        "timezone": "Africa/Casablanca"
    },
    {
        "name": "Kenitra",
        "country": "Morocco",
        "province": "Gharb - Chrarda - Béni Hssen",
        "timezone": "Africa/Casablanca"
    },
    {
        "name": "Settat",
        "country": "Morocco",
        "province": "Chaouia - Ouardigha",
        "timezone": "Africa/Casablanca"
    },
    {
        "name": "Er Rachidia",
        "country": "Morocco",
        "province": "Meknès - Tafilalet",
        "timezone": "Africa/Casablanca"
    },
    {
        "name": "Meknes",
        "country": "Morocco",
        "province": "Meknès - Tafilalet",
        "timezone": "Africa/Casablanca"
    },
    {
        "name": "Tiznit",
        "country": "Morocco",
        "province": "Souss - Massa - Draâ",
        "timezone": "Africa/Casablanca"
    },
    {
        "name": "El Jadida",
        "country": "Morocco",
        "province": "Doukkala - Abda",
        "timezone": "Africa/Casablanca"
    },
    {
        "name": "Dawra",
        "country": "Morocco",
        "province": "Laâyoune - Boujdour - Sakia El Hamra",
        "timezone": "Africa/El_Aaiun"
    },
    {
        "name": "Lemsid",
        "country": "Morocco",
        "province": "Laâyoune - Boujdour - Sakia El Hamra",
        "timezone": "Africa/El_Aaiun"
    },
    {
        "name": "Tan Tan",
        "country": "Morocco",
        "province": "Guelmim - Es-Semara",
        "timezone": "Africa/Casablanca"
    },
    {
        "name": "Bir Anzarane",
        "country": "Morocco",
        "province": "Oued el Dahab",
        "timezone": "Africa/El_Aaiun"
    },
    {
        "name": "Tangier",
        "country": "Morocco",
        "province": "Tanger - Tétouan",
        "timezone": "Africa/Casablanca"
    },
    {
        "name": "Agadir",
        "country": "Morocco",
        "province": "Souss - Massa - Draâ",
        "timezone": "Africa/Casablanca"
    },
    {
        "name": "Goulimine",
        "country": "Morocco",
        "province": "Guelmim - Es-Semara",
        "timezone": "Africa/Casablanca"
    },
    {
        "name": "Smara",
        "country": "Morocco",
        "province": "Guelmim - Es-Semara",
        "timezone": "Africa/El_Aaiun"
    },
    {
        "name": "Ad Dakhla",
        "country": "Morocco",
        "province": "Oued el Dahab",
        "timezone": "Africa/El_Aaiun"
    },
    {
        "name": "Oujda",
        "country": "Morocco",
        "province": "Oriental",
        "timezone": "Africa/Casablanca"
    },
    {
        "name": "Safi",
        "country": "Morocco",
        "province": "Doukkala - Abda",
        "timezone": "Africa/Casablanca"
    },
    {
        "name": "Laayoune",
        "country": "Morocco",
        "province": "Laâyoune - Boujdour - Sakia El Hamra",
        "timezone": "Africa/El_Aaiun"
    },
    {
        "name": "Fez",
        "country": "Morocco",
        "province": "Fès - Boulemane",
        "timezone": "Africa/Casablanca"
    },
    {
        "name": "Rabat",
        "country": "Morocco",
        "province": "Rabat - Salé - Zemmour - Zaer",
        "timezone": "Africa/Casablanca"
    },
    {
        "name": "Marrakesh",
        "country": "Morocco",
        "province": "Marrakech - Tensift - Al Haouz",
        "timezone": "Africa/Casablanca"
    },
    {
        "name": "Casablanca",
        "country": "Morocco",
        "province": "Grand Casablanca",
        "timezone": "Africa/Casablanca"
    },
    {
        "name": "Moatize",
        "country": "Mozambique",
        "province": "Tete",
        "timezone": "Africa/Maputo"
    },
    {
        "name": "Luangwa",
        "country": "Mozambique",
        "province": "Tete",
        "timezone": "Africa/Lusaka"
    },
    {
        "name": "Manica",
        "country": "Mozambique",
        "province": "Manica",
        "timezone": "Africa/Maputo"
    },
    {
        "name": "Espungabera",
        "country": "Mozambique",
        "province": "Manica",
        "timezone": "Africa/Maputo"
    },
    {
        "name": "Montepuez",
        "country": "Mozambique",
        "province": "Cabo Delgado",
        "timezone": "Africa/Maputo"
    },
    {
        "name": "Mocimboa",
        "country": "Mozambique",
        "province": "Cabo Delgado",
        "timezone": "Africa/Maputo"
    },
    {
        "name": "Marrupa",
        "country": "Mozambique",
        "province": "Nassa",
        "timezone": "Africa/Maputo"
    },
    {
        "name": "Cuamba",
        "country": "Mozambique",
        "province": "Nassa",
        "timezone": "Africa/Maputo"
    },
    {
        "name": "Ligonha",
        "country": "Mozambique",
        "province": "Nampula",
        "timezone": "Africa/Maputo"
    },
    {
        "name": "Macia",
        "country": "Mozambique",
        "province": "Gaza",
        "timezone": "Africa/Maputo"
    },
    {
        "name": "Massangena",
        "country": "Mozambique",
        "province": "Gaza",
        "timezone": "Africa/Maputo"
    },
    {
        "name": "Mapai",
        "country": "Mozambique",
        "province": "Gaza",
        "timezone": "Africa/Maputo"
    },
    {
        "name": "Dondo",
        "country": "Mozambique",
        "province": "Sofala",
        "timezone": "Africa/Maputo"
    },
    {
        "name": "Chiramba",
        "country": "Mozambique",
        "province": "Sofala",
        "timezone": "Africa/Maputo"
    },
    {
        "name": "Mocuba",
        "country": "Mozambique",
        "province": "Zambezia",
        "timezone": "Africa/Maputo"
    },
    {
        "name": "Nicuadala",
        "country": "Mozambique",
        "province": "Zambezia",
        "timezone": "Africa/Maputo"
    },
    {
        "name": "Maxixe",
        "country": "Mozambique",
        "province": "Inhambane",
        "timezone": "Africa/Maputo"
    },
    {
        "name": "Panda",
        "country": "Mozambique",
        "province": "Inhambane",
        "timezone": "Africa/Maputo"
    },
    {
        "name": "Quissico",
        "country": "Mozambique",
        "province": "Inhambane",
        "timezone": "Africa/Maputo"
    },
    {
        "name": "Vilanculos",
        "country": "Mozambique",
        "province": "Inhambane",
        "timezone": "Africa/Maputo"
    },
    {
        "name": "Matola",
        "country": "Mozambique",
        "province": "Maputo",
        "timezone": "Africa/Maputo"
    },
    {
        "name": "Chimoio",
        "country": "Mozambique",
        "province": "Manica",
        "timezone": "Africa/Maputo"
    },
    {
        "name": "Lichinga",
        "country": "Mozambique",
        "province": "Nassa",
        "timezone": "Africa/Maputo"
    },
    {
        "name": "Angoche",
        "country": "Mozambique",
        "province": "Nampula",
        "timezone": "Africa/Maputo"
    },
    {
        "name": "Mocambique",
        "country": "Mozambique",
        "province": "Nampula",
        "timezone": "Africa/Maputo"
    },
    {
        "name": "Inhambane",
        "country": "Mozambique",
        "province": "Inhambane",
        "timezone": "Africa/Maputo"
    },
    {
        "name": "Tete",
        "country": "Mozambique",
        "province": "Tete",
        "timezone": "Africa/Maputo"
    },
    {
        "name": "Pemba",
        "country": "Mozambique",
        "province": "Cabo Delgado",
        "timezone": "Africa/Maputo"
    },
    {
        "name": "Nampula",
        "country": "Mozambique",
        "province": "Nampula",
        "timezone": "Africa/Maputo"
    },
    {
        "name": "Xai-Xai",
        "country": "Mozambique",
        "province": "Gaza",
        "timezone": "Africa/Maputo"
    },
    {
        "name": "Quelimane",
        "country": "Mozambique",
        "province": "Zambezia",
        "timezone": "Africa/Maputo"
    },
    {
        "name": "Nacala",
        "country": "Mozambique",
        "province": "Nampula",
        "timezone": "Africa/Maputo"
    },
    {
        "name": "Beira",
        "country": "Mozambique",
        "province": "Sofala",
        "timezone": "Africa/Maputo"
    },
    {
        "name": "Maputo",
        "country": "Mozambique",
        "province": "Maputo",
        "timezone": "Africa/Maputo"
    },
    {
        "name": "Loikaw",
        "country": "Myanmar",
        "province": "Kayah",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Pa-an",
        "country": "Myanmar",
        "province": "Kayin",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Hakha",
        "country": "Myanmar",
        "province": "Chin",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Taunggyi",
        "country": "Myanmar",
        "province": "Shan",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Sagaing",
        "country": "Myanmar",
        "province": "Sagaing",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Myingyan",
        "country": "Myanmar",
        "province": "Mandalay",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Letpadan",
        "country": "Myanmar",
        "province": "Bago",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Taungoo",
        "country": "Myanmar",
        "province": "Bago",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Thongwa",
        "country": "Myanmar",
        "province": "Yangon",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Mudon",
        "country": "Myanmar",
        "province": "Mon",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Ye",
        "country": "Myanmar",
        "province": "Mon",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Mawlamyine",
        "country": "Myanmar",
        "province": "Mon",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Kyaukphyu",
        "country": "Myanmar",
        "province": "Rakhine",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Wakema",
        "country": "Myanmar",
        "province": "Ayeyarwady",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Labutta",
        "country": "Myanmar",
        "province": "Ayeyarwady",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Phyarpon",
        "country": "Myanmar",
        "province": "Ayeyarwady",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Yandoon",
        "country": "Myanmar",
        "province": "Ayeyarwady",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Hinthada",
        "country": "Myanmar",
        "province": "Ayeyarwady",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Pathein",
        "country": "Myanmar",
        "province": "Ayeyarwady",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Allanmyo",
        "country": "Myanmar",
        "province": "Magway",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Yaynangyoung",
        "country": "Myanmar",
        "province": "Magway",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Chauk",
        "country": "Myanmar",
        "province": "Magway",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Pakokku",
        "country": "Myanmar",
        "province": "Magway",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Namtu",
        "country": "Myanmar",
        "province": "Shan",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Dawei",
        "country": "Myanmar",
        "province": "Tanintharyi",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Shwebo",
        "country": "Myanmar",
        "province": "Sagaing",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Bago",
        "country": "Myanmar",
        "province": "Bago",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Pyu",
        "country": "Myanmar",
        "province": "Bago",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Pyay",
        "country": "Myanmar",
        "province": "Bago",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Magway",
        "country": "Myanmar",
        "province": "Magway",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Myitkyina",
        "country": "Myanmar",
        "province": "Kachin",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Monywa",
        "country": "Myanmar",
        "province": "Sagaing",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Myeik",
        "country": "Myanmar",
        "province": "Tanintharyi",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Mandalay",
        "country": "Myanmar",
        "province": "Mandalay",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Sittwe",
        "country": "Myanmar",
        "province": "Rakhine",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Naypyidaw",
        "country": "Myanmar",
        "province": "Mandalay",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Rangoon",
        "country": "Myanmar",
        "province": "Yangon",
        "timezone": "Asia/Rangoon"
    },
    {
        "name": "Omaruru",
        "country": "Namibia",
        "province": "Erongo",
        "timezone": "Africa/Windhoek"
    },
    {
        "name": "Karibib",
        "country": "Namibia",
        "province": "Erongo",
        "timezone": "Africa/Windhoek"
    },
    {
        "name": "Otavi",
        "country": "Namibia",
        "province": "Otjozondjupa",
        "timezone": "Africa/Windhoek"
    },
    {
        "name": "Gobabis",
        "country": "Namibia",
        "province": "Omaheke",
        "timezone": "Africa/Windhoek"
    },
    {
        "name": "Karasburg",
        "country": "Namibia",
        "province": "Karas",
        "timezone": "Africa/Windhoek"
    },
    {
        "name": "Bethanie",
        "country": "Namibia",
        "province": "Karas",
        "timezone": "Africa/Windhoek"
    },
    {
        "name": "Oranjemund",
        "country": "Namibia",
        "province": "Karas",
        "timezone": "Africa/Windhoek"
    },
    {
        "name": "Mariental",
        "country": "Namibia",
        "province": "Hardap",
        "timezone": "Africa/Windhoek"
    },
    {
        "name": "Rehoboth",
        "country": "Namibia",
        "province": "Hardap",
        "timezone": "Africa/Windhoek"
    },
    {
        "name": "Outjo",
        "country": "Namibia",
        "province": "Kunene",
        "timezone": "Africa/Windhoek"
    },
    {
        "name": "Opuwo",
        "country": "Namibia",
        "province": "Kunene",
        "timezone": "Africa/Windhoek"
    },
    {
        "name": "Usakos",
        "country": "Namibia",
        "province": "Erongo",
        "timezone": "Africa/Windhoek"
    },
    {
        "name": "Okahandja",
        "country": "Namibia",
        "province": "Otjozondjupa",
        "timezone": "Africa/Windhoek"
    },
    {
        "name": "Otjiwarongo",
        "country": "Namibia",
        "province": "Otjozondjupa",
        "timezone": "Africa/Windhoek"
    },
    {
        "name": "Oshikango",
        "country": "Namibia",
        "province": "Ohangwena",
        "timezone": "Africa/Windhoek"
    },
    {
        "name": "Cuangar",
        "country": "Namibia",
        "province": "Kavango",
        "timezone": "Africa/Luanda"
    },
    {
        "name": "Katima Mulilo",
        "country": "Namibia",
        "province": "Caprivi",
        "timezone": "Africa/Windhoek"
    },
    {
        "name": "Keetmanshoop",
        "country": "Namibia",
        "province": "Karas",
        "timezone": "Africa/Windhoek"
    },
    {
        "name": "Maltahöhe",
        "country": "Namibia",
        "province": "Hardap",
        "timezone": "Africa/Windhoek"
    },
    {
        "name": "Swakopmund",
        "country": "Namibia",
        "province": "Erongo",
        "timezone": "Africa/Windhoek"
    },
    {
        "name": "Ongwediva",
        "country": "Namibia",
        "province": "Oshana",
        "timezone": "Africa/Windhoek"
    },
    {
        "name": "Rundu",
        "country": "Namibia",
        "province": "Kavango",
        "timezone": "Africa/Windhoek"
    },
    {
        "name": "Tsumeb",
        "country": "Namibia",
        "province": "Oshikoto",
        "timezone": "Africa/Windhoek"
    },
    {
        "name": "Lüderitz",
        "country": "Namibia",
        "province": "Karas",
        "timezone": "Africa/Windhoek"
    },
    {
        "name": "Walvis Bay",
        "country": "Namibia",
        "province": "Erongo",
        "timezone": "Africa/Windhoek"
    },
    {
        "name": "Windhoek",
        "country": "Namibia",
        "province": "Khomas",
        "timezone": "Africa/Windhoek"
    },
    {
        "name": "Grootfontein",
        "country": "Namibia",
        "province": "Otjozondjupa",
        "timezone": "Africa/Windhoek"
    },
    {
        "name": "Salyan",
        "country": "Nepal",
        "province": "Rapti",
        "timezone": "Asia/Kathmandu"
    },
    {
        "name": "Baglung",
        "country": "Nepal",
        "province": "Dhawalagiri",
        "timezone": "Asia/Kathmandu"
    },
    {
        "name": "Jumla",
        "country": "Nepal",
        "province": "Karnali",
        "timezone": "Asia/Kathmandu"
    },
    {
        "name": "Bhairawa",
        "country": "Nepal",
        "province": "Lumbini",
        "timezone": "Asia/Kathmandu"
    },
    {
        "name": "Dandeldhura",
        "country": "Nepal",
        "province": "Mahakali",
        "timezone": "Asia/Kathmandu"
    },
    {
        "name": "Dhangarhi",
        "country": "Nepal",
        "province": "Achham",
        "timezone": "Asia/Kathmandu"
    },
    {
        "name": "Ramechhap",
        "country": "Nepal",
        "province": "Janakpur",
        "timezone": "Asia/Kathmandu"
    },
    {
        "name": "Bhimphedi",
        "country": "Nepal",
        "province": "Narayani",
        "timezone": "Asia/Kathmandu"
    },
    {
        "name": "Rajbiraj",
        "country": "Nepal",
        "province": "Sagarmatha",
        "timezone": "Asia/Kathmandu"
    },
    {
        "name": "Ilam",
        "country": "Nepal",
        "province": "Mechi",
        "timezone": "Asia/Kathmandu"
    },
    {
        "name": "Lalitpur",
        "country": "Nepal",
        "province": "Bhaktapur",
        "timezone": "Asia/Kathmandu"
    },
    {
        "name": "Hetauda",
        "country": "Nepal",
        "province": "Narayani",
        "timezone": "Asia/Kathmandu"
    },
    {
        "name": "Nepalganj",
        "country": "Nepal",
        "province": "Banke",
        "timezone": "Asia/Kathmandu"
    },
    {
        "name": "Birganj",
        "country": "Nepal",
        "province": "Narayani",
        "timezone": "Asia/Kathmandu"
    },
    {
        "name": "Biratnagar",
        "country": "Nepal",
        "province": "Bhojpur",
        "timezone": "Asia/Kathmandu"
    },
    {
        "name": "Pokhara",
        "country": "Nepal",
        "province": "Gorkha",
        "timezone": "Asia/Kathmandu"
    },
    {
        "name": "Kathmandu",
        "country": "Nepal",
        "province": "Bhaktapur",
        "timezone": "Asia/Kathmandu"
    },
    {
        "name": "Assen",
        "country": "Netherlands",
        "province": "Drenthe",
        "timezone": "Europe/Amsterdam"
    },
    {
        "name": "Arnhem",
        "country": "Netherlands",
        "province": "Gelderland",
        "timezone": "Europe/Amsterdam"
    },
    {
        "name": "Maastricht",
        "country": "Netherlands",
        "province": "Limburg",
        "timezone": "Europe/Amsterdam"
    },
    {
        "name": "Zwolle",
        "country": "Netherlands",
        "province": "Overijssel",
        "timezone": "Europe/Amsterdam"
    },
    {
        "name": "Middelburg",
        "country": "Netherlands",
        "province": "Zeeland",
        "timezone": "Europe/Amsterdam"
    },
    {
        "name": "'s-Hertogenbosch",
        "country": "Netherlands",
        "province": "Noord-Brabant",
        "timezone": "Europe/Amsterdam"
    },
    {
        "name": "Eindhoven",
        "country": "Netherlands",
        "province": "Noord-Brabant",
        "timezone": "Europe/Amsterdam"
    },
    {
        "name": "Leeuwarden",
        "country": "Netherlands",
        "province": "Friesland",
        "timezone": "Europe/Amsterdam"
    },
    {
        "name": "Groningen",
        "country": "Netherlands",
        "province": "Groningen",
        "timezone": "Europe/Amsterdam"
    },
    {
        "name": "Utrecht",
        "country": "Netherlands",
        "province": "Utrecht",
        "timezone": "Europe/Amsterdam"
    },
    {
        "name": "Haarlem",
        "country": "Netherlands",
        "province": "Noord-Holland",
        "timezone": "Europe/Amsterdam"
    },
    {
        "name": "Rotterdam",
        "country": "Netherlands",
        "province": "Zuid-Holland",
        "timezone": "Europe/Amsterdam"
    },
    {
        "name": "The Hague",
        "country": "Netherlands",
        "province": "Zuid-Holland",
        "timezone": "Europe/Amsterdam"
    },
    {
        "name": "Amsterdam",
        "country": "Netherlands",
        "province": "Noord-Holland",
        "timezone": "Europe/Amsterdam"
    },
    {
        "name": "Noumea",
        "country": "New Caledonia",
        "province": "Sud",
        "timezone": "Pacific/Noumea"
    },
    {
        "name": "Greymouth",
        "country": "New Zealand",
        "province": "West Coast",
        "timezone": "Pacific/Auckland"
    },
    {
        "name": "Upper Hutt",
        "country": "New Zealand",
        "province": "Manawatu-Wanganui",
        "timezone": "Pacific/Auckland"
    },
    {
        "name": "Masterton",
        "country": "New Zealand",
        "province": "Manawatu-Wanganui",
        "timezone": "Pacific/Auckland"
    },
    {
        "name": "Levin",
        "country": "New Zealand",
        "province": "Manawatu-Wanganui",
        "timezone": "Pacific/Auckland"
    },
    {
        "name": "Waitakere",
        "country": "New Zealand",
        "province": "Auckland",
        "timezone": "Pacific/Auckland"
    },
    {
        "name": "Takapuna",
        "country": "New Zealand",
        "province": "Auckland",
        "timezone": "Pacific/Auckland"
    },
    {
        "name": "Whakatane",
        "country": "New Zealand",
        "province": "Bay of Plenty",
        "timezone": "Pacific/Auckland"
    },
    {
        "name": "Ashburton",
        "country": "New Zealand",
        "province": "Canterbury",
        "timezone": "Pacific/Auckland"
    },
    {
        "name": "Kaiapoi",
        "country": "New Zealand",
        "province": "Canterbury",
        "timezone": "Pacific/Auckland"
    },
    {
        "name": "New Plymouth",
        "country": "New Zealand",
        "province": "Taranaki",
        "timezone": "Pacific/Auckland"
    },
    {
        "name": "Westport",
        "country": "New Zealand",
        "province": "West Coast",
        "timezone": "Pacific/Auckland"
    },
    {
        "name": "Hokitika",
        "country": "New Zealand",
        "province": "West Coast",
        "timezone": "Pacific/Auckland"
    },
    {
        "name": "Oamaru",
        "country": "New Zealand",
        "province": "Otago",
        "timezone": "Pacific/Auckland"
    },
    {
        "name": "Palmerston North",
        "country": "New Zealand",
        "province": "Manawatu-Wanganui",
        "timezone": "Pacific/Auckland"
    },
    {
        "name": "Wanganui",
        "country": "New Zealand",
        "province": "Manawatu-Wanganui",
        "timezone": "Pacific/Auckland"
    },
    {
        "name": "Hastings",
        "country": "New Zealand",
        "province": "Gisborne",
        "timezone": "Pacific/Auckland"
    },
    {
        "name": "Gisborne",
        "country": "New Zealand",
        "province": "Gisborne",
        "timezone": "Pacific/Auckland"
    },
    {
        "name": "Rotorua",
        "country": "New Zealand",
        "province": "Auckland",
        "timezone": "Pacific/Auckland"
    },
    {
        "name": "Taupo",
        "country": "New Zealand",
        "province": "Auckland",
        "timezone": "Pacific/Auckland"
    },
    {
        "name": "Tauranga",
        "country": "New Zealand",
        "province": "Bay of Plenty",
        "timezone": "Pacific/Auckland"
    },
    {
        "name": "Timaru",
        "country": "New Zealand",
        "province": "Canterbury",
        "timezone": "Pacific/Auckland"
    },
    {
        "name": "Nelson",
        "country": "New Zealand",
        "province": "Nelson",
        "timezone": "Pacific/Auckland"
    },
    {
        "name": "Whangarei",
        "country": "New Zealand",
        "province": "Northland",
        "timezone": "Pacific/Auckland"
    },
    {
        "name": "Queenstown",
        "country": "New Zealand",
        "province": "Otago",
        "timezone": "Pacific/Auckland"
    },
    {
        "name": "Invercargill",
        "country": "New Zealand",
        "province": "Southland",
        "timezone": "Pacific/Auckland"
    },
    {
        "name": "Napier",
        "country": "New Zealand",
        "province": "Gisborne",
        "timezone": "Pacific/Auckland"
    },
    {
        "name": "Manukau",
        "country": "New Zealand",
        "province": "Auckland",
        "timezone": "Pacific/Auckland"
    },
    {
        "name": "Hamilton",
        "country": "New Zealand",
        "province": "Auckland",
        "timezone": "Pacific/Auckland"
    },
    {
        "name": "Blenheim",
        "country": "New Zealand",
        "province": "Marlborough",
        "timezone": "Pacific/Auckland"
    },
    {
        "name": "Dunedin",
        "country": "New Zealand",
        "province": "Otago",
        "timezone": "Pacific/Auckland"
    },
    {
        "name": "Wellington",
        "country": "New Zealand",
        "province": "Manawatu-Wanganui",
        "timezone": "Pacific/Auckland"
    },
    {
        "name": "Christchurch",
        "country": "New Zealand",
        "province": "Canterbury",
        "timezone": "Pacific/Auckland"
    },
    {
        "name": "Auckland",
        "country": "New Zealand",
        "province": "Auckland",
        "timezone": "Pacific/Auckland"
    },
    {
        "name": "Somoto",
        "country": "Nicaragua",
        "province": "Madriz",
        "timezone": "America/Managua"
    },
    {
        "name": "Ocotal",
        "country": "Nicaragua",
        "province": "Nueva Segovia",
        "timezone": "America/Managua"
    },
    {
        "name": "San Carlos",
        "country": "Nicaragua",
        "province": "Nicaragua",
        "timezone": "America/Managua"
    },
    {
        "name": "Jinotepe",
        "country": "Nicaragua",
        "province": "Carazo",
        "timezone": "America/Managua"
    },
    {
        "name": "Jinotega",
        "country": "Nicaragua",
        "province": "Jinotega",
        "timezone": "America/Managua"
    },
    {
        "name": "Masaya",
        "country": "Nicaragua",
        "province": "Masaya",
        "timezone": "America/Managua"
    },
    {
        "name": "San Juan del Sur",
        "country": "Nicaragua",
        "province": "Nicaragua",
        "timezone": null
    },
    {
        "name": "Esteli",
        "country": "Nicaragua",
        "province": "Estelí",
        "timezone": "America/Managua"
    },
    {
        "name": "Boaco",
        "country": "Nicaragua",
        "province": "Boaco",
        "timezone": "America/Managua"
    },
    {
        "name": "Juigalpa",
        "country": "Nicaragua",
        "province": "Chontales",
        "timezone": "America/Managua"
    },
    {
        "name": "Rivas",
        "country": "Nicaragua",
        "province": "Nicaragua",
        "timezone": "America/Managua"
    },
    {
        "name": "San Juan de Nicaragua",
        "country": "Nicaragua",
        "province": "Nicaragua",
        "timezone": "America/Managua"
    },
    {
        "name": "Granada",
        "country": "Nicaragua",
        "province": "Granada",
        "timezone": "America/Managua"
    },
    {
        "name": "Chinandega",
        "country": "Nicaragua",
        "province": "Chinandega",
        "timezone": "America/Managua"
    },
    {
        "name": "Matagalpa",
        "country": "Nicaragua",
        "province": "Matagalpa",
        "timezone": "America/Managua"
    },
    {
        "name": "Puerto Cabezas",
        "country": "Nicaragua",
        "province": "Atlántico Norte",
        "timezone": "America/Managua"
    },
    {
        "name": "Leon",
        "country": "Nicaragua",
        "province": "León",
        "timezone": "America/Managua"
    },
    {
        "name": "Bluefields",
        "country": "Nicaragua",
        "province": "Atlántico Sur",
        "timezone": "America/Managua"
    },
    {
        "name": "Managua",
        "country": "Nicaragua",
        "province": "Managua",
        "timezone": "America/Managua"
    },
    {
        "name": "Goure",
        "country": "Niger",
        "province": "Zinder",
        "timezone": "Africa/Niamey"
    },
    {
        "name": "Gaya",
        "country": "Niger",
        "province": "Dosso",
        "timezone": "Africa/Niamey"
    },
    {
        "name": "Tillaberi",
        "country": "Niger",
        "province": "Niamey",
        "timezone": "Africa/Niamey"
    },
    {
        "name": "Ayorou",
        "country": "Niger",
        "province": "Niamey",
        "timezone": "Africa/Niamey"
    },
    {
        "name": "Birni Nkonni",
        "country": "Niger",
        "province": "Tahoua",
        "timezone": "Africa/Niamey"
    },
    {
        "name": "Madaoua",
        "country": "Niger",
        "province": "Tahoua",
        "timezone": "Africa/Niamey"
    },
    {
        "name": "Diffa",
        "country": "Niger",
        "province": "Diffa",
        "timezone": "Africa/Niamey"
    },
    {
        "name": "Nguigmi",
        "country": "Niger",
        "province": "Diffa",
        "timezone": "Africa/Niamey"
    },
    {
        "name": "Dosso",
        "country": "Niger",
        "province": "Dosso",
        "timezone": "Africa/Niamey"
    },
    {
        "name": "Arlit",
        "country": "Niger",
        "province": "Agadez",
        "timezone": "Africa/Niamey"
    },
    {
        "name": "Djado",
        "country": "Niger",
        "province": "Agadez",
        "timezone": "Africa/Niamey"
    },
    {
        "name": "Maradi",
        "country": "Niger",
        "province": "Maradi",
        "timezone": "Africa/Niamey"
    },
    {
        "name": "Tahoua",
        "country": "Niger",
        "province": "Tahoua",
        "timezone": "Africa/Niamey"
    },
    {
        "name": "Zinder",
        "country": "Niger",
        "province": "Zinder",
        "timezone": "Africa/Niamey"
    },
    {
        "name": "Niamey",
        "country": "Niger",
        "province": "Niamey",
        "timezone": "Africa/Niamey"
    },
    {
        "name": "Agadez",
        "country": "Niger",
        "province": "Agadez",
        "timezone": "Africa/Niamey"
    },
    {
        "name": "Umuahia",
        "country": "Nigeria",
        "province": "Abia",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Uyo",
        "country": "Nigeria",
        "province": "Akwa Ibom",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Owerri",
        "country": "Nigeria",
        "province": "Imo",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Dutse",
        "country": "Nigeria",
        "province": "Jigawa",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Damaturu",
        "country": "Nigeria",
        "province": "Yobe",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Iwo",
        "country": "Nigeria",
        "province": "Osun",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Iseyin",
        "country": "Nigeria",
        "province": "Oyo",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Biu",
        "country": "Nigeria",
        "province": "Borno",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Bama",
        "country": "Nigeria",
        "province": "Borno",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Aba",
        "country": "Nigeria",
        "province": "Abia",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Opobo",
        "country": "Nigeria",
        "province": "Akwa Ibom",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Orlu",
        "country": "Nigeria",
        "province": "Imo",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Oturkpo",
        "country": "Nigeria",
        "province": "Benue",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Calabar",
        "country": "Nigeria",
        "province": "Cross River",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Wukari",
        "country": "Nigeria",
        "province": "Taraba",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Jalingo",
        "country": "Nigeria",
        "province": "Taraba",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Kontagora",
        "country": "Nigeria",
        "province": "Niger",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Bida",
        "country": "Nigeria",
        "province": "Niger",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Abeokuta",
        "country": "Nigeria",
        "province": "Ogun",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Ijebu Ode",
        "country": "Nigeria",
        "province": "Ogun",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Akure",
        "country": "Nigeria",
        "province": "Ondo",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Ikare",
        "country": "Nigeria",
        "province": "Ondo",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Owo",
        "country": "Nigeria",
        "province": "Ondo",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Ondo",
        "country": "Nigeria",
        "province": "Ondo",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Ado Ekiti",
        "country": "Nigeria",
        "province": "Ekiti",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Ife",
        "country": "Nigeria",
        "province": "Osun",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Oshogbo",
        "country": "Nigeria",
        "province": "Osun",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Oyo",
        "country": "Nigeria",
        "province": "Oyo",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Awka",
        "country": "Nigeria",
        "province": "Anambra",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Onitsha",
        "country": "Nigeria",
        "province": "Anambra",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Azare",
        "country": "Nigeria",
        "province": "Bauchi",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Bauchi",
        "country": "Nigeria",
        "province": "Bauchi",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Gombe",
        "country": "Nigeria",
        "province": "Gombe",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Kumo",
        "country": "Nigeria",
        "province": "Gombe",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Sapele",
        "country": "Nigeria",
        "province": "Delta",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Nsukka",
        "country": "Nigeria",
        "province": "Enugu",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Lokoja",
        "country": "Nigeria",
        "province": "Kogi",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Idah",
        "country": "Nigeria",
        "province": "Kogi",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Lafia",
        "country": "Nigeria",
        "province": "Nassarawa",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Keffi",
        "country": "Nigeria",
        "province": "Nassarawa",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Funtua",
        "country": "Nigeria",
        "province": "Katsina",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Katsina",
        "country": "Nigeria",
        "province": "Katsina",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Gusau",
        "country": "Nigeria",
        "province": "Zamfara",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Nguru",
        "country": "Nigeria",
        "province": "Yobe",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Gashua",
        "country": "Nigeria",
        "province": "Yobe",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Potiskum",
        "country": "Nigeria",
        "province": "Yobe",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Birnin Kebbi",
        "country": "Nigeria",
        "province": "Kebbi",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Koko",
        "country": "Nigeria",
        "province": "Kebbi",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Mubi",
        "country": "Nigeria",
        "province": "Adamawa",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Numan",
        "country": "Nigeria",
        "province": "Adamawa",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Ilorin",
        "country": "Nigeria",
        "province": "Kwara",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Minna",
        "country": "Nigeria",
        "province": "Niger",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Zaria",
        "country": "Nigeria",
        "province": "Kaduna",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Jos",
        "country": "Nigeria",
        "province": "Plateau",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Yola",
        "country": "Nigeria",
        "province": "Adamawa",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Benin City",
        "country": "Nigeria",
        "province": "Edo",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Maiduguri",
        "country": "Nigeria",
        "province": "Borno",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Port Harcourt",
        "country": "Nigeria",
        "province": "Rivers",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Makurdi",
        "country": "Nigeria",
        "province": "Benue",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Ibadan",
        "country": "Nigeria",
        "province": "Oyo",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Ogbomosho",
        "country": "Nigeria",
        "province": "Oyo",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Warri",
        "country": "Nigeria",
        "province": "Delta",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Kaduna",
        "country": "Nigeria",
        "province": "Kaduna",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Enugu",
        "country": "Nigeria",
        "province": "Enugu",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Sokoto",
        "country": "Nigeria",
        "province": "Sokoto",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Abuja",
        "country": "Nigeria",
        "province": "Federal Capital Territory",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Kano",
        "country": "Nigeria",
        "province": "Kano",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Lagos",
        "country": "Nigeria",
        "province": "Lagos",
        "timezone": "Africa/Lagos"
    },
    {
        "name": "Sariwon",
        "country": "North Korea",
        "province": "Hwanghae-bukto",
        "timezone": "Asia/Pyongyang"
    },
    {
        "name": "Sin-Ni",
        "country": "North Korea",
        "province": "P'yongan-namdo",
        "timezone": "Asia/Pyongyang"
    },
    {
        "name": "Changyon",
        "country": "North Korea",
        "province": "Hwanghae-namdo",
        "timezone": "Asia/Pyongyang"
    },
    {
        "name": "Anbyon",
        "country": "North Korea",
        "province": "Kangwon-do",
        "timezone": "Asia/Pyongyang"
    },
    {
        "name": "Munchon",
        "country": "North Korea",
        "province": "Kangwon-do",
        "timezone": "Asia/Pyongyang"
    },
    {
        "name": "Kaesong",
        "country": "North Korea",
        "province": "Kaesong",
        "timezone": "Asia/Pyongyang"
    },
    {
        "name": "Chosan",
        "country": "North Korea",
        "province": "Chagang-do",
        "timezone": "Asia/Pyongyang"
    },
    {
        "name": "Manpo",
        "country": "North Korea",
        "province": "Chagang-do",
        "timezone": "Asia/Pyongyang"
    },
    {
        "name": "Sunchon",
        "country": "North Korea",
        "province": "P'yongan-namdo",
        "timezone": "Asia/Pyongyang"
    },
    {
        "name": "Kimhyonggwon",
        "country": "North Korea",
        "province": "Ryanggang",
        "timezone": "Asia/Pyongyang"
    },
    {
        "name": "Pyongsan",
        "country": "North Korea",
        "province": "Hwanghae-bukto",
        "timezone": "Asia/Pyongyang"
    },
    {
        "name": "Ongjin",
        "country": "North Korea",
        "province": "Hwanghae-namdo",
        "timezone": "Asia/Pyongyang"
    },
    {
        "name": "Haeju",
        "country": "North Korea",
        "province": "Hwanghae-namdo",
        "timezone": "Asia/Pyongyang"
    },
    {
        "name": "Kilchu",
        "country": "North Korea",
        "province": "Hamgyong-bukto",
        "timezone": "Asia/Pyongyang"
    },
    {
        "name": "Musan",
        "country": "North Korea",
        "province": "Hamgyong-bukto",
        "timezone": "Asia/Pyongyang"
    },
    {
        "name": "Sonbong",
        "country": "North Korea",
        "province": "Hamgyong-bukto",
        "timezone": "Asia/Pyongyang"
    },
    {
        "name": "Kanggye",
        "country": "North Korea",
        "province": "Chagang-do",
        "timezone": "Asia/Pyongyang"
    },
    {
        "name": "Hungnam",
        "country": "North Korea",
        "province": "Hamgyong-namdo",
        "timezone": "Asia/Pyongyang"
    },
    {
        "name": "Taedong",
        "country": "North Korea",
        "province": "P'yongan-bukto",
        "timezone": "Asia/Pyongyang"
    },
    {
        "name": "Chongju",
        "country": "North Korea",
        "province": "P'yongan-bukto",
        "timezone": "Asia/Pyongyang"
    },
    {
        "name": "Hyeson",
        "country": "North Korea",
        "province": "Ryanggang",
        "timezone": "Asia/Pyongyang"
    },
    {
        "name": "Nampo",
        "country": "North Korea",
        "province": "Namp'o-si",
        "timezone": "Asia/Pyongyang"
    },
    {
        "name": "Chongjin",
        "country": "North Korea",
        "province": "Hamgyong-bukto",
        "timezone": "Asia/Pyongyang"
    },
    {
        "name": "Kimchaek",
        "country": "North Korea",
        "province": "Hamgyong-bukto",
        "timezone": "Asia/Pyongyang"
    },
    {
        "name": "Hamhung",
        "country": "North Korea",
        "province": "Hamgyong-namdo",
        "timezone": "Asia/Pyongyang"
    },
    {
        "name": "Wonsan",
        "country": "North Korea",
        "province": "Kangwon-do",
        "timezone": "Asia/Pyongyang"
    },
    {
        "name": "Sinuiju",
        "country": "North Korea",
        "province": "P'yongan-bukto",
        "timezone": "Asia/Pyongyang"
    },
    {
        "name": "Pyongyang",
        "country": "North Korea",
        "province": "P'yongyang",
        "timezone": "Asia/Pyongyang"
    },
    {
        "name": "Kyrenia",
        "country": "Northern Cyprus",
        "province": "",
        "timezone": "Asia/Famagusta"
    },
    {
        "name": "Ammochostos",
        "country": "Northern Cyprus",
        "province": "",
        "timezone": "Asia/Famagusta"
    },
    {
        "name": "Capitol Hill",
        "country": "Northern Mariana Islands",
        "province": "",
        "timezone": "Pacific/Saipan"
    },
    {
        "name": "Arendal",
        "country": "Norway",
        "province": "Aust-Agder",
        "timezone": "Europe/Oslo"
    },
    {
        "name": "Vossavangen",
        "country": "Norway",
        "province": "Hordaland",
        "timezone": "Europe/Oslo"
    },
    {
        "name": "Leikanger",
        "country": "Norway",
        "province": "Sogn og Fjordane",
        "timezone": "Europe/Oslo"
    },
    {
        "name": "Bærum",
        "country": "Norway",
        "province": "Akershus",
        "timezone": "Europe/Oslo"
    },
    {
        "name": "Hamar",
        "country": "Norway",
        "province": "Hedmark",
        "timezone": "Europe/Oslo"
    },
    {
        "name": "Tønsberg",
        "country": "Norway",
        "province": "Vestfold",
        "timezone": "Europe/Oslo"
    },
    {
        "name": "Finnsnes",
        "country": "Norway",
        "province": "Troms",
        "timezone": "Europe/Oslo"
    },
    {
        "name": "Gjøvik",
        "country": "Norway",
        "province": "Oppland",
        "timezone": "Europe/Oslo"
    },
    {
        "name": "Rørvik",
        "country": "Norway",
        "province": "Nord-Trøndelag",
        "timezone": "Europe/Oslo"
    },
    {
        "name": "Harstad",
        "country": "Norway",
        "province": "Troms",
        "timezone": "Europe/Oslo"
    },
    {
        "name": "Ålesund",
        "country": "Norway",
        "province": "Møre og Romsdal",
        "timezone": "Europe/Oslo"
    },
    {
        "name": "Sandnes",
        "country": "Norway",
        "province": "Rogaland",
        "timezone": "Europe/Oslo"
    },
    {
        "name": "Drammen",
        "country": "Norway",
        "province": "Buskerud",
        "timezone": "Europe/Oslo"
    },
    {
        "name": "Moss",
        "country": "Norway",
        "province": "Ãstfold",
        "timezone": "Europe/Oslo"
    },
    {
        "name": "Steinkjer",
        "country": "Norway",
        "province": "Nord-Trøndelag",
        "timezone": "Europe/Oslo"
    },
    {
        "name": "Svolvær",
        "country": "Norway",
        "province": "Nordland",
        "timezone": "Europe/Oslo"
    },
    {
        "name": "Mo i Rana",
        "country": "Norway",
        "province": "Nordland",
        "timezone": "Europe/Oslo"
    },
    {
        "name": "Narvik",
        "country": "Norway",
        "province": "Nordland",
        "timezone": "Europe/Oslo"
    },
    {
        "name": "Bodø",
        "country": "Norway",
        "province": "Nordland",
        "timezone": "Europe/Oslo"
    },
    {
        "name": "Haugesund",
        "country": "Norway",
        "province": "Rogaland",
        "timezone": "Europe/Oslo"
    },
    {
        "name": "Stavanger",
        "country": "Norway",
        "province": "Rogaland",
        "timezone": "Europe/Oslo"
    },
    {
        "name": "Skien",
        "country": "Norway",
        "province": "Telemark",
        "timezone": "Europe/Oslo"
    },
    {
        "name": "Namsos",
        "country": "Norway",
        "province": "Nord-Trøndelag",
        "timezone": "Europe/Oslo"
    },
    {
        "name": "Alta",
        "country": "Norway",
        "province": "Finnmark",
        "timezone": "Europe/Oslo"
    },
    {
        "name": "Vadsø",
        "country": "Norway",
        "province": "Finnmark",
        "timezone": "Europe/Oslo"
    },
    {
        "name": "Molde",
        "country": "Norway",
        "province": "Møre og Romsdal",
        "timezone": "Europe/Oslo"
    },
    {
        "name": "Lillehammer",
        "country": "Norway",
        "province": "Oppland",
        "timezone": "Europe/Oslo"
    },
    {
        "name": "Kirkenes",
        "country": "Norway",
        "province": "Finnmark",
        "timezone": "Europe/Oslo"
    },
    {
        "name": "Kristiansand",
        "country": "Norway",
        "province": "Vest-Agder",
        "timezone": "Europe/Oslo"
    },
    {
        "name": "Hammerfest",
        "country": "Norway",
        "province": "Finnmark",
        "timezone": "Europe/Oslo"
    },
    {
        "name": "Tromsø",
        "country": "Norway",
        "province": "Troms",
        "timezone": "Europe/Oslo"
    },
    {
        "name": "Trondheim",
        "country": "Norway",
        "province": "Sør-Trøndelag",
        "timezone": "Europe/Oslo"
    },
    {
        "name": "Bergen",
        "country": "Norway",
        "province": "Hordaland",
        "timezone": "Europe/Oslo"
    },
    {
        "name": "Oslo",
        "country": "Norway",
        "province": "Oslo",
        "timezone": "Europe/Oslo"
    },
    {
        "name": "Alayat Samail",
        "country": "Oman",
        "province": "Ad Dakhliyah",
        "timezone": "Asia/Muscat"
    },
    {
        "name": "Dawwah",
        "country": "Oman",
        "province": "Ash Sharqiyah",
        "timezone": "Asia/Muscat"
    },
    {
        "name": "Mirbat",
        "country": "Oman",
        "province": "Dhofar",
        "timezone": "Asia/Muscat"
    },
    {
        "name": "Ibri",
        "country": "Oman",
        "province": "Al Dhahira",
        "timezone": "Asia/Muscat"
    },
    {
        "name": "Salalah",
        "country": "Oman",
        "province": "Dhofar",
        "timezone": "Asia/Muscat"
    },
    {
        "name": "Suhar",
        "country": "Oman",
        "province": "Al Batnah",
        "timezone": "Asia/Muscat"
    },
    {
        "name": "As Sib",
        "country": "Oman",
        "province": "Muscat",
        "timezone": "Asia/Muscat"
    },
    {
        "name": "Nizwa",
        "country": "Oman",
        "province": "Ad Dakhliyah",
        "timezone": "Asia/Muscat"
    },
    {
        "name": "Sur",
        "country": "Oman",
        "province": "Ash Sharqiyah",
        "timezone": "Asia/Muscat"
    },
    {
        "name": "Muscat",
        "country": "Oman",
        "province": "Muscat",
        "timezone": "Asia/Muscat"
    },
    {
        "name": "Parachinar",
        "country": "Pakistan",
        "province": "F.A.T.A.",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Sialkote",
        "country": "Pakistan",
        "province": "Punjab",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Sheikhu Pura",
        "country": "Pakistan",
        "province": "Punjab",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Gujrat",
        "country": "Pakistan",
        "province": "Punjab",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Sahiwal",
        "country": "Pakistan",
        "province": "Punjab",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Chiniot",
        "country": "Pakistan",
        "province": "Punjab",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Rahim Yar Khan",
        "country": "Pakistan",
        "province": "Punjab",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Mansehra",
        "country": "Pakistan",
        "province": "Khyber-Pakhtunkhwa",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Kohat",
        "country": "Pakistan",
        "province": "N.W.F.P.",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Abbottabad",
        "country": "Pakistan",
        "province": "N.W.F.P.",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Mardan",
        "country": "Pakistan",
        "province": "N.W.F.P.",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Gwadar",
        "country": "Pakistan",
        "province": "Baluchistan",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Zhob",
        "country": "Pakistan",
        "province": "Baluchistan",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Gilgit",
        "country": "Pakistan",
        "province": "Northern Areas",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Kasur",
        "country": "Pakistan",
        "province": "Punjab",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Kundian",
        "country": "Pakistan",
        "province": "Punjab",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Okara",
        "country": "Pakistan",
        "province": "Punjab",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Jhang",
        "country": "Pakistan",
        "province": "Punjab",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Sargodha",
        "country": "Pakistan",
        "province": "Punjab",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Dera Ghazi Khan",
        "country": "Pakistan",
        "province": "Punjab",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Sadiqabad",
        "country": "Pakistan",
        "province": "Punjab",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Nawabshah",
        "country": "Pakistan",
        "province": "Sind",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Bannu",
        "country": "Pakistan",
        "province": "N.W.F.P.",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Dera Ismail Khan",
        "country": "Pakistan",
        "province": "Khyber-Pakhtunkhwa",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Chaman",
        "country": "Pakistan",
        "province": "Baluchistan",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Turbat",
        "country": "Pakistan",
        "province": "Baluchistan",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Faisalabad",
        "country": "Pakistan",
        "province": "Punjab",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Rawalpindi",
        "country": "Pakistan",
        "province": "Punjab",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Bahawalpur",
        "country": "Pakistan",
        "province": "Punjab",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Mirput Khas",
        "country": "Pakistan",
        "province": "Sind",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Sukkur",
        "country": "Pakistan",
        "province": "Sind",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Saidu",
        "country": "Pakistan",
        "province": "N.W.F.P.",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Gujranwala",
        "country": "Pakistan",
        "province": "Punjab",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Quetta",
        "country": "Pakistan",
        "province": "Baluchistan",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Larkana",
        "country": "Pakistan",
        "province": "Sind",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Islamabad",
        "country": "Pakistan",
        "province": "F.C.T.",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Multan",
        "country": "Pakistan",
        "province": "Punjab",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Hyderabad",
        "country": "Pakistan",
        "province": "Sind",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Peshawar",
        "country": "Pakistan",
        "province": "N.W.F.P.",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Lahore",
        "country": "Pakistan",
        "province": "Punjab",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Karachi",
        "country": "Pakistan",
        "province": "Sind",
        "timezone": "Asia/Karachi"
    },
    {
        "name": "Koror",
        "country": "Palau",
        "province": "",
        "timezone": "Pacific/Palau"
    },
    {
        "name": "Melekeok",
        "country": "Palau",
        "province": "",
        "timezone": "Pacific/Palau"
    },
    {
        "name": "Ramallah",
        "country": "Palestine",
        "province": "",
        "timezone": "Asia/Hebron"
    },
    {
        "name": "Al Khalil",
        "country": "Palestine",
        "province": "",
        "timezone": "Asia/Hebron"
    },
    {
        "name": "Nablus",
        "country": "Palestine",
        "province": "",
        "timezone": "Asia/Hebron"
    },
    {
        "name": "Gaza",
        "country": "Palestine",
        "province": "",
        "timezone": "Asia/Gaza"
    },
    {
        "name": "El Porvenir",
        "country": "Panama",
        "province": "Kuna Yala",
        "timezone": "America/Panama"
    },
    {
        "name": "Penonome",
        "country": "Panama",
        "province": "Coclé",
        "timezone": "America/Panama"
    },
    {
        "name": "Chitre",
        "country": "Panama",
        "province": "Herrera",
        "timezone": "America/Panama"
    },
    {
        "name": "Jaque",
        "country": "Panama",
        "province": "Darién",
        "timezone": "America/Panama"
    },
    {
        "name": "Bocas del Toro",
        "country": "Panama",
        "province": "Bocas del Toro",
        "timezone": "America/Panama"
    },
    {
        "name": "Almirante",
        "country": "Panama",
        "province": "Bocas del Toro",
        "timezone": "America/Panama"
    },
    {
        "name": "Las Tablas",
        "country": "Panama",
        "province": "Los Santos",
        "timezone": "America/Panama"
    },
    {
        "name": "Santiago",
        "country": "Panama",
        "province": "Veraguas",
        "timezone": "America/Panama"
    },
    {
        "name": "La Palma",
        "country": "Panama",
        "province": "Darién",
        "timezone": "America/Panama"
    },
    {
        "name": "Colon",
        "country": "Panama",
        "province": "Colón",
        "timezone": "America/Panama"
    },
    {
        "name": "Balboa",
        "country": "Panama",
        "province": "Panama",
        "timezone": "America/Panama"
    },
    {
        "name": "Puerto Armuelles",
        "country": "Panama",
        "province": "Chiriquí",
        "timezone": "America/Panama"
    },
    {
        "name": "David",
        "country": "Panama",
        "province": "Chiriquí",
        "timezone": "America/Panama"
    },
    {
        "name": "Panama City",
        "country": "Panama",
        "province": "Panama",
        "timezone": "America/Panama"
    },
    {
        "name": "Wabag",
        "country": "Papua New Guinea",
        "province": "Enga",
        "timezone": "Pacific/Port_Moresby"
    },
    {
        "name": "Vanimo",
        "country": "Papua New Guinea",
        "province": "Sandaun",
        "timezone": "Pacific/Port_Moresby"
    },
    {
        "name": "Kundiawa",
        "country": "Papua New Guinea",
        "province": "Chimbu",
        "timezone": "Pacific/Port_Moresby"
    },
    {
        "name": "Kerema",
        "country": "Papua New Guinea",
        "province": "Gulf",
        "timezone": "Pacific/Port_Moresby"
    },
    {
        "name": "Arawa",
        "country": "Papua New Guinea",
        "province": "North Solomons",
        "timezone": "Pacific/Bougainville"
    },
    {
        "name": "Lorengau",
        "country": "Papua New Guinea",
        "province": "Manus",
        "timezone": "Pacific/Port_Moresby"
    },
    {
        "name": "Kimbe",
        "country": "Papua New Guinea",
        "province": "West New Britain",
        "timezone": "Pacific/Port_Moresby"
    },
    {
        "name": "Daru",
        "country": "Papua New Guinea",
        "province": "",
        "timezone": "Pacific/Port_Moresby"
    },
    {
        "name": "Sohano",
        "country": "Papua New Guinea",
        "province": "North Solomons",
        "timezone": "Pacific/Bougainville"
    },
    {
        "name": "Kieta",
        "country": "Papua New Guinea",
        "province": "North Solomons",
        "timezone": "Pacific/Bougainville"
    },
    {
        "name": "Mendi",
        "country": "Papua New Guinea",
        "province": "Southern Highlands",
        "timezone": "Pacific/Port_Moresby"
    },
    {
        "name": "Abau",
        "country": "Papua New Guinea",
        "province": "Central",
        "timezone": "Pacific/Port_Moresby"
    },
    {
        "name": "Alotau",
        "country": "Papua New Guinea",
        "province": "Milne Bay",
        "timezone": "Pacific/Port_Moresby"
    },
    {
        "name": "Popondetta",
        "country": "Papua New Guinea",
        "province": "Northern",
        "timezone": "Pacific/Port_Moresby"
    },
    {
        "name": "Hoskins",
        "country": "Papua New Guinea",
        "province": "West New Britain",
        "timezone": "Pacific/Port_Moresby"
    },
    {
        "name": "Wewak",
        "country": "Papua New Guinea",
        "province": "East Sepik",
        "timezone": "Pacific/Port_Moresby"
    },
    {
        "name": "Madang",
        "country": "Papua New Guinea",
        "province": "Madang",
        "timezone": "Pacific/Port_Moresby"
    },
    {
        "name": "Kavieng",
        "country": "Papua New Guinea",
        "province": "New Ireland",
        "timezone": "Pacific/Port_Moresby"
    },
    {
        "name": "Goroka",
        "country": "Papua New Guinea",
        "province": "Eastern Highlands",
        "timezone": "Pacific/Port_Moresby"
    },
    {
        "name": "Mt. Hagen",
        "country": "Papua New Guinea",
        "province": "Western Highlands",
        "timezone": "Pacific/Port_Moresby"
    },
    {
        "name": "Rabaul",
        "country": "Papua New Guinea",
        "province": "East New Britain",
        "timezone": "Pacific/Port_Moresby"
    },
    {
        "name": "Lae",
        "country": "Papua New Guinea",
        "province": "Morobe",
        "timezone": "Pacific/Port_Moresby"
    },
    {
        "name": "Port Moresby",
        "country": "Papua New Guinea",
        "province": "Central",
        "timezone": "Pacific/Port_Moresby"
    },
    {
        "name": "Mariscal Estigarribia",
        "country": "Paraguay",
        "province": "Boquerón",
        "timezone": "America/Asuncion"
    },
    {
        "name": "Caacupe",
        "country": "Paraguay",
        "province": "Cordillera",
        "timezone": "America/Asuncion"
    },
    {
        "name": "General Eugenio Alejandrino Garay",
        "country": "Paraguay",
        "province": "Boquerón",
        "timezone": "America/Asuncion"
    },
    {
        "name": "Arroyos y Esteros",
        "country": "Paraguay",
        "province": "Cordillera",
        "timezone": "America/Asuncion"
    },
    {
        "name": "Villa Hayes",
        "country": "Paraguay",
        "province": "Presidente Hayes",
        "timezone": "America/Asuncion"
    },
    {
        "name": "Fortin Falcon",
        "country": "Paraguay",
        "province": "Presidente Hayes",
        "timezone": "America/Asuncion"
    },
    {
        "name": "Puerto Pinasco",
        "country": "Paraguay",
        "province": "Presidente Hayes",
        "timezone": "America/Asuncion"
    },
    {
        "name": "Pozo Colorado",
        "country": "Paraguay",
        "province": "Presidente Hayes",
        "timezone": "America/Asuncion"
    },
    {
        "name": "San Pedro",
        "country": "Paraguay",
        "province": "San Pedro",
        "timezone": "America/Asuncion"
    },
    {
        "name": "San Lorenzo",
        "country": "Paraguay",
        "province": "Asunción",
        "timezone": "America/Asuncion"
    },
    {
        "name": "Ypacarai",
        "country": "Paraguay",
        "province": "Asunción",
        "timezone": "America/Asuncion"
    },
    {
        "name": "San Juan Bautista",
        "country": "Paraguay",
        "province": "Misiones",
        "timezone": "America/Asuncion"
    },
    {
        "name": "Paraguari",
        "country": "Paraguay",
        "province": "Paraguarí",
        "timezone": "America/Asuncion"
    },
    {
        "name": "Nacunday",
        "country": "Paraguay",
        "province": "Alto Paraná",
        "timezone": "America/Asuncion"
    },
    {
        "name": "Coronel Oviedo",
        "country": "Paraguay",
        "province": "Caaguazú",
        "timezone": "America/Asuncion"
    },
    {
        "name": "Caazapa",
        "country": "Paraguay",
        "province": "Caazapá",
        "timezone": "America/Asuncion"
    },
    {
        "name": "Ype Jhu",
        "country": "Paraguay",
        "province": "Canindeyú",
        "timezone": "America/Asuncion"
    },
    {
        "name": "Encarnacion",
        "country": "Paraguay",
        "province": "Itapúa",
        "timezone": "America/Asuncion"
    },
    {
        "name": "Coronel Bogado",
        "country": "Paraguay",
        "province": "Itapúa",
        "timezone": "America/Asuncion"
    },
    {
        "name": "Fuerte Olimpo",
        "country": "Paraguay",
        "province": "Alto Paraguay",
        "timezone": "America/Asuncion"
    },
    {
        "name": "Capitan Pablo Lagerenza",
        "country": "Paraguay",
        "province": "Alto Paraguay",
        "timezone": "America/Asuncion"
    },
    {
        "name": "La Victoria",
        "country": "Paraguay",
        "province": "Alto Paraguay",
        "timezone": "America/Asuncion"
    },
    {
        "name": "Horqueta",
        "country": "Paraguay",
        "province": "Concepción",
        "timezone": "America/Asuncion"
    },
    {
        "name": "Belen",
        "country": "Paraguay",
        "province": "Concepción",
        "timezone": "America/Asuncion"
    },
    {
        "name": "Rosario",
        "country": "Paraguay",
        "province": "San Pedro",
        "timezone": "America/Asuncion"
    },
    {
        "name": "Ita",
        "country": "Paraguay",
        "province": "Asunción",
        "timezone": "America/Asuncion"
    },
    {
        "name": "Pilar",
        "country": "Paraguay",
        "province": "Ñeembucú",
        "timezone": "America/Asuncion"
    },
    {
        "name": "Pedro Juan Caballero",
        "country": "Paraguay",
        "province": "Amambay",
        "timezone": "America/Asuncion"
    },
    {
        "name": "Bella Vista",
        "country": "Paraguay",
        "province": "Amambay",
        "timezone": "America/Asuncion"
    },
    {
        "name": "Abai",
        "country": "Paraguay",
        "province": "Caazapá",
        "timezone": "America/Asuncion"
    },
    {
        "name": "Ygatimi",
        "country": "Paraguay",
        "province": "Canindeyú",
        "timezone": "America/Asuncion"
    },
    {
        "name": "Hohenau",
        "country": "Paraguay",
        "province": "Itapúa",
        "timezone": "America/Asuncion"
    },
    {
        "name": "Concepcion",
        "country": "Paraguay",
        "province": "Concepción",
        "timezone": "America/Asuncion"
    },
    {
        "name": "Villarrica",
        "country": "Paraguay",
        "province": "Guairá",
        "timezone": "America/Asuncion"
    },
    {
        "name": "Filadelfia",
        "country": "Paraguay",
        "province": "Boquerón",
        "timezone": "America/Asuncion"
    },
    {
        "name": "Ciudad del Este",
        "country": "Paraguay",
        "province": "Alto Paraná",
        "timezone": "America/Asuncion"
    },
    {
        "name": "Asuncion",
        "country": "Paraguay",
        "province": "Asunción",
        "timezone": "America/Asuncion"
    },
    {
        "name": "Ferrenafe",
        "country": "Peru",
        "province": "Lambayeque",
        "timezone": "America/Lima"
    },
    {
        "name": "Motupe",
        "country": "Peru",
        "province": "Lambayeque",
        "timezone": "America/Lima"
    },
    {
        "name": "Mollendo",
        "country": "Peru",
        "province": "Arequipa",
        "timezone": "America/Lima"
    },
    {
        "name": "Urubamba",
        "country": "Peru",
        "province": "Cusco",
        "timezone": "America/Lima"
    },
    {
        "name": "Santo Tomas",
        "country": "Peru",
        "province": "Cusco",
        "timezone": "America/Lima"
    },
    {
        "name": "Putina",
        "country": "Peru",
        "province": "Callao",
        "timezone": "America/Lima"
    },
    {
        "name": "Casma",
        "country": "Peru",
        "province": "Ancash",
        "timezone": "America/Lima"
    },
    {
        "name": "Tournavista",
        "country": "Peru",
        "province": "Huánuco",
        "timezone": "America/Lima"
    },
    {
        "name": "Huamachuco",
        "country": "Peru",
        "province": "La Libertad",
        "timezone": "America/Lima"
    },
    {
        "name": "Otuzco",
        "country": "Peru",
        "province": "La Libertad",
        "timezone": "America/Lima"
    },
    {
        "name": "Lamas",
        "country": "Peru",
        "province": "San Martín",
        "timezone": "America/Lima"
    },
    {
        "name": "Nauta",
        "country": "Peru",
        "province": "Loreto",
        "timezone": "America/Lima"
    },
    {
        "name": "Puquio",
        "country": "Peru",
        "province": "Ayacucho",
        "timezone": "America/Lima"
    },
    {
        "name": "Chosica",
        "country": "Peru",
        "province": "Lima",
        "timezone": "America/Lima"
    },
    {
        "name": "Satipo",
        "country": "Peru",
        "province": "Junín",
        "timezone": "America/Lima"
    },
    {
        "name": "Tarma",
        "country": "Peru",
        "province": "Junín",
        "timezone": "America/Lima"
    },
    {
        "name": "La Oroya",
        "country": "Peru",
        "province": "Junín",
        "timezone": "America/Lima"
    },
    {
        "name": "Huaura",
        "country": "Peru",
        "province": "Lima",
        "timezone": "America/Lima"
    },
    {
        "name": "Huacho",
        "country": "Peru",
        "province": "Lima",
        "timezone": "America/Lima"
    },
    {
        "name": "Pimentel",
        "country": "Peru",
        "province": "Lambayeque",
        "timezone": "America/Lima"
    },
    {
        "name": "Olmos",
        "country": "Peru",
        "province": "Lambayeque",
        "timezone": "America/Lima"
    },
    {
        "name": "Sechura",
        "country": "Peru",
        "province": "Piura",
        "timezone": "America/Lima"
    },
    {
        "name": "Chulucanas",
        "country": "Peru",
        "province": "Piura",
        "timezone": "America/Lima"
    },
    {
        "name": "Sullana",
        "country": "Peru",
        "province": "Piura",
        "timezone": "America/Lima"
    },
    {
        "name": "Abancay",
        "country": "Peru",
        "province": "Apurímac",
        "timezone": "America/Lima"
    },
    {
        "name": "Camana",
        "country": "Peru",
        "province": "Arequipa",
        "timezone": "America/Lima"
    },
    {
        "name": "Sicuani",
        "country": "Peru",
        "province": "Cusco",
        "timezone": "America/Lima"
    },
    {
        "name": "Puno",
        "country": "Peru",
        "province": "Callao",
        "timezone": "America/Lima"
    },
    {
        "name": "Ayaviri",
        "country": "Peru",
        "province": "Callao",
        "timezone": "America/Lima"
    },
    {
        "name": "Ilave",
        "country": "Peru",
        "province": "Callao",
        "timezone": "America/Lima"
    },
    {
        "name": "Desaguadero",
        "country": "Peru",
        "province": "Callao",
        "timezone": "America/Lima"
    },
    {
        "name": "Huarmey",
        "country": "Peru",
        "province": "Ancash",
        "timezone": "America/Lima"
    },
    {
        "name": "Cajabamba",
        "country": "Peru",
        "province": "Cajamarca",
        "timezone": "America/Lima"
    },
    {
        "name": "Jaen",
        "country": "Peru",
        "province": "Cajamarca",
        "timezone": "America/Lima"
    },
    {
        "name": "Chota",
        "country": "Peru",
        "province": "Cajamarca",
        "timezone": "America/Lima"
    },
    {
        "name": "Tingo Maria",
        "country": "Peru",
        "province": "Huánuco",
        "timezone": "America/Lima"
    },
    {
        "name": "Moyobamba",
        "country": "Peru",
        "province": "San Martín",
        "timezone": "America/Lima"
    },
    {
        "name": "Juanjui",
        "country": "Peru",
        "province": "San Martín",
        "timezone": "America/Lima"
    },
    {
        "name": "Tocache",
        "country": "Peru",
        "province": "San Martín",
        "timezone": "America/Lima"
    },
    {
        "name": "Sechura",
        "country": "Peru",
        "province": "Amazonas",
        "timezone": "America/Lima"
    },
    {
        "name": "Chachapoyas",
        "country": "Peru",
        "province": "Amazonas",
        "timezone": "America/Lima"
    },
    {
        "name": "Caballococha",
        "country": "Peru",
        "province": "Loreto",
        "timezone": "America/Lima"
    },
    {
        "name": "Puca Urco",
        "country": "Peru",
        "province": "Loreto",
        "timezone": "America/Lima"
    },
    {
        "name": "Andoas",
        "country": "Peru",
        "province": "Loreto",
        "timezone": "America/Lima"
    },
    {
        "name": "Soldado Bartra",
        "country": "Peru",
        "province": "Loreto",
        "timezone": "America/Lima"
    },
    {
        "name": "Nuevo Rocafuerte",
        "country": "Peru",
        "province": "Loreto",
        "timezone": "America/Guayaquil"
    },
    {
        "name": "Requena",
        "country": "Peru",
        "province": "Loreto",
        "timezone": "America/Lima"
    },
    {
        "name": "Huanta",
        "country": "Peru",
        "province": "Ayacucho",
        "timezone": "America/Lima"
    },
    {
        "name": "Coracora",
        "country": "Peru",
        "province": "Ayacucho",
        "timezone": "America/Lima"
    },
    {
        "name": "Chincha Alta",
        "country": "Peru",
        "province": "Ica",
        "timezone": "America/Lima"
    },
    {
        "name": "Santiago",
        "country": "Peru",
        "province": "Ica",
        "timezone": "America/Lima"
    },
    {
        "name": "San Ramon",
        "country": "Peru",
        "province": "Junín",
        "timezone": "America/Lima"
    },
    {
        "name": "Junin",
        "country": "Peru",
        "province": "Junín",
        "timezone": "America/Lima"
    },
    {
        "name": "Jauja",
        "country": "Peru",
        "province": "Junín",
        "timezone": "America/Lima"
    },
    {
        "name": "Pativilca",
        "country": "Peru",
        "province": "Lima",
        "timezone": "America/Lima"
    },
    {
        "name": "Chancay",
        "country": "Peru",
        "province": "Lima",
        "timezone": "America/Lima"
    },
    {
        "name": "Chilca",
        "country": "Peru",
        "province": "Lima",
        "timezone": "America/Lima"
    },
    {
        "name": "Chiclayo",
        "country": "Peru",
        "province": "Lambayeque",
        "timezone": "America/Lima"
    },
    {
        "name": "Juliaca",
        "country": "Peru",
        "province": "Callao",
        "timezone": "America/Lima"
    },
    {
        "name": "Cerro de Pasco",
        "country": "Peru",
        "province": "Pasco",
        "timezone": "America/Lima"
    },
    {
        "name": "Tarapoto",
        "country": "Peru",
        "province": "San Martín",
        "timezone": "America/Lima"
    },
    {
        "name": "Ayacucho",
        "country": "Peru",
        "province": "Ayacucho",
        "timezone": "America/Lima"
    },
    {
        "name": "Callao",
        "country": "Peru",
        "province": "Lima",
        "timezone": "America/Lima"
    },
    {
        "name": "Paita",
        "country": "Peru",
        "province": "Piura",
        "timezone": "America/Lima"
    },
    {
        "name": "Talara",
        "country": "Peru",
        "province": "Piura",
        "timezone": "America/Lima"
    },
    {
        "name": "Tumbes",
        "country": "Peru",
        "province": "Tumbes",
        "timezone": "America/Lima"
    },
    {
        "name": "Puerto Maldonado",
        "country": "Peru",
        "province": "Madre de Dios",
        "timezone": "America/Lima"
    },
    {
        "name": "Ilo",
        "country": "Peru",
        "province": "Moquegua",
        "timezone": "America/Lima"
    },
    {
        "name": "Moquegua",
        "country": "Peru",
        "province": "Moquegua",
        "timezone": "America/Lima"
    },
    {
        "name": "Huaraz",
        "country": "Peru",
        "province": "Ancash",
        "timezone": "America/Lima"
    },
    {
        "name": "Cajamarca",
        "country": "Peru",
        "province": "Cajamarca",
        "timezone": "America/Lima"
    },
    {
        "name": "Huanuco",
        "country": "Peru",
        "province": "Huánuco",
        "timezone": "America/Lima"
    },
    {
        "name": "Pacasmayo",
        "country": "Peru",
        "province": "La Libertad",
        "timezone": "America/Lima"
    },
    {
        "name": "Salaverry",
        "country": "Peru",
        "province": "La Libertad",
        "timezone": "America/Lima"
    },
    {
        "name": "Gueppi",
        "country": "Peru",
        "province": "Loreto",
        "timezone": "America/Lima"
    },
    {
        "name": "Contamana",
        "country": "Peru",
        "province": "Loreto",
        "timezone": "America/Lima"
    },
    {
        "name": "Huancavelica",
        "country": "Peru",
        "province": "Huancavelica",
        "timezone": "America/Lima"
    },
    {
        "name": "Pisco",
        "country": "Peru",
        "province": "Ica",
        "timezone": "America/Lima"
    },
    {
        "name": "Nasca",
        "country": "Peru",
        "province": "Ica",
        "timezone": "America/Lima"
    },
    {
        "name": "Piura",
        "country": "Peru",
        "province": "Piura",
        "timezone": "America/Lima"
    },
    {
        "name": "Arequipa",
        "country": "Peru",
        "province": "Arequipa",
        "timezone": "America/Lima"
    },
    {
        "name": "Chimbote",
        "country": "Peru",
        "province": "Ancash",
        "timezone": "America/Lima"
    },
    {
        "name": "Pucallpa",
        "country": "Peru",
        "province": "Ucayali",
        "timezone": "America/Lima"
    },
    {
        "name": "Iquitos",
        "country": "Peru",
        "province": "Loreto",
        "timezone": "America/Lima"
    },
    {
        "name": "Huancayo",
        "country": "Peru",
        "province": "Junín",
        "timezone": "America/Lima"
    },
    {
        "name": "Cusco",
        "country": "Peru",
        "province": "Cusco",
        "timezone": "America/Lima"
    },
    {
        "name": "Tacna",
        "country": "Peru",
        "province": "Tacna",
        "timezone": "America/Lima"
    },
    {
        "name": "Trujillo",
        "country": "Peru",
        "province": "La Libertad",
        "timezone": "America/Lima"
    },
    {
        "name": "Ica",
        "country": "Peru",
        "province": "Ica",
        "timezone": "America/Lima"
    },
    {
        "name": "Lima",
        "country": "Peru",
        "province": "Lima",
        "timezone": "America/Lima"
    },
    {
        "name": "San Carlos",
        "country": "Philippines",
        "province": "Negros Occidental",
        "timezone": "Asia/Manila"
    },
    {
        "name": "Cadiz",
        "country": "Philippines",
        "province": "Negros Occidental",
        "timezone": "Asia/Manila"
    },
    {
        "name": "Pagadian",
        "country": "Philippines",
        "province": "Zamboanga Del Sur",
        "timezone": "Asia/Manila"
    },
    {
        "name": "Ozamis",
        "country": "Philippines",
        "province": "Misamis Occidental",
        "timezone": "Asia/Manila"
    },
    {
        "name": "Tarlac",
        "country": "Philippines",
        "province": "Tarlac",
        "timezone": "Asia/Manila"
    },
    {
        "name": "Cabanatuan",
        "country": "Philippines",
        "province": "Nueva Ecija",
        "timezone": "Asia/Manila"
    },
    {
        "name": "Olongapo",
        "country": "Philippines",
        "province": "Zambales",
        "timezone": "Asia/Manila"
    },
    {
        "name": "Dagupan",
        "country": "Philippines",
        "province": "Pangasinan",
        "timezone": "Asia/Manila"
    },
    {
        "name": "San Pablo",
        "country": "Philippines",
        "province": "Laguna",
        "timezone": "Asia/Manila"
    },
    {
        "name": "Quezon City",
        "country": "Philippines",
        "province": "Metropolitan Manila",
        "timezone": "Asia/Manila"
    },
    {
        "name": "Pasay City",
        "country": "Philippines",
        "province": "Metropolitan Manila",
        "timezone": "Asia/Manila"
    },
    {
        "name": "Iligan",
        "country": "Philippines",
        "province": "Lanao del Norte",
        "timezone": "Asia/Manila"
    },
    {
        "name": "Ormac",
        "country": "Philippines",
        "province": "Leyte",
        "timezone": "Asia/Manila"
    },
    {
        "name": "Tacloban",
        "country": "Philippines",
        "province": "Leyte",
        "timezone": "Asia/Manila"
    },
    {
        "name": "Butuan",
        "country": "Philippines",
        "province": "Agusan del Norte",
        "timezone": "Asia/Manila"
    },
    {
        "name": "Tagum",
        "country": "Philippines",
        "province": "Davao del Norte",
        "timezone": "Asia/Manila"
    },
    {
        "name": "Surigao",
        "country": "Philippines",
        "province": "Dinagat Islands",
        "timezone": "Asia/Manila"
    },
    {
        "name": "Gingoog",
        "country": "Philippines",
        "province": "Misamis Oriental",
        "timezone": "Asia/Manila"
    },
    {
        "name": "Legazpi",
        "country": "Philippines",
        "province": "Albay",
        "timezone": "Asia/Manila"
    },
    {
        "name": "Tuguegarao",
        "country": "Philippines",
        "province": "Cagayan",
        "timezone": "Asia/Manila"
    },
    {
        "name": "Vigan",
        "country": "Philippines",
        "province": "Ilocos Sur",
        "timezone": "Asia/Manila"
    },
    {
        "name": "Bacolod",
        "country": "Philippines",
        "province": "Negros Occidental",
        "timezone": "Asia/Manila"
    },
    {
        "name": "Roxas",
        "country": "Philippines",
        "province": "Capiz",
        "timezone": "Asia/Manila"
    },
    {
        "name": "Puerto Princesa",
        "country": "Philippines",
        "province": "Palawan",
        "timezone": "Asia/Manila"
    },
    {
        "name": "Naga",
        "country": "Philippines",
        "province": "Camarines Sur",
        "timezone": "Asia/Manila"
    },
    {
        "name": "Angeles",
        "country": "Philippines",
        "province": "Pampanga",
        "timezone": "Asia/Manila"
    },
    {
        "name": "Batangas",
        "country": "Philippines",
        "province": "Batangas",
        "timezone": "Asia/Manila"
    },
    {
        "name": "Cotabato",
        "country": "Philippines",
        "province": "Shariff Kabunsuan",
        "timezone": "Asia/Manila"
    },
    {
        "name": "Calbayog",
        "country": "Philippines",
        "province": "Samar",
        "timezone": "Asia/Manila"
    },
    {
        "name": "Cagayan de Oro",
        "country": "Philippines",
        "province": "Misamis Oriental",
        "timezone": "Asia/Manila"
    },
    {
        "name": "Zamboanga",
        "country": "Philippines",
        "province": "Zamboanga del Sur",
        "timezone": "Asia/Manila"
    },
    {
        "name": "Laoag",
        "country": "Philippines",
        "province": "Ilocos Norte",
        "timezone": "Asia/Manila"
    },
    {
        "name": "Baguio City",
        "country": "Philippines",
        "province": "Benguet",
        "timezone": "Asia/Manila"
    },
    {
        "name": "General Santos",
        "country": "Philippines",
        "province": "South Cotabato",
        "timezone": "Asia/Manila"
    },
    {
        "name": "Cebu",
        "country": "Philippines",
        "province": "Cebu",
        "timezone": "Asia/Manila"
    },
    {
        "name": "Iloilo",
        "country": "Philippines",
        "province": "Iloilo",
        "timezone": "Asia/Manila"
    },
    {
        "name": "Davao",
        "country": "Philippines",
        "province": "Davao Del Sur",
        "timezone": "Asia/Manila"
    },
    {
        "name": "Manila",
        "country": "Philippines",
        "province": "Metropolitan Manila",
        "timezone": "Asia/Manila"
    },
    {
        "name": "Olsztyn",
        "country": "Poland",
        "province": "Warmian-Masurian",
        "timezone": "Europe/Warsaw"
    },
    {
        "name": "Elblag",
        "country": "Poland",
        "province": "Warmian-Masurian",
        "timezone": "Europe/Warsaw"
    },
    {
        "name": "Inowroclaw",
        "country": "Poland",
        "province": "Kuyavian-Pomeranian",
        "timezone": "Europe/Warsaw"
    },
    {
        "name": "Bytom",
        "country": "Poland",
        "province": "Silesian",
        "timezone": "Europe/Warsaw"
    },
    {
        "name": "Opole",
        "country": "Poland",
        "province": "Opole",
        "timezone": "Europe/Warsaw"
    },
    {
        "name": "Koszalin",
        "country": "Poland",
        "province": "West Pomeranian",
        "timezone": "Europe/Warsaw"
    },
    {
        "name": "Elk",
        "country": "Poland",
        "province": "Warmian-Masurian",
        "timezone": "Europe/Warsaw"
    },
    {
        "name": "Gdynia",
        "country": "Poland",
        "province": "Pomeranian",
        "timezone": "Europe/Warsaw"
    },
    {
        "name": "Wroclaw",
        "country": "Poland",
        "province": "Lower Silesian",
        "timezone": "Europe/Warsaw"
    },
    {
        "name": "Szczecin",
        "country": "Poland",
        "province": "West Pomeranian",
        "timezone": "Europe/Warsaw"
    },
    {
        "name": "Zielona Gora",
        "country": "Poland",
        "province": "Lubusz",
        "timezone": "Europe/Warsaw"
    },
    {
        "name": "Poznan",
        "country": "Poland",
        "province": "Greater Poland",
        "timezone": "Europe/Warsaw"
    },
    {
        "name": "Grudziadz",
        "country": "Poland",
        "province": "Kuyavian-Pomeranian",
        "timezone": "Europe/Warsaw"
    },
    {
        "name": "Bydgoszcz",
        "country": "Poland",
        "province": "Kuyavian-Pomeranian",
        "timezone": "Europe/Warsaw"
    },
    {
        "name": "Katowice",
        "country": "Poland",
        "province": "Silesian",
        "timezone": "Europe/Warsaw"
    },
    {
        "name": "Gliwice",
        "country": "Poland",
        "province": "Silesian",
        "timezone": "Europe/Warsaw"
    },
    {
        "name": "Kielce",
        "country": "Poland",
        "province": "Swietokrzyskie",
        "timezone": "Europe/Warsaw"
    },
    {
        "name": "Bialystok",
        "country": "Poland",
        "province": "Podlachian",
        "timezone": "Europe/Warsaw"
    },
    {
        "name": "Lublin",
        "country": "Poland",
        "province": "Lublin",
        "timezone": "Europe/Warsaw"
    },
    {
        "name": "Rzeszow",
        "country": "Poland",
        "province": "Subcarpathian",
        "timezone": "Europe/Warsaw"
    },
    {
        "name": "Lódz",
        "country": "Poland",
        "province": "Lódz",
        "timezone": "Europe/Warsaw"
    },
    {
        "name": "Gdansk",
        "country": "Poland",
        "province": "Pomeranian",
        "timezone": "Europe/Warsaw"
    },
    {
        "name": "Kraków",
        "country": "Poland",
        "province": "Lesser Poland",
        "timezone": "Europe/Warsaw"
    },
    {
        "name": "Warsaw",
        "country": "Poland",
        "province": "Masovian",
        "timezone": "Europe/Warsaw"
    },
    {
        "name": "Aveiro",
        "country": "Portugal",
        "province": "Aveiro",
        "timezone": "Europe/Lisbon"
    },
    {
        "name": "Leiria",
        "country": "Portugal",
        "province": "Leiria",
        "timezone": "Europe/Lisbon"
    },
    {
        "name": "Viana Do Castelo",
        "country": "Portugal",
        "province": "Viana do Castelo",
        "timezone": "Europe/Lisbon"
    },
    {
        "name": "Beja",
        "country": "Portugal",
        "province": "Beja",
        "timezone": "Europe/Lisbon"
    },
    {
        "name": "Evora",
        "country": "Portugal",
        "province": "Évora",
        "timezone": "Europe/Lisbon"
    },
    {
        "name": "Portalegre",
        "country": "Portugal",
        "province": "Portalegre",
        "timezone": "Europe/Lisbon"
    },
    {
        "name": "Santarem",
        "country": "Portugal",
        "province": "Santarém",
        "timezone": "Europe/Lisbon"
    },
    {
        "name": "Braganca",
        "country": "Portugal",
        "province": "Bragança",
        "timezone": "Europe/Lisbon"
    },
    {
        "name": "Castelo Branco",
        "country": "Portugal",
        "province": "Castelo Branco",
        "timezone": "Europe/Lisbon"
    },
    {
        "name": "Guarda",
        "country": "Portugal",
        "province": "Guarda",
        "timezone": "Europe/Lisbon"
    },
    {
        "name": "Viseu",
        "country": "Portugal",
        "province": "Viseu",
        "timezone": "Europe/Lisbon"
    },
    {
        "name": "Vila Real",
        "country": "Portugal",
        "province": "Vila Real",
        "timezone": "Europe/Lisbon"
    },
    {
        "name": "Braga",
        "country": "Portugal",
        "province": "Braga",
        "timezone": "Europe/Lisbon"
    },
    {
        "name": "Covilha",
        "country": "Portugal",
        "province": "Castelo Branco",
        "timezone": "Europe/Lisbon"
    },
    {
        "name": "Horta",
        "country": "Portugal",
        "province": "Azores",
        "timezone": "Atlantic/Azores"
    },
    {
        "name": "Angra do Heroismo",
        "country": "Portugal",
        "province": "Azores",
        "timezone": "Atlantic/Azores"
    },
    {
        "name": "Portimao",
        "country": "Portugal",
        "province": "Faro",
        "timezone": "Europe/Lisbon"
    },
    {
        "name": "Faro",
        "country": "Portugal",
        "province": "Faro",
        "timezone": "Europe/Lisbon"
    },
    {
        "name": "Coimbra",
        "country": "Portugal",
        "province": "Coimbra",
        "timezone": "Europe/Lisbon"
    },
    {
        "name": "Setubal",
        "country": "Portugal",
        "province": "Lisboa",
        "timezone": "Europe/Lisbon"
    },
    {
        "name": "Porto",
        "country": "Portugal",
        "province": "Porto",
        "timezone": "Europe/Lisbon"
    },
    {
        "name": "Funchal",
        "country": "Portugal",
        "province": "Madeira",
        "timezone": "Atlantic/Madeira"
    },
    {
        "name": "Ponta Delgada",
        "country": "Portugal",
        "province": "Azores",
        "timezone": "Atlantic/Azores"
    },
    {
        "name": "Lisbon",
        "country": "Portugal",
        "province": "Lisboa",
        "timezone": "Europe/Lisbon"
    },
    {
        "name": "Ponce",
        "country": "Puerto Rico",
        "province": "",
        "timezone": "America/Puerto_Rico"
    },
    {
        "name": "Mayaguez",
        "country": "Puerto Rico",
        "province": "",
        "timezone": "America/Puerto_Rico"
    },
    {
        "name": "Arecibo",
        "country": "Puerto Rico",
        "province": "",
        "timezone": "America/Puerto_Rico"
    },
    {
        "name": "San Juan",
        "country": "Puerto Rico",
        "province": "",
        "timezone": "America/Puerto_Rico"
    },
    {
        "name": "Doha",
        "country": "Qatar",
        "province": "Ad Dawhah",
        "timezone": "Asia/Qatar"
    },
    {
        "name": "Targu Jiu",
        "country": "Romania",
        "province": "Gorj",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Slatina",
        "country": "Romania",
        "province": "Olt",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Alexandria",
        "country": "Romania",
        "province": "Teleorman",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Targoviste",
        "country": "Romania",
        "province": "Dâmbovita",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Giurgiu",
        "country": "Romania",
        "province": "Giurgiu",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Slobozia",
        "country": "Romania",
        "province": "Ialomita",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Alba Lulia",
        "country": "Romania",
        "province": "Alba",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Bistrita",
        "country": "Romania",
        "province": "Bistrita-Nasaud",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Deva",
        "country": "Romania",
        "province": "Hunedoara",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Zalau",
        "country": "Romania",
        "province": "Salaj",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Satu Mare",
        "country": "Romania",
        "province": "Satu Mare",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Rimnicu Vilcea",
        "country": "Romania",
        "province": "Vâlcea",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Sfintu-Gheorghe",
        "country": "Romania",
        "province": "Covasna",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Miercurea Cuic",
        "country": "Romania",
        "province": "Harghita",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Piatra-Neamt",
        "country": "Romania",
        "province": "Neamt",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Braila",
        "country": "Romania",
        "province": "Braila",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Vaslui",
        "country": "Romania",
        "province": "Vaslui",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Drobeta-Turnu Severin",
        "country": "Romania",
        "province": "Mehedinti",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Tulcea",
        "country": "Romania",
        "province": "Constanta",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Arad",
        "country": "Romania",
        "province": "Arad",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Oradea",
        "country": "Romania",
        "province": "Bihor",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Sibiu",
        "country": "Romania",
        "province": "Sibiu",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Suceava",
        "country": "Romania",
        "province": "Suceava",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Buzau",
        "country": "Romania",
        "province": "Buzau",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Galati",
        "country": "Romania",
        "province": "Galati",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Focsani",
        "country": "Romania",
        "province": "Vrancea",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Craiova",
        "country": "Romania",
        "province": "Dolj",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Calarasi",
        "country": "Romania",
        "province": "Calarasi",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Resita",
        "country": "Romania",
        "province": "Caras-Severin",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Timisoara",
        "country": "Romania",
        "province": "Timis",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Botosani",
        "country": "Romania",
        "province": "Botosani",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Baia Mare",
        "country": "Romania",
        "province": "Maramures",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Tirgu Mures",
        "country": "Romania",
        "province": "Mures",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Pitesti",
        "country": "Romania",
        "province": "Arges",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Brasov",
        "country": "Romania",
        "province": "Brasov",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Ploiesti",
        "country": "Romania",
        "province": "Prahova",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Bacau",
        "country": "Romania",
        "province": "Bacau",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Cluj-Napoca",
        "country": "Romania",
        "province": "Cluj",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Constanta",
        "country": "Romania",
        "province": "Constanta",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Iasi",
        "country": "Romania",
        "province": "Iasi",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Bucharest",
        "country": "Romania",
        "province": "Bucharest",
        "timezone": "Europe/Bucharest"
    },
    {
        "name": "Nazran",
        "country": "Russia",
        "province": "Ingush",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Ust' Ordynskiy",
        "country": "Russia",
        "province": "Ust-Orda Buryat",
        "timezone": "Asia/Irkutsk"
    },
    {
        "name": "Maykop",
        "country": "Russia",
        "province": "Adygey",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Mozdok",
        "country": "Russia",
        "province": "North Ossetia",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Georgievsk",
        "country": "Russia",
        "province": "Stavropol'",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Pyatigorsk",
        "country": "Russia",
        "province": "Stavropol'",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Kislovodsk",
        "country": "Russia",
        "province": "Stavropol'",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Nevinnomyssk",
        "country": "Russia",
        "province": "Stavropol'",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Enurmino",
        "country": "Russia",
        "province": "Chukchi Autonomous Okrug",
        "timezone": "Asia/Anadyr"
    },
    {
        "name": "Lavrentiya",
        "country": "Russia",
        "province": "Chukchi Autonomous Okrug",
        "timezone": "Asia/Anadyr"
    },
    {
        "name": "Zvezdnyy",
        "country": "Russia",
        "province": "Chukchi Autonomous Okrug",
        "timezone": "Asia/Anadyr"
    },
    {
        "name": "Mikhalkino",
        "country": "Russia",
        "province": "Koryak",
        "timezone": "Asia/Srednekolymsk"
    },
    {
        "name": "Chernyakhovsk",
        "country": "Russia",
        "province": "Kaliningrad",
        "timezone": "Europe/Kaliningrad"
    },
    {
        "name": "Severomorsk",
        "country": "Russia",
        "province": "Murmansk",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Apatity",
        "country": "Russia",
        "province": "Murmansk",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Polyarnyy",
        "country": "Russia",
        "province": "Murmansk",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Slantsy",
        "country": "Russia",
        "province": "Leningrad",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Kolpino",
        "country": "Russia",
        "province": "City of St. Petersburg",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Novozybkov",
        "country": "Russia",
        "province": "Bryansk",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Dyatkovo",
        "country": "Russia",
        "province": "Bryansk",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Shuya",
        "country": "Russia",
        "province": "Ivanovo",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Kineshma",
        "country": "Russia",
        "province": "Ivanovo",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Balakhna",
        "country": "Russia",
        "province": "Nizhegorod",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Arzamas",
        "country": "Russia",
        "province": "Nizhegorod",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Rzhev",
        "country": "Russia",
        "province": "Tver'",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Vyshnniy Volochek",
        "country": "Russia",
        "province": "Tver'",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Uglich",
        "country": "Russia",
        "province": "Yaroslavl'",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Yelets",
        "country": "Russia",
        "province": "Lipetsk",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Orekhovo-Zuevo",
        "country": "Russia",
        "province": "Moskovsskaya",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Klin",
        "country": "Russia",
        "province": "Moskovsskaya",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Sergiyev Posad",
        "country": "Russia",
        "province": "Moskovsskaya",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Kolomna",
        "country": "Russia",
        "province": "Moskovsskaya",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Bataysk",
        "country": "Russia",
        "province": "Rostov",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Taganrog",
        "country": "Russia",
        "province": "Rostov",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Novocherkassk",
        "country": "Russia",
        "province": "Rostov",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Kamensk Shakhtinskiy",
        "country": "Russia",
        "province": "Rostov",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Novoshakhtinsk",
        "country": "Russia",
        "province": "Rostov",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Aleksin",
        "country": "Russia",
        "province": "Tula",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Novomoskovsk",
        "country": "Russia",
        "province": "Tula",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Shchekino",
        "country": "Russia",
        "province": "Tula",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Nikolayevsk",
        "country": "Russia",
        "province": "Volgograd",
        "timezone": "Europe/Volgograd"
    },
    {
        "name": "Shebekino",
        "country": "Russia",
        "province": "Belgorod",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Gubkin",
        "country": "Russia",
        "province": "Belgorod",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Apsheronsk",
        "country": "Russia",
        "province": "Krasnodar",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Kropotkin",
        "country": "Russia",
        "province": "Krasnodar",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Ruzayevka",
        "country": "Russia",
        "province": "Mordovia",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Kirsanov",
        "country": "Russia",
        "province": "Tambov",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Michurinsk",
        "country": "Russia",
        "province": "Tambov",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Borisoglebsk",
        "country": "Russia",
        "province": "Voronezh",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Oktyabrskiy",
        "country": "Russia",
        "province": "Bashkortostan",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Plast",
        "country": "Russia",
        "province": "Chelyabinsk",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Bakal",
        "country": "Russia",
        "province": "Chelyabinsk",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Verkhniy Ufaley",
        "country": "Russia",
        "province": "Chelyabinsk",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Severnyy",
        "country": "Russia",
        "province": "Komi",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Kirovo-Chepetsk",
        "country": "Russia",
        "province": "Kirov",
        "timezone": "Europe/Kirov"
    },
    {
        "name": "Krasnoturinsk",
        "country": "Russia",
        "province": "Sverdlovsk",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Asbest",
        "country": "Russia",
        "province": "Sverdlovsk",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Alapayevsk",
        "country": "Russia",
        "province": "Sverdlovsk",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Krasnouralsk",
        "country": "Russia",
        "province": "Sverdlovsk",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Severouralsk",
        "country": "Russia",
        "province": "Sverdlovsk",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Novotroitsk",
        "country": "Russia",
        "province": "Orenburg",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Buguruslan",
        "country": "Russia",
        "province": "Orenburg",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Chapayevsk",
        "country": "Russia",
        "province": "Samara",
        "timezone": "Europe/Samara"
    },
    {
        "name": "Syzran",
        "country": "Russia",
        "province": "Samara",
        "timezone": "Europe/Samara"
    },
    {
        "name": "Novokuybishevsk",
        "country": "Russia",
        "province": "Samara",
        "timezone": "Europe/Samara"
    },
    {
        "name": "Naberezhnyye Chelny",
        "country": "Russia",
        "province": "Tatarstan",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Zelenodolsk",
        "country": "Russia",
        "province": "Tatarstan",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Leninogorsk",
        "country": "Russia",
        "province": "Tatarstan",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Bugulma",
        "country": "Russia",
        "province": "Tatarstan",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Nefteyugansk",
        "country": "Russia",
        "province": "Khanty-Mansiy",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Leninsk Kuznetsky",
        "country": "Russia",
        "province": "Kemerovo",
        "timezone": "Asia/Novokuznetsk"
    },
    {
        "name": "Anzhero Sudzhensk",
        "country": "Russia",
        "province": "Kemerovo",
        "timezone": "Asia/Novokuznetsk"
    },
    {
        "name": "Kiselevsk",
        "country": "Russia",
        "province": "Kemerovo",
        "timezone": "Asia/Novokuznetsk"
    },
    {
        "name": "Mundybash",
        "country": "Russia",
        "province": "Kemerovo",
        "timezone": "Asia/Novokuznetsk"
    },
    {
        "name": "Chernogorsk",
        "country": "Russia",
        "province": "Khakass",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        "name": "Abaza",
        "country": "Russia",
        "province": "Khakass",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        "name": "Iskitim",
        "country": "Russia",
        "province": "Novosibirsk",
        "timezone": "Asia/Novosibirsk"
    },
    {
        "name": "Toguchin",
        "country": "Russia",
        "province": "Novosibirsk",
        "timezone": "Asia/Novosibirsk"
    },
    {
        "name": "Kupina",
        "country": "Russia",
        "province": "Novosibirsk",
        "timezone": "Asia/Novosibirsk"
    },
    {
        "name": "Zaozernyy",
        "country": "Russia",
        "province": "Krasnoyarsk",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        "name": "Bogotol",
        "country": "Russia",
        "province": "Krasnoyarsk",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        "name": "Shilka",
        "country": "Russia",
        "province": "Chita",
        "timezone": "Asia/Chita"
    },
    {
        "name": "Sherlovaya Gora",
        "country": "Russia",
        "province": "Chita",
        "timezone": "Asia/Chita"
    },
    {
        "name": "Petrovsk Zabaykalskiy",
        "country": "Russia",
        "province": "Chita",
        "timezone": "Asia/Chita"
    },
    {
        "name": "Arsenyev",
        "country": "Russia",
        "province": "Primor'ye",
        "timezone": "Asia/Vladivostok"
    },
    {
        "name": "Partizansk",
        "country": "Russia",
        "province": "Primor'ye",
        "timezone": "Asia/Vladivostok"
    },
    {
        "name": "Dalnerechensk",
        "country": "Russia",
        "province": "Primor'ye",
        "timezone": "Asia/Vladivostok"
    },
    {
        "name": "Zemlya Bunge",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Vladivostok"
    },
    {
        "name": "Khorgo",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Put Lenina",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Obluchye",
        "country": "Russia",
        "province": "Yevrey",
        "timezone": "Asia/Vladivostok"
    },
    {
        "name": "Vanino",
        "country": "Russia",
        "province": "Khabarovsk",
        "timezone": "Asia/Vladivostok"
    },
    {
        "name": "Omchak",
        "country": "Russia",
        "province": "Maga Buryatdan",
        "timezone": "Asia/Magadan"
    },
    {
        "name": "Uglegorsk",
        "country": "Russia",
        "province": "Sakhalin",
        "timezone": "Asia/Sakhalin"
    },
    {
        "name": "Kholmsk",
        "country": "Russia",
        "province": "Sakhalin",
        "timezone": "Asia/Sakhalin"
    },
    {
        "name": "Solikamsk",
        "country": "Russia",
        "province": "Perm'",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Kizel",
        "country": "Russia",
        "province": "Perm'",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Pakhachi",
        "country": "Russia",
        "province": "Kamchatka",
        "timezone": "Asia/Kamchatka"
    },
    {
        "name": "Oymyakon",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": null
    },
    {
        "name": "Timiryazevskiy",
        "country": "Russia",
        "province": "Tomsk",
        "timezone": "Asia/Tomsk"
    },
    {
        "name": "Asino",
        "country": "Russia",
        "province": "Tomsk",
        "timezone": "Asia/Tomsk"
    },
    {
        "name": "Strezhevoy",
        "country": "Russia",
        "province": "Tomsk",
        "timezone": "Asia/Tomsk"
    },
    {
        "name": "Cherkessk",
        "country": "Russia",
        "province": "Karachay-Cherkess",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Vladikavkaz",
        "country": "Russia",
        "province": "North Ossetia",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Blagodarnyy",
        "country": "Russia",
        "province": "Stavropol'",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Zelenokumsk",
        "country": "Russia",
        "province": "Stavropol'",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Mukhomornoye",
        "country": "Russia",
        "province": "Chukchi Autonomous Okrug",
        "timezone": "Asia/Anadyr"
    },
    {
        "name": "Beringovskiy",
        "country": "Russia",
        "province": "Chukchi Autonomous Okrug",
        "timezone": "Asia/Anadyr"
    },
    {
        "name": "Bilibino",
        "country": "Russia",
        "province": "Chukchi Autonomous Okrug",
        "timezone": "Asia/Anadyr"
    },
    {
        "name": "Mys Shmidta",
        "country": "Russia",
        "province": "Chukchi Autonomous Okrug",
        "timezone": "Asia/Anadyr"
    },
    {
        "name": "Egvekinot",
        "country": "Russia",
        "province": "Chukchi Autonomous Okrug",
        "timezone": "Asia/Anadyr"
    },
    {
        "name": "Sovetsk",
        "country": "Russia",
        "province": "Kaliningrad",
        "timezone": "Europe/Kaliningrad"
    },
    {
        "name": "Nikel",
        "country": "Russia",
        "province": "Murmansk",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Monchegorsk",
        "country": "Russia",
        "province": "Murmansk",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Kirovsk",
        "country": "Russia",
        "province": "Murmansk",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Borovichi",
        "country": "Russia",
        "province": "Novgorod",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Staraya Russa",
        "country": "Russia",
        "province": "Novgorod",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Volkhov",
        "country": "Russia",
        "province": "Leningrad",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Tikhvin",
        "country": "Russia",
        "province": "Leningrad",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Svetogorsk",
        "country": "Russia",
        "province": "Leningrad",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Gatchina",
        "country": "Russia",
        "province": "Leningrad",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Luga",
        "country": "Russia",
        "province": "Leningrad",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Klintsy",
        "country": "Russia",
        "province": "Bryansk",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Roslavl",
        "country": "Russia",
        "province": "Smolensk",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Safonovo",
        "country": "Russia",
        "province": "Smolensk",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Vyazma",
        "country": "Russia",
        "province": "Smolensk",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Segezha",
        "country": "Russia",
        "province": "Karelia",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Vichuga",
        "country": "Russia",
        "province": "Ivanovo",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Sharya",
        "country": "Russia",
        "province": "Kostroma",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Buy",
        "country": "Russia",
        "province": "Kostroma",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Dzerzhinsk",
        "country": "Russia",
        "province": "Nizhegorod",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Vyska",
        "country": "Russia",
        "province": "Nizhegorod",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Kimry",
        "country": "Russia",
        "province": "Tver'",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Bezhetsk",
        "country": "Russia",
        "province": "Tver'",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Nelidovo",
        "country": "Russia",
        "province": "Tver'",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Bologoye",
        "country": "Russia",
        "province": "Tver'",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Torzhok",
        "country": "Russia",
        "province": "Tver'",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Sokol",
        "country": "Russia",
        "province": "Vologda",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Cherepovets",
        "country": "Russia",
        "province": "Vologda",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Rybinsk",
        "country": "Russia",
        "province": "Yaroslavl'",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Rostov",
        "country": "Russia",
        "province": "Yaroslavl'",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Kaluga",
        "country": "Russia",
        "province": "Kaluga",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Kirov",
        "country": "Russia",
        "province": "Kaluga",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Obninsk",
        "country": "Russia",
        "province": "Kaluga",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Lgov",
        "country": "Russia",
        "province": "Kursk",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Zheleznogorsk",
        "country": "Russia",
        "province": "Kursk",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Gryazi",
        "country": "Russia",
        "province": "Lipetsk",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Yegoryevsk",
        "country": "Russia",
        "province": "Moskovsskaya",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Podolsk",
        "country": "Russia",
        "province": "Moskovsskaya",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Solnechnogorsk",
        "country": "Russia",
        "province": "Moskovsskaya",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Noginsk",
        "country": "Russia",
        "province": "Moskovsskaya",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Serpukhov",
        "country": "Russia",
        "province": "Moskovsskaya",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Livny",
        "country": "Russia",
        "province": "Orel",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Mtsensk",
        "country": "Russia",
        "province": "Orel",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Salsk",
        "country": "Russia",
        "province": "Rostov",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Belaya Kalitva",
        "country": "Russia",
        "province": "Rostov",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Shakhty",
        "country": "Russia",
        "province": "Rostov",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Millerovo",
        "country": "Russia",
        "province": "Rostov",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Yefremov",
        "country": "Russia",
        "province": "Tula",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Bogoroditsk",
        "country": "Russia",
        "province": "Tula",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Kamyshin",
        "country": "Russia",
        "province": "Volgograd",
        "timezone": "Europe/Volgograd"
    },
    {
        "name": "Pallasovka",
        "country": "Russia",
        "province": "Volgograd",
        "timezone": "Europe/Volgograd"
    },
    {
        "name": "Frolovo",
        "country": "Russia",
        "province": "Volgograd",
        "timezone": "Europe/Volgograd"
    },
    {
        "name": "Volzhskiy",
        "country": "Russia",
        "province": "Volgograd",
        "timezone": "Europe/Volgograd"
    },
    {
        "name": "Mikhaylovka",
        "country": "Russia",
        "province": "Volgograd",
        "timezone": "Europe/Volgograd"
    },
    {
        "name": "Uryupinsk",
        "country": "Russia",
        "province": "Volgograd",
        "timezone": "Europe/Volgograd"
    },
    {
        "name": "Starsy Oskol",
        "country": "Russia",
        "province": "Belgorod",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Alekseyevka",
        "country": "Russia",
        "province": "Belgorod",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Valuyki",
        "country": "Russia",
        "province": "Belgorod",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Tuapse",
        "country": "Russia",
        "province": "Krasnodar",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Gelendzhik",
        "country": "Russia",
        "province": "Krasnodar",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Labinsk",
        "country": "Russia",
        "province": "Krasnodar",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Armavir",
        "country": "Russia",
        "province": "Krasnodar",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Timashevsk",
        "country": "Russia",
        "province": "Krasnodar",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Tikhoretsk",
        "country": "Russia",
        "province": "Krasnodar",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Yeysk",
        "country": "Russia",
        "province": "Krasnodar",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Saransk",
        "country": "Russia",
        "province": "Mordovia",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Kamenka",
        "country": "Russia",
        "province": "Penza",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Kuznetsk",
        "country": "Russia",
        "province": "Penza",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Serdobsk",
        "country": "Russia",
        "province": "Penza",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Kasimov",
        "country": "Russia",
        "province": "Ryazan'",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Sasovo",
        "country": "Russia",
        "province": "Ryazan'",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Kotovsk",
        "country": "Russia",
        "province": "Tambov",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Morshansk",
        "country": "Russia",
        "province": "Tambov",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Kovrov",
        "country": "Russia",
        "province": "Vladimir",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Murom",
        "country": "Russia",
        "province": "Vladimir",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Rayevskiy",
        "country": "Russia",
        "province": "Bashkortostan",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Sibay",
        "country": "Russia",
        "province": "Bashkortostan",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Kumertau",
        "country": "Russia",
        "province": "Bashkortostan",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Salavat",
        "country": "Russia",
        "province": "Bashkortostan",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Belebey",
        "country": "Russia",
        "province": "Bashkortostan",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Tuymazy",
        "country": "Russia",
        "province": "Bashkortostan",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Neftekamsk",
        "country": "Russia",
        "province": "Bashkortostan",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Troitsk",
        "country": "Russia",
        "province": "Chelyabinsk",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Yemanzhelinsk",
        "country": "Russia",
        "province": "Chelyabinsk",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Kartaly",
        "country": "Russia",
        "province": "Chelyabinsk",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Asha",
        "country": "Russia",
        "province": "Chelyabinsk",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Miass",
        "country": "Russia",
        "province": "Chelyabinsk",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Kyshtym",
        "country": "Russia",
        "province": "Chelyabinsk",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Kurtamysh",
        "country": "Russia",
        "province": "Kurgan",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Shadrinsk",
        "country": "Russia",
        "province": "Kurgan",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Varnek",
        "country": "Russia",
        "province": "Nenets",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Bugrino",
        "country": "Russia",
        "province": "Nenets",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Yamburg",
        "country": "Russia",
        "province": "Yamal-Nenets",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Nakhodka",
        "country": "Russia",
        "province": "Yamal-Nenets",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Sosnogorsk",
        "country": "Russia",
        "province": "Komi",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Sovetsk",
        "country": "Russia",
        "province": "Kirov",
        "timezone": "Europe/Kirov"
    },
    {
        "name": "Slobodskoy",
        "country": "Russia",
        "province": "Kirov",
        "timezone": "Europe/Kirov"
    },
    {
        "name": "Kirs",
        "country": "Russia",
        "province": "Kirov",
        "timezone": "Europe/Kirov"
    },
    {
        "name": "Omutninsk",
        "country": "Russia",
        "province": "Kirov",
        "timezone": "Europe/Kirov"
    },
    {
        "name": "Kotelnich",
        "country": "Russia",
        "province": "Kirov",
        "timezone": "Europe/Kirov"
    },
    {
        "name": "Yoshkar Ola",
        "country": "Russia",
        "province": "Mariy-El",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Kamensk Uralskiy",
        "country": "Russia",
        "province": "Sverdlovsk",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Polevskoy",
        "country": "Russia",
        "province": "Sverdlovsk",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Tavda",
        "country": "Russia",
        "province": "Sverdlovsk",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Artemovskiy",
        "country": "Russia",
        "province": "Sverdlovsk",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Nevyansk",
        "country": "Russia",
        "province": "Sverdlovsk",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Verkhnyaya Salda",
        "country": "Russia",
        "province": "Sverdlovsk",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Nizhnyaya Tura",
        "country": "Russia",
        "province": "Sverdlovsk",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Karpinsk",
        "country": "Russia",
        "province": "Sverdlovsk",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Ivdel",
        "country": "Russia",
        "province": "Sverdlovsk",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Krasnoufimsk",
        "country": "Russia",
        "province": "Sverdlovsk",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Sarapul",
        "country": "Russia",
        "province": "Udmurt",
        "timezone": "Europe/Samara"
    },
    {
        "name": "Mozhga",
        "country": "Russia",
        "province": "Udmurt",
        "timezone": "Europe/Samara"
    },
    {
        "name": "Votkinsk",
        "country": "Russia",
        "province": "Udmurt",
        "timezone": "Europe/Samara"
    },
    {
        "name": "Glazov",
        "country": "Russia",
        "province": "Udmurt",
        "timezone": "Europe/Samara"
    },
    {
        "name": "Kanash",
        "country": "Russia",
        "province": "Chuvash",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Shumerlya",
        "country": "Russia",
        "province": "Chuvash",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Alatyr",
        "country": "Russia",
        "province": "Chuvash",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Sol-lletsk",
        "country": "Russia",
        "province": "Orenburg",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Dombarovskiy",
        "country": "Russia",
        "province": "Orenburg",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Mednogorsk",
        "country": "Russia",
        "province": "Orenburg",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Gay",
        "country": "Russia",
        "province": "Orenburg",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Buzuluk",
        "country": "Russia",
        "province": "Orenburg",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Otradnyy",
        "country": "Russia",
        "province": "Samara",
        "timezone": "Europe/Samara"
    },
    {
        "name": "Tolyatti",
        "country": "Russia",
        "province": "Samara",
        "timezone": "Europe/Samara"
    },
    {
        "name": "Engels",
        "country": "Russia",
        "province": "Saratov",
        "timezone": "Europe/Saratov"
    },
    {
        "name": "Pugachev",
        "country": "Russia",
        "province": "Saratov",
        "timezone": "Europe/Saratov"
    },
    {
        "name": "Volsk",
        "country": "Russia",
        "province": "Saratov",
        "timezone": "Europe/Saratov"
    },
    {
        "name": "Atkarsk",
        "country": "Russia",
        "province": "Saratov",
        "timezone": "Europe/Saratov"
    },
    {
        "name": "Balashov",
        "country": "Russia",
        "province": "Saratov",
        "timezone": "Europe/Saratov"
    },
    {
        "name": "Almetyevsk",
        "country": "Russia",
        "province": "Tatarstan",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Chistopol",
        "country": "Russia",
        "province": "Tatarstan",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Nizhnekamsk",
        "country": "Russia",
        "province": "Tatarstan",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Dimitrovgrad",
        "country": "Russia",
        "province": "Ul'yanovsk",
        "timezone": "Europe/Ulyanovsk"
    },
    {
        "name": "Peregrebnoye",
        "country": "Russia",
        "province": "Khanty-Mansiy",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Saranpaul",
        "country": "Russia",
        "province": "Khanty-Mansiy",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Uray",
        "country": "Russia",
        "province": "Khanty-Mansiy",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Laryak",
        "country": "Russia",
        "province": "Khanty-Mansiy",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Kogalym",
        "country": "Russia",
        "province": "Khanty-Mansiy",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Megion",
        "country": "Russia",
        "province": "Khanty-Mansiy",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Cherlak",
        "country": "Russia",
        "province": "Omsk",
        "timezone": "Asia/Omsk"
    },
    {
        "name": "Kalachinsk",
        "country": "Russia",
        "province": "Omsk",
        "timezone": "Asia/Omsk"
    },
    {
        "name": "Nazyvayevsk",
        "country": "Russia",
        "province": "Omsk",
        "timezone": "Asia/Omsk"
    },
    {
        "name": "Isikul",
        "country": "Russia",
        "province": "Omsk",
        "timezone": "Asia/Omsk"
    },
    {
        "name": "Ishim",
        "country": "Russia",
        "province": "Tyumen'",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Golyshmanovo",
        "country": "Russia",
        "province": "Tyumen'",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Yalutorovsk",
        "country": "Russia",
        "province": "Tyumen'",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Biysk",
        "country": "Russia",
        "province": "Altay",
        "timezone": "Asia/Barnaul"
    },
    {
        "name": "Zmeinogorsk",
        "country": "Russia",
        "province": "Altay",
        "timezone": "Asia/Barnaul"
    },
    {
        "name": "Aleysk",
        "country": "Russia",
        "province": "Altay",
        "timezone": "Asia/Barnaul"
    },
    {
        "name": "Novoaltaysk",
        "country": "Russia",
        "province": "Altay",
        "timezone": "Asia/Barnaul"
    },
    {
        "name": "Kamenna Obi",
        "country": "Russia",
        "province": "Altay",
        "timezone": "Asia/Barnaul"
    },
    {
        "name": "Gornyak",
        "country": "Russia",
        "province": "Altay",
        "timezone": "Asia/Barnaul"
    },
    {
        "name": "Kulunda",
        "country": "Russia",
        "province": "Altay",
        "timezone": "Asia/Barnaul"
    },
    {
        "name": "Slavgorod",
        "country": "Russia",
        "province": "Altay",
        "timezone": "Asia/Barnaul"
    },
    {
        "name": "Tashtagol",
        "country": "Russia",
        "province": "Kemerovo",
        "timezone": "Asia/Novokuznetsk"
    },
    {
        "name": "Guryevsk",
        "country": "Russia",
        "province": "Kemerovo",
        "timezone": "Asia/Novokuznetsk"
    },
    {
        "name": "Yurga",
        "country": "Russia",
        "province": "Kemerovo",
        "timezone": "Asia/Novokuznetsk"
    },
    {
        "name": "Topki",
        "country": "Russia",
        "province": "Kemerovo",
        "timezone": "Asia/Novokuznetsk"
    },
    {
        "name": "Mariinsk",
        "country": "Russia",
        "province": "Kemerovo",
        "timezone": "Asia/Novokuznetsk"
    },
    {
        "name": "Shira",
        "country": "Russia",
        "province": "Khakass",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        "name": "Cherepanovo",
        "country": "Russia",
        "province": "Novosibirsk",
        "timezone": "Asia/Novosibirsk"
    },
    {
        "name": "Kargat",
        "country": "Russia",
        "province": "Novosibirsk",
        "timezone": "Asia/Novosibirsk"
    },
    {
        "name": "Ob",
        "country": "Russia",
        "province": "Novosibirsk",
        "timezone": "Asia/Novosibirsk"
    },
    {
        "name": "Karasuk",
        "country": "Russia",
        "province": "Novosibirsk",
        "timezone": "Asia/Novosibirsk"
    },
    {
        "name": "Barabinsk",
        "country": "Russia",
        "province": "Novosibirsk",
        "timezone": "Asia/Novosibirsk"
    },
    {
        "name": "Tatarsk",
        "country": "Russia",
        "province": "Novosibirsk",
        "timezone": "Asia/Novosibirsk"
    },
    {
        "name": "Kaspiysk",
        "country": "Russia",
        "province": "Dagestan",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Derbent",
        "country": "Russia",
        "province": "Dagestan",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Buynaksk",
        "country": "Russia",
        "province": "Dagestan",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Yessey",
        "country": "Russia",
        "province": "Evenk",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        "name": "Ulkan",
        "country": "Russia",
        "province": "Irkutsk",
        "timezone": "Asia/Irkutsk"
    },
    {
        "name": "Kirensk",
        "country": "Russia",
        "province": "Irkutsk",
        "timezone": "Asia/Irkutsk"
    },
    {
        "name": "Zheleznogorsk Ilimskiy",
        "country": "Russia",
        "province": "Irkutsk",
        "timezone": "Asia/Irkutsk"
    },
    {
        "name": "Vikhorevka",
        "country": "Russia",
        "province": "Irkutsk",
        "timezone": "Asia/Irkutsk"
    },
    {
        "name": "Biryusinsk",
        "country": "Russia",
        "province": "Irkutsk",
        "timezone": "Asia/Irkutsk"
    },
    {
        "name": "Kodinskiy",
        "country": "Russia",
        "province": "Krasnoyarsk",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        "name": "Artemovsk",
        "country": "Russia",
        "province": "Krasnoyarsk",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        "name": "Uyar",
        "country": "Russia",
        "province": "Krasnoyarsk",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        "name": "Uzhur",
        "country": "Russia",
        "province": "Krasnoyarsk",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        "name": "Sayanogorsk",
        "country": "Russia",
        "province": "Krasnoyarsk",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        "name": "Podkamennaya",
        "country": "Russia",
        "province": "Krasnoyarsk",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        "name": "Igarka",
        "country": "Russia",
        "province": "Krasnoyarsk",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        "name": "Agapa",
        "country": "Russia",
        "province": "Taymyr",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        "name": "Boyarka",
        "country": "Russia",
        "province": "Taymyr",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        "name": "Nordvik",
        "country": "Russia",
        "province": "Taymyr",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        "name": "Chelyuskin",
        "country": "Russia",
        "province": "Taymyr",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        "name": "Taksimo",
        "country": "Russia",
        "province": "Buryat",
        "timezone": "Asia/Irkutsk"
    },
    {
        "name": "Gusinoozyorsk",
        "country": "Russia",
        "province": "Buryat",
        "timezone": "Asia/Irkutsk"
    },
    {
        "name": "Aginskoye",
        "country": "Russia",
        "province": "Aga Buryat",
        "timezone": "Asia/Chita"
    },
    {
        "name": "Progress",
        "country": "Russia",
        "province": "Amur",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Belogorsk",
        "country": "Russia",
        "province": "Amur",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Nyukzha",
        "country": "Russia",
        "province": "Amur",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Nerchinsk",
        "country": "Russia",
        "province": "Chita",
        "timezone": "Asia/Chita"
    },
    {
        "name": "Kavalerovo",
        "country": "Russia",
        "province": "Primor'ye",
        "timezone": "Asia/Vladivostok"
    },
    {
        "name": "Spassk Dalniy",
        "country": "Russia",
        "province": "Primor'ye",
        "timezone": "Asia/Vladivostok"
    },
    {
        "name": "Shalaurova",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Vladivostok"
    },
    {
        "name": "Logashkino",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Srednekolymsk"
    },
    {
        "name": "Ust Kuyga",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Vladivostok"
    },
    {
        "name": "Pokrovsk",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Verkhnevilyuysk",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Vitim",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Olyokminsk",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Tunguskhaya",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Natara",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Zhilinda",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Trofimovsk",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Tukchi",
        "country": "Russia",
        "province": "Khabarovsk",
        "timezone": "Asia/Vladivostok"
    },
    {
        "name": "Amursk",
        "country": "Russia",
        "province": "Khabarovsk",
        "timezone": "Asia/Vladivostok"
    },
    {
        "name": "Bikin",
        "country": "Russia",
        "province": "Khabarovsk",
        "timezone": "Asia/Vladivostok"
    },
    {
        "name": "Vyazemskiy",
        "country": "Russia",
        "province": "Khabarovsk",
        "timezone": "Asia/Vladivostok"
    },
    {
        "name": "Chegdomyn",
        "country": "Russia",
        "province": "Khabarovsk",
        "timezone": "Asia/Vladivostok"
    },
    {
        "name": "Siglan",
        "country": "Russia",
        "province": "Maga Buryatdan",
        "timezone": "Asia/Magadan"
    },
    {
        "name": "Karamken",
        "country": "Russia",
        "province": "Maga Buryatdan",
        "timezone": "Asia/Magadan"
    },
    {
        "name": "Strelka",
        "country": "Russia",
        "province": "Maga Buryatdan",
        "timezone": "Asia/Magadan"
    },
    {
        "name": "Severo Kurilsk",
        "country": "Russia",
        "province": "Sakhalin",
        "timezone": "Asia/Srednekolymsk"
    },
    {
        "name": "Krasnogorsk",
        "country": "Russia",
        "province": "Sakhalin",
        "timezone": "Asia/Sakhalin"
    },
    {
        "name": "Poronaysk",
        "country": "Russia",
        "province": "Sakhalin",
        "timezone": "Asia/Sakhalin"
    },
    {
        "name": "Makarov",
        "country": "Russia",
        "province": "Sakhalin",
        "timezone": "Asia/Sakhalin"
    },
    {
        "name": "Dolinsk",
        "country": "Russia",
        "province": "Sakhalin",
        "timezone": "Asia/Sakhalin"
    },
    {
        "name": "Nevelsk",
        "country": "Russia",
        "province": "Sakhalin",
        "timezone": "Asia/Sakhalin"
    },
    {
        "name": "Kudymkar",
        "country": "Russia",
        "province": "Komi-Permyak",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Kungur",
        "country": "Russia",
        "province": "Perm'",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Krasnokamsk",
        "country": "Russia",
        "province": "Perm'",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Chusovoy",
        "country": "Russia",
        "province": "Perm'",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Gubakha",
        "country": "Russia",
        "province": "Perm'",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Utkholok",
        "country": "Russia",
        "province": "Kamchatka",
        "timezone": "Asia/Kamchatka"
    },
    {
        "name": "Bol'sheretsk",
        "country": "Russia",
        "province": "Kamchatka",
        "timezone": "Asia/Kamchatka"
    },
    {
        "name": "Il'pyrskiy",
        "country": "Russia",
        "province": "Kamchatka",
        "timezone": "Asia/Kamchatka"
    },
    {
        "name": "Korf",
        "country": "Russia",
        "province": "Kamchatka",
        "timezone": "Asia/Kamchatka"
    },
    {
        "name": "Kolpashevo",
        "country": "Russia",
        "province": "Tomsk",
        "timezone": "Asia/Tomsk"
    },
    {
        "name": "Omolon",
        "country": "Russia",
        "province": "Chukchi Autonomous Okrug",
        "timezone": "Asia/Anadyr"
    },
    {
        "name": "Pevek",
        "country": "Russia",
        "province": "Chukchi Autonomous Okrug",
        "timezone": "Asia/Anadyr"
    },
    {
        "name": "Umba",
        "country": "Russia",
        "province": "Murmansk",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Kovda",
        "country": "Russia",
        "province": "Murmansk",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Velikiy Novgorod",
        "country": "Russia",
        "province": "Novgorod",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Velikiye Luki",
        "country": "Russia",
        "province": "Pskov",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Belomorsk",
        "country": "Russia",
        "province": "Karelia",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Kem",
        "country": "Russia",
        "province": "Karelia",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Krasino",
        "country": "Russia",
        "province": "Arkhangel'sk",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Matochkin Shar",
        "country": "Russia",
        "province": "Arkhangel'sk",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Severodvinsk",
        "country": "Russia",
        "province": "Arkhangel'sk",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Kursk",
        "country": "Russia",
        "province": "Kursk",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Tula",
        "country": "Russia",
        "province": "Tula",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Tambov",
        "country": "Russia",
        "province": "Tambov",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Sterlitamak",
        "country": "Russia",
        "province": "Bashkortostan",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Kurgan",
        "country": "Russia",
        "province": "Kurgan",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Indiga",
        "country": "Russia",
        "province": "Nenets",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Shoyna",
        "country": "Russia",
        "province": "Nenets",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Novy Port",
        "country": "Russia",
        "province": "Yamal-Nenets",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Salekhard",
        "country": "Russia",
        "province": "Yamal-Nenets",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Gyda",
        "country": "Russia",
        "province": "Yamal-Nenets",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Tazovskiy",
        "country": "Russia",
        "province": "Yamal-Nenets",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Novy Urengoy",
        "country": "Russia",
        "province": "Yamal-Nenets",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Nadym",
        "country": "Russia",
        "province": "Yamal-Nenets",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Noyabrsk",
        "country": "Russia",
        "province": "Yamal-Nenets",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Syktyvkar",
        "country": "Russia",
        "province": "Komi",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Ukhta",
        "country": "Russia",
        "province": "Komi",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Serov",
        "country": "Russia",
        "province": "Sverdlovsk",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Cheboksary",
        "country": "Russia",
        "province": "Chuvash",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Orsk",
        "country": "Russia",
        "province": "Orenburg",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Balakovo",
        "country": "Russia",
        "province": "Saratov",
        "timezone": "Europe/Saratov"
    },
    {
        "name": "Igrim",
        "country": "Russia",
        "province": "Khanty-Mansiy",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Nyagan",
        "country": "Russia",
        "province": "Khanty-Mansiy",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Khanty Mansiysk",
        "country": "Russia",
        "province": "Khanty-Mansiy",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Nizhenvartovsk",
        "country": "Russia",
        "province": "Khanty-Mansiy",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Numto",
        "country": "Russia",
        "province": "Khanty-Mansiy",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Tara",
        "country": "Russia",
        "province": "Omsk",
        "timezone": "Asia/Omsk"
    },
    {
        "name": "Tobolsk",
        "country": "Russia",
        "province": "Tyumen'",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Rubtsovsk",
        "country": "Russia",
        "province": "Altay",
        "timezone": "Asia/Barnaul"
    },
    {
        "name": "Gorno Altaysk",
        "country": "Russia",
        "province": "Gorno-Altay",
        "timezone": "Asia/Barnaul"
    },
    {
        "name": "Prokopyevsk",
        "country": "Russia",
        "province": "Kemerovo",
        "timezone": "Asia/Novokuznetsk"
    },
    {
        "name": "Makhachkala",
        "country": "Russia",
        "province": "Dagestan",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Tura",
        "country": "Russia",
        "province": "Evenk",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        "name": "Noginsk",
        "country": "Russia",
        "province": "Evenk",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        "name": "Yerema",
        "country": "Russia",
        "province": "Irkutsk",
        "timezone": "Asia/Irkutsk"
    },
    {
        "name": "Tayshet",
        "country": "Russia",
        "province": "Irkutsk",
        "timezone": "Asia/Irkutsk"
    },
    {
        "name": "Usolye Sibirskoye",
        "country": "Russia",
        "province": "Irkutsk",
        "timezone": "Asia/Irkutsk"
    },
    {
        "name": "Slyudyanka",
        "country": "Russia",
        "province": "Irkutsk",
        "timezone": "Asia/Irkutsk"
    },
    {
        "name": "Cheremkhovo",
        "country": "Russia",
        "province": "Irkutsk",
        "timezone": "Asia/Irkutsk"
    },
    {
        "name": "Zima",
        "country": "Russia",
        "province": "Irkutsk",
        "timezone": "Asia/Irkutsk"
    },
    {
        "name": "Tulun",
        "country": "Russia",
        "province": "Irkutsk",
        "timezone": "Asia/Irkutsk"
    },
    {
        "name": "Nizhneudinsk",
        "country": "Russia",
        "province": "Irkutsk",
        "timezone": "Asia/Irkutsk"
    },
    {
        "name": "Ust Kut",
        "country": "Russia",
        "province": "Irkutsk",
        "timezone": "Asia/Irkutsk"
    },
    {
        "name": "Bodaybo",
        "country": "Russia",
        "province": "Irkutsk",
        "timezone": "Asia/Irkutsk"
    },
    {
        "name": "Komsa",
        "country": "Russia",
        "province": "Krasnoyarsk",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        "name": "Kansk",
        "country": "Russia",
        "province": "Krasnoyarsk",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        "name": "Achinsk",
        "country": "Russia",
        "province": "Krasnoyarsk",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        "name": "Yeniseysk",
        "country": "Russia",
        "province": "Krasnoyarsk",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        "name": "Lesosibirsk",
        "country": "Russia",
        "province": "Krasnoyarsk",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        "name": "Turukhansk",
        "country": "Russia",
        "province": "Krasnoyarsk",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        "name": "Vorontsovo",
        "country": "Russia",
        "province": "Taymyr",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        "name": "Starorybnoye",
        "country": "Russia",
        "province": "Taymyr",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        "name": "Mikhaylova",
        "country": "Russia",
        "province": "Taymyr",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        "name": "Dudinka",
        "country": "Russia",
        "province": "Taymyr",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        "name": "Teli",
        "country": "Russia",
        "province": "Tuva",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        "name": "Novyy Uoyin",
        "country": "Russia",
        "province": "Buryat",
        "timezone": "Asia/Irkutsk"
    },
    {
        "name": "Bagdarin",
        "country": "Russia",
        "province": "Buryat",
        "timezone": "Asia/Irkutsk"
    },
    {
        "name": "Severobaykalsk",
        "country": "Russia",
        "province": "Buryat",
        "timezone": "Asia/Irkutsk"
    },
    {
        "name": "Kyakhta",
        "country": "Russia",
        "province": "Buryat",
        "timezone": "Asia/Irkutsk"
    },
    {
        "name": "Svobodnyy",
        "country": "Russia",
        "province": "Amur",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Zeya",
        "country": "Russia",
        "province": "Amur",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Magdagachi",
        "country": "Russia",
        "province": "Amur",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Shimanovsk",
        "country": "Russia",
        "province": "Amur",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Skovorodino",
        "country": "Russia",
        "province": "Amur",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Tynda",
        "country": "Russia",
        "province": "Amur",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Olovyannaya",
        "country": "Russia",
        "province": "Chita",
        "timezone": "Asia/Chita"
    },
    {
        "name": "Mogocha",
        "country": "Russia",
        "province": "Chita",
        "timezone": "Asia/Chita"
    },
    {
        "name": "Krasnokamensk",
        "country": "Russia",
        "province": "Chita",
        "timezone": "Asia/Chita"
    },
    {
        "name": "Borzya",
        "country": "Russia",
        "province": "Chita",
        "timezone": "Asia/Chita"
    },
    {
        "name": "Khilok",
        "country": "Russia",
        "province": "Chita",
        "timezone": "Asia/Chita"
    },
    {
        "name": "Nakhodka",
        "country": "Russia",
        "province": "Primor'ye",
        "timezone": "Asia/Vladivostok"
    },
    {
        "name": "Ussuriysk",
        "country": "Russia",
        "province": "Primor'ye",
        "timezone": "Asia/Vladivostok"
    },
    {
        "name": "Lesozavodsk",
        "country": "Russia",
        "province": "Primor'ye",
        "timezone": "Asia/Vladivostok"
    },
    {
        "name": "Kavache",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Vladivostok"
    },
    {
        "name": "Verkhoyansk",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Vladivostok"
    },
    {
        "name": "Cherskiy",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Srednekolymsk"
    },
    {
        "name": "Srednekolymsk",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Srednekolymsk"
    },
    {
        "name": "Zyryanka",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Srednekolymsk"
    },
    {
        "name": "Eldikan",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Khandyga"
    },
    {
        "name": "Chagda",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Khandyga"
    },
    {
        "name": "Khandyga",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Khandyga"
    },
    {
        "name": "Ust Maya",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Khandyga"
    },
    {
        "name": "Neryungri",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Chernyshevskiy",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Terbyas",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Vilyuysk",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Sangar",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Menkere",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Saskylakh",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Govorovo",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Sagastyr",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Ust Olensk",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Suntar",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Olenek",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Udachnyy",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Birobidzhan",
        "country": "Russia",
        "province": "Yevrey",
        "timezone": "Asia/Vladivostok"
    },
    {
        "name": "Khakhar",
        "country": "Russia",
        "province": "Khabarovsk",
        "timezone": "Asia/Vladivostok"
    },
    {
        "name": "De Kastri",
        "country": "Russia",
        "province": "Khabarovsk",
        "timezone": "Asia/Vladivostok"
    },
    {
        "name": "Chumikan",
        "country": "Russia",
        "province": "Khabarovsk",
        "timezone": "Asia/Vladivostok"
    },
    {
        "name": "Komsomolsk na Amure",
        "country": "Russia",
        "province": "Khabarovsk",
        "timezone": "Asia/Vladivostok"
    },
    {
        "name": "Ayan",
        "country": "Russia",
        "province": "Khabarovsk",
        "timezone": "Asia/Vladivostok"
    },
    {
        "name": "Nikolayevsk na Amure",
        "country": "Russia",
        "province": "Khabarovsk",
        "timezone": "Asia/Vladivostok"
    },
    {
        "name": "Savetskaya Gavan",
        "country": "Russia",
        "province": "Khabarovsk",
        "timezone": "Asia/Vladivostok"
    },
    {
        "name": "Evensk",
        "country": "Russia",
        "province": "Maga Buryatdan",
        "timezone": "Asia/Magadan"
    },
    {
        "name": "Palatka",
        "country": "Russia",
        "province": "Maga Buryatdan",
        "timezone": "Asia/Magadan"
    },
    {
        "name": "Omsukchan",
        "country": "Russia",
        "province": "Maga Buryatdan",
        "timezone": "Asia/Magadan"
    },
    {
        "name": "Susuman",
        "country": "Russia",
        "province": "Maga Buryatdan",
        "timezone": "Asia/Magadan"
    },
    {
        "name": "Nogliki",
        "country": "Russia",
        "province": "Sakhalin",
        "timezone": "Asia/Sakhalin"
    },
    {
        "name": "Aleksandrovsk Sakhalinskiy",
        "country": "Russia",
        "province": "Sakhalin",
        "timezone": "Asia/Sakhalin"
    },
    {
        "name": "Korsakov",
        "country": "Russia",
        "province": "Sakhalin",
        "timezone": "Asia/Sakhalin"
    },
    {
        "name": "Manily",
        "country": "Russia",
        "province": "Kamchatka",
        "timezone": "Asia/Kamchatka"
    },
    {
        "name": "Oktyabrskiy",
        "country": "Russia",
        "province": "Kamchatka",
        "timezone": "Asia/Kamchatka"
    },
    {
        "name": "Klyuchi",
        "country": "Russia",
        "province": "Kamchatka",
        "timezone": "Asia/Kamchatka"
    },
    {
        "name": "Ust Kamchatsk",
        "country": "Russia",
        "province": "Kamchatka",
        "timezone": "Asia/Kamchatka"
    },
    {
        "name": "Provideniya",
        "country": "Russia",
        "province": "Chukchi Autonomous Okrug",
        "timezone": "Asia/Anadyr"
    },
    {
        "name": "Uelen",
        "country": "Russia",
        "province": "Chukchi Autonomous Okrug",
        "timezone": "Asia/Anadyr"
    },
    {
        "name": "Kandalaksha",
        "country": "Russia",
        "province": "Murmansk",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Vyborg",
        "country": "Russia",
        "province": "Leningrad",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Kondopoga",
        "country": "Russia",
        "province": "Karelia",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Rusanovo",
        "country": "Russia",
        "province": "Arkhangel'sk",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Mezen",
        "country": "Russia",
        "province": "Arkhangel'sk",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Velsk",
        "country": "Russia",
        "province": "Arkhangel'sk",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Kotlas",
        "country": "Russia",
        "province": "Arkhangel'sk",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Onega",
        "country": "Russia",
        "province": "Arkhangel'sk",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Ivanovo",
        "country": "Russia",
        "province": "Ivanovo",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Kostroma",
        "country": "Russia",
        "province": "Kostroma",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Velikiy Ustyug",
        "country": "Russia",
        "province": "Vologda",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Lipetsk",
        "country": "Russia",
        "province": "Lipetsk",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Orel",
        "country": "Russia",
        "province": "Orel",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Volgodonsk",
        "country": "Russia",
        "province": "Rostov",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Belgorod",
        "country": "Russia",
        "province": "Belgorod",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Novorossiysk",
        "country": "Russia",
        "province": "Krasnodar",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Vladimir",
        "country": "Russia",
        "province": "Vladimir",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Birsk",
        "country": "Russia",
        "province": "Bashkortostan",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Zlatoust",
        "country": "Russia",
        "province": "Chelyabinsk",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Amderma",
        "country": "Russia",
        "province": "Nenets",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Naryan Mar",
        "country": "Russia",
        "province": "Nenets",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Inta",
        "country": "Russia",
        "province": "Komi",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Usinsk",
        "country": "Russia",
        "province": "Komi",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Pechora",
        "country": "Russia",
        "province": "Komi",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Pervouralsk",
        "country": "Russia",
        "province": "Sverdlovsk",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Izhevsk",
        "country": "Russia",
        "province": "Udmurt",
        "timezone": "Europe/Samara"
    },
    {
        "name": "Akhtubinsk",
        "country": "Russia",
        "province": "Astrakhan'",
        "timezone": "Europe/Astrakhan"
    },
    {
        "name": "Elista",
        "country": "Russia",
        "province": "Kalmyk",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Krasnoarmeysk",
        "country": "Russia",
        "province": "Saratov",
        "timezone": "Europe/Saratov"
    },
    {
        "name": "Berezniki",
        "country": "Russia",
        "province": "Perm'",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Naltchik",
        "country": "Russia",
        "province": "Kabardin-Balkar",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Stavropol",
        "country": "Russia",
        "province": "Stavropol'",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Ugolnye Kopi",
        "country": "Russia",
        "province": "Chukchi Autonomous Okrug",
        "timezone": "Asia/Anadyr"
    },
    {
        "name": "Kaliningrad",
        "country": "Russia",
        "province": "Kaliningrad",
        "timezone": "Europe/Kaliningrad"
    },
    {
        "name": "Pskov",
        "country": "Russia",
        "province": "Pskov",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Bryansk",
        "country": "Russia",
        "province": "Bryansk",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Smolensk",
        "country": "Russia",
        "province": "Smolensk",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Petrozavodsk",
        "country": "Russia",
        "province": "Karelia",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Tver",
        "country": "Russia",
        "province": "Tver'",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Vologda",
        "country": "Russia",
        "province": "Vologda",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Yaroslavl",
        "country": "Russia",
        "province": "Yaroslavl'",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Rostov",
        "country": "Russia",
        "province": "Rostov",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Sochi",
        "country": "Russia",
        "province": "Krasnodar",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Krasnodar",
        "country": "Russia",
        "province": "Krasnodar",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Penza",
        "country": "Russia",
        "province": "Penza",
        "timezone": null
    },
    {
        "name": "Ryazan",
        "country": "Russia",
        "province": "Ryazan'",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Voronezh",
        "country": "Russia",
        "province": "Voronezh",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Magnitogorsk",
        "country": "Russia",
        "province": "Chelyabinsk",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Chelyabinsk",
        "country": "Russia",
        "province": "Chelyabinsk",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Vorkuta",
        "country": "Russia",
        "province": "Komi",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Kirov",
        "country": "Russia",
        "province": "Kirov",
        "timezone": "Europe/Kirov"
    },
    {
        "name": "Nizhny Tagil",
        "country": "Russia",
        "province": "Sverdlovsk",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Astrakhan",
        "country": "Russia",
        "province": "Astrakhan'",
        "timezone": "Europe/Astrakhan"
    },
    {
        "name": "Orenburg",
        "country": "Russia",
        "province": "Orenburg",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Saratov",
        "country": "Russia",
        "province": "Saratov",
        "timezone": "Europe/Saratov"
    },
    {
        "name": "Ulyanovsk",
        "country": "Russia",
        "province": "Ul'yanovsk",
        "timezone": "Europe/Ulyanovsk"
    },
    {
        "name": "Omsk",
        "country": "Russia",
        "province": "Omsk",
        "timezone": "Asia/Omsk"
    },
    {
        "name": "Tyumen",
        "country": "Russia",
        "province": "Tyumen'",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Novokuznetsk",
        "country": "Russia",
        "province": "Kemerovo",
        "timezone": "Asia/Novokuznetsk"
    },
    {
        "name": "Kemerovo",
        "country": "Russia",
        "province": "Kemerovo",
        "timezone": "Asia/Novokuznetsk"
    },
    {
        "name": "Groznyy",
        "country": "Russia",
        "province": "Chechnya",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Ust-Ulimsk",
        "country": "Russia",
        "province": "Irkutsk",
        "timezone": "Asia/Irkutsk"
    },
    {
        "name": "Angarsk",
        "country": "Russia",
        "province": "Irkutsk",
        "timezone": "Asia/Irkutsk"
    },
    {
        "name": "Abakan",
        "country": "Russia",
        "province": "Krasnoyarsk",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        "name": "Norilsk",
        "country": "Russia",
        "province": "Taymyr",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        "name": "Khatanga",
        "country": "Russia",
        "province": "Taymyr",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        "name": "Kyzyl",
        "country": "Russia",
        "province": "Tuva",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        "name": "Ulan Ude",
        "country": "Russia",
        "province": "Buryat",
        "timezone": "Asia/Irkutsk"
    },
    {
        "name": "Blagoveshchensk",
        "country": "Russia",
        "province": "Amur",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Bukachacha",
        "country": "Russia",
        "province": "Chita",
        "timezone": "Asia/Chita"
    },
    {
        "name": "Dalnegorsk",
        "country": "Russia",
        "province": "Primor'ye",
        "timezone": "Asia/Vladivostok"
    },
    {
        "name": "Ambarchik",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Srednekolymsk"
    },
    {
        "name": "Batagay",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Vladivostok"
    },
    {
        "name": "Chokurdakh",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Srednekolymsk"
    },
    {
        "name": "Ust Nera",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Ust-Nera"
    },
    {
        "name": "Lensk",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Aldan",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Mirnyy",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Zhigansk",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Okhotsk",
        "country": "Russia",
        "province": "Khabarovsk",
        "timezone": "Asia/Vladivostok"
    },
    {
        "name": "Khabarovsk",
        "country": "Russia",
        "province": "Khabarovsk",
        "timezone": "Asia/Vladivostok"
    },
    {
        "name": "Okha",
        "country": "Russia",
        "province": "Sakhalin",
        "timezone": "Asia/Sakhalin"
    },
    {
        "name": "Yuzhno Sakhalinsk",
        "country": "Russia",
        "province": "Sakhalin",
        "timezone": "Asia/Sakhalin"
    },
    {
        "name": "Tomsk",
        "country": "Russia",
        "province": "Tomsk",
        "timezone": "Asia/Tomsk"
    },
    {
        "name": "Anadyr",
        "country": "Russia",
        "province": "Chukchi Autonomous Okrug",
        "timezone": "Asia/Anadyr"
    },
    {
        "name": "Murmansk",
        "country": "Russia",
        "province": "Murmansk",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Archangel",
        "country": "Russia",
        "province": "Arkhangel'sk",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Nizhny Novgorod",
        "country": "Russia",
        "province": "Nizhegorod",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Volgograd",
        "country": "Russia",
        "province": "Volgograd",
        "timezone": "Europe/Volgograd"
    },
    {
        "name": "Ufa",
        "country": "Russia",
        "province": "Bashkortostan",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Yekaterinburg",
        "country": "Russia",
        "province": "Sverdlovsk",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Samara",
        "country": "Russia",
        "province": "Samara",
        "timezone": "Europe/Samara"
    },
    {
        "name": "Kazan",
        "country": "Russia",
        "province": "Tatarstan",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Surgut",
        "country": "Russia",
        "province": "Khanty-Mansiy",
        "timezone": "Asia/Yekaterinburg"
    },
    {
        "name": "Barnaul",
        "country": "Russia",
        "province": "Altay",
        "timezone": "Asia/Barnaul"
    },
    {
        "name": "Novosibirsk",
        "country": "Russia",
        "province": "Novosibirsk",
        "timezone": "Asia/Novosibirsk"
    },
    {
        "name": "Bratsk",
        "country": "Russia",
        "province": "Irkutsk",
        "timezone": "Asia/Irkutsk"
    },
    {
        "name": "Irkutsk",
        "country": "Russia",
        "province": "Irkutsk",
        "timezone": "Asia/Irkutsk"
    },
    {
        "name": "Krasnoyarsk",
        "country": "Russia",
        "province": "Krasnoyarsk",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        "name": "Dickson",
        "country": "Russia",
        "province": "Taymyr",
        "timezone": "Asia/Krasnoyarsk"
    },
    {
        "name": "Chita",
        "country": "Russia",
        "province": "Chita",
        "timezone": "Asia/Chita"
    },
    {
        "name": "Vladivostok",
        "country": "Russia",
        "province": "Primor'ye",
        "timezone": "Asia/Vladivostok"
    },
    {
        "name": "Nizhneyansk",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Vladivostok"
    },
    {
        "name": "Yakutsk",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Tiksi",
        "country": "Russia",
        "province": "Sakha (Yakutia)",
        "timezone": "Asia/Yakutsk"
    },
    {
        "name": "Magadan",
        "country": "Russia",
        "province": "Maga Buryatdan",
        "timezone": "Asia/Magadan"
    },
    {
        "name": "Perm",
        "country": "Russia",
        "province": "Perm'",
        "timezone": null
    },
    {
        "name": "Palana",
        "country": "Russia",
        "province": "Kamchatka",
        "timezone": "Asia/Kamchatka"
    },
    {
        "name": "Petropavlovsk Kamchatskiy",
        "country": "Russia",
        "province": "Kamchatka",
        "timezone": "Asia/Kamchatka"
    },
    {
        "name": "St. Petersburg",
        "country": "Russia",
        "province": "City of St. Petersburg",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Moscow",
        "country": "Russia",
        "province": "Moskva",
        "timezone": "Europe/Moscow"
    },
    {
        "name": "Gikongoro",
        "country": "Rwanda",
        "province": "Southern",
        "timezone": "Africa/Kigali"
    },
    {
        "name": "Kibuye",
        "country": "Rwanda",
        "province": "Western",
        "timezone": "Africa/Kigali"
    },
    {
        "name": "Kibungo",
        "country": "Rwanda",
        "province": "Eastern",
        "timezone": "Africa/Kigali"
    },
    {
        "name": "Nyanza",
        "country": "Rwanda",
        "province": "Southern",
        "timezone": "Africa/Kigali"
    },
    {
        "name": "Gitarama",
        "country": "Rwanda",
        "province": "Southern",
        "timezone": "Africa/Kigali"
    },
    {
        "name": "Butare",
        "country": "Rwanda",
        "province": "Southern",
        "timezone": "Africa/Kigali"
    },
    {
        "name": "Gisenyi",
        "country": "Rwanda",
        "province": "Western",
        "timezone": "Africa/Kigali"
    },
    {
        "name": "Cyangugu",
        "country": "Rwanda",
        "province": "Western",
        "timezone": "Africa/Kigali"
    },
    {
        "name": "Byumba",
        "country": "Rwanda",
        "province": "Northern",
        "timezone": "Africa/Kigali"
    },
    {
        "name": "Ruhengeri",
        "country": "Rwanda",
        "province": "Northern",
        "timezone": "Africa/Kigali"
    },
    {
        "name": "Kigali",
        "country": "Rwanda",
        "province": "Kigali City",
        "timezone": "Africa/Kigali"
    },
    {
        "name": "Basseterre",
        "country": "Saint Kitts and Nevis",
        "province": "",
        "timezone": "America/St_Kitts"
    },
    {
        "name": "Castries",
        "country": "Saint Lucia",
        "province": "",
        "timezone": "America/St_Lucia"
    },
    {
        "name": "Kingstown",
        "country": "Saint Vincent and the Grenadines",
        "province": "",
        "timezone": "America/St_Vincent"
    },
    {
        "name": "Apia",
        "country": "Samoa",
        "province": "",
        "timezone": "Pacific/Apia"
    },
    {
        "name": "San Marino",
        "country": "San Marino",
        "province": "",
        "timezone": "Europe/San_Marino"
    },
    {
        "name": "Santo Antonio",
        "country": "Sao Tome and Principe",
        "province": "",
        "timezone": "Africa/Sao_Tome"
    },
    {
        "name": "Sao Tome",
        "country": "Sao Tome and Principe",
        "province": "",
        "timezone": "Africa/Sao_Tome"
    },
    {
        "name": "An Nabk",
        "country": "Saudi Arabia",
        "province": "Al Jawf",
        "timezone": "Asia/Riyadh"
    },
    {
        "name": "Sakakah",
        "country": "Saudi Arabia",
        "province": "Al Jawf",
        "timezone": "Asia/Riyadh"
    },
    {
        "name": "Yanbu al Bahr",
        "country": "Saudi Arabia",
        "province": "Al Madinah",
        "timezone": "Asia/Riyadh"
    },
    {
        "name": "Dawmat al Jandal",
        "country": "Saudi Arabia",
        "province": "Al Jawf",
        "timezone": "Asia/Riyadh"
    },
    {
        "name": "Qal at Bishah",
        "country": "Saudi Arabia",
        "province": "`Asir",
        "timezone": "Asia/Riyadh"
    },
    {
        "name": "At Taif",
        "country": "Saudi Arabia",
        "province": "Makkah",
        "timezone": "Asia/Riyadh"
    },
    {
        "name": "Najran",
        "country": "Saudi Arabia",
        "province": "Najran",
        "timezone": "Asia/Riyadh"
    },
    {
        "name": "Al Quwayiyah",
        "country": "Saudi Arabia",
        "province": "Ar Riyad",
        "timezone": "Asia/Riyadh"
    },
    {
        "name": "Al Hillah",
        "country": "Saudi Arabia",
        "province": "Ar Riyad",
        "timezone": "Asia/Riyadh"
    },
    {
        "name": "Al Mubarraz",
        "country": "Saudi Arabia",
        "province": "Ash Sharqiyah",
        "timezone": "Asia/Riyadh"
    },
    {
        "name": "Al-Qatif",
        "country": "Saudi Arabia",
        "province": "Ash Sharqiyah",
        "timezone": "Asia/Riyadh"
    },
    {
        "name": "Az Zahran",
        "country": "Saudi Arabia",
        "province": "Ash Sharqiyah",
        "timezone": "Asia/Riyadh"
    },
    {
        "name": "Buraydah",
        "country": "Saudi Arabia",
        "province": "Al Quassim",
        "timezone": "Asia/Riyadh"
    },
    {
        "name": "Hail",
        "country": "Saudi Arabia",
        "province": "Ha'il",
        "timezone": "Asia/Riyadh"
    },
    {
        "name": "Arar",
        "country": "Saudi Arabia",
        "province": "Al Hudud ash Shamaliyah",
        "timezone": "Asia/Riyadh"
    },
    {
        "name": "Rafha",
        "country": "Saudi Arabia",
        "province": "Al Hudud ash Shamaliyah",
        "timezone": "Asia/Riyadh"
    },
    {
        "name": "Al Kharj",
        "country": "Saudi Arabia",
        "province": "Ar Riyad",
        "timezone": "Asia/Riyadh"
    },
    {
        "name": "Ad Damman",
        "country": "Saudi Arabia",
        "province": "Ash Sharqiyah",
        "timezone": "Asia/Riyadh"
    },
    {
        "name": "Hafar al Batin",
        "country": "Saudi Arabia",
        "province": "Ash Sharqiyah",
        "timezone": "Asia/Riyadh"
    },
    {
        "name": "Al Jubayl",
        "country": "Saudi Arabia",
        "province": "Ash Sharqiyah",
        "timezone": "Asia/Riyadh"
    },
    {
        "name": "Al Qunfudhah",
        "country": "Saudi Arabia",
        "province": "Makkah",
        "timezone": "Asia/Riyadh"
    },
    {
        "name": "Al Hufuf",
        "country": "Saudi Arabia",
        "province": "Ash Sharqiyah",
        "timezone": "Asia/Riyadh"
    },
    {
        "name": "Al Wajh",
        "country": "Saudi Arabia",
        "province": "Tabuk",
        "timezone": "Asia/Riyadh"
    },
    {
        "name": "Abha",
        "country": "Saudi Arabia",
        "province": "`Asir",
        "timezone": "Asia/Riyadh"
    },
    {
        "name": "Jizan",
        "country": "Saudi Arabia",
        "province": "Jizan",
        "timezone": "Asia/Riyadh"
    },
    {
        "name": "As Sulayyil",
        "country": "Saudi Arabia",
        "province": "Ar Riyad",
        "timezone": "Asia/Riyadh"
    },
    {
        "name": "Medina",
        "country": "Saudi Arabia",
        "province": "Al Madinah",
        "timezone": "Asia/Riyadh"
    },
    {
        "name": "Tabuk",
        "country": "Saudi Arabia",
        "province": "Tabuk",
        "timezone": "Asia/Riyadh"
    },
    {
        "name": "Jeddah",
        "country": "Saudi Arabia",
        "province": "Makkah",
        "timezone": "Asia/Riyadh"
    },
    {
        "name": "Makkah",
        "country": "Saudi Arabia",
        "province": "Makkah",
        "timezone": "Asia/Riyadh"
    },
    {
        "name": "Riyadh",
        "country": "Saudi Arabia",
        "province": "Ar Riyad",
        "timezone": "Asia/Riyadh"
    },
    {
        "name": "Fatick",
        "country": "Senegal",
        "province": "Fatick",
        "timezone": "Africa/Dakar"
    },
    {
        "name": "Diourbel",
        "country": "Senegal",
        "province": "Diourbel",
        "timezone": "Africa/Dakar"
    },
    {
        "name": "Louga",
        "country": "Senegal",
        "province": "Louga",
        "timezone": "Africa/Dakar"
    },
    {
        "name": "Thies",
        "country": "Senegal",
        "province": "Thiès",
        "timezone": "Africa/Dakar"
    },
    {
        "name": "Kolda",
        "country": "Senegal",
        "province": "Kolda",
        "timezone": "Africa/Dakar"
    },
    {
        "name": "Tambacounda",
        "country": "Senegal",
        "province": "Tambacounda",
        "timezone": "Africa/Dakar"
    },
    {
        "name": "Kedougou",
        "country": "Senegal",
        "province": "Tambacounda",
        "timezone": "Africa/Dakar"
    },
    {
        "name": "Ziguinchor",
        "country": "Senegal",
        "province": "Ziguinchor",
        "timezone": "Africa/Dakar"
    },
    {
        "name": "Kaolack",
        "country": "Senegal",
        "province": "Kaolack",
        "timezone": "Africa/Dakar"
    },
    {
        "name": "Kaedi",
        "country": "Senegal",
        "province": "Matam",
        "timezone": "Africa/Nouakchott"
    },
    {
        "name": "Dakar",
        "country": "Senegal",
        "province": "Dakar",
        "timezone": "Africa/Dakar"
    },
    {
        "name": "Subotica",
        "country": "Serbia",
        "province": "Severno-Backi",
        "timezone": "Europe/Belgrade"
    },
    {
        "name": "Kragujevac",
        "country": "Serbia",
        "province": "Šumadijski",
        "timezone": "Europe/Belgrade"
    },
    {
        "name": "Zrenjanin",
        "country": "Serbia",
        "province": "Srednje-Banatski",
        "timezone": "Europe/Belgrade"
    },
    {
        "name": "Pec",
        "country": "Serbia",
        "province": "Moravicki",
        "timezone": "Europe/Belgrade"
    },
    {
        "name": "Nis",
        "country": "Serbia",
        "province": "Nišavski",
        "timezone": "Europe/Belgrade"
    },
    {
        "name": "Novi Sad",
        "country": "Serbia",
        "province": "Južno-Backi",
        "timezone": "Europe/Belgrade"
    },
    {
        "name": "Belgrade",
        "country": "Serbia",
        "province": "Grad Beograd",
        "timezone": "Europe/Belgrade"
    },
    {
        "name": "Victoria",
        "country": "Seychelles",
        "province": "",
        "timezone": "Indian/Mahe"
    },
    {
        "name": "Makeni",
        "country": "Sierra Leone",
        "province": "Northern",
        "timezone": "Africa/Freetown"
    },
    {
        "name": "Koidu",
        "country": "Sierra Leone",
        "province": "Eastern",
        "timezone": "Africa/Freetown"
    },
    {
        "name": "Kenema",
        "country": "Sierra Leone",
        "province": "Eastern",
        "timezone": "Africa/Freetown"
    },
    {
        "name": "Bo",
        "country": "Sierra Leone",
        "province": "Southern",
        "timezone": "Africa/Freetown"
    },
    {
        "name": "Freetown",
        "country": "Sierra Leone",
        "province": "Western",
        "timezone": "Africa/Freetown"
    },
    {
        "name": "Singapore",
        "country": "Singapore",
        "province": "",
        "timezone": "Asia/Singapore"
    },
    {
        "name": "Banska Bystrica",
        "country": "Slovakia",
        "province": "Banskobystrický",
        "timezone": "Europe/Bratislava"
    },
    {
        "name": "Trnava",
        "country": "Slovakia",
        "province": "Trnavský",
        "timezone": "Europe/Bratislava"
    },
    {
        "name": "Zvolen",
        "country": "Slovakia",
        "province": "Banskobystrický",
        "timezone": "Europe/Bratislava"
    },
    {
        "name": "Zilina",
        "country": "Slovakia",
        "province": "Žilinský",
        "timezone": "Europe/Bratislava"
    },
    {
        "name": "Kosice",
        "country": "Slovakia",
        "province": "Košický",
        "timezone": "Europe/Bratislava"
    },
    {
        "name": "Presov",
        "country": "Slovakia",
        "province": "Prešov",
        "timezone": "Europe/Bratislava"
    },
    {
        "name": "Bratislava",
        "country": "Slovakia",
        "province": "Bratislavský",
        "timezone": "Europe/Bratislava"
    },
    {
        "name": "Maribor",
        "country": "Slovenia",
        "province": "Maribor",
        "timezone": "Europe/Ljubljana"
    },
    {
        "name": "Ljubljana",
        "country": "Slovenia",
        "province": "Osrednjeslovenska",
        "timezone": "Europe/Ljubljana"
    },
    {
        "name": "Gizo",
        "country": "Solomon Islands",
        "province": "Choiseul",
        "timezone": "Pacific/Guadalcanal"
    },
    {
        "name": "Lata",
        "country": "Solomon Islands",
        "province": "Temotu",
        "timezone": "Pacific/Guadalcanal"
    },
    {
        "name": "Honiara",
        "country": "Solomon Islands",
        "province": "Guadalcanal",
        "timezone": "Pacific/Guadalcanal"
    },
    {
        "name": "Hudur",
        "country": "Somalia",
        "province": "Bakool",
        "timezone": "Africa/Mogadishu"
    },
    {
        "name": "Garbahaarey",
        "country": "Somalia",
        "province": "Gedo",
        "timezone": "Africa/Mogadishu"
    },
    {
        "name": "Bu'aale",
        "country": "Somalia",
        "province": "Jubbada Dhexe",
        "timezone": "Africa/Mogadishu"
    },
    {
        "name": "Dhuusa Mareeb",
        "country": "Somalia",
        "province": "Galguduud",
        "timezone": "Africa/Mogadishu"
    },
    {
        "name": "Buurhakaba",
        "country": "Somalia",
        "province": "Bay",
        "timezone": "Africa/Mogadishu"
    },
    {
        "name": "Luuq",
        "country": "Somalia",
        "province": "Gedo",
        "timezone": "Africa/Mogadishu"
    },
    {
        "name": "Mandera",
        "country": "Somalia",
        "province": "Gedo",
        "timezone": "Africa/Nairobi"
    },
    {
        "name": "Ferfer",
        "country": "Somalia",
        "province": "Hiiraan",
        "timezone": "Africa/Mogadishu"
    },
    {
        "name": "Jawhar",
        "country": "Somalia",
        "province": "Shabeellaha Dhexe",
        "timezone": "Africa/Mogadishu"
    },
    {
        "name": "Hurdiyo",
        "country": "Somalia",
        "province": "Bari",
        "timezone": "Africa/Mogadishu"
    },
    {
        "name": "Qardho",
        "country": "Somalia",
        "province": "Bari",
        "timezone": "Africa/Mogadishu"
    },
    {
        "name": "Caluula",
        "country": "Somalia",
        "province": "Bari",
        "timezone": "Africa/Mogadishu"
    },
    {
        "name": "Buur Gaabo",
        "country": "Somalia",
        "province": "Jubbada Hoose",
        "timezone": "Africa/Mogadishu"
    },
    {
        "name": "Baydhabo",
        "country": "Somalia",
        "province": "Bay",
        "timezone": "Africa/Mogadishu"
    },
    {
        "name": "Marka",
        "country": "Somalia",
        "province": "Shabeellaha Hoose",
        "timezone": "Africa/Mogadishu"
    },
    {
        "name": "Mereeg",
        "country": "Somalia",
        "province": "Galguduud",
        "timezone": "Africa/Mogadishu"
    },
    {
        "name": "Beledweyne",
        "country": "Somalia",
        "province": "Hiiraan",
        "timezone": "Africa/Mogadishu"
    },
    {
        "name": "Boosaaso",
        "country": "Somalia",
        "province": "Bari",
        "timezone": "Africa/Mogadishu"
    },
    {
        "name": "Bandarbeyla",
        "country": "Somalia",
        "province": "Bari",
        "timezone": "Africa/Mogadishu"
    },
    {
        "name": "Gaalkacyo",
        "country": "Somalia",
        "province": "Mudug",
        "timezone": "Africa/Mogadishu"
    },
    {
        "name": "Eyl",
        "country": "Somalia",
        "province": "Nugaal",
        "timezone": "Africa/Mogadishu"
    },
    {
        "name": "Garoowe",
        "country": "Somalia",
        "province": "Nugaal",
        "timezone": "Africa/Mogadishu"
    },
    {
        "name": "Jamaame",
        "country": "Somalia",
        "province": "Jubbada Hoose",
        "timezone": "Africa/Mogadishu"
    },
    {
        "name": "Kismaayo",
        "country": "Somalia",
        "province": "Jubbada Hoose",
        "timezone": "Africa/Mogadishu"
    },
    {
        "name": "Mogadishu",
        "country": "Somalia",
        "province": "Banaadir",
        "timezone": "Africa/Mogadishu"
    },
    {
        "name": "Laascaanood",
        "country": "Somaliland",
        "province": "",
        "timezone": "Africa/Mogadishu"
    },
    {
        "name": "Ceerigaabo",
        "country": "Somaliland",
        "province": "",
        "timezone": "Africa/Mogadishu"
    },
    {
        "name": "Boorama",
        "country": "Somaliland",
        "province": "",
        "timezone": "Africa/Mogadishu"
    },
    {
        "name": "Burco",
        "country": "Somaliland",
        "province": "",
        "timezone": "Africa/Mogadishu"
    },
    {
        "name": "Maydh",
        "country": "Somaliland",
        "province": "",
        "timezone": "Africa/Mogadishu"
    },
    {
        "name": "Berbera",
        "country": "Somaliland",
        "province": "",
        "timezone": "Africa/Mogadishu"
    },
    {
        "name": "Hargeysa",
        "country": "Somaliland",
        "province": "",
        "timezone": "Africa/Mogadishu"
    },
    {
        "name": "Qacha's Nek",
        "country": "South Africa",
        "province": "Eastern Cape",
        "timezone": "Africa/Maseru"
    },
    {
        "name": "Colesberg",
        "country": "South Africa",
        "province": "Northern Cape",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Poffader",
        "country": "South Africa",
        "province": "Northern Cape",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Carnarvon",
        "country": "South Africa",
        "province": "Northern Cape",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Prieska",
        "country": "South Africa",
        "province": "Northern Cape",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Kuruman",
        "country": "South Africa",
        "province": "Northern Cape",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Knysna",
        "country": "South Africa",
        "province": "Western Cape",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Swellendam",
        "country": "South Africa",
        "province": "Western Cape",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Hermanus",
        "country": "South Africa",
        "province": "Western Cape",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Paarl",
        "country": "South Africa",
        "province": "Western Cape",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Bredasdorp",
        "country": "South Africa",
        "province": "Western Cape",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Beaufort West",
        "country": "South Africa",
        "province": "Western Cape",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Brits",
        "country": "South Africa",
        "province": "North West",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Bloemhof",
        "country": "South Africa",
        "province": "North West",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Potchefstroom",
        "country": "South Africa",
        "province": "North West",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Brandfort",
        "country": "South Africa",
        "province": "Orange Free State",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Bethlehem",
        "country": "South Africa",
        "province": "Orange Free State",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Springs",
        "country": "South Africa",
        "province": "Gauteng",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Volksrust",
        "country": "South Africa",
        "province": "Mpumalanga",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Mbombela",
        "country": "South Africa",
        "province": "Mpumalanga",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Komatipoort",
        "country": "South Africa",
        "province": "Mpumalanga",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Middelburg",
        "country": "South Africa",
        "province": "Mpumalanga",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Bethal",
        "country": "South Africa",
        "province": "Mpumalanga",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Standerton",
        "country": "South Africa",
        "province": "Mpumalanga",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Lebowakgomo",
        "country": "South Africa",
        "province": "Limpopo",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Tzaneen",
        "country": "South Africa",
        "province": "Limpopo",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Ulundi",
        "country": "South Africa",
        "province": "KwaZulu-Natal",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Ladysmith",
        "country": "South Africa",
        "province": "KwaZulu-Natal",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Port Shepstone",
        "country": "South Africa",
        "province": "KwaZulu-Natal",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Ubomba",
        "country": "South Africa",
        "province": "KwaZulu-Natal",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Cradock",
        "country": "South Africa",
        "province": "Eastern Cape",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Uitenhage",
        "country": "South Africa",
        "province": "Eastern Cape",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Port Alfred",
        "country": "South Africa",
        "province": "Eastern Cape",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Grahamstown",
        "country": "South Africa",
        "province": "Eastern Cape",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Port St. Johns",
        "country": "South Africa",
        "province": "Eastern Cape",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Aliwal North",
        "country": "South Africa",
        "province": "Eastern Cape",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Queenstown",
        "country": "South Africa",
        "province": "Eastern Cape",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Benoni",
        "country": "South Africa",
        "province": "Gauteng",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Vereeniging",
        "country": "South Africa",
        "province": "Gauteng",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "De Aar",
        "country": "South Africa",
        "province": "Northern Cape",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Alexander Bay",
        "country": "South Africa",
        "province": "Northern Cape",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Kimberley",
        "country": "South Africa",
        "province": "Northern Cape",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Oudtshoorn",
        "country": "South Africa",
        "province": "Western Cape",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Vanhynsdorp",
        "country": "South Africa",
        "province": "Western Cape",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Saldanha",
        "country": "South Africa",
        "province": "Western Cape",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Mossel Bay",
        "country": "South Africa",
        "province": "Western Cape",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Vryburg",
        "country": "South Africa",
        "province": "North West",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Rustenburg",
        "country": "South Africa",
        "province": "North West",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Mmabatho",
        "country": "South Africa",
        "province": "North West",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Klerksdorp",
        "country": "South Africa",
        "province": "North West",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Kroonstad",
        "country": "South Africa",
        "province": "Orange Free State",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Polokwane",
        "country": "South Africa",
        "province": "Limpopo",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Thohoyandou",
        "country": "South Africa",
        "province": "Limpopo",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Musina",
        "country": "South Africa",
        "province": "Limpopo",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Vryheid",
        "country": "South Africa",
        "province": "KwaZulu-Natal",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Pietermaritzburg",
        "country": "South Africa",
        "province": "KwaZulu-Natal",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Umtata",
        "country": "South Africa",
        "province": "Eastern Cape",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Graaff Reinet",
        "country": "South Africa",
        "province": "Eastern Cape",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Bhisho",
        "country": "South Africa",
        "province": "Eastern Cape",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Springbok",
        "country": "South Africa",
        "province": "Northern Cape",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Upington",
        "country": "South Africa",
        "province": "Northern Cape",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Worcester",
        "country": "South Africa",
        "province": "Western Cape",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "George",
        "country": "South Africa",
        "province": "Western Cape",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Welkom",
        "country": "South Africa",
        "province": "Orange Free State",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "East London",
        "country": "South Africa",
        "province": "Eastern Cape",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Middelburg",
        "country": "South Africa",
        "province": "Eastern Cape",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Bloemfontein",
        "country": "South Africa",
        "province": "Orange Free State",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Pretoria",
        "country": "South Africa",
        "province": "Gauteng",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Port Elizabeth",
        "country": "South Africa",
        "province": "Eastern Cape",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Durban",
        "country": "South Africa",
        "province": "KwaZulu-Natal",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Johannesburg",
        "country": "South Africa",
        "province": "Gauteng",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Cape Town",
        "country": "South Africa",
        "province": "Western Cape",
        "timezone": "Africa/Johannesburg"
    },
    {
        "name": "Grytviken",
        "country": "South Georgia and the Islands",
        "province": "",
        "timezone": "Atlantic/South_Georgia"
    },
    {
        "name": "Eumseong",
        "country": "South Korea",
        "province": "Chungcheongbuk-do",
        "timezone": "Asia/Seoul"
    },
    {
        "name": "Cheongju",
        "country": "South Korea",
        "province": "Chungcheongbuk-do",
        "timezone": "Asia/Seoul"
    },
    {
        "name": "Wonju",
        "country": "South Korea",
        "province": "Gangwon-do",
        "timezone": "Asia/Seoul"
    },
    {
        "name": "Chuncheon",
        "country": "South Korea",
        "province": "Gangwon-do",
        "timezone": "Asia/Seoul"
    },
    {
        "name": "Ansan",
        "country": "South Korea",
        "province": "Gyeonggi-do",
        "timezone": "Asia/Seoul"
    },
    {
        "name": "Iksan",
        "country": "South Korea",
        "province": "Jeollabuk-do",
        "timezone": "Asia/Seoul"
    },
    {
        "name": "Gyeongju",
        "country": "South Korea",
        "province": "Daegu",
        "timezone": "Asia/Seoul"
    },
    {
        "name": "Changwon",
        "country": "South Korea",
        "province": "Gyeongsangnam-do",
        "timezone": "Asia/Seoul"
    },
    {
        "name": "Yeosu",
        "country": "South Korea",
        "province": "Gwangju",
        "timezone": "Asia/Seoul"
    },
    {
        "name": "Andong",
        "country": "South Korea",
        "province": "Daegu",
        "timezone": "Asia/Seoul"
    },
    {
        "name": "Jeju",
        "country": "South Korea",
        "province": "Jeju",
        "timezone": "Asia/Seoul"
    },
    {
        "name": "Gangneung",
        "country": "South Korea",
        "province": "Gangwon-do",
        "timezone": "Asia/Seoul"
    },
    {
        "name": "Sokcho",
        "country": "South Korea",
        "province": "Gangwon-do",
        "timezone": "Asia/Seoul"
    },
    {
        "name": "Jeonju",
        "country": "South Korea",
        "province": "Jeollabuk-do",
        "timezone": "Asia/Seoul"
    },
    {
        "name": "Gunsan",
        "country": "South Korea",
        "province": "Jeollabuk-do",
        "timezone": "Asia/Seoul"
    },
    {
        "name": "Mokpo",
        "country": "South Korea",
        "province": "Gwangju",
        "timezone": "Asia/Seoul"
    },
    {
        "name": "Puch'on",
        "country": "South Korea",
        "province": "Gyeonggi-do",
        "timezone": "Asia/Seoul"
    },
    {
        "name": "Songnam",
        "country": "South Korea",
        "province": "Gyeonggi-do",
        "timezone": "Asia/Seoul"
    },
    {
        "name": "Goyang",
        "country": "South Korea",
        "province": "Gyeonggi-do",
        "timezone": "Asia/Seoul"
    },
    {
        "name": "Suwon",
        "country": "South Korea",
        "province": "Gyeonggi-do",
        "timezone": "Asia/Seoul"
    },
    {
        "name": "Pohang",
        "country": "South Korea",
        "province": "Daegu",
        "timezone": "Asia/Seoul"
    },
    {
        "name": "Ulsan",
        "country": "South Korea",
        "province": "Ulsan",
        "timezone": "Asia/Seoul"
    },
    {
        "name": "Daegu",
        "country": "South Korea",
        "province": "Taegu-gwangyoksi",
        "timezone": "Asia/Seoul"
    },
    {
        "name": "Incheon",
        "country": "South Korea",
        "province": "Inch'on-gwangyoksi",
        "timezone": "Asia/Seoul"
    },
    {
        "name": "Daejeon",
        "country": "South Korea",
        "province": "Daejeon",
        "timezone": "Asia/Seoul"
    },
    {
        "name": "Gwangju",
        "country": "South Korea",
        "province": "Kwangju-gwangyoksi",
        "timezone": "Asia/Seoul"
    },
    {
        "name": "Busan",
        "country": "South Korea",
        "province": "Busan",
        "timezone": "Asia/Seoul"
    },
    {
        "name": "Seoul",
        "country": "South Korea",
        "province": "Seoul",
        "timezone": "Asia/Seoul"
    },
    {
        "name": "Bentiu",
        "country": "South Sudan",
        "province": "Unity",
        "timezone": "Africa/Juba"
    },
    {
        "name": "Maridi",
        "country": "South Sudan",
        "province": "West Equatoria",
        "timezone": "Africa/Juba"
    },
    {
        "name": "Yei",
        "country": "South Sudan",
        "province": "Central Equatoria",
        "timezone": "Africa/Juba"
    },
    {
        "name": "Melut",
        "country": "South Sudan",
        "province": "Upper Nile",
        "timezone": "Africa/Juba"
    },
    {
        "name": "Nasir",
        "country": "South Sudan",
        "province": "Upper Nile",
        "timezone": "Africa/Juba"
    },
    {
        "name": "Gogrial",
        "country": "South Sudan",
        "province": "Warap",
        "timezone": "Africa/Juba"
    },
    {
        "name": "Kapoeta",
        "country": "South Sudan",
        "province": "East Equatoria",
        "timezone": "Africa/Juba"
    },
    {
        "name": "Aweil",
        "country": "South Sudan",
        "province": "North Bahr-al-Ghazal",
        "timezone": "Africa/Juba"
    },
    {
        "name": "Rumbek",
        "country": "South Sudan",
        "province": "Lakes",
        "timezone": "Africa/Juba"
    },
    {
        "name": "Yambio",
        "country": "South Sudan",
        "province": "West Equatoria",
        "timezone": "Africa/Juba"
    },
    {
        "name": "Bor",
        "country": "South Sudan",
        "province": "Jungoli",
        "timezone": "Africa/Juba"
    },
    {
        "name": "Nimule",
        "country": "South Sudan",
        "province": "East Equatoria",
        "timezone": "Africa/Juba"
    },
    {
        "name": "Juba",
        "country": "South Sudan",
        "province": "Central Equatoria",
        "timezone": "Africa/Juba"
    },
    {
        "name": "Malakal",
        "country": "South Sudan",
        "province": "Upper Nile",
        "timezone": "Africa/Juba"
    },
    {
        "name": "Wau",
        "country": "South Sudan",
        "province": "West Bahr-al-Ghazal",
        "timezone": "Africa/Juba"
    },
    {
        "name": "Merida",
        "country": "Spain",
        "province": "Extremadura",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Marbella",
        "country": "Spain",
        "province": "Andalucía",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Linares",
        "country": "Spain",
        "province": "Andalucía",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Algeciras",
        "country": "Spain",
        "province": "Andalucía",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Leon",
        "country": "Spain",
        "province": "Castilla y León",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Mataro",
        "country": "Spain",
        "province": "Cataluña",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Gijon",
        "country": "Spain",
        "province": "Principado de Asturias",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Vitoria",
        "country": "Spain",
        "province": "País Vasco",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Almeria",
        "country": "Spain",
        "province": "Andalucía",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Malaga",
        "country": "Spain",
        "province": "Andalucía",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Jaén",
        "country": "Spain",
        "province": "Andalucía",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Huelva",
        "country": "Spain",
        "province": "Andalucía",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Albacete",
        "country": "Spain",
        "province": "Castilla-La Mancha",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Toledo",
        "country": "Spain",
        "province": "Castilla-La Mancha",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Guadalajara",
        "country": "Spain",
        "province": "Castilla-La Mancha",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Santander",
        "country": "Spain",
        "province": "Cantabria",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Salamanca",
        "country": "Spain",
        "province": "Castilla y León",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Burgos",
        "country": "Spain",
        "province": "Castilla y León",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Tarragona",
        "country": "Spain",
        "province": "Cataluña",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Lorca",
        "country": "Spain",
        "province": "Región de Murcia",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Cartagena",
        "country": "Spain",
        "province": "Región de Murcia",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Oviedo",
        "country": "Spain",
        "province": "Principado de Asturias",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Santiago de Compostela",
        "country": "Spain",
        "province": "Galicia",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Badajoz",
        "country": "Spain",
        "province": "Extremadura",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Logrono",
        "country": "Spain",
        "province": "La Rioja",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "San Sebastián",
        "country": "Spain",
        "province": "País Vasco",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Alicante",
        "country": "Spain",
        "province": "Comunidad Valenciana",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Castello",
        "country": "Spain",
        "province": "Comunidad Valenciana",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Arrecife",
        "country": "Spain",
        "province": "",
        "timezone": "Atlantic/Canary"
    },
    {
        "name": "Cadiz",
        "country": "Spain",
        "province": "Andalucía",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Granada",
        "country": "Spain",
        "province": "Andalucía",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Murcia",
        "country": "Spain",
        "province": "Región de Murcia",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Ceuta",
        "country": "Spain",
        "province": "Ceuta",
        "timezone": "Africa/Ceuta"
    },
    {
        "name": "La Coruña",
        "country": "Spain",
        "province": "Galicia",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Ourense",
        "country": "Spain",
        "province": "Galicia",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Pamplona",
        "country": "Spain",
        "province": "Comunidad Foral de Navarra",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Valladolid",
        "country": "Spain",
        "province": "Castilla y León",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Melilla",
        "country": "Spain",
        "province": "Melilla",
        "timezone": "Africa/Ceuta"
    },
    {
        "name": "Palma",
        "country": "Spain",
        "province": "Islas Baleares",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Zaragoza",
        "country": "Spain",
        "province": "Aragón",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Santa Cruz de Tenerife",
        "country": "Spain",
        "province": "",
        "timezone": "Atlantic/Canary"
    },
    {
        "name": "Cordoba",
        "country": "Spain",
        "province": "Andalucía",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Vigo",
        "country": "Spain",
        "province": "Galicia",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Bilbao",
        "country": "Spain",
        "province": "País Vasco",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Las Palmas",
        "country": "Spain",
        "province": "",
        "timezone": "Atlantic/Canary"
    },
    {
        "name": "Seville",
        "country": "Spain",
        "province": "Andalucía",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Valencia",
        "country": "Spain",
        "province": "Comunidad Valenciana",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Barcelona",
        "country": "Spain",
        "province": "Cataluña",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Madrid",
        "country": "Spain",
        "province": "Comunidad de Madrid",
        "timezone": "Europe/Madrid"
    },
    {
        "name": "Trincomalee",
        "country": "Sri Lanka",
        "province": "Trincomalee",
        "timezone": "Asia/Colombo"
    },
    {
        "name": "Puttalan",
        "country": "Sri Lanka",
        "province": "Puttalam",
        "timezone": "Asia/Colombo"
    },
    {
        "name": "Ratnapura",
        "country": "Sri Lanka",
        "province": "Ratnapura",
        "timezone": "Asia/Colombo"
    },
    {
        "name": "Batticaloa",
        "country": "Sri Lanka",
        "province": "Batticaloa",
        "timezone": "Asia/Colombo"
    },
    {
        "name": "Kilinochchi",
        "country": "Sri Lanka",
        "province": "Kilinochchi",
        "timezone": "Asia/Colombo"
    },
    {
        "name": "Matara",
        "country": "Sri Lanka",
        "province": "Matara",
        "timezone": "Asia/Colombo"
    },
    {
        "name": "Badulla",
        "country": "Sri Lanka",
        "province": "Badulla",
        "timezone": "Asia/Colombo"
    },
    {
        "name": "Moratuwa",
        "country": "Sri Lanka",
        "province": "Colombo",
        "timezone": "Asia/Colombo"
    },
    {
        "name": "Galle",
        "country": "Sri Lanka",
        "province": "Galle",
        "timezone": "Asia/Colombo"
    },
    {
        "name": "Anuradhapura",
        "country": "Sri Lanka",
        "province": "Anuradhapura",
        "timezone": "Asia/Colombo"
    },
    {
        "name": "Jaffna",
        "country": "Sri Lanka",
        "province": "Jaffna",
        "timezone": "Asia/Colombo"
    },
    {
        "name": "Kandy",
        "country": "Sri Lanka",
        "province": "Kandy",
        "timezone": "Asia/Colombo"
    },
    {
        "name": "Sri Jawewardenepura Kotte",
        "country": "Sri Lanka",
        "province": "Colombo",
        "timezone": "Asia/Colombo"
    },
    {
        "name": "Colombo",
        "country": "Sri Lanka",
        "province": "Colombo",
        "timezone": "Asia/Colombo"
    },
    {
        "name": "Ad Damazin",
        "country": "Sudan",
        "province": "Blue Nile",
        "timezone": "Africa/Khartoum"
    },
    {
        "name": "Haiya",
        "country": "Sudan",
        "province": "Red Sea",
        "timezone": "Africa/Khartoum"
    },
    {
        "name": "El Manaqil",
        "country": "Sudan",
        "province": "Gezira",
        "timezone": "Africa/Khartoum"
    },
    {
        "name": "Shendi",
        "country": "Sudan",
        "province": "River Nile",
        "timezone": "Africa/Khartoum"
    },
    {
        "name": "Berber",
        "country": "Sudan",
        "province": "River Nile",
        "timezone": "Africa/Khartoum"
    },
    {
        "name": "Kerma",
        "country": "Sudan",
        "province": "Northern",
        "timezone": "Africa/Khartoum"
    },
    {
        "name": "Ed Dueim",
        "country": "Sudan",
        "province": "White Nile",
        "timezone": "Africa/Khartoum"
    },
    {
        "name": "Umm Ruwaba",
        "country": "Sudan",
        "province": "North Kurdufan",
        "timezone": "Africa/Khartoum"
    },
    {
        "name": "En Nuhud",
        "country": "Sudan",
        "province": "South Kordofan",
        "timezone": "Africa/Khartoum"
    },
    {
        "name": "Muglad",
        "country": "Sudan",
        "province": "South Kordofan",
        "timezone": "Africa/Khartoum"
    },
    {
        "name": "Tokar",
        "country": "Sudan",
        "province": "Red Sea",
        "timezone": "Africa/Khartoum"
    },
    {
        "name": "Medani",
        "country": "Sudan",
        "province": "Gezira",
        "timezone": "Africa/Khartoum"
    },
    {
        "name": "Gedaref",
        "country": "Sudan",
        "province": "Gedarif",
        "timezone": "Africa/Khartoum"
    },
    {
        "name": "EdDamer",
        "country": "Sudan",
        "province": "River Nile",
        "timezone": "Africa/Khartoum"
    },
    {
        "name": "Atbara",
        "country": "Sudan",
        "province": "River Nile",
        "timezone": "Africa/Khartoum"
    },
    {
        "name": "Wadi Halfa",
        "country": "Sudan",
        "province": "Northern",
        "timezone": "Africa/Khartoum"
    },
    {
        "name": "Merowe",
        "country": "Sudan",
        "province": "Northern",
        "timezone": "Africa/Khartoum"
    },
    {
        "name": "Kosti",
        "country": "Sudan",
        "province": "White Nile",
        "timezone": "Africa/Khartoum"
    },
    {
        "name": "Sennar",
        "country": "Sudan",
        "province": "Sennar",
        "timezone": "Africa/Khartoum"
    },
    {
        "name": "El Fasher",
        "country": "Sudan",
        "province": "Northern Darfur",
        "timezone": "Africa/Khartoum"
    },
    {
        "name": "Kadugli",
        "country": "Sudan",
        "province": "South Kordufan",
        "timezone": "Africa/Khartoum"
    },
    {
        "name": "Babanusa",
        "country": "Sudan",
        "province": "South Kordofan",
        "timezone": "Africa/Khartoum"
    },
    {
        "name": "Geneina",
        "country": "Sudan",
        "province": "West Darfur",
        "timezone": "Africa/Khartoum"
    },
    {
        "name": "Omdurman",
        "country": "Sudan",
        "province": "Khartoum",
        "timezone": "Africa/Khartoum"
    },
    {
        "name": "El Obeid",
        "country": "Sudan",
        "province": "North Kurdufan",
        "timezone": "Africa/Khartoum"
    },
    {
        "name": "Port Sudan",
        "country": "Sudan",
        "province": "Red Sea",
        "timezone": "Africa/Khartoum"
    },
    {
        "name": "Niyala",
        "country": "Sudan",
        "province": "South Darfur",
        "timezone": "Africa/Khartoum"
    },
    {
        "name": "Dongola",
        "country": "Sudan",
        "province": "Northern",
        "timezone": "Africa/Khartoum"
    },
    {
        "name": "Kassala",
        "country": "Sudan",
        "province": "Kassala",
        "timezone": "Africa/Khartoum"
    },
    {
        "name": "Khartoum",
        "country": "Sudan",
        "province": "Khartoum",
        "timezone": "Africa/Khartoum"
    },
    {
        "name": "Onverwacht",
        "country": "Suriname",
        "province": "Para",
        "timezone": "America/Paramaribo"
    },
    {
        "name": "Groningen",
        "country": "Suriname",
        "province": "Saramacca",
        "timezone": "America/Paramaribo"
    },
    {
        "name": "Brownsweg",
        "country": "Suriname",
        "province": "Brokopondo",
        "timezone": "America/Paramaribo"
    },
    {
        "name": "Moengo",
        "country": "Suriname",
        "province": "Marowijne",
        "timezone": "America/Paramaribo"
    },
    {
        "name": "Nieuw Amsterdam",
        "country": "Suriname",
        "province": "Commewijne",
        "timezone": "America/Paramaribo"
    },
    {
        "name": "Nieuw Nickerie",
        "country": "Suriname",
        "province": "Nickerie",
        "timezone": "America/Paramaribo"
    },
    {
        "name": "Brokopondo",
        "country": "Suriname",
        "province": "Brokopondo",
        "timezone": "America/Paramaribo"
    },
    {
        "name": "Totness",
        "country": "Suriname",
        "province": "Coronie",
        "timezone": "America/Paramaribo"
    },
    {
        "name": "Cottica",
        "country": "Suriname",
        "province": "Sipaliwini",
        "timezone": "America/Paramaribo"
    },
    {
        "name": "Paramaribo",
        "country": "Suriname",
        "province": "Paramaribo",
        "timezone": "America/Paramaribo"
    },
    {
        "name": "Longyearbyen",
        "country": "Svalbard and Jan Mayen Islands",
        "province": "Svalbard",
        "timezone": "Arctic/Longyearbyen"
    },
    {
        "name": "Piggs Peak",
        "country": "Swaziland",
        "province": "Hhohho",
        "timezone": "Africa/Mbabane"
    },
    {
        "name": "Siteki",
        "country": "Swaziland",
        "province": "Lubombo",
        "timezone": "Africa/Mbabane"
    },
    {
        "name": "Manzini",
        "country": "Swaziland",
        "province": "Manzini",
        "timezone": "Africa/Mbabane"
    },
    {
        "name": "Hlatikulu",
        "country": "Swaziland",
        "province": "Shiselweni",
        "timezone": "Africa/Mbabane"
    },
    {
        "name": "Golela",
        "country": "Swaziland",
        "province": "Shiselweni",
        "timezone": "Africa/Mbabane"
    },
    {
        "name": "Lobamba",
        "country": "Swaziland",
        "province": "Manzini",
        "timezone": "Africa/Mbabane"
    },
    {
        "name": "Mbabane",
        "country": "Swaziland",
        "province": "Hhohho",
        "timezone": "Africa/Mbabane"
    },
    {
        "name": "Falun",
        "country": "Sweden",
        "province": "Dalarna",
        "timezone": "Europe/Stockholm"
    },
    {
        "name": "Nykoping",
        "country": "Sweden",
        "province": "Södermanland",
        "timezone": "Europe/Stockholm"
    },
    {
        "name": "Harnosand",
        "country": "Sweden",
        "province": "Västernorrland",
        "timezone": "Europe/Stockholm"
    },
    {
        "name": "Karlskrona",
        "country": "Sweden",
        "province": "Blekinge",
        "timezone": "Europe/Stockholm"
    },
    {
        "name": "Mariestad",
        "country": "Sweden",
        "province": "Västra Götaland",
        "timezone": "Europe/Stockholm"
    },
    {
        "name": "Vannersborg",
        "country": "Sweden",
        "province": "Västra Götaland",
        "timezone": "Europe/Stockholm"
    },
    {
        "name": "Borlänge",
        "country": "Sweden",
        "province": "Dalarna",
        "timezone": "Europe/Stockholm"
    },
    {
        "name": "Västerås",
        "country": "Sweden",
        "province": "Västmanland",
        "timezone": "Europe/Stockholm"
    },
    {
        "name": "Bollnäs",
        "country": "Sweden",
        "province": "Gävleborg",
        "timezone": "Europe/Stockholm"
    },
    {
        "name": "Gävle",
        "country": "Sweden",
        "province": "Gävleborg",
        "timezone": "Europe/Stockholm"
    },
    {
        "name": "Kalmar",
        "country": "Sweden",
        "province": "Kalmar",
        "timezone": "Europe/Stockholm"
    },
    {
        "name": "Växjö",
        "country": "Sweden",
        "province": "Kronoberg",
        "timezone": "Europe/Stockholm"
    },
    {
        "name": "Örebro",
        "country": "Sweden",
        "province": "Orebro",
        "timezone": "Europe/Stockholm"
    },
    {
        "name": "Norrköping",
        "country": "Sweden",
        "province": "Östergötland",
        "timezone": "Europe/Stockholm"
    },
    {
        "name": "Halmstad",
        "country": "Sweden",
        "province": "Halland",
        "timezone": "Europe/Stockholm"
    },
    {
        "name": "Karlstad",
        "country": "Sweden",
        "province": "Värmland",
        "timezone": "Europe/Stockholm"
    },
    {
        "name": "Skellefteå",
        "country": "Sweden",
        "province": "Västerbotten",
        "timezone": "Europe/Stockholm"
    },
    {
        "name": "Visby",
        "country": "Sweden",
        "province": "Gotland",
        "timezone": "Europe/Stockholm"
    },
    {
        "name": "Trollhättan",
        "country": "Sweden",
        "province": "Västra Götaland",
        "timezone": "Europe/Stockholm"
    },
    {
        "name": "Borås",
        "country": "Sweden",
        "province": "Västra Götaland",
        "timezone": "Europe/Stockholm"
    },
    {
        "name": "Kristianstad",
        "country": "Sweden",
        "province": "Skåne",
        "timezone": "Europe/Stockholm"
    },
    {
        "name": "Helsingborg",
        "country": "Sweden",
        "province": "Skåne",
        "timezone": "Europe/Stockholm"
    },
    {
        "name": "Jönköping",
        "country": "Sweden",
        "province": "Jönköping",
        "timezone": "Europe/Stockholm"
    },
    {
        "name": "Örnsköldsvik",
        "country": "Sweden",
        "province": "Västernorrland",
        "timezone": "Europe/Stockholm"
    },
    {
        "name": "Linköping",
        "country": "Sweden",
        "province": "Östergötland",
        "timezone": "Europe/Stockholm"
    },
    {
        "name": "Östersund",
        "country": "Sweden",
        "province": "Jämtland",
        "timezone": "Europe/Stockholm"
    },
    {
        "name": "Kiruna",
        "country": "Sweden",
        "province": "Norrbotten",
        "timezone": "Europe/Stockholm"
    },
    {
        "name": "Umeå",
        "country": "Sweden",
        "province": "Västerbotten",
        "timezone": "Europe/Stockholm"
    },
    {
        "name": "Uppsala",
        "country": "Sweden",
        "province": "Uppsala",
        "timezone": "Europe/Stockholm"
    },
    {
        "name": "Göteborg",
        "country": "Sweden",
        "province": "Västra Götaland",
        "timezone": "Europe/Stockholm"
    },
    {
        "name": "Luleå",
        "country": "Sweden",
        "province": "Norrbotten",
        "timezone": "Europe/Stockholm"
    },
    {
        "name": "Sundsvall",
        "country": "Sweden",
        "province": "Västernorrland",
        "timezone": "Europe/Stockholm"
    },
    {
        "name": "Malmö",
        "country": "Sweden",
        "province": "Skåne",
        "timezone": "Europe/Stockholm"
    },
    {
        "name": "Stockholm",
        "country": "Sweden",
        "province": "Stockholm",
        "timezone": "Europe/Stockholm"
    },
    {
        "name": "Delemont",
        "country": "Switzerland",
        "province": "Jura",
        "timezone": "Europe/Zurich"
    },
    {
        "name": "Neuchatel",
        "country": "Switzerland",
        "province": "Neuchâtel",
        "timezone": "Europe/Zurich"
    },
    {
        "name": "Aarau",
        "country": "Switzerland",
        "province": "Aargau",
        "timezone": "Europe/Zurich"
    },
    {
        "name": "Stans",
        "country": "Switzerland",
        "province": "Nidwalden",
        "timezone": "Europe/Zurich"
    },
    {
        "name": "Sion",
        "country": "Switzerland",
        "province": "Valais",
        "timezone": "Europe/Zurich"
    },
    {
        "name": "Herisau",
        "country": "Switzerland",
        "province": "Appenzell Ausserrhoden",
        "timezone": "Europe/Zurich"
    },
    {
        "name": "Saint Gallen",
        "country": "Switzerland",
        "province": "Sankt Gallen",
        "timezone": "Europe/Zurich"
    },
    {
        "name": "Bellinzona",
        "country": "Switzerland",
        "province": "Ticino",
        "timezone": "Europe/Zurich"
    },
    {
        "name": "Glarus",
        "country": "Switzerland",
        "province": "Glarus",
        "timezone": "Europe/Zurich"
    },
    {
        "name": "Schaffhausen",
        "country": "Switzerland",
        "province": "Schaffhausen",
        "timezone": "Europe/Zurich"
    },
    {
        "name": "Schwyz",
        "country": "Switzerland",
        "province": "Schwyz",
        "timezone": "Europe/Zurich"
    },
    {
        "name": "Frauenfeld",
        "country": "Switzerland",
        "province": "Thurgau",
        "timezone": "Europe/Zurich"
    },
    {
        "name": "Altdorf",
        "country": "Switzerland",
        "province": "Uri",
        "timezone": "Europe/Zurich"
    },
    {
        "name": "Zug",
        "country": "Switzerland",
        "province": "Zug",
        "timezone": "Europe/Zurich"
    },
    {
        "name": "Fribourg",
        "country": "Switzerland",
        "province": "Fribourg",
        "timezone": "Europe/Zurich"
    },
    {
        "name": "Liestal",
        "country": "Switzerland",
        "province": "Basel-Landschaft",
        "timezone": "Europe/Zurich"
    },
    {
        "name": "Solothurn",
        "country": "Switzerland",
        "province": "Solothurn",
        "timezone": "Europe/Zurich"
    },
    {
        "name": "Sarnen",
        "country": "Switzerland",
        "province": "Obwalden",
        "timezone": "Europe/Zurich"
    },
    {
        "name": "Appenzell",
        "country": "Switzerland",
        "province": "Appenzell Innerrhoden",
        "timezone": "Europe/Zurich"
    },
    {
        "name": "Chur",
        "country": "Switzerland",
        "province": "Graubünden",
        "timezone": "Europe/Zurich"
    },
    {
        "name": "Biel",
        "country": "Switzerland",
        "province": "Bern",
        "timezone": "Europe/Zurich"
    },
    {
        "name": "Luzern",
        "country": "Switzerland",
        "province": "Lucerne",
        "timezone": "Europe/Zurich"
    },
    {
        "name": "Lugano",
        "country": "Switzerland",
        "province": "Ticino",
        "timezone": "Europe/Zurich"
    },
    {
        "name": "Lausanne",
        "country": "Switzerland",
        "province": "Vaud",
        "timezone": "Europe/Zurich"
    },
    {
        "name": "Basel",
        "country": "Switzerland",
        "province": "Basel-Stadt",
        "timezone": "Europe/Zurich"
    },
    {
        "name": "Bern",
        "country": "Switzerland",
        "province": "Bern",
        "timezone": "Europe/Zurich"
    },
    {
        "name": "Zürich",
        "country": "Switzerland",
        "province": "Zürich",
        "timezone": "Europe/Zurich"
    },
    {
        "name": "Geneva",
        "country": "Switzerland",
        "province": "Genève",
        "timezone": "Europe/Zurich"
    },
    {
        "name": "Dar'a",
        "country": "Syria",
        "province": "Dar`a",
        "timezone": "Asia/Damascus"
    },
    {
        "name": "Al Ladhiqiyah",
        "country": "Syria",
        "province": "Lattakia (Al Ladhiqiyah)",
        "timezone": "Asia/Damascus"
    },
    {
        "name": "Madinat ath Thawrah",
        "country": "Syria",
        "province": "Ar Raqqah",
        "timezone": "Asia/Damascus"
    },
    {
        "name": "Izaz",
        "country": "Syria",
        "province": "Aleppo (Halab)",
        "timezone": "Asia/Damascus"
    },
    {
        "name": "Manbij",
        "country": "Syria",
        "province": "Aleppo (Halab)",
        "timezone": "Asia/Damascus"
    },
    {
        "name": "Idlib",
        "country": "Syria",
        "province": "Idlib",
        "timezone": "Asia/Damascus"
    },
    {
        "name": "Al Qamishli",
        "country": "Syria",
        "province": "Hasaka (Al Haksa)",
        "timezone": "Asia/Damascus"
    },
    {
        "name": "Al Hasakah",
        "country": "Syria",
        "province": "Hasaka (Al Haksa)",
        "timezone": "Asia/Damascus"
    },
    {
        "name": "Douma",
        "country": "Syria",
        "province": "Damascus",
        "timezone": "Asia/Damascus"
    },
    {
        "name": "Tartus",
        "country": "Syria",
        "province": "Tartus",
        "timezone": "Asia/Damascus"
    },
    {
        "name": "Ar Raqqah",
        "country": "Syria",
        "province": "Ar Raqqah",
        "timezone": "Asia/Damascus"
    },
    {
        "name": "Hamah",
        "country": "Syria",
        "province": "Hamah",
        "timezone": "Asia/Damascus"
    },
    {
        "name": "Tadmur",
        "country": "Syria",
        "province": "Homs (Hims)",
        "timezone": "Asia/Damascus"
    },
    {
        "name": "Abu Kamal",
        "country": "Syria",
        "province": "Dayr Az Zawr",
        "timezone": "Asia/Damascus"
    },
    {
        "name": "Dayr az Zawr",
        "country": "Syria",
        "province": "Dayr Az Zawr",
        "timezone": "Asia/Damascus"
    },
    {
        "name": "As Suwayda",
        "country": "Syria",
        "province": "As Suwayda'",
        "timezone": "Asia/Damascus"
    },
    {
        "name": "Ad Nabk",
        "country": "Syria",
        "province": "Damascus",
        "timezone": "Asia/Damascus"
    },
    {
        "name": "Al Qunaytirah",
        "country": "Syria",
        "province": "Golan",
        "timezone": "Asia/Damascus"
    },
    {
        "name": "Hims",
        "country": "Syria",
        "province": "Homs (Hims)",
        "timezone": "Asia/Damascus"
    },
    {
        "name": "Aleppo",
        "country": "Syria",
        "province": "Aleppo (Halab)",
        "timezone": "Asia/Damascus"
    },
    {
        "name": "Damascus",
        "country": "Syria",
        "province": "Damascus",
        "timezone": "Asia/Damascus"
    },
    {
        "name": "Bade",
        "country": "Taiwan",
        "province": "Taoyuan",
        "timezone": "Asia/Taipei"
    },
    {
        "name": "Pingzhen",
        "country": "Taiwan",
        "province": "Taoyuan",
        "timezone": "Asia/Taipei"
    },
    {
        "name": "Taibao",
        "country": "Taiwan",
        "province": "Chiayi",
        "timezone": "Asia/Taipei"
    },
    {
        "name": "Taoyuan",
        "country": "Taiwan",
        "province": "Taoyuan",
        "timezone": "Asia/Taipei"
    },
    {
        "name": "Yangmei",
        "country": "Taiwan",
        "province": "Taoyuan",
        "timezone": "Asia/Taipei"
    },
    {
        "name": "Yilan",
        "country": "Taiwan",
        "province": "Yilan",
        "timezone": "Asia/Taipei"
    },
    {
        "name": "Zhubei",
        "country": "Taiwan",
        "province": "Hsinchu",
        "timezone": "Asia/Taipei"
    },
    {
        "name": "Douliou",
        "country": "Taiwan",
        "province": "Yunlin",
        "timezone": "Asia/Taipei"
    },
    {
        "name": "Zhongli",
        "country": "Taiwan",
        "province": "Taoyuan",
        "timezone": "Asia/Taipei"
    },
    {
        "name": "Keelung",
        "country": "Taiwan",
        "province": "Keelung City",
        "timezone": "Asia/Taipei"
    },
    {
        "name": "Nantou",
        "country": "Taiwan",
        "province": "Nantou",
        "timezone": "Asia/Taipei"
    },
    {
        "name": "Puzi",
        "country": "Taiwan",
        "province": "Chiayi",
        "timezone": "Asia/Taipei"
    },
    {
        "name": "Changhua",
        "country": "Taiwan",
        "province": "Changhua",
        "timezone": "Asia/Taipei"
    },
    {
        "name": "Chiayi",
        "country": "Taiwan",
        "province": "Chiayi City",
        "timezone": "Asia/Taipei"
    },
    {
        "name": "Hsinchu",
        "country": "Taiwan",
        "province": "Hsinchu City",
        "timezone": "Asia/Taipei"
    },
    {
        "name": "Miaoli",
        "country": "Taiwan",
        "province": "Miaoli",
        "timezone": "Asia/Taipei"
    },
    {
        "name": "Pingtung",
        "country": "Taiwan",
        "province": "Pingtung",
        "timezone": "Asia/Taipei"
    },
    {
        "name": "Hualien",
        "country": "Taiwan",
        "province": "Hualien",
        "timezone": "Asia/Taipei"
    },
    {
        "name": "New Taipei",
        "country": "Taiwan",
        "province": "New Taipei City",
        "timezone": "Asia/Taipei"
    },
    {
        "name": "Tainan",
        "country": "Taiwan",
        "province": "Tainan City",
        "timezone": "Asia/Taipei"
    },
    {
        "name": "Taitung",
        "country": "Taiwan",
        "province": "Taitung",
        "timezone": "Asia/Taipei"
    },
    {
        "name": "Magong",
        "country": "Taiwan",
        "province": "Penghu",
        "timezone": "Asia/Taipei"
    },
    {
        "name": "Taichung",
        "country": "Taiwan",
        "province": "Taichung City",
        "timezone": "Asia/Taipei"
    },
    {
        "name": "Kaohsiung",
        "country": "Taiwan",
        "province": "Kaohsiung City",
        "timezone": "Asia/Taipei"
    },
    {
        "name": "Taipei",
        "country": "Taiwan",
        "province": "Taipei City",
        "timezone": "Asia/Taipei"
    },
    {
        "name": "Leninobod",
        "country": "Tajikistan",
        "province": "Leninabad",
        "timezone": "Asia/Dushanbe"
    },
    {
        "name": "Qurghonteppa",
        "country": "Tajikistan",
        "province": "Khatlon",
        "timezone": "Asia/Dushanbe"
    },
    {
        "name": "Konibodom",
        "country": "Tajikistan",
        "province": "Leninabad",
        "timezone": "Asia/Dushanbe"
    },
    {
        "name": "Kuybyshevskiy",
        "country": "Tajikistan",
        "province": "Khatlon",
        "timezone": "Asia/Dushanbe"
    },
    {
        "name": "Kulob",
        "country": "Tajikistan",
        "province": "Khatlon",
        "timezone": "Asia/Dushanbe"
    },
    {
        "name": "Uroteppa",
        "country": "Tajikistan",
        "province": "Leninabad",
        "timezone": "Asia/Dushanbe"
    },
    {
        "name": "Khorugh",
        "country": "Tajikistan",
        "province": "Gorno-Badakhshan",
        "timezone": "Asia/Dushanbe"
    },
    {
        "name": "Khujand",
        "country": "Tajikistan",
        "province": "Leninabad",
        "timezone": "Asia/Dushanbe"
    },
    {
        "name": "Dushanbe",
        "country": "Tajikistan",
        "province": "Tadzhikistan Territories",
        "timezone": "Asia/Dushanbe"
    },
    {
        "name": "Wete",
        "country": "Tanzania",
        "province": "Kaskazini-Pemba",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Kibaha",
        "country": "Tanzania",
        "province": "Pwani",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Mkokotoni",
        "country": "Tanzania",
        "province": "Kaskazini-Unguja",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Tunduma",
        "country": "Tanzania",
        "province": "Mbeya",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Tukuyu",
        "country": "Tanzania",
        "province": "Mbeya",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Sumbawanga",
        "country": "Tanzania",
        "province": "Rukwa",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Mpanda",
        "country": "Tanzania",
        "province": "Rukwa",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Kipili",
        "country": "Tanzania",
        "province": "Rukwa",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Karema",
        "country": "Tanzania",
        "province": "Rukwa",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Geita",
        "country": "Tanzania",
        "province": "Mwanza",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Nyahanga",
        "country": "Tanzania",
        "province": "Mwanza",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Kahama",
        "country": "Tanzania",
        "province": "Shinyanga",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Shinyanga",
        "country": "Tanzania",
        "province": "Shinyanga",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Nzega",
        "country": "Tanzania",
        "province": "Tabora",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Sikonge",
        "country": "Tanzania",
        "province": "Tabora",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Biharamulo",
        "country": "Tanzania",
        "province": "Kagera",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Bukoba",
        "country": "Tanzania",
        "province": "Kagera",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Ngara",
        "country": "Tanzania",
        "province": "Kagera",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Kakonko",
        "country": "Tanzania",
        "province": "Kigoma",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Kasulu",
        "country": "Tanzania",
        "province": "Kigoma",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Kanyato",
        "country": "Tanzania",
        "province": "Kigoma",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Uvinza",
        "country": "Tanzania",
        "province": "Kigoma",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Kigoma",
        "country": "Tanzania",
        "province": "Kigoma",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Mikumi",
        "country": "Tanzania",
        "province": "Morogoro",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Ifakara",
        "country": "Tanzania",
        "province": "Morogoro",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Kilosa",
        "country": "Tanzania",
        "province": "Morogoro",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Chake Chake",
        "country": "Tanzania",
        "province": "Kusini-Pemba",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Kibiti",
        "country": "Tanzania",
        "province": "Pwani",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Bagamoyo",
        "country": "Tanzania",
        "province": "Pwani",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Kilindoni",
        "country": "Tanzania",
        "province": "Pwani",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Mpwapwa",
        "country": "Tanzania",
        "province": "Dodoma",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Njombe",
        "country": "Tanzania",
        "province": "Iringa",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Iringa",
        "country": "Tanzania",
        "province": "Iringa",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Masasi",
        "country": "Tanzania",
        "province": "Mtwara",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Mtwara",
        "country": "Tanzania",
        "province": "Mtwara",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Tunduru",
        "country": "Tanzania",
        "province": "Ruvuma",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Mbamba Bay",
        "country": "Tanzania",
        "province": "Ruvuma",
        "timezone": "Africa/Blantyre"
    },
    {
        "name": "Manyoni",
        "country": "Tanzania",
        "province": "Singida",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Itigi",
        "country": "Tanzania",
        "province": "Singida",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Singida",
        "country": "Tanzania",
        "province": "Singida",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Ngorongoro",
        "country": "Tanzania",
        "province": "Arusha",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Oldeani",
        "country": "Tanzania",
        "province": "Arusha",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Mbulu",
        "country": "Tanzania",
        "province": "Arusha",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Babati",
        "country": "Tanzania",
        "province": "Arusha",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Same",
        "country": "Tanzania",
        "province": "Kilimanjaro",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Moshi",
        "country": "Tanzania",
        "province": "Kilimanjaro",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Musoma",
        "country": "Tanzania",
        "province": "Mara",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Korogwe",
        "country": "Tanzania",
        "province": "Tanga",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Tabora",
        "country": "Tanzania",
        "province": "Tabora",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Lindi",
        "country": "Tanzania",
        "province": "Lindi",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Songea",
        "country": "Tanzania",
        "province": "Ruvuma",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Tanga",
        "country": "Tanzania",
        "province": "Tanga",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Mwanza",
        "country": "Tanzania",
        "province": "Mwanza",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Morogoro",
        "country": "Tanzania",
        "province": "Morogoro",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Dodoma",
        "country": "Tanzania",
        "province": "Dodoma",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Arusha",
        "country": "Tanzania",
        "province": "Arusha",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Mbeya",
        "country": "Tanzania",
        "province": "Mbeya",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Zanzibar",
        "country": "Tanzania",
        "province": "Zanzibar West",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Dar es Salaam",
        "country": "Tanzania",
        "province": "Dar-Es-Salaam",
        "timezone": "Africa/Dar_es_Salaam"
    },
    {
        "name": "Mae Hong Son",
        "country": "Thailand",
        "province": "Mae Hong Son",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Phangnga",
        "country": "Thailand",
        "province": "Phangnga",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Ranong",
        "country": "Thailand",
        "province": "Ranong",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Krabi",
        "country": "Thailand",
        "province": "Krabi",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Phatthalung",
        "country": "Thailand",
        "province": "Phatthalung",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Satun",
        "country": "Thailand",
        "province": "Satun",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Lamphun",
        "country": "Thailand",
        "province": "Lamphun",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Kamphaeng Phet",
        "country": "Thailand",
        "province": "Kamphaeng Phet",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Phichit",
        "country": "Thailand",
        "province": "Phichit",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Phetchabun",
        "country": "Thailand",
        "province": "Phetchabun",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Supham Buri",
        "country": "Thailand",
        "province": "Suphan Buri",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Ang Thong",
        "country": "Thailand",
        "province": "Ang Thong",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Chainat",
        "country": "Thailand",
        "province": "Chai Nat",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Nakhon Nayok",
        "country": "Thailand",
        "province": "Nakhon Nayok",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Sing Buri",
        "country": "Thailand",
        "province": "Sing Buri",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Nakhon Pathom",
        "country": "Thailand",
        "province": "Nakhon Pathom",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Prachuap Khiri Khan",
        "country": "Thailand",
        "province": "Prachuap Khiri Khan",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Samut Sakhon",
        "country": "Thailand",
        "province": "Samut Sakhon",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Samut Songkhram",
        "country": "Thailand",
        "province": "Samut Songkhram",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Yasothon",
        "country": "Thailand",
        "province": "Yasothon",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Chachoengsao",
        "country": "Thailand",
        "province": "Chachoengsao",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Trat",
        "country": "Thailand",
        "province": "Trat",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Kalasin",
        "country": "Thailand",
        "province": "Kalasin",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Maha Sarakham",
        "country": "Thailand",
        "province": "Maha Sarakham",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Roi Et",
        "country": "Thailand",
        "province": "Roi Et",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Pattani",
        "country": "Thailand",
        "province": "Pattani",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Chumphon",
        "country": "Thailand",
        "province": "Chumphon",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Thung Song",
        "country": "Thailand",
        "province": "Nakhon Si Thammarat",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Trang",
        "country": "Thailand",
        "province": "Trang",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Yala",
        "country": "Thailand",
        "province": "Yala",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Chiang Rai",
        "country": "Thailand",
        "province": "Chiang Rai",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Lampang",
        "country": "Thailand",
        "province": "Lampang",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Nan",
        "country": "Thailand",
        "province": "Nan",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Phayao",
        "country": "Thailand",
        "province": "Phayao",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Phrae",
        "country": "Thailand",
        "province": "Phrae",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Phitsanulok",
        "country": "Thailand",
        "province": "Phitsanulok",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Sukhothai",
        "country": "Thailand",
        "province": "Sukhothai",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Uttaradit",
        "country": "Thailand",
        "province": "Uttaradit",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Kanchanaburi",
        "country": "Thailand",
        "province": "Kanchanaburi",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Mae Sot",
        "country": "Thailand",
        "province": "Tak",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Tak",
        "country": "Thailand",
        "province": "Tak",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Uthai Thani",
        "country": "Thailand",
        "province": "Uthai Thani",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Lop Buri",
        "country": "Thailand",
        "province": "Lop Buri",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Prachin Buri",
        "country": "Thailand",
        "province": "Prachin Buri",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Ayutthaya",
        "country": "Thailand",
        "province": "Phra Nakhon Si Ayutthaya",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Pathum Thani",
        "country": "Thailand",
        "province": "Pathum Thani",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Saraburi",
        "country": "Thailand",
        "province": "Saraburi",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Nonthaburi",
        "country": "Thailand",
        "province": "Nonthaburi",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Phetchaburi",
        "country": "Thailand",
        "province": "Phetchaburi",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Hua Hin",
        "country": "Thailand",
        "province": "Prachuap Khiri Khan",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Ratchaburi",
        "country": "Thailand",
        "province": "Ratchaburi",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Samut Prakan",
        "country": "Thailand",
        "province": "Samut Prakan",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Sisaket",
        "country": "Thailand",
        "province": "Si Sa Ket",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Si Racha",
        "country": "Thailand",
        "province": "Chon Buri",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Chon Buri",
        "country": "Thailand",
        "province": "Chon Buri",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Chanthaburi",
        "country": "Thailand",
        "province": "Chanthaburi",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Aranyaprathet",
        "country": "Thailand",
        "province": "Sa Kaeo",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Rayong",
        "country": "Thailand",
        "province": "Rayong",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Buriram",
        "country": "Thailand",
        "province": "Buri Ram",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Chaiyaphum",
        "country": "Thailand",
        "province": "Chaiyaphum",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Bua Yai",
        "country": "Thailand",
        "province": "Nakhon Ratchasima",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Surin",
        "country": "Thailand",
        "province": "Surin",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Loei",
        "country": "Thailand",
        "province": "Loei",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Nong Khai",
        "country": "Thailand",
        "province": "Nong Khai",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Sakhon Nakhon",
        "country": "Thailand",
        "province": "Sakon Nakhon",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Udon Thani",
        "country": "Thailand",
        "province": "Udon Thani",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Nakhon Phanom",
        "country": "Thailand",
        "province": "Nakhon Phanom",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Narathiwat",
        "country": "Thailand",
        "province": "Narathiwat",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Khon Kaen",
        "country": "Thailand",
        "province": "Khon Kaen",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Phuket",
        "country": "Thailand",
        "province": "Phuket",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Nakhon Si Thammarat",
        "country": "Thailand",
        "province": "Nakhon Si Thammarat",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Songkhla",
        "country": "Thailand",
        "province": "Songkhla (Songkhla Lake)",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Hat Yai",
        "country": "Thailand",
        "province": "Songkhla",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Nakhon Sawan",
        "country": "Thailand",
        "province": "Nakhon Sawan",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Ubon Ratchathani",
        "country": "Thailand",
        "province": "Ubon Ratchathani",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Surat Thani",
        "country": "Thailand",
        "province": "Surat Thani",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Chiang Mai",
        "country": "Thailand",
        "province": "Chiang Mai",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Nakhon Ratchasima",
        "country": "Thailand",
        "province": "Nakhon Ratchasima",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Bangkok",
        "country": "Thailand",
        "province": "Bangkok Metropolis",
        "timezone": "Asia/Bangkok"
    },
    {
        "name": "Freeport",
        "country": "The Bahamas",
        "province": "",
        "timezone": "America/Nassau"
    },
    {
        "name": "Nassau",
        "country": "The Bahamas",
        "province": "",
        "timezone": "America/Nassau"
    },
    {
        "name": "Georgetown",
        "country": "The Gambia",
        "province": "Maccarthy Island",
        "timezone": "Africa/Banjul"
    },
    {
        "name": "Basse Santa Su",
        "country": "The Gambia",
        "province": "Upper River",
        "timezone": "Africa/Banjul"
    },
    {
        "name": "Kerewan",
        "country": "The Gambia",
        "province": "Lower River",
        "timezone": "Africa/Banjul"
    },
    {
        "name": "Mansa Konko",
        "country": "The Gambia",
        "province": "Lower River",
        "timezone": "Africa/Banjul"
    },
    {
        "name": "Bansang",
        "country": "The Gambia",
        "province": "Maccarthy Island",
        "timezone": "Africa/Banjul"
    },
    {
        "name": "Brikama",
        "country": "The Gambia",
        "province": "Banjul",
        "timezone": "Africa/Banjul"
    },
    {
        "name": "Banjul",
        "country": "The Gambia",
        "province": "Banjul",
        "timezone": "Africa/Banjul"
    },
    {
        "name": "Bassar",
        "country": "Togo",
        "province": "Kara",
        "timezone": "Africa/Lome"
    },
    {
        "name": "Sotouboua",
        "country": "Togo",
        "province": "Centre",
        "timezone": "Africa/Lome"
    },
    {
        "name": "Kpalime",
        "country": "Togo",
        "province": "Plateaux",
        "timezone": "Africa/Lome"
    },
    {
        "name": "Sokode",
        "country": "Togo",
        "province": "Centre",
        "timezone": "Africa/Lome"
    },
    {
        "name": "Mango",
        "country": "Togo",
        "province": "Savanes",
        "timezone": "Africa/Lome"
    },
    {
        "name": "Atakpame",
        "country": "Togo",
        "province": "Plateaux",
        "timezone": "Africa/Lome"
    },
    {
        "name": "Lome",
        "country": "Togo",
        "province": "Maritime",
        "timezone": "Africa/Lome"
    },
    {
        "name": "Neiafu",
        "country": "Tonga",
        "province": "",
        "timezone": "Pacific/Tongatapu"
    },
    {
        "name": "Nukualofa",
        "country": "Tonga",
        "province": "",
        "timezone": "Pacific/Tongatapu"
    },
    {
        "name": "San Fernando",
        "country": "Trinidad and Tobago",
        "province": "San Fernando",
        "timezone": "America/Port_of_Spain"
    },
    {
        "name": "Port-of-Spain",
        "country": "Trinidad and Tobago",
        "province": "Port of Spain",
        "timezone": "America/Port_of_Spain"
    },
    {
        "name": "Medenine",
        "country": "Tunisia",
        "province": "Médenine",
        "timezone": "Africa/Tunis"
    },
    {
        "name": "Kebili",
        "country": "Tunisia",
        "province": "Kebili",
        "timezone": "Africa/Tunis"
    },
    {
        "name": "Tataouine",
        "country": "Tunisia",
        "province": "Tataouine",
        "timezone": "Africa/Tunis"
    },
    {
        "name": "L'Ariana",
        "country": "Tunisia",
        "province": "Manubah",
        "timezone": "Africa/Tunis"
    },
    {
        "name": "Jendouba",
        "country": "Tunisia",
        "province": "Jendouba",
        "timezone": "Africa/Tunis"
    },
    {
        "name": "Kasserine",
        "country": "Tunisia",
        "province": "Kassérine",
        "timezone": "Africa/Tunis"
    },
    {
        "name": "Sdid Bouzid",
        "country": "Tunisia",
        "province": "Sidi Bou Zid",
        "timezone": "Africa/Tunis"
    },
    {
        "name": "Siliana",
        "country": "Tunisia",
        "province": "Siliana",
        "timezone": "Africa/Tunis"
    },
    {
        "name": "Mahdia",
        "country": "Tunisia",
        "province": "Mahdia",
        "timezone": "Africa/Tunis"
    },
    {
        "name": "Monastir",
        "country": "Tunisia",
        "province": "Monastir",
        "timezone": "Africa/Tunis"
    },
    {
        "name": "Zaghouan",
        "country": "Tunisia",
        "province": "Zaghouan",
        "timezone": "Africa/Tunis"
    },
    {
        "name": "Ben Gardane",
        "country": "Tunisia",
        "province": "Médenine",
        "timezone": "Africa/Tunis"
    },
    {
        "name": "Zarzis",
        "country": "Tunisia",
        "province": "Médenine",
        "timezone": "Africa/Tunis"
    },
    {
        "name": "Dehibat",
        "country": "Tunisia",
        "province": "Tataouine",
        "timezone": "Africa/Tunis"
    },
    {
        "name": "Tozeur",
        "country": "Tunisia",
        "province": "Tozeur",
        "timezone": "Africa/Tunis"
    },
    {
        "name": "Beja",
        "country": "Tunisia",
        "province": "Béja",
        "timezone": "Africa/Tunis"
    },
    {
        "name": "Bizerte",
        "country": "Tunisia",
        "province": "Bizerte",
        "timezone": "Africa/Tunis"
    },
    {
        "name": "Nabeul",
        "country": "Tunisia",
        "province": "Nabeul",
        "timezone": "Africa/Tunis"
    },
    {
        "name": "El Kef",
        "country": "Tunisia",
        "province": "Le Kef",
        "timezone": "Africa/Tunis"
    },
    {
        "name": "Qasserine",
        "country": "Tunisia",
        "province": "Kassérine",
        "timezone": "Africa/Tunis"
    },
    {
        "name": "Gabes",
        "country": "Tunisia",
        "province": "Gabès",
        "timezone": "Africa/Tunis"
    },
    {
        "name": "Gafsa",
        "country": "Tunisia",
        "province": "Gafsa",
        "timezone": "Africa/Tunis"
    },
    {
        "name": "Qairouan",
        "country": "Tunisia",
        "province": "Kairouan",
        "timezone": "Africa/Tunis"
    },
    {
        "name": "Sfax",
        "country": "Tunisia",
        "province": "Sfax",
        "timezone": "Africa/Tunis"
    },
    {
        "name": "Sousse",
        "country": "Tunisia",
        "province": "Sousse",
        "timezone": "Africa/Tunis"
    },
    {
        "name": "Tunis",
        "country": "Tunisia",
        "province": "Tunis",
        "timezone": "Africa/Tunis"
    },
    {
        "name": "Kirklareli",
        "country": "Turkey",
        "province": "Kirklareli",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Bilecik",
        "country": "Turkey",
        "province": "Bilecik",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Sakarya",
        "country": "Turkey",
        "province": "Sakarya",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Kastamonu",
        "country": "Turkey",
        "province": "Kastamonu",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Burdur",
        "country": "Turkey",
        "province": "Burdur",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Kirsehir",
        "country": "Turkey",
        "province": "Kirsehir",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Nevsehir",
        "country": "Turkey",
        "province": "Nevsehir",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Antioch",
        "country": "Turkey",
        "province": "Hatay",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Giresun",
        "country": "Turkey",
        "province": "Giresun",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Sinop",
        "country": "Turkey",
        "province": "Sinop",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Tokat",
        "country": "Turkey",
        "province": "Tokat",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Artvin",
        "country": "Turkey",
        "province": "Artvin",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Bingol",
        "country": "Turkey",
        "province": "Bingöl",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Bitlis",
        "country": "Turkey",
        "province": "Bitlis",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Cankiri",
        "country": "Turkey",
        "province": "Çankiri",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Nigde",
        "country": "Turkey",
        "province": "Nigde",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Yozgat",
        "country": "Turkey",
        "province": "Yozgat",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Gumushane",
        "country": "Turkey",
        "province": "Gümüshane",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Siirt",
        "country": "Turkey",
        "province": "Siirt",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Tunceli",
        "country": "Turkey",
        "province": "Tunceli",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Aydin",
        "country": "Turkey",
        "province": "Aydin",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Luleburgaz",
        "country": "Turkey",
        "province": "Kirklareli",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Isparta",
        "country": "Turkey",
        "province": "Isparta",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Kutahya",
        "country": "Turkey",
        "province": "Kütahya",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Mugla",
        "country": "Turkey",
        "province": "Mugla",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Elazig",
        "country": "Turkey",
        "province": "Elazig",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Kahramanmaras",
        "country": "Turkey",
        "province": "K. Maras",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Icel",
        "country": "Turkey",
        "province": "Mersin",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Corum",
        "country": "Turkey",
        "province": "Çorum",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Rize",
        "country": "Turkey",
        "province": "Rize",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Tatvan",
        "country": "Turkey",
        "province": "Bitlis",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Polatli",
        "country": "Turkey",
        "province": "Ankara",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Karabuk",
        "country": "Turkey",
        "province": "Zinguldak",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Nusaybin",
        "country": "Turkey",
        "province": "Mardin",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Hakkari",
        "country": "Turkey",
        "province": "Hakkari",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Soke",
        "country": "Turkey",
        "province": "Aydin",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Balikesir",
        "country": "Turkey",
        "province": "Balikesir",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Canakkale",
        "country": "Turkey",
        "province": "Çanakkale",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Edirne",
        "country": "Turkey",
        "province": "Edirne",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Tekirdag",
        "country": "Turkey",
        "province": "Tekirdag",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Izmit",
        "country": "Turkey",
        "province": "Kocaeli",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Bolu",
        "country": "Turkey",
        "province": "Bolu",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Afyon",
        "country": "Turkey",
        "province": "Afyon",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Denizli",
        "country": "Turkey",
        "province": "Denizli",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Manisa",
        "country": "Turkey",
        "province": "Manisa",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Adiyaman",
        "country": "Turkey",
        "province": "Adiyaman",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Malatya",
        "country": "Turkey",
        "province": "Malatya",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Tarsus",
        "country": "Turkey",
        "province": "Mersin",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Samandagi",
        "country": "Turkey",
        "province": "Hatay",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Hatay",
        "country": "Turkey",
        "province": "Hatay",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Iskenderun",
        "country": "Turkey",
        "province": "Hatay",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Amasya",
        "country": "Turkey",
        "province": "Amasya",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Ordu",
        "country": "Turkey",
        "province": "Ordu",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Sivas",
        "country": "Turkey",
        "province": "Sivas",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Bafra",
        "country": "Turkey",
        "province": "Samsun",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Erzurum",
        "country": "Turkey",
        "province": "Erzurum",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Erzincan",
        "country": "Turkey",
        "province": "Erzincan",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Agri",
        "country": "Turkey",
        "province": "Agri",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Diyarbakir",
        "country": "Turkey",
        "province": "Diyarbakir",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Mus",
        "country": "Turkey",
        "province": "Mus",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Zonguldak",
        "country": "Turkey",
        "province": "Zinguldak",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Eregli",
        "country": "Turkey",
        "province": "Konya",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Karaman",
        "country": "Turkey",
        "province": "Karaman",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Usak",
        "country": "Turkey",
        "province": "Usak",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Kilis",
        "country": "Turkey",
        "province": "Gaziantep",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Kirikkale",
        "country": "Turkey",
        "province": "Kinkkale",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Kars",
        "country": "Turkey",
        "province": "Kars",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Mardin",
        "country": "Turkey",
        "province": "Mardin",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Batman",
        "country": "Turkey",
        "province": "Batman",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Van",
        "country": "Turkey",
        "province": "Van",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Adapazari",
        "country": "Turkey",
        "province": "Sakarya",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Trabzon",
        "country": "Turkey",
        "province": "Trabzon",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Sanliurfa",
        "country": "Turkey",
        "province": "Sanliurfa",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Eskisehir",
        "country": "Turkey",
        "province": "Eskisehir",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Antalya",
        "country": "Turkey",
        "province": "Antalya",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Kayseri",
        "country": "Turkey",
        "province": "Kayseri",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Gaziantep",
        "country": "Turkey",
        "province": "Gaziantep",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Izmir",
        "country": "Turkey",
        "province": "Izmir",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Bursa",
        "country": "Turkey",
        "province": "Bursa",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Samsun",
        "country": "Turkey",
        "province": "Samsun",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Konya",
        "country": "Turkey",
        "province": "Konya",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Adana",
        "country": "Turkey",
        "province": "Adana",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Ankara",
        "country": "Turkey",
        "province": "Ankara",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Istanbul",
        "country": "Turkey",
        "province": "Istanbul",
        "timezone": "Europe/Istanbul"
    },
    {
        "name": "Gyzlarbat",
        "country": "Turkmenistan",
        "province": "Balkan",
        "timezone": "Asia/Ashgabat"
    },
    {
        "name": "Celeken",
        "country": "Turkmenistan",
        "province": "Balkan",
        "timezone": "Asia/Ashgabat"
    },
    {
        "name": "Tejen",
        "country": "Turkmenistan",
        "province": "Ahal",
        "timezone": "Asia/Ashgabat"
    },
    {
        "name": "Buzmeyin",
        "country": "Turkmenistan",
        "province": "Ahal",
        "timezone": "Asia/Ashgabat"
    },
    {
        "name": "Koneurgench",
        "country": "Turkmenistan",
        "province": "Tashauz",
        "timezone": "Asia/Ashgabat"
    },
    {
        "name": "Balkanabat",
        "country": "Turkmenistan",
        "province": "Balkan",
        "timezone": "Asia/Ashgabat"
    },
    {
        "name": "Kaka",
        "country": "Turkmenistan",
        "province": "Ahal",
        "timezone": "Asia/Ashgabat"
    },
    {
        "name": "Atamyrat",
        "country": "Turkmenistan",
        "province": "Chardzhou",
        "timezone": "Asia/Ashgabat"
    },
    {
        "name": "Dasoguz",
        "country": "Turkmenistan",
        "province": "Tashauz",
        "timezone": "Asia/Ashgabat"
    },
    {
        "name": "Turkmenbasy",
        "country": "Turkmenistan",
        "province": "Balkan",
        "timezone": "Asia/Ashgabat"
    },
    {
        "name": "Turkmenabat",
        "country": "Turkmenistan",
        "province": "Chardzhou",
        "timezone": "Asia/Ashgabat"
    },
    {
        "name": "Mary",
        "country": "Turkmenistan",
        "province": "Mary",
        "timezone": "Asia/Ashgabat"
    },
    {
        "name": "Ashgabat",
        "country": "Turkmenistan",
        "province": "Ahal",
        "timezone": "Asia/Ashgabat"
    },
    {
        "name": "Grand Turk",
        "country": "Turks and Caicos Islands",
        "province": "",
        "timezone": "America/Grand_Turk"
    },
    {
        "name": "Funafuti",
        "country": "Tuvalu",
        "province": "",
        "timezone": "Pacific/Funafuti"
    },
    {
        "name": "Kalangala",
        "country": "Uganda",
        "province": "Kalangala",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Kumi",
        "country": "Uganda",
        "province": "Kumi",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Kaberamaido",
        "country": "Uganda",
        "province": "Kaberamaido",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Kayunga",
        "country": "Uganda",
        "province": "Kayunga",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Iganga",
        "country": "Uganda",
        "province": "Iganga",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Kamuli",
        "country": "Uganda",
        "province": "Kamuli",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Pallisa",
        "country": "Uganda",
        "province": "Pallisa",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Mpigi",
        "country": "Uganda",
        "province": "Mpigi",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Adjumani",
        "country": "Uganda",
        "province": "Adjumani",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Nebbi",
        "country": "Uganda",
        "province": "Nebbi",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Kiboga",
        "country": "Uganda",
        "province": "Kiboga",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Nakasongola",
        "country": "Uganda",
        "province": "Nakasongola",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Bombo",
        "country": "Uganda",
        "province": "Bamunanika",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Masindi",
        "country": "Uganda",
        "province": "Masindi",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Fort Portal",
        "country": "Uganda",
        "province": "Kabarole",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Kibale",
        "country": "Uganda",
        "province": "Kibale",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Sironko",
        "country": "Uganda",
        "province": "Budadiri",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Busia",
        "country": "Uganda",
        "province": "Busia",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Katakwi",
        "country": "Uganda",
        "province": "Usuk",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Ntungamo",
        "country": "Uganda",
        "province": "Ntungamo",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Kisoro",
        "country": "Uganda",
        "province": "Kisoro",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Jinja",
        "country": "Uganda",
        "province": "Jinja",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Soroti",
        "country": "Uganda",
        "province": "Soroti",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Arua",
        "country": "Uganda",
        "province": "Arua Municipality",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Pakwach",
        "country": "Uganda",
        "province": "Nebbi",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Moyo",
        "country": "Uganda",
        "province": "Moyo",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Entebbe",
        "country": "Uganda",
        "province": "Wakiso",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Mubende",
        "country": "Uganda",
        "province": "Mubende",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Mityana",
        "country": "Uganda",
        "province": "Busujju",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Kitgum",
        "country": "Uganda",
        "province": "Kitgum",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Lira",
        "country": "Uganda",
        "province": "Lira",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Masindi-Port",
        "country": "Uganda",
        "province": "Masindi",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Mbale",
        "country": "Uganda",
        "province": "Bungokho",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Tororo",
        "country": "Uganda",
        "province": "Tororo",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Kaabong",
        "country": "Uganda",
        "province": "Kaabong",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Moroto",
        "country": "Uganda",
        "province": "Moroto",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Masaka",
        "country": "Uganda",
        "province": "Masaka",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Katwe",
        "country": "Uganda",
        "province": "Kasese",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Mbarara",
        "country": "Uganda",
        "province": "Kashari",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Kabale",
        "country": "Uganda",
        "province": "Kabale",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Kasese",
        "country": "Uganda",
        "province": "Kasese",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Gulu",
        "country": "Uganda",
        "province": "Aswa",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Kampala",
        "country": "Uganda",
        "province": "Kampala",
        "timezone": "Africa/Kampala"
    },
    {
        "name": "Mykolayiv",
        "country": "Ukraine",
        "province": "Mykolayiv",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Chernihiv",
        "country": "Ukraine",
        "province": "Chernihiv",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Khmelnytskyy",
        "country": "Ukraine",
        "province": "Khmel'nyts'kyy",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Kamyanets-Podilskyy",
        "country": "Ukraine",
        "province": "Khmel'nyts'kyy",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Drohobych",
        "country": "Ukraine",
        "province": "L'viv",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Uzhgorod",
        "country": "Ukraine",
        "province": "Transcarpathia",
        "timezone": "Europe/Uzhgorod"
    },
    {
        "name": "Uman",
        "country": "Ukraine",
        "province": "Cherkasy",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Brovary",
        "country": "Ukraine",
        "province": "Kiev",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Bila Tserkva",
        "country": "Ukraine",
        "province": "Kiev",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Illichivsk",
        "country": "Ukraine",
        "province": "Odessa",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Konotop",
        "country": "Ukraine",
        "province": "Sumy",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Kryvyy Rih",
        "country": "Ukraine",
        "province": "Dnipropetrovs'k",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Makiyivka",
        "country": "Ukraine",
        "province": "Donets'k",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Horlivka",
        "country": "Ukraine",
        "province": "Donets'k",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Kramatorsk",
        "country": "Ukraine",
        "province": "Donets'k",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Berdyansk",
        "country": "Ukraine",
        "province": "Zaporizhzhya",
        "timezone": "Europe/Zaporozhye"
    },
    {
        "name": "Dzhankoy",
        "country": "Ukraine",
        "province": "Crimea",
        "timezone": "Europe/Simferopol"
    },
    {
        "name": "Yevpatoriya",
        "country": "Ukraine",
        "province": "Crimea",
        "timezone": "Europe/Simferopol"
    },
    {
        "name": "Kerch",
        "country": "Ukraine",
        "province": "Crimea",
        "timezone": "Europe/Simferopol"
    },
    {
        "name": "Simferopol",
        "country": "Ukraine",
        "province": "Crimea",
        "timezone": "Europe/Simferopol"
    },
    {
        "name": "Kherson",
        "country": "Ukraine",
        "province": "Crimea",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Voznesensk",
        "country": "Ukraine",
        "province": "Mykolayiv",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Nizhyn",
        "country": "Ukraine",
        "province": "Chernihiv",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Rivne",
        "country": "Ukraine",
        "province": "Rivne",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Chernivtsi",
        "country": "Ukraine",
        "province": "Chernivtsi",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Ivano-Frankivsk",
        "country": "Ukraine",
        "province": "Ivano-Frankivs'k",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Ternopil",
        "country": "Ukraine",
        "province": "Ternopil'",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Lutsk",
        "country": "Ukraine",
        "province": "Volyn",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Kovel",
        "country": "Ukraine",
        "province": "Volyn",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Cherkasy",
        "country": "Ukraine",
        "province": "Cherkasy",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Kirovohrad",
        "country": "Ukraine",
        "province": "Kirovohrad",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Izmayil",
        "country": "Ukraine",
        "province": "Odessa",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Vinnytsya",
        "country": "Ukraine",
        "province": "Vinnytsya",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Korosten",
        "country": "Ukraine",
        "province": "Zhytomyr",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Shostka",
        "country": "Ukraine",
        "province": "Sumy",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Nikopol",
        "country": "Ukraine",
        "province": "Dnipropetrovs'k",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Kupyansk",
        "country": "Ukraine",
        "province": "Kharkiv",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Lysychansk",
        "country": "Ukraine",
        "province": "Luhans'k",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Luhansk",
        "country": "Ukraine",
        "province": "Luhans'k",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Poltava",
        "country": "Ukraine",
        "province": "Poltava",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Kremenchuk",
        "country": "Ukraine",
        "province": "Poltava",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Melitopol",
        "country": "Ukraine",
        "province": "Zaporizhzhya",
        "timezone": "Europe/Zaporozhye"
    },
    {
        "name": "Zaporizhzhya",
        "country": "Ukraine",
        "province": "Zaporizhzhya",
        "timezone": "Europe/Zaporozhye"
    },
    {
        "name": "Yalta",
        "country": "Ukraine",
        "province": "Crimea",
        "timezone": "Europe/Simferopol"
    },
    {
        "name": "Chernobyl",
        "country": "Ukraine",
        "province": "Kiev",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Sumy",
        "country": "Ukraine",
        "province": "Sumy",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Mariupol",
        "country": "Ukraine",
        "province": "Donets'k",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Lvov",
        "country": "Ukraine",
        "province": "L'viv",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Odessa",
        "country": "Ukraine",
        "province": "Odessa",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Zhytomyr",
        "country": "Ukraine",
        "province": "Zhytomyr",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Dnipropetrovsk",
        "country": "Ukraine",
        "province": "Dnipropetrovs'k",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Donetsk",
        "country": "Ukraine",
        "province": "Donets'k",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Kharkiv",
        "country": "Ukraine",
        "province": "Kharkiv",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Sevastapol",
        "country": "Ukraine",
        "province": "Crimea",
        "timezone": "Europe/Simferopol"
    },
    {
        "name": "Kiev",
        "country": "Ukraine",
        "province": "Kiev",
        "timezone": "Europe/Kiev"
    },
    {
        "name": "Umm al Qaywayn",
        "country": "United Arab Emirates",
        "province": "Umm Al Qaywayn",
        "timezone": "Asia/Dubai"
    },
    {
        "name": "Sharjah",
        "country": "United Arab Emirates",
        "province": "Sharjah",
        "timezone": "Asia/Dubai"
    },
    {
        "name": "Jabal Ali",
        "country": "United Arab Emirates",
        "province": "Dubay",
        "timezone": "Asia/Dubai"
    },
    {
        "name": "Ras al Khaymah",
        "country": "United Arab Emirates",
        "province": "Ras Al Khaymah",
        "timezone": "Asia/Dubai"
    },
    {
        "name": "Al Fujayrah",
        "country": "United Arab Emirates",
        "province": "Fujayrah",
        "timezone": "Asia/Dubai"
    },
    {
        "name": "Al Ayn",
        "country": "United Arab Emirates",
        "province": "Abu Dhabi",
        "timezone": "Asia/Dubai"
    },
    {
        "name": "Abu Dhabi",
        "country": "United Arab Emirates",
        "province": "Abu Dhabi",
        "timezone": "Asia/Dubai"
    },
    {
        "name": "Dubai",
        "country": "United Arab Emirates",
        "province": "Dubay",
        "timezone": "Asia/Dubai"
    },
    {
        "name": "Greenock",
        "country": "United Kingdom",
        "province": "Inverclyde",
        "timezone": "Europe/London"
    },
    {
        "name": "Sunderland",
        "country": "United Kingdom",
        "province": "Tyne and Wear",
        "timezone": "Europe/London"
    },
    {
        "name": "Southampton",
        "country": "United Kingdom",
        "province": "Southampton",
        "timezone": "Europe/London"
    },
    {
        "name": "Bristol",
        "country": "United Kingdom",
        "province": "Bristol",
        "timezone": "Europe/London"
    },
    {
        "name": "Bournemouth",
        "country": "United Kingdom",
        "province": "Bournemouth",
        "timezone": "Europe/London"
    },
    {
        "name": "Omagh",
        "country": "United Kingdom",
        "province": "Omagh",
        "timezone": "Europe/London"
    },
    {
        "name": "Chester",
        "country": "United Kingdom",
        "province": "Cheshire",
        "timezone": "Europe/London"
    },
    {
        "name": "Swansea",
        "country": "United Kingdom",
        "province": "Swansea",
        "timezone": "Europe/London"
    },
    {
        "name": "Carlisle",
        "country": "United Kingdom",
        "province": "Cumbria",
        "timezone": "Europe/London"
    },
    {
        "name": "Southend-on-Sea",
        "country": "United Kingdom",
        "province": "Southend-on-Sea",
        "timezone": "Europe/London"
    },
    {
        "name": "Reading",
        "country": "United Kingdom",
        "province": "Oxfordshire",
        "timezone": "Europe/London"
    },
    {
        "name": "Leicester",
        "country": "United Kingdom",
        "province": "Leicester",
        "timezone": "Europe/London"
    },
    {
        "name": "Bradford",
        "country": "United Kingdom",
        "province": "West Yorkshire",
        "timezone": "Europe/London"
    },
    {
        "name": "Sheffield",
        "country": "United Kingdom",
        "province": "South Yorkshire",
        "timezone": "Europe/London"
    },
    {
        "name": "Fort William",
        "country": "United Kingdom",
        "province": "Highland",
        "timezone": "Europe/London"
    },
    {
        "name": "Ayr",
        "country": "United Kingdom",
        "province": "South Ayrshire",
        "timezone": "Europe/London"
    },
    {
        "name": "Aberdeen",
        "country": "United Kingdom",
        "province": "Aberdeen",
        "timezone": "Europe/London"
    },
    {
        "name": "Perth",
        "country": "United Kingdom",
        "province": "Perthshire and Kinross",
        "timezone": "Europe/London"
    },
    {
        "name": "Dundee",
        "country": "United Kingdom",
        "province": "Dundee",
        "timezone": "Europe/London"
    },
    {
        "name": "Middlesbrough",
        "country": "United Kingdom",
        "province": "Stockton-on-Tees",
        "timezone": "Europe/London"
    },
    {
        "name": "Coventry",
        "country": "United Kingdom",
        "province": "West Midlands",
        "timezone": "Europe/London"
    },
    {
        "name": "Bath",
        "country": "United Kingdom",
        "province": "Bath and North East Somerset",
        "timezone": "Europe/London"
    },
    {
        "name": "Exeter",
        "country": "United Kingdom",
        "province": "Devon",
        "timezone": "Europe/London"
    },
    {
        "name": "Cambridge",
        "country": "United Kingdom",
        "province": "Cambridgeshire",
        "timezone": "Europe/London"
    },
    {
        "name": "Kingston upon Hull",
        "country": "United Kingdom",
        "province": "Kingston upon Hull",
        "timezone": "Europe/London"
    },
    {
        "name": "Londonderry",
        "country": "United Kingdom",
        "province": "Derry",
        "timezone": "Europe/London"
    },
    {
        "name": "Lisburn",
        "country": "United Kingdom",
        "province": "Dungannon",
        "timezone": "Europe/London"
    },
    {
        "name": "Penzance",
        "country": "United Kingdom",
        "province": "Cornwall",
        "timezone": "Europe/London"
    },
    {
        "name": "York",
        "country": "United Kingdom",
        "province": "York",
        "timezone": "Europe/London"
    },
    {
        "name": "Blackpool",
        "country": "United Kingdom",
        "province": "Lancashire",
        "timezone": "Europe/London"
    },
    {
        "name": "Dumfries",
        "country": "United Kingdom",
        "province": "Dumfries and Galloway",
        "timezone": "Europe/London"
    },
    {
        "name": "Scarborough",
        "country": "United Kingdom",
        "province": "North Yorkshire",
        "timezone": "Europe/London"
    },
    {
        "name": "Plymouth",
        "country": "United Kingdom",
        "province": "Plymouth",
        "timezone": "Europe/London"
    },
    {
        "name": "Ipswich",
        "country": "United Kingdom",
        "province": "Suffolk",
        "timezone": "Europe/London"
    },
    {
        "name": "Norwich",
        "country": "United Kingdom",
        "province": "Norfolk",
        "timezone": "Europe/London"
    },
    {
        "name": "Brighton",
        "country": "United Kingdom",
        "province": "Brighton and Hove",
        "timezone": "Europe/London"
    },
    {
        "name": "Kirkwall",
        "country": "United Kingdom",
        "province": "Moray",
        "timezone": "Europe/London"
    },
    {
        "name": "Inverness",
        "country": "United Kingdom",
        "province": "Highland",
        "timezone": "Europe/London"
    },
    {
        "name": "Oxford",
        "country": "United Kingdom",
        "province": "Oxfordshire",
        "timezone": "Europe/London"
    },
    {
        "name": "Luton",
        "country": "United Kingdom",
        "province": "Luton",
        "timezone": "Europe/London"
    },
    {
        "name": "Portsmouth",
        "country": "United Kingdom",
        "province": "Portsmouth",
        "timezone": "Europe/London"
    },
    {
        "name": "Peterborough",
        "country": "United Kingdom",
        "province": "Peterborough",
        "timezone": "Europe/London"
    },
    {
        "name": "Nottingham",
        "country": "United Kingdom",
        "province": "Nottingham",
        "timezone": "Europe/London"
    },
    {
        "name": "Stoke",
        "country": "United Kingdom",
        "province": "Stoke-on-Trent",
        "timezone": "Europe/London"
    },
    {
        "name": "Dover",
        "country": "United Kingdom",
        "province": "Kent",
        "timezone": "Europe/London"
    },
    {
        "name": "Edinburgh",
        "country": "United Kingdom",
        "province": "Edinburgh",
        "timezone": "Europe/London"
    },
    {
        "name": "Newcastle",
        "country": "United Kingdom",
        "province": "Tyne and Wear",
        "timezone": "Europe/London"
    },
    {
        "name": "Liverpool",
        "country": "United Kingdom",
        "province": "Merseyside",
        "timezone": "Europe/London"
    },
    {
        "name": "Cardiff",
        "country": "United Kingdom",
        "province": "Cardiff",
        "timezone": "Europe/London"
    },
    {
        "name": "Wick",
        "country": "United Kingdom",
        "province": "Highland",
        "timezone": "Europe/London"
    },
    {
        "name": "Leeds",
        "country": "United Kingdom",
        "province": "West Yorkshire",
        "timezone": "Europe/London"
    },
    {
        "name": "Lerwick",
        "country": "United Kingdom",
        "province": "Aberdeen",
        "timezone": "Europe/London"
    },
    {
        "name": "Manchester",
        "country": "United Kingdom",
        "province": "Manchester",
        "timezone": "Europe/London"
    },
    {
        "name": "Birmingham",
        "country": "United Kingdom",
        "province": "West Midlands",
        "timezone": "Europe/London"
    },
    {
        "name": "Belfast",
        "country": "United Kingdom",
        "province": "Belfast",
        "timezone": "Europe/London"
    },
    {
        "name": "Glasgow",
        "country": "United Kingdom",
        "province": "Glasgow",
        "timezone": "Europe/London"
    },
    {
        "name": "London",
        "country": "United Kingdom",
        "province": "Westminster",
        "timezone": "Europe/London"
    },
    {
        "name": "Faribault",
        "country": "United States of America",
        "province": "Minnesota",
        "timezone": "America/Chicago"
    },
    {
        "name": "Mankato",
        "country": "United States of America",
        "province": "Minnesota",
        "timezone": "America/Chicago"
    },
    {
        "name": "Albert Lea",
        "country": "United States of America",
        "province": "Minnesota",
        "timezone": "America/Chicago"
    },
    {
        "name": "Willmar",
        "country": "United States of America",
        "province": "Minnesota",
        "timezone": "America/Chicago"
    },
    {
        "name": "Brainerd",
        "country": "United States of America",
        "province": "Minnesota",
        "timezone": "America/Chicago"
    },
    {
        "name": "Crookston",
        "country": "United States of America",
        "province": "Minnesota",
        "timezone": "America/Chicago"
    },
    {
        "name": "Hardin",
        "country": "United States of America",
        "province": "Montana",
        "timezone": "America/Denver"
    },
    {
        "name": "Glendive",
        "country": "United States of America",
        "province": "Montana",
        "timezone": "America/Denver"
    },
    {
        "name": "Dillon",
        "country": "United States of America",
        "province": "Montana",
        "timezone": "America/Denver"
    },
    {
        "name": "Polson",
        "country": "United States of America",
        "province": "Montana",
        "timezone": "America/Denver"
    },
    {
        "name": "Devils Lake",
        "country": "United States of America",
        "province": "North Dakota",
        "timezone": "America/Chicago"
    },
    {
        "name": "Burley",
        "country": "United States of America",
        "province": "Idaho",
        "timezone": "America/Boise"
    },
    {
        "name": "Wallace",
        "country": "United States of America",
        "province": "Idaho",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Kennewick",
        "country": "United States of America",
        "province": "Washington",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Centralia",
        "country": "United States of America",
        "province": "Washington",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Glendale",
        "country": "United States of America",
        "province": "Arizona",
        "timezone": "America/Phoenix"
    },
    {
        "name": "Safford",
        "country": "United States of America",
        "province": "Arizona",
        "timezone": "America/Phoenix"
    },
    {
        "name": "Casa Grande",
        "country": "United States of America",
        "province": "Arizona",
        "timezone": "America/Phoenix"
    },
    {
        "name": "Mesa",
        "country": "United States of America",
        "province": "Arizona",
        "timezone": "America/Phoenix"
    },
    {
        "name": "Lake Havasu City",
        "country": "United States of America",
        "province": "Arizona",
        "timezone": "America/Phoenix"
    },
    {
        "name": "Nogales",
        "country": "United States of America",
        "province": "Arizona",
        "timezone": "America/Phoenix"
    },
    {
        "name": "Berkeley",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "National City",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Mendocino",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Paso Robles",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Riverside",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Delano",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "San Mateo",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Vallejo",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Glenwood Springs",
        "country": "United States of America",
        "province": "Colorado",
        "timezone": "America/Denver"
    },
    {
        "name": "Aurora",
        "country": "United States of America",
        "province": "Colorado",
        "timezone": "America/Denver"
    },
    {
        "name": "Greeley",
        "country": "United States of America",
        "province": "Colorado",
        "timezone": "America/Denver"
    },
    {
        "name": "Tonopah",
        "country": "United States of America",
        "province": "Nevada",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Deming",
        "country": "United States of America",
        "province": "New Mexico",
        "timezone": "America/Denver"
    },
    {
        "name": "Truth or Consequences",
        "country": "United States of America",
        "province": "New Mexico",
        "timezone": "America/Denver"
    },
    {
        "name": "Las Vegas",
        "country": "United States of America",
        "province": "New Mexico",
        "timezone": "America/Denver"
    },
    {
        "name": "Farmington",
        "country": "United States of America",
        "province": "New Mexico",
        "timezone": "America/Denver"
    },
    {
        "name": "Springfield",
        "country": "United States of America",
        "province": "Oregon",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Tillamook",
        "country": "United States of America",
        "province": "Oregon",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Ontario",
        "country": "United States of America",
        "province": "Oregon",
        "timezone": "America/Boise"
    },
    {
        "name": "La Grande",
        "country": "United States of America",
        "province": "Oregon",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Richfield",
        "country": "United States of America",
        "province": "Utah",
        "timezone": "America/Denver"
    },
    {
        "name": "Nephi",
        "country": "United States of America",
        "province": "Utah",
        "timezone": "America/Denver"
    },
    {
        "name": "Lander",
        "country": "United States of America",
        "province": "Wyoming",
        "timezone": "America/Denver"
    },
    {
        "name": "Powell",
        "country": "United States of America",
        "province": "Wyoming",
        "timezone": "America/Denver"
    },
    {
        "name": "Paragould",
        "country": "United States of America",
        "province": "Arkansas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Iowa City",
        "country": "United States of America",
        "province": "Iowa",
        "timezone": "America/Chicago"
    },
    {
        "name": "Ottumwa",
        "country": "United States of America",
        "province": "Iowa",
        "timezone": "America/Chicago"
    },
    {
        "name": "Spencer",
        "country": "United States of America",
        "province": "Iowa",
        "timezone": "America/Chicago"
    },
    {
        "name": "Ft. Dodge",
        "country": "United States of America",
        "province": "Iowa",
        "timezone": "America/Chicago"
    },
    {
        "name": "Hutchinson",
        "country": "United States of America",
        "province": "Kansas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Kansas City",
        "country": "United States of America",
        "province": "Kansas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Lawrence",
        "country": "United States of America",
        "province": "Kansas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Garden City",
        "country": "United States of America",
        "province": "Kansas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Manhattan",
        "country": "United States of America",
        "province": "Kansas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Hays",
        "country": "United States of America",
        "province": "Kansas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Goodland",
        "country": "United States of America",
        "province": "Kansas",
        "timezone": "America/Denver"
    },
    {
        "name": "Independence",
        "country": "United States of America",
        "province": "Missouri",
        "timezone": "America/Chicago"
    },
    {
        "name": "Kirksville",
        "country": "United States of America",
        "province": "Missouri",
        "timezone": "America/Chicago"
    },
    {
        "name": "Kearney",
        "country": "United States of America",
        "province": "Nebraska",
        "timezone": "America/Chicago"
    },
    {
        "name": "Grand Island",
        "country": "United States of America",
        "province": "Nebraska",
        "timezone": "America/Chicago"
    },
    {
        "name": "Alliance",
        "country": "United States of America",
        "province": "Nebraska",
        "timezone": "America/Denver"
    },
    {
        "name": "Bartlesville",
        "country": "United States of America",
        "province": "Oklahoma",
        "timezone": "America/Chicago"
    },
    {
        "name": "Enid",
        "country": "United States of America",
        "province": "Oklahoma",
        "timezone": "America/Chicago"
    },
    {
        "name": "Ardmore",
        "country": "United States of America",
        "province": "Oklahoma",
        "timezone": "America/Chicago"
    },
    {
        "name": "McAlester",
        "country": "United States of America",
        "province": "Oklahoma",
        "timezone": "America/Chicago"
    },
    {
        "name": "Stillwater",
        "country": "United States of America",
        "province": "Oklahoma",
        "timezone": "America/Chicago"
    },
    {
        "name": "Lead",
        "country": "United States of America",
        "province": "South Dakota",
        "timezone": "America/Denver"
    },
    {
        "name": "Slidell",
        "country": "United States of America",
        "province": "Louisiana",
        "timezone": "America/Chicago"
    },
    {
        "name": "Lake Charles",
        "country": "United States of America",
        "province": "Louisiana",
        "timezone": "America/Chicago"
    },
    {
        "name": "Metairie",
        "country": "United States of America",
        "province": "Louisiana",
        "timezone": "America/Chicago"
    },
    {
        "name": "New Iberia",
        "country": "United States of America",
        "province": "Louisiana",
        "timezone": "America/Chicago"
    },
    {
        "name": "Bryan",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "San Marcos",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Longview",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "McAllen",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Harlingen",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Alice",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "New Braunfels",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Cleburne",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Brownwood",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Alpine",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Van Horn",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Big Spring",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Vernon",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Childress",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Hereford",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Dalhart",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Texas City",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Pasadena",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Baytown",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Arlington",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "New London",
        "country": "United States of America",
        "province": "Connecticut",
        "timezone": "America/New_York"
    },
    {
        "name": "Stamford",
        "country": "United States of America",
        "province": "Connecticut",
        "timezone": "America/New_York"
    },
    {
        "name": "Waterbury",
        "country": "United States of America",
        "province": "Connecticut",
        "timezone": "America/New_York"
    },
    {
        "name": "New Bedford",
        "country": "United States of America",
        "province": "Massachusetts",
        "timezone": "America/New_York"
    },
    {
        "name": "Springfield",
        "country": "United States of America",
        "province": "Massachusetts",
        "timezone": "America/New_York"
    },
    {
        "name": "Salem",
        "country": "United States of America",
        "province": "Massachusetts",
        "timezone": "America/New_York"
    },
    {
        "name": "Pittsfield",
        "country": "United States of America",
        "province": "Massachusetts",
        "timezone": "America/New_York"
    },
    {
        "name": "Montpelier",
        "country": "United States of America",
        "province": "Vermont",
        "timezone": "America/New_York"
    },
    {
        "name": "Auburn",
        "country": "United States of America",
        "province": "Alabama",
        "timezone": "America/Chicago"
    },
    {
        "name": "Florence",
        "country": "United States of America",
        "province": "Alabama",
        "timezone": "America/Chicago"
    },
    {
        "name": "Winter Haven",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/New_York"
    },
    {
        "name": "Melbourne",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/New_York"
    },
    {
        "name": "Homestead",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/New_York"
    },
    {
        "name": "Sanford",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/New_York"
    },
    {
        "name": "Miami Beach",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/New_York"
    },
    {
        "name": "Coral Springs",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/New_York"
    },
    {
        "name": "Port Charlotte",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/New_York"
    },
    {
        "name": "Spring Hill",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/New_York"
    },
    {
        "name": "Palm Coast",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/New_York"
    },
    {
        "name": "Palatka",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/New_York"
    },
    {
        "name": "Leesburg",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/New_York"
    },
    {
        "name": "Lake City",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/New_York"
    },
    {
        "name": "Crestview",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/Chicago"
    },
    {
        "name": "Panama City",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/Chicago"
    },
    {
        "name": "Dalton",
        "country": "United States of America",
        "province": "Georgia",
        "timezone": "America/New_York"
    },
    {
        "name": "Marietta",
        "country": "United States of America",
        "province": "Georgia",
        "timezone": "America/New_York"
    },
    {
        "name": "Waycross",
        "country": "United States of America",
        "province": "Georgia",
        "timezone": "America/New_York"
    },
    {
        "name": "La Grange",
        "country": "United States of America",
        "province": "Georgia",
        "timezone": "America/New_York"
    },
    {
        "name": "Southaven",
        "country": "United States of America",
        "province": "Mississippi",
        "timezone": "America/Chicago"
    },
    {
        "name": "Meridian",
        "country": "United States of America",
        "province": "Mississippi",
        "timezone": "America/Chicago"
    },
    {
        "name": "Laurel",
        "country": "United States of America",
        "province": "Mississippi",
        "timezone": "America/Chicago"
    },
    {
        "name": "Spartanburg",
        "country": "United States of America",
        "province": "South Carolina",
        "timezone": "America/New_York"
    },
    {
        "name": "Orangeburg",
        "country": "United States of America",
        "province": "South Carolina",
        "timezone": "America/New_York"
    },
    {
        "name": "Galesburg",
        "country": "United States of America",
        "province": "Illinois",
        "timezone": "America/Chicago"
    },
    {
        "name": "Joliet",
        "country": "United States of America",
        "province": "Illinois",
        "timezone": "America/Chicago"
    },
    {
        "name": "Cape Girardeau",
        "country": "United States of America",
        "province": "Illinois",
        "timezone": "America/Chicago"
    },
    {
        "name": "Rockford",
        "country": "United States of America",
        "province": "Illinois",
        "timezone": "America/Chicago"
    },
    {
        "name": "Evanston",
        "country": "United States of America",
        "province": "Illinois",
        "timezone": "America/Chicago"
    },
    {
        "name": "Rock Island",
        "country": "United States of America",
        "province": "Illinois",
        "timezone": "America/Chicago"
    },
    {
        "name": "Elgin",
        "country": "United States of America",
        "province": "Illinois",
        "timezone": "America/Chicago"
    },
    {
        "name": "Richmond",
        "country": "United States of America",
        "province": "Indiana",
        "timezone": "America/Indiana/Indianapolis"
    },
    {
        "name": "Terre Haute",
        "country": "United States of America",
        "province": "Indiana",
        "timezone": "America/Indiana/Indianapolis"
    },
    {
        "name": "Lafayette",
        "country": "United States of America",
        "province": "Indiana",
        "timezone": "America/Indiana/Indianapolis"
    },
    {
        "name": "Marion",
        "country": "United States of America",
        "province": "Indiana",
        "timezone": "America/Indiana/Indianapolis"
    },
    {
        "name": "South Bend",
        "country": "United States of America",
        "province": "Indiana",
        "timezone": "America/Indiana/Indianapolis"
    },
    {
        "name": "New Albany",
        "country": "United States of America",
        "province": "Indiana",
        "timezone": "America/Kentucky/Louisville"
    },
    {
        "name": "Elkhart",
        "country": "United States of America",
        "province": "Indiana",
        "timezone": "America/Indiana/Indianapolis"
    },
    {
        "name": "Hopkinsville",
        "country": "United States of America",
        "province": "Kentucky",
        "timezone": "America/Chicago"
    },
    {
        "name": "London",
        "country": "United States of America",
        "province": "Kentucky",
        "timezone": "America/New_York"
    },
    {
        "name": "Madisonville",
        "country": "United States of America",
        "province": "Kentucky",
        "timezone": "America/Chicago"
    },
    {
        "name": "Rocky Mount",
        "country": "United States of America",
        "province": "North Carolina",
        "timezone": "America/New_York"
    },
    {
        "name": "Salisbury",
        "country": "United States of America",
        "province": "North Carolina",
        "timezone": "America/New_York"
    },
    {
        "name": "Durham",
        "country": "United States of America",
        "province": "North Carolina",
        "timezone": "America/New_York"
    },
    {
        "name": "Lumberton",
        "country": "United States of America",
        "province": "North Carolina",
        "timezone": "America/New_York"
    },
    {
        "name": "Zanesville",
        "country": "United States of America",
        "province": "Ohio",
        "timezone": "America/New_York"
    },
    {
        "name": "Mansfield",
        "country": "United States of America",
        "province": "Ohio",
        "timezone": "America/New_York"
    },
    {
        "name": "Bowling Green",
        "country": "United States of America",
        "province": "Ohio",
        "timezone": "America/New_York"
    },
    {
        "name": "Springfield",
        "country": "United States of America",
        "province": "Ohio",
        "timezone": "America/New_York"
    },
    {
        "name": "Lancaster",
        "country": "United States of America",
        "province": "Ohio",
        "timezone": "America/New_York"
    },
    {
        "name": "Johnson City",
        "country": "United States of America",
        "province": "Tennessee",
        "timezone": "America/New_York"
    },
    {
        "name": "Kingsport",
        "country": "United States of America",
        "province": "Tennessee",
        "timezone": "America/New_York"
    },
    {
        "name": "Columbia",
        "country": "United States of America",
        "province": "Tennessee",
        "timezone": "America/Chicago"
    },
    {
        "name": "Barlett",
        "country": "United States of America",
        "province": "Tennessee",
        "timezone": "America/Chicago"
    },
    {
        "name": "Blacksburg",
        "country": "United States of America",
        "province": "Virginia",
        "timezone": "America/New_York"
    },
    {
        "name": "Harrisonburg",
        "country": "United States of America",
        "province": "Virginia",
        "timezone": "America/New_York"
    },
    {
        "name": "Petersburg",
        "country": "United States of America",
        "province": "Virginia",
        "timezone": "America/New_York"
    },
    {
        "name": "Hampton",
        "country": "United States of America",
        "province": "Virginia",
        "timezone": "America/New_York"
    },
    {
        "name": "Sheboygan",
        "country": "United States of America",
        "province": "Wisconsin",
        "timezone": "America/Chicago"
    },
    {
        "name": "Waukesha",
        "country": "United States of America",
        "province": "Wisconsin",
        "timezone": "America/Chicago"
    },
    {
        "name": "La Crosse",
        "country": "United States of America",
        "province": "Wisconsin",
        "timezone": "America/Chicago"
    },
    {
        "name": "Eau Claire",
        "country": "United States of America",
        "province": "Wisconsin",
        "timezone": "America/Chicago"
    },
    {
        "name": "Tomah",
        "country": "United States of America",
        "province": "Wisconsin",
        "timezone": "America/Chicago"
    },
    {
        "name": "Janesville",
        "country": "United States of America",
        "province": "Wisconsin",
        "timezone": "America/Chicago"
    },
    {
        "name": "Appleton",
        "country": "United States of America",
        "province": "Wisconsin",
        "timezone": "America/Chicago"
    },
    {
        "name": "Parkersburg",
        "country": "United States of America",
        "province": "West Virginia",
        "timezone": "America/New_York"
    },
    {
        "name": "White Sulphur Springs",
        "country": "United States of America",
        "province": "West Virginia",
        "timezone": "America/New_York"
    },
    {
        "name": "Clarksburg",
        "country": "United States of America",
        "province": "West Virginia",
        "timezone": "America/New_York"
    },
    {
        "name": "Dover",
        "country": "United States of America",
        "province": "Delaware",
        "timezone": "America/New_York"
    },
    {
        "name": "St. Charles",
        "country": "United States of America",
        "province": "Maryland",
        "timezone": "America/New_York"
    },
    {
        "name": "Annapolis",
        "country": "United States of America",
        "province": "Maryland",
        "timezone": "America/New_York"
    },
    {
        "name": "Hagerstown",
        "country": "United States of America",
        "province": "Maryland",
        "timezone": "America/New_York"
    },
    {
        "name": "Paterson",
        "country": "United States of America",
        "province": "New Jersey",
        "timezone": "America/New_York"
    },
    {
        "name": "Saratoga Springs",
        "country": "United States of America",
        "province": "New York",
        "timezone": "America/New_York"
    },
    {
        "name": "Poughkeepsie",
        "country": "United States of America",
        "province": "New York",
        "timezone": "America/New_York"
    },
    {
        "name": "Plattsburg",
        "country": "United States of America",
        "province": "New York",
        "timezone": "America/New_York"
    },
    {
        "name": "Beaver Falls",
        "country": "United States of America",
        "province": "Pennsylvania",
        "timezone": "America/New_York"
    },
    {
        "name": "Altoona",
        "country": "United States of America",
        "province": "Pennsylvania",
        "timezone": "America/New_York"
    },
    {
        "name": "Williamsport",
        "country": "United States of America",
        "province": "Pennsylvania",
        "timezone": "America/New_York"
    },
    {
        "name": "Lancaster",
        "country": "United States of America",
        "province": "Pennsylvania",
        "timezone": "America/New_York"
    },
    {
        "name": "Allentown",
        "country": "United States of America",
        "province": "Pennsylvania",
        "timezone": "America/New_York"
    },
    {
        "name": "Waterville",
        "country": "United States of America",
        "province": "Maine",
        "timezone": "America/New_York"
    },
    {
        "name": "Calais",
        "country": "United States of America",
        "province": "Maine",
        "timezone": "America/New_York"
    },
    {
        "name": "Houlton",
        "country": "United States of America",
        "province": "Maine",
        "timezone": "America/New_York"
    },
    {
        "name": "Benton Harbor",
        "country": "United States of America",
        "province": "Michigan",
        "timezone": "America/Detroit"
    },
    {
        "name": "Battle Creek",
        "country": "United States of America",
        "province": "Michigan",
        "timezone": "America/Detroit"
    },
    {
        "name": "Bay City",
        "country": "United States of America",
        "province": "Michigan",
        "timezone": "America/Detroit"
    },
    {
        "name": "Alpena",
        "country": "United States of America",
        "province": "Michigan",
        "timezone": "America/Detroit"
    },
    {
        "name": "Iron Mountain",
        "country": "United States of America",
        "province": "Michigan",
        "timezone": "America/Menominee"
    },
    {
        "name": "Ironwood",
        "country": "United States of America",
        "province": "Michigan",
        "timezone": "America/Menominee"
    },
    {
        "name": "Sand Point",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Hydaburg",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Sitka"
    },
    {
        "name": "Mekoryuk",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Nome"
    },
    {
        "name": "Atqasuk",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Port Heiden",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Perryville",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Dillingham",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Goodnews Bay",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Nyac",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Tununak",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Nome"
    },
    {
        "name": "Mountain Village",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Nome"
    },
    {
        "name": "Emmonak",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Nome"
    },
    {
        "name": "Kaltag",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Teller",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Nome"
    },
    {
        "name": "Koyukuk",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Kobuk",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Selawik",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Talkeetna",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Whittier",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Montana",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Lake Minchumina",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Cantwell",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Gulkana",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Eagle",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Nenana",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Big Delta",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Allakaket",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Tanana",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Virginia",
        "country": "United States of America",
        "province": "Minnesota",
        "timezone": "America/Chicago"
    },
    {
        "name": "Winona",
        "country": "United States of America",
        "province": "Minnesota",
        "timezone": "America/Chicago"
    },
    {
        "name": "Rochester",
        "country": "United States of America",
        "province": "Minnesota",
        "timezone": "America/Chicago"
    },
    {
        "name": "Lakeville",
        "country": "United States of America",
        "province": "Minnesota",
        "timezone": "America/Chicago"
    },
    {
        "name": "Ely",
        "country": "United States of America",
        "province": "Minnesota",
        "timezone": "America/Chicago"
    },
    {
        "name": "Moorhead",
        "country": "United States of America",
        "province": "Minnesota",
        "timezone": "America/Chicago"
    },
    {
        "name": "St. Cloud",
        "country": "United States of America",
        "province": "Minnesota",
        "timezone": "America/Chicago"
    },
    {
        "name": "Miles City",
        "country": "United States of America",
        "province": "Montana",
        "timezone": "America/Denver"
    },
    {
        "name": "Bozeman",
        "country": "United States of America",
        "province": "Montana",
        "timezone": "America/Denver"
    },
    {
        "name": "Glasgow",
        "country": "United States of America",
        "province": "Montana",
        "timezone": "America/Denver"
    },
    {
        "name": "Dickinson",
        "country": "United States of America",
        "province": "North Dakota",
        "timezone": "America/Denver"
    },
    {
        "name": "Jamestown",
        "country": "United States of America",
        "province": "North Dakota",
        "timezone": "America/Chicago"
    },
    {
        "name": "Williston",
        "country": "United States of America",
        "province": "North Dakota",
        "timezone": "America/Chicago"
    },
    {
        "name": "Lihue",
        "country": "United States of America",
        "province": "Hawaii",
        "timezone": "Pacific/Honolulu"
    },
    {
        "name": "Wahiawa",
        "country": "United States of America",
        "province": "Hawaii",
        "timezone": "Pacific/Honolulu"
    },
    {
        "name": "Wailuku",
        "country": "United States of America",
        "province": "Hawaii",
        "timezone": "Pacific/Honolulu"
    },
    {
        "name": "Montpelier",
        "country": "United States of America",
        "province": "Idaho",
        "timezone": "America/Boise"
    },
    {
        "name": "Twin Falls",
        "country": "United States of America",
        "province": "Idaho",
        "timezone": "America/Boise"
    },
    {
        "name": "Caldwell",
        "country": "United States of America",
        "province": "Idaho",
        "timezone": "America/Boise"
    },
    {
        "name": "Salmon",
        "country": "United States of America",
        "province": "Idaho",
        "timezone": "America/Boise"
    },
    {
        "name": "Coeur d'Alene",
        "country": "United States of America",
        "province": "Idaho",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Richland",
        "country": "United States of America",
        "province": "Washington",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Bellingham",
        "country": "United States of America",
        "province": "Washington",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Longview",
        "country": "United States of America",
        "province": "Washington",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Walla Walla",
        "country": "United States of America",
        "province": "Washington",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Aberdeen",
        "country": "United States of America",
        "province": "Washington",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Bremerton",
        "country": "United States of America",
        "province": "Washington",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Everett",
        "country": "United States of America",
        "province": "Washington",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Bullhead City",
        "country": "United States of America",
        "province": "Arizona",
        "timezone": "America/Phoenix"
    },
    {
        "name": "Winslow",
        "country": "United States of America",
        "province": "Arizona",
        "timezone": "America/Denver"
    },
    {
        "name": "Gila Bend",
        "country": "United States of America",
        "province": "Arizona",
        "timezone": "America/Phoenix"
    },
    {
        "name": "Tombstone",
        "country": "United States of America",
        "province": "Arizona",
        "timezone": "America/Phoenix"
    },
    {
        "name": "Willcox",
        "country": "United States of America",
        "province": "Arizona",
        "timezone": "America/Phoenix"
    },
    {
        "name": "Scottsdale",
        "country": "United States of America",
        "province": "Arizona",
        "timezone": "America/Phoenix"
    },
    {
        "name": "Kingman",
        "country": "United States of America",
        "province": "Arizona",
        "timezone": "America/Phoenix"
    },
    {
        "name": "Grand Canyon",
        "country": "United States of America",
        "province": "Arizona",
        "timezone": "America/Phoenix"
    },
    {
        "name": "Arcata",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Stockton",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Barstow",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Victorville",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Pasadena",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Visalia",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "El Centro",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "San Luis Obispo",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Merced",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Yuba City",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Redding",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Santa Rosa",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Oceanside",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Modesto",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Irvine",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Ukiah",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Needles",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Bishop",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Palm Springs",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Santa Maria",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Tulare",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Mt. Shasta",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Crescent City",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Fort Collins",
        "country": "United States of America",
        "province": "Colorado",
        "timezone": "America/Denver"
    },
    {
        "name": "Pueblo",
        "country": "United States of America",
        "province": "Colorado",
        "timezone": "America/Denver"
    },
    {
        "name": "Lamar",
        "country": "United States of America",
        "province": "Colorado",
        "timezone": "America/Denver"
    },
    {
        "name": "Trinidad",
        "country": "United States of America",
        "province": "Colorado",
        "timezone": "America/Denver"
    },
    {
        "name": "Gunnison",
        "country": "United States of America",
        "province": "Colorado",
        "timezone": "America/Denver"
    },
    {
        "name": "Durango",
        "country": "United States of America",
        "province": "Colorado",
        "timezone": "America/Denver"
    },
    {
        "name": "Montrose",
        "country": "United States of America",
        "province": "Colorado",
        "timezone": "America/Denver"
    },
    {
        "name": "Craig",
        "country": "United States of America",
        "province": "Colorado",
        "timezone": "America/Denver"
    },
    {
        "name": "Boulder",
        "country": "United States of America",
        "province": "Colorado",
        "timezone": "America/Denver"
    },
    {
        "name": "Boulder City",
        "country": "United States of America",
        "province": "Nevada",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Winnemucca",
        "country": "United States of America",
        "province": "Nevada",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Roswell",
        "country": "United States of America",
        "province": "New Mexico",
        "timezone": "America/Denver"
    },
    {
        "name": "Clovis",
        "country": "United States of America",
        "province": "New Mexico",
        "timezone": "America/Denver"
    },
    {
        "name": "Las Cruces",
        "country": "United States of America",
        "province": "New Mexico",
        "timezone": "America/Denver"
    },
    {
        "name": "Hobbs",
        "country": "United States of America",
        "province": "New Mexico",
        "timezone": "America/Denver"
    },
    {
        "name": "Socorro",
        "country": "United States of America",
        "province": "New Mexico",
        "timezone": "America/Denver"
    },
    {
        "name": "Gallup",
        "country": "United States of America",
        "province": "New Mexico",
        "timezone": "America/Denver"
    },
    {
        "name": "Raton",
        "country": "United States of America",
        "province": "New Mexico",
        "timezone": "America/Denver"
    },
    {
        "name": "Tucumcari",
        "country": "United States of America",
        "province": "New Mexico",
        "timezone": "America/Denver"
    },
    {
        "name": "Roseburg",
        "country": "United States of America",
        "province": "Oregon",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Pendleton",
        "country": "United States of America",
        "province": "Oregon",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "John Day",
        "country": "United States of America",
        "province": "Oregon",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Grants Pass",
        "country": "United States of America",
        "province": "Oregon",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Corvallis",
        "country": "United States of America",
        "province": "Oregon",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Albany",
        "country": "United States of America",
        "province": "Oregon",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Astoria",
        "country": "United States of America",
        "province": "Oregon",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Logan",
        "country": "United States of America",
        "province": "Utah",
        "timezone": "America/Denver"
    },
    {
        "name": "Parowan",
        "country": "United States of America",
        "province": "Utah",
        "timezone": "America/Denver"
    },
    {
        "name": "Kanab",
        "country": "United States of America",
        "province": "Utah",
        "timezone": "America/Denver"
    },
    {
        "name": "Monticello",
        "country": "United States of America",
        "province": "Utah",
        "timezone": "America/Denver"
    },
    {
        "name": "Moab",
        "country": "United States of America",
        "province": "Utah",
        "timezone": "America/Denver"
    },
    {
        "name": "Price",
        "country": "United States of America",
        "province": "Utah",
        "timezone": "America/Denver"
    },
    {
        "name": "Cedar City",
        "country": "United States of America",
        "province": "Utah",
        "timezone": "America/Denver"
    },
    {
        "name": "Vernal",
        "country": "United States of America",
        "province": "Utah",
        "timezone": "America/Denver"
    },
    {
        "name": "Ogden",
        "country": "United States of America",
        "province": "Utah",
        "timezone": "America/Denver"
    },
    {
        "name": "Green River",
        "country": "United States of America",
        "province": "Wyoming",
        "timezone": "America/Denver"
    },
    {
        "name": "Rawlins",
        "country": "United States of America",
        "province": "Wyoming",
        "timezone": "America/Denver"
    },
    {
        "name": "Douglas",
        "country": "United States of America",
        "province": "Wyoming",
        "timezone": "America/Denver"
    },
    {
        "name": "Riverton",
        "country": "United States of America",
        "province": "Wyoming",
        "timezone": "America/Denver"
    },
    {
        "name": "Thermopolis",
        "country": "United States of America",
        "province": "Wyoming",
        "timezone": "America/Denver"
    },
    {
        "name": "Gillette",
        "country": "United States of America",
        "province": "Wyoming",
        "timezone": "America/Denver"
    },
    {
        "name": "Jonesboro",
        "country": "United States of America",
        "province": "Arkansas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Texarkana",
        "country": "United States of America",
        "province": "Arkansas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Pine Bluff",
        "country": "United States of America",
        "province": "Arkansas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Hot Springs",
        "country": "United States of America",
        "province": "Arkansas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Fort Smith",
        "country": "United States of America",
        "province": "Arkansas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Fayetteville",
        "country": "United States of America",
        "province": "Arkansas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Conway",
        "country": "United States of America",
        "province": "Arkansas",
        "timezone": "America/Chicago"
    },
    {
        "name": "El Dorado",
        "country": "United States of America",
        "province": "Arkansas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Davenport",
        "country": "United States of America",
        "province": "Iowa",
        "timezone": "America/Chicago"
    },
    {
        "name": "Burlington",
        "country": "United States of America",
        "province": "Iowa",
        "timezone": "America/Chicago"
    },
    {
        "name": "Dubuque",
        "country": "United States of America",
        "province": "Iowa",
        "timezone": "America/Chicago"
    },
    {
        "name": "Waterloo",
        "country": "United States of America",
        "province": "Iowa",
        "timezone": "America/Chicago"
    },
    {
        "name": "Sioux City",
        "country": "United States of America",
        "province": "Iowa",
        "timezone": "America/Chicago"
    },
    {
        "name": "Council Bluffs",
        "country": "United States of America",
        "province": "Iowa",
        "timezone": "America/Chicago"
    },
    {
        "name": "Ames",
        "country": "United States of America",
        "province": "Iowa",
        "timezone": "America/Chicago"
    },
    {
        "name": "Mason City",
        "country": "United States of America",
        "province": "Iowa",
        "timezone": "America/Chicago"
    },
    {
        "name": "Emporia",
        "country": "United States of America",
        "province": "Kansas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Salina",
        "country": "United States of America",
        "province": "Kansas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Dodge City",
        "country": "United States of America",
        "province": "Kansas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Coffeyville",
        "country": "United States of America",
        "province": "Kansas",
        "timezone": "America/Chicago"
    },
    {
        "name": "St. Charles",
        "country": "United States of America",
        "province": "Missouri",
        "timezone": "America/Chicago"
    },
    {
        "name": "Poplar Bluff",
        "country": "United States of America",
        "province": "Missouri",
        "timezone": "America/Chicago"
    },
    {
        "name": "Joplin",
        "country": "United States of America",
        "province": "Missouri",
        "timezone": "America/Chicago"
    },
    {
        "name": "Columbia",
        "country": "United States of America",
        "province": "Missouri",
        "timezone": "America/Chicago"
    },
    {
        "name": "St. Joseph",
        "country": "United States of America",
        "province": "Missouri",
        "timezone": "America/Chicago"
    },
    {
        "name": "McCook",
        "country": "United States of America",
        "province": "Nebraska",
        "timezone": "America/Chicago"
    },
    {
        "name": "Norfolk",
        "country": "United States of America",
        "province": "Nebraska",
        "timezone": "America/Chicago"
    },
    {
        "name": "North Platte",
        "country": "United States of America",
        "province": "Nebraska",
        "timezone": "America/Chicago"
    },
    {
        "name": "Sidney",
        "country": "United States of America",
        "province": "Nebraska",
        "timezone": "America/Denver"
    },
    {
        "name": "Scottsbluff",
        "country": "United States of America",
        "province": "Nebraska",
        "timezone": "America/Denver"
    },
    {
        "name": "Chadron",
        "country": "United States of America",
        "province": "Nebraska",
        "timezone": "America/Denver"
    },
    {
        "name": "Lawton",
        "country": "United States of America",
        "province": "Oklahoma",
        "timezone": "America/Chicago"
    },
    {
        "name": "Norman",
        "country": "United States of America",
        "province": "Oklahoma",
        "timezone": "America/Chicago"
    },
    {
        "name": "Muskogee",
        "country": "United States of America",
        "province": "Oklahoma",
        "timezone": "America/Chicago"
    },
    {
        "name": "Ponca City",
        "country": "United States of America",
        "province": "Oklahoma",
        "timezone": "America/Chicago"
    },
    {
        "name": "Shawnee",
        "country": "United States of America",
        "province": "Oklahoma",
        "timezone": "America/Chicago"
    },
    {
        "name": "Woodward",
        "country": "United States of America",
        "province": "Oklahoma",
        "timezone": "America/Chicago"
    },
    {
        "name": "Guymon",
        "country": "United States of America",
        "province": "Oklahoma",
        "timezone": "America/Chicago"
    },
    {
        "name": "Yankton",
        "country": "United States of America",
        "province": "South Dakota",
        "timezone": "America/Chicago"
    },
    {
        "name": "Brookings",
        "country": "United States of America",
        "province": "South Dakota",
        "timezone": "America/Chicago"
    },
    {
        "name": "Mitchell",
        "country": "United States of America",
        "province": "South Dakota",
        "timezone": "America/Chicago"
    },
    {
        "name": "Aberdeen",
        "country": "United States of America",
        "province": "South Dakota",
        "timezone": "America/Chicago"
    },
    {
        "name": "Mobridge",
        "country": "United States of America",
        "province": "South Dakota",
        "timezone": "America/Chicago"
    },
    {
        "name": "Houma",
        "country": "United States of America",
        "province": "Louisiana",
        "timezone": "America/Chicago"
    },
    {
        "name": "Monroe",
        "country": "United States of America",
        "province": "Louisiana",
        "timezone": "America/Chicago"
    },
    {
        "name": "Conroe",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Nacogdoches",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Eagle Pass",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Edinburg",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Kingsville",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Port Arthur",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Huntsville",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Killeen",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Lufkin",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Del Rio",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "San Angelo",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Sherman",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Beaumont",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Bay City",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Port Lavaca",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Falfurrias",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Beeville",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Fort Stockton",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Pecos",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Dumas",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Denton",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Midland",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Temple",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "New Haven",
        "country": "United States of America",
        "province": "Connecticut",
        "timezone": "America/New_York"
    },
    {
        "name": "Lowell",
        "country": "United States of America",
        "province": "Massachusetts",
        "timezone": "America/New_York"
    },
    {
        "name": "Worcester",
        "country": "United States of America",
        "province": "Massachusetts",
        "timezone": "America/New_York"
    },
    {
        "name": "Manchester",
        "country": "United States of America",
        "province": "New Hampshire",
        "timezone": "America/New_York"
    },
    {
        "name": "Newport",
        "country": "United States of America",
        "province": "Rhode Island",
        "timezone": "America/New_York"
    },
    {
        "name": "Dothan",
        "country": "United States of America",
        "province": "Alabama",
        "timezone": "America/Chicago"
    },
    {
        "name": "Tuscaloosa",
        "country": "United States of America",
        "province": "Alabama",
        "timezone": "America/Chicago"
    },
    {
        "name": "Gadsden",
        "country": "United States of America",
        "province": "Alabama",
        "timezone": "America/Chicago"
    },
    {
        "name": "Enterprise",
        "country": "United States of America",
        "province": "Alabama",
        "timezone": "America/Chicago"
    },
    {
        "name": "Selma",
        "country": "United States of America",
        "province": "Alabama",
        "timezone": "America/Chicago"
    },
    {
        "name": "Coral Gables",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/New_York"
    },
    {
        "name": "Cape Coral",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/New_York"
    },
    {
        "name": "Naples",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/New_York"
    },
    {
        "name": "Fort Pierce",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/New_York"
    },
    {
        "name": "Kissimmee",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/New_York"
    },
    {
        "name": "Titusville",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/New_York"
    },
    {
        "name": "St. Augustine",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/New_York"
    },
    {
        "name": "Ocala",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/New_York"
    },
    {
        "name": "Fort Lauderdale",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/New_York"
    },
    {
        "name": "Apalachicola",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/New_York"
    },
    {
        "name": "Vero Beach",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/New_York"
    },
    {
        "name": "Valdosta",
        "country": "United States of America",
        "province": "Georgia",
        "timezone": "America/New_York"
    },
    {
        "name": "Albany",
        "country": "United States of America",
        "province": "Georgia",
        "timezone": "America/New_York"
    },
    {
        "name": "Athens",
        "country": "United States of America",
        "province": "Georgia",
        "timezone": "America/New_York"
    },
    {
        "name": "Macon",
        "country": "United States of America",
        "province": "Georgia",
        "timezone": "America/New_York"
    },
    {
        "name": "Columbus",
        "country": "United States of America",
        "province": "Georgia",
        "timezone": "America/New_York"
    },
    {
        "name": "Douglas",
        "country": "United States of America",
        "province": "Georgia",
        "timezone": "America/New_York"
    },
    {
        "name": "Dublin",
        "country": "United States of America",
        "province": "Georgia",
        "timezone": "America/New_York"
    },
    {
        "name": "Gulfport",
        "country": "United States of America",
        "province": "Mississippi",
        "timezone": "America/Chicago"
    },
    {
        "name": "Hattiesburg",
        "country": "United States of America",
        "province": "Mississippi",
        "timezone": "America/Chicago"
    },
    {
        "name": "Tupelo",
        "country": "United States of America",
        "province": "Mississippi",
        "timezone": "America/Chicago"
    },
    {
        "name": "Greenville",
        "country": "United States of America",
        "province": "Mississippi",
        "timezone": "America/Chicago"
    },
    {
        "name": "Natchez",
        "country": "United States of America",
        "province": "Mississippi",
        "timezone": "America/Chicago"
    },
    {
        "name": "Florence",
        "country": "United States of America",
        "province": "South Carolina",
        "timezone": "America/New_York"
    },
    {
        "name": "Greenville",
        "country": "United States of America",
        "province": "South Carolina",
        "timezone": "America/New_York"
    },
    {
        "name": "Sumter",
        "country": "United States of America",
        "province": "South Carolina",
        "timezone": "America/New_York"
    },
    {
        "name": "Anderson",
        "country": "United States of America",
        "province": "South Carolina",
        "timezone": "America/New_York"
    },
    {
        "name": "Aiken",
        "country": "United States of America",
        "province": "South Carolina",
        "timezone": "America/New_York"
    },
    {
        "name": "Beaufort",
        "country": "United States of America",
        "province": "South Carolina",
        "timezone": "America/New_York"
    },
    {
        "name": "Rock Hill",
        "country": "United States of America",
        "province": "South Carolina",
        "timezone": "America/New_York"
    },
    {
        "name": "Decatur",
        "country": "United States of America",
        "province": "Illinois",
        "timezone": "America/Chicago"
    },
    {
        "name": "Alton",
        "country": "United States of America",
        "province": "Illinois",
        "timezone": "America/Chicago"
    },
    {
        "name": "Quincy",
        "country": "United States of America",
        "province": "Illinois",
        "timezone": "America/Chicago"
    },
    {
        "name": "Urbana",
        "country": "United States of America",
        "province": "Illinois",
        "timezone": "America/Chicago"
    },
    {
        "name": "Bloomington",
        "country": "United States of America",
        "province": "Illinois",
        "timezone": "America/Chicago"
    },
    {
        "name": "Kankakee",
        "country": "United States of America",
        "province": "Illinois",
        "timezone": "America/Chicago"
    },
    {
        "name": "Waukegan",
        "country": "United States of America",
        "province": "Illinois",
        "timezone": "America/Chicago"
    },
    {
        "name": "Aurora",
        "country": "United States of America",
        "province": "Illinois",
        "timezone": "America/Chicago"
    },
    {
        "name": "Carbondale",
        "country": "United States of America",
        "province": "Illinois",
        "timezone": "America/Chicago"
    },
    {
        "name": "Belleville",
        "country": "United States of America",
        "province": "Illinois",
        "timezone": "America/Chicago"
    },
    {
        "name": "Bloomington",
        "country": "United States of America",
        "province": "Indiana",
        "timezone": "America/Indiana/Indianapolis"
    },
    {
        "name": "Muncie",
        "country": "United States of America",
        "province": "Indiana",
        "timezone": "America/Indiana/Indianapolis"
    },
    {
        "name": "Kokomo",
        "country": "United States of America",
        "province": "Indiana",
        "timezone": "America/Indiana/Indianapolis"
    },
    {
        "name": "Gary",
        "country": "United States of America",
        "province": "Indiana",
        "timezone": "America/Chicago"
    },
    {
        "name": "Fort Wayne",
        "country": "United States of America",
        "province": "Indiana",
        "timezone": "America/Indiana/Indianapolis"
    },
    {
        "name": "Covington",
        "country": "United States of America",
        "province": "Kentucky",
        "timezone": "America/New_York"
    },
    {
        "name": "Bowling Green",
        "country": "United States of America",
        "province": "Kentucky",
        "timezone": "America/Chicago"
    },
    {
        "name": "Paducah",
        "country": "United States of America",
        "province": "Kentucky",
        "timezone": "America/Chicago"
    },
    {
        "name": "Owensboro",
        "country": "United States of America",
        "province": "Kentucky",
        "timezone": "America/Chicago"
    },
    {
        "name": "Jacksonville",
        "country": "United States of America",
        "province": "North Carolina",
        "timezone": "America/New_York"
    },
    {
        "name": "Goldsboro",
        "country": "United States of America",
        "province": "North Carolina",
        "timezone": "America/New_York"
    },
    {
        "name": "Greenville",
        "country": "United States of America",
        "province": "North Carolina",
        "timezone": "America/New_York"
    },
    {
        "name": "Fayetteville",
        "country": "United States of America",
        "province": "North Carolina",
        "timezone": "America/New_York"
    },
    {
        "name": "Hickory",
        "country": "United States of America",
        "province": "North Carolina",
        "timezone": "America/New_York"
    },
    {
        "name": "Asheville",
        "country": "United States of America",
        "province": "North Carolina",
        "timezone": "America/New_York"
    },
    {
        "name": "Winston-Salem",
        "country": "United States of America",
        "province": "North Carolina",
        "timezone": "America/New_York"
    },
    {
        "name": "Kitty Hawk",
        "country": "United States of America",
        "province": "North Carolina",
        "timezone": "America/New_York"
    },
    {
        "name": "Akron",
        "country": "United States of America",
        "province": "Ohio",
        "timezone": "America/New_York"
    },
    {
        "name": "Lima",
        "country": "United States of America",
        "province": "Ohio",
        "timezone": "America/New_York"
    },
    {
        "name": "Oak Ridge",
        "country": "United States of America",
        "province": "Tennessee",
        "timezone": "America/New_York"
    },
    {
        "name": "Murfreesboro",
        "country": "United States of America",
        "province": "Tennessee",
        "timezone": "America/Chicago"
    },
    {
        "name": "Clarksville",
        "country": "United States of America",
        "province": "Tennessee",
        "timezone": "America/Chicago"
    },
    {
        "name": "Jackson",
        "country": "United States of America",
        "province": "Tennessee",
        "timezone": "America/Chicago"
    },
    {
        "name": "Alexandria",
        "country": "United States of America",
        "province": "Virginia",
        "timezone": "America/New_York"
    },
    {
        "name": "Fredericksburg",
        "country": "United States of America",
        "province": "Virginia",
        "timezone": "America/New_York"
    },
    {
        "name": "Roanoke",
        "country": "United States of America",
        "province": "Virginia",
        "timezone": "America/New_York"
    },
    {
        "name": "Danville",
        "country": "United States of America",
        "province": "Virginia",
        "timezone": "America/New_York"
    },
    {
        "name": "Winchester",
        "country": "United States of America",
        "province": "Virginia",
        "timezone": "America/New_York"
    },
    {
        "name": "Bristol",
        "country": "United States of America",
        "province": "Virginia",
        "timezone": "America/New_York"
    },
    {
        "name": "Superior",
        "country": "United States of America",
        "province": "Wisconsin",
        "timezone": "America/Chicago"
    },
    {
        "name": "West Bend",
        "country": "United States of America",
        "province": "Wisconsin",
        "timezone": "America/Chicago"
    },
    {
        "name": "Fond du Lac",
        "country": "United States of America",
        "province": "Wisconsin",
        "timezone": "America/Chicago"
    },
    {
        "name": "Oshkosh",
        "country": "United States of America",
        "province": "Wisconsin",
        "timezone": "America/Chicago"
    },
    {
        "name": "Rhinelander",
        "country": "United States of America",
        "province": "Wisconsin",
        "timezone": "America/Chicago"
    },
    {
        "name": "Racine",
        "country": "United States of America",
        "province": "Wisconsin",
        "timezone": "America/Chicago"
    },
    {
        "name": "Marinette",
        "country": "United States of America",
        "province": "Wisconsin",
        "timezone": "America/Chicago"
    },
    {
        "name": "Wheeling",
        "country": "United States of America",
        "province": "West Virginia",
        "timezone": "America/New_York"
    },
    {
        "name": "Morgantown",
        "country": "United States of America",
        "province": "West Virginia",
        "timezone": "America/New_York"
    },
    {
        "name": "Huntington",
        "country": "United States of America",
        "province": "West Virginia",
        "timezone": "America/New_York"
    },
    {
        "name": "Beckley",
        "country": "United States of America",
        "province": "West Virginia",
        "timezone": "America/New_York"
    },
    {
        "name": "Wilmington",
        "country": "United States of America",
        "province": "Delaware",
        "timezone": "America/New_York"
    },
    {
        "name": "Cumberland",
        "country": "United States of America",
        "province": "Maryland",
        "timezone": "America/New_York"
    },
    {
        "name": "Atlantic City",
        "country": "United States of America",
        "province": "New Jersey",
        "timezone": "America/New_York"
    },
    {
        "name": "Newark",
        "country": "United States of America",
        "province": "New Jersey",
        "timezone": "America/New_York"
    },
    {
        "name": "Schenectady",
        "country": "United States of America",
        "province": "New York",
        "timezone": "America/New_York"
    },
    {
        "name": "Binghamton",
        "country": "United States of America",
        "province": "New York",
        "timezone": "America/New_York"
    },
    {
        "name": "Utica",
        "country": "United States of America",
        "province": "New York",
        "timezone": "America/New_York"
    },
    {
        "name": "Watertown",
        "country": "United States of America",
        "province": "New York",
        "timezone": "America/New_York"
    },
    {
        "name": "Niagara Falls",
        "country": "United States of America",
        "province": "New York",
        "timezone": "America/New_York"
    },
    {
        "name": "Jamestown",
        "country": "United States of America",
        "province": "New York",
        "timezone": "America/New_York"
    },
    {
        "name": "Elmira",
        "country": "United States of America",
        "province": "New York",
        "timezone": "America/New_York"
    },
    {
        "name": "York",
        "country": "United States of America",
        "province": "Pennsylvania",
        "timezone": "America/New_York"
    },
    {
        "name": "Johnstown",
        "country": "United States of America",
        "province": "Pennsylvania",
        "timezone": "America/New_York"
    },
    {
        "name": "Scranton",
        "country": "United States of America",
        "province": "Pennsylvania",
        "timezone": "America/New_York"
    },
    {
        "name": "State College",
        "country": "United States of America",
        "province": "Pennsylvania",
        "timezone": "America/New_York"
    },
    {
        "name": "Erie",
        "country": "United States of America",
        "province": "Pennsylvania",
        "timezone": "America/New_York"
    },
    {
        "name": "Wilkes Barre",
        "country": "United States of America",
        "province": "Pennsylvania",
        "timezone": "America/New_York"
    },
    {
        "name": "Bar Harbor",
        "country": "United States of America",
        "province": "Maine",
        "timezone": "America/New_York"
    },
    {
        "name": "Lewiston",
        "country": "United States of America",
        "province": "Maine",
        "timezone": "America/New_York"
    },
    {
        "name": "Presque Isle",
        "country": "United States of America",
        "province": "Maine",
        "timezone": "America/New_York"
    },
    {
        "name": "Ann Arbor",
        "country": "United States of America",
        "province": "Michigan",
        "timezone": "America/Detroit"
    },
    {
        "name": "Kalamazoo",
        "country": "United States of America",
        "province": "Michigan",
        "timezone": "America/Detroit"
    },
    {
        "name": "Muskegon",
        "country": "United States of America",
        "province": "Michigan",
        "timezone": "America/Detroit"
    },
    {
        "name": "Flint",
        "country": "United States of America",
        "province": "Michigan",
        "timezone": "America/Detroit"
    },
    {
        "name": "Grand Rapids",
        "country": "United States of America",
        "province": "Michigan",
        "timezone": "America/Detroit"
    },
    {
        "name": "Pontiac",
        "country": "United States of America",
        "province": "Michigan",
        "timezone": "America/Detroit"
    },
    {
        "name": "Cadillac",
        "country": "United States of America",
        "province": "Michigan",
        "timezone": "America/Detroit"
    },
    {
        "name": "Traverse City",
        "country": "United States of America",
        "province": "Michigan",
        "timezone": "America/Detroit"
    },
    {
        "name": "Petoskey",
        "country": "United States of America",
        "province": "Michigan",
        "timezone": "America/Detroit"
    },
    {
        "name": "Escanaba",
        "country": "United States of America",
        "province": "Michigan",
        "timezone": "America/Detroit"
    },
    {
        "name": "Marquette",
        "country": "United States of America",
        "province": "Michigan",
        "timezone": "America/Detroit"
    },
    {
        "name": "Hancock",
        "country": "United States of America",
        "province": "Michigan",
        "timezone": "America/Detroit"
    },
    {
        "name": "Wrangell",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Sitka"
    },
    {
        "name": "Shishmaref",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Nome"
    },
    {
        "name": "Hoonah",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Juneau"
    },
    {
        "name": "Atka",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Adak"
    },
    {
        "name": "Nikolski",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Nome"
    },
    {
        "name": "Karluk",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "False Pass",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Nome"
    },
    {
        "name": "Kivalina",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Nome"
    },
    {
        "name": "Newhalen",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Pilot Point",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Chignik",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "King Salmon",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Quinhagak",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Aniak",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Kotlit",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Nome"
    },
    {
        "name": "Unalakleet",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Koyuk",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "McGrath",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Hughes",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Ambler",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Wales",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Nome"
    },
    {
        "name": "Kotzebue",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Nome"
    },
    {
        "name": "Wasilla",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Circle",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Denali Park",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Yakutat",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Yakutat"
    },
    {
        "name": "Homer",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Tanacross",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Wiseman",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Kailua-Kona",
        "country": "United States of America",
        "province": "Hawaii",
        "timezone": "Pacific/Honolulu"
    },
    {
        "name": "Butte",
        "country": "United States of America",
        "province": "Montana",
        "timezone": "America/Denver"
    },
    {
        "name": "Grand Forks",
        "country": "United States of America",
        "province": "North Dakota",
        "timezone": "America/Chicago"
    },
    {
        "name": "Pocatello",
        "country": "United States of America",
        "province": "Idaho",
        "timezone": "America/Boise"
    },
    {
        "name": "Tacoma",
        "country": "United States of America",
        "province": "Washington",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Yuma",
        "country": "United States of America",
        "province": "Arizona",
        "timezone": "America/Phoenix"
    },
    {
        "name": "Prescott",
        "country": "United States of America",
        "province": "Arizona",
        "timezone": "America/Phoenix"
    },
    {
        "name": "Long Beach",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Grand Junction",
        "country": "United States of America",
        "province": "Colorado",
        "timezone": "America/Denver"
    },
    {
        "name": "Ely",
        "country": "United States of America",
        "province": "Nevada",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Carson City",
        "country": "United States of America",
        "province": "Nevada",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Carlsbad",
        "country": "United States of America",
        "province": "New Mexico",
        "timezone": "America/Denver"
    },
    {
        "name": "Alamogordo",
        "country": "United States of America",
        "province": "New Mexico",
        "timezone": "America/Denver"
    },
    {
        "name": "Medford",
        "country": "United States of America",
        "province": "Oregon",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Klamath Falls",
        "country": "United States of America",
        "province": "Oregon",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "St. George",
        "country": "United States of America",
        "province": "Utah",
        "timezone": "America/Denver"
    },
    {
        "name": "Provo",
        "country": "United States of America",
        "province": "Utah",
        "timezone": "America/Denver"
    },
    {
        "name": "Laramie",
        "country": "United States of America",
        "province": "Wyoming",
        "timezone": "America/Denver"
    },
    {
        "name": "Little Rock",
        "country": "United States of America",
        "province": "Arkansas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Wichita",
        "country": "United States of America",
        "province": "Kansas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Jefferson City",
        "country": "United States of America",
        "province": "Missouri",
        "timezone": "America/Chicago"
    },
    {
        "name": "Rapid City",
        "country": "United States of America",
        "province": "South Dakota",
        "timezone": "America/Denver"
    },
    {
        "name": "Lafayette",
        "country": "United States of America",
        "province": "Louisiana",
        "timezone": "America/Chicago"
    },
    {
        "name": "Galveston",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Freeport",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Victoria",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Odessa",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Wichita Falls",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Waco",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Lubbock",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Hartford",
        "country": "United States of America",
        "province": "Connecticut",
        "timezone": "America/New_York"
    },
    {
        "name": "Providence",
        "country": "United States of America",
        "province": "Rhode Island",
        "timezone": "America/New_York"
    },
    {
        "name": "Birmingham",
        "country": "United States of America",
        "province": "Alabama",
        "timezone": "America/Chicago"
    },
    {
        "name": "Mobile",
        "country": "United States of America",
        "province": "Alabama",
        "timezone": "America/Chicago"
    },
    {
        "name": "Pensacola",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/Chicago"
    },
    {
        "name": "St. Petersburg",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/New_York"
    },
    {
        "name": "Biloxi",
        "country": "United States of America",
        "province": "Mississippi",
        "timezone": "America/Chicago"
    },
    {
        "name": "Springfield",
        "country": "United States of America",
        "province": "Illinois",
        "timezone": "America/Chicago"
    },
    {
        "name": "Frankfort",
        "country": "United States of America",
        "province": "Kentucky",
        "timezone": "America/New_York"
    },
    {
        "name": "Greensboro",
        "country": "United States of America",
        "province": "North Carolina",
        "timezone": "America/New_York"
    },
    {
        "name": "Dayton",
        "country": "United States of America",
        "province": "Ohio",
        "timezone": "America/New_York"
    },
    {
        "name": "Virginia Beach",
        "country": "United States of America",
        "province": "Virginia",
        "timezone": "America/New_York"
    },
    {
        "name": "Madison",
        "country": "United States of America",
        "province": "Wisconsin",
        "timezone": "America/Chicago"
    },
    {
        "name": "Green Bay",
        "country": "United States of America",
        "province": "Wisconsin",
        "timezone": "America/Chicago"
    },
    {
        "name": "Trenton",
        "country": "United States of America",
        "province": "New Jersey",
        "timezone": "America/New_York"
    },
    {
        "name": "Lansing",
        "country": "United States of America",
        "province": "Michigan",
        "timezone": "America/Detroit"
    },
    {
        "name": "Gambell",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Nome"
    },
    {
        "name": "Palmer",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Seward",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Duluth",
        "country": "United States of America",
        "province": "Minnesota",
        "timezone": "America/Chicago"
    },
    {
        "name": "Bemidji",
        "country": "United States of America",
        "province": "Minnesota",
        "timezone": "America/Chicago"
    },
    {
        "name": "Havre",
        "country": "United States of America",
        "province": "Montana",
        "timezone": "America/Denver"
    },
    {
        "name": "Kalispell",
        "country": "United States of America",
        "province": "Montana",
        "timezone": "America/Denver"
    },
    {
        "name": "Idaho Falls",
        "country": "United States of America",
        "province": "Idaho",
        "timezone": "America/Boise"
    },
    {
        "name": "Lewiston",
        "country": "United States of America",
        "province": "Idaho",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Yakima",
        "country": "United States of America",
        "province": "Washington",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Wenatchee",
        "country": "United States of America",
        "province": "Washington",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Douglas",
        "country": "United States of America",
        "province": "Arizona",
        "timezone": "America/Phoenix"
    },
    {
        "name": "Bakersfield",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Oakland",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Lancaster",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Chico",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Monterey",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Salinas",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Los Alamos",
        "country": "United States of America",
        "province": "New Mexico",
        "timezone": "America/Denver"
    },
    {
        "name": "Eugene",
        "country": "United States of America",
        "province": "Oregon",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Coos Bay",
        "country": "United States of America",
        "province": "Oregon",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Bend",
        "country": "United States of America",
        "province": "Oregon",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Cody",
        "country": "United States of America",
        "province": "Wyoming",
        "timezone": "America/Denver"
    },
    {
        "name": "Cedar Rapids",
        "country": "United States of America",
        "province": "Iowa",
        "timezone": "America/Chicago"
    },
    {
        "name": "Springfield",
        "country": "United States of America",
        "province": "Missouri",
        "timezone": "America/Chicago"
    },
    {
        "name": "Lincoln",
        "country": "United States of America",
        "province": "Nebraska",
        "timezone": "America/Chicago"
    },
    {
        "name": "Alexandria",
        "country": "United States of America",
        "province": "Louisiana",
        "timezone": "America/Chicago"
    },
    {
        "name": "Abilene",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Brownsville",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Tyler",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Concord",
        "country": "United States of America",
        "province": "New Hampshire",
        "timezone": "America/New_York"
    },
    {
        "name": "Huntsville",
        "country": "United States of America",
        "province": "Alabama",
        "timezone": "America/Chicago"
    },
    {
        "name": "Key West",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/New_York"
    },
    {
        "name": "West Palm Beach",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/New_York"
    },
    {
        "name": "Sarasota",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/New_York"
    },
    {
        "name": "Daytona Beach",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/New_York"
    },
    {
        "name": "Gainesville",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/New_York"
    },
    {
        "name": "Ft. Myers",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/New_York"
    },
    {
        "name": "Brunswick",
        "country": "United States of America",
        "province": "Georgia",
        "timezone": "America/New_York"
    },
    {
        "name": "Augusta",
        "country": "United States of America",
        "province": "Georgia",
        "timezone": "America/New_York"
    },
    {
        "name": "Vicksburg",
        "country": "United States of America",
        "province": "Mississippi",
        "timezone": "America/Chicago"
    },
    {
        "name": "Myrtle Beach",
        "country": "United States of America",
        "province": "South Carolina",
        "timezone": "America/New_York"
    },
    {
        "name": "Charleston",
        "country": "United States of America",
        "province": "South Carolina",
        "timezone": "America/New_York"
    },
    {
        "name": "Peoria",
        "country": "United States of America",
        "province": "Illinois",
        "timezone": "America/Chicago"
    },
    {
        "name": "Evansville",
        "country": "United States of America",
        "province": "Indiana",
        "timezone": "America/Chicago"
    },
    {
        "name": "Louisville",
        "country": "United States of America",
        "province": "Kentucky",
        "timezone": "America/Kentucky/Louisville"
    },
    {
        "name": "Lexington",
        "country": "United States of America",
        "province": "Kentucky",
        "timezone": "America/New_York"
    },
    {
        "name": "Charlotte",
        "country": "United States of America",
        "province": "North Carolina",
        "timezone": "America/New_York"
    },
    {
        "name": "Youngstown",
        "country": "United States of America",
        "province": "Ohio",
        "timezone": "America/New_York"
    },
    {
        "name": "Canton",
        "country": "United States of America",
        "province": "Ohio",
        "timezone": "America/New_York"
    },
    {
        "name": "Toledo",
        "country": "United States of America",
        "province": "Ohio",
        "timezone": "America/New_York"
    },
    {
        "name": "Columbus",
        "country": "United States of America",
        "province": "Ohio",
        "timezone": "America/New_York"
    },
    {
        "name": "Chattanooga",
        "country": "United States of America",
        "province": "Tennessee",
        "timezone": "America/New_York"
    },
    {
        "name": "Charlottesville",
        "country": "United States of America",
        "province": "Virginia",
        "timezone": "America/New_York"
    },
    {
        "name": "Lynchburg",
        "country": "United States of America",
        "province": "Virginia",
        "timezone": "America/New_York"
    },
    {
        "name": "Wausau",
        "country": "United States of America",
        "province": "Wisconsin",
        "timezone": "America/Chicago"
    },
    {
        "name": "Albany",
        "country": "United States of America",
        "province": "New York",
        "timezone": "America/New_York"
    },
    {
        "name": "Ithaca",
        "country": "United States of America",
        "province": "New York",
        "timezone": "America/New_York"
    },
    {
        "name": "Harrisburg",
        "country": "United States of America",
        "province": "Pennsylvania",
        "timezone": "America/New_York"
    },
    {
        "name": "Bangor",
        "country": "United States of America",
        "province": "Maine",
        "timezone": "America/New_York"
    },
    {
        "name": "Portland",
        "country": "United States of America",
        "province": "Maine",
        "timezone": "America/New_York"
    },
    {
        "name": "Saginaw",
        "country": "United States of America",
        "province": "Michigan",
        "timezone": "America/Detroit"
    },
    {
        "name": "Ketchikan",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Sitka"
    },
    {
        "name": "Unalaska",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Nome"
    },
    {
        "name": "Togiak",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Red Devil",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Hooper Bay",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Nome"
    },
    {
        "name": "Wainwright",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Galena",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Kaktovik",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Skagway",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Juneau"
    },
    {
        "name": "Cordova",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Kenai",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Fort Yukon",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Santa Cruz",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "San Bernardino",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Bridgeport",
        "country": "United States of America",
        "province": "Connecticut",
        "timezone": "America/New_York"
    },
    {
        "name": "Rochester",
        "country": "United States of America",
        "province": "New York",
        "timezone": "America/New_York"
    },
    {
        "name": "International Falls",
        "country": "United States of America",
        "province": "Minnesota",
        "timezone": "America/Chicago"
    },
    {
        "name": "St. Paul",
        "country": "United States of America",
        "province": "Minnesota",
        "timezone": "America/Chicago"
    },
    {
        "name": "Billings",
        "country": "United States of America",
        "province": "Montana",
        "timezone": "America/Denver"
    },
    {
        "name": "Great Falls",
        "country": "United States of America",
        "province": "Montana",
        "timezone": "America/Denver"
    },
    {
        "name": "Missoula",
        "country": "United States of America",
        "province": "Montana",
        "timezone": "America/Denver"
    },
    {
        "name": "Minot",
        "country": "United States of America",
        "province": "North Dakota",
        "timezone": "America/Chicago"
    },
    {
        "name": "Fargo",
        "country": "United States of America",
        "province": "North Dakota",
        "timezone": "America/Chicago"
    },
    {
        "name": "Hilo",
        "country": "United States of America",
        "province": "Hawaii",
        "timezone": "Pacific/Honolulu"
    },
    {
        "name": "Olympia",
        "country": "United States of America",
        "province": "Washington",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Spokane",
        "country": "United States of America",
        "province": "Washington",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Vancouver",
        "country": "United States of America",
        "province": "Washington",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Flagstaff",
        "country": "United States of America",
        "province": "Arizona",
        "timezone": "America/Phoenix"
    },
    {
        "name": "Tucson",
        "country": "United States of America",
        "province": "Arizona",
        "timezone": "America/Phoenix"
    },
    {
        "name": "Santa Barbara",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Fresno",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Eureka",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Colorado Springs",
        "country": "United States of America",
        "province": "Colorado",
        "timezone": "America/Denver"
    },
    {
        "name": "Reno",
        "country": "United States of America",
        "province": "Nevada",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Elko",
        "country": "United States of America",
        "province": "Nevada",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Albuquerque",
        "country": "United States of America",
        "province": "New Mexico",
        "timezone": "America/Denver"
    },
    {
        "name": "Salem",
        "country": "United States of America",
        "province": "Oregon",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Casper",
        "country": "United States of America",
        "province": "Wyoming",
        "timezone": "America/Denver"
    },
    {
        "name": "Topeka",
        "country": "United States of America",
        "province": "Kansas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Kansas City",
        "country": "United States of America",
        "province": "Missouri",
        "timezone": "America/Chicago"
    },
    {
        "name": "Tulsa",
        "country": "United States of America",
        "province": "Oklahoma",
        "timezone": "America/Chicago"
    },
    {
        "name": "Sioux Falls",
        "country": "United States of America",
        "province": "South Dakota",
        "timezone": "America/Chicago"
    },
    {
        "name": "Shreveport",
        "country": "United States of America",
        "province": "Louisiana",
        "timezone": "America/Chicago"
    },
    {
        "name": "Baton Rouge",
        "country": "United States of America",
        "province": "Louisiana",
        "timezone": "America/Chicago"
    },
    {
        "name": "Ft. Worth",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Corpus Christi",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Austin",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Amarillo",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "El Paso",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Denver"
    },
    {
        "name": "Laredo",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Burlington",
        "country": "United States of America",
        "province": "Vermont",
        "timezone": "America/New_York"
    },
    {
        "name": "Montgomery",
        "country": "United States of America",
        "province": "Alabama",
        "timezone": "America/Chicago"
    },
    {
        "name": "Tallahassee",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/New_York"
    },
    {
        "name": "Orlando",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/New_York"
    },
    {
        "name": "Jacksonville",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/New_York"
    },
    {
        "name": "Savannah",
        "country": "United States of America",
        "province": "Georgia",
        "timezone": "America/New_York"
    },
    {
        "name": "Columbia",
        "country": "United States of America",
        "province": "South Carolina",
        "timezone": "America/New_York"
    },
    {
        "name": "Indianapolis",
        "country": "United States of America",
        "province": "Indiana",
        "timezone": "America/Indiana/Indianapolis"
    },
    {
        "name": "Wilmington",
        "country": "United States of America",
        "province": "North Carolina",
        "timezone": "America/New_York"
    },
    {
        "name": "Knoxville",
        "country": "United States of America",
        "province": "Tennessee",
        "timezone": "America/New_York"
    },
    {
        "name": "Richmond",
        "country": "United States of America",
        "province": "Virginia",
        "timezone": "America/New_York"
    },
    {
        "name": "Charleston",
        "country": "United States of America",
        "province": "West Virginia",
        "timezone": "America/New_York"
    },
    {
        "name": "Baltimore",
        "country": "United States of America",
        "province": "Maryland",
        "timezone": "America/New_York"
    },
    {
        "name": "Syracuse",
        "country": "United States of America",
        "province": "New York",
        "timezone": "America/New_York"
    },
    {
        "name": "Augusta",
        "country": "United States of America",
        "province": "Maine",
        "timezone": "America/New_York"
    },
    {
        "name": "Sault Ste. Marie",
        "country": "United States of America",
        "province": "Michigan",
        "timezone": "America/Detroit"
    },
    {
        "name": "Sitka",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Sitka"
    },
    {
        "name": "Helena",
        "country": "United States of America",
        "province": "Montana",
        "timezone": "America/Denver"
    },
    {
        "name": "Bismarck",
        "country": "United States of America",
        "province": "North Dakota",
        "timezone": "America/Chicago"
    },
    {
        "name": "Boise",
        "country": "United States of America",
        "province": "Idaho",
        "timezone": "America/Boise"
    },
    {
        "name": "San Jose",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Sacramento",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Las Vegas",
        "country": "United States of America",
        "province": "Nevada",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Santa Fe",
        "country": "United States of America",
        "province": "New Mexico",
        "timezone": "America/Denver"
    },
    {
        "name": "Portland",
        "country": "United States of America",
        "province": "Oregon",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Salt Lake City",
        "country": "United States of America",
        "province": "Utah",
        "timezone": "America/Denver"
    },
    {
        "name": "Cheyenne",
        "country": "United States of America",
        "province": "Wyoming",
        "timezone": "America/Denver"
    },
    {
        "name": "Des Moines",
        "country": "United States of America",
        "province": "Iowa",
        "timezone": "America/Chicago"
    },
    {
        "name": "Omaha",
        "country": "United States of America",
        "province": "Nebraska",
        "timezone": "America/Chicago"
    },
    {
        "name": "Oklahoma City",
        "country": "United States of America",
        "province": "Oklahoma",
        "timezone": "America/Chicago"
    },
    {
        "name": "Pierre",
        "country": "United States of America",
        "province": "South Dakota",
        "timezone": "America/Chicago"
    },
    {
        "name": "San Antonio",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Jackson",
        "country": "United States of America",
        "province": "Mississippi",
        "timezone": "America/Chicago"
    },
    {
        "name": "Raleigh",
        "country": "United States of America",
        "province": "North Carolina",
        "timezone": "America/New_York"
    },
    {
        "name": "Cleveland",
        "country": "United States of America",
        "province": "Ohio",
        "timezone": "America/New_York"
    },
    {
        "name": "Cincinnati",
        "country": "United States of America",
        "province": "Ohio",
        "timezone": "America/New_York"
    },
    {
        "name": "Nashville",
        "country": "United States of America",
        "province": "Tennessee",
        "timezone": "America/Chicago"
    },
    {
        "name": "Memphis",
        "country": "United States of America",
        "province": "Tennessee",
        "timezone": "America/Chicago"
    },
    {
        "name": "Norfolk",
        "country": "United States of America",
        "province": "Virginia",
        "timezone": "America/New_York"
    },
    {
        "name": "Milwaukee",
        "country": "United States of America",
        "province": "Wisconsin",
        "timezone": "America/Chicago"
    },
    {
        "name": "Buffalo",
        "country": "United States of America",
        "province": "New York",
        "timezone": "America/New_York"
    },
    {
        "name": "Pittsburgh",
        "country": "United States of America",
        "province": "Pennsylvania",
        "timezone": "America/New_York"
    },
    {
        "name": "Kodiak",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Cold Bay",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Nome"
    },
    {
        "name": "Bethel",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Point Hope",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Nome"
    },
    {
        "name": "Barrow",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Nome",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Nome"
    },
    {
        "name": "Valdez",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Juneau",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Juneau"
    },
    {
        "name": "Fairbanks",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Prudhoe Bay",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "Minneapolis",
        "country": "United States of America",
        "province": "Minnesota",
        "timezone": "America/Chicago"
    },
    {
        "name": "Honolulu",
        "country": "United States of America",
        "province": "Hawaii",
        "timezone": "Pacific/Honolulu"
    },
    {
        "name": "Seattle",
        "country": "United States of America",
        "province": "Washington",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Phoenix",
        "country": "United States of America",
        "province": "Arizona",
        "timezone": "America/Phoenix"
    },
    {
        "name": "San Diego",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "St. Louis",
        "country": "United States of America",
        "province": "Missouri",
        "timezone": "America/Chicago"
    },
    {
        "name": "New Orleans",
        "country": "United States of America",
        "province": "Louisiana",
        "timezone": "America/Chicago"
    },
    {
        "name": "Dallas",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Boston",
        "country": "United States of America",
        "province": "Massachusetts",
        "timezone": "America/New_York"
    },
    {
        "name": "Tampa",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/New_York"
    },
    {
        "name": "Philadelphia",
        "country": "United States of America",
        "province": "Pennsylvania",
        "timezone": "America/New_York"
    },
    {
        "name": "Detroit",
        "country": "United States of America",
        "province": "Michigan",
        "timezone": "America/Detroit"
    },
    {
        "name": "Anchorage",
        "country": "United States of America",
        "province": "Alaska",
        "timezone": "America/Anchorage"
    },
    {
        "name": "San Francisco",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Denver",
        "country": "United States of America",
        "province": "Colorado",
        "timezone": "America/Denver"
    },
    {
        "name": "Houston",
        "country": "United States of America",
        "province": "Texas",
        "timezone": "America/Chicago"
    },
    {
        "name": "Miami",
        "country": "United States of America",
        "province": "Florida",
        "timezone": "America/New_York"
    },
    {
        "name": "Atlanta",
        "country": "United States of America",
        "province": "Georgia",
        "timezone": "America/New_York"
    },
    {
        "name": "Chicago",
        "country": "United States of America",
        "province": "Illinois",
        "timezone": "America/Chicago"
    },
    {
        "name": "Los Angeles",
        "country": "United States of America",
        "province": "California",
        "timezone": "America/Los_Angeles"
    },
    {
        "name": "Washington, D.C.",
        "country": "United States of America",
        "province": "District of Columbia",
        "timezone": "America/New_York"
    },
    {
        "name": "New York",
        "country": "United States of America",
        "province": "New York",
        "timezone": "America/New_York"
    },
    {
        "name": "Christiansted",
        "country": "United States Virgin Islands",
        "province": "Virgin Islands",
        "timezone": "America/St_Thomas"
    },
    {
        "name": "Colonia del Sacramento",
        "country": "Uruguay",
        "province": "Colonia",
        "timezone": "America/Montevideo"
    },
    {
        "name": "Trinidad",
        "country": "Uruguay",
        "province": "Flores",
        "timezone": "America/Montevideo"
    },
    {
        "name": "Fray Bentos",
        "country": "Uruguay",
        "province": "Río Negro",
        "timezone": "America/Montevideo"
    },
    {
        "name": "Canelones",
        "country": "Uruguay",
        "province": "Canelones",
        "timezone": "America/Montevideo"
    },
    {
        "name": "Florida",
        "country": "Uruguay",
        "province": "Florida",
        "timezone": "America/Montevideo"
    },
    {
        "name": "Artigas",
        "country": "Uruguay",
        "province": "Artigas",
        "timezone": "America/Montevideo"
    },
    {
        "name": "Baltasar Brum",
        "country": "Uruguay",
        "province": "Artigas",
        "timezone": "America/Montevideo"
    },
    {
        "name": "Tranqueras",
        "country": "Uruguay",
        "province": "Rivera",
        "timezone": "America/Montevideo"
    },
    {
        "name": "Tacuarembo",
        "country": "Uruguay",
        "province": "Tacuarembó",
        "timezone": "America/Montevideo"
    },
    {
        "name": "Paso de los Toros",
        "country": "Uruguay",
        "province": "Tacuarembó",
        "timezone": "America/Montevideo"
    },
    {
        "name": "Vergara",
        "country": "Uruguay",
        "province": "Treinta y Tres",
        "timezone": "America/Montevideo"
    },
    {
        "name": "Treinta y Tres",
        "country": "Uruguay",
        "province": "Treinta y Tres",
        "timezone": "America/Montevideo"
    },
    {
        "name": "Santa Lucia",
        "country": "Uruguay",
        "province": "Canelones",
        "timezone": "America/Montevideo"
    },
    {
        "name": "Jose Batlle y Ordonez",
        "country": "Uruguay",
        "province": "Lavalleja",
        "timezone": "America/Montevideo"
    },
    {
        "name": "Minas",
        "country": "Uruguay",
        "province": "Lavalleja",
        "timezone": "America/Montevideo"
    },
    {
        "name": "Maldonado",
        "country": "Uruguay",
        "province": "Maldonado",
        "timezone": "America/Montevideo"
    },
    {
        "name": "Punta del Este",
        "country": "Uruguay",
        "province": "Maldonado",
        "timezone": "America/Montevideo"
    },
    {
        "name": "Aigua",
        "country": "Uruguay",
        "province": "Maldonado",
        "timezone": "America/Montevideo"
    },
    {
        "name": "La Paloma",
        "country": "Uruguay",
        "province": "Rocha",
        "timezone": "America/Montevideo"
    },
    {
        "name": "Carmelo",
        "country": "Uruguay",
        "province": "Colonia",
        "timezone": "America/Montevideo"
    },
    {
        "name": "Bella Union",
        "country": "Uruguay",
        "province": "Artigas",
        "timezone": "America/Montevideo"
    },
    {
        "name": "Mercedes",
        "country": "Uruguay",
        "province": "Soriano",
        "timezone": "America/Montevideo"
    },
    {
        "name": "Melo",
        "country": "Uruguay",
        "province": "Cerro Largo",
        "timezone": "America/Montevideo"
    },
    {
        "name": "Rivera",
        "country": "Uruguay",
        "province": "Rivera",
        "timezone": "America/Montevideo"
    },
    {
        "name": "Lascano",
        "country": "Uruguay",
        "province": "Rocha",
        "timezone": "America/Montevideo"
    },
    {
        "name": "Castillos",
        "country": "Uruguay",
        "province": "Rocha",
        "timezone": "America/Montevideo"
    },
    {
        "name": "San Jose de Mayo",
        "country": "Uruguay",
        "province": "San José",
        "timezone": "America/Montevideo"
    },
    {
        "name": "Rocha",
        "country": "Uruguay",
        "province": "Rocha",
        "timezone": "America/Montevideo"
    },
    {
        "name": "Paysandu",
        "country": "Uruguay",
        "province": "Paysandú",
        "timezone": "America/Montevideo"
    },
    {
        "name": "Salto",
        "country": "Uruguay",
        "province": "Salto",
        "timezone": "America/Montevideo"
    },
    {
        "name": "Durazno",
        "country": "Uruguay",
        "province": "Durazno",
        "timezone": "America/Montevideo"
    },
    {
        "name": "Montevideo",
        "country": "Uruguay",
        "province": "Montevideo",
        "timezone": "America/Montevideo"
    },
    {
        "name": "Khujayli",
        "country": "Uzbekistan",
        "province": "Karakalpakstan",
        "timezone": "Asia/Samarkand"
    },
    {
        "name": "Urgut",
        "country": "Uzbekistan",
        "province": "Samarkand",
        "timezone": "Asia/Samarkand"
    },
    {
        "name": "Kattaqorgon",
        "country": "Uzbekistan",
        "province": "Samarkand",
        "timezone": "Asia/Samarkand"
    },
    {
        "name": "Denow",
        "country": "Uzbekistan",
        "province": "Surkhandarya",
        "timezone": "Asia/Samarkand"
    },
    {
        "name": "Guliston",
        "country": "Uzbekistan",
        "province": "Sirdaryo",
        "timezone": "Asia/Tashkent"
    },
    {
        "name": "Iskandar",
        "country": "Uzbekistan",
        "province": "Tashkent",
        "timezone": "Asia/Tashkent"
    },
    {
        "name": "Chirchiq",
        "country": "Uzbekistan",
        "province": "Tashkent",
        "timezone": "Asia/Tashkent"
    },
    {
        "name": "Kogon",
        "country": "Uzbekistan",
        "province": "Bukhoro",
        "timezone": "Asia/Samarkand"
    },
    {
        "name": "Khiwa",
        "country": "Uzbekistan",
        "province": "Khorezm",
        "timezone": "Asia/Samarkand"
    },
    {
        "name": "Chimboy",
        "country": "Uzbekistan",
        "province": "Karakalpakstan",
        "timezone": "Asia/Samarkand"
    },
    {
        "name": "Qunghirot",
        "country": "Uzbekistan",
        "province": "Karakalpakstan",
        "timezone": "Asia/Samarkand"
    },
    {
        "name": "Zarafshon",
        "country": "Uzbekistan",
        "province": "Navoi",
        "timezone": "Asia/Samarkand"
    },
    {
        "name": "Navoi",
        "country": "Uzbekistan",
        "province": "Navoi",
        "timezone": "Asia/Samarkand"
    },
    {
        "name": "Shahrisabz",
        "country": "Uzbekistan",
        "province": "Kashkadarya",
        "timezone": "Asia/Samarkand"
    },
    {
        "name": "Qarshi",
        "country": "Uzbekistan",
        "province": "Kashkadarya",
        "timezone": "Asia/Samarkand"
    },
    {
        "name": "Qoqon",
        "country": "Uzbekistan",
        "province": "Ferghana",
        "timezone": "Asia/Tashkent"
    },
    {
        "name": "Jizzax",
        "country": "Uzbekistan",
        "province": "Jizzakh",
        "timezone": "Asia/Tashkent"
    },
    {
        "name": "Angren",
        "country": "Uzbekistan",
        "province": "Tashkent",
        "timezone": "Asia/Tashkent"
    },
    {
        "name": "Olmaliq",
        "country": "Uzbekistan",
        "province": "Tashkent",
        "timezone": "Asia/Tashkent"
    },
    {
        "name": "Muynoq",
        "country": "Uzbekistan",
        "province": "Karakalpakstan",
        "timezone": "Asia/Samarkand"
    },
    {
        "name": "Termiz",
        "country": "Uzbekistan",
        "province": "Surkhandarya",
        "timezone": "Asia/Samarkand"
    },
    {
        "name": "Fargona",
        "country": "Uzbekistan",
        "province": "Ferghana",
        "timezone": "Asia/Tashkent"
    },
    {
        "name": "Namangan",
        "country": "Uzbekistan",
        "province": "Namangan",
        "timezone": "Asia/Tashkent"
    },
    {
        "name": "Urgentch",
        "country": "Uzbekistan",
        "province": "Khorezm",
        "timezone": "Asia/Samarkand"
    },
    {
        "name": "Bukhara",
        "country": "Uzbekistan",
        "province": "Bukhoro",
        "timezone": "Asia/Samarkand"
    },
    {
        "name": "Nukus",
        "country": "Uzbekistan",
        "province": "Karakalpakstan",
        "timezone": "Asia/Samarkand"
    },
    {
        "name": "Andijon",
        "country": "Uzbekistan",
        "province": "Andijon",
        "timezone": "Asia/Tashkent"
    },
    {
        "name": "Samarqand",
        "country": "Uzbekistan",
        "province": "Samarkand",
        "timezone": "Asia/Samarkand"
    },
    {
        "name": "Tashkent",
        "country": "Uzbekistan",
        "province": "Tashkent",
        "timezone": "Asia/Tashkent"
    },
    {
        "name": "Luganville",
        "country": "Vanuatu",
        "province": "Sanma",
        "timezone": "Pacific/Efate"
    },
    {
        "name": "Port Vila",
        "country": "Vanuatu",
        "province": "Shefa",
        "timezone": "Pacific/Efate"
    },
    {
        "name": "Vatican City",
        "country": "Vatican (Holy Sea)",
        "province": "Lazio",
        "timezone": "Europe/Rome"
    },
    {
        "name": "San Carlos",
        "country": "Venezuela",
        "province": "Cojedes",
        "timezone": "America/Caracas"
    },
    {
        "name": "San Felipe",
        "country": "Venezuela",
        "province": "Yaracuy",
        "timezone": "America/Caracas"
    },
    {
        "name": "San Juan De Los Morros",
        "country": "Venezuela",
        "province": "Guárico",
        "timezone": "America/Caracas"
    },
    {
        "name": "La Asuncion",
        "country": "Venezuela",
        "province": "Nueva Esparta",
        "timezone": "America/Caracas"
    },
    {
        "name": "Guasdualito",
        "country": "Venezuela",
        "province": "Apure",
        "timezone": "America/Caracas"
    },
    {
        "name": "Barinas",
        "country": "Venezuela",
        "province": "Barinas",
        "timezone": "America/Caracas"
    },
    {
        "name": "Valera",
        "country": "Venezuela",
        "province": "Trujillo",
        "timezone": "America/Caracas"
    },
    {
        "name": "Cabimas",
        "country": "Venezuela",
        "province": "Trujillo",
        "timezone": "America/Caracas"
    },
    {
        "name": "Carora",
        "country": "Venezuela",
        "province": "Lara",
        "timezone": "America/Caracas"
    },
    {
        "name": "Guanare",
        "country": "Venezuela",
        "province": "Portuguesa",
        "timezone": "America/Caracas"
    },
    {
        "name": "Puerto la Cruz",
        "country": "Venezuela",
        "province": "Anzoátegui",
        "timezone": "America/Caracas"
    },
    {
        "name": "Anaco",
        "country": "Venezuela",
        "province": "Anzoátegui",
        "timezone": "America/Caracas"
    },
    {
        "name": "Los Teques",
        "country": "Venezuela",
        "province": "Distrito Capital",
        "timezone": "America/Caracas"
    },
    {
        "name": "Valle de la Pascua",
        "country": "Venezuela",
        "province": "Guárico",
        "timezone": "America/Caracas"
    },
    {
        "name": "Ocumare del Tuy",
        "country": "Venezuela",
        "province": "Miranda",
        "timezone": "America/Caracas"
    },
    {
        "name": "Carupano",
        "country": "Venezuela",
        "province": "Sucre",
        "timezone": "America/Caracas"
    },
    {
        "name": "Trujillo",
        "country": "Venezuela",
        "province": "Trujillo",
        "timezone": "America/Caracas"
    },
    {
        "name": "Santa Rita",
        "country": "Venezuela",
        "province": "Trujillo",
        "timezone": "America/Caracas"
    },
    {
        "name": "Machiques",
        "country": "Venezuela",
        "province": "Zulia",
        "timezone": "America/Caracas"
    },
    {
        "name": "San Carlos del Zulia",
        "country": "Venezuela",
        "province": "Zulia",
        "timezone": "America/Caracas"
    },
    {
        "name": "Puerto Cabello",
        "country": "Venezuela",
        "province": "Carabobo",
        "timezone": "America/Caracas"
    },
    {
        "name": "Acarigua",
        "country": "Venezuela",
        "province": "Portuguesa",
        "timezone": "America/Caracas"
    },
    {
        "name": "Upata",
        "country": "Venezuela",
        "province": "Bolívar",
        "timezone": "America/Caracas"
    },
    {
        "name": "El Manteco",
        "country": "Venezuela",
        "province": "Bolívar",
        "timezone": "America/Caracas"
    },
    {
        "name": "Chaguaramas",
        "country": "Venezuela",
        "province": "Anzoátegui",
        "timezone": "America/Caracas"
    },
    {
        "name": "Barcelona",
        "country": "Venezuela",
        "province": "Anzoátegui",
        "timezone": "America/Caracas"
    },
    {
        "name": "El Tigre",
        "country": "Venezuela",
        "province": "Anzoátegui",
        "timezone": "America/Caracas"
    },
    {
        "name": "Maiquetia",
        "country": "Venezuela",
        "province": "Vargas",
        "timezone": "America/Caracas"
    },
    {
        "name": "Calabozo",
        "country": "Venezuela",
        "province": "Guárico",
        "timezone": "America/Caracas"
    },
    {
        "name": "Zaraza",
        "country": "Venezuela",
        "province": "Guárico",
        "timezone": "America/Caracas"
    },
    {
        "name": "Altagracia de Orituco",
        "country": "Venezuela",
        "province": "Guárico",
        "timezone": "America/Caracas"
    },
    {
        "name": "Tucupita",
        "country": "Venezuela",
        "province": "Monagas",
        "timezone": "America/Caracas"
    },
    {
        "name": "Porlamar",
        "country": "Venezuela",
        "province": "Nueva Esparta",
        "timezone": "America/Caracas"
    },
    {
        "name": "San Fernando de Apure",
        "country": "Venezuela",
        "province": "Apure",
        "timezone": "America/Caracas"
    },
    {
        "name": "Barquisimeto",
        "country": "Venezuela",
        "province": "Lara",
        "timezone": "America/Caracas"
    },
    {
        "name": "Maturin",
        "country": "Venezuela",
        "province": "Monagas",
        "timezone": "America/Caracas"
    },
    {
        "name": "Cumana",
        "country": "Venezuela",
        "province": "Sucre",
        "timezone": "America/Caracas"
    },
    {
        "name": "Coro",
        "country": "Venezuela",
        "province": "Falcón",
        "timezone": "America/Caracas"
    },
    {
        "name": "Punto Fijo",
        "country": "Venezuela",
        "province": "Falcón",
        "timezone": "America/Caracas"
    },
    {
        "name": "La Esmeralda",
        "country": "Venezuela",
        "province": "Amazonas",
        "timezone": "America/Caracas"
    },
    {
        "name": "Ciudad Bolivar",
        "country": "Venezuela",
        "province": "Bolívar",
        "timezone": "America/Caracas"
    },
    {
        "name": "El Dorado",
        "country": "Venezuela",
        "province": "Bolívar",
        "timezone": "America/Caracas"
    },
    {
        "name": "Maracay",
        "country": "Venezuela",
        "province": "Aragua",
        "timezone": "America/Caracas"
    },
    {
        "name": "Merida",
        "country": "Venezuela",
        "province": "Mérida",
        "timezone": "America/Caracas"
    },
    {
        "name": "Puerto Ayacucho",
        "country": "Venezuela",
        "province": "Amazonas",
        "timezone": "America/Caracas"
    },
    {
        "name": "San Cristobal",
        "country": "Venezuela",
        "province": "Táchira",
        "timezone": "America/Caracas"
    },
    {
        "name": "Valencia",
        "country": "Venezuela",
        "province": "Carabobo",
        "timezone": "America/Caracas"
    },
    {
        "name": "Ciudad Guayana",
        "country": "Venezuela",
        "province": "Bolívar",
        "timezone": "America/Caracas"
    },
    {
        "name": "Maracaibo",
        "country": "Venezuela",
        "province": "Zulia",
        "timezone": "America/Caracas"
    },
    {
        "name": "Caracas",
        "country": "Venezuela",
        "province": "Distrito Capital",
        "timezone": "America/Caracas"
    },
    {
        "name": "Tay Ninh",
        "country": "Vietnam",
        "province": "Tây Ninh",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Luan Chau",
        "country": "Vietnam",
        "province": "Điện Biên",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Bac Kan",
        "country": "Vietnam",
        "province": "Bac Kan",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Lang Son",
        "country": "Vietnam",
        "province": "Lạng Sơn",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Son La",
        "country": "Vietnam",
        "province": "Son La",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Tuyen Quang",
        "country": "Vietnam",
        "province": "Tuyên Quang",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Yen Bai",
        "country": "Vietnam",
        "province": "Yên Bái",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Hai Duong",
        "country": "Vietnam",
        "province": "Hải Dương",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Thai Binh",
        "country": "Vietnam",
        "province": "Thái Bình",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Tuy Hoa",
        "country": "Vietnam",
        "province": "Phú Yên",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Thu Dau Mot",
        "country": "Vietnam",
        "province": "Bình Duong",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Dong Ha",
        "country": "Vietnam",
        "province": "Quảng Trị",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Cao Lanh",
        "country": "Vietnam",
        "province": "Đồng Tháp",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Truc Giang",
        "country": "Vietnam",
        "province": "Bến Tre",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Tra Vinh",
        "country": "Vietnam",
        "province": "Trà Vinh",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Vinh Long",
        "country": "Vietnam",
        "province": "Vinh Long",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Cao Bang",
        "country": "Vietnam",
        "province": "Cao Bằng",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Hong Gai",
        "country": "Vietnam",
        "province": "Quảng Ninh",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Cam Pha",
        "country": "Vietnam",
        "province": "Quảng Ninh",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Lao Chi",
        "country": "Vietnam",
        "province": "Lào Cai",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Hoa Binh",
        "country": "Vietnam",
        "province": "Hòa Bình",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Son Tay",
        "country": "Vietnam",
        "province": "Hà Tây",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Ninh Binh",
        "country": "Vietnam",
        "province": "Ninh Bình",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Viet Tri",
        "country": "Vietnam",
        "province": "Phú Thọ",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Bac Giang",
        "country": "Vietnam",
        "province": "Bắc Giang",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Ha Tinh",
        "country": "Vietnam",
        "province": "Ha Tinh",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Buon Me Thuot",
        "country": "Vietnam",
        "province": "Dak Lak",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Da Lat",
        "country": "Vietnam",
        "province": "Lâm Đồng",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Phan Rang",
        "country": "Vietnam",
        "province": "Ninh Thuận",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Hon Quan",
        "country": "Vietnam",
        "province": "Bình Phước",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Kon Tum",
        "country": "Vietnam",
        "province": "Kon Tum",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Quang Ngai",
        "country": "Vietnam",
        "province": "Quảng Ngãi",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Quang Tri",
        "country": "Vietnam",
        "province": "Quảng Trị",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Vung Tau",
        "country": "Vietnam",
        "province": "Bà Rịa–Vũng Tàu",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Phan Thiet",
        "country": "Vietnam",
        "province": "Bình Thuận",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Long Xuyen",
        "country": "Vietnam",
        "province": "An Giang",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Chau Doc",
        "country": "Vietnam",
        "province": "An Giang",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Rach Gia",
        "country": "Vietnam",
        "province": "Kiên Giang",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Tan An",
        "country": "Vietnam",
        "province": "Long An",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "My Tho",
        "country": "Vietnam",
        "province": "Tiền Giang",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Bac Lieu",
        "country": "Vietnam",
        "province": "Bạc Liêu",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Ca Mau",
        "country": "Vietnam",
        "province": "Cà Mau",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Soc Trang",
        "country": "Vietnam",
        "province": "Sóc Trang",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Ha Giang",
        "country": "Vietnam",
        "province": "Hà Giang",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Thai Nguyen",
        "country": "Vietnam",
        "province": "Thái Nguyên",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Thanh Hoa",
        "country": "Vietnam",
        "province": "Thanh Hóa",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Nam Dinh",
        "country": "Vietnam",
        "province": "Nam Định",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Vinh",
        "country": "Vietnam",
        "province": "Nghệ An",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Dong Hoi",
        "country": "Vietnam",
        "province": "Quảng Bình",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Play Ku",
        "country": "Vietnam",
        "province": "Gia Lai",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Nha Trang",
        "country": "Vietnam",
        "province": "Khánh Hòa",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Cam Ranh",
        "country": "Vietnam",
        "province": "Khánh Hòa",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Qui Nhon",
        "country": "Vietnam",
        "province": "Bình Định",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Hue",
        "country": "Vietnam",
        "province": "Thừa Thiên–Huế",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Bien Hoa",
        "country": "Vietnam",
        "province": "Đồng Nai",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Can Tho",
        "country": "Vietnam",
        "province": "Can Tho",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Haiphong",
        "country": "Vietnam",
        "province": "Quảng Ninh",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Da Nang",
        "country": "Vietnam",
        "province": "Da Nang",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Hanoi",
        "country": "Vietnam",
        "province": "Thái Nguyên",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Ho Chi Minh City",
        "country": "Vietnam",
        "province": "Ho Chi Minh City",
        "timezone": "Asia/Ho_Chi_Minh"
    },
    {
        "name": "Bir Lehlou",
        "country": "Western Sahara",
        "province": "",
        "timezone": "Africa/El_Aaiun"
    },
    {
        "name": "Al Bayda",
        "country": "Yemen",
        "province": "Al Bayda'",
        "timezone": "Asia/Aden"
    },
    {
        "name": "'Ataq",
        "country": "Yemen",
        "province": "Shabwah",
        "timezone": "Asia/Aden"
    },
    {
        "name": "Marib",
        "country": "Yemen",
        "province": "Ma'rib",
        "timezone": "Asia/Aden"
    },
    {
        "name": "Dhamar",
        "country": "Yemen",
        "province": "Dhamar",
        "timezone": "Asia/Aden"
    },
    {
        "name": "Ibb",
        "country": "Yemen",
        "province": "Ibb",
        "timezone": "Asia/Aden"
    },
    {
        "name": "Ash Shihr",
        "country": "Yemen",
        "province": "Hadramawt",
        "timezone": "Asia/Aden"
    },
    {
        "name": "Zabid",
        "country": "Yemen",
        "province": "Al Hudaydah",
        "timezone": "Asia/Aden"
    },
    {
        "name": "Hajjah",
        "country": "Yemen",
        "province": "Hajjah",
        "timezone": "Asia/Aden"
    },
    {
        "name": "Lahij",
        "country": "Yemen",
        "province": "Lahij",
        "timezone": "Asia/Aden"
    },
    {
        "name": "Al Ghaydah",
        "country": "Yemen",
        "province": "Al Mahrah",
        "timezone": "Asia/Aden"
    },
    {
        "name": "Rida",
        "country": "Yemen",
        "province": "Al Bayda'",
        "timezone": "Asia/Aden"
    },
    {
        "name": "Hadiboh",
        "country": "Yemen",
        "province": "Hadramawt",
        "timezone": "Asia/Aden"
    },
    {
        "name": "Saywun",
        "country": "Yemen",
        "province": "Hadramawt",
        "timezone": "Asia/Aden"
    },
    {
        "name": "Sadah",
        "country": "Yemen",
        "province": "Sa`dah",
        "timezone": "Asia/Aden"
    },
    {
        "name": "Al Hudaydah",
        "country": "Yemen",
        "province": "Al Hudaydah",
        "timezone": "Asia/Aden"
    },
    {
        "name": "Sayhut",
        "country": "Yemen",
        "province": "Al Mahrah",
        "timezone": "Asia/Aden"
    },
    {
        "name": "Al Mukalla",
        "country": "Yemen",
        "province": "Hadramawt",
        "timezone": "Asia/Aden"
    },
    {
        "name": "Taizz",
        "country": "Yemen",
        "province": "Ta`izz",
        "timezone": "Asia/Aden"
    },
    {
        "name": "Aden",
        "country": "Yemen",
        "province": "`Adan",
        "timezone": "Asia/Aden"
    },
    {
        "name": "Sanaa",
        "country": "Yemen",
        "province": "Amanat Al Asimah",
        "timezone": "Asia/Aden"
    },
    {
        "name": "Kawambwa",
        "country": "Zambia",
        "province": "Luapula",
        "timezone": "Africa/Lusaka"
    },
    {
        "name": "Nchelenge",
        "country": "Zambia",
        "province": "Luapula",
        "timezone": "Africa/Lusaka"
    },
    {
        "name": "Chinsali",
        "country": "Zambia",
        "province": "Northern",
        "timezone": "Africa/Lusaka"
    },
    {
        "name": "Kasama",
        "country": "Zambia",
        "province": "Northern",
        "timezone": "Africa/Lusaka"
    },
    {
        "name": "Kapiri Mposhi",
        "country": "Zambia",
        "province": "Central",
        "timezone": "Africa/Lusaka"
    },
    {
        "name": "Mumbwa",
        "country": "Zambia",
        "province": "Central",
        "timezone": "Africa/Lusaka"
    },
    {
        "name": "Chingola",
        "country": "Zambia",
        "province": "Copperbelt",
        "timezone": "Africa/Lusaka"
    },
    {
        "name": "Chililabombwe",
        "country": "Zambia",
        "province": "Copperbelt",
        "timezone": "Africa/Lusaka"
    },
    {
        "name": "Nyimba",
        "country": "Zambia",
        "province": "Eastern",
        "timezone": "Africa/Lusaka"
    },
    {
        "name": "Lundazi",
        "country": "Zambia",
        "province": "Eastern",
        "timezone": "Africa/Lusaka"
    },
    {
        "name": "Chipata",
        "country": "Zambia",
        "province": "Eastern",
        "timezone": "Africa/Lusaka"
    },
    {
        "name": "Mwinilunga",
        "country": "Zambia",
        "province": "North-Western",
        "timezone": "Africa/Lusaka"
    },
    {
        "name": "Kasempa",
        "country": "Zambia",
        "province": "North-Western",
        "timezone": "Africa/Lusaka"
    },
    {
        "name": "Solwezi",
        "country": "Zambia",
        "province": "North-Western",
        "timezone": "Africa/Lusaka"
    },
    {
        "name": "Choma",
        "country": "Zambia",
        "province": "Southern",
        "timezone": "Africa/Lusaka"
    },
    {
        "name": "Mongu",
        "country": "Zambia",
        "province": "Western",
        "timezone": "Africa/Lusaka"
    },
    {
        "name": "Kaoma",
        "country": "Zambia",
        "province": "Western",
        "timezone": "Africa/Lusaka"
    },
    {
        "name": "Sesheke",
        "country": "Zambia",
        "province": "Western",
        "timezone": "Africa/Lusaka"
    },
    {
        "name": "Lukulu",
        "country": "Zambia",
        "province": "Western",
        "timezone": "Africa/Lusaka"
    },
    {
        "name": "Kalabo",
        "country": "Zambia",
        "province": "Western",
        "timezone": "Africa/Lusaka"
    },
    {
        "name": "Senanga",
        "country": "Zambia",
        "province": "Western",
        "timezone": "Africa/Lusaka"
    },
    {
        "name": "Mansa",
        "country": "Zambia",
        "province": "Luapula",
        "timezone": "Africa/Lusaka"
    },
    {
        "name": "Mpika",
        "country": "Zambia",
        "province": "Northern",
        "timezone": "Africa/Lusaka"
    },
    {
        "name": "Mbala",
        "country": "Zambia",
        "province": "Northern",
        "timezone": "Africa/Lusaka"
    },
    {
        "name": "Luanshya",
        "country": "Zambia",
        "province": "Copperbelt",
        "timezone": "Africa/Lusaka"
    },
    {
        "name": "Ndola",
        "country": "Zambia",
        "province": "Copperbelt",
        "timezone": "Africa/Lusaka"
    },
    {
        "name": "Zambezi",
        "country": "Zambia",
        "province": "North-Western",
        "timezone": "Africa/Lusaka"
    },
    {
        "name": "Kafue",
        "country": "Zambia",
        "province": "Southern",
        "timezone": "Africa/Lusaka"
    },
    {
        "name": "Mazabuka",
        "country": "Zambia",
        "province": "Southern",
        "timezone": "Africa/Lusaka"
    },
    {
        "name": "Kabwe",
        "country": "Zambia",
        "province": "Central",
        "timezone": "Africa/Lusaka"
    },
    {
        "name": "Mufulira",
        "country": "Zambia",
        "province": "Copperbelt",
        "timezone": "Africa/Lusaka"
    },
    {
        "name": "Kitwe",
        "country": "Zambia",
        "province": "Copperbelt",
        "timezone": "Africa/Lusaka"
    },
    {
        "name": "Livingstone",
        "country": "Zambia",
        "province": "Southern",
        "timezone": "Africa/Lusaka"
    },
    {
        "name": "Lusaka",
        "country": "Zambia",
        "province": "Lusaka",
        "timezone": "Africa/Lusaka"
    },
    {
        "name": "Mazowe",
        "country": "Zimbabwe",
        "province": "Mashonaland Central",
        "timezone": "Africa/Harare"
    },
    {
        "name": "Shamva",
        "country": "Zimbabwe",
        "province": "Mashonaland Central",
        "timezone": "Africa/Harare"
    },
    {
        "name": "Victoria Falls",
        "country": "Zimbabwe",
        "province": "Matabeleland North",
        "timezone": "Africa/Harare"
    },
    {
        "name": "Zvishavane",
        "country": "Zimbabwe",
        "province": "Midlands",
        "timezone": "Africa/Harare"
    },
    {
        "name": "Kwekwe",
        "country": "Zimbabwe",
        "province": "Midlands",
        "timezone": "Africa/Harare"
    },
    {
        "name": "Plumtree",
        "country": "Zimbabwe",
        "province": "Matabeleland South",
        "timezone": "Africa/Harare"
    },
    {
        "name": "Beitbridge",
        "country": "Zimbabwe",
        "province": "Matabeleland South",
        "timezone": "Africa/Harare"
    },
    {
        "name": "Gwanda",
        "country": "Zimbabwe",
        "province": "Matabeleland South",
        "timezone": "Africa/Harare"
    },
    {
        "name": "Chiredzi",
        "country": "Zimbabwe",
        "province": "Masvingo",
        "timezone": "Africa/Harare"
    },
    {
        "name": "Masvingo",
        "country": "Zimbabwe",
        "province": "Masvingo",
        "timezone": "Africa/Harare"
    },
    {
        "name": "Karoi",
        "country": "Zimbabwe",
        "province": "Mashonaland West",
        "timezone": "Africa/Harare"
    },
    {
        "name": "Chinhoyi",
        "country": "Zimbabwe",
        "province": "Mashonaland West",
        "timezone": "Africa/Harare"
    },
    {
        "name": "Kariba",
        "country": "Zimbabwe",
        "province": "Mashonaland West",
        "timezone": "Africa/Harare"
    },
    {
        "name": "Hwange",
        "country": "Zimbabwe",
        "province": "Matabeleland North",
        "timezone": "Africa/Harare"
    },
    {
        "name": "Gweru",
        "country": "Zimbabwe",
        "province": "Midlands",
        "timezone": "Africa/Harare"
    },
    {
        "name": "Mutare",
        "country": "Zimbabwe",
        "province": "Manicaland",
        "timezone": "Africa/Harare"
    },
    {
        "name": "Kadoma",
        "country": "Zimbabwe",
        "province": "Mashonaland West",
        "timezone": "Africa/Harare"
    },
    {
        "name": "Chitungwiza",
        "country": "Zimbabwe",
        "province": "Harare",
        "timezone": "Africa/Harare"
    },
    {
        "name": "Harare",
        "country": "Zimbabwe",
        "province": "Harare",
        "timezone": "Africa/Harare"
    },
    {
        "name": "Bulawayo",
        "country": "Zimbabwe",
        "province": "Bulawayo",
        "timezone": "Africa/Harare"
    },
    {
        "name": "Copenhagen",
        "country": "Denmark",
        "province": "Hovedstaden",
        "timezone": "Europe/Copenhagen"
    },
    {
        "name": "Oakleigh",
        "country": "Australia",
        "province": "Victoria",
        "timezone": "Australia/Melbourne"
    },
    {
        "name": "Oak Park",
        "country": "United States of America",
        "timezone": "America/Chicago"
    }
]

export const agencyCategory = [
    {
        label: "Search engine optimization (SEO)",
        value: "Search engine optimization (SEO)"
    },
    { label: "Social media agencies", value: "Social media agencies" },
    { label: "Digital Marketing", value: "Digital Marketing" },
    { label: "Agency Types", value: "Agency Types" },
    { label: "Paid Ads Marketing", value: "Paid Ads Marketing" },
    { label: "Lead Generation", value: "Lead Generation" },
    { label: "Video production", value: "Video production" },
    { label: "Content marketing", value: "Content marketing" },
    { label: "Influencer marketing", value: "Influencer marketing" },
    { label: "Email marketing", value: "Email marketing" },
    { label: "Web design", value: "Web design" },
    { label: "Affiliate marketing", value: "Affiliate marketing" },
    { label: "Graphic design", value: "Graphic design" },
    { label: "Others", value: "Others" },
    { label: "Branding", value: "Branding" },
    { label: "Public relations", value: "Public relations" },
    { label: "Event planning", value: "Event planning" },
    { label: "Recruitment", value: "Recruitment" },
    { label: "Mobile Marketing", value: "Mobile Marketing" },
    { label: "Public Affairs", value: "Public Affairs" },
    { label: "Integrated marketing", value: "Integrated marketing" },
    { label: "Market research", value: "Market research" },
    { label: "Coaching", value: "Coaching" },
    { label: "Talent", value: "Talent" },
    { label: "Interior design", value: "Interior design" },
    { label: "Travel", value: "Travel" },
    { label: "Legal", value: "Legal" },
    { label: "Accounting", value: "Accounting" },
    { label: "Architecture", value: "Architecture" },
    { label: "Translation", value: "Translation" },
    { label: "Environmental consulting", value: "Environmental consulting" },
    { label: "industry", value: "industry" },
    { label: "industry", value: "construction" },
    { label: "design", value: "design" },
    { label: "it", value: "iT" },
    { label: "automobile", value: "automobile" },
    { label: "education", value: "education" }
];

export const defaultUserRole = Object.freeze({
    "role": 2,
    "pjt": [1],
    "cli": [1],
    "dash": [1],
    "profile": [1],
    "cHub": [1],
    "boards": [1],
    "type": "emp",
})

export const URL_REGEX = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/

export const MAX_FILE_UPLOAD_SIZE = 20161520 //20 MB

export const REPEAT_OPTIONS = [
    { value: "1", label: "Daily" },
    { value: "2", label: "Weekly" },
    { value: "3", label: "Monthly" },
    { value: "4", label: "Yearly" }
]

export const DAY_OPTIONS = [
    { value: "1", label: "Sun" },
    { value: "2", label: "Mon" },
    { value: "3", label: "Tue" },
    { value: "4", label: "Wed" },
    { value: "5", label: "Thu" },
    { value: "6", label: "Fri" },
    { value: "7", label: "Sat" },
]

export const WEEK_OPTIONS = [
    { value: "1", label: "1st" },
    { value: "2", label: "2nd" },
    { value: "3", label: "3rd" },
    { value: "4", label: "4th" },
    { value: "5", label: "5th" },
    { value: "6", label: "Last" },
]

export const TASK_WEEK_OPTIONS = [
    { value: "1", label: "1st" },
    { value: "2", label: "2nd" },
    { value: "3", label: "3rd" },
    { value: "4", label: "4th" },
    { value: "5", label: "5th" },
    { value: "6", label: "Last" },
]

export const MONTH_OPTIONS = [
    { value: "1", label: "Jan" },
    { value: "2", label: "Feb" },
    { value: "3", label: "Mar" },
    { value: "4", label: "Apr" },
    { value: "5", label: "May" },
    { value: "6", label: "Jun" },
    { value: "7", label: "Jul" },
    { value: "8", label: "Aug" },
    { value: "9", label: "Sep" },
    { value: "10", label: "Oct" },
    { value: "11", label: "Nov" },
    { value: "12", label: "Dec" },
]

export const END_TYPES = [
    { "value": "1", "label": "Never" },
    { "value": "2", "label": "On" },
    { "value": "3", "label": "After" }
]

export const MONTH=[
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ]

  export const TIME_ZONE_OPTIONS = [
    {
        "label": "DST",
        "value": "DST",
        "offset": -12
    },
    {
        "label": "U",
        "value": "U",
        "offset": -11
    },
    {
        "label": "HST",
        "value": "HST",
        "offset": -10
    },
    {
        "label": "AKDT",
        "value": "AKDT",
        "offset": -8
    },
    {
        "label": "PDT",
        "value": "PDT",
        "offset": -7
    },
    {
        "label": "PST",
        "value": "PST",
        "offset": -8
    },
    {
        "label": "UMST",
        "value": "UMST",
        "offset": -7
    },
    {
        "label": "MDT",
        "value": "MDT",
        "offset": -6
    },
    {
        "label": "CAST",
        "value": "CAST",
        "offset": -6
    },
    {
        "label": "CDT",
        "value": "CDT",
        "offset": -5
    },
    {
        "label": "CCST",
        "value": "CCST",
        "offset": -6
    },
    {
        "label": "SPST",
        "value": "SPST",
        "offset": -5
    },
    {
        "label": "EST",
        "value": "EST",
        "offset": -5
    },
    {
        "label": "EDT",
        "value": "EDT",
        "offset": -4
    },
    {
        "label": "UEDT",
        "value": "UEDT",
        "offset": -5
    },
    {
        "label": "VST",
        "value": "VST",
        "offset": -4.5
    },
    {
        "label": "PYT",
        "value": "PYT",
        "offset": -4
    },
    {
        "label": "ADT",
        "value": "ADT",
        "offset": -3
    },
    {
        "label": "CBST",
        "value": "CBST",
        "offset": -4
    },
    {
        "label": "SWST",
        "value": "SWST",
        "offset": -4
    },
    {
        "label": "PSST",
        "value": "PSST",
        "offset": -4
    },
    {
        "label": "NDT",
        "value": "NDT",
        "offset": -2.5
    },
    {
        "label": "ESAST",
        "value": "ESAST",
        "offset": -3
    },
    {
        "label": "AST",
        "value": "AST",
        "offset": -3
    },
    {
        "label": "SEST",
        "value": "SEST",
        "offset": -3
    },
    {
        "label": "GDT",
        "value": "GDT",
        "offset": -3
    },
    {
        "label": "MST",
        "value": "MST",
        "offset": -3
    },
    {
        "label": "BST",
        "value": "BST",
        "offset": -3
    },
    {
        "label": "U",
        "value": "U",
        "offset": -2
    },
    {
        "label": "MDT",
        "value": "MDT",
        "offset": -1
    },
    {
        "label": "ADT",
        "value": "ADT",
        "offset": 0
    },
    {
        "label": "CVST",
        "value": "CVST",
        "offset": -1
    },
    {
        "label": "MDT",
        "value": "MDT",
        "offset": 1
    },
    {
        "label": "UTC",
        "value": "UTC",
        "offset": 0
    },
    {
        "label": "GMT",
        "value": "GMT",
        "offset": 0
    },
    {
        "label": "BST",
        "value": "BST",
        "offset": 1
    },
    {
        "label": "GDT",
        "value": "GDT",
        "offset": 1
    },
    {
        "label": "GST",
        "value": "GST",
        "offset": 0
    },
    {
        "label": "WEDT",
        "value": "WEDT",
        "offset": 2
    },
    {
        "label": "CEDT",
        "value": "CEDT",
        "offset": 2
    },
    {
        "label": "RDT",
        "value": "RDT",
        "offset": 2
    },
    {
        "label": "CEDT",
        "value": "CEDT",
        "offset": 2
    },
    {
        "label": "WCAST",
        "value": "WCAST",
        "offset": 1
    },
    {
        "label": "NST",
        "value": "NST",
        "offset": 1
    },
    {
        "label": "GDT",
        "value": "GDT",
        "offset": 3
    },
    {
        "label": "MEDT",
        "value": "MEDT",
        "offset": 3
    },
    {
        "label": "EST",
        "value": "EST",
        "offset": 2
    },
    {
        "label": "SDT",
        "value": "SDT",
        "offset": 3
    },
    {
        "label": "EEDT",
        "value": "EEDT",
        "offset": 3
    },
    {
        "label": "SAST",
        "value": "SAST",
        "offset": 2
    },
    {
        "label": "FDT",
        "value": "FDT",
        "offset": 3
    },
    {
        "label": "TDT",
        "value": "TDT",
        "offset": 3
    },
    {
        "label": "JDT",
        "value": "JDT",
        "offset": 3
    },
    {
        "label": "LST",
        "value": "LST",
        "offset": 2
    },
    {
        "label": "JST",
        "value": "JST",
        "offset": 3
    },
    {
        "label": "AST",
        "value": "AST",
        "offset": 3
    },
    {
        "label": "KST",
        "value": "KST",
        "offset": 3
    },
    {
        "label": "AST",
        "value": "AST",
        "offset": 3
    },
    {
        "label": "EAST",
        "value": "EAST",
        "offset": 3
    },
    {
        "label": "MSK",
        "value": "MSK",
        "offset": 3
    },
    {
        "label": "SAMT",
        "value": "SAMT",
        "offset": 4
    },
    {
        "label": "IDT",
        "value": "IDT",
        "offset": 4.5
    },
    {
        "label": "AST",
        "value": "AST",
        "offset": 4
    },
    {
        "label": "ADT",
        "value": "ADT",
        "offset": 5
    },
    {
        "label": "MST",
        "value": "MST",
        "offset": 4
    },
    {
        "label": "GET",
        "value": "GET",
        "offset": 4
    },
    {
        "label": "CST",
        "value": "CST",
        "offset": 4
    },
    {
        "label": "AST",
        "value": "AST",
        "offset": 4.5
    },
    {
        "label": "WAST",
        "value": "WAST",
        "offset": 5
    },
    {
        "label": "YEKT",
        "value": "YEKT",
        "offset": 5
    },
    {
        "label": "PKT",
        "value": "PKT",
        "offset": 5
    },
    {
        "label": "IST",
        "value": "IST",
        "offset": 5.5
    },
    {
        "label": "SLST",
        "value": "SLST",
        "offset": 5.5
    },
    {
        "label": "NST",
        "value": "NST",
        "offset": 5.75
    },
    {
        "label": "CAST",
        "value": "CAST",
        "offset": 6
    },
    {
        "label": "BST",
        "value": "BST",
        "offset": 6
    },
    {
        "label": "MST",
        "value": "MST",
        "offset": 6.5
    },
    {
        "label": "SAST",
        "value": "SAST",
        "offset": 7
    },
    {
        "label": "NCAST",
        "value": "NCAST",
        "offset": 7
    },
    {
        "label": "CST",
        "value": "CST",
        "offset": 8
    },
    {
        "label": "NAST",
        "value": "NAST",
        "offset": 8
    },
    {
        "label": "MPST",
        "value": "MPST",
        "offset": 8
    },
    {
        "label": "WAST",
        "value": "WAST",
        "offset": 8
    },
    {
        "label": "TST",
        "value": "TST",
        "offset": 8
    },
    {
        "label": "UST",
        "value": "UST",
        "offset": 8
    },
    {
        "label": "NAEST",
        "value": "NAEST",
        "offset": 8
    },
    {
        "label": "JST",
        "value": "JST",
        "offset": 9
    },
    {
        "label": "KST",
        "value": "KST",
        "offset": 9
    },
    {
        "label": "CAST",
        "value": "CAST",
        "offset": 9.5
    },
    {
        "label": "ACST",
        "value": "ACST",
        "offset": 9.5
    },
    {
        "label": "EAST",
        "value": "EAST",
        "offset": 10
    },
    {
        "label": "AEST",
        "value": "AEST",
        "offset": 10
    },
    {
        "label": "WPST",
        "value": "WPST",
        "offset": 10
    },
    {
        "label": "TST",
        "value": "TST",
        "offset": 10
    },
    {
        "label": "YST",
        "value": "YST",
        "offset": 9
    },
    {
        "label": "CPST",
        "value": "CPST",
        "offset": 11
    },
    {
        "label": "VST",
        "value": "VST",
        "offset": 11
    },
    {
        "label": "NZST",
        "value": "NZST",
        "offset": 12
    },
    {
        "label": "U",
        "value": "U",
        "offset": 12
    },
    {
        "label": "FST",
        "value": "FST",
        "offset": 12
    },
    {
        "label": "MST",
        "value": "MST",
        "offset": 12
    },
    {
        "label": "KDT",
        "value": "KDT",
        "offset": 13
    },
    {
        "label": "TST",
        "value": "TST",
        "offset": 13
    },
    {
        "label": "SST",
        "value": "SST",
        "offset": 13
    }
  ]

  export const FUNNEL_STEP_TYPES = {
    PROPOSAL: {
      label: "Proposal",
      step_type: "PROPOSAL",
    },
    CONTRACT: {
      label: "Contract",
      step_type: "CONTRACT",
    },
    INVOICE: {
      label: "Invoice",
      step_type: "INVOICE",
    },
    FLOZY_SPACE: {
      label: "Flozy space",
      step_type: "FLOZY_SPACE",
    },
    WELCOME_VIDEO: {
      label: "Welcome Video",
      step_type: "WELCOME_VIDEO",
    },
    PAGE: {
      label: "Page",
      step_type: "PAGE",
    },

    FORMS: {
      label: "Forms",
      step_type: "FORMS",
    },

    CALENDAR_BOOKING: {
      label: "Calendar Booking",
      step_type: "CALENDAR_BOOKING",
    },

    // SIGN_UP: {
    //     label: "Sign up",
    //     step_type: "SIGN_UP"
    // }
  };
  
export const URL_PROTOCOL_REGEX = '^(http|https):\/\/';
export const ScheduleManagement = {
    SCHEDULE_MANAGEMENT: 'Schedule Management',
    SCHEDULE_MESSAGE: 'Schedule Message',
    CHAT_WORKFLOW: 'Chat Workflow',
    ADD_MESSAGE:'Add Message',
    ADD_MESSAGE_TITLE_PLACEHOLDER:'Enter title for this schedule message...',
    ADD_MESSAGE_PLACEHOLDER:'Write your message...',
    MESSAGE: 'Message',
    DATE_ERROR: 'Start date must be less than end date'
}

export const TIME_OPTIONS = [
    { label: "12:00 AM", value: "12:00 AM", filter: "12 AM", time: 0 },
    { label: "12:30 AM", value: "12:30 AM", filter: "12 AM", time: 30 },
    { label: "01:00 AM", value: "01:00 AM", filter: "01 AM", time: 0 },
    { label: "01:30 AM", value: "01:30 AM", filter: "01 AM", time: 30 },
    { label: "02:00 AM", value: "02:00 AM", filter: "02 AM", time: 0 },
    { label: "02:30 AM", value: "02:30 AM", filter: "02 AM", time: 30 },
    { label: "03:00 AM", value: "03:00 AM", filter: "03 AM", time: 0 },
    { label: "03:30 AM", value: "03:30 AM", filter: "03 AM", time: 30 },
    { label: "04:00 AM", value: "04:00 AM", filter: "04 AM", time: 0 },
    { label: "04:30 AM", value: "04:30 AM", filter: "04 AM", time: 30 },
    { label: "05:00 AM", value: "05:00 AM", filter: "05 AM", time: 0 },
    { label: "05:30 AM", value: "05:30 AM", filter: "05 AM", time: 30 },
    { label: "06:00 AM", value: "06:00 AM", filter: "06 AM", time: 0 },
    { label: "06:30 AM", value: "06:30 AM", filter: "06 AM", time: 30 },
    { label: "07:00 AM", value: "07:00 AM", filter: "07 AM", time: 0 },
    { label: "07:30 AM", value: "07:30 AM", filter: "07 AM", time: 30 },
    { label: "08:00 AM", value: "08:00 AM", filter: "08 AM", time: 0 },
    { label: "08:30 AM", value: "08:30 AM", filter: "08 AM", time: 30 },
    { label: "09:00 AM", value: "09:00 AM", filter: "09 AM", time: 0 },
    { label: "09:30 AM", value: "09:30 AM", filter: "09 AM", time: 30 },
    { label: "10:00 AM", value: "10:00 AM", filter: "10 AM", time: 0 },
    { label: "10:30 AM", value: "10:30 AM", filter: "10 AM", time: 30 },
    { label: "11:00 AM", value: "11:00 AM", filter: "11 AM", time: 0 },
    { label: "11:30 AM", value: "11:30 AM", filter: "11 AM", time: 30 },
    { label: "12:00 PM", value: "12:00 PM", filter: "12 PM", time: 0 },
    { label: "12:30 PM", value: "12:30 PM", filter: "12 PM", time: 30 },
    { label: "01:00 PM", value: "01:00 PM", filter: "01 PM", time: 0 },
    { label: "01:30 PM", value: "01:30 PM", filter: "01 PM", time: 30 },
    { label: "02:00 PM", value: "02:00 PM", filter: "02 PM", time: 0 },
    { label: "02:30 PM", value: "02:30 PM", filter: "02 PM", time: 30 },
    { label: "03:00 PM", value: "03:00 PM", filter: "03 PM", time: 0 },
    { label: "03:30 PM", value: "03:30 PM", filter: "03 PM", time: 30 },
    { label: "04:00 PM", value: "04:00 PM", filter: "04 PM", time: 0 },
    { label: "04:30 PM", value: "04:30 PM", filter: "04 PM", time: 30 },
    { label: "05:00 PM", value: "05:00 PM", filter: "05 PM", time: 0 },
    { label: "05:30 PM", value: "05:30 PM", filter: "05 PM", time: 30 },
    { label: "06:00 PM", value: "06:00 PM", filter: "06 PM", time: 0 },
    { label: "06:30 PM", value: "06:30 PM", filter: "06 PM", time: 30 },
    { label: "07:00 PM", value: "07:00 PM", filter: "07 PM", time: 0 },
    { label: "07:30 PM", value: "07:30 PM", filter: "07 PM", time: 30 },
    { label: "08:00 PM", value: "08:00 PM", filter: "08 PM", time: 0 },
    { label: "08:30 PM", value: "08:30 PM", filter: "08 PM", time: 30 },
    { label: "09:00 PM", value: "09:00 PM", filter: "09 PM", time: 0 },
    { label: "09:30 PM", value: "09:30 PM", filter: "09 PM", time: 30 },
    { label: "10:00 PM", value: "10:00 PM", filter: "10 PM", time: 0 },
    { label: "10:30 PM", value: "10:30 PM", filter: "10 PM", time: 30 },
    { label: "11:00 PM", value: "11:00 PM", filter: "11 PM", time: 0 },
    { label: "11:30 PM", value: "11:30 PM", filter: "11 PM", time: 30 },
]

export const TIME_OPTIONS_EVENT = [
    { label: "12:00 AM", value: "12:00 AM", filter: "12 AM", time: 0, },
    { label: "12:15 AM", value: "12:15 AM", filter: "12 AM", time: 15, },
    { label: "12:30 AM", value: "12:30 AM", filter: "12 AM", time: 30, },
    { label: "12:45 AM", value: "12:45 AM", filter: "12 AM", time: 45, },
    { label: "01:00 AM", value: "01:00 AM", filter: "01 AM", time: 0, },
    { label: "01:15 AM", value: "01:15 AM", filter: "01 AM", time: 15, },
    { label: "01:30 AM", value: "01:30 AM", filter: "01 AM", time: 30, },
    { label: "01:45 AM", value: "01:45 AM", filter: "01 AM", time: 45, },
    { label: "02:00 AM", value: "02:00 AM", filter: "02 AM", time: 0, },
    { label: "02:15 AM", value: "02:15 AM", filter: "02 AM", time: 15, },
    { label: "02:30 AM", value: "02:30 AM", filter: "02 AM", time: 30, },
    { label: "02:45 AM", value: "02:45 AM", filter: "02 AM", time: 45, },
    { label: "03:00 AM", value: "03:00 AM", filter: "03 AM", time: 0, },
    { label: "03:15 AM", value: "03:15 AM", filter: "03 AM", time: 15, },
    { label: "03:30 AM", value: "03:30 AM", filter: "03 AM", time: 30, },
    { label: "03:45 AM", value: "03:45 AM", filter: "03 AM", time: 45, },
    { label: "04:00 AM", value: "04:00 AM", filter: "04 AM", time: 0, },
    { label: "04:15 AM", value: "04:15 AM", filter: "04 AM", time: 15, },
    { label: "04:30 AM", value: "04:30 AM", filter: "04 AM", time: 30, },
    { label: "04:45 AM", value: "04:45 AM", filter: "04 AM", time: 45, },
    { label: "05:00 AM", value: "05:00 AM", filter: "05 AM", time: 0, },
    { label: "05:15 AM", value: "05:15 AM", filter: "05 AM", time: 15, },
    { label: "05:30 AM", value: "05:30 AM", filter: "05 AM", time: 30, },
    { label: "05:45 AM", value: "05:45 AM", filter: "05 AM", time: 45, },
    { label: "06:00 AM", value: "06:00 AM", filter: "06 AM", time: 0, },
    { label: "06:15 AM", value: "06:15 AM", filter: "06 AM", time: 15, },
    { label: "06:30 AM", value: "06:30 AM", filter: "06 AM", time: 30, },
    { label: "06:45 AM", value: "06:45 AM", filter: "06 AM", time: 45, },
    { label: "07:00 AM", value: "07:00 AM", filter: "07 AM", time: 0, },
    { label: "07:15 AM", value: "07:15 AM", filter: "07 AM", time: 15, },
    { label: "07:30 AM", value: "07:30 AM", filter: "07 AM", time: 30, },
    { label: "07:45 AM", value: "07:45 AM", filter: "07 AM", time: 45, },
    { label: "08:00 AM", value: "08:00 AM", filter: "08 AM", time: 0, },
    { label: "08:15 AM", value: "08:15 AM", filter: "08 AM", time: 15, },
    { label: "08:30 AM", value: "08:30 AM", filter: "08 AM", time: 30, },
    { label: "08:45 AM", value: "08:45 AM", filter: "08 AM", time: 45, },
    { label: "09:00 AM", value: "09:00 AM", filter: "09 AM", time: 0, },
    { label: "09:15 AM", value: "09:15 AM", filter: "09 AM", time: 15, },
    { label: "09:30 AM", value: "09:30 AM", filter: "09 AM", time: 30, },
    { label: "09:45 AM", value: "09:45 AM", filter: "09 AM", time: 45, },
    { label: "10:00 AM", value: "10:00 AM", filter: "10 AM", time: 0, },
    { label: "10:15 AM", value: "10:15 AM", filter: "10 AM", time: 15, },
    { label: "10:30 AM", value: "10:30 AM", filter: "10 AM", time: 30, },
    { label: "10:45 AM", value: "10:45 AM", filter: "10 AM", time: 45, },
    { label: "11:00 AM", value: "11:00 AM", filter: "11 AM", time: 0, },
    { label: "11:15 AM", value: "11:15 AM", filter: "11 AM", time: 15, },
    { label: "11:30 AM", value: "11:30 AM", filter: "11 AM", time: 30, },
    { label: "11:45 AM", value: "11:45 AM", filter: "11 AM", time: 45, },
    { label: "12:00 PM", value: "12:00 PM", filter: "12 PM", time: 0, },
    { label: "12:15 PM", value: "12:15 PM", filter: "12 PM", time: 15, },
    { label: "12:30 PM", value: "12:30 PM", filter: "12 PM", time: 30, },
    { label: "12:45 PM", value: "12:45 PM", filter: "12 PM", time: 45, },
    { label: "01:00 PM", value: "01:00 PM", filter: "01 PM", time: 0, },
    { label: "01:15 PM", value: "01:15 PM", filter: "01 PM", time: 15, },
    { label: "01:30 PM", value: "01:30 PM", filter: "01 PM", time: 30, },
    { label: "01:45 PM", value: "01:45 PM", filter: "01 PM", time: 45, },
    { label: "02:00 PM", value: "02:00 PM", filter: "02 PM", time: 0, },
    { label: "02:15 PM", value: "02:15 PM", filter: "02 PM", time: 15, },
    { label: "02:30 PM", value: "02:30 PM", filter: "02 PM", time: 30, },
    { label: "02:45 PM", value: "02:45 PM", filter: "02 PM", time: 45, },
    { label: "03:00 PM", value: "03:00 PM", filter: "03 PM", time: 0, },
    { label: "03:15 PM", value: "03:15 PM", filter: "03 PM", time: 15, },
    { label: "03:30 PM", value: "03:30 PM", filter: "03 PM", time: 30, },
    { label: "03:45 PM", value: "03:45 PM", filter: "03 PM", time: 45, },
    { label: "04:00 PM", value: "04:00 PM", filter: "04 PM", time: 0, },
    { label: "04:15 PM", value: "04:15 PM", filter: "04 PM", time: 15, },
    { label: "04:30 PM", value: "04:30 PM", filter: "04 PM", time: 30, },
    { label: "04:45 PM", value: "04:45 PM", filter: "04 PM", time: 45, },
    { label: "05:00 PM", value: "05:00 PM", filter: "05 PM", time: 0, },
    { label: "05:15 PM", value: "05:15 PM", filter: "05 PM", time: 15, },
    { label: "05:30 PM", value: "05:30 PM", filter: "05 PM", time: 30, },
    { label: "05:45 PM", value: "05:45 PM", filter: "05 PM", time: 45, },
    { label: "06:00 PM", value: "06:00 PM", filter: "06 PM", time: 0, },
    { label: "06:15 PM", value: "06:15 PM", filter: "06 PM", time: 15, },
    { label: "06:30 PM", value: "06:30 PM", filter: "06 PM", time: 30, },
    { label: "06:45 PM", value: "06:45 PM", filter: "06 PM", time: 45, },
    { label: "07:00 PM", value: "07:00 PM", filter: "07 PM", time: 0, },
    { label: "07:15 PM", value: "07:15 PM", filter: "07 PM", time: 15, },
    { label: "07:30 PM", value: "07:30 PM", filter: "07 PM", time: 30, },
    { label: "07:45 PM", value: "07:45 PM", filter: "07 PM", time: 45, },
    { label: "08:00 PM", value: "08:00 PM", filter: "08 PM", time: 0, },
    { label: "08:15 PM", value: "08:15 PM", filter: "08 PM", time: 15, },
    { label: "08:30 PM", value: "08:30 PM", filter: "08 PM", time: 30, },
    { label: "08:45 PM", value: "08:45 PM", filter: "08 PM", time: 45, },
    { label: "09:00 PM", value: "09:00 PM", filter: "09 PM", time: 0, },
    { label: "09:15 PM", value: "09:15 PM", filter: "09 PM", time: 15, },
    { label: "09:30 PM", value: "09:30 PM", filter: "09 PM", time: 30, },
    { label: "09:45 PM", value: "09:45 PM", filter: "09 PM", time: 45, },
    { label: "10:00 PM", value: "10:00 PM", filter: "10 PM", time: 0, },
    { label: "10:15 PM", value: "10:15 PM", filter: "10 PM", time: 15, },
    { label: "10:30 PM", value: "10:30 PM", filter: "10 PM", time: 30, },
    { label: "10:45 PM", value: "10:45 PM", filter: "10 PM", time: 45, },
    { label: "11:00 PM", value: "11:00 PM", filter: "11 PM", time: 0, },
    { label: "11:15 PM", value: "11:15 PM", filter: "11 PM", time: 15, },
    { label: "11:30 PM", value: "11:30 PM", filter: "11 PM", time: 30, },
    { label: "11:45 PM", value: "11:45 PM", filter: "11 PM", time: 45, },
]

export const emailTemplates = {
    template1: "https://sweetp-user-uploads.s3.eu-west-2.amazonaws.com/stage/1706678900887_template1.png",
    template2: "https://sweetp-user-uploads.s3.eu-west-2.amazonaws.com/stage/1706678963297_template2.png",
    templateDark1: "https://sweetp-user-uploads.s3.eu-west-2.amazonaws.com/stage%20/%20A4%20-%2065emailTempdark1.png",
    templateDark2: "https://sweetp-user-uploads.s3.eu-west-2.amazonaws.com/stage%20/%20A4%20-%2066emailTempdark2.png"
  };
 
export const BOOKING_EVENT_OPTIONS = [
    { value: "1", label: "One-on-One", icon: <EventOnetoOneIcon /> },
    // { value: "2", label: "Group Meeting" }
]

export const APPOINTMENT_ONLINE_OPTIONS = [
    { value: "1", label: "Google Meet" },
    { value: "2", label: "Flozy" }
]

  export const flozySpaces = {
    flozySpacetemplate1: "https://sweetp-user-uploads.s3.eu-west-2.amazonaws.com/stage%20/%20rr.png",
    flozySpacetemplate2: "https://sweetp-user-uploads.s3.eu-west-2.amazonaws.com/stage/22995/FLOWS/39742/Group%201707481441%20%283%29_1718348732443.png",
    flozySpaceDarkTemplate1:"https://sweetp-user-uploads.s3.eu-west-2.amazonaws.com/stage/22995/FLOWS/39030/DarkEmailTemplate1.png_1715153063392",
    flozySpaceDarkTemplate2:"https://sweetp-user-uploads.s3.eu-west-2.amazonaws.com/stage/22995/FLOWS/39030/DarkEmailTemplate2.jpg_1715153063395",
    flozySpacetemplatemobile2: "https://sweetp-user-uploads.s3.eu-west-2.amazonaws.com/stage/1162/FLOWS/5623/new%20mobile%20tem_1725261671396.png",
    flozySpacetemplatemobileDark2:"https://sweetp-user-uploads.s3.eu-west-2.amazonaws.com/stage/1162/FLOWS/5623/darktemplatemob_1725261775309.png"
  };


export const APPOINTMENT_PHONE_OPTIONS = [
    { value: "1", label: "I'll reach out to my invitee (Get user's contact number)" },
    { value: "2", label: "Invitee should call me (Provide my contact number)" }
]

export const BOOKING_MINUTES = [
    { value: "10", label: "10 minutes" },
    { value: "15", label: "15 minutes" },
    { value: "30", label: "30 minutes" },
    { value: "45", label: "45 minutes" },
    { value: "60", label: "60 minutes" },
    { value: "90", label: "90 minutes" },
    { value: "120", label: "120 minutes" }
]

export const TIME_OPTION = [
    { value: "1", label: "Minutes" },
    { value: "2", label: "Hour" },
    { value: "3", label: "Days" }
]

export const TIME_OPTIONS_1 = [
    { value: "1", label: "mins" },
    { value: "2", label: "hrs" },
    { value: "3", label: "days" }
]

export const DAY_OPTION = [
    { value: "1", label: "Days" },
    { value: "2", label: "Months" }
]

export const DAILY_LIMIT = [
    { value: "0", label: "No Limit" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" }
]

export const DAY_LIMIT = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
    { value: "13", label: "13" },
    { value: "14", label: "14" },
    { value: "15", label: "15" },
    { value: "16", label: "16" },
    { value: "17", label: "17" },
    { value: "18", label: "18" },
    { value: "19", label: "19" },
    { value: "20", label: "20" },
    { value: "21", label: "21" },
    { value: "22", label: "22" },
    { value: "23", label: "23" },
    { value: "24", label: "24" },
    { value: "25", label: "25" },
    { value: "26", label: "26" },
    { value: "27", label: "27" },
    { value: "28", label: "28" },
    { value: "29", label: "29" },
    { value: "30", label: "30" }
]

export const MODE_OPTIONS = [
    {value:1,label:'Web Conference',
    options:[
    {value:1, label:'Google Meet',logo:<GooglemeetIcon/>},
    // {value:2, label:'Flozy Room',logo:<FlozyLogo height={20} width={20}/>},
    {value:3, label:'Custom Meet',logo:<CustommeetIcon />},
    {value:4, label:'Zoom Meet',logo:<ZoomAppIconSmall height={20} width={20}/>},
    ]},
    {value:2,label:'Phone Call'},
    {value:3,label:'In Person Meeting'},
]

export const STATUS_OPTIONS = [
    { value: 1, label: "No Show" },
    { value: 2, label: "Done" },
    { value: 3, label: "Cancelled" },
    { value: 4, label: "Blocked" },
    { value: 0, label: "Confirmed" },
]

export const EVENT_TYPE=[
    {value:1,label:'One-One'},
    {value:2,label:'One-Group'}
]

export const EMAIL_SERVICE_PROVIDER = [
    { value: "gmail", label: "Gmail", Icon: GmailIcon },
    { value: "outlook", label: "Outlook", Icon: OutlookIcon },
    // { value: "godaddy", label: "Godaddy", Icon: GodaddyIcon },
    // { value: "yahoo", label: "", Icon: YahooIcon },
    { value: "other", label: "Other" },
]

export const EMAIL_SERVICE_PROVIDER_OAUTH = [
    { value: "gmail", label: "Gmail", Icon: GmailIcon },
]

export const MAP_BOARD_FIELDS = [
    { value: "list", label: "List *", required: true },
    { value: "task_title", label: "Task title *", required: true },
    { value: "created_on", label: "Created on (YYYY-MM-DD)", type: "date" },
    { value: "first_name", label: "First name" },
    { value: "last_name", label: "Last name" },
    { value: "email", label: "Email" },
    { value: "phone", label: "Phone" },
    { value: "job_title", label: "Job title" },
    { value: "tags", label: "Tag" },
    { value: "next_meeting", label: "Next Meeting (YYYY-MM-DD)", type: "date" },
    { value: "last_meeting", label: "Last Meeting (YYYY-MM-DD)", type: "date" },
    { value: "due_date", label: "Due Date (YYYY-MM-DD)", type: "date" },
    { value: "company", label: "Company" },
    { value: "task_status", label: "Status" },
    { value: "task_sub_status", label: "Sub Status" },
    { value: "estimated_value", label: "Estimated Value" },
];

export const DEFAULT_BOOKING_PAGE_SETTINGS= (mode) => {
    return{
    "bgColor": "#ffffff",  
    "textColor": mode === "light" ? "#0F172A" : "#fff", 
    "primaryColor": "#2563EB" ,
    "primaryTextColor":"#2563EB"}
}

export const DEFAULT_USER_IMAGE = "https://sweetp-user-uploads.s3.eu-west-2.amazonaws.com/stage/1278/FLOWS/4272/Avatar_1719225396621.png"
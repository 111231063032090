import React from "react";
import { useSelector } from "react-redux";
import { Popover, MenuItem, Typography, IconButton, Grid, Checkbox, useMediaQuery, Avatar } from "@material-ui/core";
import { ImagedotIcon, MoreIcon, CheckedBlueIcon, UnCheckedCheckbox } from "../../assets/svg";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import NotificationListStyle from "../Notification/NotificationListStyle";
import UserDefaultImg from '../../assets/images/avatar.png'
import { userDisplayName } from "../../utils";

// Render notification list
const NotificationItem = ({ classes, notification, index, type, tabValue, updateNotification, setNotification_list, showCheckbox, handleSelect, cutMessage, hideHeaderElements, handleCheckDelete }) => {

    const newChat = useSelector((s) => s.chatv2.newChat);
    const { onlineUsers } = useSelector(state => state.chatv2)

    let avatar_filename;
    let name;
    let user_id;
    let userdata = newChat.find((f) => f.users.id === notification.notification_from);

    if (userdata && userdata.users) {
        name = userdata?.users?.name;
        user_id = userdata?.users.id;
        avatar_filename = userdata?.users?.avatar_filename || '';
    } else {
        avatar_filename = notification?.from_user?.avatar_filename || ''
    }

    let avatarfile =
        tabValue === 2
            ? notification?.agencyData?.agency_logo
                ? notification?.agencyData.agency_logo :
                notification?.users?.avatar_filename ?
                    notification?.users?.avatar_filename
                    : UserDefaultImg
            : tabValue === 1
                ? notification?.users?.avatar_filename
                    ? notification?.users?.avatar_filename
                    : UserDefaultImg
                : avatar_filename
                    ? avatar_filename
                    : UserDefaultImg;

    const handleNotificationClick = () => {
        updateNotification(notification, true);
        setNotification_list((prevList) =>
            prevList.filter((item) => item.id !== notification.id)
        );
    };
    const [menuPopoverAnchor, setMenuPopoverAnchor] = React.useState(null);
    const handleMenuClose = () => {
        setMenuPopoverAnchor(null);
    };
    const handleMenuClick = (event) => {
        event.stopPropagation();
        setMenuPopoverAnchor(event.currentTarget);
    };

    const isMobile = useMediaQuery('(max-width:899px)');

    const getNotificationText = () => {
        let textInfo = "";
    
        const truncateName = (name) => { 
            if(isMobile){
                return name.length > 15 ?( name.substring(0,10) + '...') : name;
            }else{
                return name.length > 20 ? name.substring(0, 20) + "..." : name;
            }
        };

        if (tabValue === 0) {
            textInfo = `Received a message from ${truncateName(userDisplayName(notification?.from_user, notification))}`;
        } else if (tabValue === 1) {
            const userDisplayNameTruncated = truncateName(userDisplayName(notification?.users));
            switch (notification?.notification_action_type + '') {
                case "1":
                    textInfo = `${userDisplayNameTruncated} Assigned a task`;
                    break;
                case "2":
                    textInfo = `${userDisplayNameTruncated} Updated a task`;
                    break;
                case "3":
                    textInfo = `Approval request for task : `;
                    break;
                case "4":
                    textInfo = `Approval request for task has been approved : `;
                    break;
                case "5":
                    textInfo = `Approval request for task has been declined : `;
                    break;
                default:
                    textInfo = `${userDisplayNameTruncated} Assigned a task`;
                    break;
            }
        } else if (tabValue === 2) {
            if (notification?.notification_sub_type === "calendar" && notification?.notification_action_type === 2) {
                // works for a updated meeting
                textInfo = `${truncateName(userDisplayName(notification?.users))} Updated a meeting`;
            } else if (notification?.agencyData?.notification_sub_type !== "calendar") {
                textInfo = "";
            } else {
                textInfo = notification?.agencyData?.agency_name;
            }
        }
        if (tabValue !== 0) {
            // textInfo = textInfo ? `${textInfo} ${cutMessage(notification.notification_message, 45)}` : cutMessage(notification.notification_message, 45)
            if (isMobile) {
                // For mobile
                textInfo = textInfo?.length > 10
                  ? `${textInfo} ${cutMessage(notification.notification_message.substring(0,7)+'...')}`
                  : cutMessage(notification.notification_message);
              } else {
                // For web
                textInfo = textInfo ?.length > 20
                  ? `${textInfo} ${cutMessage(notification.notification_message.substring(0,10)+'...')}`
                  : cutMessage(notification.notification_message);
              }
        }
        return textInfo;
    }
    return (
        <Grid container key={notification.id}>
            <Grid item xs={12}>
                <Grid container justifyContent="space-between" alignItems="center" className={`${classes.unreadchat2} nowrap`}>
                    <Grid item onClick={handleNotificationClick}>
                        <Grid container spacing={1} alignItems="center" className="nowrap">
                            {showCheckbox && (
                                <Grid item xs={2}>
                                    <Checkbox
                                        onClick={handleSelect(notification)}
                                        icon={<UnCheckedCheckbox />}
                                        checkedIcon={<CheckedBlueIcon />}
                                        checked={notification.isChecked ? true : false}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={4} className={classes.imageWidth}>
                                <Grid className={`relative ${classes.imageContent}`}>
                                    {notification?.from_user?.is_bot_res ?
                                        <span className={classes.zapierEmoji}>
                                            ⚡
                                        </span>
                                        :
                                        <>
                                            <Avatar title={userDisplayName(notification?.users) ? userDisplayName(notification?.users) : ""} alt={userDisplayName(notification?.users) ? userDisplayName(notification?.users) : ""} src={avatarfile ? avatarfile : ''} />
                                            <Grid
                                                className={`${tabValue === 2 ? classes.Imagedottothers2 : classes.Imagedot} ${tabValue === 1 ? classes.ImagedotTask : ""} ${onlineUsers[user_id] ? "" : classes.offlineDot}`}>
                                                {onlineUsers[user_id] ? <ImagedotIcon /> : null}
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                            </Grid>
                            <Grid item >
                                <Grid item>
                                    <Grid container className="oneLineEllip nowrap whiteSpacNowrap">
                                        <Grid item>
                                            <Typography
                                                variant="subtitle"
                                                color="primary"
                                                className={`fw-600  ${tabValue === 2 ? "ml-2" : ""}`}
                                            >
                                                {getNotificationText()}

                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item>
                                            <Typography
                                                className={`${tabValue === 2 ? classes.msgtimeothers2 : classes.msgtime
                                                    } ${tabValue === 1 ? classes.msgtimeTask : ""}  ${tabValue === 2 ? "ml-2" : ""}`}
                                            >
                                                {moment.utc(notification.notification_time).local().fromNow()}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        {!hideHeaderElements && (
                            <IconButton
                                onClick={handleMenuClick}
                            >
                                <MoreIcon />
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Popover
                id="menu-popover"
                open={Boolean(menuPopoverAnchor)}
                anchorEl={menuPopoverAnchor}
                onClose={handleMenuClose}
                placement={"right-start"}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem
                    style={{ width: "150px", height: "35px", color: "#64748B" }}
                    onClick={() => {
                        handleMenuClose();
                        updateNotification(notification, false);
                    }}
                >
                    Mark read
                </MenuItem>
                <MenuItem
                    style={{ color: "#64748B" }}
                    onClick={() => {
                        handleMenuClose();
                        handleCheckDelete(notification.id);
                    }}
                >
                    Delete
                </MenuItem>
            </Popover>
        </Grid>
    );
};

export default withStyles(NotificationListStyle)(NotificationItem);

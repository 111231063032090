import { baseAction } from "./baseAction";
import { profileConstant, alertConstant, loaderConstant, chatv2Constant } from "../constants";
import { profileService } from "../services";

// Import History
import history from "../../config/history";
import { alertAction } from "./alertAction";

const { request, success, failure } = baseAction;

// All login based actions
export const profileAction = {
  createProfile,
  saveWebBrowserToken,
  saveIosAppToken,

  getProfile,
  getProfileNames,
  saveProfileNames,

  getProjectUsers,
  getProjectClients,
  updateAgencyUserRole,
  deleteUser,
  deleteClientUser,

  getNotifications,
  getTasks,
  getInvites,
  updateNotification,
  updateNotificationViewStatus,
  deleteNotification,
  getGlobalData,

  inviteUserNew,
  inviteClientUserNew,
  newUserInvitationAccept,
  getNewUserInviteData,

  getInviteLink,
  getInviteFlowsAndClients,
  getAgencyDetailsCount,
  updateShowAppLogo,
  getEmailOtp,
  verifyOtp,
  resendOtp,
  updateFromChatListNotifications,
  updateFlowPermission,
  // clearNotifications,
  clearNotifications,
  emailUnSubscription,

  // Get App Config like favicon, title, logo, icons
  getAppConfig,

  saveCustomDomain,
  checkUniqueCustomDomain,
  getCustomDomainDetails,

  checkDnsStatus,
  checkPingStatus,

  checkSslStatus,
  generateSSL,

  publishCustomDomain,
  unPublishCustomDomain,
  
  publishPage,
  unPublishPage,

  updateAgencyProfile,
  getApplicationApps,
  getUserConfiguredApps,

  saveAgencyEmailConfig,
  getAgencyEmailConfig,
  testAgencyEmailConfig,
  getAuthUrl,
  getInvoiceNotification,
  verifyDomainRecords,
  removePublishedDomain,
  updateSupportRestoreId
};

// /** create new profile */
function createProfile(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.CREATE_PROFILE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    profileService.createProfile(params).then(data => {
      if (data && data.status === "success") {
        dispatch(success(profileConstant.CREATE_PROFILE_S, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack()
      } else {
        dispatch(failure(alertConstant.ALERT_ERROR, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    }, error => {
      dispatch(failure(profileConstant.CREATE_PROFILE_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/**
 * Save Users' Browser Web Token For Notification
 */
function saveWebBrowserToken(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.UPDATE_WEB_TOKEN_R, params));
    profileService.saveWebBrowserToken(params).then(data => {
      callBack();
    }, error => {
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    }
    );
  };
}


/**
 * Save Users' Mobile App Token For Notification
 */
function saveIosAppToken(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.UPDATE_WEB_TOKEN_R, params));
    profileService.saveIosAppToken(params).then(data => {
      callBack();
    }, error => {
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    }
    );
  };
}

// /** get profile */
function getProfileNames(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.GET_PROFILES_NAMES_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    profileService.getProfileNames(params).then(data => {
      dispatch(success(profileConstant.GET_PROFILES_NAMES_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(profileConstant.GET_PROFILES_NAMES_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

// /** get profile */
function saveProfileNames(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.SAVE_PROFILES_NAMES_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    profileService.saveProfileNames(params).then(data => {
      dispatch(success(profileConstant.SAVE_PROFILES_NAMES_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(profileConstant.SAVE_PROFILES_NAMES_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

// /** get profile */
function getProfile(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.GET_PROFILES_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    profileService.getProfile(params).then(data => {
      dispatch(success(profileConstant.GET_PROFILES_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(profileConstant.GET_PROFILES_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}


// /** get inviteUserNew */
function inviteUserNew(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.INVITE_N_USERS_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    profileService.inviteUserNew(params).then(data => {
      dispatch(request(loaderConstant.LOADING_FALSE));
      if (data.status === "success") {
        dispatch(success(profileConstant.INVITE_N_USERS_S, params));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        callBack(data)
      } else {
        dispatch(success(alertConstant.ALERT_ERROR, data.message));
      }
    }, error => {
      dispatch(failure(profileConstant.INVITE_N_USERS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** NEW USER INVITATION ACCEPTING */
function newUserInvitationAccept(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.NEW_USER_U_ACCEPT_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    profileService.newUserInvitationAccept(params).then(data => {
      dispatch(success(profileConstant.NEW_USER_U_ACCEPT_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack(data)
    }, error => {
      dispatch(failure(profileConstant.NEW_USER_U_ACCEPT_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

function getNewUserInviteData(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.G_NEW_USER_U_ACCEPT_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    profileService.getNewUserInviteData(params).then(data => {
      dispatch(request(loaderConstant.LOADING_FALSE));
      if (data.data) {
        dispatch(success(profileConstant.G_NEW_USER_U_ACCEPT_S, data));
        callBack(data.data)
      } else {
        history.push('/')
        dispatch(failure(alertConstant.ALERT_ERROR, data.message));
      }
    }, error => {
      dispatch(failure(profileConstant.G_NEW_USER_U_ACCEPT_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}


// /** send new client invitation*/
function inviteClientUserNew(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.INVITE_N_CLIENT_USERS_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    profileService.inviteClientUserNew(params).then(data => {
      dispatch(request(loaderConstant.LOADING_FALSE));

      if (data.status === "success") {
        dispatch(success(profileConstant.INVITE_N_CLIENT_USERS_S, params));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        callBack(data)
      } else {
        dispatch(success(alertConstant.ALERT_ERROR, data.message));
      }

    }, error => {
      dispatch(failure(profileConstant.INVITE_N_CLIENT_USERS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

//**************************************************TEAMS************************************************************* */

// /** get project users */
function getProjectUsers(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.GET_PROJECT_USERS_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    profileService.getProjectUsers(params).then(data => {
      dispatch(success(profileConstant.GET_PROJECT_USERS_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack(data)
    }, error => {
      dispatch(failure(profileConstant.GET_PROJECT_USERS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

// /** get project clients */
function getProjectClients(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.GET_PROJECT_CLIENTS_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    profileService.getProjectClients(params).then(data => {
      dispatch(success(profileConstant.GET_PROJECT_CLIENTS_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(profileConstant.GET_PROJECT_CLIENTS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

// /** update Agency User Role */
function updateAgencyUserRole(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.UPDATE_USER_ROLE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    profileService.updateAgencyUserRole(params).then(data => {
      dispatch(success(profileConstant.UPDATE_USER_ROLE_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(profileConstant.UPDATE_USER_ROLE_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

// /** delete User */
function deleteUser(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.DELETE_USER_ROLE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    profileService.deleteUser(params).then(data => {
      dispatch(success(profileConstant.DELETE_USER_ROLE_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));

      if (data.status === "success") {
        dispatch(success(profileConstant.DELETE_USER_ROLE_S, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      } else {
        dispatch(success(alertConstant.ALERT_ERROR, data.message));
      }

      callBack()
    }, error => {
      dispatch(failure(profileConstant.DELETE_USER_ROLE_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

// /** delete client  */
function deleteClientUser(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.DELETE_USER_ROLE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    profileService.deleteClientUser(params).then(data => {
      dispatch(request(loaderConstant.LOADING_FALSE));
      if (data.status === "success") {
        dispatch(success(profileConstant.DELETE_USER_ROLE_S, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      } else {
        dispatch(success(alertConstant.ALERT_ERROR, data.message));
      }
      callBack()
    }, error => {
      dispatch(failure(profileConstant.DELETE_USER_ROLE_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

//**************************************************TEAMS************************************************************* */

// /** get Notifications */
function getNotifications(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.GET_NOTIFICATIONS_R, params));
    profileService.getNotifications(params).then(data => {
      dispatch(success(profileConstant.GET_NOTIFICATIONS_S, data));
      callBack()
    }, error => {
      dispatch(failure(profileConstant.GET_NOTIFICATIONS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    }
    );
  };
}


// /** get getTasks */
function getTasks(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.GET_TASKS_R, params));
    profileService.getTasks(params).then(data => {
      dispatch(success(profileConstant.GET_TASKS_S, data));
      callBack()
    }, error => {
      dispatch(failure(profileConstant.GET_TASKS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    }
    );
  };
}

// /** get invites */
function getInvites(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.GET_INVITES_R, params));
    profileService.getInvites(params).then(data => {
      dispatch(success(profileConstant.GET_INVITES_S, data));
      callBack()
    }, error => {
      dispatch(failure(profileConstant.GET_INVITES_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    }
    );
  };
}
//update notification
function updateNotification(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.UPDATE_NOTIFICATIONS_R, params));
    profileService.updateNotification(params).then(result => {
      const { type, data, ids, redirect = true } = params;
      if (ids && ids.length > 0) {
        callBack(type);
        return;
      }
      dispatch(success(profileConstant.UPDATE_NOTIFICATIONS_S, params));
      if (type == 'message' && redirect) {
        dispatch(success(chatv2Constant.CHATV2_UPDATE_NOTIFICATION_S, data));
      }
      // Redirecting to the page
      if (type === "message" && redirect) {
        if (data && data["invite_type"] === 1) {
          history.push(`/chat/projects?id=${data.job_id}`)
        } else if (data && data["invite_type"] === 2) {
          history.push(`/chat/clients?id=${data.job_id}`)
        } else {
          if (data?.hasOwnProperty('job_id') && data.job_id) {
            history.push(`/chat/teams?id=${data.job_id}`)
          } else {
            history.push(`/dashboard`)
          }
        }
      } else if (type === "task" && redirect) {
        if (data && data["invite_type"] === 1 && data.job_id) {
          history.push(`/projects/detail/${data.job_id}/tasks/${data.item_id}`)
        } else if (data && data["invite_type"] === 2 && data.job_id) {
          history.push(`/clients/detail/${data.job_id}/tasks/${data.item_id}`)
        } else if (data?.item_id) {
          history.push(`/tasks/${data.item_id}`)
        } else {
          history.push(`/dashboard`)
        }
      } else if (type === "invite" && data?.notification_sub_type?.indexOf('wsm_') == -1 && redirect) {
        if (data["invite_type"] === 1) {
          history.push(`/projects/detail/${data.job_id}`)
        } else if (data["invite_type"] === 2) {
          history.push(`/clients/detail/${data.job_id}`)
        } else if (data?.notification_sub_type == "calendar") {
          history.push(`/calendar/view/${data?.job_id}`)
        } else {
          history.push(`/dashboard`)
        }
      } else if (data?.notification_sub_type?.indexOf('wsm_') >= 0 && redirect) {
        history.push(`/wsm`)
      } else if (type === "invoice" && redirect) {
        history.push(`/invoices/summary`)
      }

      callBack()
    }, error => {
      dispatch(failure(profileConstant.UPDATE_NOTIFICATIONS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    }
    );
  };
}


//update notification view status
function updateNotificationViewStatus(params, callBack = () => { }) {
  return (dispatch) => {
    // dispatch(request(profileConstant.UPDATE_NOTIFICATIONS_R, params));x
    profileService.updateNotificationViewStatus(params).then(result => {
      callBack()
    }, error => {
      // dispatch(failure(profileConstant.UPDATE_NOTIFICATIONS_F, error));
      // dispatch(failure(alertConstant.ALERT_ERROR, error));
    }
    );
  };
}



export function deleteNotification(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.DELETE_NOTIFICATION_R, params));

    profileService.deleteNotification(params).then(
      (result) => {
        if (result) {
          const { id, ids } = params;
          dispatch(success(profileConstant.DELETE_NOTIFICATION_S, { id, ids }));
          callBack();
        } else {
        }
      },
      (error) => {
        dispatch(failure(profileConstant.DELETE_NOTIFICATION_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

//**************************************************COMMON************************************************************* */

// /** get global */
function getGlobalData(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(loaderConstant.GLOBAL_LOADING_TRUE));
    dispatch(request(profileConstant.GET_GLOBAL_R, params));
    profileService.getGlobalData(params).then(data => {
      dispatch(success(profileConstant.GET_GLOBAL_S, data));
      dispatch(request(loaderConstant.GLOBAL_LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(profileConstant.GET_GLOBAL_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.GLOBAL_LOADING_FALSE));
    }
    );
  };
}


// /** get global */
function getInviteLink(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.GET_TEAM_INVITE_LINK_R, params));
    profileService.getInviteLink(params).then(data => {
      dispatch(success(profileConstant.GET_TEAM_INVITE_LINK_S, data));
      callBack()
    }, error => {
      dispatch(failure(profileConstant.GET_TEAM_INVITE_LINK_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    }
    );
  };
}

// /** get invite popup flows and clients list */
function getInviteFlowsAndClients(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.GET_INVITES_FLOWS_AND_CLIENTS_R));
    profileService.getInviteFlowsAndClients(params).then(data => {
      dispatch(success(profileConstant.GET_INVITES_FLOWS_AND_CLIENTS_S, data.data));
      // callBack()
    }, error => {
      dispatch(failure(profileConstant.GET_INVITES_FLOWS_AND_CLIENTS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    }
    );
  };
}

function getAgencyDetailsCount(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.GET_AGENCCY_DETAIL_COUNT_R));
    profileService.getAgencyDetailsCount(params).then(data => {
      dispatch(success(profileConstant.GET_AGENCCY_DETAIL_COUNT_S, data.data));
      // callBack()
    }, error => {
      dispatch(failure(profileConstant.GET_AGENCCY_DETAIL_COUNT_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    }
    );
  };
}

// Update workspace logo visibility
function updateShowAppLogo(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.UPDATE_SHOW_LOGO_R));
    profileService.updateShowAppLogo(params).then(data => {
      dispatch(success(profileConstant.UPDATE_SHOW_LOGO_S, data.data));
      callBack()
    }, error => {
      dispatch(failure(profileConstant.UPDATE_SHOW_LOGO_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    }
    );
  };
}

// get otp for email verification  
function getEmailOtp(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.GET_EMAIL_OTP_R));
    profileService.getEmailOtp(params).then(data => {
      dispatch(success(profileConstant.GET_EMAIL_OTP_S, data.data));
      callBack(data.data)
    }, error => {
      dispatch(failure(profileConstant.GET_EMAIL_OTP_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error))
    })
  }
}

function verifyOtp(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.VERIFY_EMAIL_OTP_R));
    profileService.verifyOtp(params).then(data => {
      dispatch(success(profileConstant.VERIFY_EMAIL_OTP_S, data.data));
      callBack(data.data)
    }, error => {
      dispatch(failure(profileConstant.VERIFY_EMAIL_OTP_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error))
    })
  }
}

function resendOtp(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.RESEND_EMAIL_OTP_R));
    profileService.resendOtp(params).then(data => {
      dispatch(success(profileConstant.RESEND_EMAIL_OTP_S, data.data));
      callBack(data.data)
    }, error => {
      dispatch(failure(profileConstant.RESEND_EMAIL_OTP_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error))
    })
  }
}

function updateFromChatListNotifications(filterData, cb = null) {
  return (dispatch, getState) => {
    const { notification_type, invite_type, job_id } = filterData
    const { profile, chatv2 } = getState()
    const { notifications_n } = profile || { notifications_n: [] }
    const { newChat } = chatv2 || { newChat: [] }
    const type_msgs = notifications_n?.filter(f =>
      f.invite_type == invite_type
      && f.notification_type == notification_type
      && f.job_id == job_id
    )
    const nIds = type_msgs?.map(m => m.id)
    if (nIds.length) {
      profileService.updateNotification({
        ids: nIds
      }).then(() => {
        dispatch(getNotifications())
        dispatch(success(chatv2Constant.CHATV2_GET_NEW_MESSAGES_S, {
          data: newChat?.filter(f =>
            f.job_id != job_id &&
            f.notification_type != notification_type &&
            f.invite_type == invite_type
          )
        }))
        if (cb) {
          cb()
        }
      }, error => {
        dispatch(failure(alertConstant.ALERT_ERROR, error))
      })
    } else if (cb) {
      cb()
    }
  }
}

function updateFlowPermission(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.UPDATE_FLOW_PERMISSION_R, params));
    // dispatch(request(loaderConstant.LOADING_TRUE));
    profileService.updateFlowPermission(params).then(data => {
      dispatch(success(profileConstant.UPDATE_FLOW_PERMISSION_S, data));
      // dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(profileConstant.UPDATE_FLOW_PERMISSION_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      // dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}


function clearNotifications(type, _callBack = () => { }) {
  return (dispatch) => {
    dispatch(success(profileConstant.CLEAR_NOTIFICATIONS_S, { type: type }));
  };
}

// email un subscription public link
function emailUnSubscription(params) {
  return (dispatch) => {
    profileService.unSubscribeEmail(params).then(data => {
      dispatch(success(profileConstant.EMAIL_UN_SUBSCRIPTION_S))
      if (data.status == 'success') {
        dispatch(alertAction.successAlert(data.message))
        history.push("/login")
      }
    }, error => {
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    })
  }
}

function getAppConfig(params, cb = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.GET_APP_CONFIG_R, {}));
    profileService.getAppConfig(params).then(data => {
      if (data) {
        dispatch(success(profileConstant.GET_APP_CONFIG_S, data.data));
      } else {
        dispatch(failure(profileConstant.GET_APP_CONFIG_F, {}));
      }
      cb(data.data)
    }, error => {
      dispatch(failure(profileConstant.GET_APP_CONFIG_F, error));
    }
    );
  };
}

function saveCustomDomain(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.UPDATE_CUSTOM_DOMAIN_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    profileService.saveCustomDomain(params).then(data => {
      if (data && data.status === "success") {
        dispatch(success(profileConstant.UPDATE_CUSTOM_DOMAIN_S, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack()
      } else {
        dispatch(failure(alertConstant.ALERT_ERROR, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    }, error => {
      dispatch(failure(profileConstant.UPDATE_CUSTOM_DOMAIN_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

function checkUniqueCustomDomain(params, callBack = () => { }) {
  return () => {
    profileService.checkUniqueCustomDomain(params).then(data => {
      if (data && data.status === "success") {
        callBack(data.data);
      }
    }, error => {
      console.log(error)
    }
    );
  };
}

function getCustomDomainDetails(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(profileConstant.GET_CUSTOM_DOMAIN_DETAILS_R));
    profileService.getCustomDomainDetails(params).then(data => {
      dispatch(success(profileConstant.GET_CUSTOM_DOMAIN_DETAILS_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(profileConstant.GET_CUSTOM_DOMAIN_DETAILS_F, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
      dispatch(failure(alertConstant.ALERT_ERROR, error))
    })
  }
}

function checkDnsStatus(params, callBack = () => { }) {
  return (dispatch) => {
    profileService.checkDnsStatus(params).then(data => {
      if (data && data.status === "success") {
        callBack(data.data);
      }
    }, error => {
      // dispatch(failure(alertConstant.ALERT_ERROR, error))
      callBack(null);
    });
  };
}

function checkPingStatus(params, callBack = () => { }) {
  return (dispatch) => {
    profileService.checkPingStatus(params).then(data => {
      if (data && data.status === "success" && !data.data.status) {
        dispatch(success(alertConstant.ALERT_SUCCESS, `Failed to verify: ${data.data.message.replace("ping:", "")}`));
      }
      callBack(data.data);
    }, error => {
      console.log(error)
    });
  };
}

function checkSslStatus(params, callBack = () => { }) {
  return (dispatch) => {
    profileService.checkSslStatus(params).then(data => {
      if (data && data.status === "success" && !data.data.status) {
        dispatch(success(alertConstant.ALERT_SUCCESS, `Failed to verify: error code: ${data.data.message.code}`));
      }
      callBack(data.data);

    }, error => {
      console.log(error)
    });
  };
}

function generateSSL(params, callBack = () => { }) {
  return (dispatch) => {
    profileService.generateSSL(params).then(data => {
      callBack(data.data);
    }, error => {
      console.log(error)
    });
  };
}

function publishCustomDomain(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.PUBLISH_DOMAIN_PAGE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    profileService.publishCustomDomain(params).then(data => {
      dispatch(request(loaderConstant.LOADING_FALSE));
      dispatch(success(profileConstant.PUBLISH_DOMAIN_PAGE_S, data));
      dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      callBack()
    }, error => {
      dispatch(failure(profileConstant.PUBLISH_DOMAIN_PAGE_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

function unPublishCustomDomain(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.PUBLISH_DOMAIN_PAGE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    profileService.unPublishCustomDomain(params).then(data => {
      dispatch(request(loaderConstant.LOADING_FALSE));
      dispatch(success(profileConstant.PUBLISH_DOMAIN_PAGE_S, data));
      dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      callBack(data.data)
    }, error => {
      dispatch(failure(profileConstant.PUBLISH_DOMAIN_PAGE_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

function publishPage(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.PUBLISH_DOMAIN_PAGE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    profileService.publishPage(params).then(data => {
      dispatch(request(loaderConstant.LOADING_FALSE));
      dispatch(success(profileConstant.PUBLISH_DOMAIN_PAGE_S, data));
      dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      callBack()
    }, error => {
      dispatch(failure(profileConstant.PUBLISH_DOMAIN_PAGE_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

function unPublishPage(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.PUBLISH_DOMAIN_PAGE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    profileService.unPublishPage(params).then(data => {
      dispatch(request(loaderConstant.LOADING_FALSE));
      dispatch(success(profileConstant.PUBLISH_DOMAIN_PAGE_S, data));
      dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      callBack(data.data)
    }, error => {
      dispatch(failure(profileConstant.PUBLISH_DOMAIN_PAGE_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

function updateAgencyProfile(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.UPDATE_AGENCY_DETAILS_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    profileService.updateAgencyProfile(params).then(data => {
      dispatch(success(profileConstant.UPDATE_AGENCY_DETAILS_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(profileConstant.UPDATE_AGENCY_DETAILS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

function saveAgencyEmailConfig(params, callBack = () => { }, fromSaveBtn) {
  return (dispatch) => {
    dispatch(request(profileConstant.SAVE_AGENCY_EMAIL_CONFIG_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    profileService.saveAgencyEmailConfig({ emailConfig: params}).then(data => {
      dispatch(success(profileConstant.SAVE_AGENCY_EMAIL_CONFIG_S, params));
      dispatch(success(profileConstant.UPDATE_AGENCY_EMAIL_CONFIG, params));
      dispatch(request(loaderConstant.LOADING_FALSE));

      if (fromSaveBtn) {
        dispatch(success(alertConstant.ALERT_SUCCESS, "Saved successfully"));
      }
      
      callBack()
    }, error => {
      dispatch(failure(profileConstant.SAVE_AGENCY_EMAIL_CONFIG_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

function testAgencyEmailConfig(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.TEST_AGENCY_EMAIL_CONFIG_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    profileService.testAgencyEmailConfig({ emailConfig: params}).then(data => {
      dispatch(success(profileConstant.TEST_AGENCY_EMAIL_CONFIG_S, params));
      dispatch(request(loaderConstant.LOADING_FALSE));
      dispatch(success(alertConstant.ALERT_SUCCESS, "Mail send successfully"));
      callBack()
    }, error => {
      dispatch(failure(profileConstant.TEST_AGENCY_EMAIL_CONFIG_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error?.response || "Unable to send mail"));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}
// get all application apps
function getApplicationApps(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.GET_APPLICATION_APPS_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    profileService.getApplicationApps(params).then(data => {
      dispatch(success(profileConstant.GET_APPLICATION_APPS_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(profileConstant.GET_APPLICATION_APPS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

// get user integrated apps
function getUserConfiguredApps(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.GET_USER_CONFIGURED_APPS_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    profileService.getUserConfiguredApps(params).then(data => {
      dispatch(success(profileConstant.GET_USER_CONFIGURED_APPS_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack(data)
    }, error => {
      dispatch(failure(profileConstant.GET_USER_CONFIGURED_APPS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

// /** get invoice notification */
function getInvoiceNotification(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.GET_INVOICE_NOTIFICATION_R, params));
    profileService.getInvoiceNotification(params).then(data => {
      dispatch(success(profileConstant.GET_INVOICE_NOTIFICATION_S, data));
      callBack()
    }, error => {
      dispatch(failure(profileConstant.GET_INVOICE_NOTIFICATION_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    }
    );
  };
}

function getAgencyEmailConfig(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.GET_AGENCY_EMAIL_CONFIG_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    profileService.getAgencyEmailConfig(params).then(data => {
      if (params?.authType) {
        if (data?.data?.authentication_type === params?.authType) {
          dispatch(success(profileConstant.GET_AGENCY_EMAIL_CONFIG_S, data));
        }
      } else {
        dispatch(success(profileConstant.GET_AGENCY_EMAIL_CONFIG_S, data));
      }
      
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(profileConstant.GET_AGENCY_EMAIL_CONFIG_F, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

function verifyDomainRecords(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.VERIFY_DOMAIN_RECORDS_R, params));
    profileService.verifyDomainRecords(params).then(data => {
      dispatch(success(profileConstant.VERIFY_DOMAIN_RECORDS_S, data));
      callBack(data.data)
    }, error => {
      dispatch(failure(profileConstant.VERIFY_DOMAIN_RECORDS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    }
    );
  };
}

function getAuthUrl(params, pageType, callBack = () => {}) {
  return (dispatch) => {
      dispatch(request(profileConstant.GET_GOOGLE_AUTH_URL_R, params));
      profileService.getAuthUrl(params, pageType).then(data => {
          dispatch(success(profileConstant.GET_GOOGLE_AUTH_URL_S, data));
          dispatch(request(loaderConstant.LOADING_FALSE));
          callBack()
      }, error => {
          dispatch(failure(profileConstant.GET_GOOGLE_AUTH_URL_F, error));
          dispatch(failure(alertConstant.ALERT_ERROR, error));
          dispatch(request(loaderConstant.LOADING_FALSE));
      });
  };
}

function removePublishedDomain(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.DELETE_PUBLISHED_DOMAIN_R, params));
    profileService.removePublishedUrl(params).then(data => {
      dispatch(success(profileConstant.DELETE_PUBLISHED_DOMAIN_S, data));
      callBack(data.data)
    }, error => {
      dispatch(failure(profileConstant.DELETE_PUBLISHED_DOMAIN_F, error));
    })
  }
}
function updateSupportRestoreId(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(profileConstant.UPDATE_SUPPORT_ID_R, params));
    profileService.updateSupportRestoreId(params).then(data => {
      dispatch(success(profileConstant.UPDATE_SUPPORT_ID_S, data));
      callBack(data.data)
    }, error => {
      dispatch(failure(profileConstant.UPDATE_SUPPORT_ID_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    }
    );
  };
}


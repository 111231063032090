import React, { useState, useEffect, useRef, useCallback, useMemo, forwardRef, useImperativeHandle } from 'react';
import PropTypes from "prop-types";
import createMentionPlugin, { defaultSuggestionsFilter, defaultTheme as mentionDefaultTheme } from '@draft-js-plugins/mention';
import { EditorState, RichUtils, convertToRaw, getDefaultKeyBinding, KeyBindingUtil } from 'draft-js';
import Editor, { createEditorStateWithText } from '@draft-js-plugins/editor';
import createLinkifyPlugin, { extractLinks } from '@draft-js-plugins/linkify';
import createEmojiPlugin, { defaultTheme } from '@draft-js-plugins/emoji';
import createHashtagPlugin, { extractHashtagsWithIndices } from '@draft-js-plugins/hashtag';
import { Grid, IconButton, withStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { isMobile } from 'react-device-detect';
import { useLocation } from "react-router-dom";

import '../../../../node_modules/@draft-js-plugins/mention/lib/plugin.css'
import useCapacitorKeyboard from "../../../hooks/keyboard_listener";
import EntryComponent from "./plugins/EntryComponent";


// Imports Styles
import styles from "./styles";
// import "./tenor.css";

//Icons
import { InputPlusIcon, EditTick, ChatEmojiIcon } from '../../../assets/svg'

import { getDraftMessages, isFlozyMobileApp, setDraftMessage } from '../../../utils';

const emojiTheme = {
  ...defaultTheme,
  emoji: 'chatEmoji',
  emojiSuggestions: `${defaultTheme.emojiSuggestions} chatEmojiSuggestions`,
  emojiSuggestionsEntry: `${defaultTheme.emojiSuggestionsEntry} chatEmojiSuggestionsEntry`,
  emojiSelect: `${defaultTheme.emojiSelect} chatEmojiSelect ml-1 floatOption`,
  emojiSelectButton: `${defaultTheme.emojiSelectButton} chatEmojiSelectButton MuiButtonBase-root MuiIconButton-root`,
  emojiSelectButtonPressed: `${defaultTheme.emojiSelectButtonPressed} chatEmojiSelectButtonPressed MuiButtonBase-root MuiIconButton-root`,
  emojiSelectPopover: `${defaultTheme.emojiSelectPopover} chatEmojiSelectPopover`,
  emojiSelectPopoverClosed: `${defaultTheme.emojiSelectPopoverClosed} chatEmojiSelectPopoverClosed`,
}

const mentionTheme = {
  ...mentionDefaultTheme,
  mention: 'chatMention',
  mentionSuggestions: 'chatMentionsSuggestions',
  mentionSuggestionsEntry: 'chatMentionSuggestionsEntry',
  mentionSuggestionsEntryFocused: 'chatMentionSuggestionsEntryFocused',
  mentionSuggestionsEntryText: 'chatMentionSuggestionsEntryText',
  mentionSuggestionsEntryAvatar: 'chatMentionSuggestionsEntryAvatar',
  mentionSuggestionsEntryContainer: 'chatMentionSuggestionsEntryContainer',
  mentionSuggestionsEntryContainerLeft: 'chatMentionSuggestionsEntryContainerLeft',
  mentionSuggestionsEntryContainerRight: 'chatMentionSuggestionsEntryContainerRight'
}

const emojiPlugin = createEmojiPlugin({
  // imagePath: 'http://cdn.jsdelivr.net/emojione/assets/svg/',
  useNativeArt: true,
  theme: emojiTheme,
  selectButtonContent: <div id="myChickooo"><ChatEmojiIcon /></div>,
  selectPopperOptions: {
    placement: 'top-end',
    modifiers: [{ name: 'preventOverflow', enabled: false }]
  }
});

const { EmojiSelect } = emojiPlugin;


const hashtagPlugin = createHashtagPlugin({
  theme: {
    hashtag: 'chatHashTag'
  }
});

/**
 * TextField Component
 * @param {*} props
 */
const TextEditor = forwardRef((props, ref) => {
  const { id, type, classes, handleChatSend, handleAttachment, userSuggestions, handleTyping, placeHolder, infinityModeHandler, typingUsers, userId, unsendMessages, updateDraftMessages, handleChatEditMessage, actionIcons, editChatData, backgroundColor } = props;
  const notesContent = getInitial();
  const [editorState, setEditorState] = useState(notesContent);
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState(userSuggestions);
  const [allMentions, setAllMentions] = useState([]);
  const [tinyUrl, setTinyUrl] = useState(null);
  const [tinySelectedUrl, setTinySelectedUrl] = useState(null)
  const [tinyUrlMeta, setTinyUrlMeta] = useState([]);
  const [tinyFetchedUrls, setTinyFetchedUrls] = useState([]);
  const [chatInputText, setChatInputText] = useState("");
  const [chatHashTags, setChatHashTags] = useState([]);
  const [chatRawContent, setChatRawContent] = useState(convertToRaw(
    notesContent.getCurrentContent()
  ));
  const { channel } = useSelector(s => s.chatv2)
  const { profile } = useSelector(s => s.profile)
  const [unsend_messages, setunsend_messages] = useState(unsendMessages)
  const activeHubData = (channel[type] || []).find(f => f.id == id)
  const [infinityButtonOn, setInfinityButtonOn] = useState(false)
  const location = useLocation();
  // const [isEditorFocus, setIsEditorFocus] = useState(false)
  const editorRef = useRef(null);

  const isKeyboardVisible = useCapacitorKeyboard();

  const linkifyPlugin = createLinkifyPlugin({
    target: '_blank',
    component: (props) => {
      return <a {...props} onClick={() => window.open(props.href, '_blank')} />
    },
  });


  useImperativeHandle(ref, () => ({
    // Expose parent function to parent component
    onThumbnailClick: (message_text) => {
      let newState = createEditorStateWithText(message_text ? message_text?.trim() : '');
      setEditorState(newState);
    },
    clearEditorState: (msgId) => {
      setDraftMessage(msgId, "");
      setEditorState(createEditorStateWithText(""));
    }
  }));

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      theme: mentionTheme,
      supportWhitespace: true,
      mentionPrefix: '@',
      entityMutability: 'IMMUTABLE',
      positionSuggestions: (settings) => {
        return {
          left: settings.decoratorRect.left + 'px',
          top: settings.decoratorRect.top + 'px', // change this value (40) for manage the distance between cursor and bottom edge of popover
          display: 'block',
          transform: 'scale(1) translateY(-100%)', // transition popover on the value of its height
          transformOrigin: '1em 0% 0px',
          transition: 'all 0.25s cubic-bezier(0.3, 1.2, 0.2, 1)',
          position: 'fixed',
          maxWidth: 'max-content',
          minWidth: 200
        };
      },
      mentionComponent: (mentionProps) => {
        return (
          <span
            className={mentionProps.className}
          >
            {mentionProps.children}
          </span>
        );
      },
    });
    const { MentionSuggestions } = mentionPlugin;
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  useEffect(() => {
    if(editChatData?.message_mentions) {
      setAllMentions(userSuggestions)
    }
    const handlePaste = async (event) => {
      const items = event.clipboardData.items;
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.kind === 'file' && item.type.includes('image')) {
          const blob = item.getAsFile();
          const url = URL.createObjectURL(blob);
          handleAttachment({ isPaste: true, blob: blob, url: url });
        }
      }
    };
    window.addEventListener('paste', handlePaste);
    return () => {
      window.removeEventListener('paste', handlePaste);
    };
  }, []);

  // Added to remove the session data to clear the reload count to fix mention trigger issue in chat
  useEffect(() => {
    if (!isFlozyMobileApp()) {
      const reloadCount = sessionStorage.getItem('reloadCount');
      if (reloadCount < 1) {
        sessionStorage.setItem('reloadCount', String(reloadCount + 1));
        window.location.reload();
      }
    }
  }, [])

  useEffect(() => {
    // Deactivates infiniti mode when user is in Infiniti chat room.
    if (activeHubData?.isInfiniti === 1) {
      setInfinityButtonOn(false)
      infinityModeHandler(true)
    }
  },[activeHubData?.isInfiniti])

  useEffect(() => {
    if (userSuggestions && userSuggestions.length > 0 && suggestions !== userSuggestions) {
      setSuggestions(userSuggestions)
    } else {
      setSuggestions([])
    }
  }, [userSuggestions])

  useEffect(() => {
    if (chatInputText && chatInputText.length > 0) {
      let urls = extractLinks(chatInputText)
      let hashTags = extractHashtagsWithIndices(chatInputText);
      if (urls && tinyUrl !== urls) {
        setTinyUrl([...urls])
      }
      else {
        setTinyUrl(null)
      }

      if (hashTags !== chatHashTags) {
        setChatHashTags(hashTags)
      }
    }
  }, [chatInputText])

  useEffect(() => {
    if (tinyUrl && tinyUrl.length > 0) {
      let url = tinyUrl.filter((val) => val.schema === "https:");
      if (url.length > 0) {

        let urlSelect = url[0]["url"];
        if (urlSelect !== tinySelectedUrl) {
          setTinySelectedUrl(urlSelect)
        }
      }
    } else {
      setTinySelectedUrl(null);
      setTinyUrlMeta([]);
    }
  }, [tinyUrl])


  function getInitial() {
    if (id) {
      const draftMessages = getDraftMessages();
      if (draftMessages[id] && actionIcons.includes('send')) {
        let newState = createEditorStateWithText(draftMessages[id].trim());
        return newState
      } else if (editChatData?.message_text) {
        let newState = createEditorStateWithText(editChatData?.message_text);
        return newState
      } else {
        return (createEditorStateWithText(""))
      }
    } else {
      return createEditorStateWithText("")
    }
  }

  useEffect(() => {
    const newState = getInitial();
    setEditorState(newState);
    setChatRawContent(convertToRaw(newState.getCurrentContent()));
  }, [location, id])

  useEffect(() => {
    setChatInputText(editorState?.getCurrentContent().getPlainText());
    editorRef.current.focus()
  }, [editorState])


  const onOpenChange = useCallback((_open) => {
    setOpen(_open);
  }, []);

  const onSearchChange = useCallback(({ value }) => {
    setSuggestions(defaultSuggestionsFilter(value, userSuggestions));
  }, [userSuggestions]);

  const onAddMention = (val) => {
    setAllMentions([...allMentions, val])
  };

  const onChange = async (newEditorState, is_edit = false) => {
    const rawContent = await convertToRaw(newEditorState.getCurrentContent());
    const blockContent = rawContent.blocks;
    const chatMsgText = await blockContent.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
    const currentContentTextLength = editorState?.getCurrentContent().getPlainText().length;
    const newContentTextLength = newEditorState.getCurrentContent().getPlainText().length;

    let unsend_Messages = {}
    if (chatMsgText !== '' && newEditorState._immutable._map.size > 10) {
      unsend_Messages[`${id}`] = chatMsgText
      let unSendMessages = { ...unsend_messages, ...unsend_Messages }
      setunsend_messages(unSendMessages)

      if (unSendMessages !== undefined) {
        Object.entries(unSendMessages).map((ele) => {
          if (ele[1] == "\n" && ele[0]) {
            delete unSendMessages[`${ele[0]}`];
          }
        })
      }
    }
    if (((currentContentTextLength > 0 || newContentTextLength > 0) && newEditorState._immutable._map.size == 10 && unsendMessages && Object.entries(unsendMessages).length == 0 && currentContentTextLength === 0) || (currentContentTextLength === 0 && newContentTextLength !== 0)) {
      // WORKAROUND: listens to input changes and focuses/moves cursor to back after typing in first character
      setEditorState(EditorState.moveFocusToEnd(newEditorState));
    } else {
      if (currentContentTextLength > 0 || chatMsgText == '@' || (chatMsgText == '\n' && !unsendMessages?.[`${id}`])) {
        setEditorState(newEditorState);
      }
    }
    if (!typingUsers?.[`${type}_${id}_${userId}`] && newContentTextLength >= 1) {
      handleTyping(true)
    } else {
      setTimeout(() => {
        handleTyping(false)
      }, 4000)
    }
    if (currentContentTextLength >= 1 && !is_edit && actionIcons.includes('send')) {
      localStorage.setItem("id", `${id}_${type}`)
      setInputFocus(true);
    }
    else {
      setInputFocus(false);
    }

    // handleChatText(chatMsgText);
    setChatRawContent(rawContent)
    setChatInputText(chatMsgText);
    if (!actionIcons.includes('send')) {
      const messageUrls = tinyUrl && tinyUrl.length > 0 ? tinyUrl.map((val) => { return val.url }) : [];
      const urlMetaData = tinyUrlMeta && tinyUrlMeta.length > 0 ? tinyUrlMeta.filter(val => val && val.hasOwnProperty("url") && messageUrls.includes(val.url)) : []
      let dataObj = {
        chatText: chatMsgText,
        chatRawContent: chatRawContent,
        hashtags: chatHashTags,
        mentions: allMentions,
        links: {
          url: tinyUrl,
          urlData: urlMetaData
          // urlData: tinyUrlMeta
        }
      }
      handleChatSend(dataObj)
    }
  };

  const keyBindingFn = (e) => {
    e.stopPropagation();
    if (KeyBindingUtil.hasCommandModifier(e) && e.keyCode === 66) { return 'bBold'; }
    else if (KeyBindingUtil.hasCommandModifier(e) && e.keyCode === 85) { return 'uLine'; }
    else if (KeyBindingUtil.hasCommandModifier(e) && e.keyCode === 73) { return 'iItalic'; }
    else if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.keyCode === 55) { return 'oLIST'; }
    else if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.keyCode === 56) { return 'uLIST'; }
    else if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.keyCode === 57) { return 'cBLOCK'; }
    else if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.keyCode === 48) { return 'bQUOTE'; }
    else if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.keyCode === 49) { return 'hONE'; }
    else if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.keyCode === 50) { return 'hTWO'; }
    else if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.keyCode === 51) { return 'hTHREE'; }
    else if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.keyCode === 52) { return 'hFOUR'; }
    else if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.keyCode === 53) { return 'hFIVE'; }
    else if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.keyCode === 54) { return 'hSIX'; }
    else if (e.keyCode === 13) {
      if (e.nativeEvent.shiftKey) {
        return "shift-enter"
      } else {
        if (isKeyboardVisible || isMobile) {
          return "split-block"
        }
        return "handle-submit"
      }
    }

    return getDefaultKeyBinding(e);
  }

  const handleKeyCommand = (command) => {
    let newState;
    if (command === 'bBold') {
      newState = RichUtils.toggleInlineStyle(editorState, 'BOLD');
    }
    else if (command === 'uLine') {
      newState = RichUtils.toggleInlineStyle(editorState, 'UNDERLINE');
    }
    else if (command === 'iItalic') {
      newState = RichUtils.toggleInlineStyle(editorState, 'ITALIC');
    }
    else if (command === 'oLIST') {
      newState = RichUtils.toggleBlockType(editorState, 'ordered-list-item');
    }
    else if (command === 'uLIST') {
      newState = RichUtils.toggleBlockType(editorState, 'unordered-list-item');
    }
    else if (command === 'cBLOCK') {
      newState = RichUtils.toggleBlockType(editorState, 'code-block');
    }
    else if (command === 'bQUOTE') {
      newState = RichUtils.toggleBlockType(editorState, 'blockquote');
    }
    else if (command === 'hONE') {
      newState = RichUtils.toggleBlockType(editorState, 'header-one');
    }
    else if (command === 'hTWO') {
      newState = RichUtils.toggleBlockType(editorState, 'header-two');
    }
    else if (command === 'hTHREE') {
      newState = RichUtils.toggleBlockType(editorState, 'header-three');
    }
    else if (command === 'hFOUR') {
      newState = RichUtils.toggleBlockType(editorState, 'header-four');
    }
    else if (command === 'hFIVE') {
      newState = RichUtils.toggleBlockType(editorState, 'header-five');
    }
    else if (command === 'hSIX') {
      newState = RichUtils.toggleBlockType(editorState, 'header-six');
    }
    else if (command === 'shift-enter') {
      newState = RichUtils.insertSoftNewline(editorState);
    } else if (command === "handle-submit") {
      if (editChatData?.message_text) {
        handleEditChat()
      } else {
        localStorage.setItem("id", "")
        handleChatSubmit();
      }
    }

    if (newState) {
      onChange(newState);
      return 'handled';
    }

    return 'not-handled';
  }

  const getEntityRanges = (string, array) => {
    const matches = [];
    const map = {};
    for (let i = 0; i < array.length; i++) {
      const prefix = '@';
      const entry = array[i].name;
      const index = string.indexOf(prefix + entry);

      if (index !== -1) {
        matches.push({
          offset: index,
          length: entry.length + 1,
          key: i
        });
        map[`${i}` + ''] = {
          type: 'mention',
          mutability: 'SEGMENTED',
          data: {
            mention: {
              ...array[i]
            }
          }
        }
      }
    }

    return [matches, map];
  }

  // Edit message
  const handleEditChat = () => {
    const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
    const chatMsgBlocks = blocks.map(block => (!block.text.trim() && '\n') || block.text);
    const chatMsgText = chatMsgBlocks.length > 0 && chatMsgBlocks[0] !== "\n" ? chatMsgBlocks.join('\n') : "";
    const messageUrls = tinyUrl && tinyUrl.length > 0 ? tinyUrl.map((val) => { return val.url }) : [];
    const urlMetaData = tinyUrlMeta && tinyUrlMeta.length > 0 ? tinyUrlMeta.filter(val => val && val.hasOwnProperty("url") && messageUrls.includes(val.url)) : []
    let rawContent_input = chatRawContent;
    rawContent_input = JSON.parse(JSON.stringify(rawContent_input));
    rawContent_input.blocks = rawContent_input.blocks.map(block => {
      return {
        ...block,
        text: block.text.trim()
      };
    });
    if (chatMsgText && chatMsgText.length > 0) {
      let obj = JSON.parse(JSON.stringify(rawContent_input));
      let ranges = getEntityRanges(chatMsgText, allMentions)
      obj["blocks"][0]["entityRanges"] = ranges[0];
      obj["entityMap"] = ranges[1]

      let dataObj = {
        message_id: editChatData?.id,
        chatText: chatMsgText,
        chatRawContent: obj,
        hashtags: chatHashTags,
        mentions: allMentions,
        links: {
          url: tinyUrl,
          urlData: urlMetaData
          // urlData: tinyUrlMeta
        }
      }

      setEditorState(createEditorStateWithText(""));
      setDraftMessage(id, "");
      setTinySelectedUrl(null);
      setTinyUrlMeta([]);
      setTinyFetchedUrls([]);
      dataObj["isEdited"] = false
      if (editChatData.message_text != chatMsgText) {
        dataObj["isEdited"] = true
      }
      handleChatEditMessage(dataObj)
      handleTyping(false)

    }
    else
      return false;
  }

  /* 
  const handleReturn = (e) => {
    e.stopPropagation();
  }
  */

  const handleChatSubmit = (get_welcome_message = false) => {
    const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
    const chatMsgBlocks = blocks.map(block => (!block.text.trim() && '\n') || block.text);
    const chatMsgText = chatMsgBlocks.length > 0 && chatMsgBlocks[0] !== "\n" ? chatMsgBlocks.join('\n') : "";
    const messageUrls = tinyUrl && tinyUrl.length > 0 ? tinyUrl.map((val) => { return val.url }) : [];
    const urlMetaData = tinyUrlMeta && tinyUrlMeta.length > 0 ? tinyUrlMeta.filter(val => val && val.hasOwnProperty("url") && messageUrls.includes(val.url)) : []

    if ((chatMsgText && chatMsgText.length > 0) || get_welcome_message) {
      let is_infiniti = 0
      if (activeHubData.isInfiniti == 1 || get_welcome_message) {
        is_infiniti = 1
      }
      let dataObj = {
        chatText: chatMsgText,
        chatRawContent: chatRawContent,
        hashtags: chatHashTags,
        mentions: allMentions,
        links: {
          url: tinyUrl,
          urlData: urlMetaData
          // urlData: tinyUrlMeta
        }
      }

      if (get_welcome_message) {
        dataObj['get_welcome_message'] = get_welcome_message
      }

      setEditorState(createEditorStateWithText(""));
      setDraftMessage(id, "");
      setTinySelectedUrl(null);
      setTinyUrlMeta([]);
      setTinyFetchedUrls([]);
      handleChatSend(dataObj, is_infiniti)
      let sendMessages = {}
      sendMessages[`${id}`] = ''
      let messages = unsendMessages
      delete messages[`${id}`];
      setunsend_messages(messages)
      updateDraftMessages(messages)
      handleTyping(false)
    }
    else
      return false;
  }
  const [inputFocus, setInputFocus] = useState(false);


  const styleMap = {
    CODE: {
      backgroundColor: '#111',
      color: '#fff',
      fontSize: '1.5rem',
      padding: '2px',
    },
  };

  const editorPlugins = [linkifyPlugin, emojiPlugin, hashtagPlugin]

 

  useEffect(() => {
    if (infinityButtonOn && profile.show_ai_welcome_msg) {
      // To trigger a welcome message
      handleChatSubmit(true);
    }
  }, [infinityButtonOn])

  useEffect(() => {
    if (!isMobile) {
      editorRef.current.focus();
    }
  }, [])

  return (
    <>
      <Grid className={`chatInputBox ${classes.chatInputBox}`} ref={ref}>
        <Grid container alignItems="center" className={`${classes.textOverallCon} textOverallCon`} style={{ background: backgroundColor }}>
          {
            actionIcons.includes('plus') &&
            <IconButton onClick={handleAttachment} className="mShow"><InputPlusIcon /></IconButton>
          }
          <Editor
            customStyleMap={styleMap}
            editorState={editorState}
            //!open to disable keybinding on mention open
            keyBindingFn={!open && keyBindingFn}
            handleKeyCommand={handleKeyCommand}
            // onFocus={() => setIsEditorFocus(true)}
            onBlur={() => {
              handleTyping(false);
              // setIsEditorFocus(false);
              if (Object.keys(editChatData || {}).length === 0) {
                setDraftMessage(id, editorState?.getCurrentContent().getPlainText());
              }
            }}
            onChange={onChange}
            placeholder={placeHolder}
            spellCheck={true}
            plugins={[...editorPlugins]}
            ref={editorRef}
          // handleReturn={(e) => handleReturn(e)}
          />
        </Grid>
        <MentionSuggestions
          className={classes.chatMentions}
          open={open}
          suggestions={suggestions}
          onOpenChange={onOpenChange}
          onSearchChange={onSearchChange}
          onAddMention={onAddMention}
          entryComponent={EntryComponent}
        // popoverContainer={({ children }) => <div>{children}</div>}
        />
      </Grid>
      <Grid className={`${classes.chatTypAttach} chatTypAttach`}>
        {
          !isMobile && actionIcons.includes('emoji') &&
          <EmojiSelect className="emojiIcon floatOption ml-1" />
        }
        {
          actionIcons.includes('edit') &&
          <>
            <IconButton onClick={() => handleEditChat()} className='pl-1'>
              <EditTick />
            </IconButton>
          </>
        }
      </Grid>
    </>
  );
})

// default props
TextEditor.defaultProps = {
  variant: "outlined",
  editChatData: {},
  // notesText: ``,
  // handleChatText: () => { },
  handleChatSend: () => { },
  handleAttachment: () => { },
  handleChatGif: () => { },
  handleTyping: () => { },
  infinityModeHandler: () => { },
  activateInfinity: false,
  placeHolder: "Start Typing...",
  unsendMessages: {},
  actionIcons: ['plus', 'audio', 'emoji', 'gif', 'send', 'infinity'],
  backgroundColor: 'F0F0F0'
};

// prop types
TextEditor.propTypes = {
  variant: PropTypes.string,
  editChatData: PropTypes.object,
  // notesText: PropTypes.string,
  // handleChatText: PropTypes.func,
  handleChatSend: PropTypes.func,
  handleAttachment: PropTypes.func,
  infinityModeHandler: PropTypes.func,
  handleChatGif: PropTypes.func,
  handleTyping: PropTypes.func,
  placeHolder: PropTypes.string,
  activateInfinity: PropTypes.bool,
  unsendMessages: PropTypes.object,
  actionIcons: PropTypes.array
};

// export component
export const EditChatInput = withStyles(styles)(TextEditor);

const OverrideStyle = (theme) => ({
  pageEditorRoot: {
    '& > div, > div > div': {
      // height: '100%'
    },
    "& .toolbar": {
      background: theme.palette.containers.card,
      borderColor: theme.palette.primary.border5,
      display: "flex",
      flexWrap: "nowrap",
      overflowX: "auto",
      boxShadow: "0px 2px 16px #0000001f",
      padding: "5px 8px !important",
      borderRadius: "10px",
      maxWidth: "100%",
      "& .MuiOutlinedInput-root": {
        color: theme.palette.primary.main,
        background: theme.palette.containers.bg1,
      },
    },
    '& .mini-tool-wrpr-ei': {
      background: theme.palette.editor.background,
      borderColor: theme.palette.editor.borderColor,
      '& button': {
        '& svg': {
          stroke: theme.palette.editor.svgStroke
        }
      },
    },
    "& .editor-wrapper": {
      // background: `${theme.palette.containers.card}`,
      '@media only screen and (max-width: 599px)': {
        width: '100%',
        '& .ed-section-wrapper .ed-section-inner': {
          width: '90%'
        }
      }
    },
    "& .close-popupbtn": {
      background: theme.palette.containers.bg7,
    },
    "& .MuiButton-root.secondaryBtn": {
      background: theme.palette.containers.card,
    },
    '@media only screen and (max-width: 768px)': {
      '& .editor-wrapper.fixed': {
        minWidth: '90%'
      }
    }
  },
  editorRoot: {
    height: `${window.innerHeight - 100}px`,
    overflowY: "auto",
  },
  textMsgEditor: {
    fontSize: '0.875rem',
    fontWeight: 400,
    '& .chat-editor:last-child:after': {
      content: "''",
      width: '65px',
      height: "5px",
      display: "inline-block",
      verticalAlign: "baseline"
  }
  },
  chatEditorRoot: {
    '& .chatEditorRoot': {
      '&:focus-visible': {
        outline: 'none',
        border: 'none',
      }
    },
    overflowY: "auto",
    "& .toolbar": {
      background: theme.palette.containers.card,
      borderColor: theme.palette.primary.border5,
      display: "flex",
      flexWrap: "nowrap",
      overflowX: "auto",
      boxShadow: "0px 2px 16px #0000001f",
      padding: "5px 8px !important",
      borderRadius: "10px",
      maxWidth: "100%",
      "& .MuiOutlinedInput-root": {
        color: theme.palette.primary.main,
        background: theme.palette.containers.bg1,
      },
    },
    '& .mini-tool-wrpr-ei': {
      background: theme.palette.editor.background,
      borderColor: theme.palette.editor.borderColor,
      '& button': {
        '& svg': {
          stroke: theme.palette.editor.svgStroke
        }
      },
    },
  },
  funnelEditorRoot: {
    height: "100%",
    "& .signed-btn-del": {
      display: "none !important"
    },
    "@media only screen and (max-width: 599px)": {
      "& > div": {
        paddingBottom: "10vh !important"
      },
    }
  },
  funnelTemplateEditor: {
    height: "100%",
    "& .editor-wrapper": {
      // zoom:0.8
    },
    '@media only screen and (max-width: 599px)': {
      height: '100% !important'
    }
  },
  boardEditor: {
    "& #slate-wrapper-scroll-container": {
      height: `${window.outerHeight - 545}px !important`,
    },
    '& .tools-drawer': {
      zIndex: '1300 !important'
    },
    '& .scoll-area': {
      flex: 'none'
    }
  },
  editorRootFlow: {
    "& .editor-t-wrapper": {
      background: "none !important",
    },
    "& #slate-wrapper-scroll-container": {
      height: `${window.innerHeight - 150}px !important`,
      borderRadius: '10px',
      paddingTop: '56px'
    },
    "& .editor-wrapper": {
      paddingTop: '10px',
      width: '85%',
      '&.full': {
        width: '85%'
      },
      '& .ed-section-inner': {
        minWidth: '90%'
      }
    },
    "& .max-content": {
      paddingBottom: '0px !important',
    },
    '& .section-wrapper-fluid': {
      '& .grid-container.container': {
        width: '100% !important'
      }
    },
    '& .mini-tool-wrpr-ei': {
      bottom: '-20px'
    },
    '@media only screen and (max-width: 599px)': {
      '& .mini-tool-wrpr-ei': {
        bottom: '3px !important'
      },
    },
  },
  editorRootClient: {
    "& #slate-wrapper-scroll-container": {
      height: `${window.innerHeight - 140}px !important`,
      borderRadius: '10px',
      paddingTop: '56px'
    },
    "& .editor-wrapper": {
      paddingTop: '10px',
      width: '85%',
      '&.full': {
        width: '85%'
      },
      '& .ed-section-inner': {
        minWidth: '90%'
      }
    },
    "& .max-content": {
      paddingBottom: '0px !important',
    },
    '& .section-wrapper-fluid': {
      '& .grid-container.container': {
        width: '100% !important'
      }
    },
    '& .mini-tool-wrpr-ei': {
      bottom: '-15px'
    },
    '@media only screen and (max-width: 599px)': {
      '& .mini-tool-wrpr-ei': {
        bottom: '3px !important'
      },
    },
  },
  editorBriefClient_show: {
    "& #slate-wrapper-scroll-container": {
      height: `${window.innerHeight - 258}px !important`,
      borderRadius: '10px',
    },
    "& .editor-wrapper": {
      paddingTop: '16px',
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
      width: '90%',
      '&.full': {
        width: '90%'
      }
    },
    '& .section-wrapper-fluid': {
      '& .grid-container.container': {
        width: '100% !important'
      }
    },
    '& .ed-section-inner': {
      minWidth: '90%'
    },
    '@media only screen and (max-width: 599px)': {
      '& .mini-tool-wrpr-ei': {
        bottom: '3px'
      },
    }
  },
  editorBriefClient_hide: {
    "& #slate-wrapper-scroll-container": {
      height: `${window.innerHeight - 145}px !important`,
      borderRadius: '10px',
    },
    "& .editor-wrapper": {
      paddingTop: '16px',
    },
    '& .section-wrapper-fluid': {
      '& .grid-container.container': {
        width: '100% !important'
      }
    },
    '& .ed-section-inner': {
      minWidth: '90%'
    }
  },
  editorBriefFlow_show: {
    "& #slate-wrapper-scroll-container": {
      height: `${window.innerHeight - 307}px !important`,
      borderRadius: '10px',
    },
    "& .editor-wrapper": {
      paddingTop: '16px',
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
      width: '90%',
      '&.full': {
        width: '90%'
      }
    },
    '& .dialogComp': {
      '& .ei-footer-text': {
        opacity: 1
      },
      '& .editor-wrapper': {
        width: '60%',
        '&.full': {
          width: '90%'
        }
      }
    },
    '& .section-wrapper-fluid': {
      '& .grid-container.container': {
        width: '100% !important'
      }
    },
    '& .ed-section-inner': {
      minWidth: '90%'
    },
    '& .mini-tool-wrpr-ei': {
      bottom: '-35px'
    },
    '@media only screen and (max-width: 599px)': {
      '& .mini-tool-wrpr-ei': {
        bottom: '3px'
      },
    }
  },
  editorBooking_show: {
    "& #slate-wrapper-scroll-container": {
      height: `${window.innerHeight - 634}px !important`,
      borderRadius: '10px',
    },
    "& .editor-wrapper": {
      paddingTop: '16px',
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
      width: '90%',
      '&.full': {
        width: '90%'
      }
    },
    '& .dialogComp': {
      '& .ei-footer-text': {
        opacity: 1
      },
      '& .editor-wrapper': {
        width: '60%',
        '&.full': {
          width: '90%'
        }
      }
    },
    '& .section-wrapper-fluid': {
      '& .grid-container.container': {
        width: '100% !important'
      }
    },
    '& .ed-section-inner': {
      minWidth: '90%'
    },
    '& .mini-tool-wrpr-ei': {
      bottom: '-35px'
    },
    '@media only screen and (max-width: 599px)': {
      '& .mini-tool-wrpr-ei': {
        bottom: '-15px'
      },
    }
  },
  editorBriefFlow_hide: {
    "& #slate-wrapper-scroll-container": {
      height: `${window.innerHeight - 145}px !important`,
      borderRadius: '10px',
    },
    "& .editor-wrapper": {
      paddingTop: '16px',
    },
    '& .section-wrapper-fluid': {
      '& .grid-container.container': {
        width: '100%'
      }
    },
    '& .ed-section-inner': {
      minWidth: '90%'
    }
  },
  builderRoot: {
    "& .toolbar": {
      minHeight: '54px',
      borderRadius: '10px',
      margin: '0px',
      justifyContent: 'center'
    },
    "& #slate-wrapper-scroll-container": {
      height: `${window.innerHeight - 142}px !important`,
    },
  },
  builderBrain: {
    '& .ei-footer-text': {
      opacity: 0
    },
    '& .ed-section-wrapper ': {
      '& .ed-section-inner': {
        minWidth: '100%'
      }
    },
    "& #slate-wrapper-scroll-container": {
      height: `${window.innerHeight - 96}px !important`,
      borderRadius: '6px',
      '&.no-color': {
        background: theme.palette.containers.card
      }
    },
    '& .section-wrapper-fluid': {
      '& .grid-container.container': {
        width: '100% !important'
      }
    },
    '& .editor-wrapper': {
      padding: '12px',
      paddingTop: '60px !important',
      maxWidth: '1024px !important',
      '&.full': {
        width: '85%'
      }
    },
    '& .dialogComp': {
      '& .ei-footer-text': {
        opacity: 1
      },
      '& .editor-wrapper': {
        width: '60%',
        '&.full': {
          width: '80%'
        }
      }
    },
    "& .editor-t-wrapper": {
      background: "none !important",
    },
  },
  editorRootPublicDoc: {
    '& .top-left-editor-wrapper': {
      marginTop: '0px !important'
    }
  },
});

export default OverrideStyle;

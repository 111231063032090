import moment from "moment";
import history from "../../config/history";

export const HEADERS = ["title", "priority", "assigned_to", "status", "group_type", "group_type_name", "approved_by", "start_date", "due_date", "tags"];

export const HEADERS_MAP = {
    title: "Task Name",
    priority: "Priority",
    assigned_to: "Assigned To",
    status: "Status",
    group_type: "Group",
    group_type_name: "Flow/Client",
    approved_by: "Approved By",
    start_date: "Start Date",
    due_date: "Due Date",
    tags: "Tags",
    created_at: "Created on"
}

export const GROUPINGS_MAP = {
    default: "Default View",
    group_type_name: "Flow/Client Name",
    title: "Task Name",
    priority: "Priority",
    assigned_to: "Assignee",
    status: "Status",
    group_type: "Group",
    approved_by: "Approved By",
    start_date: "Start Date",
    due_date: "Due Date",
    tags: "Tags",
    created_at: "Created at"
}

export const DEFAULT_PREFERENCE = [
    { "name": "title", "position": 1, "visible": true },
    { "name": "due_date", "position": 9, "visible": true },
    { "name": "priority", "position": 2, "visible": true },
    { "name": "assigned_to", "position": 3, "visible": true },
    { "name": "status", "position": 4, "visible": true },
    { "name": "group_type", "position": 5, "visible": true },
    { "name": "group_type_name", "position": 6, "visible": true },
    { "name": "approved_by", "position": 7, "visible": false },
    { "name": "start_date", "position": 8, "visible": false },
    { "name": "tags", "position": 10, "visible": false },
    { "name": "created_at", "position": 11, "visible": true }
]

export const DEFAULT_DASHBOARD_PREFERENCE = ["title", "priority", "assigned_to", "status", "group_type"]

export const DEFAULT_GROUP_BY = "default";
export const TAGS = "Tags";

export const DEFAULT_FILTERS = [
    { "key": "flows", "value": "Flows" },
    { "key": "clients", "value": "Clients" },
    { "key": "status", "value": "Status" },
    { "key": "assigned_to", "value": "Assigned To" },
    { "key": "due_date", "value": "Date" },
    { "key": "created_at", "value": "Created at" },
]

export const FLOWS = "flows";
export const CLIENTS = "clients";
export const ASSIGNED_TO = "assigned_to";
export const STATUS = "status";
export const DUE_DATE = "due_date";

export const DEFAULT_STATUS = [
    { "key": "1", "value": "To Do" },
    { "key": "2", "value": "In Progress" },
    { "key": "3", "value": "Completed" },
    { "key": "4", "value": "Awaiting Approval" },
    { "key": "5", "value": "Overdue" },
]

export const STATUS_OPTIONS = [
    { value: "1", label: "To do", bgColor: "#E5EAF1", color: "#64748b" },
    { value: "2", label: "In Progress", bgColor: "rgba(37, 99, 235, 0.13)", color: "rgb(37, 99, 235)" },
    { value: "3", label: "Completed", bgColor: "rgba(14, 142, 42, 0.15)", color: "rgb(14, 142, 42)" },
    { value: "4", label: "Awaiting Approval", bgColor: "#ffffff", color: "#FBAD3E", styles: { display: "none" } },
    { value: "5", label: "Overdue", bgColor: "#E05971", color: "#FFFFFF", styles: { display: "none" } }
]

export const PRIORITY_OPTIONS = [
    // { value: "0", label: "None" },
    { value: "1", label: "Low", bgColor: "#00C975", color: "#fff", styles: { paddingRight:"25px" } },
    { value: "2", label: "Medium", bgColor: "#FBAD3E", color: "#fff", styles: { paddingRight:"25px" } },
    { value: "3", label: "High", bgColor: "#E1425E", color: "#fff", styles: { paddingRight:"25px" } },
]

export const GROUP_OPTIONS = [
    { value: "1", label: "Flows" },
    { value: "2", label: "Clients" }
]

export const FULL_GROUP_OPTIONS = [
    { value: "1", label: "Flows" },
    { value: "2", label: "Internal Clients" },
    { value: "3", label: "External Clients" },
]

export const PRIORITY_COLOR = {
    "3": "#E1425E",
    "2": "#FBAD3E",
    "1": "#00C975"
}

export const PRIORITY_OBJ = {
    "1": "Low",
    "2": "Medium",
    "3": "High"
}

export const GROUP_OBJ = {
    "1": "Flows",
    "2": "Clients",
}

export const GROUP_OBJ_1 = {
    "1": "Flows",
    "2": "Internal Clients",
    "3": "External Clients"
}

export const STATUS_OBJ = {
    "1": "To Do",
    "2": "In Progress",
    "3": "Completed",
    "4": "Awaiting Approval",
    "5": "Overdue"
}

export const REPEAT_OPTIONS = [
    { value: "1", label: "Daily" },
    { value: "2", label: "Weekly" },
    { value: "3", label: "Monthly" },
    { value: "4", label: "Yearly" }
]

export const WEEK_OPTIONS = [
    { value: "1", label: "Sun" },
    { value: "2", label: "Mon" },
    { value: "3", label: "Tue" },
    { value: "4", label: "Wed" },
    { value: "5", label: "Thu" },
    { value: "6", label: "Fri" },
    { value: "7", label: "Sat" },
]

export const MONTH_OPTIONS = [
    { value: "1", label: "1st" },
    { value: "2", label: "2nd" },
    { value: "3", label: "3rd" },
    { value: "4", label: "4th" },
    { value: "5", label: "5th" },
    { value: "6", label: "Last" },
]

export const YEAR_OPTIONS = [
    { value: "1", label: "Jan" },
    { value: "2", label: "Feb" },
    { value: "3", label: "Mar" },
    { value: "4", label: "Apr" },
    { value: "5", label: "May" },
    { value: "6", label: "Jun" },
    { value: "7", label: "Jul" },
    { value: "8", label: "Aug" },
    { value: "9", label: "Sep" },
    { value: "10", label: "Oct" },
    { value: "11", label: "Nov" },
    { value: "12", label: "Dec" },
]

export const DATES = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']

export const MONTH = [
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
]

// get title suggestions
export const getTitleSuggestions = (jobList, group_type, checkAll = false) => {
    group_type = group_type + ""
    return jobList.reduce((acc, cur) => {
        if (cur.group_type + "" === group_type && group_type === "1") {
            acc.push({ ...cur, id: cur.value })
        } else if (cur.group_type + "" === group_type && (checkAll || (group_type === "2" && cur.client_type + "" === "1"))) {
            acc.push({ ...cur, id: cur.value })
        } else if (cur.group_type + "" === "2" && (checkAll || (group_type === "3" && cur.client_type + "" === "2"))) {
            acc.push({ ...cur, id: cur.value })
        }
        return acc
    }, [])
}

export const TIME_ZONE_OPTIONS = [
    {
        "label": "DST",
        "value": "DST",
        "offset": -12
    },
    {
        "label": "U",
        "value": "U",
        "offset": -11
    },
    {
        "label": "HST",
        "value": "HST",
        "offset": -10
    },
    {
        "label": "AKDT",
        "value": "AKDT",
        "offset": -8
    },
    {
        "label": "PDT",
        "value": "PDT",
        "offset": -7
    },
    {
        "label": "PST",
        "value": "PST",
        "offset": -8
    },
    {
        "label": "UMST",
        "value": "UMST",
        "offset": -7
    },
    {
        "label": "MDT",
        "value": "MDT",
        "offset": -6
    },
    {
        "label": "CAST",
        "value": "CAST",
        "offset": -6
    },
    {
        "label": "CDT",
        "value": "CDT",
        "offset": -5
    },
    {
        "label": "CCST",
        "value": "CCST",
        "offset": -6
    },
    {
        "label": "SPST",
        "value": "SPST",
        "offset": -5
    },
    {
        "label": "EST",
        "value": "EST",
        "offset": -5
    },
    {
        "label": "EDT",
        "value": "EDT",
        "offset": -4
    },
    {
        "label": "UEDT",
        "value": "UEDT",
        "offset": -5
    },
    {
        "label": "VST",
        "value": "VST",
        "offset": -4.5
    },
    {
        "label": "PYT",
        "value": "PYT",
        "offset": -4
    },
    {
        "label": "ADT",
        "value": "ADT",
        "offset": -3
    },
    {
        "label": "CBST",
        "value": "CBST",
        "offset": -4
    },
    {
        "label": "SWST",
        "value": "SWST",
        "offset": -4
    },
    {
        "label": "PSST",
        "value": "PSST",
        "offset": -4
    },
    {
        "label": "NDT",
        "value": "NDT",
        "offset": -2.5
    },
    {
        "label": "ESAST",
        "value": "ESAST",
        "offset": -3
    },
    {
        "label": "AST",
        "value": "AST",
        "offset": -3
    },
    {
        "label": "SEST",
        "value": "SEST",
        "offset": -3
    },
    {
        "label": "GDT",
        "value": "GDT",
        "offset": -3
    },
    {
        "label": "MST",
        "value": "MST",
        "offset": -3
    },
    {
        "label": "BST",
        "value": "BST",
        "offset": -3
    },
    {
        "label": "CVST",
        "value": "CVST",
        "offset": -1
    },
    {
        "label": "UTC",
        "value": "UTC",
        "offset": 0
    },
    {
        "label": "GMT",
        "value": "GMT",
        "offset": 0
    },
    {
        "label": "GST",
        "value": "GST",
        "offset": 0
    },
    {
        "label": "WEDT",
        "value": "WEDT",
        "offset": 2
    },
    {
        "label": "CEDT",
        "value": "CEDT",
        "offset": 2
    },
    {
        "label": "RDT",
        "value": "RDT",
        "offset": 2
    },
    {
        "label": "WCAST",
        "value": "WCAST",
        "offset": 1
    },
    {
        "label": "MEDT",
        "value": "MEDT",
        "offset": 3
    },
    {
        "label": "SDT",
        "value": "SDT",
        "offset": 3
    },
    {
        "label": "EEDT",
        "value": "EEDT",
        "offset": 3
    },
    {
        "label": "SAST",
        "value": "SAST",
        "offset": 2
    },
    {
        "label": "FDT",
        "value": "FDT",
        "offset": 3
    },
    {
        "label": "TDT",
        "value": "TDT",
        "offset": 3
    },
    {
        "label": "JDT",
        "value": "JDT",
        "offset": 3
    },
    {
        "label": "LST",
        "value": "LST",
        "offset": 2
    },
    {
        "label": "JST",
        "value": "JST",
        "offset": 3
    },
    {
        "label": "KST",
        "value": "KST",
        "offset": 3
    },
    {
        "label": "EAST",
        "value": "EAST",
        "offset": 3
    },
    {
        "label": "MSK",
        "value": "MSK",
        "offset": 3
    },
    {
        "label": "SAMT",
        "value": "SAMT",
        "offset": 4
    },
    {
        "label": "IDT",
        "value": "IDT",
        "offset": 4.5
    },
    {
        "label": "GET",
        "value": "GET",
        "offset": 4
    },
    {
        "label": "CST",
        "value": "CST",
        "offset": 4
    },
    {
        "label": "WAST",
        "value": "WAST",
        "offset": 5
    },
    {
        "label": "YEKT",
        "value": "YEKT",
        "offset": 5
    },
    {
        "label": "PKT",
        "value": "PKT",
        "offset": 5
    },
    {
        "label": "IST",
        "value": "IST",
        "offset": 5.5
    },
    {
        "label": "SLST",
        "value": "SLST",
        "offset": 5.5
    },
    {
        "label": "NST",
        "value": "NST",
        "offset": 5.75
    },
    {
        "label": "NCAST",
        "value": "NCAST",
        "offset": 7
    },
    {
        "label": "NAST",
        "value": "NAST",
        "offset": 8
    },
    {
        "label": "MPST",
        "value": "MPST",
        "offset": 8
    },
    {
        "label": "TST",
        "value": "TST",
        "offset": 8
    },
    {
        "label": "UST",
        "value": "UST",
        "offset": 8
    },
    {
        "label": "NAEST",
        "value": "NAEST",
        "offset": 8
    },
    {
        "label": "ACST",
        "value": "ACST",
        "offset": 9.5
    },
    {
        "label": "AEST",
        "value": "AEST",
        "offset": 10
    },
    {
        "label": "WPST",
        "value": "WPST",
        "offset": 10
    },
    {
        "label": "YST",
        "value": "YST",
        "offset": 9
    },
    {
        "label": "CPST",
        "value": "CPST",
        "offset": 11
    },
    {
        "label": "NZST",
        "value": "NZST",
        "offset": 12
    },
    {
        "label": "FST",
        "value": "FST",
        "offset": 12
    },
    {
        "label": "KDT",
        "value": "KDT",
        "offset": 13
    },
    {
        "label": "SST",
        "value": "SST",
        "offset": 13
    }
]

export const DAYS_OPTIONS = ['First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth', '	Seventh', 'Eighth', 'Ninth', 'Tenth', 'Eleventh', 'Twelfth', 'Thirteenth', 'Fourteenth', 'Fifteenth', 'Sixteenth', 'Seventeenth', 'Eighteenth', 'Nineteenth', 'Twentieth', 'Twenty first', 'Twenty second', 'Twenty third', 'Twenty fourth', 'Twenty fifth', 'Twenty sixth', 'Twenty seventh', 'Twenty eighth', 'Twenty ninth', 'Thirtieth', 'Thirty first']

export const CALENDER_MENU_LIST = [
    { value: "3", label: "Task" },
    { value: "0", label: "Meeting" },
    { value: "1", label: "Timeblock" },
    // { value: "3", label: "Boards" },
    // { value: "4", label: "Reminders" },
]

export const CALENDER_MENU = [
    { value: "3", label: "Task" },
    { value: "0", label: "Meeting" },
    { value: "1", label: "Timeblock" },
    { value: "4",label:"Booking"}
    // { value: "3", label: "Boards" },
    // { value: "4", label: "Reminders" },
]

export const CALENDER_SELECT_LIST = [
    { value: "day", label: "Day" },
    { value: "week", label: "Week" },
    { value: "month", label: "Month" }
]

export const CALENDER_SELECT_LIST_MOBILE = [
    { value: "day", label: "Day" },
    { value: "week", label: "Week" },
]

export const CALENDER_MENU_OBJ = {
    "3": "Task",
    "0": "Meeting",
    "1": "Timeblock",
}

export const POP_OVER_LIST = ["Meeting", "Timeblock"]

export const CLIENTS_PREFERENCE = [
    { "name": "title", "position": 1, "visible": true },
    { "name": "priority", "position": 2, "visible": true },
    { "name": "assigned_to", "position": 3, "visible": true },
    { "name": "start_date", "position": 4, "visible": true },
]


export const TIMEBLOCK_COLORS = [
    { value: 1, label: '#D9E8DC' },
    { value: 2, label: '#E3ECFF' },
    { value: 3, label: '#FFDFEF' },
    { value: 4, label: '#F0D9B9' },
    { value: 5, label: '#E7FFEC' },
    { value: 6, label: '#EDEDED' },
    { value: 7, label: '#FEE0DD' },
    { value: 8, label: '#ADCDDF' },
    { value: 9, label: '#C3ADDF' },
    { value: 10, label: '#FFD9A7' },
    { value: 11, label: '#FFC6C6' },
    { value: 12, label: '#C2C2E5' },
    { value: 13, label: '#8F8F8F' }
]

export const MEETING_COLORS = [
    { value: 1, label: '#2563EB' },
    { value: 2, label: '#62C879' },
    { value: 3, label: '#8F76E7' },
    { value: 4, label: '#E68585' },
    { value: 5, label: '#A1C1E5' },
    { value: 6, label: '#8F8F8F' },
    { value: 7, label: '#F1B45E' },
    { value: 8, label: '#D37AA7' }
]

export const END_TYPES = [
    { "value": "1", "label": "Never" },
    { "value": "2", "label": "On" },
    { "value": "3", "label": "After" }
]
export const STATUS_COLOR = {
    "3": "rgba(14, 142, 42, 0.15)",
    "2": "rgba(37, 99, 235, 0.13)",
    "1": "#E5EAF1",
    "4": "#f8e1c1",
    "5" : "#E05971"
}

export const COLOR = {
    "3": "#0E8E2A",
    "2": "#2563EB",
    "1": "#64748B",
    "4": "#FBAD3E",
    "5": "#FFFFFF"
}


export const taskValidation = (taskData, subTaskIndex) => {
    if (!taskData.title) {
        return "Please provide task name"
    }

    if (subTaskIndex === 0 || subTaskIndex >= 1) {
        return "Please add or clear the subtasks"
    }

    if (taskData.is_recurring) {
        const { schedule_type, recurring_months, recurring_weeks, recurring_days, occurance_month, occurance_day, end_type, end_at } = (taskData.recurring || {});
        
        if(!taskData.start_date){
            return "Please select the start date for the task to start the recurring"
        }
        
        if (!schedule_type) {
            return "Please select recurring type"
        }
        if ((schedule_type + "") === "2" && !recurring_days) {
            return "Please select occurance days"
        }

        if (schedule_type + "" === "3") {
            if ((!occurance_day) && ((!recurring_days || (recurring_days && !recurring_days.length)) || (!recurring_weeks || (recurring_weeks && !recurring_weeks.length)))) {
                return "Please select recurring occurances"
            }
        }

        if (schedule_type + "" === "4") {
            if ((!occurance_month || !occurance_day) && ((!recurring_days || (recurring_days && !recurring_days.length)) || (!recurring_months || (recurring_months && !recurring_months.length)) || (!recurring_weeks || (recurring_weeks && !recurring_weeks.length)))) {
                return "Please select recurring occurances"
            }
        }

        if (['2', '3'].includes(end_type + "") && !end_at) {
            return "Please select recurring end option"
        }

        if((end_type + "" === "2") && moment(end_at).format("YYYY-MM-DD") <= moment(taskData.start_date).format("YYYY-MM-DD")){
            return "Recurring end date should be greater than task start date"
        }

    }

    return null
}

export const INVERSE_OPTIONS = {
    "status": {
        "To Do": "1",
        "In Progress": "2",
        "Completed": "3",
        "Awaiting Approval": "4",
        "Overdue":"5"
    },
    "priority": {
        "Low": "1",
        "Medium": "2",
        "High": "3"
    },
    "group_type": {
        "Flows": "1",
        "Clients": "2",
    }
}

export const checkBothClients = (jobList) => {
    let hasBothClients = jobList.reduce((acc, cur) => {
        if (cur.group_type + "" === "2") {
            acc[cur.client_type] = true
        }
        return acc
    }, {})
    hasBothClients = hasBothClients['1'] && hasBothClients['2'];
    return hasBothClients
}

export const hasUserAccess = (user_id, ag_owner, role_type, assigned_to = [], approved_by = {}, created_by = null) => {
    try {
        const hasRoleAccess = ((user_id === ag_owner) || (["admin","emp_admin"].includes(role_type)) || (created_by === user_id));
        const hasAccess = ((approved_by && approved_by.id === user_id) || !!(assigned_to && assigned_to.find(f => (f.id === user_id))))
        return (hasRoleAccess || hasAccess)
    } catch (err) {
        return false
    }
}

export const COLUMN_WIDTH = {
    "title": "32%",
    "priority": "12%",
    "assigned_to": "12%",
    "status": "10%",
    "group_type": "8%",
    "group_type_name": "14%",
    "approved_by": "10%",
    "start_date": "10%",
    "due_date": "10%",
    "tags": "10%",
    "created_at": "10%"
}

export const isClientPage = (type) => { return type === 'Clients' }
export const isFlowPage = (type) => { return type === 'Flows' }
export const isDashboardPage = (type) => { return type === 'dashboard' }
export const isCalendar = (type) => { return type === 'calendar' }
export const isTaskPage = (type) =>{ return type ? false : true }

export const currentGroupBy = (preference) => {
    return preference && preference.task && preference.task.groupBy ? preference.task.groupBy : DEFAULT_GROUP_BY
}

export const isDefaultGroupBy = (groupByPref) => {
    return groupByPref ? groupByPref === "default" : false
}

export const getPageFilters = (taskFilter, type, projectID, clientID, user_id) => {
    if (isClientPage(type) && clientID) {
        taskFilter.clients = [{ 'value': clientID }]
    } else if (isFlowPage(type) && projectID) {
        taskFilter.flows = [{ 'value': projectID }]
    } else if (isDashboardPage(type)) {
        taskFilter = {
            ...taskFilter,
            due_date: [moment().subtract(1, 'days')],
            assigned_to: [{ value: user_id }],
            isDashboard : true
        }
    }
    return taskFilter
}

export const getTaskHeaders = (type, preference, isDefaultGroup=false) => {
    // const taskPreference = (isClientPage(type) || isFlowPage(type)) ? CLIENTS_PREFERENCE : preference && preference.task && preference.task.columnSettings ? preference.task.columnSettings : DEFAULT_PREFERENCE;
    const taskPreference = preference && preference.task && preference.task.columnSettings ? preference.task.columnSettings : DEFAULT_PREFERENCE;
    const HEADERS = isDashboardPage(type) ? DEFAULT_DASHBOARD_PREFERENCE : taskPreference.reduce((acc, cur) => {
        if (cur.visible) {
            acc.push(cur.name)
        }
        return acc;
    }, [])
    return (isClientPage(type) || isFlowPage(type)) ? HEADERS.filter(h=>(!["group_type","group_type_name"].includes(h))) : HEADERS;
}

export const handleTaskOpenRoute = (type, projectID, clientID, taskId) => {
    if (isFlowPage(type)) {
        history.push(`/projects/detail/${projectID}/tasks/${taskId}`)
    } else if (isClientPage(type)) {
        history.push(`/clients/detail/${clientID}/tasks/${taskId}`)
    } else {
        history.push(`/tasks/${taskId}`)
    }
}

export const handleTaskRouteClose = (type, projectID, clientID) => {
    if (isFlowPage(type)) {
        history.push(`/projects/detail/${projectID}/tasks`)
    } else if (isClientPage(type)) {
        history.push(`/clients/detail/${clientID}/tasks`)
    } else if (isCalendar(type)) {
        history.push('/calendar')
    } else {
        history.push(`/tasks`)
    }
}

export const SORT_FIELDS = [
    "title",
    "priority",
    // "assigned_to",
    "status",
    "group_type",
    "group_type_name",
    // "approved_by",
    "start_date",
    "due_date",
    // "tags",
    "created_at"
]

export const getNewVal = (newValue, currentData = {}) => {
    const key = Object.keys(newValue || {})[0];
    let returnObj = {}
    switch (key) {
        case "priority":
            returnObj[key] = PRIORITY_OBJ[newValue[key]];
            break;
        case "status":
            returnObj[key] = STATUS_OBJ[newValue[key]];
            returnObj.task_status = newValue[key];
            break;
        case "due_date":
            returnObj[key] = newValue[key]
            const curDate = moment().format("YYYY-MM-DD")
            if((newValue[key] < curDate) && currentData?.task_status && Number(currentData?.task_status) !== 5){
                returnObj.status = STATUS_OBJ["5"];
                returnObj.task_status = 5;
            } else if((newValue[key] >= curDate) && currentData?.task_status && Number(currentData.task_status) === 5){
                returnObj.status = STATUS_OBJ["1"];
                returnObj.task_status = 1;    
            }
            break;
        default:
            returnObj[key] = newValue[key];
            break;
    }
    return returnObj;
}

export const getGroupLabel = (groupBy = 'default') => {
    const groupByList = {
        group_type_name: "Without Flow/Client name",
        due_date: "No Due Date",
        priority: "None Priority",
        status: "No Status",
        group_type: "Without Group",
        assigned_to: "Unassigned",
        approved_by: "without Approvedby",
        start_date: "No Start Date",
        tags: "No Tags",
        default: "Others"
    }
    return groupByList[groupBy] || "Others"
}

export const getTeamMembers = (data, usersList) => {
    // show only the related users
    let teamMembers = [];
    let grp_id_tmp = data?.group_id || "0";
    const currentList = (usersList || []).find(f => (f.group_id + '' === grp_id_tmp + ''))
    teamMembers = currentList && currentList.users ? currentList.users.map(m => {
        return { ...m, avatar: m.avatar || m.avatar_filename }
    }) : []
    return teamMembers;
}

export const getApprovalStatus = (user_id, assignedTo = [], approvedBy = {}, created_by, ag_owner) => {
    const isApprover = approvedBy && approvedBy.id ? Number(approvedBy.id) === Number(user_id) : null
    const isAssignee = assignedTo.some(s => { return Number(s.id) === Number(user_id) })
    return (isApprover || (isApprover === null)) ? 3 : isAssignee ? 4 : 3;
}

export const getColPreference = (preference, isClientPage, isFlowPage, isDefaultGroup) =>{
    let curPreference = preference || [];
    curPreference = curPreference.map(m=>{
        if((isClientPage || isFlowPage) && ["group_type_name", "group_type"].includes(m.name)){
            return { ...m, disabled : true }
        }else{
            return { ...m, disabled : false  }
        }
    })
    return curPreference
}

export const templateValidation = (params) =>{
    let errMsg = null;
    if(!params.title){
        errMsg = "Please enter the task title"
    }else if(!params.tasks || (params.tasks && params.tasks.length === 0)){
        errMsg = "Please select tasks"
    }
    return errMsg
}

export const IMPORT_FIELDS = [
    { key: "priority", display: "Priority" },
    { key: "tags", display: "Tags" },
    // { key: "subtasks", display: "Subtasks" },
    { key: "attachments", display: "Attachments" },
    { key: "timeZone", display: "Timezone" },
    { key: "status", display: "Status" },
    { key: "recurring", display: "Recurring" },
    { key: "startDate", display: "Start date" },
    { key: "dueDate", display: "Due Date" },
    // { key: "comments", display: "Comments" },
    { key: "description", display: "Description" },
    { key: "startTime", display: "Start Time" },
    { key: "endTime", display: "End time" },
]

export const USER_FIELDS = [
    { key: "created_by", display: "Created By" },
    { key: "created_at", display: "Created Date" },
    { key: "updated_at", display: "Last Update" },
]

export const tzZeroAdder = (offset='') =>{
    try{
        let [sign, hour, minute] = offset.match(/([+-]?)(\d+):(\d+)/).slice(1);
        hour = hour.padStart(2, '0');
        return `${sign}${hour}:${minute}`;
    }catch(err){
        return offset
    }
}
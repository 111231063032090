import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from "prop-types";
import { Grid, withStyles, Box ,Typography} from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import VideoThumbnail from 'react-video-thumbnail';
import PlayArrowOutlinedIcon from '@material-ui/icons/PlayArrowOutlined';
import PlayButton from '../../assets/images/playButton.png'
import { getUploadPreviewFiles, isFlozyMobileApp } from "../../utils"
import doc from "../../assets/icons/doc.svg"
import { CircularProgress } from "@material-ui/core"

//Import Styles
import styles from "./styles";
import { GifLoader } from './GifLoader';
import { Editor, EditorState } from 'draft-js';
import createMentionPlugin from '@draft-js-plugins/mention';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import createHashtagPlugin from '@draft-js-plugins/hashtag';
import className from 'classnames';
import { createEditorStateWithText } from '@draft-js-plugins/editor';

const styleMap = {
    CODE: {
        backgroundColor: '#111',
        color: '#fff',
        fontSize: '1.5rem',
        padding: '2px',
    },
};

const MediaMessage = (props) => {
    const { progress_bar } = useSelector(s => s.chatv2)
    const { classes, message, handleSlideShowView, handleUndo, undo, slidepreviewdata, isUploading, handleHashTagClick, handleMentionClick, msgComponentType, userId,type } = props;
    const file = message.message_file ? JSON.parse(message.message_file) : null;
    const uploadProgress = Object?.values(progress_bar?.[type] || {})?.[0] || 0


    if (file) {
        file["id"] = message.id
    }
    const editor = useRef(null);

    // const initialState = notesContent;
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [loading, setLoading] = useState(true);
    let imgContainerSize = 250
    if(isFlozyMobileApp()) {
        imgContainerSize = 100
    }
    const [containerSize, setContainerSize] = useState({ width: imgContainerSize, height: imgContainerSize });
    const containerRef = useRef(null);

    useEffect(() => {
        // Initialize ResizeObserver
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                const { width, height } = entry.contentRect;
                if (width > 0 && height > 0) {
                    setContainerSize({ width, height });
                }
            }
        });

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        // Cleanup observer on unmount
        return () => {
            if (containerRef.current) {
                resizeObserver.unobserve(containerRef.current);
            }
        };
    },  [containerRef.current || []]);

    useEffect(() => {
        if (file?.url) {
            const img = new Image();
            img.src = file?.url;

            img.onload = () => {
                setLoading(false);
            };

            img.onerror = () => {
                setLoading(false);
            };

            return () => {
                img.onload = null;
                img.onerror = null;
            };
        }
    }, [file?.url]);

    useEffect(() => {
        let notesContent = "";
        try {
            notesContent = message?.message_text ? createEditorStateWithText(message?.message_text?.trim()) : EditorState.createEmpty();
        } catch (err) {
            notesContent = EditorState.createEmpty()
        }
        setEditorState(notesContent);
    }, [message?.message_text])

    const onChange = (editorState) => {
        setEditorState(editorState);
    };

    const mentionPlugin = createMentionPlugin({
        classes: classes,
        mentionComponent: (mentionProps) => {
            let { mention, decoratedText } = mentionProps
            return (
                <a className={`${className(classes.messageMention, msgComponentType)} messageMention`} onClick={() => handleMentionClick(mention)}> {decoratedText}</a >
            )
        }
    });

    const linkifyPlugin = createLinkifyPlugin({
        target: '_blank',
        classes: classes,
        component: (props) => {
            return <a  {...props} className={`${className(classes.messageLink, msgComponentType)} messageLink`} onClick={() => window.open(props.href, '_blank')} />
        },
    });

    const hashtagPlugin = createHashtagPlugin({
        classes: classes,
        hashtagComponent: (props) => {
            let { decoratedText } = props;
            return <a className={className(classes.messageHashTag, msgComponentType)} onClick={() => handleHashTagClick(decoratedText)} >{decoratedText}</a>
        },
    });

    const renderImage = (file, previewType) => {
        switch (previewType[1]) {
            case 'gif':
                return <GifLoader
                    classes={classes}
                    file={file}
                />
            default:
                return (
                    isFlozyMobileApp() ? (
                        <img
                        className={`${classes.chatImage}`}
                        src={file?.url}
                    />
                    )
                    :
                    <div
                        style={{ position: 'relative', width: containerSize.width, height: containerSize.height + 6 }}
                    >
                        {
                            loading &&
                            <Skeleton
                                variant="rect"
                                width={containerSize.width}
                                height={containerSize.height}
                                style={{
                                    borderRadius: '8px',
                                    backgroundColor: '#e0e0e0',
                                    position: 'absolute'
                                }}
                            />
                        }
                        <img
                            ref={containerRef}
                            onLoad={() => setLoading(false)}
                            className={`${classes.chatImage}`}
                            src={file?.url}
                        />
                    </div >
                )


        }
    }

    const getPreview = () => {
        if (file && file.url) {
            let previewType = file.type.split('/');
            let fileType = previewType[0];
            let previewUrl = file.thumb;
            let previewGrid = fileType === "image" ?
                renderImage(file, previewType) :
                fileType === "video" ?
                    <div
                        className={classes.videoThumb}>
                        <div className={classes.videoThumbPlayBtnContainer}>
                            <span className={classes.videoThumbPlayBtn}>
                                <PlayArrowOutlinedIcon />
                            </span>
                        </div>
                        {
                            file.thumb ?
                                <img className={classes.chatImage} alt={"thumb"} src={file.thumb || PlayButton} /> :
                                <VideoThumbnail
                                    videoUrl={previewUrl}
                                />
                        }
                    </div>
                    : "";
            return previewGrid;
        }
    }
    // When a image/video is uploaded, for that current user, the url wont be changed after success callback
    if ((isUploading && message.uploadPreviews) || message.uploadPreviews) {
        const { classes, message: { uploadPreviews } } = props || { message: {} }
        const { file_type, files_data } = uploadPreviews || {}
        let previewFiles = getUploadPreviewFiles(files_data) || []

        const renderThumb = (m) => {
            let src = doc;
            if (m.thumb) {
                src = m.thumb;
            } else if (m.preview) {
                src = m.preview
            }
            return (
                <>
                    {file_type?.indexOf('video') > -1 ?
                        <div
                            className={classes.videoThumb}>
                            {message.message_file &&
                                <div className={classes.videoThumbPlayBtnContainer}>
                                    <span className={classes.videoThumbPlayBtn}>
                                        <PlayArrowOutlinedIcon />
                                    </span>
                                </div>
                            }
                            <img className={classes.chatImage} alt={"thumb"} src={src || PlayButton} />
                        </div>
                        :
                        (file_type?.indexOf('gif') > -1 ?
                            <GifLoader
                                classes={classes}
                                file={src}
                            />
                            :
                            <img
                                className={classes.chatImage}
                                src={src}
                                alt={m?.name}
                            />)
                    }
                </>
            )
        }

        return (
            <>
                {!message.deleted ?
                    <div
                        style={{
                            width: "min-content"
                        }}
                    >
                        <div
                            className={`${classes.chatSingleMedia} ${classes.imageContainer} ${(message?.message_text?.trim()) ? "" : "chatSingleMedia"} ${previewFiles.length > 1 && classes.multipleImage}`}
                            onTouchEnd={(e) => {
                                if (isFlozyMobileApp()) {
                                    message.message_file && !isUploading ?
                                        handleSlideShowView({
                                            mediaArray: [file],
                                            allFiles: [message],
                                            index: 0,
                                            star: message.star,
                                            previewData: [slidepreviewdata],
                                            data: message //to get data in slideshow
                                        })
                                        :

                                        console.log("Loading")
                                }
                            }}
                            onClick={(e) => {
                                if (!isFlozyMobileApp()) {
                                    message.message_file && !isUploading ?
                                        handleSlideShowView({
                                            mediaArray: [file],
                                            allFiles: [message],
                                            index: 0,
                                            star: message.star,
                                            previewData: [slidepreviewdata],
                                            data: message //to get data in slideshow
                                        })
                                        :

                                        console.log("Loading")
                                }
                            }}
                        >
                            {
                                previewFiles?.map((m) => {
                                    return renderThumb(m)
                                })
                            }

                            <div className="progress-container">
                                {!message.message_file && (
                                    <>
                                        <CircularProgress
                                            variant="determinate"
                                            value={100}
                                            className="circular-progress background"
                                        />
                                        <CircularProgress
                                            variant="determinate"
                                            value={uploadProgress}
                                            className="circular-progress foreground"
                                        />
                                        
                                    </>
                                )}
                            </div>

                        </div>
                        {
                            message?.message_text?.trim() &&
                            <Grid>
                                <div className={`${message.star && userId === message.star_user_id ? classes.textMsgBubbleStar : classes.textMsgBubble} disable-user-select p5`}>
                                    <Editor
                                        customStyleMap={styleMap}
                                        editorState={editorState}
                                        onChange={onChange}
                                        plugins={[linkifyPlugin, mentionPlugin, hashtagPlugin]}
                                        ref={editor}
                                        readOnly={true}
                                    />
                                </div>
                            </Grid>
                        }
                    </div>
                    :
                    (message.deleted &&
                        <span className="deletedMessage">This message has been deleted
                            <a style={{ paddingLeft: "10px", color: "blue", fontWeight: "bold" }} className={"cursorPointer"} onClick={() => handleUndo(message.id)}>undo</a>
                        </span>
                    )
                }
            </>
        )
    } else {
        return (
            <>
                {!undo ?
                    <div
                        style={{
                            width: slidepreviewdata?.moduleType === "schedule" ? "" : "min-content"
                        }}
                    >
                        <Grid
                            className={`${classes.chatSingleMedia} ${classes.imageContainer} ${message?.message_text?.trim() ? "" : "chatSingleMedia"}`}
                            onClick={(e) => {
                                message.message_file && !isUploading ?
                                    handleSlideShowView({
                                        mediaArray: [file],
                                        allFiles: [message],
                                        index: 0,
                                        star: message.star,
                                        previewData: [slidepreviewdata],
                                        data: message //to get data in slideshow
                                    })
                                    :
                                    console.log("Loading")
                            }}>
                            {
                                file && file.url ?
                                    getPreview() :
                                    <Skeleton variant="rect" width={210} height={118} />
                            }
                        </Grid>
                        {
                            message?.message_category === 1 && message?.message_text?.trim() &&
                            <Grid>
                                <div className={`${message.star && userId == message.star_user_id ? classes.textMsgBubbleStar : classes.textMsgBubble} disable-user-select p5`}>
                                    <Editor
                                        customStyleMap={styleMap}
                                        editorState={editorState}
                                        onChange={onChange}
                                        plugins={[linkifyPlugin, mentionPlugin, hashtagPlugin]}
                                        ref={editor}
                                        readOnly={true}
                                    />
                                </div>
                            </Grid>
                        }
                    </div>
                    :
                    <span className="deletedMessage">This message has been deleted
                        <a style={{ paddingLeft: "10px", color: "blue", fontWeight: "bold" }} className={"cursorPointer"} onClick={() => handleUndo(message.id)}>undo</a>
                    </span>
                }
            </>
        )
    }
}

// Default props
MediaMessage.defaultProps = {
    classes: {},
    message: null,
    handleSlideShowView: () => { }
};

// Prop types
MediaMessage.propTypes = {
    classes: PropTypes.object,
    message: PropTypes.object,
    handleSlideShowView: PropTypes.func,
};

export default withStyles(styles)(MediaMessage)
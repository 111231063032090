export const loaderConstant = {
  LOADING_TRUE: "LOADING_TRUE",
  LOADING_FALSE: "LOADING_FALSE",

  LAZY_LOADING_TRUE: "LAZY_LOADING_TRUE",
  LAZY_LOADING_FALSE: "LAZY_LOADING_FALSE",

  GLOBAL_LOADING_TRUE: "GLOBAL_LOADING_TRUE",
  GLOBAL_LOADING_FALSE: "GLOBAL_LOADING_FALSE",

  TEMPLATE_LOADING_TRUE: "TEMPLATE_LOADING_TRUE",
  TEMPLATE_LOADING_FALSE: "TEMPLATE_LOADING_FALSE",

  GOOGLE_EVENTS_LOADING_TRUE: "GOOGLE_EVENTS_LOADING_TRUE",
  GOOGLE_EVENTS_LOADING_FALSE: "GOOGLE_EVENTS_LOADING_FALSE",

  CREATING_NEW_DOC_TRUE:"CREATING_NEW_DOC_TRUE",
  CREATING_NEW_DOC_FALSE:"CREATING_NEW_DOC_FALSE"
};

// Import API Helper
import { postRequest, getRequest, deleteRequest, putRequest } from "../../utils/apiHelper";

export const docsService = {
    createDoc,
    getDocs,
    getDocContent,
    updateDocContent,
    deleteDoc,
    addAccess,
    addPublicAccessToken,
    removePublicAccessToken,
    getPublicDoc,
    getShareMembers,
    updateShareMembers,
    getSharedDocs,
    copyDoc,
    pinDoc,
    getCustomFolder,
    moveDoc
};

// create new doc
async function createDoc(parent_doc_id = 0, params) {
    const response = await postRequest(`docs/${parent_doc_id}`, params);
    return response;
}

// get doc list
async function getDocs(path = 'root', params = { search: "", skip: 0, limit : 20 }) {
    const { search,skip,limit } = params
    let url=`docs/?path=${path}&search=${search?search:''}`
    // if(skip){
        url+=`&skip=${skip}`
    // }
    if(limit){
        url+=`&limit=${limit}`
    }
    const response = await getRequest(url);
    return response;
}

// get doc content
async function getDocContent(id = 0) {
    const response = await getRequest(`docs/${id}`);
    return response;
}

// update doc content
async function updateDocContent(id = 0, params = {}) {
    const response = await putRequest(`docs/${id}`, params);
    return response;
}

// delete doc
async function deleteDoc(id = 0, params = {}) {
    const response = await deleteRequest(`docs/${id}`, params);
    return response;
}

// add public access to document
async function addPublicAccessToken(id = 0, params = {}) {
    const response = await postRequest(`docs/access-public/${id}`, params);
    return response;
}

// add public access to document
async function removePublicAccessToken(id = 0, params = {}) {
    const response = await putRequest(`docs/access-public/${id}`, params);
    return response;
}

// get public access document
async function getPublicDoc(token) {
    const response = await getRequest(`docs/public/${token}`);
    return response;
}

// add access to document
async function addAccess(id = 0, params = {}) {
    const response = await postRequest(`docs/access/${id}`, params);
    return response;
}

// get document share information
async function getShareMembers(id = 0, params = {}) {
    const response = await getRequest(`docs/access/${id}`);
    return response;
}

// get document share information
async function updateShareMembers(id = 0, params = {}) {
    const response = await putRequest(`docs/access/${id}`, params);
    return response;
}

// get shared docs list
async function getSharedDocs() {
    const response = await getRequest(`docs/shared/all`);
    return response;
}

// Copys doc
async function copyDoc(parent_doc_id = 0, params) {
    const response = await postRequest(`docs/copy/${parent_doc_id}`, params);
    return response;
}

// Pin doc
async function pinDoc(id = 0, params) {
    const response = await postRequest(`docs/pin/${id}`, params);
    return response;
}

// Pin doc
async function getCustomFolder(id = 0, params) {
    const { search } = params
    const response = await getRequest(`docs/get/Folders?doc_id=${id}&search=${search?search:''}`);
    return response;
}

// Pin doc
async function moveDoc(id = 0, params) {
    const response = await postRequest(`docs/move/${id}`, params);
    return response;
}
import React from 'react';
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";


//import components
import TextMessage from "./TextMessage";
import MediaMessage from "./MediaMessage";
import GroupMediaMessage from "./GroupMediaMessage";
import DocsMessage from "./DocsMessage";
import VoiceNoteMessage from "./VoiceNoteMessage";
import { UploadingFiles } from './UploadingFiles';

//Import Styles
import styles from "./styles";

const MsgComponent = (props) => {
    const { classes, message, msgComponentType,type} = props;
    let messageComponent = '';
    switch (message?.message_category) {
        case 1:
            messageComponent = <MediaMessage {...props} />
            break;
        case 2:
            messageComponent = <DocsMessage {...props} />
            break;
        case 3:
            messageComponent = <GroupMediaMessage {...props} />
            break;
        case 4:
            messageComponent = <VoiceNoteMessage {...props} />
            break;
        // uploading files
        case 7:
            messageComponent = <UploadingFiles {...props} />
            break;
        default:
            messageComponent = <TextMessage {...props} />
            break;
    }

    return (
        <>
            <div className={classes[msgComponentType]}>
                {messageComponent}
            </div>
        </>
    )
}

// Default props
MsgComponent.defaultProps = {
    classes: {},
    message: null,
};

// Prop types
MsgComponent.propTypes = {
    classes: PropTypes.object,
    message: PropTypes.object,
};
export const MessageComponent = withStyles(styles)(MsgComponent)

import { PARAM_KEY, SENT_MESSAGE_API_SERVICE_NAME } from '../containers/Chatv2/Chatv2Constants';

/**
 * Helper Function for chat Send
 * @param {*} userData 
 * @returns 
 * 
 */


export const getTempMsgIdHelper = (userData) => {
    return `${userData.userId}_${userData.agency_pref}_${userData.id}_${new Date().getTime()}`;
}


export const getParamsHelper = (userData) => {
    /* Add Temp message for the sender */
    const tempMessageId = userData?.media_uniqId ? userData.media_uniqId : getTempMsgIdHelper(userData)

    // temp message object
    let tempMessage = {
        id: tempMessageId,
        msgKey: tempMessageId,
        message_category: userData?.messageObj?.messageCategory || userData?.messageCategory || 0,
        message_text: userData?.messageObj?.message_text || userData?.chatText,
        message_raw_text: JSON.stringify(userData?.messageObj?.chatRawContent || userData?.chatRawContent),
        chat_mentions: userData?.messageObj?.mentions,
        chat_links: userData?.messageObj?.links,
        message_from: userData?.userId,
        created_by: userData?.userId,
        user_id: userData?.userId,
        delivered_status: 0,
        read_status: 0,
        reactions: null,
        star: 0,
        star_user_id: null,
        first_name: userData?.first_name,
        last_name: userData?.last_name,
        name: userData?.name,
        username: userData?.username,
        avatar_filename: userData?.profileImage,
        show_infiniti_loader: (!userData?.infinitySendClicked && userData?.activateInfinity) || userData.is_infiniti_room || (userData?.activateInfinity && userData?.messageObj?.get_welcome_message),
        is_infiniti_res: userData.is_infiniti_room ? 1 : (userData?.messageObj?.is_infiniti_res || (userData?.messageObj?.get_welcome_message && userData?.activateInfinity) || 0),
        is_infiniti_room: userData.is_infiniti_room || false,
        ...(userData?.messageObj?.ext_objs) || {}
    }

    // new chat message object
    let chatMessageObj = {
        jobId: userData?.id,
        userId: userData?.userId,
        message: userData?.messageObj,
        messageCategory: userData?.messageObj?.messageCategory || 0,
        isReply: false,
        replyMsgId: null,
        agencyId: userData?.agency_pref,
        msgKey: tempMessage?.msgKey,
        isForward: false,
        forwardmsgId: null,
        isForward_type: null,
        is_infiniti_room: userData.is_infiniti_room,
        chat_title: userData?.chat_title || "",
        username: userData?.username || "",
    };

    if ((!userData?.infinitySendClicked && userData?.activateInfinity) || (userData?.messageObj?.get_welcome_message && userData?.activateInfinity)) {
        chatMessageObj = {
            ...chatMessageObj,
            mode: 'infiniti',
            message_from: userData?.userId
        }

        tempMessage['show_infiniti_loader'] = true
        tempMessage['is_infiniti_res'] = 1
    }

    if (userData?.infinitySendClicked) {
        chatMessageObj = {
            ...chatMessageObj,
        }
    }

    if (userData?.reply && userData?.reply.id) {
        chatMessageObj["isReply"] = true;
        chatMessageObj["replyMsgId"] = userData?.reply.id;
        tempMessage['is_reply'] = 1;
        tempMessage['reply_message_id'] = userData?.reply.id;
    }

    return {
        chatMessageObj,
        tempMessage
    }
}

export const handleChatSendHelper = (userData) => {
    const payloadData = getParamsHelper(userData)
    const params = {
        skip: 1,
        cur_type: userData.type,
        room_id: userData.id
    }
    const serviceName = SENT_MESSAGE_API_SERVICE_NAME[userData.type]
    let data = { payloadData, params, serviceName }
    return data
}

export const formatFileSize = (size) => {
    if (size >= 1024 * 1024) {
        return (size / (1024 * 1024)).toFixed(2) + ' MB';
    } else if (size >= 1024) {
        return (size / 1024).toFixed(2) + ' KB';
    } else {
        return '';
    }
}
export const mediaCompresser = async (file) => {
    try {
        let blob = null;
        let fileObject = null;
        if (file.type.startsWith("video")) {
            blob = await new Promise((resolve, reject) => {
                const vid = document.createElement("video");
                vid.height = 200;
                vid.width = 200;
                vid.setAttribute('crossorigin', 'anonymous');
                const fileURL = URL.createObjectURL(file);
                vid.src = fileURL;
                vid.style.display = "none";
                document.body.appendChild(vid);

                vid.addEventListener("loadedmetadata", () => {
                    const seekTime = vid.duration * 0.05;
                    vid.currentTime = seekTime;
                });

                vid.addEventListener("error", reject);

                vid.addEventListener("seeked", () => {
                    const aspectRatio = vid.videoWidth / vid.videoHeight;
                    const canvas = document.createElement("canvas");
                    const maxWidth = 500;
                    const maxHeight = Math.floor(maxWidth / aspectRatio);
                    canvas.width = Math.min(vid.videoWidth, maxWidth);
                    canvas.height = Math.min(vid.videoHeight, maxHeight);
                    let ctx = canvas.getContext('2d');
                    ctx.drawImage(vid, 0, 0, canvas.width, canvas.height);
                    canvas.toBlob((e) => {
                        resolve(e);
                        URL.revokeObjectURL(fileURL);
                        document.body.removeChild(vid);
                    }, "image/jpeg", 0.7);
                });
            });

            fileObject = new File([blob], file.name + "_thumb", { type: file.type });
        } else if (file.type.startsWith("image")) {
            if (file.type === "image/svg+xml") {
                const text = await file.text();
                const img = new Image();
                img.src = `data:image/svg+xml;base64,${btoa(text)}`;
                
                // Wait for the image to load
                await new Promise((resolve, reject) => {
                    img.onload = resolve;
                    img.onerror = reject;
                });

                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);

                blob = await new Promise((res) => canvas.toBlob(res, "image/jpeg", 0.7));
                fileObject = new File([blob], file.name + "_thumb", { type: "image/jpeg" });

            } else {
                // Handle other image types (JPEG, PNG, etc.)
                const originalSizeMB = file.size / (1024 * 1024);
                let targetSizeMB;

                if (originalSizeMB >= 10) {
                    targetSizeMB = originalSizeMB * 0.2;
                } else if (originalSizeMB >= 3) {
                    targetSizeMB = originalSizeMB * 0.5;
                } else {
                    targetSizeMB = originalSizeMB * 0.7;
                }

                const quality = Math.max(0.1, targetSizeMB / originalSizeMB);
                let bitmap = await createImageBitmap(file, {
                    resizeQuality: "low"
                });

                const canvas = document.createElement('canvas');
                canvas.width = bitmap.width;
                canvas.height = bitmap.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(bitmap, 0, 0);

                blob = await new Promise((res) => canvas.toBlob(res, "image/jpeg", quality));
                fileObject = new File([blob], file.name + "_thumb", { type: file.type });
            }
        }

        return fileObject;
    } catch (error) {
        console.log("Image Compress error:", error);
    }
};

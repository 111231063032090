export const profileConstant = {

    //create profile
    CREATE_PROFILE_R: "CREATE_PROFILE_R",
    CREATE_PROFILE_S: "CREATE_PROFILE_S",
    CREATE_PROFILE_F: "CREATE_PROFILE_F",

    //update reducer data
    UPDATE_CREATE_PROFILE: "UPDATE_CREATE_PROFILE",

    //update reducer data for public page
    UPDATE_PROFILE_PUBLIC_PAGE: "UPDATE_PROFILE_PUBLIC_PAGE",

    //update payment user  data
    UPDATE_WEB_TOKEN_R: "UPDATE_WEB_TOKEN_R",
    UPDATE_WEB_TOKEN_S: "UPDATE_WEB_TOKEN_S",
    UPDATE_WEB_TOKEN_F: "UPDATE_WEB_TOKEN_F",

    GET_PROFILES_R: "GET_PROFILES_R",
    GET_PROFILES_S: "GET_PROFILES_S",
    GET_PROFILES_F: "GET_PROFILES_F",

    GET_PROFILES_NAMES_R: "GET_PROFILES_NAMES_R",
    GET_PROFILES_NAMES_S: "GET_PROFILES_NAMES_S",
    GET_PROFILES_NAMES_F: "GET_PROFILES_NAMES_F",

    SAVE_PROFILES_NAMES_R: "SAVE_PROFILES_NAMES_R",
    SAVE_PROFILES_NAMES_S: "SAVE_PROFILES_NAMES_S",
    SAVE_PROFILES_NAMES_F: "SAVE_PROFILES_NAMES_F",

    NAME_REDUCER_UPDATE: "NAME_REDUCER_UPDATE",

    CLEAR_PROFILE: "CLEAR_PROFILE",

    //**************************************************TEAMS************************************************************* */

    GET_PROJECT_USERS_R: "GET_PROJECT_USERS_R",
    GET_PROJECT_USERS_S: "GET_PROJECT_USERS_S",
    GET_PROJECT_USERS_F: "GET_PROJECT_USERS_F",

    GET_PROJECT_CLIENTS_R: "GET_PROJECT_CLIENTS_R",
    GET_PROJECT_CLIENTS_S: "GET_PROJECT_CLIENTS_S",
    GET_PROJECT_CLIENTS_F: "GET_PROJECT_CLIENTS_F",

    UPDATE_ROLE: "UPDATE_ROLE",

    UPDATE_USER_ROLE_R: "UPDATE_USER_ROLE_R",
    UPDATE_USER_ROLE_S: "UPDATE_USER_ROLE_S",
    UPDATE_USER_ROLE_F: "UPDATE_USER_ROLE_F",

    DELETE_USER_ROLE_R: "DELETE_USER_ROLE_R",
    DELETE_USER_ROLE_F: "DELETE_USER_ROLE_F",
    DELETE_USER_ROLE_S: "DELETE_USER_ROLE_S",

    UPDATE_INVITE_USER: "UPDATE_INVITE_USER",
    UPDATE_CLIENT_INVITE: "UPDATE_CLIENT_INVITE",

    INVITE_N_USERS_R: "INVITE_N_USERS_R",
    INVITE_N_USERS_S: "INVITE_N_USERS_S",
    INVITE_N_USERS_F: "INVITE_N_USERS_F",

    INVITE_N_CLIENT_USERS_R: "INVITE_N_CLIENT_USERS_R",
    INVITE_N_CLIENT_USERS_S: "INVITE_N_CLIENT_USERS_S",
    INVITE_N_CLIENT_USERS_F: "INVITE_N_CLIENT_USERS_F",

    NEW_USER_U_ACCEPT_R: "NEW_USER_U_ACCEPT_R",
    NEW_USER_U_ACCEPT_S: "NEW_USER_U_ACCEPT_S",
    NEW_USER_U_ACCEPT_F: "NEW_USER_U_ACCEPT_F",

    G_NEW_USER_U_ACCEPT_R: "G_NEW_USER_U_ACCEPT_R",
    G_NEW_USER_U_ACCEPT_S: "G_NEW_USER_U_ACCEPT_S",
    G_NEW_USER_U_ACCEPT_F: "G_NEW_USER_U_ACCEPT_F",

    //**************************************************NOTIFICATIONS************************************************************* */

    GET_NOTIFICATIONS_R: "GET_NOTIFICATIONS_R",
    GET_NOTIFICATIONS_S: "GET_NOTIFICATIONS_S",
    GET_NOTIFICATIONS_F: "GET_NOTIFICATIONS_F",

    GET_TASKS_R: "GET_TASKS_R",
    GET_TASKS_S: "GET_TASKS_S",
    GET_TASKS_F: "GET_TASKS_F",

    GET_INVITES_R: "GET_INVITES_R",
    GET_INVITES_S: "GET_INVITES_S",
    GET_INVITES_F: "GET_INVITES_F",

    UPDATE_NOTIFICATIONS_R: "UPDATE_NOTIFICATIONS_R",
    UPDATE_NOTIFICATIONS_S: "UPDATE_NOTIFICATIONS_S",
    UPDATE_NOTIFICATIONS_F: "UPDATE_NOTIFICATIONS_F",

    //**************************************************COMMON************************************************************* */

    GET_GLOBAL_R: "GET_GLOBAL_R",
    GET_GLOBAL_S: "GET_GLOBAL_S",
    GET_GLOBAL_F: "GET_GLOBAL_F",

    GET_TEAM_INVITE_LINK_R: "GET_TEAM_INVITE_LINK_R",
    GET_TEAM_INVITE_LINK_S: "GET_TEAM_INVITE_LINK_S",
    GET_TEAM_INVITE_LINK_F: "GET_TEAM_INVITE_LINK_F",

    UPDATE_AGENCY_NOTIFICATION_S: "UPDATE_AGENCY_NOTIFICATION_S",

    UPDATE_NEW_MESSAGE: "UPDATE_NEW_MESSAGE",

    GET_INVITES_FLOWS_AND_CLIENTS_R: "GET_INVITES_FLOWS_AND_CLIENTS_R",
    GET_INVITES_FLOWS_AND_CLIENTS_S: "GET_INVITES_FLOWS_AND_CLIENTS_S",
    GET_INVITES_FLOWS_AND_CLIENTS_F: "GET_INVITES_FLOWS_AND_CLIENTS_F",

    GET_AGENCCY_DETAIL_COUNT_R: "GET_AGENCCY_DETAIL_COUNT_R",
    GET_AGENCCY_DETAIL_COUNT_S: "GET_AGENCCY_DETAIL_COUNT_S",
    GET_AGENCCY_DETAIL_COUNT_F: "GET_AGENCCY_DETAIL_COUNT_F",


    UPDATE_SHOW_LOGO_R: "UPDATE_SHOW_LOGO_R",
    UPDATE_SHOW_LOGO_S: "UPDATE_SHOW_LOGO_S",
    UPDATE_SHOW_LOGO_F: "UPDATE_SHOW_LOGO_F",

    //*****************************EMAIL_VERIFICATION****************//

    GET_EMAIL_OTP_R: "GET_EMAIL_OTP_R",
    GET_EMAIL_OTP_S: "GET_EMAIL_OTP_S",
    GET_EMAIL_OTP_F: "GET_EMAIL_OTP_F",

    VERIFY_EMAIL_OTP_R: "VERIFY_EMAIL_OTP_R",
    VERIFY_EMAIL_OTP_S: "VERIFY_EMAIL_OTP_S",
    VERIFY_EMAIL_OTP_F: "VERIFY_EMAIL_OTP_F",

    RESEND_EMAIL_OTP_R: "RESEND_EMAIL_OTP_R",
    RESEND_EMAIL_OTP_S: "RESEND_EMAIL_OTP_S",
    RESEND_EMAIL_OTP_F: "RESEND_EMAIL_OTP_F",

    // Update flow permission
    UPDATE_FLOW_PERMISSION_R: "UPDATE_FLOW_PERMISSION_R",
    UPDATE_FLOW_PERMISSION_S: "UPDATE_FLOW_PERMISSION_S",
    UPDATE_FLOW_PERMISSION_F: "UPDATE_FLOW_PERMISSION_F",

    //Delete Notification
    DELETE_NOTIFICATION_R: "DELETE_NOTIFICATION_R",
    DELETE_NOTIFICATION_S: "DELETE_NOTIFICATION_S",
    DELETE_NOTIFICATION_F: "DELETE_NOTIFICATION_F",
    CLEAR_NOTIFICATIONS_S: "CLEAR_NOTIFICATIONS_S",

    EMAIL_UN_SUBSCRIPTION_S: 'EMAIL_UN_SUBSCRIPTION_S',

    // white label app config
    GET_APP_CONFIG_R: "GET_APP_CONFIG_R",
    GET_APP_CONFIG_S: "GET_APP_CONFIG_S",
    GET_APP_CONFIG_F: "GET_APP_CONFIG_F",

    UPDATE_CUSTOM_DOMAIN_R:"UPDATE_CUSTOM_DOMAIN_R",
    UPDATE_CUSTOM_DOMAIN_S:"UPDATE_CUSTOM_DOMAIN_S",
    UPDATE_CUSTOM_DOMAIN_F:"UPDATE_CUSTOM_DOMAIN_F",

    GET_CUSTOM_DOMAIN_DETAILS_R:"GET_CUSTOM_DOMAIN_DETAILS_R",
    GET_CUSTOM_DOMAIN_DETAILS_S:"GET_CUSTOM_DOMAIN_DETAILS_S",
    GET_CUSTOM_DOMAIN_DETAILS_F:"GET_CUSTOM_DOMAIN_DETAILS_F",

    PUBLISH_DOMAIN_PAGE_R:"PUBLISH_DOMAIN_PAGE_R",
    PUBLISH_DOMAIN_PAGE_S:"PUBLISH_DOMAIN_PAGE_S",
    PUBLISH_DOMAIN_PAGE_F:"PUBLISH_DOMAIN_PAGE_F",

    UPDATE_AGENCY_DETAILS_R:"UPDATE_AGENCY_DETAILS_R",
    UPDATE_AGENCY_DETAILS_S:"UPDATE_AGENCY_DETAILS_S",
    UPDATE_AGENCY_DETAILS_F:"UPDATE_AGENCY_DETAILS_F",

    SAVE_AGENCY_EMAIL_CONFIG_R:"SAVE_AGENCY_EMAIL_CONFIG_R",
    SAVE_AGENCY_EMAIL_CONFIG_S:"SAVE_AGENCY_EMAIL_CONFIG_S",
    SAVE_AGENCY_EMAIL_CONFIG_F:"SAVE_AGENCY_EMAIL_CONFIG_F",

    TEST_AGENCY_EMAIL_CONFIG_R:"TEST_AGENCY_EMAIL_CONFIG_R",
    TEST_AGENCY_EMAIL_CONFIG_S:"TEST_AGENCY_EMAIL_CONFIG_S",
    TEST_AGENCY_EMAIL_CONFIG_F:"TEST_AGENCY_EMAIL_CONFIG_F",

    GET_AGENCY_EMAIL_CONFIG_R:"GET_AGENCY_EMAIL_CONFIG_R",
    GET_AGENCY_EMAIL_CONFIG_S:"GET_AGENCY_EMAIL_CONFIG_S",
    GET_AGENCY_EMAIL_CONFIG_F:"GET_AGENCY_EMAIL_CONFIG_F",
    UPDATE_AGENCY_EMAIL_CONFIG: "UPDATE_AGENCY_EMAIL_CONFIG",

    GET_GOOGLE_AUTH_URL_R: "GET_GOOGLE_AUTH_URL_R",
    GET_GOOGLE_AUTH_URL_S: "GET_GOOGLE_AUTH_URL_S",
    GET_GOOGLE_AUTH_URL_F: "GET_GOOGLE_AUTH_URL_F",
    GET_APPLICATION_APPS_R: "GET_APPLICATION_APPS_R",
    GET_APPLICATION_APPS_S: "GET_APPLICATION_APPS_S",
    GET_APPLICATION_APPS_F: "GET_APPLICATION_APPS_F",

    GET_USER_CONFIGURED_APPS_R: "GET_USER_CONFIGURED_APPS_R",
    GET_USER_CONFIGURED_APPS_S: "GET_USER_CONFIGURED_APPS_S",
    GET_USER_CONFIGURED_APPS_F: "GET_USER_CONFIGURED_APPS_F",

    GET_INVOICE_NOTIFICATION_R:"GET_INVOICE_NOTIFICATION_R",
    GET_INVOICE_NOTIFICATION_S:"GET_INVOICE_NOTIFICATION_S",
    GET_INVOICE_NOTIFICATION_F:"GET_INVOICE_NOTIFICATION_F",

    VERIFY_DOMAIN_RECORDS_R:"VERIFY_DOMAIN_RECORDS_R",
    VERIFY_DOMAIN_RECORDS_S:"VERIFY_DOMAIN_RECORDS_S",
    VERIFY_DOMAIN_RECORDS_F:"VERIFY_DOMAIN_RECORDS_F",

    GET_GOOGLE_AUTH_URL_R: "GET_GOOGLE_AUTH_URL_R",
    GET_GOOGLE_AUTH_URL_S: "GET_GOOGLE_AUTH_URL_S",
    GET_GOOGLE_AUTH_URL_F: "GET_GOOGLE_AUTH_URL_F",

    DELETE_PUBLISHED_DOMAIN_R:"DELETE_PUBLISHED_DOMAIN_R",
    DELETE_PUBLISHED_DOMAIN_S:"DELETE_PUBLISHED_DOMAIN_S",
    DELETE_PUBLISHED_DOMAIN_F:"DELETE_PUBLISHED_DOMAIN_F",
    PUBLIC_BOARD_LOGO: "PUBLIC_BOARD_LOGO"
};

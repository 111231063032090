import { createTheme } from "@material-ui/core";
import typography from "./typography";
import overrides from "./overrides";


// body: '#171A20',
// card: '#222638',

// ****First theme****** - -->  Color changed
// ***body***  const agBg1= '#171A20'; --> #373737 
// ***card*** const agBg2= '#222638'; --> #202020
// const agBg3= '#36393C';
// const agBg4= '#363C57'; --->  #434343

const agBg1 = '#0C1019';
const agBg2 = '#141720'; //111419
const agBg3 = '#36393C';
const agBg4 = '#434343';

// chat // action
const agBg5 = '#2D3441';

//chat input // size flows/clients changer
const agBg6 = '#202938';

// popover
const agBg7 = '#10151B';

//calenderbg
const agBg8 = '#182C56';
const agBg9 = '#131720';


export const getPalette = (themeType) => {
  if (themeType === "light") {
    return {
      primary: {
        dary: '#12A8DE',
        main: '#0F172A',
        light: '#64748B',
        bgColor: "#fff",

        border1: '#D7DBEC',
        border2: '#ecebeb',
        border3: '#E2E8F0',
        border4: '#ffffff',
        border5: '#F1F5F9',
        border6: '#F3F3F3', //toborderNone
        border7: '#DAE4ED',
        border8: '#E4E8F1',
        border9: '#D7DBEC',
        border10: '#E6EAEE',
        border11: '#D1D4DC',
        border12: '#2563EB',
        border13: '#6F6F6F33',
        border14: 'none',
        secondaryTwoBtn: '#D8DDE1',

        disabled: '#d7dbec',
        gradientBtn: 'linear-gradient(90deg, #5351FC 0%, #19A9FC 100%)',
        gradientBtn90: 'linear-gradient(to right, #5351FC 0%, #19A9FC 100%)',
        gradientBtn180: 'linear-gradient(180deg, #5351FC 0%, #19A9FC 100%)',
        gradientBtnSelectedBg: 'linear-gradient(180deg, rgba(83, 81, 252, 0.04) 0%, rgba(25, 169, 252, 0.04) 100%)',
        gradientBtnDisabled: 'linear-gradient(90deg, #7E84A3 0%, #7E84A3 100%)',
        selectedBox: 'linear-gradient(white, white), linear-gradient(180deg, #5154F8 , #1AA5FB )',
        infinityBg: 'linear-gradient(#FCFAFF, #FCFAFF), linear-gradient(180deg, #8D53F2, #9D3EDF, #2140FF )',
        detailPage: 'radial-gradient(#F4F8FC 0%, #F4F4FB 100%)',
        activeCard: 'linear-gradient(91.69deg, #EEF5FF 0.59%, #EFF1FF 0.59%, #EEF7FF 96.34%)'
      },
      secondary: {
        main: '#64748B',
      },
      greyshades: {
        main: '#110308',
        dark: '#878083',
        dark2: '#9DA9BA',
        light1: '#C3C3C3',
        light2: '#E6E5E5',
        light3: '#E5EAF1',
        light4: '#FFFFFF',
        light5: '#E0E0E0',
        light6: '#E5EAF1',
      },
      text: {
        blueText: '#2563EB',
        contrastText: "#fff",
        primary: '#0F172A',
        seocondary: '#64748B',
        secondary: '#64748B',
        secondary2: '#94A3B8',

        text1: '#0F172A',
        text2: '#0F172A',
        text3: '#64748B',
        text4: '#0F172A',
        text5: '#0F172A',
        text6: '#94A3B8',
        text7: '#A2B0B9',
        text8: '#94A3B8',
        text9: '#2563EB',
        text10: '#64748B',
        text11: '#B8C5D7',
        text12: '#C0C8D4',
        text13: '#F1F3F9',
        text14: '#778599',
        text15: '#0f172a'

      },
      containers: {
        card: '#ffffff',
        body: '#F4F6F9',
        bg1: '#ffffff',
        bg2: '#F4F6FC',
        bg3: '#E9F3FE',
        bg4: '#F5F6F9',
        bg5: '#F5F9FF',
        bg6: '#F2F5FA',
        bg7: '#F8FAFC',
        bg8: '#e3e3e3',
        bg9: '#F0F0F0',
        bg10: '#E7EDF6',
        bg11: '#fff',
        bg12: '#E3E4E9',
        bg13: '#F7FAFD',
        bg14: '#F2F6FA',
        bg15: '#EBF1F7',
        bg16: '#ffffffba',
        bg17: '#F7F7F7',
        bg18: '#EDEDED',
        bg19: '#aabefb',
        bg20: '#EFF6FF',
        bg21: '#EFF0F3',
        bg22: '#f7f7f773',
        bg23: '#D7DBEC',
        bg24: '#E4EAF2',
        bg25: '#f6f6f6',
        bg26: '#F8FAFF',
        bg27: '#EBF1FF',
        bg28: '#EFF1FB',
        bg29: '#ffffff',
        bg30: '#FBFCFF',
        bg31: '#fff',
        bg32: '#F7F9FE',
        bg33: '#F4F7FF',
        bg34: '#EFF1FB',
        bg35: '#F0F7FF',
        bg36: '#F2F4F5',
        bg37: '#F9FCFF',
        bg38: '#E8F6FF',
        bg39: '#f3f6fb',
        bg40: '#E9F3FE',
        bg41: '#ECF2FF',
        bg42: '#ffffff',
        bg43: '#ffffff',
        arrowBg: '#EBF1F9',
        copyLinkBg: '#EBF1FF',
        bg44: '#F5F6F9',
        bg45: '#ffffff',
        bg46: '#F0F5FF',
        bg47: '#F1F3F9',
        bg48: '#F8F9FB',
        bg49: 'linear-gradient(to bottom , #EFF0F2 25%, #EBE7F6 100%, #F4F5F7 50%)',
        bg50: '#EDF0F4',
        bg51: '#F6F6F6',
        emojiBg: '#fff',
        iconBtnBg: '#D2E2FC',
        timeValues: '#F7F7F7',
        toggleBtn: {
          bg: '#EBF1F7',
          activeBg: '#fff',
          border: '#DAE4ED'
        },
        // with text
        toggleBtn2: {
          bg: '#F2F5FA',
          color: '#0F172A',
          activeBg: '#fff',
          activeColor: '#2563EB'
        },
        toggleBtn3: {
          bg: '#fff',
          color: '#64748B',
          activeBg: '#2563eb1a',
          activeColor: '#0F172A'
        },
        toggleBtn4: {
          bg: '#fff',
          color: '#64748B',
          activeBg: '#2563eb1a',
          activeColor: '#2563EB'
        },
        toolBar: '#fff',
        switch: {
          color: '#0f172a'
        },
        roundIcon: {
          bg: '#EBEAED',
          border: '#EBEAED',
          color: '#2E2C34'
        },
        taskTodoStatus: "#E5EAF1",
      },
      noItemIcon: {
        front: {
          bg: '#C5D7FF',
          light: '#fff',
          border:'#C5D7FF'
        },
        back: {
          bg: '#fff',
          light: '#fff'
        }
      },
      disabled: {
        disabled1: {
          bg: '#EBECED',
          color: '#6a6a6a'
        }
      },
      buttons: {
        close: {
          bg: '#ffffff',
          color: '#2563EB',
          border: '#2563eb'
        },
        outline: {
          color: '#0F172A'
        },
        textPrimary: {
          color: '#0F172A',
          bg: 'transparent'
        }
      },
      shadows: {
        shadow1: '0 0 8px #ddd',
        shadow2: '0 0 25px 0 #b3b3b3',
        shadow3: '0px 0px 3px #dbdbdb',
        shadow4: '0 28px 28px #d7dbec',
        shadow5: '0 5px 10px #efefef',
        shadow6: '0px 1px 6px #c6cde654',
        shadow7: '0px 0px 14px #d7dbec',
        shadow8: '0px 1px 4px #ccc',
        shadow9: '0 24px 36px rgba(0,0,0,0.11), 0 24px 46px #3182ff33',
        shadow10: '0px 1px 8px #0000001a',
        shadow11: '4px 4px 15px 0px rgba(0, 0, 0, 0.02)',
        shadow12: '0px 4px 16px 0px #0000000D',

      },
      colors: {
        green: '#24D164',
        pink: '#ED4F9D',
        blue: '#2563EB',
        red: '#FF3B3B',
        darkGreen: '#0E8E2A',
        orange: '#F97C01'
      },
      calendar: {
        dateCardBg: '#EFF4FF',
        dateCardBorder: '#D8DDE133',
      },
      editor: {
        textColor: "#000000",
        background: "#FFFFFF",
        svgStroke: "#64748B",
        borderColor: "#D8DDE1",
        activeColor: "#2563EB",
      },
      type: "light", // This sets the theme type to "light"
    };
  } else {
    return {
      primary: {
        // #2563EB
        dary: '#12A8DE',
        main: '#ffffff',
        light: '#64748B',
        contrastText: "#fff",
        textColor: "#2A2D33",
        bgColor: "#fff",
        bodyBgColor: '#EFF9FF',
        bgColorGray: '#F6F7FB',

        border1: '#363C57',
        border2: '#363C57',
        border3: '#363C57',
        border4: '#94A3B8',
        border5: '#363C57',
        border6: 'none', //toborderNone
        border7: '#363C57',
        border8: '#363C57',
        border9: agBg2,
        border10: '#363C57',
        border11: '#D1D4DC',
        border12: '#2563EB',
        border13: '#6F6F6F33',
        border14: '#363C57',
        secondaryTwoBtn: agBg2,

        disabled: '#363C57',
        gradientBtn: 'linear-gradient(90deg, #5351FC 0%, #19A9FC 100%)',
        gradientBtn180: 'linear-gradient(180deg, #5351FC 0%, #19A9FC 100%)',
        gradientBtnSelectedBg: 'linear-gradient(180deg, rgba(83, 81, 252, 0.04) 0%, rgba(25, 169, 252, 0.04) 100%)',
        gradientBtnDisabled: 'linear-gradient(90deg, #7E84A3 0%, #7E84A3 100%)',
        selectedBox: 'linear-gradient(#222638, #222638), linear-gradient(180deg, #5154F8 , #1AA5FB )',
        infinityBg: `linear-gradient(${agBg1}, ${agBg1}), linear-gradient(180deg, #8D53F2, #9D3EDF, #2140FF )`,
        detailPage: `radial-gradient(${agBg1} 0%, ${agBg1} 100%)`,
        activeCard: 'linear-gradient(91.69deg, #000000 0.59%, #000000 0.59%, #000000 96.34%)'
      },
      secondary: {
        main: '#ACB9CC',
      },
      greyshades: {
        main: '#110308',
        dark: '#878083',
        dark2: '#9DA9BA',
        light1: '#C3C3C3',
        light2: '#E6E5E5',
        light3: agBg1,
        light4: '#FFFFFF',
        light5: '#64748B',
        light6: agBg3
      },
      text: {
        blueText: '#2563EB',
        contrastText: "#ffffff",
        primary: '#0F172A',
        seocondary: '#ACB9CC',
        secondary: '#ACB9CC',
        secondary2: '#94A3B8',

        text1: '#778599',
        text2: '#2563EB',
        text3: '#778599',
        text4: '#94A3B8',
        text5: '#fff',
        text6: '#94A3B8',
        text7: '#778599',
        text8: '#fff',
        text9: '#fff',
        text10: '#94A3B8',
        text11: '#778599',
        text12: '#778599',
        text13: '#2A2D33',
        // text13: '#778599',
        text14: '#778599',
        text15: '#F0F0F0'
      },
      containers: {
        card: agBg2,
        body: agBg1,
        bg1: agBg1,
        bg2: agBg4,
        bg3: agBg2,
        bg4: agBg1,
        bg5: agBg1,
        bg6: agBg5,
        bg7: agBg5,
        bg8: agBg2,
        bg9: agBg4,
        bg10: agBg2,
        bg11: agBg7,
        bg12: agBg3,
        bg13: agBg3,
        bg14: agBg3,
        bg15: agBg3,
        bg16: agBg2,
        bg17: agBg2,
        bg18: agBg3,
        bg19: agBg1,
        bg20: agBg1,
        bg21: agBg2,
        bg22: '#f7f7f700',
        bg23: agBg1,
        bg24: agBg2,
        bg25: agBg2,
        bg26: agBg1,
        bg27: agBg2,
        bg28: agBg1,
        bg29: agBg6,
        bg30: agBg8,
        bg31: agBg9,
        bg32: agBg1,
        bg33: agBg1,
        bg34: 'transparent',
        bg35: agBg1,
        bg36: agBg2,
        bg37: agBg1,
        bg38: agBg1,
        bg39: agBg3,
        bg40: agBg1,
        bg41: 'linear-gradient(0deg, #171920, #171920),linear-gradient(0deg, rgba(228, 232, 241, 0.2), rgba(228, 232, 241, 0.2))',
        bg42: '#0C1019',
        bg43: 'linear-gradient(0deg, #171920, #171920),linear-gradient(0deg, rgba(228, 232, 241, 0.2), rgba(228, 232, 241, 0.2))',
        arrowBg: '#2563EB40',
        copyLinkBg: 'linear-gradient(0deg, #171920, #171920),linear-gradient(0deg, rgba(228, 232, 241, 0.2), rgba(228, 232, 241, 0.2))',
        bg44: agBg6,
        bg45: '#2563EB',
        bg46: '#262B38',
        bg47: agBg1,
        bg48: agBg1,
        bg49: '#3A3A3A',
        bg50: '#1C2027',
        bg51: agBg3,
        emojiBg: '#696969',
        iconBtnBg: agBg2,
        secondaryTwoBtn: agBg2,
        timeValues: agBg6,
        toggleBtn: {
          bg: agBg1,
          activeBg: agBg2,
          border: agBg4
        },
        toggleBtn2: {
          bg: agBg1,
          color: '#778599',
          activeBg: agBg2,
          activeColor: '#fff'
        },
        toggleBtn3: {
          bg: agBg3,
          color: '#94A3B8',
          activeBg: agBg2,
          activeColor: '#fff'
        },
        toggleBtn4: {
          bg: agBg3,
          color: '#94A3B8',
          activeBg: agBg2,
          activeColor: '#fff'
        },
        toolBar: '#000',
        roundIcon: {
          bg: '#171C25',
          color: '#fff',
          border: agBg4
        },
        taskTodoStatus: "#37393c",
      },
      noItemIcon: {
        front: {
          bg: '#484848',
          light: '#575757',
          border:'#3D3C3C'
        },
        back: {
          bg: '#363636',
          light: '#363636'
        }
      },
      disabled: {
        disabled1: {
          bg: agBg3,
          color: '#6a6a6a'
        }
      },
      buttons: {
        close: {
          bg: agBg1,
          color: '#fff',
          border: agBg4
        },
        outline: {
          color: '#fff'
        },
        textPrimary: {
          color: '#E7E7E7',
          bg: agBg1
        }
      },
      background: {
        paper: agBg2,
        default: agBg1
      },
      shadows: {
        shadow1: '0 0 8px #ddd0',
        shadow2: '0 0 25px 0 #b3b3b300',
        shadow3: '0px 0px 3px #0c0c0c',
        shadow4: '0 28px 28px #000000',
        shadow5: '0 5px 10px #000000',
        shadow6: '0px 1px 6px #0000008c',
        // shadow7: '0px 0px 26px #000000f7',
        shadow7: '0px 0px 26px #0000007d',
        shadow8: '1px 1px 2px #000000c9',
        shadow9: '0 24px 36px rgba(0,0,0,0.11), 0 24px 46px #080808',
        shadow10: '0px 0px 26px #0000007d',
        shadow11: '4px 4px 15px 0px rgba(0, 0, 0, 0.02)',
        shadow12: '0px 4px 16px 0px #0000000D',
      },
      colors: {
        green: '#24D164',
        pink: '#ED4F9D',
        blue: '#2563EB',
        red: '#FF3B3B',
        darkGreen: '#0E8E2A',
        orange: '#F97C01'
      },
      calendar: {
        dateCardBg: agBg1,
        dateCardBorder: '#363C57',
      },
      editor: {
        textColor: "#FFFFFF",
        background: "#141720",
        svgStroke: "#64748B",
        borderColor: "#64748B",
        activeColor: "#2563EB",
      },
      type: "dark",
    };
  }
};

const theme = createTheme({
  palette: getPalette(localStorage.getItem('themeType')),
  overrides,
  typography,
});

export default theme;
